import { lighten } from 'polished';

/** Currently the same theme for dark and light modes */
export const movingTrains = {
  lineCap: 'butt' as CanvasLineCap,
  forColoredLines: 'rgba(0, 0, 0, .3)',
  forGreyLines: 'rgba(255, 255, 255, .3)',
};

/**
 * This is for styling the map drawn in Canvas via OpenLayers,
 * whereas HTML DOM styling is in a different theme file.
 */

export const MapsTheme = {
  dark: {
    adaIcon: {
      background: '#000',
      foreground: '#fff',
      stroke: '#4C4C4C',
    },
    airportLines: '#FFD700',
    dot: {
      color: '#000',
      colorUnavailable: 'rgba(0, 0, 0, 0.4)',
      label: '#000',
      labelUnavailable: 'rgba(0, 0, 0, 0.4)',
      labelOutside: '#fff',
      labelOutsideUnavailable: 'rgba(255, 255, 255, 0.4)',
    },
    entrances: {
      background: '#717171',
      backgroundActive: '#fff',
      foreground: '#000',
    },
    labels: {
      active: '#fff',
      inactive: '#888',
      outline: '#000',
      hover: '#33BBFF',
    },
    lines: {
      notSelected: '#343434',
      notSelectedLight: lighten(0.04, '#343434'),
      stroke: '#000',
    },
    movingTrains,
    pin: '#fff',
    transferLines: '#fff',
    vaccineIcon: {
      background: '#fff',
      backgroundInactive: '#888',
      foreground: '#000',
      hover: {
        background: '#33BBFF',
        backgroundInactive: '#888',
        foreground: '#000',
      },
    },
  },
  light: {
    adaIcon: {
      background: '#fff',
      foreground: '#000',
      stroke: '#B2B2B2',
    },
    airportLines: '#FFD700',
    dot: {
      color: '#000',
      colorUnavailable: 'rgba(0, 0, 0, 0.4)',
      label: '#000',
      labelUnavailable: 'rgba(0, 0, 0, 0.4)',
      labelOutside: '#000',
      labelOutsideUnavailable: 'rgba(0, 0, 0, 0.4)',
    },
    entrances: {
      background: '#aaa',
      backgroundActive: '#000',
      foreground: '#fff',
    },
    labels: {
      active: '#000',
      inactive: '#888',
      outline: '#fff',
      hover: '#0038A6',
    },
    lines: {
      notSelected: '#ddd',
      notSelectedLight: lighten(0.04, '#ddd'),
      stroke: '#fff',
    },
    movingTrains,
    pin: '#000',
    transferLines: '#000',
    vaccineIcon: {
      background: '#000',
      backgroundInactive: '#888',
      foreground: '#fff',
      hover: {
        background: '#0039A6',
        backgroundInactive: '#888',
        foreground: '#fff',
      },
    },
  },
};

export const getMapTheme = (useDarkTheme?: boolean) => {
  return useDarkTheme ? MapsTheme.dark : MapsTheme.light;
};
