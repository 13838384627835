export const isAppleMobileDevice = (): boolean => {
  return ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
};

export const isAndroidDevice = (): boolean => {
  return !!navigator.userAgent.match(/Android/i);
};

export const isInternetExplorer = (): boolean => {
  return (
    /MSIE ([0-9]{1,}[\\.0-9]{0,})/i.test(navigator.userAgent) ||
    /Trident\/.*rv:([0-9]{1,}[\\.0-9]{0,})/i.test(navigator.userAgent)
  );
};

export const isStandaloneMode = (): boolean => {
  return window.matchMedia('(display-mode: standalone)').matches;
};

export const isPhone = (): boolean => {
  return (
    /mobile/i.test(navigator.userAgent) &&
    !/ipad|tablet/i.test(navigator.userAgent)
  );
};

export const isLandscapeMode = (): boolean => {
  return window.matchMedia('(orientation: landscape)').matches;
};
