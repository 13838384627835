/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useRef, useState, useCallback } from 'react';
import flatten from 'lodash/flatten';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Feature, Geolocation, Map as OlMap, View } from 'ol';
import { boundingExtent } from 'ol/extent';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { defaults as defaultControls } from 'ol/control';
import { defaults, KeyboardZoom, KeyboardPan } from 'ol/interaction';
import { MultiLineString, Point as OlPoint } from 'ol/geom';
import {
  addAirportLabelsLayer,
  addAirportRouteLabelsLayer,
  addAirportRouteLinesLayer,
  addAirportStationLabelsLayer,
  addAirportStationsDotsLayer,
  addAirportTerminalLinesLayer,
  addBoroughsLabelsLayer,
  addMapTilesLayer,
  addSelectedStationPinLayer,
  addSubwayLineDotLettersLayer,
  addSubwayRouteIdsLayer,
  addSubwayRouteLinesLayer,
  addSubwayStationDotsLayer,
  addSubwayStationLabelsLayer,
  addSubwayStationTransferLinesLayer,
  addSubwayStopsEntrancesLayer,
  setSelectedStationPinLayerSource,
  addGeoLocationLayer,
  addVaccineLocationsLayer,
  setAirportLabelFeatureHoverStyle,
  setSubwayStationLabelFeatureHoverStyle,
  setVaccineLocationsFeatureHoverStyle,
  setVaccineLocationsFeatureSelectedStyle,
} from './layers';
import {
  RouteSegment,
  SingleStyleFunction,
} from './subway-openlayers-graphics';
import { RoutesUnavailable, TimeFilter } from '../subway-data/index';
import {
  OtpRouteId,
  SkippedEdgeStation,
  StopList,
  StopListsByRoute,
  SubwayRouteId,
  Station,
  StopId,
} from '../subway-data/subway-types';
import { AppDispatch, AppState } from '../models';
import {
  DEFAULT_EXTENT,
  DEFAULT_MAX_ZOOM,
  DEFAULT_MIN_ZOOM,
  DEFAULT_ROTATION,
  DEFAULT_TRANSITION_DURATION,
  DEFAULT_TRANSITION_EASING,
  MOVING_TRAIN_FPS,
  MOVING_TRAIN_MIN_ZOOM,
} from '../config';
import {
  getMapAda,
  getMapCenter,
  getMapGeolocation,
  getMapSelectedRouteId,
  getMapSelectedStation,
  getMapZoom,
  getMapMode,
  getMapElevatorEscalatorStatus,
  getUseDarkMap,
  getMapTimeFilter,
} from '../selectors/map/basic';
import {
  getMapAirportsLabelsFeatures,
  getMapAirportsRouteStationsFeatures,
  getMapAirportsStationsDotsFeatures,
  getMapAirportsTerminalsFeatures,
} from '../selectors/map/openlayers-airports-features';
import {
  getMapAllFeaturesForAllRoutes,
  getMapAllFeaturesForAllRoutesUnified,
  getMapSoloStationsFeatures,
  getMapSoloFeaturesForSelectedRoute,
  getMapStationStyleFunction,
  getMapStationsTransfersFeatures,
  getMapStopsEntrancesFeatures,
  getVaccineLocationsFeatures,
} from '../selectors/map/openlayers-features';
import { getUIInitialized } from '../selectors/ui';
import 'ol/ol.css';
import { isLandscapeMode, isPhone } from '../utils/deviceDetector.utils';
import { getMapStopListsByRoute } from '../selectors/map/getMapStopListsByRoute';
import { getMapRoutesUnavailable } from '../selectors/map/getMapRoutesUnavailable';
import { RouteDisplayMode, ZoomLevel } from './map-types';
import { MapState } from '../models/map';
import { getOlMapZoom } from './maps-utils';
import { clamp } from 'ol/math';
import {
  BLOCK_PEEKABOO_FEATURE_CLASS_NAME,
  DOT_HOVERABLE_FEATURE_LABEL_CLASS_NAME,
  EDITING,
  HIT_TOLERANCE,
  HOVERABLE_FEATURE_LABEL_CLASS_NAME,
  SELECTABLE_FEATURE_LABEL_CLASS_NAME,
  USE_VACCINE_LOCATIONS_ON_MAP,
} from './maps-constants';
import { Point } from '../geometry/geometry-types';
import { never, noModifierKeys, targetNotEditable } from 'ol/events/condition';
import {
  getMapAllRoutesSegmentFeatures,
  getMapAllRoutesSegmentMultiLineFeatures,
  getMovingTrainsFeatures,
} from '../selectors/map/route-segments';
import { updateMovingTrainsLayer } from './layers/layer-moving-trains';
import { FeatureLike } from 'ol/Feature';
import { addSubwayRouteLinesMultiLayer } from './layers/layer-subway-route-lines-multi';
import { addSubwayAdaIconsLayer } from './layers/layer-subway-ada-icons';

const Main = styled.main<{ useDarkMap: boolean }>`
  background-color: ${p => (p.useDarkMap ? '#000' : '#f7f7f7')};
  height: 100%;
  margin: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;

  .ol-rotate,
  .ol-attribution {
    display: none;
  }
`;

interface OpenLayersBitmapProps {
  mapAda: boolean;
  mapAirportsLabelsFeatures: Feature[];
  mapAirportsRouteStationsFeatures: Feature[];
  mapAirportsStationsDotsFeatures: Feature[];
  mapAirportsTerminalsFeatures: Feature[];
  mapAllFeaturesForAllRoutes: Feature[];
  mapAllFeaturesForAllRoutesUnified: Feature[];
  mapCenter: { lat: number; lon: number };
  mapMode: RouteDisplayMode;
  mapAllRoutesSegmentFeatures: Feature[];
  mapAllRoutesSegmentMultiLineFeatures: Feature[];
  movingTrainsFeatures: Feature[];
  mapRoutesUnavailable: RoutesUnavailable;
  mapSelectedRouteId: SubwayRouteId | '';
  mapSoloStationsFeatures: Feature[];
  mapSoloFeaturesForSelectedRoute: Feature[];
  mapStationStyleFunction: SingleStyleFunction;
  mapStopsEntrancesFeatures: Feature[];
  mapStopListsByRoute: StopListsByRoute;
  mapStationsTransfersFeatures: Feature[];
  mapVaccineLocationsFeatures: Feature[];
  mapZoom: number;
  mapTimeFilter: TimeFilter;
  initialized: boolean;
  setAirportTerminalViewOpened: (newStatus: boolean) => void;
  setCloseAllOverlays: () => void;
  setMapView: (mapView: Pick<MapState, 'lat' | 'lon' | 'zoom'>) => void;
  setSelectedAirportTerminalId: (selectedTerminalId: string | null) => void;
  setSelectedStation: (mapSelectedStation: Station | null) => void;
  setSelectedVaccineLocationId: (locationId: string | null) => void;
  setStationViewOpened: (value: boolean) => void;
  setVaccinationViewOpened: (newStatus: boolean) => void;
  mapSelectedStation: Station;
  setCurrentSegment: (segmentId: string) => void;
  updateStopTrainTimes: (stopId: StopId) => void;
  useDarkMap: boolean;
  mapVaccineLocationsVisible: boolean;
}

const OpenLayersBitmap: FC<OpenLayersBitmapProps> = ({
  mapAda,
  mapAirportsLabelsFeatures,
  mapAirportsRouteStationsFeatures,
  mapAirportsStationsDotsFeatures,
  mapAirportsTerminalsFeatures,
  mapAllFeaturesForAllRoutes,
  mapAllFeaturesForAllRoutesUnified,
  mapCenter,
  mapMode,
  mapAllRoutesSegmentFeatures,
  mapAllRoutesSegmentMultiLineFeatures,
  movingTrainsFeatures,
  mapRoutesUnavailable,
  mapSelectedRouteId,
  mapSoloStationsFeatures,
  mapSoloFeaturesForSelectedRoute,
  mapStationStyleFunction,
  mapStopsEntrancesFeatures,
  mapStopListsByRoute,
  mapStationsTransfersFeatures,
  mapVaccineLocationsFeatures,
  mapZoom,
  mapTimeFilter,
  initialized,
  setAirportTerminalViewOpened,
  setCloseAllOverlays,
  setMapView,
  setSelectedAirportTerminalId,
  setSelectedStation,
  setSelectedVaccineLocationId,
  setStationViewOpened,
  setVaccinationViewOpened,
  mapSelectedStation,
  setCurrentSegment,
  updateStopTrainTimes,
  useDarkMap,
  mapVaccineLocationsVisible,
}) => {
  const geolocationRef = useRef<Geolocation>();
  const mapRef = useRef<OlMap>();
  const mapModeRef = useRef<RouteDisplayMode>(mapMode);
  const viewRef = useRef<View>();
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [movingTrainsUpdated, setMovingTrainsUpdated] = useState(Date.now());
  const movingTrainsInterval = useRef<number | undefined>();

  const onMapPointerMove = useCallback(
    e => {
      if (!mapRef.current || e.dragging || isPhone()) return;

      const { zoom: currentZoom } = e.frameState.viewState;
      const pixel = mapRef.current.getEventPixel(e.originalEvent);

      // Get the first hoverable feature of the are
      // Reduced the hit area to work better with labels near each other
      let validFeatureAtPixel:
        | FeatureLike
        | undefined = mapRef.current.getFeaturesAtPixel(pixel, {
        hitTolerance: HIT_TOLERANCE / 2,
        layerFilter: layer => {
          return layer
            .getClassName()
            .includes(HOVERABLE_FEATURE_LABEL_CLASS_NAME);
        },
      })[0];

      // If the hover is not on a label or airport, check if is on a dot
      if (!validFeatureAtPixel) {
        // The dots don't have the color hover effect, but they need the cursors
        // and to highlight the respective label
        // The hit tolerance will hover the letter and the nearest dot because
        // the dots are canvas elements and don't have OL events support.
        validFeatureAtPixel = mapRef.current.getFeaturesAtPixel(pixel, {
          hitTolerance: HIT_TOLERANCE,
          layerFilter: layer => {
            return layer
              .getClassName()
              .includes(DOT_HOVERABLE_FEATURE_LABEL_CLASS_NAME);
          },
        })[0];
      }

      const isVaccineLocation = validFeatureAtPixel?.get(
        'isVaccineLocation'
      ) as boolean;

      // The vaccine icons have the click on any zoom
      mapRef.current.getTargetElement().style.cursor =
        isVaccineLocation || (validFeatureAtPixel && currentZoom >= 14)
          ? 'pointer'
          : 'initial';

      setAirportLabelFeatureHoverStyle(
        useDarkMap,
        validFeatureAtPixel as Feature
      );

      setSubwayStationLabelFeatureHoverStyle({
        isADAFilterActive: mapAda,
        routeId: mapSelectedRouteId,
        stationLabelOrDotFeature: validFeatureAtPixel as Feature,
        useDarkMap,
      });

      setVaccineLocationsFeatureHoverStyle(
        mapAda,
        useDarkMap,
        validFeatureAtPixel as Feature
      );
    },
    [mapAda, mapSelectedRouteId, useDarkMap]
  );

  // singleclick has a 250ms delay
  const onMapSingleClick = useCallback(
    event => {
      if (!mapRef.current || event.dragging) return;

      const { zoom: currentZoom } = event.frameState.viewState;

      // The higher hit tolerance returns more than one feature when they are close.
      // We get the first one to use.
      const validFeatureAtPixel = mapRef.current.getFeaturesAtPixel(
        event.pixel,
        {
          hitTolerance: HIT_TOLERANCE / 2,
          layerFilter: layer => {
            // Click events only apply to layers designated as 'Selectable Features'
            return layer
              .getClassName()
              .includes(SELECTABLE_FEATURE_LABEL_CLASS_NAME);
          },
        }
      )[0];

      if (validFeatureAtPixel) {
        const station = validFeatureAtPixel.get('station') as Station;
        const terminalId = validFeatureAtPixel.get('terminalId') as string;
        const isVaccineLocation = validFeatureAtPixel.get(
          'isVaccineLocation'
        ) as boolean;
        const selectedPinPosition = validFeatureAtPixel.get(
          'selectedPinPosition'
        ) as Point;

        // As we use the station features to show the line icons at the end of
        // the lines on zoom less than 14 on the unfiltered map, we need a
        // specific condition to block the click at that level.
        const blockStationClick =
          mapModeRef.current === 'all' &&
          currentZoom < ZoomLevel.z14 &&
          // The vaccine locations are clickable on any zoom levels
          !isVaccineLocation;

        if (!blockStationClick && selectedPinPosition) {
          if (isPhone()) {
            const padding = isLandscapeMode()
              ? [100, 300, 300, 300]
              : [100, 300, 600, 300];
            const currentView = mapRef.current.getView();
            const currentZoom = currentView.getZoom();
            // Use the selected position to calc the extent and center
            // the screen on the same place on any click
            const extent = boundingExtent([selectedPinPosition]);

            currentView.fit(extent, {
              padding,
              maxZoom: currentZoom,
              easing: DEFAULT_TRANSITION_EASING,
              duration: DEFAULT_TRANSITION_DURATION,
            });
          }

          setCloseAllOverlays();

          if (isVaccineLocation) {
            const locationId = validFeatureAtPixel.get('locationId') as string;
            setSelectedVaccineLocationId(locationId);
            setVaccinationViewOpened(true);
            setVaccineLocationsFeatureSelectedStyle(
              mapAda,
              useDarkMap,
              validFeatureAtPixel as Feature
            );
          } else {
            if (terminalId) {
              setSelectedAirportTerminalId(terminalId);
              setAirportTerminalViewOpened(true);
            } else {
              setSelectedStation(station);
              setStationViewOpened(true);
            }

            // set marker after other state changes
            setSelectedStationPinLayerSource(
              new Feature({
                geometry: new OlPoint(selectedPinPosition),
                isAirportTerminal: !!terminalId,
                isHorizontalStation: validFeatureAtPixel.get(
                  'isHorizontalStation'
                ) as boolean,
                skippedEdgeStation: validFeatureAtPixel.get(
                  'skippedEdgeStation'
                ) as SkippedEdgeStation,
              })
            );
          }
        }
      }

      const nonFeatureClick =
        mapRef.current.getFeaturesAtPixel(event.pixel, {
          // Maintain consistent hit tolerance threshold for feature & non-feature clicks
          hitTolerance: HIT_TOLERANCE,
        }).length < 1;
      // On phone, tapping map outside drawer closes drawer
      if (nonFeatureClick && isPhone()) {
        setCloseAllOverlays();
        setSelectedStationPinLayerSource();
        setVaccineLocationsFeatureSelectedStyle(mapAda, useDarkMap);
      }

      if (EDITING) {
        mapRef.current.forEachFeatureAtPixel(event.pixel, (feature, layer) => {
          const routeSegment = feature.get('routeSegment') as
            | RouteSegment
            | undefined;
          if (routeSegment) {
            const { segmentId } = routeSegment;
            setCurrentSegment(segmentId);
          }
        });
      }
    },
    [
      mapAda,
      setAirportTerminalViewOpened,
      setCloseAllOverlays,
      setCurrentSegment,
      setSelectedAirportTerminalId,
      setSelectedStation,
      setSelectedVaccineLocationId,
      setStationViewOpened,
      setVaccinationViewOpened,
      useDarkMap,
    ]
  );

  useEffect(() => {
    mapModeRef.current = mapMode;
  }, [mapMode]);

  useEffect(() => {
    viewRef.current = new View({
      center: fromLonLat([mapCenter.lon, mapCenter.lat]),
      zoom: mapZoom,
      minZoom: DEFAULT_MIN_ZOOM,
      maxZoom: DEFAULT_MAX_ZOOM,
      rotation: DEFAULT_ROTATION,
      extent: DEFAULT_EXTENT,
    });

    geolocationRef.current = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: viewRef.current.getProjection(),
    });

    geolocationRef.current.setTracking(true);
    // TODO: address compiler warning:
    // "TS2774: This condition will always return true since this function is always defined.
    // Did you mean to call it instead?"
    // Ignoring by casting to any, to avoid changing legacy code for now
    const keyboardPan = new KeyboardPan({
      condition: (noModifierKeys as any) && (targetNotEditable as any),
    });
    const olMap = new OlMap({
      controls: defaultControls({ zoom: false }),
      interactions: defaults({
        pinchRotate: false,
        altShiftDragRotate: false,
        // allows dragging and focusing without focus
        onFocusOnly: false,
        keyboard: false,
      }).extend([
        new KeyboardZoom({
          condition: never,
        }),
        keyboardPan,
      ]),
      pixelRatio: window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio,
      target: 'map',
      view: viewRef.current,
    });

    olMap.on('movestart', () => {
      setIsMoving(true);
      // TODO: hide a layer sooner when it's going to be hidden after zoom change
    });

    olMap.on('moveend', event => {
      /*
       * `moveend` is triggered after user's interaction
       * but also after calling `view.animate()`. With this
       * boolean, we block a second `moveend` unintended
       * animate call.
       */
      setTimeout(() => {
        setIsMoving(false);
      }, 0);

      /*
       * Sync Map values in the store
       * when user interacts with it
       */
      const view = event.map.getView();
      const zoom = view.getZoom() ?? NaN;
      const viewCenter = view.getCenter() ?? [0, 0];
      const [newLon, newLat] = toLonLat(viewCenter || []);

      // TODO: unify with logic in setMapView
      const epsilon = 10 ** -8;
      const lat =
        Math.abs(newLat - mapCenter.lat) > epsilon ? newLat : mapCenter.lat;
      const lon =
        Math.abs(newLon - mapCenter.lon) > epsilon ? newLon : mapCenter.lon;

      setMapView({
        lat,
        lon,
        zoom,
      });

      // Load train arrival data for the station closest to the center of the view,
      // after zoom/pan gesture is finished
      const stationsSource = new VectorSource({
        features: mapAllFeaturesForAllRoutes,
      });
      const stationFeatureClosestToViewCenter = stationsSource.getClosestFeatureToCoordinate(
        viewCenter
      );
      const stationClosestToViewCenter = stationFeatureClosestToViewCenter?.get(
        'station'
      ) as Station | null;
      if (stationClosestToViewCenter && zoom > MOVING_TRAIN_MIN_ZOOM) {
        updateStopTrainTimes(stationClosestToViewCenter.stopId);
      }
    });

    // postrender event fires after a map frame is rendered
    // Peekaboo effect
    olMap.on('postrender', event => {
      const { resolution } = event.frameState.viewState;
      // NOTE: the zoom can slightly overshoot 18 because the zoom "stretches" and bounces back
      const currentZoom = getOlMapZoom(resolution);
      const alpha = clamp(1 - (currentZoom - 18) / 0.25, 0.25, 1);

      olMap.getLayers().forEach(layer => {
        const classNames = layer.getClassName();
        if (!classNames.includes(BLOCK_PEEKABOO_FEATURE_CLASS_NAME)) {
          layer.setOpacity(alpha);
        }
      });

      if (currentZoom > MOVING_TRAIN_MIN_ZOOM) {
        if (!movingTrainsInterval.current) {
          movingTrainsInterval.current = setInterval(() => {
            setMovingTrainsUpdated(Date.now());
          }, 1000 / MOVING_TRAIN_FPS);
        }
      } else {
        clearInterval(movingTrainsInterval.current);
        movingTrainsInterval.current = undefined;
      }
    });

    mapRef.current = olMap;
    // Only run this effect once, to create the map
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mapRef.current?.on('pointermove', onMapPointerMove);

    return () => {
      mapRef.current?.un('pointermove', onMapPointerMove);
    };
  }, [onMapPointerMove]);

  useEffect(() => {
    mapRef.current?.on('singleclick', onMapSingleClick);

    return () => {
      mapRef.current?.un('singleclick', onMapSingleClick);
    };
  }, [onMapSingleClick]);

  // Note that layers are rendered in the order supplied, so if you want,
  // for example, a vector layer to appear on top of a tile layer, it must
  // come after the tile layer.

  // Base layers
  useEffect(() => {
    if (!mapRef.current) return;
    addMapTilesLayer(mapRef.current);
  }, [useDarkMap]);

  useEffect(() => {
    if (!mapRef.current) return;
    // They are behind the lines and revealed by the peekaboo feature.
    addSubwayStopsEntrancesLayer({
      map: mapRef.current,
      stopsEntrancesFeatures: mapStopsEntrancesFeatures,
      useBlackIcons: !!mapSelectedRouteId || !!mapSelectedStation,
      useDarkMap,
    });
  }, [
    mapStopsEntrancesFeatures,
    mapSelectedRouteId,
    mapSelectedStation,
    useDarkMap,
  ]);

  // Line Layers
  useEffect(() => {
    if (!mapRef.current) return;
    addAirportRouteLinesLayer({
      airportsRouteStationsFeatures: mapAirportsRouteStationsFeatures,
      map: mapRef.current,
      useDarkMap,
    });
    addAirportTerminalLinesLayer({
      airportsTerminalsFeatures: mapAirportsTerminalsFeatures,
      map: mapRef.current,
      useDarkMap,
    });
  }, [
    mapAirportsRouteStationsFeatures,
    mapAirportsTerminalsFeatures,
    useDarkMap,
  ]);

  useEffect(() => {
    if (!mapRef.current) return;
    addSubwayRouteLinesLayer({
      map: mapRef.current,
      routeId: mapSelectedRouteId,
      allRoutesSegmentFeatures: mapAllRoutesSegmentFeatures,
      useDarkMap,
    });

    addSubwayRouteLinesMultiLayer({
      map: mapRef.current,
      routeId: mapSelectedRouteId,
      allRoutesSegmentMultiLineFeatures: mapAllRoutesSegmentMultiLineFeatures,
      useDarkMap,
    });
  }, [
    mapSelectedRouteId,
    mapAllRoutesSegmentFeatures,
    useDarkMap,
    mapAllRoutesSegmentMultiLineFeatures,
  ]);

  useEffect(() => {
    if (!mapRef.current) return;
    addSubwayStationTransferLinesLayer({
      features: mapStationsTransfersFeatures,
      map: mapRef.current,
      useDarkMap,
    });
  }, [mapStationsTransfersFeatures, useDarkMap]);

  // Dots and ids layers

  useEffect(() => {
    if (!mapRef.current) return;
    addAirportStationsDotsLayer({
      airportsStationsDotsFeatures: mapAirportsStationsDotsFeatures,
      map: mapRef.current,
      useDarkMap,
    });
  }, [mapAirportsStationsDotsFeatures, useDarkMap]);

  useEffect(() => {
    if (!mapRef.current) return;
    addSubwayStationDotsLayer({
      allFeaturesForAllRoutes: mapAllFeaturesForAllRoutes,
      map: mapRef.current,
      routeId: mapSelectedRouteId,
      routesUnavailable: mapRoutesUnavailable,
      soloFeaturesForCurrentRoute: mapSoloFeaturesForSelectedRoute,
      useDarkMap,
    });
  }, [
    mapAllFeaturesForAllRoutes,
    mapSelectedRouteId,
    mapRoutesUnavailable,
    mapSoloFeaturesForSelectedRoute,
    useDarkMap,
  ]);

  useEffect(() => {
    if (!mapRef.current) return;
    addSubwayLineDotLettersLayer({
      allFeaturesForAllRoutes: mapAllFeaturesForAllRoutes,
      map: mapRef.current,
      routeId: mapSelectedRouteId,
      soloFeaturesForCurrentRoute: mapSoloFeaturesForSelectedRoute,
      stationStyleFunction: mapStationStyleFunction,
    });
  }, [
    mapAllFeaturesForAllRoutes,
    mapSelectedRouteId,
    mapSoloFeaturesForSelectedRoute,
    mapStationStyleFunction,
  ]);

  useEffect(() => {
    if (!mapRef.current) return;
    addSubwayRouteIdsLayer({
      allFeaturesForAllRoutesUnified: mapAllFeaturesForAllRoutesUnified,
      isADAFilterActive: mapAda,
      map: mapRef.current,
      routeId: mapSelectedRouteId,
      routesUnavailable: mapRoutesUnavailable,
      useDarkMap,
    });
  }, [
    mapAllFeaturesForAllRoutesUnified,
    mapAda,
    mapSelectedRouteId,
    mapRoutesUnavailable,
    useDarkMap,
  ]);

  // Label layers

  useEffect(() => {
    if (!mapRef.current) return;
    addBoroughsLabelsLayer({
      map: mapRef.current,
      useDarkMap,
    });
  }, [useDarkMap]);

  useEffect(() => {
    if (!mapRef.current) return;
    addSubwayStationLabelsLayer({
      allFeaturesForAllRoutesUnified: mapAllFeaturesForAllRoutesUnified,
      isADAFilterActive: mapAda,
      map: mapRef.current,
      routeId: mapSelectedRouteId,
      soloStationsFeatures: mapSoloStationsFeatures,
      useDarkMap,
    });
  }, [
    mapAllFeaturesForAllRoutesUnified,
    mapAda,
    mapSelectedRouteId,
    mapSoloStationsFeatures,
    useDarkMap,
  ]);

  useEffect(() => {
    if (!mapRef.current) return;
    addAirportRouteLabelsLayer({
      airportsRouteStationsFeatures: mapAirportsRouteStationsFeatures,
      map: mapRef.current,
      useDarkMap,
    });
    addAirportStationLabelsLayer({
      airportsStationsDotsFeatures: mapAirportsStationsDotsFeatures,
      map: mapRef.current,
      useDarkMap,
    });
    addAirportLabelsLayer({
      airportsLabelsFeatures: mapAirportsLabelsFeatures,
      map: mapRef.current,
      useDarkMap,
    });
  }, [
    mapAirportsRouteStationsFeatures,
    mapAirportsStationsDotsFeatures,
    mapAirportsLabelsFeatures,
    useDarkMap,
  ]);

  useEffect(() => {
    if (!mapRef.current) return;
    // The geo pin should be always on top
    addGeoLocationLayer(
      mapRef.current,
      viewRef.current!,
      geolocationRef.current!
    );
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;
    // The pin icon for the selected station,
    // we should keep it as the last item.
    addSelectedStationPinLayer(mapRef.current, useDarkMap);
  }, [useDarkMap]);

  // Isolate the rapid updates of the moving trains layer to avoid memory leak
  useEffect(() => {
    if (!mapRef.current) return;
    updateMovingTrainsLayer(mapRef.current, movingTrainsFeatures);
  }, [
    movingTrainsFeatures,
    mapSelectedStation,
    mapTimeFilter,
    movingTrainsUpdated,
  ]);

  // Show ADA icons on zoom lower than 14 when ADA is active
  useEffect(() => {
    if (!mapRef.current) return;
    addSubwayAdaIconsLayer({
      allFeaturesForAllRoutesUnified: mapAllFeaturesForAllRoutesUnified,
      isADAFilterActive: mapAda,
      map: mapRef.current,
      routeId: mapSelectedRouteId,
      soloStationsFeatures: mapSoloStationsFeatures,
      useDarkMap,
    });
  }, [
    mapAllFeaturesForAllRoutesUnified,
    mapAda,
    useDarkMap,
    mapSelectedRouteId,
    mapSoloStationsFeatures,
  ]);

  useEffect(() => {
    if (!USE_VACCINE_LOCATIONS_ON_MAP || !mapRef.current) return;
    // Vaccine Location Dots
    addVaccineLocationsLayer({
      isADAFilterActive: mapAda,
      isActive: mapVaccineLocationsVisible,
      map: mapRef.current,
      vaccineLocationsFeatures: mapVaccineLocationsFeatures,
      useDarkMap,
    });
  }, [
    mapAda,
    mapVaccineLocationsFeatures,
    mapVaccineLocationsVisible,
    useDarkMap,
  ]);

  // When route selection changes, redraw the map and adjust pan/zoom
  useEffect(() => {
    const olMap = mapRef.current;
    if (!olMap) return;

    if (mapSelectedRouteId && initialized) {
      // TODO: check this conversion of route ID type instead of casting
      const routeId = mapSelectedRouteId as OtpRouteId;
      // TODO: handle multiple stop lists for route
      const stopsForRoute: StopList = flatten(mapStopListsByRoute[routeId]);

      // Prevent the map from crashing if the line doesn't have any stops.
      if (stopsForRoute.length) {
        // Map fits all route's subway stations
        const routeLineGeometry = new MultiLineString([
          stopsForRoute.map(el => fromLonLat([el.lon, el.lat])),
        ]);

        // Draw the selected route before starting the fit animation
        olMap.renderSync();

        // A limited extent is configured to ensure that we accurately detect if the newly selected line is within view
        const currentExtent = olMap
          .getView()
          .calculateExtent(isPhone() ? [80, 300] : [600, 500]);
        const isRouteWithinView = routeLineGeometry.intersectsExtent(
          currentExtent
        );

        const currentViewCenter = olMap.getView().getCenter() ?? [0, 0];
        const routePointClosestToViewCenter = routeLineGeometry.getClosestPoint(
          currentViewCenter
        );

        !isRouteWithinView &&
          olMap.getView().animate({
            center: routePointClosestToViewCenter,
            duration: DEFAULT_TRANSITION_DURATION,
            easing: DEFAULT_TRANSITION_EASING,
          });
      }
    }

    // TODO: also depend on mapStopsListByRoute?
    // Don't call effect when `initialized` changes
    // eslint-disable-next-line
  }, [mapSelectedRouteId]);

  useEffect(() => {
    const olMap = mapRef.current;
    if (!olMap) return;

    /*
     * Only animate zoom if incoming
     * value is different from the one
     * already applied
     */
    // Disabling this check for now because it makes the URL zoom sync more buggy
    // const appliedZoom = olMap.getView().getZoom();
    // if (Math.abs(appliedZoom - map.zoom) > 0.00000001) {
    olMap.getView().animate({
      zoom: mapZoom,
      duration: DEFAULT_TRANSITION_DURATION,
      easing: DEFAULT_TRANSITION_EASING,
    });
    // }
  }, [mapZoom]);

  useEffect(() => {
    const olMap = mapRef.current;
    if (!olMap) return;

    const appliedCenter = olMap.getView().getCenter();
    const newCenter = fromLonLat([mapCenter.lon, mapCenter.lat]);

    /*
     * Only animate zoom if incoming
     * value is different from the one
     * already applied
     */
    if (appliedCenter !== newCenter && !isMoving) {
      olMap.getView().animate({
        center: newCenter,
        duration: DEFAULT_TRANSITION_DURATION,
        easing: DEFAULT_TRANSITION_EASING,
      });
    }
    // We don't want to rerender when isMoving changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapCenter.lon, mapCenter.lat]);

  /**
   * Chrome iOS has a bug when we change the device orientation. The map
   * size is not correct recalculated after the viewport size change.
   *
   * We are not adding particular conditions because the recalculation
   * doesn't cause problems with the currently working browsers.
   */
  const onChange = useCallback(() => {
    mapRef.current?.updateSize();
  }, [mapRef]);

  useEffect(() => {
    window.addEventListener('orientationchange', onChange);

    return () => {
      window.removeEventListener('orientationchange', onChange);
    };
  }, [onChange]);

  // tabIndex allows the map to be focusable
  return <Main id="map" tabIndex={0} useDarkMap={useDarkMap} />;
};

const mapStateToProps = (state: AppState) => ({
  mapAda: getMapAda(state),
  mapAllFeaturesForAllRoutes: getMapAllFeaturesForAllRoutes(state),
  mapAllFeaturesForAllRoutesUnified: getMapAllFeaturesForAllRoutesUnified(
    state
  ),
  mapAirportsLabelsFeatures: getMapAirportsLabelsFeatures(),
  mapAirportsRouteStationsFeatures: getMapAirportsRouteStationsFeatures(state),
  mapAirportsStationsDotsFeatures: getMapAirportsStationsDotsFeatures(state),
  mapAirportsTerminalsFeatures: getMapAirportsTerminalsFeatures(state),
  mapCenter: getMapCenter(state),
  mapMode: getMapMode(state),
  mapElevatorEscalatorStatus: getMapElevatorEscalatorStatus(state),
  mapAllRoutesSegmentFeatures: getMapAllRoutesSegmentFeatures(state),
  mapAllRoutesSegmentMultiLineFeatures: getMapAllRoutesSegmentMultiLineFeatures(
    state
  ),
  movingTrainsFeatures: getMovingTrainsFeatures(state),
  mapRoutesUnavailable: getMapRoutesUnavailable(state),
  mapSelectedRouteId: getMapSelectedRouteId(state),
  mapSelectedStation: getMapSelectedStation(state),
  mapSoloStationsFeatures: getMapSoloStationsFeatures(state),
  mapSoloFeaturesForSelectedRoute: getMapSoloFeaturesForSelectedRoute(state),
  mapStationStyleFunction: getMapStationStyleFunction(state),
  mapStopsEntrancesFeatures: getMapStopsEntrancesFeatures(state),
  mapStopListsByRoute: getMapStopListsByRoute(state),
  mapStationsTransfersFeatures: getMapStationsTransfersFeatures(state),
  mapVaccineLocationsFeatures: getVaccineLocationsFeatures(state),
  mapZoom: getMapZoom(state),
  mapTimeFilter: getMapTimeFilter(state),
  geolocation: getMapGeolocation(state),
  initialized: getUIInitialized(state),
  useDarkMap: getUseDarkMap(state),
  mapVaccineLocationsVisible: state.map.vaccineLocationsVisible,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setAirportTerminalViewOpened: dispatch.ui.setAirportTerminalViewOpened,
  setCloseAllOverlays: dispatch.ui.setCloseAllOverlays,
  setMapView: dispatch.map.setMapView,
  setSelectedAirportTerminalId: dispatch.map.setSelectedAirportTerminalId,
  setSelectedStation: dispatch.map.setSelectedStation,
  setSelectedVaccineLocationId: dispatch.map.setSelectedVaccineLocationId,
  setStationViewOpened: dispatch.ui.setStationViewOpened,
  setVaccinationViewOpened: dispatch.ui.setVaccinationViewOpened,
  setCurrentSegment: dispatch.map.setCurrentSegment,
  updateStopTrainTimes: dispatch.map.updateStopTrainTimes,
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenLayersBitmap);
