import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import models, { createStore } from './models';
import App from './containers/App';
import Stats from './utils/Stats';
import { getDevQaUrlData } from './utils/url.utils';
import ReactGA from 'react-ga';

/*
 * Polyfill for `:focus-visible` pseudo-selector
 * in order to target focus only using tab
 */
import 'focus-visible';

const store = createStore(models);

const { REACT_APP_BUILD_COMMIT, REACT_APP_NETLIFY } = process.env;

const Application: React.FC = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(<Application />);

// Disabled: Show performance monitor (frames per second) //
const devHostNameWhitelist = [
  'localhost',
  'mta-map-develop.netlify.app',
  'mta-map.on.work.co',
];
if (
  devHostNameWhitelist.some(
    hostname =>
      window.location.hostname.startsWith('192.168') ||
      window.location.hostname.endsWith(hostname)
  ) &&
  getDevQaUrlData().enablefps
) {
  const stats = new Stats();
  // Show fps by default; clicking the panel will switch to ms per frame and MB of RAM
  stats.showPanel(0); // 0: fps, 1: ms, 2: mb
  document.body.appendChild(stats.dom);

  const animate = () => {
    stats.begin();
    stats.end();
    requestAnimationFrame(animate);
  };
  requestAnimationFrame(animate);
}

//// Show build commit hash in window title ////

// TODO: remove from production
if (REACT_APP_NETLIFY && REACT_APP_BUILD_COMMIT) {
  window.document.title = `(#${REACT_APP_BUILD_COMMIT.substr(0, 7)}) ${
    window.document.title
  }`;
}

if (!REACT_APP_NETLIFY) {
  ReactGA.initialize('UA-152891902-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

serviceWorker.register({
  onUpdate: async registration => {
    // Detect a new service worker is waiting to be activated, updating the cache
    // https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    const waitingWorker = registration.waiting;

    if (waitingWorker) {
      // Make Workbox call skipWaiting()
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });

      // Once the waiting service worker is active
      // Once the service worker is unregistered, reload the page to let
      // the browser prefetch again the assets, invalidating the cache
      window.location.reload();
    }
  },
});
