import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import intersectionBy from 'lodash/intersectionBy';
import { Feature } from 'ol';
import { Fill, Style, Text } from 'ol/style';
import { toRadians } from 'ol/math';
import { fromLonLat } from 'ol/proj';
import {
  OtpRouteId,
  SkippedEdgeStation,
  Station,
  Stations,
  Stop,
  StopList,
  StopListsByRoute,
  SubwayRouteId,
  StopId,
  SubwayRouteIds,
} from '../../subway-data/subway-types';
import {
  MANHATTAN_DEGREES,
  subwayRouteIdFromOtpRouteId,
  subwayRouteLabelFromSubwayRouteId,
  RoutesUnavailable,
} from '../../subway-data';
import {
  projectStation,
  RouteSegment,
  SingleStyleFunction,
} from '../../maps/subway-openlayers-graphics';
import theme from '../../utils/theme';
import {
  getMapSelectedRouteId,
  getMapSelectedStation,
  getMapVaccineLocations,
  getUseDarkMap,
} from './basic';
import {
  getMapStopListsByRoute,
  getMapStopListsByRouteRaw,
} from './getMapStopListsByRoute';
import { getMapNormalAngleDegreesForAllStations } from './geometry';
import { Point } from '../../geometry/geometry-types';
import { rotateDegrees, fromPolar } from '../../geometry/point-utils';
import {
  Circle as OlCircle,
  GeometryCollection,
  Point as OlPoint,
  LineString,
} from 'ol/geom';
import {
  cleanSubwayName,
  wrapText,
  getNormalAngleAndPointsForLinePoints,
  getOlMapZoom,
  getStopDirectionAngle,
  getRoutesUnavailableForStop,
} from '../../maps/maps-utils';
import { ZoomLevel } from '../../maps/map-types';
import { getStopPoint } from '../../maps/subway-stops.utils';
import { getMapStations } from './getMapStations';
import {
  hasCustomOffsetPoint,
  hasNegativeXAxisModifier,
  hasNegativeYAxisModifier,
  is45AngleBiDirectional,
  isLastSkipped45Angle,
  isLeftDescending,
  isSharpRightDescendingExclusion,
  isSharpRightDescending,
  isStandardRightDescendingExclusion,
  isStandardRightDescending_45Excluded,
  isLeftAlignedExclusion,
  is45AngleExclusion,
  isSlightLeftDescending,
  isBottomRightAligned,
  isTopRightAligned,
  isBottomLeftAligned,
  isTopLeftAligned,
  isLeftAlignedWithNegativeXAxisModifier,
} from '../../subway-data/station-alignment';
import {
  EMPTY_STYLE,
  STATION_SCALE,
  STATION_SPACING,
} from '../../maps/maps-constants';
import { getMapAvailableStopIdsForSelectedRoute } from './getMapAvailableStopIds';
import otpStopEntrances from '../../subway-data/otp/otp-stops-entrances';
import { findPrimaryStopIdFromAlternative } from '../../subway-data/complex-stations';
import { intersectsWithAvailableStopIds } from '../../utils/feature.utils';
import { getMapConnectedStations } from './getMapConnectedStations';
import { connectedStationsCustomization } from '../../subway-data/connected-stations-customization';
import { getMapTheme } from '../../maps/maps-theme';
import { getMapStationsAndStopsForSelectedRoute } from './getMapStationsAndStopsForSelectedRoute';
import { getMapRoutesUnavailable } from './getMapRoutesUnavailable';
import { getMapAllRoutesSegmentFeatures } from './route-segments';
import { getDevQaUrlData } from '../../utils/url.utils';
import { VaccineLocation } from '../../services/loadCovidVaccines';

const getNormalAngleDegreesForLineStation = (
  routeId: SubwayRouteId | 'SIR',
  stopId: string,
  stationAngles: { [stopId: string]: number }
) => {
  if (routeId === 'SIR') return 0;
  return stationAngles[stopId];
};

export const featureForStop = (
  allRoutesSegmentFeatures: Feature[],
  stop: Stop,
  station: Station,
  stationAngles: { [stopId: string]: number },
  stopListsByRoute: StopListsByRoute
): Feature => {
  const stopPoint = getStopPoint({ stop, station, stationAngles });
  const { isHorizontalStation, point, skippedEdgeStation } = getStationXYPoints(
    station,
    stopListsByRoute,
    stationAngles
  );

  const routeSegmentFeature = allRoutesSegmentFeatures.find(someFeature => {
    const routeSegment = someFeature.get('routeSegment') as RouteSegment;
    return (
      routeSegment.routeId === stop.routeId &&
      routeSegment.stopIds[0] === stop.stopId
    );
  });

  const rotationAngle = getStopDirectionAngle({
    direction: getDevQaUrlData().direction ?? stop.direction,
    routeId: stop.routeId,
    segmentNormalAngles:
      (routeSegmentFeature?.get('routeSegment') as RouteSegment | undefined)
        ?.normalAnglesDegrees ?? [],
    stationAngle: stationAngles[stop.stopId],
    stopId: stop.stopId,
  });

  const feature = new Feature({
    type: 'icon',
    geometry: new OlPoint(stopPoint),
    isHorizontalStation,
    rotationAngle,
    selectedPinPosition: point,
    skippedEdgeStation,
    station,
    stop,
  });

  return feature;
};

const getAlignmentOffsetXAxisModifier = (
  isLastSkipped45Angle: boolean,
  isVerticalStation: boolean,
  normalAngle: number,
  skippedEdgeStation: SkippedEdgeStation,
  stopId: StopId
) => {
  if (
    hasNegativeXAxisModifier[stopId] ||
    isLeftAlignedWithNegativeXAxisModifier[stopId] ||
    (isVerticalStation && skippedEdgeStation === SkippedEdgeStation.last) ||
    skippedEdgeStation === SkippedEdgeStation.last ||
    (isSharpRightDescending(normalAngle) &&
      !isSharpRightDescendingExclusion[stopId]) ||
    (isStandardRightDescending_45Excluded(normalAngle) &&
      skippedEdgeStation === SkippedEdgeStation.none &&
      !isStandardRightDescendingExclusion[stopId]) ||
    isLastSkipped45Angle
  )
    return -1;
  else return 1;
};

const getAlignmentOffsetYAxisModifier = (
  isNegativeHorizontalStation: boolean,
  normalAngle: number,
  stopId: StopId
) => {
  if (stopId === 'R09') return 1; // Queensboro Plaza (W/N/7)
  if (stopId === 'G20') return -0.5; // 36 St (R/M)
  if (
    hasNegativeYAxisModifier[stopId] ||
    isNegativeHorizontalStation ||
    (is45AngleBiDirectional(normalAngle) && !is45AngleExclusion[stopId])
  )
    return -1;
  else if (stopId === 'A15') return 0.5;
  else if (stopId === '418') return -1.5;
  else return 1;
};

const getAlignmentOffsetPoint = (
  normalAngle: number,
  transfersAndPassthroughs: SubwayRouteIds,
  skippedEdgeStation: SkippedEdgeStation,
  isHorizontalStation: boolean,
  isVerticalStation: boolean,
  isLastSkipped45Angle: boolean,
  isNegativeHorizontalStation: boolean,
  stopId: string
): Point => {
  const negativeHorizontalStationModifier = isNegativeHorizontalStation
    ? -1
    : 1;

  const xAxisModifier: number = getAlignmentOffsetXAxisModifier(
    isLastSkipped45Angle,
    isVerticalStation,
    normalAngle,
    skippedEdgeStation,
    stopId
  );

  const yAxisModifier: number = getAlignmentOffsetYAxisModifier(
    isNegativeHorizontalStation,
    normalAngle,
    stopId
  );

  const getCustomTransferOffset = (transferAmount: number): number =>
    (transferAmount / 2) * 20 + 10;
  const TRANSFER_BASED_OFFSET: number = getCustomTransferOffset(
    transfersAndPassthroughs.length
  );

  let alignmentOffsetPoint: Point = [TRANSFER_BASED_OFFSET * xAxisModifier, 0]; // default
  const ANGLE_STATION_OFFSET_MODIFIER = 1.5;
  const HORIZONTAL_STATION_OFFSET_MODIFIER = 2;

  if (hasCustomOffsetPoint[stopId]) {
    if (stopId === '232') {
      // Borough Hall (2, 3, 4, 5)
      return [0, -40];
    } else if (stopId === '234') {
      // Nevins St (2, 3, 4, 5)
      return [0, 50];
    } else if (stopId === '225') {
      // 125 St (2, 3) - offset for bus route to airport
      return [
        getCustomTransferOffset(2) * xAxisModifier,
        TRANSFER_BASED_OFFSET + 1 * -yAxisModifier,
      ];
    } else if (stopId === '621') {
      // 125 St (4, 5, 6) - offset for bus route to airport
      return [
        getCustomTransferOffset(3) * xAxisModifier,
        getCustomTransferOffset(3) * yAxisModifier,
      ];
    } else if (stopId === 'A27') {
      // 42 St Port Authority (A, C, E)
      return [-40, -25];
    } else if (stopId === 'A52') {
      // Liberty Av (A, C)
      return [25, 25];
    } else if (stopId === 'Q01') {
      // Canal St (R, W, N, Q, 6, J, Z)
      return [
        getCustomTransferOffset(transfersAndPassthroughs.length - 1) *
          xAxisModifier,
        getCustomTransferOffset(1) * yAxisModifier,
      ];
    } else if (stopId === 'M18') {
      // Delancey Essex (F, M, J, Z)
      return [0, -40];
    } else if (stopId === 'R09') {
      // Queensboro Plaza (7, W, N)
      return [
        getCustomTransferOffset(transfersAndPassthroughs.length - 1) *
          xAxisModifier,
        0,
      ];
    } else if (stopId === 'R21') {
      // 8 St - NYU (R, W)
      return [-45, 0];
    } else if (stopId === 'R22') {
      // Prince St (R, W)
      return [-45, 0];
    } else if (stopId === 'R25') {
      // Cortlandt St (R, W)
      return [-35, -25];
    } else if (stopId === 'R32') {
      // Union St (R)
      return [-50, 0];
    } else if (stopId === 'R33') {
      // 4 Av-9 St (R)
      return [-40, 30];
    } else if (stopId === '127') {
      // Times Sq 42 St
      return [55, -35];
    } else if (stopId === '142') {
      // South Ferry (1)
      return [0, 0];
    } else if (stopId === '235') {
      // Atlantic Av Barclays (2, 3)
      return [80, 90];
    } else if (stopId === '236') {
      // Bergen St (2, 3)
      return [-50, -60];
    } else if (stopId === 'R30') {
      // DeKalb Av (R, Q, B)
      return [70, 90];
    } else if (stopId === 'R31') {
      // Atlantic Av Barclays (R, N, D)
      return [-50, -40];
    } else if (stopId === 'D16') {
      // 42 St Bryant Park (shifted to reduce decluttering with Times Sq)
      return [
        TRANSFER_BASED_OFFSET * xAxisModifier,
        getCustomTransferOffset(-4) * yAxisModifier,
      ];
    } else if (
      stopId === 'R20' // 14 St Union Square (Bi-directional route intersection)
    ) {
      return [
        TRANSFER_BASED_OFFSET * xAxisModifier,
        (TRANSFER_BASED_OFFSET / ANGLE_STATION_OFFSET_MODIFIER) * yAxisModifier,
      ];
    } else if (stopId === 'G14') {
      // Jackson Hts Roosevelt Av
      return [
        getCustomTransferOffset(1),
        (TRANSFER_BASED_OFFSET / HORIZONTAL_STATION_OFFSET_MODIFIER) *
          yAxisModifier,
      ];
    } else if (stopId === 'G21') {
      // Queens Plaza St (R, M, E)
      return [TRANSFER_BASED_OFFSET * -xAxisModifier, 5];
    } else if (stopId === 'R15') {
      // 49 St (R, W, N) - non-dynamic positioning due to adjacency with 1/2/3 lines
      return [getCustomTransferOffset(4), 0];
    } else if (stopId === '710') {
      // 74 St Broadway (7)
      return [0, TRANSFER_BASED_OFFSET * yAxisModifier];
    } else if (stopId === '249') {
      // Kingston Av (3)
      return [
        TRANSFER_BASED_OFFSET * -xAxisModifier,
        TRANSFER_BASED_OFFSET * -yAxisModifier,
      ];
    } else if (stopId === 'D12') {
      // 155 St (B, D)
      return [
        TRANSFER_BASED_OFFSET * xAxisModifier,
        TRANSFER_BASED_OFFSET * -yAxisModifier,
      ];
    } else if (stopId === '631') {
      // Grand Central 42 St
      return [60, -25];
    } else if (stopId === 'G12') {
      // Grand Av Newtown - force consistent placement to account for rerouting
      return [
        TRANSFER_BASED_OFFSET * ANGLE_STATION_OFFSET_MODIFIER,
        TRANSFER_BASED_OFFSET * ANGLE_STATION_OFFSET_MODIFIER,
      ];
    } else if (stopId === 'L17') {
      // Myrtle - Wyckoff Avs (L, M)
      return [
        (TRANSFER_BASED_OFFSET / HORIZONTAL_STATION_OFFSET_MODIFIER) *
          xAxisModifier,
        TRANSFER_BASED_OFFSET *
          HORIZONTAL_STATION_OFFSET_MODIFIER *
          -yAxisModifier,
      ];
    } else if (stopId === 'A38') {
      //  Fulton St (A, C, J, Z)
      return [
        TRANSFER_BASED_OFFSET * xAxisModifier,
        TRANSFER_BASED_OFFSET * yAxisModifier,
      ];
    } else if (stopId === 'A41') {
      //  Jay St - MetroTech (F, A, C, R)
      return [-60, 70];
    } else if (stopId === 'J20') {
      //  Crescent St (J, Z)
      return [30, -40];
    } else if (
      stopId === 'R27' // Whitehall St, (R, W)
    ) {
      return [
        (TRANSFER_BASED_OFFSET / ANGLE_STATION_OFFSET_MODIFIER) * 0.8,
        TRANSFER_BASED_OFFSET * -0.6,
      ];
    } else if (
      stopId === 'R28' // Court St (W, R)
    ) {
      return [0, TRANSFER_BASED_OFFSET * yAxisModifier];
    } else if (
      stopId === 'N04' // New Utrecht Av (N)
    ) {
      return [10, 15];
    }
  } // end custom by stopId
  else if (isTopLeftAligned[stopId] || isBottomLeftAligned[stopId]) {
    return [
      (TRANSFER_BASED_OFFSET / ANGLE_STATION_OFFSET_MODIFIER) * -xAxisModifier,
      (TRANSFER_BASED_OFFSET / ANGLE_STATION_OFFSET_MODIFIER) * yAxisModifier,
    ];
  } else if (isBottomRightAligned[stopId]) {
    return [
      (TRANSFER_BASED_OFFSET / ANGLE_STATION_OFFSET_MODIFIER) * xAxisModifier,
      (TRANSFER_BASED_OFFSET / ANGLE_STATION_OFFSET_MODIFIER) * yAxisModifier,
    ];
  } else if (isTopRightAligned[stopId]) {
    return [
      TRANSFER_BASED_OFFSET * xAxisModifier,
      (TRANSFER_BASED_OFFSET / ANGLE_STATION_OFFSET_MODIFIER) * yAxisModifier,
    ];
  } else if (isLastSkipped45Angle) {
    return [
      (TRANSFER_BASED_OFFSET / ANGLE_STATION_OFFSET_MODIFIER) * xAxisModifier,
      (TRANSFER_BASED_OFFSET / ANGLE_STATION_OFFSET_MODIFIER) * yAxisModifier,
    ];
  } else if (
    isSlightLeftDescending(normalAngle) ||
    isStandardRightDescending_45Excluded(normalAngle)
  ) {
    return [
      TRANSFER_BASED_OFFSET * xAxisModifier,
      TRANSFER_BASED_OFFSET * -yAxisModifier * ANGLE_STATION_OFFSET_MODIFIER,
    ];
  } else if (
    (!is45AngleExclusion[stopId] && is45AngleBiDirectional(normalAngle)) ||
    isLeftDescending(normalAngle)
  ) {
    return [
      TRANSFER_BASED_OFFSET * xAxisModifier,
      TRANSFER_BASED_OFFSET * -yAxisModifier,
    ];
  } else if (is45AngleExclusion[stopId]) {
    return [
      TRANSFER_BASED_OFFSET * xAxisModifier,
      TRANSFER_BASED_OFFSET * -yAxisModifier * ANGLE_STATION_OFFSET_MODIFIER,
    ];
  } else if (isSharpRightDescending(normalAngle)) {
    // 28 St & 23 St (R, W, N)
    return [
      getCustomTransferOffset(transfersAndPassthroughs.length - 1) *
        xAxisModifier,
      getCustomTransferOffset(1) * -yAxisModifier,
    ];
  } else if (isHorizontalStation) {
    return [
      0,
      (TRANSFER_BASED_OFFSET / HORIZONTAL_STATION_OFFSET_MODIFIER) *
        yAxisModifier *
        negativeHorizontalStationModifier,
    ];
  } else if (isVerticalStation && isLeftAlignedExclusion[stopId]) {
    return [TRANSFER_BASED_OFFSET * -xAxisModifier, 0];
  }

  return alignmentOffsetPoint;
};

export const getStationXYPoints = (
  station: Station,
  stopListsByRoute: StopListsByRoute,
  stationAngles: { [stopId: string]: number }
): {
  point: Point;
  normalAngle?: number;
  isHorizontalStation?: boolean;
  isVerticalStation?: boolean;
  skippedEdgeStation?: SkippedEdgeStation;
  isNegativeHorizontalStation?: boolean;
} => {
  const { stopId, transfersAndPassthroughs } = station;
  const projected = projectStation(station);

  const firstRouteId: SubwayRouteId | undefined = transfersAndPassthroughs[0];
  const lastSubwayRouteAtStation: SubwayRouteId | undefined =
    transfersAndPassthroughs[transfersAndPassthroughs.length - 1];
  const firstSubwayRouteAtStation: SubwayRouteId | undefined =
    transfersAndPassthroughs[0];
  const normalAngle = getNormalAngleDegreesForLineStation(
    firstRouteId as SubwayRouteId,
    stopId,
    stationAngles
  );

  // TODO: handle all stop lists for the route
  const stopsForFirstSubwayRouteAtStation: StopList | undefined =
    stopListsByRoute[firstSubwayRouteAtStation]?.[0];
  const stopsForLastSubwayRouteAtStation: StopList | undefined =
    stopListsByRoute[lastSubwayRouteAtStation]?.[0];

  const getMatchingOtpStop = (stops: StopList | undefined) =>
    stops?.find(stop => stop.stopId === stopId);
  const matchingOtpStopFirstRoute = getMatchingOtpStop(
    stopsForFirstSubwayRouteAtStation
  );
  const matchingOtpStopLastRoute = getMatchingOtpStop(
    stopsForLastSubwayRouteAtStation
  );

  const isSkippingStop = stop => stop?.stopType === '2';
  const skippingFirstStation = isSkippingStop(matchingOtpStopFirstRoute);
  const skippingLastStation = isSkippingStop(matchingOtpStopLastRoute);

  let skippedEdgeStation: SkippedEdgeStation = SkippedEdgeStation.none;
  if (skippingFirstStation) skippedEdgeStation = SkippedEdgeStation.first;
  if (skippingLastStation) skippedEdgeStation = SkippedEdgeStation.last;

  const isHorizontalStation =
    (normalAngle > 70 && normalAngle < 100) ||
    (normalAngle < -70 && normalAngle > -100);
  const isVerticalStation = normalAngle < 30 && normalAngle > -30;
  const isNegativeHorizontalStation = isHorizontalStation && normalAngle < 0;

  const alignmentOffsetPoint: Point = getAlignmentOffsetPoint(
    normalAngle,
    transfersAndPassthroughs,
    skippedEdgeStation,
    isHorizontalStation,
    isVerticalStation,
    isLastSkipped45Angle(normalAngle, skippedEdgeStation),
    isNegativeHorizontalStation,
    stopId
  );

  const rotatedAlignmentOffsetPoint: Point = rotateDegrees(
    alignmentOffsetPoint,
    -MANHATTAN_DEGREES
  );

  return {
    point: [
      projected[0] + rotatedAlignmentOffsetPoint[0],
      projected[1] + rotatedAlignmentOffsetPoint[1],
    ],
    normalAngle,
    isHorizontalStation,
    isVerticalStation,
    skippedEdgeStation,
    isNegativeHorizontalStation,
  };
};

export const featureForStation = (
  station: Station,
  stopListsByRoute: StopListsByRoute,
  stopListsByRouteRaw: Partial<StopListsByRoute>,
  stationAngles: { [stopId: string]: number }
): Feature => {
  const { stopId, stopName, lineIcons, transfers } = station;

  const maxChars = 15;
  const cleanTitle = cleanSubwayName(stopName);
  const text = wrapText(cleanTitle, maxChars);
  const titleLinesCount = text.split('\n').length;

  const {
    point: [x, y],
    normalAngle,
    isHorizontalStation,
    isVerticalStation,
    skippedEdgeStation,
    isNegativeHorizontalStation,
  } = getStationXYPoints(station, stopListsByRoute, stationAngles);

  const feature = new Feature({
    type: 'icon',
    // Usually, a Point here would be enough, but using a Point as the style's
    // geometry makes decluttering go haywire. Strangely, a Circle with radius
    // of 0 makes decluttering work fine again, so we create that as needed.
    // Finally, we use a geometry collection here; each Style seems to correctly
    // pick from the geometry type it needs to work.
    geometry: new GeometryCollection([
      new OlPoint([x, y]),
      new OlCircle([x, y], 0),
    ]),
    routesUnavailableForStop: getRoutesUnavailableForStop(
      stopId,
      transfers,
      stopListsByRouteRaw
    ),
    selectedPinPosition: [x, y],
  });
  feature.set('station', station);
  feature.set('lineIcons', lineIcons);
  feature.set('stopId', stopId);
  feature.set('title', text);
  feature.set('titleLinesCount', titleLinesCount);
  feature.set('normalAngle', normalAngle);
  feature.set('isHorizontalStation', isHorizontalStation);
  feature.set('isVerticalStation', isVerticalStation);
  feature.set('skippedEdgeStation', skippedEdgeStation);
  feature.set('isNegativeHorizontalStation', isNegativeHorizontalStation);

  return feature;
};

export const getMapSoloFeaturesForSelectedRoute = createSelector(
  getMapAllRoutesSegmentFeatures,
  getMapNormalAngleDegreesForAllStations,
  getMapStationsAndStopsForSelectedRoute,
  getMapStopListsByRoute,
  (
    allRoutesSegmentFeatures: Feature[],
    stationAngles: { [stopId: string]: number },
    stationsAndStopsForSelectedRoute: { stations: Stations; stops: StopList },
    stopListsByRoute: StopListsByRoute
  ): Feature[] => {
    const { stations, stops } = stationsAndStopsForSelectedRoute;

    return stops.map(stop => {
      const station = stations.find(
        someStation => someStation.stopId === stop.stopId
      )!;
      return featureForStop(
        allRoutesSegmentFeatures,
        stop,
        station,
        stationAngles,
        stopListsByRoute
      );
    });
  }
);

export const getMapAllFeaturesForAllRoutes = createSelector(
  getMapAllRoutesSegmentFeatures,
  getMapStopListsByRoute,
  getMapNormalAngleDegreesForAllStations,
  getMapStations,
  (
    allRoutesSegmentFeatures: Feature[],
    stopListsByRoute: StopListsByRoute,
    stationAngles: { [stopId: string]: number },
    stations: Stations
  ): Feature[] => {
    const features: Feature[] = [];
    Object.keys(stopListsByRoute).forEach(routeId => {
      // TODO: check for duplicates (maybe use lodash's uniqBy)
      const stopsForRoute = flatten<Stop>(stopListsByRoute[routeId]);
      features.push(
        ...stopsForRoute.map(stop => {
          const station = stations.find(
            someStation => someStation.stopId === stop.stopId
          )!;
          return featureForStop(
            allRoutesSegmentFeatures,
            stop,
            station,
            stationAngles,
            stopListsByRoute
          );
        })
      );
    });
    return features;
  }
);

export const getMapAllFeaturesForAllRoutesUnified = createSelector(
  getMapStopListsByRoute,
  getMapStopListsByRouteRaw,
  getMapNormalAngleDegreesForAllStations,
  getMapStations,
  (
    stopListsByRoute: StopListsByRoute,
    stopListsByRouteRaw: Partial<StopListsByRoute>,
    stationAngles: { [stopId: string]: number },
    stations: Stations
  ): Feature[] => {
    return (
      stations
        .map(station =>
          featureForStation(
            station,
            stopListsByRoute,
            stopListsByRouteRaw,
            stationAngles
          )
        )
        // make ADA station to render last, so the declutter
        // will consider them a high priority
        .sort(feature => (feature.get('station').ada ? 1 : -1))
    );
  }
);

const lineLettersNotOverALine = {
  127: { S: true },
  142: { 1: true },
  247: { 2: true, 5: true },
  250: { 4: true, 5: true },
  252: { 3: true },
  617: { 6: true },
  618: { 6: true },
  631: { 7: true },
  640: { 6: true },
  719: { G: true },
  726: { 7: true },
  A65: { A: true },
  D26: { SF: true },
  D40: { B: true },
  D43: { D: true, N: true },
  F27: { G: true },
  G08: { M: true, R: true },
  H06: { A: true },
  H15: { A: true, SR: true },
  J22: { J: true },
  J27: { L: true },
  L05: { L: true },
  L06: { L: true },
  L08: { L: true },
  L10: { L: true },
  L11: { L: true },
  L13: { L: true },
  L15: { L: true },
  L16: { L: true },
  L17: { L: true },
  L29: { L: true },
  N10: { W: true },
  M11: { M: true },
  R20: { L: true },
  R27: { W: true },
  R45: { R: true },
  E01: { E: true },
  S09: { SIR: true },
  S11: { SIR: true },
  S13: { SIR: true },
  S14: { SIR: true },
  S15: { SIR: true },
  S16: { SIR: true },
  S17: { SIR: true },
  S18: { SIR: true },
  S19: { SIR: true },
  S20: { SIR: true },
  S21: { SIR: true },
  S22: { SIR: true },
  S23: { SIR: true },
  S24: { SIR: true },
  S25: { SIR: true },
  S26: { SIR: true },
  S27: { SIR: true },
  S28: { SIR: true },
  S29: { SIR: true },
  S30: { SIR: true },
  S31: { SIR: true },
};

export const getMapStationStyleFunction = createSelector(
  getMapNormalAngleDegreesForAllStations,
  getMapSelectedRouteId,
  getMapRoutesUnavailable,
  getUseDarkMap,
  (
    stationAngles: { [stopId: string]: number },
    selectedRouteId: SubwayRouteId | '',
    routesUnavailable: RoutesUnavailable,
    useDarkMap: boolean
  ): SingleStyleFunction => (feature, resolution): Style => {
    const currentZoom = getOlMapZoom(resolution);

    if (
      currentZoom < ZoomLevel.z15 ||
      (selectedRouteId && currentZoom < ZoomLevel.z17)
    ) {
      return EMPTY_STYLE;
    }

    // Rotate the route ID to sit nicely within the colored line
    // sometimes station is undefined currently
    const stop: Stop | undefined = feature.get('stop');
    const station: Station | undefined = feature.get('station');
    // e.g. Hide the A on Lafayette Av because the A train doesn't stop there
    if (!stop || stop.stopType === '2') return EMPTY_STYLE;

    const isUnavailable = routesUnavailable[stop?.routeId] || stop.unavailable;
    let angleDegrees = stop ? stationAngles[stop.stopId] : 0;
    const fontSize = (11.5 / resolution) * STATION_SCALE;
    let offsetX = 0;
    let offsetY = (10 / resolution) * STATION_SCALE;

    let shouldMoveToRightSide = Math.abs(angleDegrees) === 90;
    let shouldMoveToLeftSide = false;
    if (
      station &&
      station.perpendicularStack &&
      station.perpendicularStack.includes(stop.routeId as SubwayRouteId)
    ) {
      const lastInStack =
        station.perpendicularStack[station.perpendicularStack.length - 1];
      const side: 'left' | 'right' =
        station.transfersAndPassthroughs.indexOf(lastInStack) === 0
          ? 'left'
          : 'right';
      if (side === 'right') {
        // e.g. Times Sq-42 St (7/S), Delancey St Essex St (M/J/Z)
        shouldMoveToRightSide = true;
      } else if (side === 'left') {
        // e.g. Grand Central 42 St (7/S)
        shouldMoveToLeftSide = true;
      }
    }

    if (shouldMoveToRightSide) {
      // For 90 and -90, position to the side by swapping x and y offsets.
      // Add a little extra to fix a small vertical alignment issue.
      [offsetX, offsetY] = [offsetY, offsetX + 0.8 / resolution];
    } else if (shouldMoveToLeftSide) {
      // Invert the x offset
      [offsetX, offsetY] = [-offsetY, offsetX + 0.8 / resolution];
    } else if (angleDegrees === 0) {
      angleDegrees += 90;
    } else if (angleDegrees > 90) {
      // Fix upside-downs like (C) Lafayette Av, (C) Nostrand, (G) Fulton
      angleDegrees -= 180;
    } else if (angleDegrees < -90) {
      angleDegrees += 180;
    }
    const text = subwayRouteLabelFromSubwayRouteId(
      subwayRouteIdFromOtpRouteId(stop.routeId as OtpRouteId)
    );
    let textRotationDegrees = -angleDegrees;
    const horizontalTolerance = 20;
    const almostHorizontal =
      Math.abs(angleDegrees - 90) < horizontalTolerance ||
      Math.abs(angleDegrees + 90) < horizontalTolerance;
    if (almostHorizontal) {
      textRotationDegrees = 0;
    }

    const isNotOverALine = lineLettersNotOverALine[stop.stopId]?.[text];

    const colorsToUse = getMapTheme(useDarkMap).dot;
    let textColor = isUnavailable
      ? colorsToUse.labelUnavailable
      : colorsToUse.label;

    if (isNotOverALine) {
      textColor = isUnavailable
        ? colorsToUse.labelOutsideUnavailable
        : colorsToUse.labelOutside;
    }

    // TODO: Newkirk & Beverly have dots at the wrong angle, and text should be at -35 instead of -55
    // TODO: (D) 20 Av, Bay Pkwy
    // TODO: (C) Liberty Av, Van Siclen Av
    // TODO: (A) Ozone Park

    return new Style({
      text: new Text({
        text,
        offsetX,
        offsetY,
        font: `bold ${fontSize}px ${theme.fonts.default}`,
        rotation: toRadians(textRotationDegrees),
        fill: new Fill({
          color: textColor,
        }),
      }),
    });
  }
);

export const getMapSoloStationsFeatures = createSelector(
  getMapStopListsByRoute,
  getMapStopListsByRouteRaw,
  getMapNormalAngleDegreesForAllStations,
  getMapStationsAndStopsForSelectedRoute,
  (
    stopListsByRoute: Partial<StopListsByRoute>,
    stopListsByRouteRaw: Partial<StopListsByRoute>,
    stationAngles: { [stopId: string]: number },
    stationsAndStopsForSelectedRoute: { stations: Stations; stops: StopList }
  ): Feature[] => {
    const { stations } = stationsAndStopsForSelectedRoute;

    return (
      stations
        .map(station =>
          featureForStation(
            station,
            // TODO: make type-safe
            stopListsByRoute as StopListsByRoute,
            stopListsByRouteRaw,
            stationAngles
          )
        )
        // make ADA station to render last, so the declutter
        // will consider them a high priority
        .sort(feature => (feature.get('station').ada ? 1 : -1))
    );
  }
);

export const getMapStopsEntrancesFeatures = createSelector(
  getMapAvailableStopIdsForSelectedRoute,
  getMapSelectedStation,
  (
    availableStopIdsForSelectedRoute: string[],
    selectedStation: Station | null
  ): Feature[] =>
    otpStopEntrances
      .filter(entrance => {
        if (selectedStation) {
          const { stopId } = selectedStation;

          return intersectsWithAvailableStopIds(
            [findPrimaryStopIdFromAlternative(stopId)],
            [findPrimaryStopIdFromAlternative(entrance.stopId)]
          );
        }

        return intersectsWithAvailableStopIds(
          availableStopIdsForSelectedRoute,
          [findPrimaryStopIdFromAlternative(entrance.stopId)]
        );
      })
      .map(entrance => {
        const { lat, lon } = entrance;

        return new Feature({
          geometry: new OlPoint(fromLonLat([lon, lat])),
        });
      })
);

export const getMapStationsTransfersFeatures = createSelector(
  getMapConnectedStations,
  getMapNormalAngleDegreesForAllStations,
  getMapStationsAndStopsForSelectedRoute,
  (
    connectedStations: Stations[],
    subwayStationsAngles: { [stopId: string]: number },
    stationsAndStopsForSelectedRoute: { stations: Stations; stops: StopList }
  ): Feature[] => {
    const { stations } = stationsAndStopsForSelectedRoute;

    return connectedStations.reduce<Feature[]>((features, connectedGroup) => {
      if (
        stations.length &&
        connectedGroup.length &&
        !intersectionBy(connectedGroup, stations, 'stopId').length
      ) {
        return features;
      }

      const groupComplexId = connectedGroup[0].Complex_ID;
      const stationsCustomProps =
        connectedStationsCustomization[groupComplexId];
      const customOrder = stationsCustomProps.map(props => props.stopId);
      const sortedStations = (connectedGroup as Array<Station>).sort((a, b) => {
        return customOrder.indexOf(a.stopId) > customOrder.indexOf(b.stopId)
          ? 1
          : -1;
      });

      sortedStations.forEach((station, index) => {
        const nextStation = connectedGroup[index + 1];
        const stationCustomProps = stationsCustomProps.find(
          props => props.stopId === station.stopId
        );

        // The connectStationDots option is not currently being used by any connected stations
        if (stationCustomProps?.connectStationDots) {
          features.push(
            new Feature({
              geometry: new LineString([
                getStationTransferPoint({
                  parallelSpacingAtFirstIndex: true,
                  station,
                  subwayStationsAngles,
                }),
                getStationTransferPoint({
                  parallelSpacingAtFirstIndex: false,
                  station,
                  subwayStationsAngles,
                }),
              ]),
            })
          );
        }

        if (nextStation) {
          const nextStationCustomProps = stationsCustomProps.find(
            props => props.stopId === nextStation.stopId
          );

          let parallelSpacingAtFirstIndex =
            stationCustomProps?.parallelSpacingAtFirstIndex;
          let nextStationParallelSpacingAtFirstIndex =
            nextStationCustomProps?.parallelSpacingAtFirstIndex;
          // Special case: some stations like Fulton (A/C/J/Z) and Park Place
          // are middle stations that need to have transfer lines
          // on both left and right without passing through its dots
          // TODO: represent this by modifying connectedStationsCustomization instead
          const middleStationsToSkip = ['A38', 'E01', '228'];
          if (middleStationsToSkip.includes(nextStation.stopId)) {
            nextStationParallelSpacingAtFirstIndex = !nextStationParallelSpacingAtFirstIndex;
          }
          const { points } = getNormalAngleAndPointsForLinePoints(
            [
              getStationTransferPoint({
                parallelSpacingAtFirstIndex,
                station,
                subwayStationsAngles,
              }),
              getStationTransferPoint({
                parallelSpacingAtFirstIndex: nextStationParallelSpacingAtFirstIndex,
                station: nextStation,
                subwayStationsAngles,
              }),
            ],
            stationCustomProps?.pathStrategyIndex
          );

          features.push(
            new Feature({
              geometry: new LineString(points),
            })
          );
        }
      });

      return features;
    }, []);
  }
);

export const getVaccineLocationsFeatures = createSelector(
  getMapVaccineLocations,
  (vaccineLocations: VaccineLocation[] | null): Feature[] => {
    if (!vaccineLocations) {
      return [];
    }

    // Move the ADA locations to the bottom of the list to render them
    // on top of the others and create the right visual when we have
    // ADA and Vaccine Locations active.
    const sortedLocations = vaccineLocations.sort(a =>
      a.attributes.ada ? 1 : -1
    );

    return sortedLocations.map(vaccineLocation => {
      const { attributes, lat, lon } = vaccineLocation;

      return new Feature({
        geometry: new OlPoint(fromLonLat([lon, lat])),
        selectedPinPosition: fromLonLat([lon, lat]),
        locationId: attributes.id,
        isAdaLocation: attributes.ada,
        isVaccineLocation: true,
      });
    });
  }
);

const getStationTransferPoint = ({
  parallelSpacingAtFirstIndex = false,
  station,
  subwayStationsAngles,
}: {
  parallelSpacingAtFirstIndex?: boolean;
  station: Station;
  subwayStationsAngles: { [stopId: string]: number };
}): Point => {
  const angleDegrees = subwayStationsAngles[station.stopId];
  const projected = projectStation(station);

  // If we use the first index, the line will align with
  // the first transfer of the list.
  let transfers = station.transfers;
  // Fix station.transfers that are reversed on these stations for some reason
  // TODO: fix the reversal upstream
  const reversedTransfersStations = ['R23', 'R31', 'F23', 'N04'];
  if (reversedTransfersStations.includes(station.stopId)) {
    transfers = station.transfersAndPassthroughs;
  }

  const transferRouteId = parallelSpacingAtFirstIndex
    ? transfers[0]
    : transfers[station.transfers.length - 1];

  let padding = 0.7;
  // Fix for Lexington Av/59 St
  if (station.angle === -90) {
    padding = -padding;
  }

  let routeIndex = station.transfersAndPassthroughs.indexOf(transferRouteId);
  let indexForAlignment = parallelSpacingAtFirstIndex
    ? routeIndex - padding
    : routeIndex + padding;
  // Fix for Brooklyn Bridge - City Hall
  if (station.stopId === '640') {
    indexForAlignment = 0;
  }
  // This algorithm is based on parallelSpacingAtIndex()
  // but modified to allow the index to be negative or more than the max index
  const maxIndex = station.transfersAndPassthroughs.length - 1;
  const ratio = indexForAlignment - 0.5 * maxIndex;
  const parallelSpacing = STATION_SPACING * ratio;

  return fromPolar(
    parallelSpacing,
    toRadians(angleDegrees - MANHATTAN_DEGREES),
    projected
  );
};
