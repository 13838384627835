import React from 'react';
import styled, { css } from 'styled-components/macro';
import { desktop } from '../utils/theme';

interface OfflineIndicatorProps {
  isActive: boolean;
}

const OfflineIndicatorContainer = styled.div<OfflineIndicatorProps>`
  width: 100%;
  background-color: ${p => p.theme.colors.black};
  padding: 8px 24px;
  border-radius: 4px;
  transform: ${p => (p.isActive ? 'translateY(0px)' : 'translateY(-20px)')};
  opacity: ${p => (p.isActive ? 1 : 0)};
  transition: ${p => p.theme.transition};
  transition-duration: 0.7s;

  ${desktop(css`
    padding: 14px 22px;
  `)}
`;

const OfflineIndicatorText = styled.p`
  color: ${p => p.theme.colors.gray};
  font-size: 1.4rem;
  text-align: center;
  line-height: 1;
`;

const OfflineIndicator: React.FC<OfflineIndicatorProps> = ({
  isActive,
  children,
}) => (
  <OfflineIndicatorContainer isActive={isActive}>
    <OfflineIndicatorText>{children}</OfflineIndicatorText>
  </OfflineIndicatorContainer>
);

export default OfflineIndicator;
