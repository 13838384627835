import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  RouteStatus,
  Station,
  SubwayRouteId,
} from '../subway-data/subway-types';
import {
  desktopExtraLarge,
  desktopLarge,
  landscapePhone,
  notTouch,
  tablet,
  touch,
} from '../utils/theme';
import AirportInfo from '../containers/AirportInfo';
import FilterButton from '../containers/FilterButton';
import SubwayRoutesMenu from '../containers/SubwayRoutesMenu';
import ServiceStatus from '../containers/ServiceStatus';
import StationStatus from '../containers/StationStatus';
import PillTimeToggle, { timeFilterValues } from '../containers/PillTimeToggle';
import ErrorModal from '../containers/ErrorModal';
import { TRANSITION_DURATION, TRAY_SMALL_HEIGHT, TrayPosition } from './Tray';
import CurrentLocation from './Controls/CurrentLocation';
import ServiceStatusButton, {
  ServiceStatusButtonContainer,
} from '../containers/ServiceStatusButton';
import { ZoomButton, ZoomButtonGroup } from './Controls/ZoomButtons';
import AccessibilityMode, {
  AccessibilityModeContainer,
} from './Controls/AccessibilityMode';
import Icon, { IconContainer, IconTypes } from './Icon';
import OfflineIndicator from '../containers/OfflineIndicator';
import {
  routeIdFromShortcut,
  RoutesUnavailable,
  TimeFilter,
} from '../subway-data';
import { MENU_TRANSITION } from '../utils/animations';
import { DEFAULT_MAX_ZOOM, DEFAULT_MIN_ZOOM } from '../config';
import EmergencyAlertContainer from '../containers/EmergencyAlert';
import EmergencyAlertButton from '../containers/EmergencyAlertButton'; // EmergencyAlertButtonContainer,
import { EmergencyAlert } from '../subway-data/otp/emergency-alerts-types';
import { isPhone } from '../utils/deviceDetector.utils';
import { UIState } from '../models/ui';
import ShortcutsModal from './ShortcutsModal/ShortcutsModal';
import BetaButton from './BetaButton/BetaButton';
import Footer from './Footer/Footer';
import { label } from './Text';
import { EDITING, USE_VACCINE_LOCATIONS_ON_MAP } from '../maps/maps-constants';
import VaccineLocationInfo from '../containers/VaccineLocationInfo';
import VaccineMode, { VaccineModeContainer } from './Controls/VaccineMode';
import {
  setSelectedStationPinLayerSource,
  setVaccineLocationsFeatureSelectedStyle,
} from '../maps/layers';
import { VaccineLocation } from '../services/loadCovidVaccines';

const SkipLinkChildren = css`
  ${label};
  color: ${p => p.theme.colors.black};
  font-weight: bold;
  margin-right: 10px;
  padding: 5px 10px;

  &:focus.focus-visible {
    outline: 1px dotted ${p => p.theme.colors.black};
  }
`;

const SkipNavLink = styled.a`
  ${SkipLinkChildren};
  color: inherit;
  display: block;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
`;

const SkipNavButton = styled.button`
  ${SkipLinkChildren};
`;

const SkipNav = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.white};
  height: 60px;
  display: flex;
  padding: 0 20px;
  position: absolute;
  top: 0;
  transform-origin: center;
  transform: translateY(-100%);
  transition: ${p => p.theme.transition};
  transition-duration: ${TRANSITION_DURATION}ms;
  transition-delay: 0;
  width: 100%;
  z-index: 999;

  &:focus-within {
    transform: translateY(0);
  }
`;

const ControlsContainer = styled.div<{
  shortcutsMenuOpen: boolean;
}>`
  ${touch(css`
    position: fixed;
    bottom: ${p => p.theme.margin};
    bottom: ${p =>
      `calc(env(safe-area-inset-bottom) * 0.75 + ${p.theme.margin})`};
    left: ${p => p.theme.margin};
    left: ${p => `calc(env(safe-area-inset-left) + ${p.theme.margin})`};
    right: ${p => p.theme.margin};
    right: ${p => `calc(env(safe-area-inset-right) + ${p.theme.margin})`};
    z-index: ${p => p.theme.zIndex.controls};
    pointer-events: none;

    ${tablet(css`
      position: static;
      top: inherit;
      right: inherit;
      bottom: inherit;
      left: inherit;
    `)}
  `)}

  opacity: 1;
  transition: opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);

  ${p =>
    p.shortcutsMenuOpen &&
    css`
      opacity: 0;
    `};

  ${notTouch(css`
    position: static;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  `)}
`;

const ControlsInnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${landscapePhone(css`
    max-width: ${p => p.theme.sizes.landscapeContent};
    margin: 0 auto;
  `)}

  ${tablet(css`
    display: block;
    align-items: inherit;
    justify-content: inherit;

    ${ServiceStatusButtonContainer} {
      display: none;
    }
  `)}

  ${notTouch(css`
    display: block;
    align-items: inherit;
    justify-content: inherit;
  `)}
`;

const BetaButtonContainer = styled.div`
  position: absolute;
  top: ${p => p.theme.margin};
  top: ${p => `calc(env(safe-area-inset-top) + ${p.theme.margin})`};
  left: ${p => p.theme.margin};
  left: ${p => `calc(env(safe-area-inset-left) + ${p.theme.margin})`};

  ${desktopLarge(css`
    display: none;
  `)};
`;

const FooterContainer = styled.div<{ shortcutsMenuOpen: boolean }>`
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 1;
  transition: ${p => p.theme.modalTransition};

  ${p =>
    p.shortcutsMenuOpen &&
    css`
      opacity: 0;
    `}
`;

const FilterButtonContainer = styled.div<{
  controlsTranslate: number;
  isOpen: boolean;
  translate: boolean;
}>`
  position: relative;
  z-index: ${p => p.theme.zIndex.controls};
  transform: ${p => `translateY(${p.translate ? '60px' : 0})`};
  transition: ${p => p.theme.transition};
  transition-duration: ${TRANSITION_DURATION}ms;
  pointer-events: all;

  ${notTouch(css`
    position: fixed;
    top: ${p => p.theme.margin};
    top: ${p => `calc(env(safe-area-inset-top) + ${p.theme.margin})`};
    left: ${p => p.theme.margin};
    left: ${p => `calc(env(safe-area-inset-left) + ${p.theme.margin})`};
    z-index: ${p => p.theme.zIndex.controls};
  `)}

  ${touch(css`
    // controlsTranslate value is getting applied to
    // FilterButtonContainer and PillTimeToggleContainer in order to leave
    // other elements in ControlsContainer in place, only on mobile
    transform: ${(p: any) =>
      p.controlsTranslate
        ? `translateY(-${Math.floor(p.controlsTranslate)}px)`
        : ''};
    transition-delay: ${(p: any) =>
      p.controlsTranslate ? 0 : p.theme.transitionDelay.trayAnimate};

    ${tablet(css`
      position: fixed;
      top: ${p => p.theme.margin};
      top: ${p => `calc(env(safe-area-inset-top) + ${p.theme.margin})`};
      left: ${p => p.theme.margin};
      left: ${p => `calc(env(safe-area-inset-left) + ${p.theme.margin})`};
      z-index: ${p => p.theme.zIndex.controls};
    `)}
  `)}

  ${tablet(css`
    display: none;
  `)};
`;

interface PillTimeToggleContainerProps {
  controlsTranslate: number;
  isOpen: boolean;
  translate: boolean;
}
const PillTimeToggleContainer = styled.div<PillTimeToggleContainerProps>`
  pointer-events: ${p => (p.isOpen ? 'all' : 'none')};
  visibility: ${p => (p.isOpen ? 'visible' : 'hidden')};

  ${notTouch(css`
    position: fixed;
    top: ${p => p.theme.margin};
    top: ${p => `calc(env(safe-area-inset-top) + ${p.theme.margin})`};
    left: 85px;
    left: calc(env(safe-area-inset-left) + 85px);
    z-index: ${p => p.theme.zIndex.controls};
    opacity: ${(p: PillTimeToggleContainerProps) => (p.isOpen ? 1 : 0)};
    transform: ${(p: PillTimeToggleContainerProps) =>
      p.isOpen
        ? `translateX(0px) translateY(${p.translate ? '60px' : 0})`
        : 'translateX(-20px)'};
    transition: ${p => p.theme.transition};
    transition-duration: ${TRANSITION_DURATION}ms;
    transition-delay: ${(p: PillTimeToggleContainerProps) =>
      p.isOpen ? MENU_TRANSITION : 0}ms;

    ${desktopLarge(css`
      left: 72px;
      left: calc(env(safe-area-inset-left) + 72px);
    `)};

    ${desktopExtraLarge(css`
      left: 85px;
      left: calc(env(safe-area-inset-left) + 85px);
    `)};
  `)};

  ${touch(css`
    // controlsTranslate value is getting applied to
    // PillTimeToggleContainer and FilterButtonContainer in order to leave
    // other elements in ControlsContainer in place, only on mobile
    transform: ${(p: any) =>
      p.controlsTranslate
        ? `translateY(-${Math.floor(p.controlsTranslate)}px)`
        : ''};
    transition: ${p => p.theme.transition};
    transition-duration: ${TRANSITION_DURATION}ms;
    transition-delay: ${(p: any) =>
      p.controlsTranslate ? 0 : p.theme.transitionDelay.trayAnimate};

    ${tablet(css`
      position: fixed;
      top: ${p => p.theme.margin};
      top: ${p => `calc(env(safe-area-inset-top) + ${p.theme.margin})`};
      left: 72px;
      left: calc(env(safe-area-inset-left) + 72px);
      z-index: ${p => p.theme.zIndex.controls};
      opacity: ${(p: any) => (p.isOpen ? 1 : 0)};
      transform: ${(p: any) =>
        p.isOpen ? 'translateX(0px)' : 'translateX(-20px)'};
    `)};
  `)};

  ${tablet(css`
    display: none;
  `)};
`;

const ZoomContainer = styled.div`
  display: none;
  pointer-events: all;

  ${notTouch(css`
    position: fixed;
    bottom: ${p => p.theme.marginBottomExtra};
    right: ${p => p.theme.margin};
    z-index: ${p => p.theme.zIndex.controls};
    display: block;
  `)}
`;

const BottomRightContainer = styled.div<{ translate: boolean }>`
  display: flex;
  flex-direction: column;
  pointer-events: all;
  align-items: center;

  ${notTouch(css`
    position: fixed;
    // HACK: hard-code the bottom to move it above the zoom buttons
    // TODO: wrap this in a container with zoom buttons
    bottom: 110px;

    right: ${p => p.theme.margin};
    right: ${p => `calc(env(safe-area-inset-right) + ${p.theme.margin})`};
    z-index: ${p => p.theme.zIndex.controls};
  `)}

  ${desktopExtraLarge(css`
    bottom: 125px;
  `)};

  ${touch(css`
    ${tablet(css`
      position: fixed;
      // HACK: hard-code the bottom to move it above the zoom buttons
      // TODO: wrap this in a container with zoom buttons
      bottom: ${p => p.theme.margin};
      bottom: ${p => `calc(env(safe-area-inset-bottom) + ${p.theme.margin})`};

      right: ${p => p.theme.margin};
      right: ${p => `calc(env(safe-area-inset-right) + ${p.theme.margin})`};
      z-index: ${p => p.theme.zIndex.controls};
    `)}
  `)}

  ${AccessibilityModeContainer},
  ${VaccineModeContainer} {
    margin-bottom: 10px;
  }

  ${notTouch(css`
    ${ServiceStatusButtonContainer} {
      transform: ${(p: any) => `translateY(${p.translate ? '60px' : 0})`};
      padding-top: 0px;
    }
  `)}
`;

export interface AppProps {
  ada: boolean;
  airportTerminalViewOpened: boolean;
  centerMapOnGeolocation: () => void;
  currentTrayPosition: TrayPosition;
  emergencyAlert: EmergencyAlert | null;
  emergencyAlertOpened: boolean;
  geolocationEnabled: boolean;
  routeMenuOpened: boolean;
  routesUnavailable: RoutesUnavailable;
  statusViewOpened: boolean;
  selectedRouteId: SubwayRouteId | '';
  selectedRouteStatus: RouteStatus;
  shortcutsMenuOpen: boolean;
  errorType?: UIState['errorType'];
  currentTime: TimeFilter;
  timeFilter: TimeFilter;
  setCloseAllOverlays: () => void;
  setEmergencyAlertOpened: (emergencyAlertOpened: boolean) => void;
  setIsDebugMenuOpen: (isDebugMenuOpen: boolean) => void;
  setMapZoom: (value: number) => void;
  setRouteMenuOpened: (value: boolean) => void;
  setSelectedRouteId: (routeId: SubwayRouteId | '') => void;
  setStatusViewOpened: (value: boolean) => void;
  setStationViewOpened: (value: boolean) => void;
  setShortcutsMenuOpen: (isOpen?: boolean) => void;
  stationViewOpened: boolean;
  toggleADA: () => void;
  toggleVaccineLocationsVisible: () => void;
  useDarkMap: boolean;
  zoom: number;
  zoomIn: () => void;
  zoomOut: () => void;
  requestCloseErrorModal: () => void;
  setAirportTerminalViewOpened: (value: boolean) => void;
  setSelectedStation: (value: Station | null) => void;
  setTimeFilter: (value: TimeFilter) => void;
  setCurrentTrayPosition: (value: TrayPosition) => void;
  setVaccinationViewOpened: (value: boolean) => void;
  vaccineLocations: VaccineLocation[] | null;
  vaccineLocationsVisible: boolean;
  vaccinationViewOpened: boolean;
}

const App: React.FC<AppProps> = ({
  ada,
  airportTerminalViewOpened,
  centerMapOnGeolocation,
  currentTrayPosition,
  emergencyAlert,
  emergencyAlertOpened,
  geolocationEnabled,
  routeMenuOpened,
  routesUnavailable,
  selectedRouteId,
  selectedRouteStatus,
  shortcutsMenuOpen,
  errorType,
  currentTime,
  timeFilter,
  setEmergencyAlertOpened,
  setIsDebugMenuOpen,
  setRouteMenuOpened,
  setSelectedRouteId,
  setStatusViewOpened,
  setStationViewOpened,
  stationViewOpened,
  statusViewOpened,
  toggleADA,
  toggleVaccineLocationsVisible,
  setShortcutsMenuOpen,
  setMapZoom,
  zoom,
  zoomIn,
  zoomOut,
  requestCloseErrorModal,
  setAirportTerminalViewOpened,
  setTimeFilter,
  useDarkMap,
  setCurrentTrayPosition,
  setCloseAllOverlays,
  setVaccinationViewOpened,
  vaccineLocations,
  vaccineLocationsVisible,
  vaccinationViewOpened,
}) => {
  const skipRef = useRef<HTMLDivElement>(null);
  const [skipRefVisible, setSkipRefVisible] = useState<boolean>(false);

  const openMenu = useCallback(() => {
    // the first click on the phone will not open the menu but will
    // clean the selected route and hide the status.
    setStatusViewOpened(false);
    setCurrentTrayPosition(TrayPosition.hidden);

    if (selectedRouteId && isPhone()) {
      setSelectedRouteId('');
    } else {
      setRouteMenuOpened(true);
    }
  }, [
    selectedRouteId,
    setCurrentTrayPosition,
    setRouteMenuOpened,
    setSelectedRouteId,
    setStatusViewOpened,
  ]);

  const keyboardShortcuts = useCallback(
    (e: KeyboardEvent) => {
      // if alt, meta or ctrl but not shift are pressed, ignore
      if (e.altKey || e.metaKey || (e.ctrlKey && !e.shiftKey)) return;

      const key = e.key.toUpperCase();

      // ignore actions until error is dismissed
      // or shortcuts menu is closed
      if (errorType && key !== 'ESCAPE') return;
      if (
        shortcutsMenuOpen &&
        !(key === 'ESCAPE' || (key === '?' && e.shiftKey))
      )
        return;

      // handle zoom
      switch (e.code) {
        case 'Equal':
          zoomIn();
          return;
        case 'Minus':
          zoomOut();
          return;
        default:
          break;
      }

      const routeId = routeIdFromShortcut(e);
      if (routeId) {
        setStationViewOpened(false);
        setSelectedRouteId(routeId === selectedRouteId ? '' : routeId);
        return;
      }

      // handle ctrl shortcuts, assumes shift is also pressed
      if (e.ctrlKey) {
        const ctrlMap = {
          L: centerMapOnGeolocation,
        };

        ctrlMap[key]?.();
        return;
      }

      // handle shift shortcuts, ctrl shouldn't be pressed
      if (e.shiftKey) {
        const shiftMap = {
          '?': () => setShortcutsMenuOpen(!shortcutsMenuOpen),
          A: toggleADA,
        };

        if (USE_VACCINE_LOCATIONS_ON_MAP) {
          shiftMap['V'] = toggleVaccineLocationsVisible;
        }

        shiftMap[key]?.();
        return;
      }

      const defaultMap = {
        ESCAPE: () => {
          if (errorType) requestCloseErrorModal();
          else if (shortcutsMenuOpen) setShortcutsMenuOpen(false);
          else if (stationViewOpened) {
            setStationViewOpened(false);
          } else if (airportTerminalViewOpened) {
            setAirportTerminalViewOpened(false);
          } else if (vaccinationViewOpened) {
            setVaccinationViewOpened(false);
          } else if (selectedRouteId) {
            setSelectedRouteId('');
          }
        },
        T: () => {
          const timeTable = timeFilterValues[currentTime];
          const currentFilter = timeTable.findIndex(t => t === timeFilter);
          const nextFilter =
            timeTable[
              currentFilter + 1 === timeTable.length ? 0 : currentFilter + 1
            ];
          setTimeFilter(nextFilter);
        },
      };

      defaultMap[key]?.();
      return;
    },
    [
      errorType,
      shortcutsMenuOpen,
      zoomIn,
      zoomOut,
      setStationViewOpened,
      setSelectedRouteId,
      selectedRouteId,
      centerMapOnGeolocation,
      toggleADA,
      setShortcutsMenuOpen,
      requestCloseErrorModal,
      stationViewOpened,
      airportTerminalViewOpened,
      vaccinationViewOpened,
      setAirportTerminalViewOpened,
      setVaccinationViewOpened,
      currentTime,
      setTimeFilter,
      timeFilter,
      toggleVaccineLocationsVisible,
    ]
  );

  useEffect(() => {
    // Map editing mode uses the number keys for a different purpose.
    if (EDITING) return;
    window.addEventListener('keyup', keyboardShortcuts);

    return () => window.removeEventListener('keyup', keyboardShortcuts);
  }, [keyboardShortcuts]);

  const skipBarListener = useCallback(
    (e: FocusEvent) => {
      const skipNavLink = document.getElementById('skip-nav-link');
      const skipNavButton = document.getElementById('skip-nav-button');

      setSkipRefVisible(
        e.type === 'focusin'
          ? true
          : e.relatedTarget === skipNavLink || e.relatedTarget === skipNavButton
      );
    },
    [setSkipRefVisible]
  );

  useEffect(() => {
    const skipBar = skipRef.current;
    if (!skipBar) return;

    skipBar.addEventListener('focusin', skipBarListener);
    skipBar.addEventListener('focusout', skipBarListener);
    return () => {
      skipBar.removeEventListener('focusin', skipBarListener);
      skipBar.removeEventListener('focusout', skipBarListener);
    };
  }, [skipRef, skipBarListener]);

  const isFilterButtonDisabled: boolean = !!routesUnavailable[selectedRouteId];
  const isTopControlsActive =
    (!airportTerminalViewOpened &&
      !routeMenuOpened &&
      !stationViewOpened &&
      !vaccinationViewOpened) ||
    isPhone();
  const isEmergencyAlertButtonActive =
    !selectedRouteId && !!emergencyAlert && isTopControlsActive;

  const betaButtonInteraction = useCallback(() => {
    // Close the layers and remove the selected pin styles from the map
    setCloseAllOverlays();
    setSelectedStationPinLayerSource();
    setVaccineLocationsFeatureSelectedStyle(ada, useDarkMap);

    if (selectedRouteId) setSelectedRouteId('');
    else setMapZoom(11);
  }, [
    ada,
    selectedRouteId,
    setCloseAllOverlays,
    setSelectedRouteId,
    setMapZoom,
    useDarkMap,
  ]);

  return (
    <>
      <SkipNav ref={skipRef}>
        <SkipNavLink id="skip-nav-link" href="/accessibility.html">
          Accessibility help
        </SkipNavLink>
        <SkipNavButton
          id="skip-nav-button"
          onClick={() => setShortcutsMenuOpen(true)}
        >
          Keyboard Modal
        </SkipNavButton>
      </SkipNav>
      <BetaButtonContainer>
        <BetaButton
          action={betaButtonInteraction}
          useDarkMap={useDarkMap}
          zoom={zoom}
        />
      </BetaButtonContainer>
      <SubwayRoutesMenu
        translate={skipRefVisible}
        shortcutsMenuOpen={shortcutsMenuOpen}
      />
      <ControlsContainer shortcutsMenuOpen={shortcutsMenuOpen}>
        <ControlsInnerContainer>
          <FilterButtonContainer
            controlsTranslate={
              currentTrayPosition === TrayPosition.small ? TRAY_SMALL_HEIGHT : 0
            }
            isOpen={isTopControlsActive}
            translate={skipRefVisible}
          >
            <FilterButton
              deemphasized={isFilterButtonDisabled}
              isOpen={isTopControlsActive}
              onClick={openMenu}
              selectedRouteId={selectedRouteId}
            />
          </FilterButtonContainer>

          <PillTimeToggleContainer
            controlsTranslate={
              currentTrayPosition === TrayPosition.small ? TRAY_SMALL_HEIGHT : 0
            }
            isOpen={isTopControlsActive}
            translate={skipRefVisible}
          >
            <PillTimeToggle />
          </PillTimeToggleContainer>

          <BottomRightContainer translate={skipRefVisible}>
            {vaccineLocations && USE_VACCINE_LOCATIONS_ON_MAP && (
              <VaccineMode
                aria-label="Show COVID vaccine locations"
                isActive={vaccineLocationsVisible}
                onClick={toggleVaccineLocationsVisible}
              />
            )}

            <AccessibilityMode
              aria-label="Only display accessible stations"
              isActive={ada}
              onClick={toggleADA}
            />

            <CurrentLocation
              aria-label="Center map on current location"
              disabled={!geolocationEnabled}
              onClick={centerMapOnGeolocation}
              onDevMenuClick={() => {
                setIsDebugMenuOpen(true);
              }}
            />

            {/*
              The button is on the bottom container because of the mobile
              position it has. We could refactor the markup to organize
              it better.
            */}
            <ServiceStatusButton
              isActive={isTopControlsActive}
              isRouteUnavailable={!!routesUnavailable[selectedRouteId]}
              {...{
                routeMenuOpened,
                statusViewOpened,
                selectedRouteStatus,
                setStatusViewOpened,
              }}
            />
            <EmergencyAlertButton
              isActive={isEmergencyAlertButtonActive}
              {...{
                emergencyAlertOpened,
                routeMenuOpened,
                selectedRouteStatus,
                setEmergencyAlertOpened,
                statusViewOpened,
              }}
            />
          </BottomRightContainer>

          <ZoomContainer>
            <ZoomButtonGroup>
              <ZoomButton
                data-cy="zoomin-control"
                position="top"
                aria-label="Zoom in"
                onClick={zoomIn}
                disabled={zoom >= DEFAULT_MAX_ZOOM}
              >
                <IconContainer>
                  <Icon type={IconTypes.Plus} />
                </IconContainer>
              </ZoomButton>

              <ZoomButton
                data-cy="zoomout-control"
                position="bottom"
                aria-label="Zoom out"
                onClick={zoomOut}
                disabled={zoom <= DEFAULT_MIN_ZOOM}
              >
                <IconContainer>
                  <Icon type={IconTypes.Minus} />
                </IconContainer>
              </ZoomButton>
            </ZoomButtonGroup>
          </ZoomContainer>
        </ControlsInnerContainer>
      </ControlsContainer>
      {!isPhone() && (
        <FooterContainer shortcutsMenuOpen={shortcutsMenuOpen}>
          <Footer />
        </FooterContainer>
      )}
      <AirportInfo
        shortcutsMenuOpen={shortcutsMenuOpen}
        translate={skipRefVisible}
      />
      <VaccineLocationInfo
        shortcutsMenuOpen={shortcutsMenuOpen}
        translate={skipRefVisible}
      />
      <ServiceStatus selectedRouteStatus={selectedRouteStatus} />
      <StationStatus
        shortcutsMenuOpen={shortcutsMenuOpen}
        translate={skipRefVisible}
      />
      <EmergencyAlertContainer
        translate={skipRefVisible}
        shortcutsMenuOpen={shortcutsMenuOpen}
      />
      <ShortcutsModal
        closeModal={() => setShortcutsMenuOpen(false)}
        isOpen={shortcutsMenuOpen}
      />
      <ErrorModal />
      {/* TODO: allow Developer menu to appear on specific testing domains */}
      {/*<DebugMenu />*/}
      <OfflineIndicator />
    </>
  );
};

export default App;
