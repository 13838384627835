import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';

import { landscapePhone, tablet } from '../../utils/theme';
import { TRANSITION_200 } from '../../utils/animations';
import { links, LinkType } from './constants';

export const FooterSection = styled.footer`
  background-color: ${p => p.theme.colors.grayF7};
  color: ${p => p.theme.colors.gray7B};
  display: flex;
  flex-grow: 1;
  font-size: 1rem;
  justify-content: space-between;
  padding: 16px 30px;

  ${landscapePhone(css`
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 80%;
    padding: 16px 0;

    > a {
      margin-right: 10px;
    }
  `)};

  ${tablet(css`
    background-color: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.textGray};
    font-size: 0.9rem;
    max-width: 292px;
    padding: 6px ${p => p.theme.margin} 6px 15px;
  `)};
`;

const FooterLink = styled.a`
  color: inherit;
  display: block;
  text-decoration: none;
  transition: color ${TRANSITION_200}ms;

  &:visited {
    color: inherit;
  }

  &:focus.focus-visible {
    outline: 1px dotted ${p => p.theme.colors.black};
  }

  &:hover {
    color: ${p => p.theme.colors.blue};
  }
`;

const Footer: FC = () => (
  <FooterSection>
    {links.map((link: LinkType) => (
      <FooterLink key={link.title} href={link.url} target={link.target}>
        {link.title}
      </FooterLink>
    ))}
  </FooterSection>
);

export default Footer;
