import { containsCoordinate } from 'ol/extent';
import { DEFAULT_EXTENT } from '../config';
import { fromLonLat } from 'ol/proj';

export const EPSILON = 10 ** -8;

/*
 * Determine if two values are different
 * eg. comparing latitudes & longitudes
 */
export const isValueDifferent = (
  originValue: number,
  value: number | undefined,
  precision: number = EPSILON
): boolean => {
  if (value === undefined) {
    return false;
  }

  return Math.abs(value - originValue) > precision;
};

/*
 * Determine if a given geolocation
 * (latitude & longitude) are within an extent
 */
export const isWithinExtent = (
  coords: number[],
  extent = DEFAULT_EXTENT
): boolean => {
  return containsCoordinate(extent, fromLonLat(coords));
};
