import flattenDeep from 'lodash/flattenDeep';
import uniq from 'lodash/uniq';
import { createSelector } from 'reselect';

import {
  SubwayRouteId,
  StopListsByRoute,
  Stop,
} from '../../subway-data/subway-types';

import { getMapSelectedRouteId } from './basic';
import { getMapStopListsByRoute } from './getMapStopListsByRoute';

export const getMapAvailableStopIdsForAllRoutes = createSelector(
  getMapStopListsByRoute,
  (stopListsByRoute: StopListsByRoute): string[] => {
    const flattenedStops = flattenDeep<Stop>(Object.values(stopListsByRoute));
    return uniq(flattenedStops.map(stop => stop.stopId));
  }
);

export const getMapAvailableStopIdsForSelectedRoute = createSelector(
  getMapSelectedRouteId,
  getMapStopListsByRoute,
  (
    selectedRouteId: SubwayRouteId | '',
    stopListsByRoute: StopListsByRoute
  ): string[] => {
    const flattenedStops = flattenDeep<Stop>(
      stopListsByRoute[selectedRouteId] ?? Object.values(stopListsByRoute)
    );
    return uniq(flattenedStops.map(stop => stop.stopId));
  }
);
