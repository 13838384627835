import { Feature, Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style } from 'ol/style';
import { RenderFunction } from 'ol/style/Style';

import {
  subwayRouteColors,
  subwayRouteInactiveColors,
} from '../../subway-data';
import { SubwayRouteId } from '../../subway-data/subway-types';
import { Point } from '../../geometry/geometry-types';
import {
  drawRoundSingleLineForPoints,
  getRadiiAtLineIndex,
} from '../../geometry/paths';

import { RENDER_BUFFER_ROUTES, STATION_SPACING } from '../maps-constants';
import { getMapTheme } from '../maps-theme';
import { getRouteLineWidth } from '../maps-utils';
import {
  LayerRenderingProps,
  RouteSegment,
} from '../subway-openlayers-graphics';
import { getRadiusBase } from './utils/getRadiusBase';

const renderRouteSegments = (
  selectedRouteId: SubwayRouteId | '',
  useDarkMap: boolean
): RenderFunction => (coordinates, state) => {
  const { context, feature, pixelRatio, resolution } = state;
  const routeId = feature.get('routeId') as SubwayRouteId;
  const routeSegments = feature.get('routeSegments') as RouteSegment[];

  const colorsToUse = getMapTheme(useDarkMap).lines;
  const lineActiveColor = subwayRouteColors[routeId];
  const lineInactiveColor = subwayRouteInactiveColors[routeId];
  const lineNotSelectedColor = colorsToUse.notSelected;
  const lineWidth = getRouteLineWidth(pixelRatio, resolution);

  context.beginPath();

  let previousUnavailable = routeSegments[0].unavailable;
  coordinates.forEach((coordinate, index) => {
    const {
      segmentsNormalAnglesDegrees,
      routeIndex,
      strategizedPoints,
      strategyIndex,
      maxIndex,
      nextRouteIndex,
      nextMaxIndex,
      unavailable,
    } = routeSegments[index];

    if (previousUnavailable !== unavailable) {
      context.stroke();
      context.beginPath();
      previousUnavailable = unavailable;
    }

    const points = coordinate as Point[];
    const lineSelectedColor = unavailable ? lineInactiveColor : lineActiveColor;
    const lineColor =
      selectedRouteId && selectedRouteId !== routeId
        ? lineNotSelectedColor
        : lineSelectedColor;

    // The 2 stations at each end of the route segment may have different numbers of routes (dots)
    // so base the radii calculation on the station with the lowest number
    // e.g. Green 4/5/6 connects 14 St-Union Sq with 8 dots to Astor Pl with 3 dots
    // Without this adjustment, the radii will not be symmetrical in a 4-point curve (2 elbows)
    // NOTE: this code is currently duplicated between here and layer-subway-route-lines.ts
    // and must be kept in sync manually
    // TODO: consolidate in RouteSegment creation?
    let effectiveMaxIndex = maxIndex;
    let lineIndex = routeIndex;
    if (nextMaxIndex < maxIndex) {
      effectiveMaxIndex = nextMaxIndex;
      lineIndex = nextRouteIndex;
    }

    const radiusBase = getRadiusBase(resolution);
    const radii = getRadiiAtLineIndex({
      points: strategizedPoints,
      radius: radiusBase,
      spacing: (STATION_SPACING * pixelRatio) / resolution,
      lineIndex,
      maxIndex: effectiveMaxIndex,
      segmentsNormalAnglesDegrees,
    });

    context.lineCap = 'round';
    context.strokeStyle = lineColor;
    context.lineWidth = lineWidth;
    drawRoundSingleLineForPoints(points, radii, context, strategyIndex);
  });
  context.stroke();
};

let subwayRouteLinesLayer: VectorLayer;
export const addSubwayRouteLinesMultiLayer = ({
  map,
  routeId,
  allRoutesSegmentMultiLineFeatures,
  useDarkMap,
}: Pick<LayerRenderingProps, 'routeId'> & {
  map: Map;
  allRoutesSegmentMultiLineFeatures: Feature[];
  useDarkMap: boolean;
  p?: number;
}) => {
  if (subwayRouteLinesLayer) {
    subwayRouteLinesLayer.setSource(
      new VectorSource({
        features: allRoutesSegmentMultiLineFeatures,
      })
    );
    subwayRouteLinesLayer.setStyle(
      new Style({
        renderer: renderRouteSegments(routeId, useDarkMap),
      })
    );
  } else {
    subwayRouteLinesLayer = new VectorLayer({
      maxZoom: 14.999,
      renderBuffer: RENDER_BUFFER_ROUTES,
      source: new VectorSource({
        features: allRoutesSegmentMultiLineFeatures,
      }),
      style: new Style({
        renderer: renderRouteSegments(routeId, useDarkMap),
      }),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(subwayRouteLinesLayer);
  }
};
