import { createSelector } from 'reselect';
import { getMapStopListsByTimeFilterAndRoute, getMapTimeFilter } from './basic';
import { MapState } from '../../models/map';
import { TimeFilter } from '../../subway-data';
import { StopListsByRoute } from '../../subway-data/subway-types';

export const getMapStopListsByRoute = createSelector(
  getMapStopListsByTimeFilterAndRoute,
  getMapTimeFilter,
  (
    mapStopListsByTimeFilterAndRoute: MapState['stopListsByTimeFilterAndRoute'],
    mapTimeFilter: TimeFilter
  ): StopListsByRoute => {
    // Use the timeFilter-specific data; fallback to weekday patternGraph if current time is not loaded
    const currentTimePatternGraphStopLists =
      mapStopListsByTimeFilterAndRoute[mapTimeFilter];
    const fallbackPatternGraphStopLists = !currentTimePatternGraphStopLists
      ? mapStopListsByTimeFilterAndRoute.weekday
      : {};
    const stopListsByRoute = {
      ...fallbackPatternGraphStopLists,
      ...currentTimePatternGraphStopLists,
    } as StopListsByRoute;

    // We'll also use the default (non-PG) routes if they don't exist at all
    // This is needed because a stops length of 0 mean "the route uses pattern
    // graph, but it's unavailable"
    const mapStopListsDefault = mapStopListsByTimeFilterAndRoute.default;

    for (const routeId of Object.keys(mapStopListsDefault)) {
      if (!stopListsByRoute[routeId]?.length) {
        stopListsByRoute[routeId] = mapStopListsDefault[routeId];
      }
    }

    return stopListsByRoute;
  }
);

/**
 * The getMapStopListsByRoute selector creates the necessary fallbacks to lines that
 * are not running to set a default stop list to display the line in the map.
 *
 * This selector will return the data without the fallbacks to validate the lines
 * that are out of service — the lines with stopLists.length === 0
 */
export const getMapStopListsByRouteRaw = createSelector(
  getMapStopListsByTimeFilterAndRoute,
  getMapTimeFilter,
  (
    mapStopListsByTimeFilterAndRoute: MapState['stopListsByTimeFilterAndRoute'],
    mapTimeFilter: TimeFilter
  ): Partial<StopListsByRoute> =>
    mapStopListsByTimeFilterAndRoute[mapTimeFilter] || {}
);
