import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { TimeFilter } from '../subway-data';
import { AppDispatch, AppState } from '../models';
import TimeToggle from '../components/Controls/TimeToggle';
import { tablet, landscapePhone } from '../utils/theme';
import { getMapCurrentTime, getMapTimeFilter } from '../selectors/map/basic';
export const MenuTimeToggleContainer = styled.div`
  display: none;

  ${tablet(css`
    display: flex;
    margin-top: 0px;
  `)}

  ${landscapePhone(css`
    display: none;
  `)}
`;

interface MenuTimeToggleProps {
  currentTime: TimeFilter;
  isAwaitingServiceStatus: boolean;
  isOpen: boolean;
  setTimeFilter: (timeFilter: TimeFilter) => void;
  timeFilter: TimeFilter;
}
const MenuTimeToggle: React.FC<MenuTimeToggleProps> = ({
  currentTime,
  timeFilter,
  setTimeFilter,
  isOpen,
  isAwaitingServiceStatus,
}) => (
  <MenuTimeToggleContainer>
    <TimeToggle
      display="large"
      data-cy="timefilter-menu"
      currentTime={currentTime}
      selectedTime={timeFilter}
      isOpen={isOpen}
      onToggle={timeFilter => {
        if (timeFilter) {
          setTimeFilter(timeFilter);
        }
      }}
      isAwaitingServiceStatus={isAwaitingServiceStatus}
    />
  </MenuTimeToggleContainer>
);

const mapStateToProps = (state: AppState) => ({
  timeFilter: getMapTimeFilter(state),
  currentTime: getMapCurrentTime(state),
  isOpen: state.ui.routeMenuOpened,
  isAwaitingServiceStatus: state.ui.isAwaitingServiceStatus,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setTimeFilter: dispatch.map.setTimeFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuTimeToggle);
