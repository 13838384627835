import { createSelector } from 'reselect';
import {
  getMapServiceStatus,
  getMapSelectedRouteId,
  getMapTimeFilter,
} from './basic';
import { getMapStopListsByRoute } from './getMapStopListsByRoute';

/* 
import {
  listUnidirectionalServices,
  servicesIntoReadableString,
} from '../../utils/unidirectionalServices';

import { RouteStatusDetail } from '../../subway-data/subway-types';
*/

import { RouteStatus } from '../../subway-data/subway-types';

export const getMapRouteStatus = createSelector(
  getMapServiceStatus,
  getMapStopListsByRoute,
  getMapSelectedRouteId,
  getMapTimeFilter,
  (serviceStatusByRoute, stopListsByRoute, selectedRouteId, timeFilter) => {
    const routeStatus: RouteStatus = serviceStatusByRoute[timeFilter][
      selectedRouteId
    ] || { routeId: selectedRouteId, lastUpdated: 0, statusDetails: [] };

    /*
    const routeUnidirectionalService = servicesIntoReadableString(
      listUnidirectionalServices(stopListsByRoute[selectedRouteId])
    );

    const tempStatusDetails: RouteStatusDetail = {
      statusSummary: 'Planned service changes',
      statusDescription:
        'For details on planned service changes, go to <a href="https://new.mta.info/planned-service-changes/subway">https://new.mta.info/planned-service-changes/subway</a>',
      statusLastUpdated: 'Last updated now',
    };
    */

    return {
      ...routeStatus,
      statusDetails: [...routeStatus?.statusDetails],
    };

    /*
    if (routeUnidirectionalService.length > 0) {
      const unidirectionalDetails: RouteStatusDetail = {
        statusSummary: 'This train is running in one direction',
        statusDescription: routeUnidirectionalService
          .map((s, index) => `${index > 0 ? '<br />' : ''}<p>${s}</p>`)
          .join(''),
        statusLastUpdated: 'Last updated now',
      };
      return {
        ...routeStatus,
        statusDetails: [
          ...routeStatus?.statusDetails,
          unidirectionalDetails,
          tempStatusDetails,
        ],
      };
    } else {
      return {
        ...routeStatus,
        statusDetails: [...routeStatus?.statusDetails],
      };
    }
    */
  }
);
