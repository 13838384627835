import React, { FC } from 'react';
import memoize from 'lodash/memoize';
import { SubwayRouteId, SubwayRouteIds } from '../subway-data/subway-types';
import {
  subwayRouteColors,
  subwayRouteInactiveColors,
  subwayRouteHasDarkText,
  subwayRouteLabelFromSubwayRouteId,
  RoutesUnavailable,
} from '../subway-data';
import { renderToStaticMarkup } from 'react-dom/server';
import theme from '../utils/theme';
import { Size } from 'ol/size';
import { getMapTheme } from './maps-theme';
import { subwayRouteHasGreyColor } from '../subway-data/subway-route-colors';

export const SubwayRouteIconSVG: FC<{ routeId: SubwayRouteId }> = ({
  routeId,
}) => {
  const circleFill = subwayRouteColors[routeId];
  const textFill = subwayRouteHasDarkText[routeId] ? 'black' : 'white';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      viewBox="0 0 100 100"
    >
      <circle cx="50%" cy="50%" r="50%" fill={circleFill} />
      <text
        fill={textFill}
        fontFamily={theme.fonts.default}
        fontWeight="bold"
        fontSize={70}
        dominantBaseline="middle"
        textAnchor="middle"
        x="50%"
        y="50%"
        dy="0.085em"
      >
        {routeId}
      </text>
    </svg>
  );
};

export const SubwayRouteIconG: FC<{
  routeId: SubwayRouteId;
  width?: number;
  inactive?: boolean;
  strokeWidth?: number;
  useDarkBorder?: boolean;
}> = ({ routeId, width = 100, inactive, strokeWidth, useDarkBorder }) => {
  const routeIdLabel = subwayRouteLabelFromSubwayRouteId(routeId);
  const circleFill = subwayRouteColors[routeId];
  const inactiveFill = subwayRouteInactiveColors[routeId];
  const textFill = subwayRouteHasDarkText[routeId] ? 'black' : 'white';
  const r = width / 2;
  const fontScale = routeIdLabel.length > 1 ? 0.7 : 1;
  // Because the inactive icon is lightened, use black (40% opacity)
  // except for grey routes (L, the shuttles) where white is needed for contrast
  const inactiveTextFill = subwayRouteHasGreyColor[routeId]
    ? 'white'
    : 'rgba(0, 0, 0, 0.4)';

  return (
    <>
      <circle
        cx={r}
        cy={r}
        r={r}
        fill={inactive ? inactiveFill : circleFill}
        stroke={useDarkBorder ? 'black' : 'white'}
        strokeWidth={strokeWidth}
        transform="translate(.5,.5)"
      />
      <text
        fill={inactive ? inactiveTextFill : textFill}
        fontFamily={theme.fonts.default}
        fontWeight="bold"
        fontSize={width * 0.7 * fontScale}
        dominantBaseline="middle"
        textAnchor="middle"
        x={r}
        y={r}
        dy="0.085em"
        transform="translate(.5,.5)"
      >
        {routeIdLabel}
      </text>
    </>
  );
};

export const AccessibilityIconG: FC<{
  isADAFilterActive?: boolean;
  useDarkMap?: boolean;
  width?: number;
}> = ({ isADAFilterActive, useDarkMap, width = 16 }) => {
  const colorsToUse = getMapTheme(useDarkMap).adaIcon;
  const fillColor = isADAFilterActive
    ? colorsToUse.foreground
    : colorsToUse.background;
  const pathColor = isADAFilterActive
    ? colorsToUse.background
    : colorsToUse.foreground;
  const outerStrokeColor = isADAFilterActive ? fillColor : colorsToUse.stroke;
  const r = width / 2;

  return (
    <>
      <circle
        cx={r}
        cy={r}
        fill={fillColor}
        r={r}
        stroke={outerStrokeColor}
        strokeWidth="1"
        transform="translate(.5,.5)"
      />
      <path
        d="M5.488 4.998l.003.923a2.405 2.405 0 00-1.086 2.678 2.41 2.41 0 002.956 1.693 2.417 2.417 0 001.586-1.348l.468.84a3.226 3.226 0 01-1.84 1.306 3.226 3.226 0 01-2.087-6.092zm.917-1.013c.317 0 .575.257.575.574l-.001.367h1.515a.471.471 0 010 .943H6.979v1.177h2.06c.217 0 .4.148.454.349a.234.234 0 01.02.03l.01.014.918 1.666a.472.472 0 01-.17.632.437.437 0 01-.599-.148l-.008-.015-.875-1.585h-2.5v-.011a.575.575 0 01-.459-.564V4.56c0-.317.257-.574.575-.574zm0-1.885c.444 0 .805.37.805.824 0 .456-.36.825-.805.825a.815.815 0 01-.805-.825c0-.455.36-.824.805-.824z"
        fill={pathColor}
        transform="translate(1.5,1.5)"
      />
    </>
  );
};

export const BigAccessibilityIconG: FC<{
  isADAFilterActive: boolean;
  useDarkMap?: boolean;
}> = ({ isADAFilterActive, useDarkMap }) => {
  const colorsToUse = getMapTheme(useDarkMap).adaIcon;
  const fillColor = isADAFilterActive
    ? colorsToUse.foreground
    : colorsToUse.background;
  const pathColor = isADAFilterActive
    ? colorsToUse.background
    : colorsToUse.foreground;
  const outerStrokeColor = colorsToUse.stroke;

  return (
    <g transform="translate(1 1)" fill="none" fillRule="evenodd">
      <rect
        stroke={outerStrokeColor}
        strokeWidth="2"
        fill={fillColor}
        x="-.5"
        y="-.5"
        width="19"
        height="19"
        rx="9.5"
      />
      <path
        d="M7.054 6.427l.006 1.185a3.092 3.092 0 00-1.397 3.444 3.098 3.098 0 003.801 2.177 3.108 3.108 0 002.039-1.734l.601 1.08a4.147 4.147 0 01-2.365 1.68 4.148 4.148 0 01-2.686-7.832zm1.18-1.304a.74.74 0 01.74.74v.471h1.946a.606.606 0 110 1.212l-1.946-.001V9.06h2.647c.279 0 .514.189.584.445a.512.512 0 01.038.06l1.182 2.141a.607.607 0 01-.218.813.562.562 0 01-.782-.21L11.3 10.272H8.087v-.014a.74.74 0 01-.591-.724v-3.67a.74.74 0 01.739-.74zm0-2.423c.572 0 1.036.475 1.036 1.06 0 .586-.464 1.06-1.035 1.06A1.048 1.048 0 017.2 3.76c0-.585.463-1.06 1.035-1.06z"
        fill={pathColor}
      />
    </g>
  );
};

export const subwayRouteIconSVGDataString = ({
  routeId,
}: {
  routeId: SubwayRouteId;
}) =>
  'data:image/svg+xml;utf8,' +
  renderToStaticMarkup(<SubwayRouteIconSVG routeId={routeId} />);

interface SubwaySignSVGProps {
  title?: string;
  routeIds: SubwayRouteIds;
  routesUnavailable: RoutesUnavailable;
  showRoutes?: boolean;
  isADAStation?: boolean;
  isADAFilterActive?: boolean;
  iconWidth?: number;
  isLabelVisible?: boolean;
  useDarkMap?: boolean;
}

export const routeIconsSvgData = memoize(
  ({
    iconWidth = 16,
    isADAFilterActive,
    isADAStation,
    routeIds,
    routesUnavailable,
    useDarkMap,
  }: SubwaySignSVGProps): { imgSize: Size; src: string } => {
    const lineLimit = 7;
    const iconWidthPadded = iconWidth + 2;
    const totalItems = routeIds.length + (isADAStation ? 1 : 0);
    const iconLines = Math.ceil(totalItems / lineLimit);
    const width = iconWidthPadded * (iconLines > 1 ? lineLimit : totalItems);
    const height = iconWidthPadded * iconLines;

    if (!routeIds.length && !isADAStation) {
      return {
        imgSize: [0, 0],
        src: '',
      };
    }

    return {
      imgSize: [width, height],
      src:
        'data:image/svg+xml;base64,' +
        btoa(
          renderToStaticMarkup(
            <svg
              height={height}
              viewBox={`0 0 ${width} ${height}`}
              width={width}
              xmlns="http://www.w3.org/2000/svg"
            >
              {isADAStation && (
                <AccessibilityIconG
                  isADAFilterActive={isADAFilterActive}
                  useDarkMap={useDarkMap}
                />
              )}
              {routeIds &&
                routeIds.map((routeId, index) => {
                  const deemphasized = !!routesUnavailable[routeId];
                  const realIndex = index + (isADAStation ? 1 : 0);

                  const currentLine = Math.floor(realIndex / lineLimit);
                  const currentColumn = realIndex - lineLimit * currentLine;

                  return (
                    <g
                      key={routeId}
                      transform={`translate(${iconWidthPadded *
                        currentColumn}, ${iconWidthPadded * currentLine})`}
                    >
                      <SubwayRouteIconG
                        inactive={deemphasized}
                        routeId={routeId}
                        strokeWidth={1}
                        useDarkBorder={useDarkMap}
                        width={iconWidth}
                      />
                    </g>
                  );
                })}
            </svg>
          )
        ),
    };
  },
  ({
    iconWidth,
    isADAFilterActive,
    isADAStation,
    routeIds,
    routesUnavailable,
    useDarkMap,
  }: SubwaySignSVGProps) =>
    `${iconWidth}-${isADAFilterActive}-${isADAStation}-${routeIds}-${routeIds.map(
      routeId => routesUnavailable[routeId]
    )}-${useDarkMap}`
);

export const SelectedStationMarkerSVG = ({ fill }: { fill?: string }) => {
  return (
    <svg width="16" height="23" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter
          x="-60%"
          y="-300%"
          width="220%"
          height="700%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feGaussianBlur stdDeviation=".8" in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="translate(-1)" fill="none" fillRule="evenodd">
        <path
          d="M2.225 12.16l.039.058c.047.07.096.137.143.207l5.56 8a.868.868 0 001.423 0l5.553-7.986c.05-.074.105-.148.156-.224l.035-.051h.001a7.685 7.685 0 00-1.416-9.97 7.677 7.677 0 00-10.069 0 7.682 7.682 0 00-1.415 9.97l-.01-.004zM8.68 4.8a3.2 3.2 0 11-.001 6.402A3.2 3.2 0 018.68 4.8z"
          fill={fill || '#000'}
          fillRule="nonzero"
        />
        <ellipse
          fill={fill || '#000'}
          fillRule="nonzero"
          filter="url(#a)"
          cx="8.6"
          cy="21.2"
          rx="2"
          ry="1"
        />
      </g>
    </svg>
  );
};

export const selectedStationMarkerSVGDataString = (fill?: string) =>
  'data:image/svg+xml;base64,' +
  btoa(renderToStaticMarkup(<SelectedStationMarkerSVG fill={fill} />));

export const airportIconSvg = memoize(
  (iconWidth: number, iconColor?: string) => {
    return (
      'data:image/svg+xml;base64,' +
      btoa(
        renderToStaticMarkup(
          <svg
            height={iconWidth}
            viewBox={`0 0 ${iconWidth} ${iconWidth}`}
            width={iconWidth}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.274.308c.948-.939 2.373.479 1.4 1.444l-3.968 3.946 2.216 8.844L12.457 16l-3.71-7.308-2.965 2.95.28 2.266-1.16 1.153-1.358-2.571-2.587-1.352 1.16-1.153 2.265.265 2.967-2.949L0 3.613l1.466-1.457L10.28 4.28 14.274.308z"
              fillRule="evenodd"
              fill={iconColor || '#000'}
            />
          </svg>
        )
      )
    );
  },
  (iconWidth: number, iconColor?: string) => {
    return `${iconWidth}-${iconColor}`;
  }
);

export const subwayEntranceIconSVGDataString = memoize(
  (backgroundColor: string = '#000', foregroundColor: string = '#fff') => {
    return (
      'data:image/svg+xml;base64,' +
      btoa(
        renderToStaticMarkup(
          <svg
            height="25"
            viewBox={`0 0 25 25`}
            width="25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path id="Rectangle" fill={backgroundColor} d="M0 0h25v25H0z" />
            <path
              id="Path"
              fill={foregroundColor}
              fillRule="nonzero"
              d="M6.9 12l-2.4 2.5 8 8 8-8L18 12l-3.8 3.8V5.5h-3.4v10.3z"
            />
            <path
              id="Rectangle_1_"
              fill={foregroundColor}
              fillRule="nonzero"
              d="M1 2h23v1H1z"
            />
          </svg>
        )
      )
    );
  }
);

export const vaccineIconSVGDataString = memoize(
  (
    backgroundColor: string = '#000',
    foregroundColor: string = '#fff',
    iconWidth: number = 18
  ) => {
    return (
      'data:image/svg+xml;base64,' +
      btoa(
        renderToStaticMarkup(
          <svg
            height={iconWidth}
            viewBox={`0 0 ${iconWidth} ${iconWidth}`}
            width={iconWidth}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 17.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
              fill={backgroundColor}
              stroke={foregroundColor}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.636 4.07l1.768 1.768-.566.566-.6-.6-1.132 1.13.955.955-4.243 4.243-1.5-.41-1.046 1.046a.4.4 0 01-.565-.566l1.045-1.045-.409-1.5 4.243-4.243.954.955 1.132-1.132-.601-.6.565-.566zM9.374 9.446L8.03 8.1l1.556-1.555 1.343 1.343-1.555 1.556z"
              fill={foregroundColor}
            />
          </svg>
        )
      )
    );
  },
  (backgroundColor: string, foregroundColor: string, iconWidth: number) => {
    return `${iconWidth}-${backgroundColor}-${foregroundColor}`;
  }
);

export const vaccineIconPinSVGDataString = memoize(
  (
    backgroundColor: string = '#000',
    foregroundColor: string = '#fff',
    iconWidth: number = 38
  ) => {
    return (
      'data:image/svg+xml;base64,' +
      btoa(
        renderToStaticMarkup(
          <svg
            // original height/width proportion 24/16
            height={iconWidth * 1.5}
            viewBox={`0 0 ${iconWidth} ${iconWidth}`}
            width={iconWidth}
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity=".2" filter="url(#filter0_f)">
              <path
                d="M9 23c1.105 0 2-.448 2-1s-.895-1-2-1-2 .448-2 1 .895 1 2 1z"
                fill={backgroundColor}
              />
            </g>
            <path
              d="M9.214 21.27c1.677-1.34 5.124-4.312 6.996-7.765a8.5 8.5 0 10-15.113-1.37c1.471 4.025 5.306 7.618 7.187 9.133a.74.74 0 00.93.002z"
              fill={backgroundColor}
              stroke={foregroundColor}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.636 4.07l1.768 1.768-.566.566-.6-.6-1.132 1.13.955.955-4.243 4.243-1.5-.41-1.046 1.046a.4.4 0 01-.565-.566l1.045-1.045-.409-1.5 4.243-4.243.954.955 1.132-1.132-.601-.6.565-.566zM9.374 9.446L8.03 8.1l1.556-1.555 1.343 1.343-1.555 1.556z"
              fill={foregroundColor}
            />
            <defs>
              <filter
                id="filter0_f"
                x="5.4"
                y="19.4"
                width="7.2"
                height="5.2"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation=".8"
                  result="effect1_foregroundBlur"
                />
              </filter>
            </defs>
          </svg>
        )
      )
    );
  },
  (backgroundColor: string, foregroundColor: string, iconWidth: number) => {
    return `${iconWidth}-${backgroundColor}-${foregroundColor}`;
  }
);
