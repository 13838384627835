import { Feature, Map } from 'ol';
import { Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import {
  getRadiiAtLineIndex,
  drawRoundSingleLineForPoints,
} from '../../geometry/paths';
import { Point } from '../../geometry/geometry-types';

import {
  getAirportsLineWidth,
  getLineDashValue,
} from '../airports-openlayers-graphics';
import { RENDER_BUFFER_ROUTES, STATION_SPACING } from '../maps-constants';
import { getMapTheme } from '../maps-theme';
import { getOlMapZoom } from '../maps-utils';
import { getRadiusBase } from './utils/getRadiusBase';

const getStyle = (useDarkMap: boolean) =>
  new Style({
    renderer: (coordinates, state) => {
      const { context, feature, pixelRatio, resolution } = state;
      const currentZoom = getOlMapZoom(resolution);
      const maxIndex = feature.get('maxIndex') as number;
      const segmentsNormalAnglesDegrees = feature.get(
        'segmentsNormalAnglesDegrees'
      ) as number[];
      const showAsActive = feature.get('showAsActive') as boolean;
      const showAsTransfer = feature.get('showAsTransfer') as boolean;
      const useDash = feature.get('useDash') as boolean;
      const colorsToUse = getMapTheme(useDarkMap);
      let lineWidthPerZoom =
        getAirportsLineWidth(currentZoom, useDash) * pixelRatio;

      context.beginPath();
      context.lineCap = 'round';

      if (showAsTransfer) {
        context.strokeStyle = colorsToUse.transferLines;
        context.lineWidth = lineWidthPerZoom / resolution / 4;
      } else {
        context.strokeStyle = showAsActive
          ? colorsToUse.airportLines
          : colorsToUse.lines.notSelected;
        context.lineWidth = lineWidthPerZoom / resolution;
      }

      if (useDash) {
        context.setLineDash(getLineDashValue(lineWidthPerZoom, resolution));
      }

      const radiusBase = getRadiusBase(resolution);
      const radii = getRadiiAtLineIndex({
        points: coordinates as Point[],
        radius: radiusBase,
        spacing: (STATION_SPACING * pixelRatio) / resolution,
        lineIndex: maxIndex,
        maxIndex,
        segmentsNormalAnglesDegrees,
      });

      drawRoundSingleLineForPoints(coordinates as Point[], radii, context);

      context.stroke();
    },
  });

let airportRouteLinesLayer: VectorLayer;
export const addAirportRouteLinesLayer = ({
  airportsRouteStationsFeatures,
  map,
  useDarkMap,
}: {
  airportsRouteStationsFeatures: Feature[];
  map: Map;
  useDarkMap: boolean;
}) => {
  if (airportRouteLinesLayer) {
    airportRouteLinesLayer.setSource(
      new VectorSource({
        features: airportsRouteStationsFeatures,
      })
    );
    airportRouteLinesLayer.setStyle(getStyle(useDarkMap));
  } else {
    airportRouteLinesLayer = new VectorLayer({
      renderBuffer: RENDER_BUFFER_ROUTES,
      source: new VectorSource({
        features: airportsRouteStationsFeatures,
      }),
      style: getStyle(useDarkMap),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(airportRouteLinesLayer);
  }
};
