import React, { MouseEventHandler, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { Station, SubwayRouteId } from '../../subway-data/subway-types';
import { TrainStatusByStopId } from '../../subway-data/station-status-types';
import CloseButton from '../Controls/CloseButton';
import Icon, { IconTypes } from '../Icon';
import LoadingIndicator from '../LoadingIndicator';
import { isPhone } from '../../utils/deviceDetector.utils';
import {
  landscapePhone,
  tablet,
  desktopExtraLarge,
  desktopLarge,
} from '../../utils/theme';
import { IconContainer } from '../Icon';
import { StationStatusRoutesIcons } from './StationStatusRoutesIcons';
import {
  StationStatusTrainsTable,
  StationStatusServiceTable,
} from './StationStatusTrainsTable';
import {
  RoutesUnavailable,
  TimeFilter,
  RoutesUnidirectional,
  RoutesWithSituation,
} from '../../subway-data';
import Services from './Services';
import { ElevatorEscalatorStatus } from '../../subway-data/elevator-escalator-status-types';
import NotRunning from '../NotRunning';
import {
  notRunningAtStationServiceMessage,
  noServiceaAtStationMessage,
} from '../ui-constants';
import { title, label, labelSmall } from '../Text';
import { timeLabels } from '../../utils/date.utils';

const Wrapper = styled.div`
  background-color: ${p => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-top: 30px;
  padding: 0 calc(env(safe-area-inset-right) + 30px) 0
    calc(env(safe-area-inset-right) + 30px);
  will-change: transform;

  ${landscapePhone(css`
    flex-direction: row;
    justify-content: space-between;
    max-height: calc(100vh - 28px);
    margin-top: 0;
    padding: 0 calc(env(safe-area-inset-left) + 74px) 0
      calc(env(safe-area-inset-right) + 74px);
  `)}

  ${tablet(css`
    flex-direction: column;
    margin-top: 0;
    overflow-y: unset;
    padding: 0 0 0 24px;
  `)}

  ${desktopExtraLarge(css`
    margin-top: 0;
    padding: 0 0 0 30px;
  `)}
`;

const Header = styled.div`
  background: ${p => p.theme.colors.white};
  padding-bottom: 64px;
  position: sticky;
  transform: translate3d(0, 0, 0);
  top: 0;
  z-index: 10;

  ${landscapePhone(css`
    flex-basis: 316px;
    padding-top: 12px;
  `)}

  ${tablet(css`
    padding: 20px 24px 40px 0;
  `)}

  ${desktopExtraLarge(css`
    padding: 28px 30px 50px 0;
  `)}
`;

const HeaderClose = styled.div`
  margin: 0 -7px 13px 0;
  text-align: right;
`;

const HeaderTitleWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

const HeaderTitle = styled.h2`
  ${title};
  margin: 0 0 8px;
`;

const HeaderAdaIcon = styled(IconContainer)`
  flex-shrink: 0;
  margin-left: 20px;
  margin-right: -7px;

  ${tablet(css`
    height: 28px;
    margin-right: -4px;
    width: 28px;
  `)}

  ${desktopExtraLarge(css`
    height: 30px;
    margin-right: -7px;
    width: 30px;
  `)}
`;

const HeaderRoutesIcons = styled.div`
  ${desktopLarge(css`
    height: 16px;
  `)};

  ${desktopExtraLarge(css`
    height: 20px;
  `)};

  svg {
    ${tablet(css`
      transform: scale(0.6666);
    `)}

    ${desktopExtraLarge(css`
      transform: scale(0.8333);
    `)}
  }
`;

const ServiceAlert = styled.div`
  border-top: 1px solid ${p => p.theme.colors.lightGray};
  padding-top: 32px;

  ${tablet(css`
    margin-right: 24px;
    padding: 20px 0 40px;
  `)};

  ${desktopExtraLarge(css`
    margin-right: 30px;
    padding: 28px 0 50px;
  `)}
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${tablet(css`
    padding-right: 24px;
  `)};

  ${desktopExtraLarge(css`
    padding-right: 30px;
  `)}

  ${landscapePhone(css`
    flex-basis: 316px;
    height: auto;
  `)}
`;

const ListHeader = styled.div<{ top: number | undefined }>`
  align-items: center;
  background: ${p => p.theme.colors.white};
  border-bottom: 1px solid ${p => p.theme.colors.lightGray};
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding-bottom: 16px;
  position: sticky;
  transform: translate3d(0, 0, 0);
  top: ${p => (p.top ? p.top : 0)}px;
  z-index: 10;

  ${tablet(css`
    padding-bottom: 8px;
  `)}
`;

const ListFooter = styled.div`
  border-top: 1px solid ${p => p.theme.colors.lightGray};
  margin-bottom: ${p =>
    `calc(env(safe-area-inset-bottom) + ${p.theme.marginBottomExtra})`};

  ${tablet(css`
    margin-bottom: 30px;
    padding-top: 15px;
  `)};
`;

export const ListHeaderTitle = styled.span`
  ${label};
  vertical-align: baseline;
`;

const UpdateLabel = styled.p`
  ${labelSmall};
  margin-top: 15px;

  ${tablet(css`
    margin-top: 0;
  `)};
`;

const ToggleButton = styled.button`
  ${labelSmall};

  color: ${p => p.theme.colors.blue};
  cursor: pointer;
  display: flex;
  margin-top: 3px;
`;

function buildTimeFilterTitle(currentTime: TimeFilter, timeFilter: TimeFilter) {
  const filterLabel = timeLabels[currentTime][timeFilter];
  return `${filterLabel.label} - ${filterLabel.timeframe.replace('-', 'to')}`;
}

function useAutoRefresh(startTimer: boolean, onAutoRefresh: () => void) {
  const refreshIntervalRef = useRef<number | undefined>();
  const refreshIntervalSeconds = 30;

  useEffect(() => {
    if (startTimer) {
      // we do the first call and start the timer
      onAutoRefresh();

      refreshIntervalRef.current = setInterval(
        onAutoRefresh,
        1000 * refreshIntervalSeconds
      );
    }

    return () => {
      clearInterval(refreshIntervalRef.current);
    };
  }, [onAutoRefresh, startTimer]);
}

export interface StationStatusProps {
  currentTime: TimeFilter;
  equipmentOutageListings: ElevatorEscalatorStatus;
  onAutoRefresh: () => void;
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  resetTimeFilter?: () => void;
  routesUnavailableForStation: RoutesUnavailable;
  routesWithSituation: RoutesWithSituation;
  unidirectionalRoutesForStation: RoutesUnidirectional;
  setSelectedRouteId: (routeId: SubwayRouteId | '') => void;
  selectedStation: Station | null;
  timeFilter: TimeFilter;
  trainStatusByStopId: TrainStatusByStopId;
  useTimers: boolean;
}
const StationStatus: React.FC<StationStatusProps> = ({
  currentTime,
  equipmentOutageListings,
  onCloseClick,
  onAutoRefresh,
  routesUnavailableForStation,
  routesWithSituation,
  selectedStation,
  setSelectedRouteId,
  trainStatusByStopId,
  unidirectionalRoutesForStation,
  useTimers,
  timeFilter,
  resetTimeFilter,
}) => {
  const listHeaderRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  useAutoRefresh(useTimers, onAutoRefresh);

  if (!selectedStation) return null;

  const { stopId, stopName, ada, transfers } = selectedStation;
  const hasServices = equipmentOutageListings?.outages[stopId]?.length >= 1;
  const hasTimeFilter = timeFilter !== currentTime;
  const noUpcomingTrains = trainStatusByStopId[stopId]?.trains?.length === 0;
  const noServiceAtStation = transfers.every(
    t => routesUnavailableForStation[t]
  );
  return (
    <Wrapper>
      <Header ref={headerRef}>
        {!isPhone() && (
          <HeaderClose>
            <CloseButton onClick={onCloseClick} />
          </HeaderClose>
        )}
        <HeaderTitleWrapper>
          <HeaderTitle>{stopName}</HeaderTitle>
          {ada && (
            <HeaderAdaIcon>
              <Icon type={IconTypes.Accessibility} />
            </HeaderAdaIcon>
          )}
        </HeaderTitleWrapper>

        <HeaderRoutesIcons>
          <StationStatusRoutesIcons
            routeIds={transfers}
            routesUnavailableForStation={routesUnavailableForStation}
            width={24}
            widthPadded={26}
          />
        </HeaderRoutesIcons>
      </Header>
      {(noUpcomingTrains || noServiceAtStation) && !hasTimeFilter ? (
        <ServiceAlert>
          <NotRunning
            notRunningServiceMessage={
              noUpcomingTrains
                ? notRunningAtStationServiceMessage
                : noServiceaAtStationMessage
            }
          />
        </ServiceAlert>
      ) : (
        <List>
          {hasServices && !hasTimeFilter && (
            <Services
              isVisible={hasServices}
              equipmentOutageListings={equipmentOutageListings.outages[stopId]}
              equipmentTotal={
                equipmentOutageListings.equipmentTotalsByStopId[stopId]
              }
              mobileScrollRef={headerRef}
              top={headerRef.current?.getBoundingClientRect().height}
            />
          )}
          <ListHeader
            ref={listHeaderRef}
            top={headerRef.current?.getBoundingClientRect().height}
          >
            <ListHeaderTitle>
              {hasTimeFilter
                ? buildTimeFilterTitle(currentTime, timeFilter)
                : 'Next Trains'}
            </ListHeaderTitle>
          </ListHeader>
          {hasTimeFilter ? (
            <>
              <StationStatusServiceTable
                routesWithSituation={routesWithSituation}
                setSelectedRouteId={setSelectedRouteId}
                transfers={transfers}
                unavailableRoutes={routesUnavailableForStation}
                unidirectionalRoutes={unidirectionalRoutesForStation}
              />
              <ListFooter>
                <UpdateLabel>Updated now</UpdateLabel>
                <ToggleButton onClick={resetTimeFilter}>
                  Back to live map
                </ToggleButton>
              </ListFooter>
            </>
          ) : (
            <>
              {!trainStatusByStopId[stopId] ? (
                <LoadingIndicator />
              ) : (
                <StationStatusTrainsTable
                  upcomingTrainTimes={trainStatusByStopId[stopId].trains}
                />
              )}
            </>
          )}
        </List>
      )}
    </Wrapper>
  );
};

export default StationStatus;
