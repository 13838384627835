import round from 'lodash/round';
import { Feature, Map } from 'ol';
import { Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import theme from '../../utils/theme';

import { RENDER_BUFFER_ICONS } from '../maps-constants';
import { getMapTheme } from '../maps-theme';
import { ZoomLevel } from '../map-types';
import { getOlMapZoom, getProportionalValueForZoom } from '../maps-utils';
import { airportIconSvg } from '../subway-sign-svg';

// We create the icon once to use it on the addAirportLabelsLayer function
const airportIconImgElementLight = document.createElement('img');
const airportIconImgElementLightHover = document.createElement('img');
const airportIconImgElementDark = document.createElement('img');
const airportIconImgElementDarkHover = document.createElement('img');

const darkLabelTheme = getMapTheme(true).labels;
const lightLabelTheme = getMapTheme(false).labels;

airportIconImgElementLight.src = airportIconSvg(16, lightLabelTheme.active);
airportIconImgElementLightHover.src = airportIconSvg(16, lightLabelTheme.hover);
airportIconImgElementDark.src = airportIconSvg(16, darkLabelTheme.active);
airportIconImgElementDarkHover.src = airportIconSvg(16, darkLabelTheme.hover);

const getAirportLabelStyle = ({
  useDarkMap,
  useHover = false,
}: {
  useDarkMap: boolean;
  useHover?: boolean;
}) =>
  new Style({
    renderer: (coordinates, state) => {
      const { context, feature, pixelRatio, resolution } = state;
      const currentZoom = getOlMapZoom(resolution);
      const fontSize = round(
        getProportionalValueForZoom(currentZoom, {
          [ZoomLevel.z13]: 13,
          [ZoomLevel.z14]: 17,
          [ZoomLevel.z15]: 17,
          [ZoomLevel.z16]: 24,
          [ZoomLevel.z17]: 30,
        }),
        2
      );
      const iconWidth = round(
        getProportionalValueForZoom(currentZoom, {
          [ZoomLevel.z11]: 8,
          [ZoomLevel.z12]: 12,
          [ZoomLevel.z13]: 12,
          [ZoomLevel.z14]: 16,
          [ZoomLevel.z14]: 24,
          [ZoomLevel.z16]: 36,
        }),
        2
      );
      const text =
        currentZoom >= ZoomLevel.z13
          ? currentZoom >= ZoomLevel.z16
            ? (feature.get('name') as string)
            : (feature.get('nameShort') as string)
          : '';
      const [initialX, initialY] = coordinates as number[];

      // We need to define the font size to calculate the text
      // width and get the center alignment of the block text + icon.
      context.font = `bold ${fontSize * pixelRatio}px ${theme.fonts.default}`;

      const textWidth = round(context.measureText(text).width, 2);
      const iconWidthForRatio = iconWidth * pixelRatio;
      const iconWidthPaddedForRatio = iconWidthForRatio * 1.2;

      const iconOffsetX = (textWidth + iconWidthPaddedForRatio) / 2;
      const textOffsetX = iconOffsetX - iconWidthPaddedForRatio;
      const colorsToUse = getMapTheme(useDarkMap).labels;

      let airportIcon = useDarkMap
        ? airportIconImgElementDark
        : airportIconImgElementLight;

      if (useHover) {
        airportIcon = useDarkMap
          ? airportIconImgElementDarkHover
          : airportIconImgElementLightHover;
      }

      context.fillStyle = useHover ? colorsToUse.hover : colorsToUse.active;
      context.fillText(
        text,
        initialX - textOffsetX,
        initialY + fontSize * 0.35 * pixelRatio
      );

      context.drawImage(
        airportIcon,
        initialX - iconOffsetX,
        initialY - iconWidthForRatio / 2,
        iconWidthForRatio,
        iconWidthForRatio
      );
    },
  });

// @ts-ignore
let airportLabelsLayer: VectorLayer;
export const addAirportLabelsLayer = ({
  airportsLabelsFeatures,
  map,
  useDarkMap,
}: {
  airportsLabelsFeatures: Feature[];
  map: Map;
  useDarkMap: boolean;
}) => {
  const style = getAirportLabelStyle({ useDarkMap });

  if (airportLabelsLayer) {
    airportLabelsLayer.setSource(
      new VectorSource({
        features: airportsLabelsFeatures,
      })
    );
    airportLabelsLayer.setStyle(style);
  } else {
    airportLabelsLayer = new VectorLayer({
      className: 'airport-labels',
      renderBuffer: RENDER_BUFFER_ICONS,
      source: new VectorSource({
        features: airportsLabelsFeatures,
      }),
      style,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(airportLabelsLayer);
  }
};

// The map does not detect the label because we use canvas to draw it.
// As a workaround, we detect the hover on an airport terminal and find
// the terminal label near it.
let currentTerminalHoveredFeature: Feature | undefined;
let currentLabelHoveredFeatureWithStyle: Feature | undefined;
export const setAirportLabelFeatureHoverStyle = (
  useDarkMap: boolean,
  terminalFeature?: Feature
) => {
  const terminalId = terminalFeature?.get('terminalId') as String;

  // If the new feature is not an airport label or is different
  // from the previous label, we clean the last one.
  if (!terminalId || terminalFeature !== currentTerminalHoveredFeature) {
    currentLabelHoveredFeatureWithStyle?.setStyle(undefined);
    currentLabelHoveredFeatureWithStyle = undefined;
    currentTerminalHoveredFeature = undefined;
  }

  if (terminalId && !currentTerminalHoveredFeature) {
    currentTerminalHoveredFeature = terminalFeature;
    currentLabelHoveredFeatureWithStyle = airportLabelsLayer
      .getSource()
      .getFeatures()
      .find(f => f.get('terminalId') === terminalId);

    currentLabelHoveredFeatureWithStyle?.setStyle(
      getAirportLabelStyle({ useDarkMap, useHover: true })
    );
  }
};
