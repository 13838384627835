import { Stop, StopBase, StopList, StopLists } from '../subway-types';

const findIndexOfStopOnReferences = (
  stop: StopBase | undefined,
  references: StopList,
  startIndex: number = 0
) => {
  if (stop) {
    let referencesList: StopList = references;

    if (startIndex) {
      referencesList = references.slice(startIndex, references.length);
    }

    return (
      referencesList.findIndex(
        referenceStop =>
          referenceStop.stopId === stop.stopId &&
          !referenceStop.isDuplicated &&
          !referenceStop.isRerouting
      ) + startIndex
    );
  }

  return -1;
};

export const addMissingBaseStops = (
  stopLists: StopLists,
  references: StopList
): StopLists => {
  // if the stopLists is empty, the route is unavailable
  // We return it empty to handle unavailable lines later
  if (!stopLists.length) {
    return stopLists;
  }

  let referencesNotInPG = [...references];

  // Create a list with the base stops that are not present in the PG data
  stopLists.forEach(stopList => {
    referencesNotInPG = referencesNotInPG.filter(
      reference => !stopList.find(stop => stop.stopId === reference.stopId)
    );
  });

  // The reference list can have some duplicated stops to create branch forks
  // or rerouting stops to cover unexpected scenarios. We don't need any of
  // them as missing stops to make the base path of the line.
  referencesNotInPG = referencesNotInPG.filter(
    reference => !reference.isDuplicated && !reference.isRerouting
  );

  // If we have a list of stops that are not in the PG data, we need to
  // convert the list into lists of sequential stops. We can have stops
  // from the beginning, end, or a branch not running.We need to go
  // through them, grouping by the order they are in the references.
  if (referencesNotInPG.length) {
    const lastIndexOfReferencesNotInPG = referencesNotInPG.length - 1;
    const missingBaseStopLists: Stop[][] = [];
    let currentNotInPGStopList: Stop[] = [];
    let currentNotInPGStopListFirstReferenceIndex: number | undefined;

    referencesNotInPG.forEach((referenceNotInPG, index) => {
      const currentReferenceIndex = findIndexOfStopOnReferences(
        referenceNotInPG,
        references,
        currentNotInPGStopListFirstReferenceIndex
      );
      const nextReferenceIndex = findIndexOfStopOnReferences(
        referencesNotInPG[index + 1],
        references,
        currentNotInPGStopListFirstReferenceIndex
      );

      // We add it to the current sub list of grouped stops.
      currentNotInPGStopList.push({
        ...referenceNotInPG,
        // We force the current not used stop to be unavailable and not
        // be a terminal because the valid terminals are the ones from PG.
        isTerminal: false,
        unavailable: true,
      });

      // If the current not in PG stopList has one item, we set the index
      // of the first no in PG element of the list to be the current
      // reference index.
      if (currentNotInPGStopList.length === 1) {
        currentNotInPGStopListFirstReferenceIndex = currentReferenceIndex;
      }

      // If the next unused stop reference index is not right after the
      // current one, it means that we are starting a new group of stops
      // after this stop.
      if (
        nextReferenceIndex !== currentReferenceIndex + 1 ||
        index === lastIndexOfReferencesNotInPG
      ) {
        // When we remove the used stops, we can have visually unconnected
        // base stops lists.We draw the segments based on the stopLists order;
        // because of that, we need to add back a stop to the beginning or
        // end of the current group.
        const referenceIndexBeforeStart =
          findIndexOfStopOnReferences(
            currentNotInPGStopList[0],
            references,
            currentNotInPGStopListFirstReferenceIndex
          ) - 1;

        if (referenceIndexBeforeStart > -1) {
          const validReferenceBeforeStart =
            references[referenceIndexBeforeStart];

          if (
            validReferenceBeforeStart &&
            !validReferenceBeforeStart.isDuplicated &&
            !validReferenceBeforeStart.isRerouting
          ) {
            currentNotInPGStopList.unshift({
              ...validReferenceBeforeStart,
              isTerminal: false,
              unavailable: true,
            });
          }
        }

        const validReferenceAfterEnd = references[currentReferenceIndex + 1];

        if (
          validReferenceAfterEnd &&
          !validReferenceAfterEnd.isDuplicated &&
          !validReferenceAfterEnd.isRerouting
        ) {
          currentNotInPGStopList.push({
            ...validReferenceAfterEnd,
            isTerminal: false,
            unavailable: true,
          });
        }

        // We push the current missing stopList to the main missing list
        // and start a new one.
        missingBaseStopLists.push(currentNotInPGStopList);
        currentNotInPGStopList = [];

        // We force the next loop to start looking for the missing index
        // reference after the index of the last not in PG element of the
        // previous list.
        currentNotInPGStopListFirstReferenceIndex = currentReferenceIndex + 1;
      }
    });

    return [...missingBaseStopLists, ...stopLists];
  }

  return stopLists;
};
