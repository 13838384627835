import { Feature, Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import {
  DOT_HOVERABLE_FEATURE_LABEL_CLASS_NAME,
  SELECTABLE_FEATURE_LABEL_CLASS_NAME,
} from '../maps-constants';
import { LayerRenderingProps } from '../subway-openlayers-graphics';

let subwayLineDotLettersLayer: VectorLayer;
export const addSubwayLineDotLettersLayer = ({
  allFeaturesForAllRoutes,
  map,
  routeId,
  soloFeaturesForCurrentRoute,
  stationStyleFunction,
}: Pick<LayerRenderingProps, 'routeId'> & {
  allFeaturesForAllRoutes: Feature[];
  map: Map;
  soloFeaturesForCurrentRoute: Feature[];
  stationStyleFunction;
}) => {
  const features = routeId
    ? soloFeaturesForCurrentRoute
    : allFeaturesForAllRoutes;

  if (subwayLineDotLettersLayer) {
    subwayLineDotLettersLayer.setSource(
      new VectorSource({
        features,
      })
    );
    subwayLineDotLettersLayer.setStyle(stationStyleFunction);
  } else {
    subwayLineDotLettersLayer = new VectorLayer({
      className: `${SELECTABLE_FEATURE_LABEL_CLASS_NAME} ${DOT_HOVERABLE_FEATURE_LABEL_CLASS_NAME}`,
      source: new VectorSource({
        features,
      }),
      style: stationStyleFunction,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(subwayLineDotLettersLayer);
  }
};
