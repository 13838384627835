import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  RouteStatus,
  RouteStatusDetail,
  SubwayRouteId,
} from '../subway-data/subway-types';
import { tablet, notTouch, desktopExtraLarge } from '../utils/theme';
import NotRunning from './NotRunning';
import {
  lineNotRunningServiceMessage,
  noScheduledWorkServiceMessage,
} from './ui-constants';
import {
  cleanStatusDescription,
  withSubwayRouteIcons,
} from '../utils/parse.utils';
import Icon, { IconTypes } from './Icon';
import { isPhone } from '../utils/deviceDetector.utils';
import { content, subTitle, labelSmall } from './Text';

export const MessageWrapper = styled.div`
  padding: 20px 30px 48px;

  ${tablet(css`
    border-top: 1px solid ${p => p.theme.colors.gray};
    padding: 20px 0;
  `)}
`;

export const ServiceStatusTrigger = styled.button<{ collapseTitle?: boolean }>`
  align-items: center;
  border: 0;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${p => (p.collapseTitle ? '0 0 30px' : '30px 0')};
  width: 100%;
  transition: padding-top 500ms;

  ${tablet(css`
    padding: 14px 0;
  `)};

  ${desktopExtraLarge(css`
    padding: 20px 0;
  `)};
`;

const ServiceStatusIcon = styled.span<{ hidden: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 20px;
  width: 21px;
  transform: rotate(-180deg);
  transition: transform 0.2s linear;
  visibility: ${p => (p.hidden ? 'hidden' : 'initial')};

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
`;

export const ServiceStatusTitle = styled.h2`
  ${subTitle};
  margin: 0;
  max-width: 80%;
  text-align: initial;
  text-transform: capitalize;
`;

const ServiceStatusContentWrapper = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s linear, visibility 0.6s linear;
  visibility: hidden;

  /* Empty space to smooth animation behaviour,
  adding a margin/padding would affect the layout
  and make the animation jump around */
  > section {
    height: 14px;
    width: 100%;

    ${desktopExtraLarge(css`
      height: 20px;
    `)}
  }
`;

const ServiceStatusWrapper = styled.li<{ isActive: boolean }>`
  background-color: ${p => p.theme.colors.white};
  padding: 0 calc(env(safe-area-inset-left) + 28px);

  &:not(:first-of-type) {
    border-top: 1px solid ${p => p.theme.colors.gray};
  }

  ${tablet(css`
    border-top: 1px solid ${p => p.theme.colors.gray};
    padding: 0;
  `)};

  ${ServiceStatusIcon} {
    ${p =>
      p.isActive &&
      css`
        transform: rotate(0deg);
        transition: transform 0.6s linear;
      `}
  }

  ${ServiceStatusContentWrapper} {
    ${p =>
      p.isActive &&
      css`
        max-height: unset;
        overflow-y: auto;
        transition: max-height 0.6s linear;
        visibility: initial;
      `}
  }
`;

const ServiceStatusHeader = styled.div`
  ${subTitle}
  margin-bottom: 14px;
`;

const ServiceStatusCategory = styled.h3`
  ${labelSmall};
  margin: 0;
  margin-bottom: 14px;

  ${notTouch(css`
    margin-bottom: 8px;
  `)}
`;

const ServiceStatusTime = styled.h3`
  ${labelSmall};
  margin: 0;
  margin-bottom: 40px;

  ${notTouch(css`
    margin-bottom: 24px;
  `)}
`;

const ServiceStatusDescription = styled.div`
  ${content};

  /*
   * <font> tags in MTA generated HTML
   * contain a size attribute that impacts
   * our layout
   */
  font,
  a {
    ${content};
  }

  table {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 6px;

    tr:first-child {
      td {
        padding-bottom: 14px;
      }

      b {
        text-transform: uppercase !important;
        font-weight: 400 !important;
        color: #777777 !important;
      }
    }

    tr:not(:last-child) {
      border-bottom: 1px solid #d8d8d8;
    }

    tr:not(:first-child) {
      td {
        padding: 18px 0px 24px 0px;
      }
    }

    b {
      font-weight: 400 !important;
    }
  }

  hr {
    display: none;
  }

  td,
  tr {
    background-color: transparent !important;
  }

  /* Sometimes a table will be wrapped in a <div id="1234567"> with inline style "display:none;"
   because MTA's site hides the table until the user clicks a link to show it. */
  div[id][style] {
    display: block !important;
  }

  a {
    color: ${p => p.theme.colors.blue} !important;
    text-decoration: none;
    outline: none;

    font {
      color: ${p => p.theme.colors.blue};
    }

    &:hover {
      text-decoration: underline;
    }

    &:focus.focus-visible {
      border: 1px dashed ${p => p.theme.colors.black};
    }
  }
`;

const ServiceStatusTimestamp = styled.p`
  ${labelSmall};
  margin: 0;
  margin-top: 40px;

  ${notTouch(css`
    margin-top: 24px;
  `)}
`;

const ServiceStatusItem: React.FC<{
  hideToggleIcon: boolean;
  id: string;
  isActive: boolean;
  statusDetail: RouteStatusDetail;
  toggleActive: (id: string) => void;
  collapseTitle?: boolean;
  routeId: string;
}> = ({
  collapseTitle,
  hideToggleIcon,
  id,
  isActive,
  statusDetail,
  toggleActive,
  routeId,
}) => {
  const {
    statusHeader,
    statusCategory,
    statusLastUpdated,
    statusHumanReadable,
  } = statusDetail;
  const titleWithIcons = withSubwayRouteIcons(statusDetail.statusSummary);
  const cleanedDescription = cleanStatusDescription(
    statusDetail.statusDescription
  );
  const cleanedDescriptionWithIcons = withSubwayRouteIcons(cleanedDescription);

  const titleId = `title-${id}`;
  const regionId = `region-${id}`;

  return (
    <ServiceStatusWrapper isActive={isActive}>
      <ServiceStatusTrigger
        onClick={() => toggleActive(id)}
        aria-controls={regionId}
        collapseTitle={collapseTitle}
      >
        <ServiceStatusTitle
          id={titleId}
          dangerouslySetInnerHTML={{
            __html: titleWithIcons,
          }}
        />
        <ServiceStatusIcon aria-hidden hidden={hideToggleIcon}>
          <Icon type={IconTypes.Chevron} />
        </ServiceStatusIcon>
      </ServiceStatusTrigger>
      <ServiceStatusContentWrapper
        aria-expanded={isActive}
        aria-labelledby={titleId}
        id={regionId}
      >
        {statusCategory && (
          <ServiceStatusCategory>{statusCategory}</ServiceStatusCategory>
        )}
        {/** When the time description is empty (e.g. because the status is until further notice),
         remove the element to avoid extra whitespace. */}
        {statusHumanReadable && (
          <ServiceStatusTime>{statusHumanReadable}</ServiceStatusTime>
        )}
        {statusHeader && statusDetail.statusSummary !== statusHeader && (
          <ServiceStatusHeader
            dangerouslySetInnerHTML={{
              __html: withSubwayRouteIcons(statusHeader),
            }}
          />
        )}
        <ServiceStatusDescription
          dangerouslySetInnerHTML={{
            __html: cleanedDescriptionWithIcons,
          }}
        />
        {titleWithIcons.includes('Planned') && (
          <ServiceStatusDescription>
            <p style={{ marginTop: `14px` }}>
              <a
                href={`https://new.mta.info/alerts?selectedRoutes=MTASBWY:${routeId}`}
              >
                See all alerts
              </a>
            </p>
          </ServiceStatusDescription>
        )}
        {statusLastUpdated && (
          <ServiceStatusTimestamp>{statusLastUpdated}</ServiceStatusTimestamp>
        )}
        <section />
      </ServiceStatusContentWrapper>
    </ServiceStatusWrapper>
  );
};

export const ServiceStatusContentInner = styled.ul`
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
`;

const ServiceStatusContent: React.FC<{
  isOpen: boolean;
  isRouteUnavailable: boolean;
  isSmallPosition?: boolean;
  routeId: SubwayRouteId | '';
  status: RouteStatus;
}> = ({ isOpen, isRouteUnavailable, isSmallPosition, routeId, status }) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const statusRef = useRef<RouteStatus>(status);
  const [activeDetails, setActiveDetails] = useState<string>('');

  const toggleActiveDetails = useCallback(
    (id: string) => {
      const prevActive = activeDetails;
      if (!activeDetails) {
        setActiveDetails(id);
      } else {
        setActiveDetails('');
        setTimeout(() => {
          id !== prevActive && setActiveDetails(id);
        }, 600);
      }
    },
    [activeDetails, setActiveDetails]
  );

  useEffect(() => {
    if (status && status !== statusRef.current) {
      setActiveDetails('');
      statusRef.current = status;
    }
  }, [status]);

  useEffect(() => {
    if (!!isOpen) return;
    else setActiveDetails('');
  }, [isOpen, setActiveDetails]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const links = Array.from(container.querySelectorAll('a'));

    if (isOpen) {
      links.forEach(link => {
        link.setAttribute('tabindex', '0');
      });
    } else {
      links.forEach(link => {
        link.setAttribute('tabindex', '-1');
      });
    }
  }, [isOpen]);

  if (!routeId) return null;

  if (!status.statusDetails.length) {
    return (
      <MessageWrapper>
        <NotRunning
          notRunningServiceMessage={
            isRouteUnavailable
              ? lineNotRunningServiceMessage
              : noScheduledWorkServiceMessage
          }
        />
      </MessageWrapper>
    );
  } else {
    return (
      <ServiceStatusContentInner ref={containerRef}>
        {status.statusDetails.map((detail, index) => {
          const id =
            detail.statusId ||
            `missing-${Math.random()
              .toString(36)
              .substring(7)}`;
          const singleDetail = isPhone() && status.statusDetails.length === 1;

          return (
            <ServiceStatusItem
              isActive={id === activeDetails || singleDetail}
              hideToggleIcon={singleDetail}
              id={id}
              key={id}
              statusDetail={detail}
              toggleActive={toggleActiveDetails}
              collapseTitle={isSmallPosition && index === 0}
              routeId={routeId}
            />
          );
        })}
      </ServiceStatusContentInner>
    );
  }
};

export default ServiceStatusContent;
