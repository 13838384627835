import { createSelector } from 'reselect';
import { Feature } from 'ol';
import { Circle as OlCircle, Point as OlPoint, LineString } from 'ol/geom';
import { toRadians } from 'ol/math';
import { fromLonLat } from 'ol/proj';

import { Point } from '../../geometry/geometry-types';
import {
  fromPolar,
  parallelSpacingAtIndex,
  fromPolarDegrees,
} from '../../geometry/point-utils';

import { STATION_SPACING } from '../../maps/maps-constants';
import { getNormalAngleAndPointsForLinePoints } from '../../maps/maps-utils';
import { projectStation } from '../../maps/subway-openlayers-graphics';
import { MANHATTAN_DEGREES } from '../../subway-data';
import {
  airportsRouteStations,
  airportsTerminals,
} from '../../subway-data/airport-stations';
import {
  AirportRouteStation,
  HasRequiredSubwayStopIds,
  Stations,
  SubwayRouteId,
} from '../../subway-data/subway-types';
import { intersectsWithAvailableStopIds } from '../../utils/feature.utils';

import { getMapSelectedRouteId } from './basic';
import { getMapNormalAngleDegreesForAllStations } from './geometry';
import { getMapStations } from './getMapStations';
import { getMapAvailableStopIdsForSelectedRoute } from './getMapAvailableStopIds';

let mapAirportsLabelsFeaturesCache: Feature[];
export const getMapAirportsLabelsFeatures = (): Feature[] => {
  if (!mapAirportsLabelsFeaturesCache) {
    mapAirportsLabelsFeaturesCache = airportsTerminals.map(terminal => {
      const { center, name, nameShort } = terminal;

      return new Feature({
        geometry: new OlPoint(fromLonLat([center.lon, center.lat])),
        name: name,
        nameShort,
        terminalId: terminal.terminalId,
      });
    });
  }

  return mapAirportsLabelsFeaturesCache;
};

export const getMapAirportsRouteStationsFeatures = createSelector(
  getMapNormalAngleDegreesForAllStations,
  getMapStations,
  getMapSelectedRouteId,
  getMapAvailableStopIdsForSelectedRoute,
  (
    subwayStationsAngles: { [stopId: string]: number },
    subwayStations: Stations,
    selectedRouteId: SubwayRouteId | '',
    availableStopIdsForSelectedRoute: string[]
  ): Feature[] => {
    return airportsRouteStations.map(station => {
      let segmentsNormalAnglesDegrees: number[] = [];
      let points: Point[] = [
        getAirportStationPointFromRelatedSubwayStop({
          airportStation: station,
          selectedRouteId,
          subwayStations,
          subwayStationsAngles,
        }),
      ];

      if (station.nextStopId) {
        const nextStation = airportsRouteStations.find(
          nextStation => nextStation.stopId === station.nextStopId
        );

        if (nextStation) {
          ({
            segmentsNormalAnglesDegrees,
            points,
          } = getNormalAngleAndPointsForLinePoints(
            [
              points[0],
              getAirportStationPointFromRelatedSubwayStop({
                airportStation: nextStation,
                selectedRouteId,
                subwayStations,
                subwayStationsAngles,
              }),
            ],
            station.pathStrategyIndex
          ));
        }
      }

      if (station.terminal) {
        const relatedTerminal = airportsTerminals.find(
          terminal => terminal.terminalId === station.terminal!.terminalId
        );

        if (relatedTerminal) {
          const { center, radius } = relatedTerminal;
          const centerPoints = fromLonLat([center.lon, center.lat]) as Point;
          ({
            segmentsNormalAnglesDegrees,
            points,
          } = getNormalAngleAndPointsForLinePoints(
            [
              points[0],
              fromPolarDegrees(
                radius,
                station.terminal.angle - MANHATTAN_DEGREES,
                centerPoints
              ),
            ],
            station.pathStrategyIndex
          ));
        }
      }

      const relatedStation = subwayStations.find(subwayStation => {
        return subwayStation.stopId === station.subwayStopId;
      });

      return new Feature({
        geometry: new LineString(points),
        lineLabel: station.lineLabel,
        lineLabelAlign: station.lineLabelAlign,
        maxIndex: relatedStation?.transfers.length ?? 0,
        segmentsNormalAnglesDegrees,
        showAsActive: intersectsWithAvailableStopIds(
          availableStopIdsForSelectedRoute,
          station.requiredSubwayStopIds
        ),
        showAsTransfer: station.isTransfer,
        useDash: station.isBusStation,
      });
    });
  }
);

export const getMapAirportsTerminalsFeatures = createSelector(
  getMapAvailableStopIdsForSelectedRoute,
  (availableStopIdsForSelectedRoute: string[]): Feature[] => {
    return airportsTerminals.map(terminal => {
      const { center, radius, requiredSubwayStopIds } = terminal;

      return new Feature({
        geometry: new OlCircle(fromLonLat([center.lon, center.lat]), radius),
        selectedPinPosition: fromLonLat([center.lon, center.lat]),
        showAsActive: intersectsWithAvailableStopIds(
          availableStopIdsForSelectedRoute,
          requiredSubwayStopIds
        ),
        terminalId: terminal.terminalId,
        useDash: terminal.isBusTerminal,
      });
    });
  }
);

export const getMapAirportsStationsDotsFeatures = createSelector(
  getMapAvailableStopIdsForSelectedRoute,
  getMapSelectedRouteId,
  getMapStations,
  getMapNormalAngleDegreesForAllStations,
  (
    availableStopIdsForSelectedRoute: string[],
    selectedRouteId: SubwayRouteId | '',
    subwayStations: Stations,
    subwayStationsAngles: { [stopId: string]: number }
  ): Feature[] => {
    return airportsTerminals
      .reduce<Feature[]>((features, terminal) => {
        if (
          !intersectsWithAvailableStopIds(
            availableStopIdsForSelectedRoute,
            terminal.requiredSubwayStopIds
          )
        ) {
          return features;
        }

        const { center, radius, stations } = terminal;
        const centerPoints = fromLonLat([center.lon, center.lat]) as Point;

        stations.forEach(terminal => {
          const { nameShort, angle, name } = terminal;

          features.push(
            new Feature({
              nameShort,
              geometry: new OlPoint(
                fromPolarDegrees(
                  radius,
                  angle - MANHATTAN_DEGREES,
                  centerPoints
                )
              ),
              name,
              positionHorizontal: angle > 90 && angle < 270 ? 'left' : 'right',
              positionVertical:
                angle >= 80 && angle <= 100
                  ? 'top'
                  : angle >= 260 && angle <= 280
                  ? 'bottom'
                  : 'middle',
            })
          );
        });

        return features;
      }, [])
      .concat(
        getValidStationsForDotsFeatures(
          availableStopIdsForSelectedRoute,
          airportsRouteStations
        ).map(station => {
          const { stopName } = station;

          return new Feature({
            geometry: new OlPoint(
              getAirportStationPointFromRelatedSubwayStop({
                airportStation: station,
                selectedRouteId,
                subwayStations,
                subwayStationsAngles,
              })
            ),
            name: stopName,
            positionVertical: station.labelPositionVertical,
          });
        })
      );
  }
);

const getValidAirportItemsForFeatures = <T extends HasRequiredSubwayStopIds>(
  availableStopIdsForSelectedRoute: string[],
  items: T[]
): T[] => {
  return items.filter(item =>
    intersectsWithAvailableStopIds(
      availableStopIdsForSelectedRoute,
      item.requiredSubwayStopIds
    )
  );
};

const getValidStationsForDotsFeatures = (
  availableStopIdsForSelectedRoute: string[],
  stations: AirportRouteStation[]
) => {
  return getValidAirportItemsForFeatures(
    availableStopIdsForSelectedRoute,
    stations.filter(station => !station.isBusStation && !station.isTransfer)
  );
};

/**
 * We add the airport station dot as the last one of the subway station transfers list.
 */
const getAirportStationPointFromRelatedSubwayStop = ({
  airportStation,
  selectedRouteId,
  subwayStations,
  subwayStationsAngles,
}: {
  airportStation: AirportRouteStation;
  selectedRouteId: SubwayRouteId | '';
  subwayStations: Stations;
  subwayStationsAngles: { [stopId: string]: number };
}): Point => {
  const relatedStation = subwayStations.find(station => {
    return station.stopId === airportStation.subwayStopId;
  });

  if (relatedStation) {
    const { placeStationDotAsFirst } = airportStation;
    const angleDegrees = subwayStationsAngles[relatedStation.stopId];
    const projected = projectStation(relatedStation);

    // If we have a selectedRouteId we don't need the length of the transfers
    // because the map shows only one subway line.
    let maxIndex = selectedRouteId ? 1 : relatedStation.transfers.length + 1;

    if (airportStation.isTransfer) {
      // If its a transfer we don't shift the position because the transfer line
      // needs to start behind the subway station dots.
      maxIndex = 0;
    }

    const parallelSpacing = parallelSpacingAtIndex(
      STATION_SPACING,
      // The subway station has N routes associated with it. We force the airport
      // station to be the last route for the station to align the dot visually.
      maxIndex,
      maxIndex
    );

    return fromPolar(
      // If we have placeStationDotAsFirst we shift the dot the opposite way
      // to make it visually better.
      parallelSpacing * (placeStationDotAsFirst ? -1 : 1),
      toRadians(angleDegrees - MANHATTAN_DEGREES),
      projected
    );
  }

  return fromLonLat([airportStation.lon, airportStation.lat]) as Point;
};
