import styled, { css } from 'styled-components/macro';
import { tablet } from '../utils/theme';

const DEFAULT_COLOR = 'rgba(255, 255, 255, 0.8)';

const ModalOverlay = styled.div<{
  color?: string;
  zIndex?: number;
  hiddenOnDesktop?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${p => p.zIndex || p.theme.zIndex.modals - 1};
  width: 100%;
  height: 100%;
  background-color: ${p => p.color || DEFAULT_COLOR};

  ${tablet(css`
    display: ${(p: any) => (p.hiddenOnDesktop ? 'none' : 'block')};
  `)}
`;

export default ModalOverlay;
