import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { formatDistance } from 'date-fns';
import OfflineIndicator from '../components/OfflineIndicator';
import { tablet } from '../utils/theme';
import { AppState } from '../models';

const Container = styled.div<{ isActive: boolean }>`
  position: fixed;
  top: 8px;
  left: 8px;
  right: 8px;
  z-index: 90;
  pointer-events: ${p => (p.isActive ? 'all' : 'none')};

  ${tablet(css`
    left: auto;
    top: ${p => p.theme.margin};
    right: ${p => p.theme.margin};
    max-width: 320px;
  `)}
`;

interface OfflineIndicatorContainerProps {
  lastUpdate: number;
}

const OfflineIndicatorContainer: React.FC<OfflineIndicatorContainerProps> = ({
  lastUpdate,
}) => {
  const [isOffline, setIsOffline] = useState<boolean>(false);
  const [lastUpdateText, setLastUpdateText] = useState<string>('');
  const offlineIntervalRef = useRef<number>();
  const offlineIntervalSeconds = 30;

  const updateLastUpdateText = useCallback(() => {
    setLastUpdateText(
      lastUpdate !== 0
        ? `Last update: ${formatDistance(new Date(lastUpdate), new Date())}`
        : ''
    );
  }, [lastUpdate]);

  const offlineListener = useCallback(() => {
    updateLastUpdateText();
    setIsOffline(true);
  }, [updateLastUpdateText]);

  const onlineListener = () => {
    setIsOffline(false);
  };

  useEffect(() => {
    if (!navigator.onLine) {
      offlineListener();
    }

    window.addEventListener('offline', offlineListener);
    window.addEventListener('online', onlineListener);

    return () => {
      window.removeEventListener('offline', offlineListener);
      window.removeEventListener('online', onlineListener);
    };
  }, [offlineListener]);

  useEffect(() => {
    if (isOffline) {
      offlineIntervalRef.current = setInterval(
        updateLastUpdateText,
        offlineIntervalSeconds * 1000
      );
    }

    return () => {
      clearInterval(offlineIntervalRef.current);
    };
  }, [isOffline, updateLastUpdateText]);

  return (
    <Container isActive={isOffline}>
      <OfflineIndicator isActive={isOffline}>
        <span>Offline. </span>
        {lastUpdateText && <span>{lastUpdateText}</span>}
      </OfflineIndicator>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  lastUpdate: state.ui.lastUpdate,
});

export default connect(mapStateToProps)(OfflineIndicatorContainer);
