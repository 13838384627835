import { StopId, SkippedEdgeStation } from './subway-types';

type CustomAlignmentByStopId = { [K in StopId]: true };

export const isLeftAligned: CustomAlignmentByStopId = {
  124: true, // 66 St - Lincoln Center (1, 2, 3)
  236: true, // Bergen St (2, 3, 4)
  617: true, // Cypress Av (6)
  710: true, // 74 St Broadway (7)
  716: true, // 33 St, (7)
  721: true, // Vernon Blvd Jackson Av (7)
  A41: true, // Jay St - MetroTech (F, A, C, R)
  A44: true, // Clinton Washington (A, C)
  D14: true, // Queens Blvd (D, B, E)
  D27: true, // Parkside Av (Q)
  D29: true, // Beverley Rd (Q)
  D30: true, // Cortelyou Rd, (Q)
  D38: true, // Neck Rd (Q)
  F09: true, // Court Sq - 23 St (E, M)
  F23: true, // 4 Av (F, G)
  M11: true, // Myrtle Av (J, M, Z)
  R32: true, // Union St (R)
};

export const isLeftAlignedWithNegativeXAxisModifier: CustomAlignmentByStopId = {
  101: true, // Van Cortlandt Park 242 St (1)
  104: true, // 231 St (1)
  103: true, // 238 St (1)
  106: true, // Marble Hill 225 St (1)
  107: true, // 215 St (1)
  108: true, // 207 St (1)
  109: true, // Dyckman St (1)
  110: true, // 191 St (1)
  111: true, // 181 St (1)
  112: true, // 168 St Washington Hts (1)
  113: true, // 157 St (1)
  114: true, // 145 St (1)
  115: true, // 137 St City College (1)
  116: true, // 125 St (1)
  117: true, // 116 St - Columbia University (1)
  118: true, // Cathedral Pkwy (1)
  120: true, // 96 St (1, 2, 3)
  128: true, // 34 St Penn Station (1, 2, 3)
  132: true, // 14 St (1, 2, 3)
  137: true, // Chambers St (1, 2, 3)
  138: true, // WTC Cortlandt, (1)
  139: true, // Rector St (1)
  402: true, // Moshulu Pkwy (5)
  405: true, // Bedford Park Blvd Lehman College (5)
  406: true, // Kingsbridge Rd (4)
  629: true, // 59 St (4, 5, 6)
  640: true, // Brooklyn Bridge City Hall (4, 5, 6)
  A02: true, // Inwood 207 St (A)
  A05: true, // 190 St (A)
  A06: true, // 181 St (A)
  A07: true, // 175 St (A)
  A12: true, // 145 St (A, C, B, D)
  A15: true, // 125 St (A, B, C, D)
  A24: true, // 59 St Columbus Circle (1, A, C, B, D)
  A25: true, // 50 St (A, C, E)
  A28: true, // 34 St Penn Station (A, C, E)
  A30: true, // 23 St (A, C, E)
  A31: true, // 14 St (A, C, E)
  A36: true, // Chambers (A, C)
  A45: true, // Franklin Av (A, C)
  F04: true, // Sutphin Blvd (F)
  G20: true, // 36 St (R, M)
  H04: true, // Broad Channel, (A, SR)
  J24: true, // Alabama Av, (J, Z)
  L21: true, // Bushwick Aberdeen (L)
  L26: true, // Livonia Av, (L)
};

export const isTopLeftAligned: CustomAlignmentByStopId = {
  239: true, // Franklin Av (2, 3, 4, 5)
  712: true, // Woodside 61 St, (7)
  714: true, // 46 St, (7)
  715: true, // 40 St, (7)
  A02: true, // Inwood 207 St (A)
  A03: true, // Dyckman St (A)
  A46: true, // Nostrand Av (A, C)
  A47: true, // Kingston Throop Av (A, C)
  A48: true, // Utica Av (A, C)
  A49: true, // Ralph Av (A, C)
  A50: true, // Rockaway Av (A, C)
  A53: true, // Van Siclen  (A, C)
  A54: true, // Shepherd Av (A, C)
  A61: true, // Rockaway Blvd, (A)
  D42: true, // W 8 St NY Aquarium (Q, F)
  E01: true, // World Trade Center (E)
  G06: true, // Sutphin Blvd - Archer Av - JFK Airport (Z, J, E)
  G21: true, // Queens Plaza St (R, M, E)
  G36: true, // Fulton St (G)
  J13: true, // 111 St (J)
  J16: true, // 85 St Forest Pkwy (J)
  J19: true, // Cypress Hills (J, Z)
  J22: true, // Cleveland St (J)
  L19: true, // Halsey St, (L)
  R03: true, // Astoria Blvd, (N, W)
  R28: true, // Court St (R)
};

export const isBottomLeftAligned: CustomAlignmentByStopId = {
  244: true, // Church Av, (2, 5)
  D26: true, // Prospect Park, (SR)
  M23: true, // Broad St (J, Z)
  S03: true, // Park Pl, (SR)
  A27: true, // 42 St Port Authority (A, C, E)
  A33: true, // Spring St (C/E)
  A34: true, // Canal St (A/C/E)
  R25: true, // Cortlandt St (R, W)
  R31: true, // Atlantic Av Barclays (R, N, D)
};

export const isBottomRightAligned: CustomAlignmentByStopId = {
  220: true, // Jackson Av, (2, 5)
  418: true, // Fulton St (4, 5)
  J20: true, // Crescent St (J, Z)
};

export const isTopRightAligned: CustomAlignmentByStopId = {
  225: true, // 125 St, (2, 3)
  235: true, // Atlantic Av - Barclays Ctr (2, 3)
  A09: true, // 168 St, (A, C)
  A10: true, // 163 St Amsterdam Av, (C)
  A52: true, // Liberty Av (A, C)
  D25: true, // 7 Av (Q, B)
  S04: true, // Botanic Garden (SF)
};

export const isLeftAlignedExclusion: CustomAlignmentByStopId = {
  A33: true, // Spring St (C/E)
  A34: true, // Canal St (A/C/E)
  G30: true, // Broadway (G)
  L19: true, // Halsey St, (L)
  R15: true, // 49 St (R, W, N)
  R26: true, // Rector St (R, W, N)
  A52: true, // Liberty Av (A, C)
};

export const isHorizontalOffsetExclusion: CustomAlignmentByStopId = {
  // 232: true, // Borough Hall (2, 3, 4, 5)
  630: true, // 51 St (6)
  710: true, // 74 St Broadway (7)
  A09: true, // 168 St, (A, C)
  A45: true, // Franklin Av (C)
};

export const isAlignedBelow: CustomAlignmentByStopId = {
  232: true, // Borough Hall (2, 3, 4, 5)
  233: true, // Hoyt St (2, 3, 4, 5)
  238: true, // Eastern Pkwy Brooklyn Museum (2, 3, 4)
  248: true, // Nostrand Av (3, 4)
  A38: true, // Fulton St (A, C, J, Z)
  A42: true, // Hoyt Schermerhorn St (A, C, G)
  D21: true, // Broadway-Lafayette St (B, D, F, M)
  D40: true, // Brighton Beach (Q, B)
  D43: true, // Coney Island (Q, F, D, N)
  E01: true, // World Trade Center (E)
  F14: true, // 2 Av (F)
  F23: true, // 4 Av (F, G)
  G07: true, // Jamaica - Van Wyck (E)
  G14: true, // Jackson Hts Roosevelt Av (R, M, F, E)
  M11: true, // Myrtle Av (J, M, Z)
  R09: true, // Queensboro Plaza (7, W, N)
  142: true, // South Ferry (1)
};

export const hasNegativeXAxisModifier: CustomAlignmentByStopId = {
  249: true, // Kingston Av (3)
  R26: true, // Rector St (R, W, N)
};

export const hasNegativeYAxisModifier: CustomAlignmentByStopId = {
  225: true, // 125 St, (2, 3)
  233: true, // Hoyt St (2, 3, 4, 5)
  238: true, // Eastern Pkwy Brooklyn Museum (2, 3, 4)
  244: true, // Church Av, (2, 5)
  248: true, // Nostrand Av (3, 4)
  619: true, // 3 Av 138 St (6)
  621: true, // 125 St, (4, 5, 6)
  A42: true, // Hoyt Schermerhorn St (A, C, G)
  A45: true, // Franklin Av (A, C)
  D21: true, // Broadway-Lafayette St (B, D, F, M)
  D43: true, // Coney Island (Q, F, D, N)
  D40: true, // Brighton Beach (B, Q)
  D42: true, // W 8 St NY Aquarium (Q, F)
  F14: true, // 2 Av (F)
  F23: true, // 4 Av (F, G)
  G07: true, // Jamaica - Van Wyck (E)
  G30: true, // Broadway (G)
  L19: true, // Halsey St, (L)
  M11: true, // Myrtle Av (J, M, Z)
  R09: true, // Queensboro Plaza (7, W, N)
  R20: true, // 14 St Union Square (L, R, W, N, Q, 4, 5, 6)
  S04: true, // Botanic Garden (SF)
};

export const isStandardRightDescendingExclusion: CustomAlignmentByStopId = {
  B22: true, // 25 Av (D)
};

export const isSharpRightDescendingExclusion: CustomAlignmentByStopId = {
  A09: true, // 168 St (C)
  A10: true, // 163 St Amsterdam Av (C)
  G30: true, // Broadway (G)
  S01: true, // Franklin Av (FS)
};

export const is45AngleExclusion: CustomAlignmentByStopId = {
  418: true, // Fulton St (4, 5)
  A15: true, // 125 St, (A, C, B, D)
  H04: true, // Broad Channel, (A, SR)
};

export const hasCustomOffsetPoint: CustomAlignmentByStopId = {
  127: true, // Times Sq 42 St (1, 2, 3, R, W, N, Q, 7, GS)
  225: true, // 125 St, (2, 3)
  232: true, // Borough Hall (2, 3, 4, 5)
  234: true, // Nevins St (2, 3, 4, 5)
  235: true, // Atlantic Av - Barclays Ctr (2, 3)
  236: true, // Bergen St (2, 3, 4)
  249: true, // Kingston Av (3)
  621: true, // 125 St, (4, 5, 6)
  631: true, // Grand Central 42 St (4, 5, 6, 7, GS)
  710: true, // 74 St Broadway (7)
  A27: true, // 42 St Port Authority (A, C, E)
  A38: true, // Fulton St (A, C, J, Z)
  A41: true, // Jay St - MetroTech (F, A, C, R)
  A52: true, // Liberty Av (A, C)
  D16: true, // 42 St Bryant Park (B, D, F, M)
  D12: true, // 155 St (B, D)
  G12: true, // Grand Av Newtown (R, N, F, E)
  G14: true, // Jackson Hts - Roosevelt Av (R, M, F, E)
  G21: true, // Queens Plaza St (R, M, E)
  J19: true, // Cypress Hills (J, Z)
  J20: true, // Crescent St (J, Z)
  // NOTE: L01 doesn't actually have a custom offset point specified because putting it here with the default is already fine
  L01: true, // 8 Av (L)
  L17: true, // Myrtle - Wyckoff Avs (L, M)
  M18: true, // Delancey Essex (F, M, J, Z)
  N04: true, // New Utrecht Av (N)
  R15: true, // 49 St (R, W, N)
  R20: true, // 14 St Union Square (L, R, W, N, Q, 4, 5, 6)
  R21: true, // 8 St-NYU (R, W)
  R22: true, // Prince St (R, W)
  R25: true, // Cortlandt St (R, W)
  142: true, // South Ferry (1)
  R28: true, // Court St (W, R)
  R30: true, // DeKalb Av ( B, Q, R)
  R31: true, // Atlantic Av Barclays (R, N, D)
  R32: true, // Union St (R)
  R33: true, // 4 Av-9 St (R)
};

export const isSharpRightDescending = (normalAngle: number): boolean =>
  normalAngle > 15 && normalAngle < 30;

export const isStandardRightDescending_45Excluded = (
  normalAngle: number
): boolean => normalAngle !== 45 && normalAngle > 40 && normalAngle < 50;

export const isSharpLeftDescending = (normalAngle: number): boolean =>
  normalAngle > -30 && normalAngle < -20;

export const isSlightLeftDescending = (normalAngle: number): boolean =>
  (normalAngle > 100 && normalAngle <= 151) ||
  (normalAngle > -60 && normalAngle < -30);

export const isLeftDescending = (normalAngle: number): boolean =>
  isSharpLeftDescending(normalAngle) || isSlightLeftDescending(normalAngle);

export const is45AngleBiDirectional = (normalAngle: number): boolean =>
  normalAngle === 45 || normalAngle === -45;

export const isLastSkipped45Angle = (
  normalAngle: number,
  skippedEdgeStation: SkippedEdgeStation | undefined
): boolean =>
  normalAngle === 45 && skippedEdgeStation === SkippedEdgeStation.last;
