import React, { useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { desktopExtraLarge, notTouch, tablet } from '../utils/theme';
import { useRef } from 'react';
import { EmergencyAlert } from '../subway-data/otp/emergency-alerts-types';
import { content, link, labelSmall, subTitle } from './Text';

type ContainerType = 'Tray' | 'aside';

export const EmergencyAlertTitle = styled.h2`
  ${subTitle};
  padding-bottom: 42px;
  text-transform: capitalize;

  ${notTouch(css`
    ${content};
    font-weight: bold;
    padding-bottom: 19px;
  `)}
`;

const EmergencyAlertDescription = styled.li`
  ${content};
  display: flex;
  flex-direction: column;

  ${tablet(css`
    flex-direction: row;
  `)}
`;

const EmergencyAlertLinkDescription = styled(EmergencyAlertDescription)`
  margin-top: 18px;
`;

const EmergencyAlertLink = styled.a`
  ${link};
`;

const EmergencyAlertSubheader = styled.p`
  display: none;

  ${notTouch(css`
    ${labelSmall};
    font-weight: normal;
    display: block;
    padding-top: 2px;
  `)}
`;

const EmergencyAlertTimestamp = styled.p`
  ${labelSmall};
  margin: 0;
  font-weight: normal;
  margin-top: 23px;

  ${notTouch(css`
    margin-top: 14px;
  `)}
`;

const EmergencyAlertWrapper = styled.div`
  padding: 20px 30px 0px 30px;
  padding-left: calc(env(safe-area-inset-left) + 30px);
  padding-right: calc(env(safe-area-inset-right) + 30px);
  background-color: #fff;

  ${notTouch(css`
    padding: 24px 22px 24px 22px;
  `)}
`;

const EmergencyAlertContentInner = styled.ul<{
  containerType: ContainerType;
  collapseTitle?: boolean;
}>`
  ${EmergencyAlertWrapper} {
    &:first-child {
      ${(p: any) => {
        if (p.containerType !== 'Tray') return;
        return css`
          padding-top: ${p.collapseTitle ? '0' : '14px'};
        `;
      }}
    }
  }
`;

const Image = styled.img`
  align-self: center;
  flex-shrink: 0;
  max-width: 100px;
  margin: 40px 0;

  ${tablet(css`
    margin: 0 0 0 13px;
    max-width: 50px;
  `)}

  ${desktopExtraLarge(css`
    margin: 0 0 0 20px;
    max-width: 70px;
  `)}
`;

const formatReadableTimestampFromUnixEpoch = (updated): string => {
  const unixDateInMS = updated * 1000;
  const convertedDate = new Date(unixDateInMS);
  const formattedDate: string = convertedDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  });
  const formattedTime: string = convertedDate.toLocaleTimeString('en-US', {
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
  });

  return `Updated ${formattedDate} at ${formattedTime?.replace(
    // ex: 5:00am
    formattedTime.slice(-3),
    formattedTime.slice(-2).toLowerCase()
  )}`;
};

const EmergencyAlertContent: React.FC<{
  containerType: ContainerType;
  emergencyAlertContent: EmergencyAlert;
  isSmallPosition?: boolean;
  onCloseCallback?: () => void;
  setVaccineLocationsVisible?: (value: boolean) => void;
}> = ({
  containerType,
  emergencyAlertContent,
  isSmallPosition,
  onCloseCallback,
  setVaccineLocationsVisible,
}) => {
  const containerRef = useRef<HTMLUListElement>(null);

  const onCLickToPromote = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      if (emergencyAlertContent?.activateFeature === 'vaccineLocations') {
        setVaccineLocationsVisible && setVaccineLocationsVisible(true);
        onCloseCallback && onCloseCallback();
      }
    },
    [emergencyAlertContent, onCloseCallback, setVaccineLocationsVisible]
  );

  return (
    <EmergencyAlertContentInner
      ref={containerRef}
      containerType={containerType}
      collapseTitle={isSmallPosition}
    >
      {emergencyAlertContent && (
        <EmergencyAlertWrapper>
          <EmergencyAlertTitle>
            {emergencyAlertContent?.title}
            <EmergencyAlertSubheader>
              Get local info and updates.
            </EmergencyAlertSubheader>
          </EmergencyAlertTitle>
          <EmergencyAlertDescription>
            <div>
              {emergencyAlertContent?.text}
              {emergencyAlertContent.activateFeature && (
                <EmergencyAlertLink href="#" onClick={onCLickToPromote}>
                  Try now
                </EmergencyAlertLink>
              )}
            </div>

            {emergencyAlertContent?.image && (
              <Image src={emergencyAlertContent?.image} />
            )}
          </EmergencyAlertDescription>
          {emergencyAlertContent?.link && (
            <>
              <EmergencyAlertLinkDescription>
                {'For more details:'}
              </EmergencyAlertLinkDescription>
              <EmergencyAlertLink
                href={emergencyAlertContent?.link}
                target="_blank"
              >
                {emergencyAlertContent?.link_title}
              </EmergencyAlertLink>
            </>
          )}
          {!emergencyAlertContent?.hideDate && (
            <EmergencyAlertTimestamp>
              {formatReadableTimestampFromUnixEpoch(
                emergencyAlertContent?.updated
              )}
            </EmergencyAlertTimestamp>
          )}
        </EmergencyAlertWrapper>
      )}
    </EmergencyAlertContentInner>
  );
};

export default EmergencyAlertContent;
