import React, { useCallback, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import styled, { css } from 'styled-components/macro';

import { tablet, desktopExtraLarge, notTouch } from '../../utils/theme';

import Icon, { IconContainer, IconTypes } from '../Icon';
import { content } from '../Text';

const ListItem = styled.li`
  border-bottom: 1px solid ${p => p.theme.colors.lightGray};

  &:last-child {
    margin-bottom: 44px;
    border-bottom: none;

    ${tablet(css`
      margin-bottom: 32px;
    `)}
  }
`;

const ListItemHeader = styled.span<{ alert?: boolean; warning?: boolean }>`
  align-items: flex-start;
  display: flex;
  padding: 15px 0;
  width: 100%;

  ${p =>
    css`
      color: ${p.alert
        ? p.theme.colors.red
        : p.warning
        ? p.theme.colors.orange
        : p.theme.colors.black};
    `}
`;

const Link = styled.a<{
  alert?: boolean;
  useBlack?: boolean;
  warning?: boolean;
}>`
  align-items: flex-start;
  display: flex;
  padding: 15px 0;
  text-decoration: none;
  width: 100%;

  ${p =>
    css`
      color: ${p.alert
        ? p.theme.colors.red
        : p.warning
        ? p.theme.colors.orange
        : p.useBlack
        ? p.theme.colors.black
        : p.theme.colors.blue};
      font-weight: ${!p.alert && !p.warning && !p.useBlack ? 500 : 'normal'};
    `}

  ${notTouch(css`
    &:hover {
      color: ${p => p.theme.colors.blue};
      text-decoration: underline;
    }
  `)}

  &:focus.focus-visible {
    outline: 1px dashed ${p => p.theme.colors.black};
  }
`;

const ItemIcon = styled(IconContainer)<{
  leftAligned?: boolean;
  rotateIcon?: boolean;
}>`
  flex-shrink: 0;
  height: 24px;
  width: 24px;

  ${tablet(css`
    height: 18px;
    width: 18px;
  `)}

  ${desktopExtraLarge(css`
    height: 24px;
    width: 24px;
  `)}

  ${p =>
    css`
      margin-${p.leftAligned ? 'right' : 'left'}: 31px;

      ${tablet(css`
        margin-${p.leftAligned ? 'right' : 'left'}: 12px;
      `)}

      ${desktopExtraLarge(css`
        margin-${p.leftAligned ? 'right' : 'left'}: 16px;
      `)}
    `}

    ${p =>
      p.rotateIcon &&
      css`
        transform: rotate(180deg);
      `}
`;

const ItemText = styled.p`
  ${content}
  width: 100%;
`;

const AccordionContent = styled.div`
  padding: 24px 55px;

  ${tablet(css`
    padding: 18px 30px;
  `)}

  ${desktopExtraLarge(css`
    padding: 24px 40px;
  `)}
`;

interface VaccineLocationInfoItemProps {
  copy: string | string[];
  iconType: IconTypes;
  locationId: string;
  styleAlert?: boolean;
  styleWarning?: boolean;
  url?: string;
}

export const VaccineLocationInfoItem: React.FC<VaccineLocationInfoItemProps> = ({
  children,
  copy,
  iconType,
  locationId,
  styleAlert,
  styleWarning,
  url,
}) => {
  const DEFAULT_ANIMATION_DURATION = 300;
  const locationIdRef = useRef(locationId);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [animationDuration, setAnimationDuration] = useState<number>(
    DEFAULT_ANIMATION_DURATION
  );
  let HeaderWrapper = React.Fragment;
  let headerWrapperProps = {};

  const toggleAccordion = useCallback(
    (e: React.MouseEvent<HTMLLinkElement>) => {
      e.preventDefault();
      setIsExpanded(!isExpanded);
    },
    [isExpanded, setIsExpanded]
  );

  useEffect(() => {
    if (locationIdRef.current !== locationId) {
      locationIdRef.current = locationId;
      setAnimationDuration(0);
      setIsExpanded(false);
      setTimeout(() => {
        setAnimationDuration(DEFAULT_ANIMATION_DURATION);
      }, 200);
    }
  }, [isExpanded, locationId, locationIdRef, setIsExpanded]);

  if (children) {
    HeaderWrapper = Link;
    headerWrapperProps = {
      alert: styleAlert,
      href: '#',
      onClick: toggleAccordion,
      useBlack: true,
      warning: styleWarning,
    };
  } else if (url) {
    HeaderWrapper = Link;
    headerWrapperProps = { href: url, target: '_blank' };
  } else {
    HeaderWrapper = ListItemHeader;
    headerWrapperProps = { alert: styleAlert, warning: styleWarning };
  }

  return (
    <ListItem>
      <HeaderWrapper {...headerWrapperProps}>
        <ItemIcon leftAligned={true}>
          <Icon type={iconType} />
        </ItemIcon>
        <ItemText>{copy}</ItemText>
        {children && (
          <ItemIcon rotateIcon={!isExpanded}>
            <Icon type={IconTypes.Chevron} />
          </ItemIcon>
        )}
      </HeaderWrapper>

      {children && (
        <AnimateHeight
          duration={animationDuration}
          height={isExpanded ? 'auto' : 0}
          easing="ease-in-out"
          animateOpacity
        >
          <AccordionContent>{children}</AccordionContent>
        </AnimateHeight>
      )}
    </ListItem>
  );
};
