import React, { useRef, RefObject } from 'react';
import styled, { css } from 'styled-components/macro';
import { desktopLarge, desktopExtraLarge, tablet } from '../../utils/theme';
import { ListHeaderTitle } from './StationStatus';
import Icon, { IconContainer, IconTypes } from '../Icon';
import { ElevatorEscalatorStatus } from './ElevatorEscalatorStatus';
import { OutageListing } from '../../subway-data/elevator-escalator-status-types';

const ServicesContainer = styled.div<{ isVisible: boolean }>`
  visibility: ${p => (p.isVisible ? 'visible' : 'hidden')};
  height: ${p => (p.isVisible ? 'auto' : '0')};
`;

const ServicesHeader = styled.div<{ top: number | undefined }>`
  align-items: center;
  background: ${p => p.theme.colors.white};
  border-bottom: 1px solid ${p => p.theme.colors.lightGray};
  color: ${p => p.theme.colors.textGray};
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 14px;
  padding-bottom: 16px;
  position: sticky;
  transform: translate3d(0, 0, 0);
  top: ${p => p.top || 0}px;
  z-index: 9;

  ${tablet(css`
    padding-bottom: 8px;
  `)}
`;

const OutageAlertIconContainer = styled(IconContainer)`
  circle {
    fill: ${p => p.theme.colors.black};
  }

  path {
    fill: ${p => p.theme.colors.white};
  }

  svg {
    /* 20px / 24px */
    transform: scale(0.835);

    ${desktopLarge(css`
      /* 14px / 24px */
      transform: scale(0.59);
    `)};

    ${desktopExtraLarge(css`
      /* 17px / 24px */
      transform: scale(0.71);
    `)};
  }
`;

interface ServicesProps {
  isVisible: boolean;
  equipmentOutageListings: OutageListing[];
  equipmentTotal: number;
  mobileScrollRef?: RefObject<HTMLDivElement>;
  top?: number | undefined;
}

const Services: React.FC<ServicesProps> = ({
  isVisible,
  equipmentOutageListings,
  equipmentTotal,
  mobileScrollRef,
  top,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <ServicesContainer isVisible={isVisible} ref={scrollRef}>
      <ServicesHeader top={top}>
        <ListHeaderTitle>Services</ListHeaderTitle>
        <OutageAlertIconContainer>
          <Icon type={IconTypes.Alert} />
        </OutageAlertIconContainer>
      </ServicesHeader>
      {equipmentOutageListings && (
        <ElevatorEscalatorStatus
          equipmentOutageListings={equipmentOutageListings}
          equipmentTotal={equipmentTotal}
          scrollRef={scrollRef}
          mobileScrollRef={mobileScrollRef}
        />
      )}
    </ServicesContainer>
  );
};

export default Services;
