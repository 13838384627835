import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

interface LoadingIndicatorProps {}

// Helpful explanation of the math involved to generate this spinner: https://glennmccomb.com/articles/building-a-pure-css-animated-svg-spinner/

const indicatorSize = 45;
const lineThickness = 2;
const radius = 15;
const offset = radius * 2 * Math.PI;
const duration = '1.5s';

const rotator = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  `;

const dash = keyframes`
    0% {
      stroke-dashoffset: ${offset};
    }
    50% {
      stroke-dashoffset: ${offset / 2};
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: ${offset};
      transform: rotate(450deg);
    }
  `;

const LoadingIndicatorContainer = styled.div<LoadingIndicatorProps>`
  .spinner {
    animation: ${rotator} ${duration} linear infinite;
  }

  .path {
    stroke-dasharray: ${offset};
    stroke-dashoffset: 0;
    stroke: #000;
    transform-origin: center;
    animation: ${dash} ${duration} ease-in-out infinite;
  }

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${p => p.theme.zIndex.spinner};
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = () => {
  return (
    <svg
      className="spinner"
      width={indicatorSize}
      height={indicatorSize}
      viewBox={`0 0 ${indicatorSize} ${indicatorSize}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth={lineThickness}
        strokeLinecap="round"
        cx={indicatorSize / 2}
        cy={indicatorSize / 2}
        r={radius}
      ></circle>
    </svg>
  );
};

const LoadingIndicator: React.FC<LoadingIndicatorProps> = () => {
  return (
    <LoadingIndicatorContainer>
      <LoadingSpinner />
    </LoadingIndicatorContainer>
  );
};

export default LoadingIndicator;
