import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';

import { getWindowHeight } from '../../utils/window.utils';

import TrayBar from './TrayBar';
import TrayContainer from './TrayContainer';
import TrayContent from './TrayContent';
import { TrayInner, TrayInnerOverlay } from './TrayInner';

export const TRANSITION_DURATION = 400;
export const TRAY_SMALL_HEIGHT = 60;

export enum TrayPosition {
  hidden = 'hidden',
  small = 'small',
  twoThirds = 'twoThirds',
  full = 'full',
}

const trayPositionsOrder = [
  TrayPosition.hidden,
  TrayPosition.small,
  TrayPosition.twoThirds,
  TrayPosition.full,
];

interface TrayProps {
  currentTrayPosition: TrayPosition;
  /** Swiping down the tray from full position returns it to the initial position.
   * This prop removes that and closes the tray when swiping down from the full position. */
  closeOnSwipe?: boolean;
  defaultPosition: TrayPosition;
  onCloseCallback: () => void;
  isOpen: boolean;
  setCurrentTrayPosition: (value: TrayPosition) => void;
}

const getTranslateYPositions = () => {
  return {
    full: getWindowHeight(),
    hidden: 0,
    small: TRAY_SMALL_HEIGHT + 10, // extra padding for PillTimeToggle and FilterButton
    twoThirds: getWindowHeight() * 0.66,
  };
};

const getNextPosition = (
  isSwappingUp: boolean,
  currentPosition: TrayPosition,
  defaultPosition: TrayPosition
): TrayPosition => {
  const currentIndex = trayPositionsOrder.indexOf(currentPosition);
  const defaultIndex = trayPositionsOrder.indexOf(defaultPosition);
  const lastIndex = trayPositionsOrder.length - 1;

  // When swiping up, we always go to the next position
  if (isSwappingUp) {
    return (
      trayPositionsOrder[currentIndex + 1] ?? trayPositionsOrder[lastIndex]
    );
  }

  // When we swipe down
  // If the tray was fully open we move back to the default position
  if (currentIndex === lastIndex) {
    return defaultPosition;
  }

  // If the current position is the default or lower we close it
  if (currentIndex <= defaultIndex) {
    return TrayPosition.hidden;
  }

  // Any other swipe down event moves to the previous position
  return trayPositionsOrder[currentIndex - 1];
};

const Tray: FC<TrayProps> = ({
  children,
  closeOnSwipe,
  currentTrayPosition,
  defaultPosition,
  isOpen,
  onCloseCallback,
  setCurrentTrayPosition,
}) => {
  const isFullyOpened = currentTrayPosition === TrayPosition.full;

  const trayRef = useRef<HTMLDivElement>(null);
  const translateYPositions = useRef(getTranslateYPositions());
  const [springVal, setSpringVal] = useSpring(() => ({
    y: 0,
  }));

  const updateTrayPosition = useCallback(
    (position: TrayPosition): void => {
      if (position === TrayPosition.hidden) {
        onCloseCallback();
      }

      setCurrentTrayPosition(position);
    },
    [onCloseCallback, setCurrentTrayPosition]
  );

  const onResize = useCallback(() => {
    if (!isOpen) {
      return;
    }
    translateYPositions.current = getTranslateYPositions();

    // reset top offset when resize is fired
    if (currentTrayPosition !== TrayPosition.hidden) {
      setSpringVal({
        y: -translateYPositions.current[currentTrayPosition],
        immediate: true,
      });
    }
  }, [currentTrayPosition, isOpen, translateYPositions, setSpringVal]);

  useEffect(() => {
    if (!trayRef.current || !isOpen) {
      return;
    }
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [isOpen, onResize]);

  // Set the default position when it open
  useEffect(() => {
    if (isOpen) {
      setCurrentTrayPosition(defaultPosition);
    }
  }, [defaultPosition, isOpen, setCurrentTrayPosition]);

  // Handle the position change
  useEffect(() => {
    if (isOpen) {
      setSpringVal({
        y: -translateYPositions.current[currentTrayPosition],
      });
    } else {
      setSpringVal({
        y: -translateYPositions.current.hidden,
      });
    }
  }, [currentTrayPosition, isOpen, setSpringVal]);

  const bind = useDrag(
    ({ direction: [, dY], last, tap, event }) => {
      event?.stopPropagation();

      const isSwipingDown = dY === 1;
      // The dY on tap is 0 and we consider that up because
      // the tap flow is the same of swiping up
      const isSwipingUp = dY === 0 || dY === -1;
      // We use the new position for almost all the events
      let newPosition = getNextPosition(
        isSwipingUp,
        currentTrayPosition,
        defaultPosition
      );

      if (tap) {
        const target = event?.target as HTMLElement;
        if (!target || currentTrayPosition === TrayPosition.hidden) return;

        // Tray moves to next position if tapping anywhere when small
        // or tapping on the header when 2/3 of screen
        if (
          currentTrayPosition === TrayPosition.small ||
          (target.classList?.contains('trayBar__container') &&
            currentTrayPosition === TrayPosition.twoThirds)
        ) {
          updateTrayPosition(newPosition);
        } else if (
          target.classList?.contains('trayBar__close') &&
          isFullyOpened
        ) {
          // Tray closes when tapping on `x` if it's fully opened
          updateTrayPosition(TrayPosition.hidden);
        }
      } else if (last) {
        // If swiping up ow down we move to the next position
        // the scroll top is <= 0 because of the scroll bounce
        // we have on iOS devices
        if (isSwipingUp) {
          updateTrayPosition(newPosition);
        } else if (isSwipingDown && (trayRef.current?.scrollTop ?? 0) <= 0) {
          updateTrayPosition(closeOnSwipe ? TrayPosition.hidden : newPosition);
        }
      }
    },
    {
      axis: 'y',
    }
  );

  return (
    <TrayContainer {...bind()} style={springVal}>
      <TrayInner
        aria-modal="true"
        className="trayInner"
        data-inner="true"
        ref={trayRef}
        scrollEnabled={isFullyOpened}
        showBoxShadow={isOpen}
      >
        <TrayInnerOverlay
          isVisible={currentTrayPosition === TrayPosition.small}
        />
        <TrayBar isFullPosition={isOpen && isFullyOpened} />
        <TrayContent>{children}</TrayContent>
      </TrayInner>
    </TrayContainer>
  );
};

export default Tray;
