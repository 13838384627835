import {
  EquipmentType,
  FilteredOutage,
} from '../subway-data/elevator-escalator-status-types';

export const formatReadableOutageDateTime = (
  date: string
): { formattedDate: string; formattedTime: string } => {
  const dateToFormat: Date = new Date(date);
  const formattedDate: string = dateToFormat.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
  const formattedTime: string = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(dateToFormat);
  return {
    formattedDate,
    formattedTime,
  };
};

//[equipment type(s)] from [serving] is/are out of service.
export const formatReadableOutage = (outage: FilteredOutage): string => {
  const { equipmenttype, serving } = outage.listing;
  const { count } = outage;

  if (equipmenttype && serving && count) {
    return `${EquipmentType[equipmenttype]}${
      count > 1 ? 's' : ''
    } from ${serving} ${count > 1 ? 'are' : 'is'} out of service.`;
  } else return 'There is an equipment outage at this station.';
};
