import { parse as queryStringParse } from 'query-string';

import { SubwayRouteId, RouteDirection } from '../subway-data/subway-types';
import { DEFAULT_LAT, DEFAULT_LON, DEFAULT_ZOOM } from '../config';
import { subwayRouteIds, TimeFilter, timeFilters } from '../subway-data';

export interface DevQaUrlData {
  date?: string;
  direction?: RouteDirection;
  enablefps?: string;
  time?: string;
}

/**
 * We can override the current app date, time, and subway lines direction
 * via URL query parameters. The parameters are intended for unit testing
 * but omitted in general usage.
 *
 * The date and time help test time-sensitive train patterns without changing
 * the computer clock. Omitting any of them will default it for the current
 * computer date time.
 * Date format is: yyyy-mm-dd
 * Time format is: hh:mm(AM|PM)
 *
 * The direction forces the pattern graph request for a specific direction.
 * Omitting it will draw the direction with the most stops. It should be a
 * valid RouteDirection.
 *
 * @example http://localhost:3000/?date=2020-06-18&time=9:00PM&direction=0
 */
export const getDevQaUrlData = (): DevQaUrlData => {
  let { date, direction, time, enablefps } = queryStringParse(
    window.location.search
  ) as {
    [key: string]: string;
  };

  return {
    date,
    direction: ['0', '1'].includes(direction)
      ? (direction as RouteDirection)
      : undefined,
    enablefps,
    time,
  };
};

interface UrlMap {
  lat: number;
  lon: number;
  zoom: number;
}

interface UrlRoute {
  ada: boolean;
  selectedRouteId: SubwayRouteId | '';
  /**
   * The time filter will start the app with the specified time filter selected.
   * Omitting it will set the selected time filter to current from the date. It
   * should be a valid TimeFilter.
   *
   * The time filter is a power user feature for QA/Dev, but that could be used
   * public by MTA to share specific time filtered links. The reason we don't
   * keep setting the param in the URL is to block ordinary users to bookmark
   * or share URLs with specific time filters that would not make sense to
   * further access, like people that save the app to the home of their phones.
   */
  timeFilter?: TimeFilter;
  vaccineLocations: boolean;
}

export type UrlData = UrlMap & UrlRoute;

export const ADA_STRING = 'accessible';
export const VACCINE_LOCATIONS_STRING = 'vaccineLocations';
export const HASH_PREFIX = '#';

const parseRouteIdTimeFilterAdaVaccine = (data: string): UrlRoute => {
  return data.split(',').reduce<UrlRoute>(
    (acc, value) => {
      if (timeFilters.includes(value as TimeFilter)) {
        acc.timeFilter = value as TimeFilter;
      } else if (subwayRouteIds.includes(value as SubwayRouteId)) {
        acc.selectedRouteId = value as SubwayRouteId;
      } else if (value === ADA_STRING) {
        acc.ada = true;
      } else if (value === VACCINE_LOCATIONS_STRING) {
        acc.vaccineLocations = true;
      }

      return acc;
    },
    {
      ada: false,
      selectedRouteId: '',
      vaccineLocations: false,
    }
  );
};

const parseLatLonZoom = (data: string): UrlMap => {
  const items = data.split(',');

  return {
    lat: parseFloat(items[0]) || DEFAULT_LAT,
    lon: parseFloat(items[1]) || DEFAULT_LON,
    zoom: parseFloat(items[2]) || DEFAULT_ZOOM,
  };
};

/** Rounds a number to a specific number of decimal places. */
const roundDecimals = (decimals: number) => (num: number): number =>
  Math.round(10 ** decimals * num) / 10 ** decimals;

const round7 = roundDecimals(7);
const round2 = roundDecimals(2);

const parseHash = (hash = ''): UrlData => {
  const [routeIdTimeFilterAdaVaccine = '', latLonZoom = ''] = hash.split('@');

  return {
    ...parseLatLonZoom(latLonZoom),
    ...parseRouteIdTimeFilterAdaVaccine(routeIdTimeFilterAdaVaccine),
  };
};

const generateHash = ({
  ada,
  lat,
  lon,
  selectedRouteId,
  vaccineLocations,
  zoom,
}: Omit<UrlData, 'timeFilter'>): string => {
  // Round instead of using .toPrecision, to avoid trailing zeroes like 12.00
  const latLonZoom = `${round7(lat)},${round7(lon)},${round2(zoom)}z`;
  const routeIdAdaVaccine = [
    selectedRouteId,
    ada && ADA_STRING,
    vaccineLocations && VACCINE_LOCATIONS_STRING,
  ]
    .filter(item => !!item)
    .join(',');

  return `${routeIdAdaVaccine}@${latLonZoom}`;
};

const get = (): UrlData => {
  const hash = window.location.hash.replace(HASH_PREFIX, '');
  return parseHash(hash);
};

const set = (urlData: Omit<UrlData, 'timeFilter'>): void => {
  const hash = `${HASH_PREFIX}${generateHash(urlData)}`;
  window.history.pushState(urlData, 'map', hash);
};

const UrlState = {
  generateHash,
  get,
  parseHash,
  set,
};

export default UrlState;
