import round from 'lodash/round';
import { Feature, Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { StyleFunction } from 'ol/style/Style';

import { Station } from '../../subway-data/subway-types';
import { ZoomLevel } from '../map-types';

import { EMPTY_STYLE, RENDER_BUFFER_ICONS } from '../maps-constants';
import { getOlMapZoom, getProportionalValueForZoom } from '../maps-utils';
import { LayerRenderingProps } from '../subway-openlayers-graphics';
import { routeIconsSvgData } from '../subway-sign-svg';

import { getLabelAlignmentProps } from './utils/label-alignment';

const getAdaIconStyle = (
  useDarkMap: boolean,
  isADAFilterActive?: boolean
): StyleFunction => (feature, resolution) => {
  if (isADAFilterActive) {
    const currentZoom = getOlMapZoom(resolution);
    const iconWidth = 18;
    const iconScale = round(
      getProportionalValueForZoom(currentZoom, {
        [ZoomLevel.z12]: 12 / iconWidth,
        [ZoomLevel.z13]: 16 / iconWidth,
        [ZoomLevel.z14]: 20 / iconWidth,
      }),
      2
    );

    const iconX = round(
      getProportionalValueForZoom(currentZoom, {
        [ZoomLevel.z17]: 1,
        [ZoomLevel.z18]: 0.8,
      }),
      2
    );

    const { anchorOrigin, iconY } = getLabelAlignmentProps({
      feature,
      fontLineHeight: 0,
      iconScale,
      iconWidth,
      useIcons: true,
    });

    return new Style({
      image: new Icon({
        anchor: [iconX, iconY],
        anchorOrigin,
        anchorXUnits: IconAnchorUnits.PIXELS,
        anchorYUnits: IconAnchorUnits.PIXELS,
        scale: iconScale,
        // we don't use routesUnavailableForStop because for the line icon
        // what matter is if the line is running
        ...routeIconsSvgData({
          iconWidth,
          isADAFilterActive,
          isADAStation: true,
          routeIds: [],
          routesUnavailable: {},
          useDarkMap,
        }),
      }),
    });
  }

  return EMPTY_STYLE;
};

// This layer shows the ADA icons on zoom lower than 14
// when the ADA feature is on
let subwayAdaIconsLayer: VectorLayer;
export const addSubwayAdaIconsLayer = ({
  allFeaturesForAllRoutesUnified,
  isADAFilterActive,
  map,
  routeId,
  soloStationsFeatures,
  useDarkMap,
}: LayerRenderingProps & {
  allFeaturesForAllRoutesUnified: Feature[];
  isADAFilterActive: boolean;
  map: Map;
  soloStationsFeatures: Feature[];
  useDarkMap: boolean;
}) => {
  const features = (routeId
    ? soloStationsFeatures
    : allFeaturesForAllRoutesUnified
  ).filter(feature => (feature.get('station') as Station).ada);
  const style = getAdaIconStyle(useDarkMap, isADAFilterActive);
  const maxZoom = routeId ? 12.999 : 13.999;

  if (subwayAdaIconsLayer) {
    subwayAdaIconsLayer.setSource(
      new VectorSource({
        features,
      })
    );
    subwayAdaIconsLayer.setMaxZoom(maxZoom);
    subwayAdaIconsLayer.setStyle(style);
  } else {
    subwayAdaIconsLayer = new VectorLayer({
      maxZoom,
      renderBuffer: RENDER_BUFFER_ICONS,
      source: new VectorSource({
        features,
      }),
      style,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(subwayAdaIconsLayer);
  }
};
