import round from 'lodash/round';
import { Feature, Map } from 'ol';
import { Style, Icon } from 'ol/style';
import { StyleFunction } from 'ol/style/Style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { RENDER_BUFFER_ICONS } from '../maps-constants';
import { getOlMapZoom, getProportionalValueForZoom } from '../maps-utils';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { selectedStationMarkerSVGDataString } from '../subway-sign-svg';
import { MapsTheme } from '../maps-theme';
import { SkippedEdgeStation } from '../../subway-data/subway-types';
import { ZoomLevel } from '../map-types';

const darkIcon = selectedStationMarkerSVGDataString(MapsTheme.dark.pin);
const lightIcon = selectedStationMarkerSVGDataString(MapsTheme.light.pin);

const getStyleFunction = (useDarkMap: boolean): StyleFunction => (
  feature,
  resolution
) => {
  const currentZoom = getOlMapZoom(resolution);
  const isHorizontalStation = feature.get('isHorizontalStation') as boolean;
  const isAirportTerminal = feature.get('isAirportTerminal') as boolean;
  const skippedEdgeStation = feature.get(
    'skippedEdgeStation'
  ) as SkippedEdgeStation;

  let anchorX = skippedEdgeStation === SkippedEdgeStation.last ? 5 : 10;
  let anchorY = 30;

  if (isHorizontalStation) {
    anchorX = 20;
    anchorY = 20;
  } else if (isAirportTerminal) {
    anchorX = round(
      getProportionalValueForZoom(currentZoom, {
        [ZoomLevel.z14]: 7,
        [ZoomLevel.z16]: 0,
      }),
      2
    );
    anchorY = round(
      getProportionalValueForZoom(currentZoom, {
        [ZoomLevel.z14]: 35,
        [ZoomLevel.z15]: 40,
        [ZoomLevel.z16]: 50,
      }),
      2
    );
  }

  return new Style({
    image: new Icon({
      anchor: [anchorX, anchorY],
      anchorXUnits: IconAnchorUnits.PIXELS,
      anchorYUnits: IconAnchorUnits.PIXELS,
      src: useDarkMap ? darkIcon : lightIcon,
    }),
  });
};

let selectedStationPinLayer: VectorLayer;
export const addSelectedStationPinLayer = (map: Map, useDarkMap: boolean) => {
  if (selectedStationPinLayer) {
    selectedStationPinLayer.setStyle(getStyleFunction(useDarkMap));
  } else {
    selectedStationPinLayer = new VectorLayer({
      renderBuffer: RENDER_BUFFER_ICONS,
      source: new VectorSource(),
      style: getStyleFunction(useDarkMap),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(selectedStationPinLayer);
  }
};

export const setSelectedStationPinLayerSource = (feature?: Feature) => {
  const source = selectedStationPinLayer.getSource();

  source.clear();

  if (feature) {
    source.addFeature(feature);
  }
};
