import React from 'react';
import styled, { css } from 'styled-components/macro';
import { RouteStatus } from '../subway-data/subway-types';
import {
  notTouch,
  touch,
  tablet,
  desktopLarge,
  desktopExtraLarge,
} from '../utils/theme';
import ServiceStatus from '../components/Controls/ServiceStatus';
import {
  SERVICE_STATUS_BUTTON_TRANSITION,
  MENU_TRANSITION_DELAY_OUT,
} from '../utils/animations';
import { isPhone } from '../utils/deviceDetector.utils';

interface ServiceStatusButtonProps {
  isActive: boolean;
  isRouteUnavailable: boolean;
  statusViewOpened: boolean;
  selectedRouteStatus: RouteStatus;
  setStatusViewOpened: (statusViewOpened: boolean) => void;
}

export const ServiceStatusButtonContainer = styled.div<{
  hasSelectedRouteStatusData: boolean;
  isActive: boolean;
  isClosed?: boolean;
  isPhone?: boolean;
}>`
  visibility: ${p => (p.isActive ? 'visible' : 'hidden')};
  padding-top: 10px;

  ${notTouch(css`
    position: fixed;
    top: ${p => p.theme.margin};
    left: 240px;
    z-index: 4;
    display: block;
    opacity: ${(p: any) => (p.isActive ? 1 : 0)};
    transform: ${(p: any) =>
      p.isActive ? 'translateX(0px)' : 'translateX(-20px)'};
    pointer-events: ${(p: any) => (p.isActive ? 'all' : 'none')};
    transition: ${p => p.theme.transition};
    transition-property: transform, opacity;
    transition-duration: ${SERVICE_STATUS_BUTTON_TRANSITION}ms;
    transition-delay: ${(p: any) =>
      isPhone()
        ? 0
        : p.hasSelectedRouteStatusData
        ? MENU_TRANSITION_DELAY_OUT
        : 600}ms;

    ${desktopLarge(css`
      left: 220px;
    `)};

    ${desktopExtraLarge(css`
      left: 270px;
    `)};
  `)}

  ${touch(css`
    display: ${(p: any) => (p.isActive ? 'block' : 'none')};
    ${tablet(css`
      position: fixed;
      top: 10px;
      left: 220px;
      z-index: 4;
      display: block;
      transform: ${(p: any) =>
        p.isActive ? 'translateX(0px)' : 'translateX(-20px)'};
      pointer-events: ${(p: any) => (p.isActive ? 'all' : 'none')};
      transition: ${p => p.theme.transition};
      transition-duration: ${SERVICE_STATUS_BUTTON_TRANSITION}ms;
      transition-delay: ${(p: any) =>
        isPhone()
          ? 0
          : p.hasSelectedRouteStatusData
          ? MENU_TRANSITION_DELAY_OUT
          : 600}ms;
    `)}
  `)}
`;

const ServiceStatusButton: React.FC<ServiceStatusButtonProps> = ({
  isActive,
  isRouteUnavailable,
  statusViewOpened,
  selectedRouteStatus,
  setStatusViewOpened,
}) => {
  const hasSelectedRouteStatusData =
    selectedRouteStatus.statusDetails.length > 0;

  return (
    <ServiceStatusButtonContainer
      hasSelectedRouteStatusData={hasSelectedRouteStatusData}
      isPhone={isPhone()}
      isClosed={statusViewOpened}
      isActive={isActive && (hasSelectedRouteStatusData || isRouteUnavailable)}
    >
      <ServiceStatus
        isClosed={statusViewOpened}
        onClick={() => {
          setStatusViewOpened(isPhone() ? true : !statusViewOpened);
        }}
      />
    </ServiceStatusButtonContainer>
  );
};

export default ServiceStatusButton;
