import { createSelector } from 'reselect';
import { getMapStations } from './getMapStations';
import {
  Stations,
  Stop,
  StopLists,
  StopListsByRoute,
} from '../../subway-data/subway-types';
import { LineAB, Point } from '../../geometry/geometry-types';
import { fromLonLat } from 'ol/proj';
import { getMapFilteredStopListsByRoute } from './getMapFilteredStopListsByRoute';
import { MANHATTAN_DEGREES } from '../../subway-data';
import flatten from 'lodash/flatten';
import round from 'lodash/round';
import { projectStation } from '../../maps/subway-openlayers-graphics';
import { getSegmentId } from '../../maps/subway-routes.utils';
import {
  pointsNormalAnglesDegrees,
  rotatePointsDegrees,
} from '../../geometry/point-utils';
import strategiesForSegments, {
  SegmentStrategyIndex,
} from '../../maps/strategies-for-segments';
import { getStrategyByIndex } from '../../geometry/path-strategies';

export const getMapNormalAngleDegreesForAllStations = createSelector(
  getMapFilteredStopListsByRoute,
  getMapStations,
  (
    filteredStopListsForRoutes: StopListsByRoute,
    stations: Stations
  ): { [stopId: string]: number } => {
    const results: Record<string, number> = {};
    const cachedStopListsForRoute: Record<string, StopLists> = {};

    stations.forEach(station => {
      // Sometimes we need to manually specify the angle in the unified station data
      let result: number = NaN;

      // Try manual angle
      const manualAngle = station.angle;
      if (manualAngle !== undefined) result = manualAngle;

      // Try calculation
      if (isNaN(result)) {
        // TODO: calculate based on below
        const routeId = station.transfers[0];
        // const stationIndex = stationsByLine[routeId].indexOf(station);
        // const anglesForLine = stationNormalAnglesByLine[routeId];
        // const somehowTheAngle = toDegrees(anglesForLine[stationIndex]);

        const rotation = MANHATTAN_DEGREES;
        let stopListsForRoute: StopLists = cachedStopListsForRoute[routeId];
        if (!stopListsForRoute) {
          stopListsForRoute = filteredStopListsForRoutes[routeId];
          cachedStopListsForRoute[routeId] = stopListsForRoute;
        }

        const stopsForRoute = flatten(stopListsForRoute);
        const stopIndex = stopsForRoute.findIndex(
          stop => stop.stopId === station.stopId
        );
        const stop: Stop | undefined = stopsForRoute[stopIndex];
        const nextStop: Stop | undefined = stopsForRoute[stopIndex + 1];

        const thisPosition = projectStation(station);
        // TODO: handle last stop on route, for which there is no nextStop
        if (!stop || !nextStop) {
          result = 0;
        } else {
          const nextStation = stations.find(
            someStation => someStation.stopId === nextStop.stopId
          )!;
          const nextPosition = projectStation(nextStation);
          const segmentId = getSegmentId(stop, nextStop);
          // const thisPosition = pointsForRoute[stopIndex];
          // const nextPosition = pointsForRoute[stopIndex + 1];
          const endpoints: LineAB = [thisPosition, nextPosition];
          const rotatedPoints = rotatePointsDegrees(endpoints, rotation);

          const strategyIndex: SegmentStrategyIndex =
            strategiesForSegments[segmentId] || 0;
          const strategy = getStrategyByIndex(strategyIndex);
          const strategizedRotated = strategy(rotatedPoints);
          const normalAnglesDegrees = pointsNormalAnglesDegrees(
            strategizedRotated
          ).map(angleDegrees => round(angleDegrees, 1));

          result = normalAnglesDegrees[0];
        }
      }

      results[station.stopId] = result || 0;
    });

    return results;
  }
);

// TODO: use in the map
export const getProjectedPointsByStopId = createSelector(
  getMapStations,
  (
    stations: Stations
  ): Readonly<{
    [stopId: string]: Point;
  }> => {
    return stations.reduce((projected, stationUnified) => {
      const { stopId, lon, lat } = stationUnified;
      projected[stopId] = fromLonLat([lon, lat]) as Point;
      return projected;
    }, {});
  }
);
