// 20190828235500

/**
 * We modified the data to handle re-routing and line forks, using this as base
 * data to sort the stops/stations when we don't have the PatternGraph (PG)
 * data or adding skipped or missing stops to the current PG data paths.
 * We use the data to create stops that will generate segments between
 * sequential stops.
 *
 * FORKS / BRANCHES -----------------------------------------------------------
 *
 * We create forks duplicating the route path ordered, duplicating some
 * branched stops in the reverse order, adding the `isDuplicated` props to
 * stops we will ignore — for example, a Route X with a branch in the end.
 *
 * Stop X2 branching into X3 and X5:
 * Stop X1 -> Stop X2 -> Stop X3 -> Stop X4
 *                    -> Stop X5
 *
 * The expected segments in the map are:
 * X1 -> X2, X2 -> X3, X3 -> X4, X2 -> X5
 *
 * We need the data in the array like:
 * {stopId: 'Stop X1', ... },
 * {stopId: 'Stop X2', ... }
 * {stopId: 'Stop X3, ... }
 * {stopId: 'Stop X4', ... }
 * // Ignored because we are reversing the branch
 * {stopId: 'Stop X3, isDuplicated true ... }
 * // We duplicate 2 without the prop to create the
 * // X2 -> X5 sequence/segment
 * {stopId: 'Stop X2', ... }
 * {stopId: 'Stop X5, ... }
 *
 * RE-ROUTING -----------------------------------------------------------------
 *
 * The map will automatically draw the re-routing in the map using the stops we
 * received from the PG. One problem is that some re-routing paths make the line
 * to be visually not right in the rendered map.
 *
 * To help mitigating it, we can duplicate the re-routing path from the route
 * we want the re-routing line to group within the map visually.
 *
 * For X base stops 1, 2, 3, 4, 5, 6, 7, and Y base stops 20, 21, 22, 23, 24,
 * 25, 26, 40, 42. An X re-routing from PG with 1, 2, 3, 4, 22, 26 could
 * generate weird lines on the map for segment 22 -> 26 because the map wouldn't
 * know if we need to add stops to create a better shape. To fix that, we could
 * duplicate part of the Y path we think makes sense replacing the `routeId`,
 * like that:
 * {stopId: 'Stop 22', routeId: 'X', isRerouting: true ... },
 * {stopId: 'Stop 23', routeId: 'X', isRerouting: true ... },
 * {stopId: 'Stop 24', routeId: 'X', isRerouting: true ... },
 * {stopId: 'Stop 25', routeId: 'X', isRerouting: true ... },
 * {stopId: 'Stop 26', routeId: 'X', isRerouting: true ... },
 */

import { OtpStopOnRoute } from '../subway-types';

const stopsOnRoutes: OtpStopOnRoute[] = [
  {
    stopSequence: '1',
    routeId: '1',
    stopId: '101',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: '1',
    stopId: '103',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: '1',
    stopId: '104',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: '1',
    stopId: '106',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: '1',
    stopId: '107',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: '1',
    stopId: '108',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: '1',
    stopId: '109',
    stopType: '0',
  },
  {
    stopSequence: '9',
    routeId: '1',
    stopId: '110',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: '1',
    stopId: '111',
    stopType: '0',
  },
  {
    stopSequence: '11',
    routeId: '1',
    stopId: '112',
    stopType: '0',
    stopStatus: '-1',
  },
  {
    stopSequence: '12',
    routeId: '1',
    stopId: '113',
    stopType: '0',
  },
  {
    stopSequence: '13',
    routeId: '1',
    stopId: '114',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: '1',
    stopId: '115',
    stopType: '0',
  },
  {
    stopSequence: '15',
    routeId: '1',
    stopId: '116',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: '1',
    stopId: '117',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: '1',
    stopId: '118',
    stopType: '0',
  },
  {
    stopSequence: '18',
    routeId: '1',
    stopId: '119',
    stopType: '0',
  },
  {
    stopSequence: '19',
    routeId: '1',
    stopId: '120',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: '1',
    stopId: '121',
    stopType: '0',
  },
  {
    stopSequence: '21',
    routeId: '1',
    stopId: '122',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: '1',
    stopId: '123',
    stopType: '0',
  },
  {
    stopSequence: '23',
    routeId: '1',
    stopId: '124',
    stopType: '0',
  },
  // Copied from routeId `2`
  {
    stopSequence: '24',
    routeId: '1',
    stopId: 'A24',
    stopType: '0',
  },
  {
    stopSequence: '25',
    routeId: '1',
    stopId: '126',
    stopType: '0',
  },
  {
    stopSequence: '26',
    routeId: '1',
    stopId: '127',
    stopType: '0',
  },
  {
    stopSequence: '27',
    routeId: '1',
    stopId: '128',
    stopType: '0',
  },
  {
    stopSequence: '28',
    routeId: '1',
    stopId: '129',
    stopType: '0',
  },
  {
    stopSequence: '29',
    routeId: '1',
    stopId: '130',
    stopType: '0',
  },
  {
    stopSequence: '30',
    routeId: '1',
    stopId: '131',
    stopType: '0',
  },
  {
    stopSequence: '31',
    routeId: '1',
    stopId: '132',
    stopType: '0',
  },
  {
    stopSequence: '32',
    routeId: '1',
    stopId: '133',
    stopType: '0',
  },
  {
    stopSequence: '33',
    routeId: '1',
    stopId: '134',
    stopType: '0',
  },
  {
    stopSequence: '34',
    routeId: '1',
    stopId: '135',
    stopType: '0',
  },
  {
    stopSequence: '35',
    routeId: '1',
    stopId: '136',
    stopType: '0',
  },
  {
    stopSequence: '36',
    routeId: '1',
    stopId: '137',
    stopType: '0',
  },
  {
    stopSequence: '37',
    routeId: '1',
    stopId: '138',
    stopType: '0',
  },
  {
    stopSequence: '38',
    routeId: '1',
    stopId: '139',
    stopType: '0',
  },
  {
    stopSequence: '39',
    routeId: '1',
    stopId: '142',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: '2',
    stopId: '201',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: '2',
    stopId: '204',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: '2',
    stopId: '205',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: '2',
    stopId: '206',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: '2',
    stopId: '207',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: '2',
    stopId: '208',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: '2',
    stopId: '209',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: '2',
    stopId: '210',
    stopType: '0',
  },
  {
    stopSequence: '9',
    routeId: '2',
    stopId: '211',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: '2',
    stopId: '212',
    stopType: '0',
  },
  {
    stopSequence: '11',
    routeId: '2',
    stopId: '213',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: '2',
    stopId: '214',
    stopType: '0',
  },
  {
    stopSequence: '13',
    routeId: '2',
    stopId: '215',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: '2',
    stopId: '216',
    stopType: '0',
  },
  {
    stopSequence: '15',
    routeId: '2',
    stopId: '217',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: '2',
    stopId: '218',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: '2',
    stopId: '219',
    stopType: '0',
  },
  {
    stopSequence: '18',
    routeId: '2',
    stopId: '220',
    stopType: '0',
  },
  {
    stopSequence: '19',
    routeId: '2',
    stopId: '221',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: '2',
    stopId: '222',
    stopType: '2',
  },
  // Copied from routeId `3`
  {
    stopSequence: '2',
    routeId: '2',
    stopId: '302',
    stopType: '2',
  },
  {
    stopSequence: '22',
    routeId: '2',
    stopId: '224',
    stopType: '0',
  },
  {
    stopSequence: '23',
    routeId: '2',
    stopId: '225',
    stopType: '0',
  },
  {
    stopSequence: '24',
    routeId: '2',
    stopId: '226',
    stopType: '0',
  },
  {
    stopSequence: '25',
    routeId: '2',
    stopId: '227',
    stopType: '0',
  },
  // Copied from routeId `1`
  {
    stopSequence: '18',
    routeId: '2',
    stopId: '119',
    stopType: '2',
  },
  {
    stopSequence: '26',
    routeId: '2',
    stopId: '120',
    stopType: '0',
  },
  {
    stopSequence: '27',
    routeId: '2',
    stopId: '121',
    stopType: '2',
  },
  {
    stopSequence: '28',
    routeId: '2',
    stopId: '122',
    stopType: '2',
  },
  {
    stopSequence: '29',
    routeId: '2',
    stopId: '123',
    stopType: '0',
  },
  {
    stopSequence: '30',
    routeId: '2',
    stopId: '124',
    stopType: '2',
  },
  // Copied from routeId `2`
  {
    stopSequence: '31',
    routeId: '2',
    stopId: 'A24',
    stopType: '2',
  },
  {
    stopSequence: '32',
    routeId: '2',
    stopId: '126',
    stopType: '2',
  },
  {
    stopSequence: '33',
    routeId: '2',
    stopId: '127',
    stopType: '0',
  },
  {
    stopSequence: '34',
    routeId: '2',
    stopId: '128',
    stopType: '0',
  },
  {
    stopSequence: '35',
    routeId: '2',
    stopId: '129',
    stopType: '2',
  },
  {
    stopSequence: '36',
    routeId: '2',
    stopId: '130',
    stopType: '2',
  },
  {
    stopSequence: '37',
    routeId: '2',
    stopId: '131',
    stopType: '2',
  },
  {
    stopSequence: '38',
    routeId: '2',
    stopId: '132',
    stopType: '0',
  },
  {
    stopSequence: '39',
    routeId: '2',
    stopId: '133',
    stopType: '2',
  },
  {
    stopSequence: '40',
    routeId: '2',
    stopId: '134',
    stopType: '2',
  },
  {
    stopSequence: '41',
    routeId: '2',
    stopId: '135',
    stopType: '2',
  },
  {
    stopSequence: '42',
    routeId: '2',
    stopId: '136',
    stopType: '2',
  },
  {
    stopSequence: '43',
    routeId: '2',
    stopId: '137',
    stopType: '0',
  },
  {
    stopSequence: '44',
    routeId: '2',
    stopId: '228',
    stopType: '0',
  },
  {
    stopSequence: '45',
    routeId: '2',
    stopId: '229',
    stopType: '0',
  },
  {
    stopSequence: '46',
    routeId: '2',
    stopId: '230',
    stopType: '0',
  },
  {
    stopSequence: '48',
    routeId: '2',
    stopId: '231',
    stopType: '0',
  },
  {
    stopSequence: '49',
    routeId: '2',
    stopId: '232',
    stopType: '0',
  },
  {
    stopSequence: '50',
    routeId: '2',
    stopId: '233',
    stopType: '0',
  },
  {
    stopSequence: '51',
    routeId: '2',
    stopId: '234',
    stopType: '0',
  },
  {
    stopSequence: '52',
    routeId: '2',
    stopId: '235',
    stopType: '0',
  },
  {
    stopSequence: '53',
    routeId: '2',
    stopId: '236',
    stopType: '0',
  },
  // ADDED to unite B/Q and 2/3/4/5
  {
    stopSequence: '17',
    routeId: '2',
    stopId: 'D25',
    stopType: '2',
  },
  {
    stopSequence: '54',
    routeId: '2',
    stopId: '237',
    stopType: '0',
  },
  {
    stopSequence: '55',
    routeId: '2',
    stopId: '238',
    stopType: '0',
  },
  {
    stopSequence: '56',
    routeId: '2',
    stopId: '239',
    stopType: '0',
  },
  {
    stopSequence: '57',
    routeId: '2',
    stopId: '241',
    stopType: '0',
  },
  {
    stopSequence: '58',
    routeId: '2',
    stopId: '242',
    stopType: '0',
    stopStatus: '-3',
  },
  {
    stopSequence: '59',
    routeId: '2',
    stopId: '243',
    stopType: '0',
  },
  {
    stopSequence: '60',
    routeId: '2',
    stopId: '244',
    stopType: '0',
  },
  {
    stopSequence: '61',
    routeId: '2',
    stopId: '245',
    stopType: '0',
  },
  {
    stopSequence: '62',
    routeId: '2',
    stopId: '246',
    stopType: '0',
  },
  {
    stopSequence: '63',
    routeId: '2',
    stopId: '247',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: '3',
    stopId: '301',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: '3',
    stopId: '302',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: '3',
    stopId: '224',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: '3',
    stopId: '225',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: '3',
    stopId: '226',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: '3',
    stopId: '227',
    stopType: '0',
  },
  // Copied from routeId `1`
  {
    stopSequence: '18',
    routeId: '3',
    stopId: '119',
    stopType: '2',
  },
  {
    stopSequence: '7',
    routeId: '3',
    stopId: '120',
    stopType: '0',
  },
  // Copied from routeId `1`
  {
    stopSequence: '20',
    routeId: '3',
    stopId: '121',
    stopType: '2',
  },
  // Copied from routeId `2`
  {
    stopSequence: '21',
    routeId: '3',
    stopId: '122',
    stopType: '2',
  },
  {
    stopSequence: '8',
    routeId: '3',
    stopId: '123',
    stopType: '0',
  },
  // Copied from routeId `2`
  {
    stopSequence: '23',
    routeId: '3',
    stopId: '124',
    stopType: '2',
  },
  // Copied from routeId `2`
  {
    stopSequence: '24',
    routeId: '3',
    stopId: 'A24',
    stopType: '2',
  },
  // Copied from routeId `1`
  {
    stopSequence: '25',
    routeId: '3',
    stopId: '126',
    stopType: '2',
  },
  {
    stopSequence: '9',
    routeId: '3',
    stopId: '127',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: '3',
    stopId: '128',
    stopType: '1',
  },
  // Copied from routeId `3`
  {
    stopSequence: '35',
    routeId: '3',
    stopId: '129',
    stopType: '2',
  },
  // Copied from routeId `3`
  {
    stopSequence: '36',
    routeId: '3',
    stopId: '130',
    stopType: '2',
  },
  // Copied from routeId `3`
  {
    stopSequence: '37',
    routeId: '3',
    stopId: '131',
    stopType: '2',
  },
  {
    stopSequence: '11',
    routeId: '3',
    stopId: '132',
    stopType: '1',
  },
  // Copied from routeId `1`
  {
    stopSequence: '32',
    routeId: '3',
    stopId: '133',
    stopType: '2',
  },
  // Copied from routeId `1`
  {
    stopSequence: '33',
    routeId: '3',
    stopId: '134',
    stopType: '2',
  },
  // Copied from routeId `1`
  {
    stopSequence: '34',
    routeId: '3',
    stopId: '135',
    stopType: '2',
  },
  // Copied from routeId `1`
  {
    stopSequence: '35',
    routeId: '3',
    stopId: '136',
    stopType: '2',
  },
  {
    stopSequence: '12',
    routeId: '3',
    stopId: '137',
    stopType: '1',
  },
  {
    stopSequence: '13',
    routeId: '3',
    stopId: '228',
    stopType: '1',
  },
  {
    stopSequence: '14',
    routeId: '3',
    stopId: '229',
    stopType: '1',
  },
  {
    stopSequence: '15',
    routeId: '3',
    stopId: '230',
    stopType: '1',
  },
  {
    stopSequence: '17',
    routeId: '3',
    stopId: '231',
    stopType: '1',
  },
  {
    stopSequence: '18',
    routeId: '3',
    stopId: '232',
    stopType: '1',
  },
  {
    stopSequence: '19',
    routeId: '3',
    stopId: '233',
    stopType: '1',
  },
  {
    stopSequence: '20',
    routeId: '3',
    stopId: '234',
    stopType: '1',
  },
  {
    stopSequence: '21',
    routeId: '3',
    stopId: '235',
    stopType: '1',
  },
  {
    stopSequence: '22',
    routeId: '3',
    stopId: '236',
    stopType: '1',
  },
  // ADDED to unite B/Q and 2/3/4/5
  {
    stopSequence: '17',
    routeId: '3',
    stopId: 'D25',
    stopType: '2',
  },
  {
    stopSequence: '23',
    routeId: '3',
    stopId: '237',
    stopType: '1',
  },
  {
    stopSequence: '24',
    routeId: '3',
    stopId: '238',
    stopType: '1',
  },
  {
    stopSequence: '25',
    routeId: '3',
    stopId: '239',
    stopType: '1',
  },
  {
    stopSequence: '26',
    routeId: '3',
    stopId: '248',
    stopType: '1',
  },
  {
    stopSequence: '27',
    routeId: '3',
    stopId: '249',
    stopType: '1',
  },
  {
    stopSequence: '28',
    routeId: '3',
    stopId: '250',
    stopType: '1',
  },
  {
    stopSequence: '29',
    routeId: '3',
    stopId: '251',
    stopType: '1',
  },
  {
    stopSequence: '30',
    routeId: '3',
    stopId: '252',
    stopType: '1',
  },
  {
    stopSequence: '31',
    routeId: '3',
    stopId: '253',
    stopType: '1',
  },
  {
    stopSequence: '32',
    routeId: '3',
    stopId: '254',
    stopType: '1',
  },
  {
    stopSequence: '33',
    routeId: '3',
    stopId: '255',
    stopType: '1',
  },
  {
    stopSequence: '34',
    routeId: '3',
    stopId: '256',
    stopType: '1',
  },
  {
    stopSequence: '35',
    routeId: '3',
    stopId: '257',
    stopType: '1',
  },
  {
    stopSequence: '1',
    routeId: '4',
    stopId: '401',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: '4',
    stopId: '402',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: '4',
    stopId: '405',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: '4',
    stopId: '406',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: '4',
    stopId: '407',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: '4',
    stopId: '408',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: '4',
    stopId: '409',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: '4',
    stopId: '410',
    stopType: '0',
  },
  {
    stopSequence: '9',
    routeId: '4',
    stopId: '411',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: '4',
    stopId: '412',
    stopType: '0',
  },
  {
    stopSequence: '11',
    routeId: '4',
    stopId: '413',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: '4',
    // Updated from `414`
    stopId: 'D11',
    stopType: '0',
  },
  {
    stopSequence: '13',
    routeId: '4',
    // Updated from `415`
    stopId: '222',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: '4',
    stopId: '416',
    stopType: '1',
  },
  {
    stopSequence: '16',
    routeId: '4',
    stopId: '621',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: '4',
    stopId: '622',
    stopType: '2',
  },
  {
    stopSequence: '18',
    routeId: '4',
    stopId: '623',
    stopType: '2',
  },
  {
    stopSequence: '19',
    routeId: '4',
    stopId: '624',
    stopType: '2',
  },
  {
    stopSequence: '20',
    routeId: '4',
    stopId: '625',
    stopType: '2',
  },
  {
    stopSequence: '21',
    routeId: '4',
    stopId: '626',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: '4',
    stopId: '627',
    stopType: '2',
  },
  {
    stopSequence: '23',
    routeId: '4',
    stopId: '628',
    stopType: '2',
  },
  {
    stopSequence: '24',
    routeId: '4',
    stopId: '629',
    stopType: '0',
  },
  {
    stopSequence: '25',
    routeId: '4',
    stopId: '630',
    stopType: '2',
  },
  {
    stopSequence: '26',
    routeId: '4',
    stopId: '631',
    stopType: '0',
  },
  {
    stopSequence: '27',
    routeId: '4',
    stopId: '632',
    stopType: '2',
  },
  {
    stopSequence: '28',
    routeId: '4',
    stopId: '633',
    stopType: '2',
  },
  {
    stopSequence: '29',
    routeId: '4',
    stopId: '634',
    stopType: '2',
  },
  {
    stopSequence: '30',
    routeId: '4',
    // Updated from `635`
    stopId: 'R20',
    stopType: '0',
  },
  {
    stopSequence: '31',
    routeId: '4',
    stopId: '636',
    stopType: '2',
  },
  {
    stopSequence: '32',
    routeId: '4',
    stopId: '637',
    stopType: '2',
  },
  {
    stopSequence: '33',
    routeId: '4',
    stopId: '638',
    stopType: '2',
  },
  {
    stopSequence: '34',
    routeId: '4',
    // Updated from `639`
    stopId: 'Q01',
    stopType: '2',
  },
  {
    stopSequence: '35',
    routeId: '4',
    stopId: '640',
    stopType: '0',
  },
  {
    stopSequence: '36',
    routeId: '4',
    stopId: '418',
    stopType: '0',
  },
  {
    stopSequence: '37',
    routeId: '4',
    stopId: '419',
    stopType: '0',
  },
  {
    stopSequence: '38',
    routeId: '4',
    stopId: '420',
    stopType: '0',
  },
  {
    stopSequence: '40',
    routeId: '4',
    // Updated from `423`
    stopId: '232',
    stopType: '0',
  },
  // Copied from routeId `2`
  {
    stopSequence: '50',
    routeId: '4',
    stopId: '233',
    stopType: '2',
  },
  {
    stopSequence: '41',
    routeId: '4',
    stopId: '234',
    stopType: '0',
  },
  {
    stopSequence: '42',
    routeId: '4',
    stopId: '235',
    stopType: '0',
  },
  {
    stopSequence: '43',
    routeId: '4',
    stopId: '236',
    stopType: '2',
  },
  // ADDED to unite B/Q and 2/3/4/5
  {
    stopSequence: '17',
    routeId: '4',
    stopId: 'D25',
    stopType: '2',
  },
  {
    stopSequence: '44',
    routeId: '4',
    stopId: '237',
    stopType: '2',
  },
  {
    stopSequence: '45',
    routeId: '4',
    stopId: '238',
    stopType: '2',
  },
  {
    stopSequence: '46',
    routeId: '4',
    stopId: '239',
    stopType: '0',
  },
  {
    stopSequence: '47',
    routeId: '4',
    stopId: '248',
    stopType: '2',
  },
  {
    stopSequence: '48',
    routeId: '4',
    stopId: '249',
    stopType: '2',
  },
  {
    stopSequence: '49',
    routeId: '4',
    stopId: '250',
    stopType: '0',
  },
  {
    stopSequence: '50',
    routeId: '4',
    stopId: '251',
    stopType: '2',
  },
  {
    stopSequence: '51',
    routeId: '4',
    stopId: '252',
    stopType: '2',
  },
  {
    stopSequence: '52',
    routeId: '4',
    stopId: '253',
    stopType: '2',
  },
  {
    stopSequence: '53',
    routeId: '4',
    stopId: '254',
    stopType: '2',
  },
  {
    stopSequence: '54',
    routeId: '4',
    stopId: '255',
    stopType: '2',
  },
  {
    stopSequence: '55',
    routeId: '4',
    stopId: '256',
    stopType: '2',
  },
  {
    stopSequence: '56',
    routeId: '4',
    stopId: '257',
    stopType: '2',
  },
  {
    stopSequence: '1',
    routeId: '5',
    stopId: '501',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: '5',
    stopId: '502',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: '5',
    stopId: '503',
    stopType: '0',
    stopStatus: '-3',
  },
  {
    stopSequence: '4',
    routeId: '5',
    stopId: '504',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: '5',
    stopId: '505',
    stopType: '0',
  },
  /*
  // Doubling back on itself to accurately render fork in route
  {
    stopSequence: '14',
    routeId: '5',
    stopId: '212',
    stopType: '3',
  },
  // Doubling back on itself to accurately render fork in route
  {
    stopSequence: '13',
    routeId: '5',
    stopId: '211',
    stopType: '3',
  },
  // Doubling back on itself to accurately render fork in route
  {
    stopSequence: '12',
    routeId: '5',
    stopId: '210',
    stopType: '3',
  },
  // Doubling back on itself to accurately render fork in route
  {
    stopSequence: '11',
    routeId: '5',
    stopId: '209',
    stopType: '3',
  },
  // Doubling back on itself to accurately render fork in route
  {
    stopSequence: '10',
    routeId: '5',
    stopId: '208',
    stopType: '3',
  },
  // Doubling back on itself to accurately render fork in route
  {
    stopSequence: '9',
    routeId: '5',
    stopId: '207',
    stopType: '3',
  },
  // Doubling back on itself to accurately render fork in route
  {
    stopSequence: '8',
    routeId: '5',
    stopId: '206',
    stopType: '3',
  },
  // Doubling back on itself to accurately render fork in route
  {
    stopSequence: '7',
    routeId: '5',
    stopId: '205',
    stopType: '3',
  },
 */
  /*
  {
    stopSequence: '6',
    routeId: '5',
    stopId: '204',
    stopType: '3',
  },
  {
    stopSequence: '7',
    routeId: '5',
    stopId: '205',
    stopType: '3',
  },
  {
    stopSequence: '8',
    routeId: '5',
    stopId: '206',
    stopType: '3',
  },
  {
    stopSequence: '9',
    routeId: '5',
    stopId: '207',
    stopType: '3',
  },
  {
    stopSequence: '10',
    routeId: '5',
    stopId: '208',
    stopType: '3',
  },
  {
    stopSequence: '11',
    routeId: '5',
    stopId: '209',
    stopType: '3',
  },
  {
    stopSequence: '12',
    routeId: '5',
    stopId: '210',
    stopType: '3',
  },
  {
    stopSequence: '13',
    routeId: '5',
    stopId: '211',
    stopType: '3',
  },
  */
  {
    stopSequence: '14',
    routeId: '5',
    stopId: '212',
    stopType: '3',
  },
  {
    stopSequence: '15',
    routeId: '5',
    stopId: '213',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: '5',
    stopId: '214',
    stopType: '1',
  },
  {
    stopSequence: '17',
    routeId: '5',
    stopId: '215',
    stopType: '1',
  },
  {
    stopSequence: '18',
    routeId: '5',
    stopId: '216',
    stopType: '1',
  },
  {
    stopSequence: '19',
    routeId: '5',
    stopId: '217',
    stopType: '1',
  },
  {
    stopSequence: '20',
    routeId: '5',
    stopId: '218',
    stopType: '1',
  },
  {
    stopSequence: '21',
    routeId: '5',
    stopId: '219',
    stopType: '1',
  },
  {
    stopSequence: '22',
    routeId: '5',
    stopId: '220',
    stopType: '1',
  },
  {
    stopSequence: '23',
    routeId: '5',
    stopId: '221',
    stopType: '1',
  },
  {
    stopSequence: '24',
    routeId: '5',
    stopId: '222',
    stopType: '1',
  },
  {
    stopSequence: '25',
    routeId: '5',
    stopId: '416',
    stopType: '1',
  },
  {
    stopSequence: '27',
    routeId: '5',
    stopId: '621',
    stopType: '1',
  },
  // Copied from routeId `4`
  {
    stopSequence: '22',
    routeId: '5',
    stopId: '627',
    stopType: '2',
  },
  // Copied from routeId `4`
  {
    stopSequence: '17',
    routeId: '5',
    stopId: '622',
    stopType: '2',
  },
  // Copied from routeId `4`
  {
    stopSequence: '18',
    routeId: '5',
    stopId: '623',
    stopType: '2',
  },
  // Copied from routeId `4`
  {
    stopSequence: '19',
    routeId: '5',
    stopId: '624',
    stopType: '2',
  },
  // Copied from routeId `4`
  {
    stopSequence: '20',
    routeId: '5',
    stopId: '625',
    stopType: '2',
  },
  {
    stopSequence: '28',
    routeId: '5',
    stopId: '626',
    stopType: '1',
  },
  // Copied from routeId `4`
  {
    stopSequence: '23',
    routeId: '5',
    stopId: '628',
    stopType: '2',
  },
  {
    stopSequence: '29',
    routeId: '5',
    stopId: '629',
    stopType: '1',
  },
  // Copied from routeId `4`
  {
    stopSequence: '25',
    routeId: '5',
    stopId: '630',
    stopType: '2',
  },
  {
    stopSequence: '30',
    routeId: '5',
    stopId: '631',
    stopType: '1',
  },
  // Copied from routeId `4`
  {
    stopSequence: '31',
    routeId: '5',
    stopId: '632',
    stopType: '2',
  },
  // Copied from routeId `4`
  {
    stopSequence: '32',
    routeId: '5',
    stopId: '633',
    stopType: '2',
  },
  // Copied from routeId `4`
  {
    stopSequence: '33',
    routeId: '5',
    stopId: '634',
    stopType: '2',
  },
  {
    stopSequence: '31',
    routeId: '5',
    // Updated from `635`
    stopId: 'R20',
    stopType: '1',
  },
  // Copied from routeId `6`
  {
    stopSequence: '35',
    routeId: '5',
    stopId: '636',
    stopType: '2',
  },
  // Copied from routeId `6`
  {
    stopSequence: '36',
    routeId: '5',
    stopId: '637',
    stopType: '2',
  },
  // Copied from routeId `6`
  {
    stopSequence: '37',
    routeId: '5',
    stopId: '638',
    stopType: '2',
  },
  // Copied from routeId `6`
  {
    stopSequence: '38',
    routeId: '5',
    // Updated from `639`
    stopId: 'Q01',
    stopType: '2',
  },
  {
    stopSequence: '32',
    routeId: '5',
    stopId: '640',
    stopType: '1',
  },
  {
    stopSequence: '33',
    routeId: '5',
    stopId: '418',
    stopType: '1',
  },
  {
    stopSequence: '34',
    routeId: '5',
    stopId: '419',
    stopType: '1',
  },
  {
    stopSequence: '35',
    routeId: '5',
    stopId: '420',
    stopType: '1',
  },
  {
    stopSequence: '37',
    routeId: '5',
    // Updated from `423`
    stopId: '232',
    stopType: '1',
  },
  // Copied from routeId `2`
  {
    stopSequence: '50',
    routeId: '5',
    stopId: '233',
    stopType: '2',
  },
  {
    stopSequence: '38',
    routeId: '5',
    stopId: '234',
    stopType: '1',
  },
  {
    stopSequence: '39',
    routeId: '5',
    stopId: '235',
    stopType: '1',
  },
  // Copied from routeId `4`
  {
    stopSequence: '43',
    routeId: '5',
    stopId: '236',
    stopType: '2',
  },
  // ADDED to unite B/Q and 2/3/4/5
  {
    stopSequence: '17',
    routeId: '5',
    stopId: 'D25',
    stopType: '2',
  },
  // Copied from routeId `4`
  {
    stopSequence: '44',
    routeId: '5',
    stopId: '237',
    stopType: '2',
  },
  // Copied from routeId `4`
  {
    stopSequence: '45',
    routeId: '5',
    stopId: '238',
    stopType: '2',
  },
  {
    stopSequence: '40',
    routeId: '5',
    stopId: '239',
    stopType: '1',
  },
  // Copied from routeId `4`
  {
    stopSequence: '47',
    routeId: '5',
    stopId: '248',
    stopType: '2',
  },
  // Copied from routeId `4`
  {
    stopSequence: '48',
    routeId: '5',
    stopId: '249',
    stopType: '2',
  },
  // Copied from routeId `4`
  {
    stopSequence: '49',
    routeId: '5',
    stopId: '250',
    stopType: '0',
  },
  // Duplicated to handle occasional fork in the 5
  {
    isDuplicated: true,
    stopSequence: '48',
    routeId: '5',
    stopId: '249',
    stopType: '2',
  },
  // Duplicated to handle occasional fork in the 5
  {
    isDuplicated: true,
    stopSequence: '47',
    routeId: '5',
    stopId: '248',
    stopType: '2',
  },
  {
    stopSequence: '40',
    routeId: '5',
    stopId: '239',
    stopType: '1',
  },
  {
    stopSequence: '41',
    routeId: '5',
    stopId: '241',
    stopType: '1',
  },
  {
    stopSequence: '42',
    routeId: '5',
    stopId: '242',
    stopType: '1',
    stopStatus: '-3',
  },
  {
    stopSequence: '43',
    routeId: '5',
    stopId: '243',
    stopType: '1',
  },
  {
    stopSequence: '44',
    routeId: '5',
    stopId: '244',
    stopType: '1',
  },
  {
    stopSequence: '45',
    routeId: '5',
    stopId: '245',
    stopType: '1',
  },
  {
    stopSequence: '46',
    routeId: '5',
    stopId: '246',
    stopType: '1',
  },
  {
    stopSequence: '47',
    routeId: '5',
    stopId: '247',
    stopType: '1',
  },
  {
    stopSequence: '1',
    routeId: '6',
    stopId: '601',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: '6',
    stopId: '602',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: '6',
    stopId: '603',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: '6',
    stopId: '604',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: '6',
    stopId: '606',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: '6',
    stopId: '607',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: '6',
    stopId: '608',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: '6',
    stopId: '609',
    stopType: '0',
  },
  {
    stopSequence: '9',
    routeId: '6',
    stopId: '610',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: '6',
    stopId: '611',
    stopType: '0',
  },
  {
    stopSequence: '11',
    routeId: '6',
    stopId: '612',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: '6',
    stopId: '613',
    stopType: '0',
  },
  {
    stopSequence: '13',
    routeId: '6',
    stopId: '614',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: '6',
    stopId: '615',
    stopType: '0',
  },
  {
    stopSequence: '15',
    routeId: '6',
    stopId: '616',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: '6',
    stopId: '617',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: '6',
    stopId: '618',
    stopType: '0',
  },
  {
    stopSequence: '18',
    routeId: '6',
    stopId: '619',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: '6',
    stopId: '621',
    stopType: '0',
  },
  {
    stopSequence: '21',
    routeId: '6',
    stopId: '622',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: '6',
    stopId: '623',
    stopType: '0',
  },
  {
    stopSequence: '23',
    routeId: '6',
    stopId: '624',
    stopType: '0',
  },
  {
    stopSequence: '24',
    routeId: '6',
    stopId: '625',
    stopType: '0',
  },
  {
    stopSequence: '25',
    routeId: '6',
    stopId: '626',
    stopType: '0',
  },
  {
    stopSequence: '26',
    routeId: '6',
    stopId: '627',
    stopType: '0',
  },
  {
    stopSequence: '27',
    routeId: '6',
    stopId: '628',
    stopType: '0',
  },
  {
    stopSequence: '28',
    routeId: '6',
    stopId: '629',
    stopType: '0',
  },
  {
    stopSequence: '29',
    routeId: '6',
    stopId: '630',
    stopType: '0',
  },
  {
    stopSequence: '30',
    routeId: '6',
    stopId: '631',
    stopType: '0',
  },
  {
    stopSequence: '31',
    routeId: '6',
    stopId: '632',
    stopType: '0',
  },
  {
    stopSequence: '32',
    routeId: '6',
    stopId: '633',
    stopType: '0',
  },
  {
    stopSequence: '33',
    routeId: '6',
    stopId: '634',
    stopType: '0',
  },
  {
    stopSequence: '34',
    routeId: '6',
    // Updated from `635`
    stopId: 'R20',
    stopType: '0',
  },
  {
    stopSequence: '35',
    routeId: '6',
    stopId: '636',
    stopType: '0',
  },
  {
    stopSequence: '36',
    routeId: '6',
    stopId: '637',
    stopType: '0',
  },
  {
    stopSequence: '37',
    routeId: '6',
    stopId: '638',
    stopType: '0',
  },
  {
    stopSequence: '38',
    routeId: '6',
    // Updated from `639`
    stopId: 'Q01',
    stopType: '0',
  },
  {
    stopSequence: '39',
    routeId: '6',
    stopId: '640',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: '7',
    stopId: '701',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: '7',
    stopId: '702',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: '7',
    stopId: '705',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: '7',
    stopId: '706',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: '7',
    stopId: '707',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: '7',
    stopId: '708',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: '7',
    stopId: '709',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: '7',
    stopId: '710',
    stopType: '0',
  },
  {
    stopSequence: '9',
    routeId: '7',
    stopId: '711',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: '7',
    stopId: '712',
    stopType: '0',
  },
  {
    stopSequence: '11',
    routeId: '7',
    stopId: '713',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: '7',
    stopId: '714',
    stopType: '0',
  },
  {
    stopSequence: '13',
    routeId: '7',
    stopId: '715',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: '7',
    stopId: '716',
    stopType: '0',
  },
  {
    stopSequence: '15',
    routeId: '7',
    // Updated from `719`
    stopId: 'R09',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: '7',
    stopId: '719',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: '7',
    stopId: '720',
    stopType: '0',
  },
  {
    stopSequence: '18',
    routeId: '7',
    stopId: '721',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: '7',
    // Updated from `723`
    stopId: '631',
    stopType: '0',
  },
  {
    stopSequence: '21',
    routeId: '7',
    stopId: '724',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: '7',
    // Updated from `725`
    stopId: '127',
    stopType: '0',
  },
  {
    stopSequence: '23',
    routeId: '7',
    stopId: '726',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'A',
    stopId: 'A02',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'A',
    stopId: 'A03',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: 'A',
    stopId: 'A05',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'A',
    stopId: 'A06',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: 'A',
    stopId: 'A07',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: 'A',
    stopId: 'A09',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: 'A',
    stopId: 'A10',
    stopType: '2',
  },
  {
    stopSequence: '8',
    routeId: 'A',
    stopId: 'A11',
    stopType: '2',
  },
  {
    stopSequence: '9',
    routeId: 'A',
    stopId: 'A12',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: 'A',
    stopId: 'A14',
    stopType: '2',
  },
  {
    stopSequence: '11',
    routeId: 'A',
    stopId: 'A15',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: 'A',
    stopId: 'A16',
    stopType: '2',
  },
  {
    stopSequence: '13',
    routeId: 'A',
    stopId: 'A17',
    stopType: '2',
  },
  {
    stopSequence: '14',
    routeId: 'A',
    stopId: 'A18',
    stopType: '2',
  },
  {
    stopSequence: '15',
    routeId: 'A',
    stopId: 'A19',
    stopType: '2',
  },
  {
    stopSequence: '16',
    routeId: 'A',
    stopId: 'A20',
    stopType: '2',
  },
  {
    stopSequence: '17',
    routeId: 'A',
    stopId: 'A21',
    stopType: '2',
  },
  {
    stopSequence: '18',
    routeId: 'A',
    stopId: 'A22',
    stopType: '2',
  },
  {
    stopSequence: '19',
    routeId: 'A',
    stopId: 'A24',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: 'A',
    stopId: 'A25',
    stopType: '2',
  },
  {
    stopSequence: '21',
    routeId: 'A',
    stopId: 'A27',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: 'A',
    stopId: 'A28',
    stopType: '0',
  },
  {
    stopSequence: '23',
    routeId: 'A',
    stopId: 'A30',
    stopType: '2',
  },
  {
    stopSequence: '24',
    routeId: 'A',
    stopId: 'A31',
    stopType: '0',
  },
  {
    stopSequence: '25',
    routeId: 'A',
    stopId: 'A32',
    stopType: '0',
  },
  {
    stopSequence: '26',
    routeId: 'A',
    stopId: 'A33',
    stopType: '2',
  },
  {
    stopSequence: '27',
    routeId: 'A',
    stopId: 'A34',
    stopType: '0',
  },
  {
    stopSequence: '28',
    routeId: 'A',
    stopId: 'A36',
    stopType: '0',
  },
  {
    stopSequence: '29',
    routeId: 'A',
    stopId: 'A38',
    stopType: '0',
  },
  {
    stopSequence: '31',
    routeId: 'A',
    stopId: 'A40',
    stopType: '0',
  },
  {
    stopSequence: '32',
    routeId: 'A',
    stopId: 'A41',
    stopType: '0',
  },
  {
    stopSequence: '33',
    routeId: 'A',
    stopId: 'A42',
    stopType: '0',
  },
  {
    stopSequence: '34',
    routeId: 'A',
    stopId: 'A43',
    stopType: '2',
  },
  {
    stopSequence: '35',
    routeId: 'A',
    stopId: 'A44',
    stopType: '2',
  },
  {
    stopSequence: '36',
    routeId: 'A',
    stopId: 'A45',
    stopType: '2',
  },
  {
    stopSequence: '37',
    routeId: 'A',
    stopId: 'A46',
    stopType: '0',
  },
  {
    stopSequence: '38',
    routeId: 'A',
    stopId: 'A47',
    stopType: '2',
  },
  {
    stopSequence: '39',
    routeId: 'A',
    stopId: 'A48',
    stopType: '0',
  },
  {
    stopSequence: '40',
    routeId: 'A',
    stopId: 'A49',
    stopType: '2',
  },
  {
    stopSequence: '41',
    routeId: 'A',
    stopId: 'A50',
    stopType: '2',
  },
  {
    stopSequence: '42',
    routeId: 'A',
    // Updated from `A51`
    stopId: 'J27',
    stopType: '0',
  },
  {
    stopSequence: '43',
    routeId: 'A',
    stopId: 'A52',
    stopType: '2',
  },
  {
    stopSequence: '44',
    routeId: 'A',
    stopId: 'A53',
    stopType: '2',
  },
  {
    stopSequence: '45',
    routeId: 'A',
    stopId: 'A54',
    stopType: '2',
  },
  {
    stopSequence: '46',
    routeId: 'A',
    stopId: 'A55',
    stopType: '0',
  },
  {
    stopSequence: '47',
    routeId: 'A',
    stopId: 'A57',
    stopType: '0',
  },
  {
    stopSequence: '49',
    routeId: 'A',
    stopId: 'A59',
    stopType: '0',
  },
  {
    stopSequence: '50',
    routeId: 'A',
    stopId: 'A60',
    stopType: '0',
  },
  {
    stopSequence: '51',
    routeId: 'A',
    stopId: 'A61',
    stopType: '0',
  },
  {
    stopSequence: '52',
    routeId: 'A',
    stopId: 'A63',
    stopType: '0',
  },
  {
    stopSequence: '53',
    routeId: 'A',
    stopId: 'A64',
    stopType: '0',
  },
  {
    stopSequence: '54',
    routeId: 'A',
    stopId: 'A65',
    stopType: '0',
  },
  // Doubling back on itself to accurately render fork in route
  {
    isDuplicated: true,
    stopSequence: '53',
    routeId: 'A',
    stopId: 'A64',
    stopType: '0',
  },
  // Doubling back on itself to accurately render fork in route
  {
    isDuplicated: true,
    stopSequence: '52',
    routeId: 'A',
    stopId: 'A63',
    stopType: '0',
  },
  // Doubling back on itself to accurately render fork in route
  {
    stopSequence: '51',
    routeId: 'A',
    stopId: 'A61',
    stopType: '0',
  },
  {
    stopSequence: '55',
    routeId: 'A',
    stopId: 'H01',
    stopType: '0',
  },
  {
    stopSequence: '56',
    routeId: 'A',
    stopId: 'H02',
    stopType: '0',
  },
  {
    stopSequence: '57',
    routeId: 'A',
    stopId: 'H03',
    stopType: '0',
  },
  {
    stopSequence: '58',
    routeId: 'A',
    stopId: 'H04',
    stopType: '0',
  },
  {
    stopSequence: '59',
    routeId: 'A',
    stopId: 'H06',
    stopType: '0',
  },
  {
    stopSequence: '60',
    routeId: 'A',
    stopId: 'H07',
    stopType: '0',
  },
  {
    stopSequence: '61',
    routeId: 'A',
    stopId: 'H08',
    stopType: '0',
  },
  {
    stopSequence: '62',
    routeId: 'A',
    stopId: 'H09',
    stopType: '0',
  },
  {
    stopSequence: '63',
    routeId: 'A',
    stopId: 'H10',
    stopType: '0',
  },
  {
    stopSequence: '64',
    routeId: 'A',
    stopId: 'H11',
    stopType: '0',
  },
  // Doubling back on itself to accurately render fork in route
  {
    isDuplicated: true,
    stopSequence: '63',
    routeId: 'A',
    stopId: 'H10',
    stopType: '0',
  },
  // Doubling back on itself to accurately render fork in route
  {
    isDuplicated: true,
    stopSequence: '62',
    routeId: 'A',
    stopId: 'H09',
    stopType: '0',
  },
  // Doubling back on itself to accurately render fork in route
  {
    isDuplicated: true,
    stopSequence: '61',
    routeId: 'A',
    stopId: 'H08',
    stopType: '0',
  },
  // Doubling back on itself to accurately render fork in route
  {
    isDuplicated: true,
    stopSequence: '60',
    routeId: 'A',
    stopId: 'H07',
    stopType: '0',
  },
  // Doubling back on itself to accurately render fork in route
  {
    isDuplicated: true,
    stopSequence: '59',
    routeId: 'A',
    stopId: 'H06',
    stopType: '0',
  },
  // Doubling back on itself to accurately render fork in route
  {
    stopSequence: '58',
    routeId: 'A',
    stopId: 'H04',
    stopType: '0',
  },
  {
    stopSequence: '65',
    routeId: 'A',
    stopId: 'H12',
    stopType: '3',
  },
  {
    stopSequence: '66',
    routeId: 'A',
    stopId: 'H13',
    stopType: '3',
  },
  {
    stopSequence: '67',
    routeId: 'A',
    stopId: 'H14',
    stopType: '3',
  },
  {
    stopSequence: '68',
    routeId: 'A',
    stopId: 'H15',
    stopType: '3',
  },
  {
    stopSequence: '1',
    routeId: 'B',
    stopId: 'D03',
    stopType: '4',
  },
  {
    stopSequence: '2',
    routeId: 'B',
    stopId: 'D04',
    stopType: '4',
  },
  {
    stopSequence: '3',
    routeId: 'B',
    stopId: 'D05',
    stopType: '4',
  },
  {
    stopSequence: '4',
    routeId: 'B',
    stopId: 'D06',
    stopType: '4',
  },
  {
    stopSequence: '5',
    routeId: 'B',
    stopId: 'D07',
    stopType: '4',
  },
  {
    stopSequence: '6',
    routeId: 'B',
    stopId: 'D08',
    stopType: '4',
  },
  {
    stopSequence: '7',
    routeId: 'B',
    stopId: 'D09',
    stopType: '4',
  },
  {
    stopSequence: '8',
    routeId: 'B',
    stopId: 'D10',
    stopType: '4',
  },
  {
    stopSequence: '9',
    routeId: 'B',
    stopId: 'D11',
    stopType: '4',
  },
  {
    stopSequence: '11',
    routeId: 'B',
    stopId: 'D12',
    stopType: '4',
  },
  // Updated from 'D13'
  {
    stopSequence: '12',
    routeId: 'B',
    stopId: 'A12',
    stopType: '1',
  },
  {
    stopSequence: '13',
    routeId: 'B',
    stopId: 'A14',
    stopType: '1',
  },
  {
    stopSequence: '14',
    routeId: 'B',
    stopId: 'A15',
    stopType: '1',
  },
  {
    stopSequence: '15',
    routeId: 'B',
    stopId: 'A16',
    stopType: '1',
  },
  {
    stopSequence: '16',
    routeId: 'B',
    stopId: 'A17',
    stopType: '1',
  },
  {
    stopSequence: '17',
    routeId: 'B',
    stopId: 'A18',
    stopType: '1',
  },
  {
    stopSequence: '18',
    routeId: 'B',
    stopId: 'A19',
    stopType: '1',
  },
  {
    stopSequence: '19',
    routeId: 'B',
    stopId: 'A20',
    stopType: '1',
  },
  {
    stopSequence: '20',
    routeId: 'B',
    stopId: 'A21',
    stopType: '1',
  },
  {
    stopSequence: '21',
    routeId: 'B',
    stopId: 'A22',
    stopType: '1',
  },
  {
    stopSequence: '22',
    routeId: 'B',
    stopId: 'A24',
    stopType: '1',
  },
  {
    stopSequence: '23',
    routeId: 'B',
    stopId: 'D14',
    stopType: '1',
  },
  {
    stopSequence: '24',
    routeId: 'B',
    stopId: 'D15',
    stopType: '1',
  },
  {
    stopSequence: '25',
    routeId: 'B',
    stopId: 'D16',
    stopType: '1',
  },
  {
    stopSequence: '26',
    routeId: 'B',
    //updated from 'D17'
    stopId: 'R17',
    stopType: '1',
  },
  // Copied from routeId `M`
  {
    stopSequence: '18',
    routeId: 'B',
    stopId: 'D18',
    stopType: '2',
  },
  // Copied from routeId `M`
  {
    stopSequence: '22',
    routeId: 'B',
    stopId: 'D19',
    stopType: '2',
  },
  {
    stopSequence: '27',
    routeId: 'B',
    //updated from 'D20'
    stopId: 'A32',
    stopType: '1',
  },
  {
    stopSequence: '28',
    routeId: 'B',
    stopId: 'D21',
    stopType: '1',
  },
  {
    stopSequence: '29',
    routeId: 'B',
    stopId: 'D22',
    stopType: '1',
  },
  {
    stopSequence: '31',
    routeId: 'B',
    stopId: 'R30',
    stopType: '1',
  },
  // ADDED to pass through Nevins
  {
    stopSequence: '31',
    routeId: 'B',
    stopId: '234',
    stopType: '2',
  },
  {
    stopSequence: '32',
    routeId: 'B',
    // EDITED to unite the B/Q and 2/3/4/5 clusters
    // stopId: 'D24',
    stopId: '235',
    stopType: '1',
  },
  // ADDED to unite B/Q and 2/3/4/5
  {
    stopSequence: '17',
    routeId: 'B',
    stopId: '236',
    stopType: '2',
  },
  {
    stopSequence: '33',
    routeId: 'B',
    stopId: 'D25',
    stopType: '1',
  },
  // ADDED to unite B/Q and 2/3/4/5 at Grand Army Plaza
  // REVERTED
  // {
  //   stopSequence: '17',
  //   routeId: 'B',
  //   stopId: '237',
  //   stopType: '2',
  // },
  {
    stopSequence: '34',
    routeId: 'B',
    stopId: 'D26',
    stopType: '1',
  },
  // Copied from routeId `Q`
  {
    stopSequence: '34',
    routeId: 'B',
    stopId: 'D27',
    stopType: '2',
  },
  {
    stopSequence: '35',
    routeId: 'B',
    stopId: 'D28',
    stopType: '1',
  },
  // Copied from routeId `Q`
  {
    stopSequence: '22',
    routeId: 'B',
    stopId: 'D29',
    stopType: '2',
  },
  // Copied from routeId `Q`
  {
    stopSequence: '23',
    routeId: 'B',
    stopId: 'D30',
    stopType: '2',
  },
  {
    stopSequence: '36',
    routeId: 'B',
    stopId: 'D31',
    stopType: '1',
  },
  // Copied from routeId `Q`
  {
    stopSequence: '25',
    routeId: 'B',
    stopId: 'D32',
    stopType: '2',
  },
  // Copied from routeId `Q`
  {
    stopSequence: '26',
    routeId: 'B',
    stopId: 'D33',
    stopType: '2',
  },
  // Copied from routeId `Q`
  {
    stopSequence: '27',
    routeId: 'B',
    stopId: 'D34',
    stopType: '2',
  },
  {
    stopSequence: '37',
    routeId: 'B',
    stopId: 'D35',
    stopType: '1',
  },
  // Copied from routeId `Q`
  {
    stopSequence: '29',
    routeId: 'B',
    stopId: 'D37',
    stopType: '2',
  },
  // Copied from routeId `Q`
  {
    stopSequence: '30',
    routeId: 'B',
    stopId: 'D38',
    stopType: '2',
  },
  {
    stopSequence: '38',
    routeId: 'B',
    stopId: 'D39',
    stopType: '1',
  },
  {
    stopSequence: '39',
    routeId: 'B',
    stopId: 'D40',
    stopType: '1',
  },
  {
    stopSequence: '1',
    routeId: 'C',
    stopId: 'A09',
    stopType: '1',
  },
  {
    stopSequence: '2',
    routeId: 'C',
    stopId: 'A10',
    stopType: '1',
  },
  {
    stopSequence: '3',
    routeId: 'C',
    stopId: 'A11',
    stopType: '1',
  },
  {
    stopSequence: '4',
    routeId: 'C',
    stopId: 'A12',
    stopType: '1',
  },
  {
    stopSequence: '5',
    routeId: 'C',
    stopId: 'A14',
    stopType: '1',
  },
  {
    stopSequence: '6',
    routeId: 'C',
    stopId: 'A15',
    stopType: '1',
  },
  {
    stopSequence: '7',
    routeId: 'C',
    stopId: 'A16',
    stopType: '1',
  },
  {
    stopSequence: '8',
    routeId: 'C',
    stopId: 'A17',
    stopType: '1',
  },
  {
    stopSequence: '9',
    routeId: 'C',
    stopId: 'A18',
    stopType: '1',
  },
  {
    stopSequence: '10',
    routeId: 'C',
    stopId: 'A19',
    stopType: '1',
  },
  {
    stopSequence: '11',
    routeId: 'C',
    stopId: 'A20',
    stopType: '1',
  },
  {
    stopSequence: '12',
    routeId: 'C',
    stopId: 'A21',
    stopType: '1',
  },
  {
    stopSequence: '13',
    routeId: 'C',
    stopId: 'A22',
    stopType: '1',
  },
  {
    stopSequence: '14',
    routeId: 'C',
    stopId: 'A24',
    stopType: '1',
  },
  {
    stopSequence: '15',
    routeId: 'C',
    stopId: 'A25',
    stopType: '1',
  },
  {
    stopSequence: '16',
    routeId: 'C',
    stopId: 'A27',
    stopType: '1',
  },
  {
    stopSequence: '17',
    routeId: 'C',
    stopId: 'A28',
    stopType: '1',
  },
  {
    stopSequence: '18',
    routeId: 'C',
    stopId: 'A30',
    stopType: '1',
  },
  {
    stopSequence: '19',
    routeId: 'C',
    stopId: 'A31',
    stopType: '1',
  },
  {
    stopSequence: '20',
    routeId: 'C',
    stopId: 'A32',
    stopType: '1',
  },
  {
    stopSequence: '21',
    routeId: 'C',
    stopId: 'A33',
    stopType: '1',
  },
  {
    stopSequence: '22',
    routeId: 'C',
    stopId: 'A34',
    stopType: '1',
  },
  {
    stopSequence: '23',
    routeId: 'C',
    stopId: 'A36',
    stopType: '1',
  },
  {
    stopSequence: '24',
    routeId: 'C',
    stopId: 'A38',
    stopType: '1',
  },
  {
    stopSequence: '26',
    routeId: 'C',
    stopId: 'A40',
    stopType: '1',
  },
  {
    stopSequence: '27',
    routeId: 'C',
    stopId: 'A41',
    stopType: '1',
  },
  {
    stopSequence: '28',
    routeId: 'C',
    stopId: 'A42',
    stopType: '1',
  },
  {
    stopSequence: '29',
    routeId: 'C',
    stopId: 'A43',
    stopType: '1',
  },
  {
    stopSequence: '30',
    routeId: 'C',
    stopId: 'A44',
    stopType: '1',
  },
  {
    stopSequence: '31',
    routeId: 'C',
    stopId: 'A45',
    stopType: '1',
  },
  {
    stopSequence: '32',
    routeId: 'C',
    stopId: 'A46',
    stopType: '1',
  },
  {
    stopSequence: '33',
    routeId: 'C',
    stopId: 'A47',
    stopType: '1',
  },
  {
    stopSequence: '34',
    routeId: 'C',
    stopId: 'A48',
    stopType: '1',
  },
  {
    stopSequence: '35',
    routeId: 'C',
    stopId: 'A49',
    stopType: '1',
  },
  {
    stopSequence: '36',
    routeId: 'C',
    stopId: 'A50',
    stopType: '1',
  },
  {
    stopSequence: '37',
    routeId: 'C',
    // Updated from `A51`
    stopId: 'J27',
    stopType: '1',
  },
  {
    stopSequence: '38',
    routeId: 'C',
    stopId: 'A52',
    stopType: '1',
  },
  {
    stopSequence: '39',
    routeId: 'C',
    stopId: 'A53',
    stopType: '1',
  },
  {
    stopSequence: '40',
    routeId: 'C',
    stopId: 'A54',
    stopType: '1',
  },
  {
    stopSequence: '41',
    routeId: 'C',
    stopId: 'A55',
    stopType: '1',
  },
  {
    stopSequence: '1',
    routeId: 'D',
    stopId: 'D01',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'D',
    stopId: 'D03',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: 'D',
    stopId: 'D04',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'D',
    stopId: 'D05',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: 'D',
    stopId: 'D06',
    stopType: '1',
  },
  {
    stopSequence: '6',
    routeId: 'D',
    stopId: 'D07',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: 'D',
    stopId: 'D08',
    stopType: '1',
  },
  {
    stopSequence: '8',
    routeId: 'D',
    stopId: 'D09',
    stopType: '1',
  },
  {
    stopSequence: '9',
    routeId: 'D',
    stopId: 'D10',
    stopType: '1',
  },
  {
    stopSequence: '10',
    routeId: 'D',
    stopId: 'D11',
    stopType: '1',
  },
  {
    stopSequence: '12',
    routeId: 'D',
    stopId: 'D12',
    stopType: '1',
  },
  // Updated from 'D13'
  {
    stopSequence: '13',
    routeId: 'D',
    stopId: 'A12',
    stopType: '0',
  },
  // Copied from routeId `B`
  {
    stopSequence: '13',
    routeId: 'D',
    stopId: 'A14',
    stopType: '2',
  },
  {
    stopSequence: '14',
    routeId: 'D',
    stopId: 'A15',
    stopType: '0',
  },
  // Copied from routeId `B`
  {
    stopSequence: '15',
    routeId: 'D',
    stopId: 'A16',
    stopType: '2',
  },
  // Copied from routeId `B`
  {
    stopSequence: '16',
    routeId: 'D',
    stopId: 'A17',
    stopType: '2',
  },
  // Copied from routeId `B`
  {
    stopSequence: '17',
    routeId: 'D',
    stopId: 'A18',
    stopType: '2',
  },
  // Copied from routeId `B`
  {
    stopSequence: '18',
    routeId: 'D',
    stopId: 'A19',
    stopType: '2',
  },
  // Copied from routeId `B`
  {
    stopSequence: '19',
    routeId: 'D',
    stopId: 'A20',
    stopType: '2',
  },
  // Copied from routeId `B`
  {
    stopSequence: '20',
    routeId: 'D',
    stopId: 'A21',
    stopType: '2',
  },
  // Copied from routeId `B`
  {
    stopSequence: '21',
    routeId: 'D',
    stopId: 'A22',
    stopType: '2',
  },
  {
    stopSequence: '15',
    routeId: 'D',
    stopId: 'A24',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: 'D',
    stopId: 'D14',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: 'D',
    stopId: 'D15',
    stopType: '0',
  },
  {
    stopSequence: '18',
    routeId: 'D',
    stopId: 'D16',
    stopType: '0',
  },
  {
    stopSequence: '19',
    routeId: 'D',
    //updated from 'D17'
    stopId: 'R17',
    stopType: '0',
  },
  // Copied from routeId `M`
  {
    stopSequence: '18',
    routeId: 'D',
    stopId: 'D18',
    stopType: '2',
  },
  // Copied from routeId `M`
  {
    stopSequence: '22',
    routeId: 'D',
    stopId: 'D19',
    stopType: '2',
  },
  {
    stopSequence: '20',
    routeId: 'D',
    //updated from 'D20'
    stopId: 'A32',
    stopType: '0',
  },
  {
    stopSequence: '21',
    routeId: 'D',
    stopId: 'D21',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: 'D',
    stopId: 'D22',
    stopType: '0',
  },
  {
    stopSequence: '24',
    routeId: 'D',
    stopId: 'R30',
    stopType: '2',
  },
  {
    stopSequence: '25',
    routeId: 'D',
    stopId: 'R31',
    stopType: '0',
  },
  {
    stopSequence: '26',
    routeId: 'D',
    stopId: 'R32',
    stopType: '2',
  },
  {
    stopSequence: '27',
    routeId: 'D',
    stopId: 'R33',
    stopType: '2',
  },
  {
    stopSequence: '28',
    routeId: 'D',
    stopId: 'R34',
    stopType: '2',
  },
  {
    stopSequence: '29',
    routeId: 'D',
    stopId: 'R35',
    stopType: '2',
  },
  {
    stopSequence: '30',
    routeId: 'D',
    stopId: 'R36',
    stopType: '0',
  },
  {
    stopSequence: '31',
    routeId: 'D',
    stopId: 'B12',
    stopType: '0',
  },
  {
    stopSequence: '32',
    routeId: 'D',
    stopId: 'B13',
    stopType: '0',
  },
  {
    stopSequence: '33',
    routeId: 'D',
    stopId: 'B14',
    stopType: '0',
  },
  {
    stopSequence: '34',
    routeId: 'D',
    stopId: 'B15',
    stopType: '0',
  },
  {
    stopSequence: '35',
    routeId: 'D',
    stopId: 'B16',
    stopType: '0',
  },
  {
    stopSequence: '36',
    routeId: 'D',
    stopId: 'B17',
    stopType: '0',
  },
  {
    stopSequence: '37',
    routeId: 'D',
    stopId: 'B18',
    stopType: '0',
  },
  {
    stopSequence: '38',
    routeId: 'D',
    stopId: 'B19',
    stopType: '0',
  },
  {
    stopSequence: '39',
    routeId: 'D',
    stopId: 'B20',
    stopType: '0',
  },
  {
    stopSequence: '40',
    routeId: 'D',
    stopId: 'B21',
    stopType: '0',
  },
  {
    stopSequence: '41',
    routeId: 'D',
    stopId: 'B22',
    stopType: '0',
  },
  {
    stopSequence: '42',
    routeId: 'D',
    stopId: 'B23',
    stopType: '0',
  },
  {
    stopSequence: '43',
    routeId: 'D',
    stopId: 'D43',
    stopType: '0',
  },
  // Copied from routeId `F`
  {
    stopSequence: '1',
    routeId: 'E',
    stopId: 'F01',
    stopType: '0',
  },
  // Copied from routeId `F`
  {
    stopSequence: '2',
    routeId: 'E',
    stopId: 'F02',
    stopType: '0',
  },
  // Copied from routeId `F`
  {
    stopSequence: '3',
    routeId: 'E',
    stopId: 'F03',
    stopType: '0',
  },
  // Copied from routeId `F`
  {
    stopSequence: '4',
    routeId: 'E',
    stopId: 'F04',
    stopType: '0',
  },
  // Copied from routeId `F`
  {
    stopSequence: '5',
    routeId: 'E',
    stopId: 'F05',
    stopType: '0',
  },
  // Copied from routeId `F`
  {
    stopSequence: '5',
    routeId: 'E',
    stopId: 'F06',
    stopType: '0',
  },
  // Duplicated to handle fork in the E
  {
    isDuplicated: true,
    stopSequence: '4',
    routeId: 'E',
    stopId: 'F05',
    stopType: '1',
  },
  // Duplicated to handle fork in the E
  {
    isDuplicated: true,
    stopSequence: '3',
    routeId: 'E',
    stopId: 'G07',
    stopType: '0',
  },
  // Duplicated to handle fork in the E
  {
    isDuplicated: true,
    stopSequence: '2',
    routeId: 'E',
    stopId: 'G06',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'E',
    stopId: 'G05',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'E',
    stopId: 'G06',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: 'E',
    stopId: 'G07',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'E',
    stopId: 'F05',
    stopType: '1',
  },
  {
    stopSequence: '5',
    routeId: 'E',
    stopId: 'F06',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: 'E',
    stopId: 'F07',
    stopType: '1',
  },
  {
    stopSequence: '7',
    routeId: 'E',
    stopId: 'G08',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: 'E',
    stopId: 'G09',
    stopType: '2',
  },
  {
    stopSequence: '9',
    routeId: 'E',
    stopId: 'G10',
    stopType: '2',
  },
  {
    stopSequence: '10',
    routeId: 'E',
    stopId: 'G11',
    stopType: '2',
  },
  {
    stopSequence: '11',
    routeId: 'E',
    stopId: 'G12',
    stopType: '2',
  },
  {
    stopSequence: '12',
    routeId: 'E',
    stopId: 'G13',
    stopType: '2',
  },
  {
    stopSequence: '13',
    routeId: 'E',
    stopId: 'G14',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: 'E',
    stopId: 'G15',
    stopType: '2',
  },
  {
    stopSequence: '15',
    routeId: 'E',
    stopId: 'G16',
    stopType: '2',
  },
  {
    stopSequence: '16',
    routeId: 'E',
    stopId: 'G18',
    stopType: '2',
  },
  {
    stopSequence: '17',
    routeId: 'E',
    stopId: 'G19',
    stopType: '2',
  },
  {
    stopSequence: '18',
    routeId: 'E',
    stopId: 'G20',
    stopType: '2',
  },
  {
    stopSequence: '19',
    routeId: 'E',
    stopId: 'G21',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: 'E',
    stopId: 'F09',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: 'E',
    stopId: 'F11',
    stopType: '0',
  },
  {
    stopSequence: '23',
    routeId: 'E',
    stopId: 'F12',
    stopType: '0',
  },
  {
    stopSequence: '24',
    routeId: 'E',
    stopId: 'D14',
    stopType: '0',
  },
  {
    stopSequence: '25',
    routeId: 'E',
    stopId: 'A25',
    stopType: '0',
  },
  {
    stopSequence: '26',
    routeId: 'E',
    stopId: 'A27',
    stopType: '0',
  },
  {
    stopSequence: '27',
    routeId: 'E',
    stopId: 'A28',
    stopType: '0',
  },
  {
    stopSequence: '28',
    routeId: 'E',
    stopId: 'A30',
    stopType: '0',
  },
  {
    stopSequence: '29',
    routeId: 'E',
    stopId: 'A31',
    stopType: '0',
  },
  {
    stopSequence: '30',
    routeId: 'E',
    stopId: 'A32',
    stopType: '0',
  },
  {
    stopSequence: '31',
    routeId: 'E',
    stopId: 'A33',
    stopType: '0',
  },
  {
    stopSequence: '32',
    routeId: 'E',
    stopId: 'A34',
    stopType: '0',
  },
  // copied from routeId `A`
  // E goes through Chambers St to World Trade Center w/o stopping
  {
    stopSequence: '28',
    routeId: 'E',
    stopId: 'A36',
    stopType: '2',
  },
  {
    stopSequence: '33',
    routeId: 'E',
    stopId: 'E01',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'F',
    stopId: 'F01',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'F',
    stopId: 'F02',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: 'F',
    stopId: 'F03',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'F',
    stopId: 'F04',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: 'F',
    stopId: 'F05',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: 'F',
    stopId: 'F06',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: 'F',
    stopId: 'F07',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: 'F',
    stopId: 'G08',
    stopType: '0',
  },
  // Copied from routeId `E`
  {
    stopSequence: '8',
    routeId: 'F',
    stopId: 'G09',
    stopType: '2',
  },
  // Copied from routeId `E`
  {
    stopSequence: '9',
    routeId: 'F',
    stopId: 'G10',
    stopType: '2',
  },
  // Copied from routeId `E`
  {
    stopSequence: '10',
    routeId: 'F',
    stopId: 'G11',
    stopType: '2',
  },
  // Copied from routeId `E`
  {
    stopSequence: '11',
    routeId: 'F',
    stopId: 'G12',
    stopType: '2',
  },
  // Copied from routeId `E`
  {
    stopSequence: '12',
    routeId: 'F',
    stopId: 'G13',
    stopType: '2',
  },
  {
    stopSequence: '9',
    routeId: 'F',
    stopId: 'G14',
    stopType: '0',
  },
  // Copied from routeId `E`
  {
    stopSequence: '14',
    routeId: 'F',
    stopId: 'G15',
    stopType: '2',
  },
  // Copied from routeId `E`
  {
    stopSequence: '15',
    routeId: 'F',
    stopId: 'G16',
    stopType: '2',
  },
  // Copied from routeId `E`
  {
    stopSequence: '16',
    routeId: 'F',
    stopId: 'G18',
    stopType: '2',
  },
  // Copied from routeId `E`
  {
    stopSequence: '17',
    routeId: 'F',
    stopId: 'G19',
    stopType: '2',
  },
  // Copied from routeId `E`
  {
    stopSequence: '18',
    routeId: 'F',
    stopId: 'G20',
    stopType: '2',
  },
  {
    stopSequence: '10',
    routeId: 'F',
    stopId: 'B04',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: 'F',
    stopId: 'B06',
    stopType: '0',
  },
  {
    stopSequence: '13',
    routeId: 'F',
    stopId: 'B08',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: 'F',
    stopId: 'B10',
    stopType: '0',
  },
  {
    stopSequence: '15',
    routeId: 'F',
    stopId: 'D15',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: 'F',
    stopId: 'D16',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: 'F',
    //updated from 'D17'
    stopId: 'R17',
    stopType: '0',
  },
  {
    stopSequence: '18',
    routeId: 'F',
    stopId: 'D18',
    stopType: '0',
  },
  {
    stopSequence: '19',
    routeId: 'F',
    stopId: 'D19',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: 'F',
    //updated from 'D20'
    stopId: 'A32',
    stopType: '0',
  },
  {
    stopSequence: '21',
    routeId: 'F',
    stopId: 'D21',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: 'F',
    stopId: 'F14',
    stopType: '0',
  },
  {
    stopSequence: '23',
    routeId: 'F',
    // Updated from `F15`
    stopId: 'M18',
    stopType: '0',
  },
  {
    stopSequence: '24',
    routeId: 'F',
    stopId: 'F16',
    stopType: '0',
  },
  {
    stopSequence: '26',
    routeId: 'F',
    stopId: 'F18',
    stopType: '0',
  },
  {
    stopSequence: '27',
    routeId: 'F',
    stopId: 'A41',
    stopType: '0',
  },
  {
    stopSequence: '28',
    routeId: 'F',
    stopId: 'F20',
    stopType: '0',
  },
  {
    stopSequence: '29',
    routeId: 'F',
    stopId: 'F21',
    stopType: '0',
  },
  {
    stopSequence: '30',
    routeId: 'F',
    stopId: 'F22',
    stopType: '0',
  },
  {
    stopSequence: '31',
    routeId: 'F',
    stopId: 'F23',
    stopType: '0',
  },
  {
    stopSequence: '32',
    routeId: 'F',
    stopId: 'F24',
    stopType: '0',
  },
  {
    stopSequence: '33',
    routeId: 'F',
    stopId: 'F25',
    stopType: '0',
  },
  {
    stopSequence: '34',
    routeId: 'F',
    stopId: 'F26',
    stopType: '0',
  },
  {
    stopSequence: '35',
    routeId: 'F',
    stopId: 'F27',
    stopType: '0',
  },
  {
    stopSequence: '36',
    routeId: 'F',
    stopId: 'F29',
    stopType: '0',
  },
  {
    stopSequence: '37',
    routeId: 'F',
    stopId: 'F30',
    stopType: '0',
  },
  {
    stopSequence: '38',
    routeId: 'F',
    stopId: 'F31',
    stopType: '0',
  },
  {
    stopSequence: '39',
    routeId: 'F',
    stopId: 'F32',
    stopType: '0',
  },
  {
    stopSequence: '40',
    routeId: 'F',
    stopId: 'F33',
    stopType: '0',
  },
  {
    stopSequence: '41',
    routeId: 'F',
    stopId: 'F34',
    stopType: '0',
  },
  {
    stopSequence: '42',
    routeId: 'F',
    stopId: 'F35',
    stopType: '0',
  },
  {
    stopSequence: '43',
    routeId: 'F',
    stopId: 'F36',
    stopType: '0',
  },
  {
    stopSequence: '44',
    routeId: 'F',
    stopId: 'F38',
    stopType: '0',
  },
  {
    stopSequence: '45',
    routeId: 'F',
    stopId: 'F39',
    stopType: '0',
  },
  {
    stopSequence: '46',
    routeId: 'F',
    stopId: 'D42',
    stopType: '0',
  },
  {
    stopSequence: '47',
    routeId: 'F',
    stopId: 'D43',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'G',
    // Updated from `G22`
    stopId: '719',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'G',
    stopId: 'G24',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'G',
    stopId: 'G26',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: 'G',
    stopId: 'G28',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: 'G',
    stopId: 'G29',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: 'G',
    stopId: 'G30',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: 'G',
    stopId: 'G31',
    stopType: '0',
  },
  {
    stopSequence: '9',
    routeId: 'G',
    stopId: 'G32',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: 'G',
    stopId: 'G33',
    stopType: '0',
  },
  {
    stopSequence: '11',
    routeId: 'G',
    stopId: 'G34',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: 'G',
    stopId: 'G35',
    stopType: '0',
  },
  {
    stopSequence: '13',
    routeId: 'G',
    stopId: 'G36',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: 'G',
    stopId: 'A42',
    stopType: '0',
  },
  {
    stopSequence: '15',
    routeId: 'G',
    stopId: 'F20',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: 'G',
    stopId: 'F21',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: 'G',
    stopId: 'F22',
    stopType: '0',
  },
  {
    stopSequence: '18',
    routeId: 'G',
    stopId: 'F23',
    stopType: '0',
  },
  {
    stopSequence: '19',
    routeId: 'G',
    stopId: 'F24',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: 'G',
    stopId: 'F25',
    stopType: '0',
  },
  {
    stopSequence: '21',
    routeId: 'G',
    stopId: 'F26',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: 'G',
    stopId: 'F27',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'GS',
    // Updated from `901`
    stopId: '631',
    stopType: '1',
  },
  {
    stopSequence: '2',
    routeId: 'GS',
    // Updated from `902`
    stopId: '127',
    stopType: '1',
  },
  {
    stopSequence: '1',
    routeId: 'FS',
    stopId: 'S01',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'FS',
    stopId: 'S03',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: 'FS',
    stopId: 'S04',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'FS',
    stopId: 'D26',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'H',
    stopId: 'H04',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'H',
    stopId: 'H12',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: 'H',
    stopId: 'H13',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'H',
    stopId: 'H14',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: 'H',
    stopId: 'H15',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'J',
    stopId: 'G05',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'J',
    stopId: 'G06',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: 'J',
    stopId: 'J12',
    stopType: '1',
  },
  {
    stopSequence: '4',
    routeId: 'J',
    stopId: 'J13',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: 'J',
    stopId: 'J14',
    stopType: '1',
  },
  {
    stopSequence: '6',
    routeId: 'J',
    stopId: 'J15',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: 'J',
    stopId: 'J16',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: 'J',
    stopId: 'J17',
    stopType: '1',
  },
  {
    stopSequence: '10',
    routeId: 'J',
    stopId: 'J19',
    stopType: '0',
  },
  {
    stopSequence: '11',
    routeId: 'J',
    stopId: 'J20',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: 'J',
    stopId: 'J21',
    stopType: '1',
  },
  {
    stopSequence: '13',
    routeId: 'J',
    stopId: 'J22',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: 'J',
    stopId: 'J23',
    stopType: '1',
  },
  {
    stopSequence: '15',
    routeId: 'J',
    stopId: 'J24',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: 'J',
    stopId: 'J27',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: 'J',
    stopId: 'J28',
    stopType: '1',
  },
  {
    stopSequence: '18',
    routeId: 'J',
    stopId: 'J29',
    stopType: '0',
  },
  {
    stopSequence: '19',
    routeId: 'J',
    stopId: 'J30',
    stopType: '1',
  },
  {
    stopSequence: '20',
    routeId: 'J',
    stopId: 'J31',
    stopType: '0',
  },
  {
    stopSequence: '21',
    routeId: 'J',
    stopId: 'M11',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: 'J',
    stopId: 'M12',
    stopType: '1',
  },
  {
    stopSequence: '23',
    routeId: 'J',
    stopId: 'M13',
    stopType: '1',
  },
  {
    stopSequence: '24',
    routeId: 'J',
    stopId: 'M14',
    stopType: '1',
  },
  {
    stopSequence: '25',
    routeId: 'J',
    stopId: 'M16',
    stopType: '0',
  },
  {
    stopSequence: '27',
    routeId: 'J',
    stopId: 'M18',
    stopType: '0',
  },
  {
    stopSequence: '28',
    routeId: 'J',
    stopId: 'M19',
    stopType: '0',
  },
  {
    stopSequence: '29',
    routeId: 'J',
    // Updated from `M20`
    stopId: 'Q01',
    stopType: '0',
  },
  {
    stopSequence: '30',
    routeId: 'J',
    stopId: 'M21',
    stopType: '0',
  },
  {
    stopSequence: '31',
    routeId: 'J',
    // Updated from `M22`
    stopId: 'A38',
    stopType: '0',
  },
  {
    stopSequence: '32',
    routeId: 'J',
    stopId: 'M23',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'L',
    stopId: 'L01',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'L',
    stopId: 'L02',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: 'L',
    // Updated from `L03`
    stopId: 'R20',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'L',
    stopId: 'L05',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: 'L',
    stopId: 'L06',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: 'L',
    stopId: 'L08',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: 'L',
    stopId: 'L10',
    stopType: '0',
  },
  {
    stopSequence: '9',
    routeId: 'L',
    stopId: 'L11',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: 'L',
    stopId: 'L12',
    stopType: '0',
  },
  {
    stopSequence: '11',
    routeId: 'L',
    stopId: 'L13',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: 'L',
    stopId: 'L14',
    stopType: '0',
  },
  {
    stopSequence: '13',
    routeId: 'L',
    stopId: 'L15',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: 'L',
    stopId: 'L16',
    stopType: '0',
  },
  {
    stopSequence: '15',
    routeId: 'L',
    stopId: 'L17',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: 'L',
    stopId: 'L19',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: 'L',
    stopId: 'L20',
    stopType: '0',
  },
  {
    stopSequence: '18',
    routeId: 'L',
    stopId: 'L21',
    stopType: '0',
  },
  {
    stopSequence: '19',
    routeId: 'L',
    // Updated from `L22`
    stopId: 'J27',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: 'L',
    stopId: 'L24',
    stopType: '0',
  },
  {
    stopSequence: '21',
    routeId: 'L',
    stopId: 'L25',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: 'L',
    stopId: 'L26',
    stopType: '0',
  },
  {
    stopSequence: '23',
    routeId: 'L',
    stopId: 'L27',
    stopType: '0',
  },
  {
    stopSequence: '24',
    routeId: 'L',
    stopId: 'L28',
    stopType: '0',
  },
  {
    stopSequence: '25',
    routeId: 'L',
    stopId: 'L29',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'M',
    stopId: 'G08',
    stopType: '1',
  },
  {
    stopSequence: '2',
    routeId: 'M',
    stopId: 'G09',
    stopType: '1',
  },
  {
    stopSequence: '3',
    routeId: 'M',
    stopId: 'G10',
    stopType: '1',
  },
  {
    stopSequence: '4',
    routeId: 'M',
    stopId: 'G11',
    stopType: '1',
  },
  {
    stopSequence: '5',
    routeId: 'M',
    stopId: 'G12',
    stopType: '1',
  },
  {
    stopSequence: '6',
    routeId: 'M',
    stopId: 'G13',
    stopType: '1',
  },
  {
    stopSequence: '7',
    routeId: 'M',
    stopId: 'G14',
    stopType: '1',
  },
  {
    stopSequence: '8',
    routeId: 'M',
    stopId: 'G15',
    stopType: '1',
  },
  {
    stopSequence: '9',
    routeId: 'M',
    stopId: 'G16',
    stopType: '1',
  },
  {
    stopSequence: '10',
    routeId: 'M',
    stopId: 'G18',
    stopType: '1',
  },
  {
    stopSequence: '11',
    routeId: 'M',
    stopId: 'G19',
    stopType: '1',
  },
  {
    stopSequence: '12',
    routeId: 'M',
    stopId: 'G20',
    stopType: '1',
  },
  {
    stopSequence: '13',
    routeId: 'M',
    stopId: 'G21',
    stopType: '1',
  },
  {
    stopSequence: '14',
    routeId: 'M',
    stopId: 'F09',
    stopType: '1',
  },
  {
    stopSequence: '16',
    routeId: 'M',
    stopId: 'F11',
    stopType: '1',
  },
  {
    stopSequence: '17',
    routeId: 'M',
    stopId: 'F12',
    stopType: '1',
  },
  {
    stopSequence: '18',
    routeId: 'M',
    stopId: 'D15',
    stopType: '1',
  },
  {
    stopSequence: '19',
    routeId: 'M',
    stopId: 'D16',
    stopType: '1',
  },
  {
    stopSequence: '20',
    routeId: 'M',
    //updated from 'D17'
    stopId: 'R17',
    stopType: '1',
  },
  {
    stopSequence: '21',
    routeId: 'M',
    stopId: 'D18',
    stopType: '1',
  },
  {
    stopSequence: '22',
    routeId: 'M',
    stopId: 'D19',
    stopType: '1',
  },
  {
    stopSequence: '23',
    routeId: 'M',
    //updated from 'D20'
    stopId: 'A32',
    stopType: '1',
  },
  {
    stopSequence: '24',
    routeId: 'M',
    stopId: 'D21',
    stopType: '1',
  },
  // Added to make M pass through 2 Av on F track
  {
    stopSequence: '24',
    routeId: 'M',
    stopId: 'F14',
    stopType: '2',
  },
  {
    stopSequence: '25',
    routeId: 'M',
    stopId: 'M18',
    stopType: '1',
  },
  {
    stopSequence: '27',
    routeId: 'M',
    stopId: 'M16',
    stopType: '1',
  },
  {
    stopSequence: '28',
    routeId: 'M',
    stopId: 'M14',
    stopType: '1',
  },
  {
    stopSequence: '29',
    routeId: 'M',
    stopId: 'M13',
    stopType: '1',
  },
  {
    stopSequence: '30',
    routeId: 'M',
    stopId: 'M12',
    stopType: '1',
  },
  {
    stopSequence: '31',
    routeId: 'M',
    stopId: 'M11',
    stopType: '0',
  },
  {
    stopSequence: '32',
    routeId: 'M',
    stopId: 'M10',
    stopType: '0',
  },
  {
    stopSequence: '33',
    routeId: 'M',
    stopId: 'M09',
    stopType: '0',
  },
  {
    stopSequence: '34',
    routeId: 'M',
    // Updated from `M08`
    stopId: 'L17',
    stopType: '0',
  },
  {
    stopSequence: '36',
    routeId: 'M',
    stopId: 'M06',
    stopType: '0',
  },
  {
    stopSequence: '37',
    routeId: 'M',
    stopId: 'M05',
    stopType: '0',
  },
  {
    stopSequence: '38',
    routeId: 'M',
    stopId: 'M04',
    stopType: '0',
  },
  {
    stopSequence: '39',
    routeId: 'M',
    stopId: 'M01',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'N',
    stopId: 'R01',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'N',
    stopId: 'R03',
    stopType: '0',
    stopStatus: '-1',
  },
  {
    stopSequence: '3',
    routeId: 'N',
    stopId: 'R04',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'N',
    stopId: 'R05',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: 'N',
    stopId: 'R06',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: 'N',
    stopId: 'R08',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: 'N',
    stopId: 'R09',
    stopType: '0',
  },
  {
    stopSequence: '9',
    routeId: 'N',
    stopId: 'R11',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: 'N',
    stopId: 'R13',
    stopType: '0',
  },
  {
    stopSequence: '11',
    routeId: 'N',
    stopId: 'R14',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: 'N',
    stopId: 'R15',
    stopType: '0',
  },
  {
    stopSequence: '13',
    routeId: 'N',
    // Updated from `R16`
    stopId: '127',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: 'N',
    stopId: 'R17',
    stopType: '0',
  },
  {
    stopSequence: '15',
    routeId: 'N',
    stopId: 'R18',
    stopType: '1',
  },
  {
    stopSequence: '16',
    routeId: 'N',
    stopId: 'R19',
    stopType: '1',
  },
  {
    stopSequence: '17',
    routeId: 'N',
    stopId: 'R20',
    stopType: '0',
  },
  {
    stopSequence: '18',
    routeId: 'N',
    stopId: 'R21',
    stopType: '1',
  },
  {
    stopSequence: '19',
    routeId: 'N',
    stopId: 'R22',
    stopType: '1',
  },
  {
    stopSequence: '20',
    routeId: 'N',
    stopId: 'R23',
    stopType: '0',
  },
  // Remove N stops in Financial District from default base path because they are for late night only (midnight to 6 AM)
  /*
  {
    stopSequence: '21',
    routeId: 'N',
    stopId: 'R24',
    stopType: '2',
  },
  {
    stopSequence: '22',
    routeId: 'N',
    stopId: 'R25',
    stopType: '2',
  },
  {
    stopSequence: '23',
    routeId: 'N',
    stopId: 'R26',
    stopType: '2',
  },
  {
    stopSequence: '24',
    routeId: 'N',
    stopId: 'R27',
    stopType: '2',
  },
  {
    stopSequence: '26',
    routeId: 'N',
    stopId: 'R28',
    stopType: '2',
  },
  {
    stopSequence: '27',
    routeId: 'N',
    // Updated from `R29`
    stopId: 'A41',
    stopType: '2',
  },
  */
  {
    stopSequence: '28',
    routeId: 'N',
    stopId: 'R30',
    stopType: '2',
  },
  {
    stopSequence: '29',
    routeId: 'N',
    stopId: 'R31',
    stopType: '0',
  },
  {
    stopSequence: '30',
    routeId: 'N',
    stopId: 'R32',
    stopType: '2',
  },
  {
    stopSequence: '31',
    routeId: 'N',
    stopId: 'R33',
    stopType: '2',
  },
  {
    stopSequence: '32',
    routeId: 'N',
    stopId: 'R34',
    stopType: '2',
  },
  {
    stopSequence: '33',
    routeId: 'N',
    stopId: 'R35',
    stopType: '2',
  },
  {
    stopSequence: '34',
    routeId: 'N',
    stopId: 'R36',
    stopType: '0',
  },
  {
    stopSequence: '35',
    routeId: 'N',
    stopId: 'R39',
    stopType: '2',
  },
  {
    stopSequence: '36',
    routeId: 'N',
    stopId: 'R40',
    stopType: '2',
  },
  {
    stopSequence: '37',
    routeId: 'N',
    stopId: 'R41',
    stopType: '0',
  },
  {
    stopSequence: '38',
    routeId: 'N',
    stopId: 'N02',
    stopType: '0',
  },
  {
    stopSequence: '39',
    routeId: 'N',
    stopId: 'N03',
    stopType: '0',
  },
  {
    stopSequence: '40',
    routeId: 'N',
    stopId: 'N04',
    stopType: '0',
  },
  {
    stopSequence: '41',
    routeId: 'N',
    stopId: 'N05',
    stopType: '0',
  },
  {
    stopSequence: '42',
    routeId: 'N',
    stopId: 'N06',
    stopType: '0',
  },
  {
    stopSequence: '43',
    routeId: 'N',
    stopId: 'N07',
    stopType: '0',
  },
  {
    stopSequence: '44',
    routeId: 'N',
    stopId: 'N08',
    stopType: '0',
  },
  {
    stopSequence: '45',
    routeId: 'N',
    stopId: 'N09',
    stopType: '0',
  },
  {
    stopSequence: '46',
    routeId: 'N',
    stopId: 'N10',
    stopType: '0',
  },
  {
    stopSequence: '47',
    routeId: 'N',
    stopId: 'D43',
    stopType: '0',
  },
  // N stops duplicated from Q to cover unexpected rerouting branch
  // with skipped stops on weekends.
  // In these situations, we need to create the whole branch to cover
  // skipped stops from PG and repeating the first stop of the branch,
  // in this case, Canal St.
  {
    isRerouting: true,
    stopSequence: '14',
    routeId: 'N',
    stopId: 'R23',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '16',
    routeId: 'N',
    stopId: 'R30',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '17',
    routeId: 'N',
    // EDITED to unite the B/Q and 2/3/4/5 clusters
    // stopId: 'D24',
    stopId: '235',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '18',
    routeId: 'N',
    stopId: 'D25',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '19',
    routeId: 'N',
    stopId: 'D26',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '20',
    routeId: 'N',
    stopId: 'D27',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '21',
    routeId: 'N',
    stopId: 'D28',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '22',
    routeId: 'N',
    stopId: 'D29',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '23',
    routeId: 'N',
    stopId: 'D30',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '24',
    routeId: 'N',
    stopId: 'D31',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '25',
    routeId: 'N',
    stopId: 'D32',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '26',
    routeId: 'N',
    stopId: 'D33',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '27',
    routeId: 'N',
    stopId: 'D34',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '28',
    routeId: 'N',
    stopId: 'D35',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '29',
    routeId: 'N',
    stopId: 'D37',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '30',
    routeId: 'N',
    stopId: 'D38',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '31',
    routeId: 'N',
    stopId: 'D39',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '32',
    routeId: 'N',
    stopId: 'D40',
    stopType: '0',
  },
  // ^^^ End of N duplicated stops
  {
    stopSequence: '1',
    routeId: 'Q',
    stopId: 'Q05',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'Q',
    stopId: 'Q04',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: 'Q',
    stopId: 'Q03',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'Q',
    stopId: 'B08',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: 'Q',
    stopId: 'R14',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: 'Q',
    stopId: 'R15',
    stopType: '2',
  },
  {
    stopSequence: '7',
    routeId: 'Q',
    // Updated from `R16`
    stopId: '127',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: 'Q',
    stopId: 'R17',
    stopType: '0',
  },
  {
    stopSequence: '9',
    routeId: 'Q',
    stopId: 'R18',
    stopType: '2',
  },
  {
    stopSequence: '10',
    routeId: 'Q',
    stopId: 'R19',
    stopType: '2',
  },
  {
    stopSequence: '11',
    routeId: 'Q',
    stopId: 'R20',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: 'Q',
    stopId: 'R21',
    stopType: '2',
  },
  {
    stopSequence: '13',
    routeId: 'Q',
    stopId: 'R22',
    stopType: '2',
  },
  {
    stopSequence: '14',
    routeId: 'Q',
    stopId: 'R23',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: 'Q',
    stopId: 'R30',
    stopType: '0',
  },
  // ADDED to pass through Nevins
  {
    stopSequence: '31',
    routeId: 'Q',
    stopId: '234',
    stopType: '2',
  },
  {
    stopSequence: '17',
    routeId: 'Q',
    // EDITED to unite the B/Q and 2/3/4/5 clusters
    // stopId: 'D24',
    stopId: '235',
    stopType: '0',
  },
  // ADDED to unite B/Q and 2/3/4/5
  {
    stopSequence: '17',
    routeId: 'Q',
    stopId: '236',
    stopType: '2',
  },
  {
    stopSequence: '17',
    routeId: 'Q',
    // EDITED to unite the B/Q and 2/3/4/5 clusters
    stopId: '236',
    stopType: '2',
  },
  {
    stopSequence: '18',
    routeId: 'Q',
    stopId: 'D25',
    stopType: '0',
  },
  // ADDED to unite B/Q and 2/3/4/5 at Grand Army Plaza
  // REVERTED
  // {
  //   stopSequence: '17',
  //   routeId: 'Q',
  //   stopId: '237',
  //   stopType: '2',
  // },
  {
    stopSequence: '19',
    routeId: 'Q',
    stopId: 'D26',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: 'Q',
    stopId: 'D27',
    stopType: '0',
  },
  {
    stopSequence: '21',
    routeId: 'Q',
    stopId: 'D28',
    stopType: '0',
  },
  {
    stopSequence: '22',
    routeId: 'Q',
    stopId: 'D29',
    stopType: '0',
  },
  {
    stopSequence: '23',
    routeId: 'Q',
    stopId: 'D30',
    stopType: '0',
  },
  {
    stopSequence: '24',
    routeId: 'Q',
    stopId: 'D31',
    stopType: '0',
  },
  {
    stopSequence: '25',
    routeId: 'Q',
    stopId: 'D32',
    stopType: '0',
  },
  {
    stopSequence: '26',
    routeId: 'Q',
    stopId: 'D33',
    stopType: '0',
  },
  {
    stopSequence: '27',
    routeId: 'Q',
    stopId: 'D34',
    stopType: '0',
  },
  {
    stopSequence: '28',
    routeId: 'Q',
    stopId: 'D35',
    stopType: '0',
  },
  {
    stopSequence: '29',
    routeId: 'Q',
    stopId: 'D37',
    stopType: '0',
  },
  {
    stopSequence: '30',
    routeId: 'Q',
    stopId: 'D38',
    stopType: '0',
  },
  {
    stopSequence: '31',
    routeId: 'Q',
    stopId: 'D39',
    stopType: '0',
  },
  {
    stopSequence: '32',
    routeId: 'Q',
    stopId: 'D40',
    stopType: '0',
  },
  {
    stopSequence: '33',
    routeId: 'Q',
    stopId: 'D41',
    stopType: '0',
  },
  {
    stopSequence: '34',
    routeId: 'Q',
    stopId: 'D42',
    stopType: '0',
  },
  {
    stopSequence: '35',
    routeId: 'Q',
    stopId: 'D43',
    stopType: '0',
  },

  // Q stops duplicated from R to cover unexpected rerouting branch
  // with skipped stops, usually at 4pm on weekdays
  // In these situations, we need to create the whole branch to cover
  // skipped stops from PG and repeating the first stop of the branch,
  // in this case, Canal St.
  {
    isRerouting: true,
    stopSequence: '26',
    routeId: 'Q',
    // Updated from `R23`
    stopId: 'R23',
    stopType: '1',
  },
  {
    isRerouting: true,
    stopSequence: '27',
    routeId: 'Q',
    stopId: 'R24',
    stopType: '1',
  },
  {
    isRerouting: true,
    stopSequence: '28',
    routeId: 'Q',
    stopId: 'R25',
    stopType: '1',
  },
  {
    isRerouting: true,
    stopSequence: '29',
    routeId: 'Q',
    stopId: 'R26',
    stopType: '1',
  },
  {
    isRerouting: true,
    stopSequence: '30',
    routeId: 'Q',
    stopId: 'R27',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '32',
    routeId: 'Q',
    stopId: 'R28',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '33',
    routeId: 'Q',
    // Updated from `R29`
    stopId: 'A41',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '34',
    routeId: 'Q',
    stopId: 'R30',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '35',
    routeId: 'Q',
    stopId: 'R31',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '36',
    routeId: 'Q',
    stopId: 'R32',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '37',
    routeId: 'Q',
    stopId: 'R33',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '38',
    routeId: 'Q',
    stopId: 'R34',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '39',
    routeId: 'Q',
    stopId: 'R35',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '40',
    routeId: 'Q',
    stopId: 'R36',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '41',
    routeId: 'Q',
    stopId: 'R39',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '42',
    routeId: 'Q',
    stopId: 'R40',
    stopType: '0',
  },
  {
    isRerouting: true,
    stopSequence: '43',
    routeId: 'Q',
    stopId: 'R41',
    stopType: '0',
  },
  // ^^^ End of Q duplicated stops
  {
    stopSequence: '1',
    routeId: 'R',
    stopId: 'G08',
    stopType: '1',
  },
  {
    stopSequence: '2',
    routeId: 'R',
    stopId: 'G09',
    stopType: '1',
  },
  {
    stopSequence: '3',
    routeId: 'R',
    stopId: 'G10',
    stopType: '1',
  },
  {
    stopSequence: '4',
    routeId: 'R',
    stopId: 'G11',
    stopType: '1',
  },
  {
    stopSequence: '5',
    routeId: 'R',
    stopId: 'G12',
    stopType: '1',
  },
  {
    stopSequence: '6',
    routeId: 'R',
    stopId: 'G13',
    stopType: '1',
  },
  {
    stopSequence: '7',
    routeId: 'R',
    stopId: 'G14',
    stopType: '1',
  },
  {
    stopSequence: '8',
    routeId: 'R',
    stopId: 'G15',
    stopType: '1',
  },
  {
    stopSequence: '9',
    routeId: 'R',
    stopId: 'G16',
    stopType: '1',
  },
  {
    stopSequence: '10',
    routeId: 'R',
    stopId: 'G18',
    stopType: '1',
  },
  {
    stopSequence: '11',
    routeId: 'R',
    stopId: 'G19',
    stopType: '1',
  },
  {
    stopSequence: '12',
    routeId: 'R',
    stopId: 'G20',
    stopType: '1',
  },
  {
    stopSequence: '13',
    routeId: 'R',
    stopId: 'G21',
    stopType: '1',
  },
  {
    stopSequence: '15',
    routeId: 'R',
    stopId: 'R11',
    stopType: '1',
  },
  {
    stopSequence: '16',
    routeId: 'R',
    stopId: 'R13',
    stopType: '1',
  },
  {
    stopSequence: '17',
    routeId: 'R',
    stopId: 'R14',
    stopType: '1',
  },
  {
    stopSequence: '18',
    routeId: 'R',
    stopId: 'R15',
    stopType: '1',
  },
  {
    stopSequence: '19',
    routeId: 'R',
    // Updated from `R16`
    stopId: '127',
    stopType: '1',
  },
  {
    stopSequence: '20',
    routeId: 'R',
    stopId: 'R17',
    stopType: '1',
  },
  {
    stopSequence: '21',
    routeId: 'R',
    stopId: 'R18',
    stopType: '1',
  },
  {
    stopSequence: '22',
    routeId: 'R',
    stopId: 'R19',
    stopType: '1',
  },
  {
    stopSequence: '23',
    routeId: 'R',
    stopId: 'R20',
    stopType: '1',
  },
  {
    stopSequence: '24',
    routeId: 'R',
    stopId: 'R21',
    stopType: '1',
  },
  {
    stopSequence: '25',
    routeId: 'R',
    stopId: 'R22',
    stopType: '1',
  },
  {
    stopSequence: '26',
    routeId: 'R',
    // Updated from `R23`
    stopId: 'R23',
    stopType: '1',
  },
  {
    stopSequence: '27',
    routeId: 'R',
    stopId: 'R24',
    stopType: '1',
  },
  {
    stopSequence: '28',
    routeId: 'R',
    stopId: 'R25',
    stopType: '1',
  },
  {
    stopSequence: '29',
    routeId: 'R',
    stopId: 'R26',
    stopType: '1',
  },
  {
    stopSequence: '30',
    routeId: 'R',
    stopId: 'R27',
    stopType: '0',
  },
  {
    stopSequence: '32',
    routeId: 'R',
    stopId: 'R28',
    stopType: '0',
  },
  {
    stopSequence: '33',
    routeId: 'R',
    // Updated from `R29`
    stopId: 'A41',
    stopType: '0',
  },
  {
    stopSequence: '34',
    routeId: 'R',
    stopId: 'R30',
    stopType: '0',
  },
  {
    stopSequence: '35',
    routeId: 'R',
    stopId: 'R31',
    stopType: '0',
  },
  {
    stopSequence: '36',
    routeId: 'R',
    stopId: 'R32',
    stopType: '0',
  },
  {
    stopSequence: '37',
    routeId: 'R',
    stopId: 'R33',
    stopType: '0',
  },
  {
    stopSequence: '38',
    routeId: 'R',
    stopId: 'R34',
    stopType: '0',
  },
  {
    stopSequence: '39',
    routeId: 'R',
    stopId: 'R35',
    stopType: '0',
  },
  {
    stopSequence: '40',
    routeId: 'R',
    stopId: 'R36',
    stopType: '0',
  },
  {
    stopSequence: '41',
    routeId: 'R',
    stopId: 'R39',
    stopType: '0',
  },
  {
    stopSequence: '42',
    routeId: 'R',
    stopId: 'R40',
    stopType: '0',
  },
  {
    stopSequence: '43',
    routeId: 'R',
    stopId: 'R41',
    stopType: '0',
  },
  {
    stopSequence: '44',
    routeId: 'R',
    stopId: 'R42',
    stopType: '0',
  },
  {
    stopSequence: '45',
    routeId: 'R',
    stopId: 'R43',
    stopType: '0',
  },
  {
    stopSequence: '46',
    routeId: 'R',
    stopId: 'R44',
    stopType: '0',
  },
  {
    stopSequence: '47',
    routeId: 'R',
    stopId: 'R45',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'SI',
    stopId: 'S31',
    stopType: '0',
  },
  {
    stopSequence: '2',
    routeId: 'SI',
    stopId: 'S30',
    stopType: '0',
  },
  {
    stopSequence: '3',
    routeId: 'SI',
    stopId: 'S29',
    stopType: '0',
  },
  {
    stopSequence: '4',
    routeId: 'SI',
    stopId: 'S28',
    stopType: '0',
  },
  {
    stopSequence: '5',
    routeId: 'SI',
    stopId: 'S27',
    stopType: '0',
  },
  {
    stopSequence: '6',
    routeId: 'SI',
    stopId: 'S26',
    stopType: '0',
  },
  {
    stopSequence: '7',
    routeId: 'SI',
    stopId: 'S25',
    stopType: '0',
  },
  {
    stopSequence: '8',
    routeId: 'SI',
    stopId: 'S24',
    stopType: '0',
  },
  {
    stopSequence: '9',
    routeId: 'SI',
    stopId: 'S23',
    stopType: '0',
  },
  {
    stopSequence: '10',
    routeId: 'SI',
    stopId: 'S22',
    stopType: '0',
  },
  {
    stopSequence: '11',
    routeId: 'SI',
    stopId: 'S21',
    stopType: '0',
  },
  {
    stopSequence: '12',
    routeId: 'SI',
    stopId: 'S20',
    stopType: '0',
  },
  {
    stopSequence: '13',
    routeId: 'SI',
    stopId: 'S19',
    stopType: '0',
  },
  {
    stopSequence: '14',
    routeId: 'SI',
    stopId: 'S18',
    stopType: '0',
  },
  {
    stopSequence: '15',
    routeId: 'SI',
    stopId: 'S17',
    stopType: '0',
  },
  {
    stopSequence: '16',
    routeId: 'SI',
    stopId: 'S16',
    stopType: '0',
  },
  {
    stopSequence: '17',
    routeId: 'SI',
    stopId: 'S15',
    stopType: '0',
  },
  {
    stopSequence: '18',
    routeId: 'SI',
    stopId: 'S14',
    stopType: '0',
  },
  {
    stopSequence: '19',
    routeId: 'SI',
    stopId: 'S13',
    stopType: '0',
  },
  {
    stopSequence: '20',
    routeId: 'SI',
    stopId: 'S11',
    stopType: '0',
  },
  {
    stopSequence: '21',
    routeId: 'SI',
    stopId: 'S09',
    stopType: '0',
  },
  {
    stopSequence: '1',
    routeId: 'W',
    stopId: 'R01',
    stopType: '1',
  },
  {
    stopSequence: '2',
    routeId: 'W',
    stopId: 'R03',
    stopType: '1',
    stopStatus: '-1',
  },
  {
    stopSequence: '3',
    routeId: 'W',
    stopId: 'R04',
    stopType: '1',
  },
  {
    stopSequence: '4',
    routeId: 'W',
    stopId: 'R05',
    stopType: '1',
  },
  {
    stopSequence: '5',
    routeId: 'W',
    stopId: 'R06',
    stopType: '1',
  },
  {
    stopSequence: '6',
    routeId: 'W',
    stopId: 'R08',
    stopType: '1',
  },
  {
    stopSequence: '7',
    routeId: 'W',
    stopId: 'R09',
    stopType: '1',
  },
  {
    stopSequence: '9',
    routeId: 'W',
    stopId: 'R11',
    stopType: '1',
  },
  {
    stopSequence: '10',
    routeId: 'W',
    stopId: 'R13',
    stopType: '1',
  },
  {
    stopSequence: '11',
    routeId: 'W',
    stopId: 'R14',
    stopType: '1',
  },
  {
    stopSequence: '12',
    routeId: 'W',
    stopId: 'R15',
    stopType: '1',
  },
  {
    stopSequence: '13',
    routeId: 'W',
    // Updated from `R16`
    stopId: '127',
    stopType: '1',
  },
  {
    stopSequence: '14',
    routeId: 'W',
    stopId: 'R17',
    stopType: '1',
  },
  {
    stopSequence: '15',
    routeId: 'W',
    stopId: 'R18',
    stopType: '1',
  },
  {
    stopSequence: '16',
    routeId: 'W',
    stopId: 'R19',
    stopType: '1',
  },
  {
    stopSequence: '17',
    routeId: 'W',
    stopId: 'R20',
    stopType: '1',
  },
  {
    stopSequence: '18',
    routeId: 'W',
    stopId: 'R21',
    stopType: '1',
  },
  {
    stopSequence: '19',
    routeId: 'W',
    stopId: 'R22',
    stopType: '1',
  },
  {
    stopSequence: '20',
    routeId: 'W',
    // Updated from `R23`
    stopId: 'R23',
    stopType: '1',
  },
  {
    stopSequence: '21',
    routeId: 'W',
    stopId: 'R24',
    stopType: '1',
  },
  {
    stopSequence: '22',
    routeId: 'W',
    stopId: 'R25',
    stopType: '1',
  },
  {
    stopSequence: '23',
    routeId: 'W',
    stopId: 'R26',
    stopType: '1',
  },
  {
    stopSequence: '24',
    routeId: 'W',
    stopId: 'R27',
    stopType: '1',
  },
  {
    stopSequence: '1',
    routeId: 'Z',
    stopId: 'G05',
    stopType: '3',
  },
  {
    stopSequence: '2',
    routeId: 'Z',
    stopId: 'G06',
    stopType: '3',
  },
  {
    stopSequence: '3',
    routeId: 'Z',
    stopId: 'J12',
    stopType: '3',
  },
  // Copied from routeId `J`
  {
    stopSequence: '4',
    routeId: 'Z',
    stopId: 'J13',
    stopType: '2',
  },
  {
    stopSequence: '4',
    routeId: 'Z',
    stopId: 'J14',
    stopType: '3',
  },
  {
    stopSequence: '5',
    routeId: 'Z',
    stopId: 'J15',
    stopType: '3',
  },
  // Copied from routeId `J`
  {
    stopSequence: '7',
    routeId: 'Z',
    stopId: 'J16',
    stopType: '2',
  },
  {
    stopSequence: '6',
    routeId: 'Z',
    stopId: 'J17',
    stopType: '3',
  },
  // Copied from routeId `J`
  {
    stopSequence: '10',
    routeId: 'Z',
    stopId: 'J19',
    stopType: '2',
  },
  {
    stopSequence: '8',
    routeId: 'Z',
    stopId: 'J20',
    stopType: '3',
  },
  {
    stopSequence: '9',
    routeId: 'Z',
    stopId: 'J21',
    stopType: '3',
  },
  // Copied from routeId `J`
  {
    stopSequence: '13',
    routeId: 'Z',
    stopId: 'J22',
    stopType: '2',
  },
  {
    stopSequence: '11',
    routeId: 'Z',
    stopId: 'J23',
    stopType: '3',
  },
  {
    stopSequence: '12',
    routeId: 'Z',
    stopId: 'J24',
    stopType: '3',
  },
  {
    stopSequence: '13',
    routeId: 'Z',
    stopId: 'J27',
    stopType: '3',
  },
  {
    stopSequence: '14',
    routeId: 'Z',
    stopId: 'J28',
    stopType: '3',
  },
  // Copied from routeId `J`
  {
    stopSequence: '18',
    routeId: 'Z',
    stopId: 'J29',
    stopType: '2',
  },
  {
    stopSequence: '15',
    routeId: 'Z',
    stopId: 'J30',
    stopType: '3',
  },
  // Copied from routeId `J`
  {
    stopSequence: '20',
    routeId: 'Z',
    stopId: 'J31',
    stopType: '2',
  },
  {
    stopSequence: '16',
    routeId: 'Z',
    stopId: 'M11',
    stopType: '3',
  },
  // Copied from routeId `J`
  {
    stopSequence: '22',
    routeId: 'Z',
    stopId: 'M12',
    stopType: '2',
  },
  // Copied from routeId `J`
  {
    stopSequence: '23',
    routeId: 'Z',
    stopId: 'M13',
    stopType: '2',
  },
  // Copied from routeId `J`
  {
    stopSequence: '24',
    routeId: 'Z',
    stopId: 'M14',
    stopType: '2',
  },
  {
    stopSequence: '17',
    routeId: 'Z',
    stopId: 'M16',
    stopType: '3',
  },
  {
    stopSequence: '18',
    routeId: 'Z',
    stopId: 'M18',
    stopType: '3',
  },
  {
    stopSequence: '19',
    routeId: 'Z',
    stopId: 'M19',
    stopType: '3',
  },
  {
    stopSequence: '20',
    routeId: 'Z',
    // Updated from `M20`
    stopId: 'Q01',
    stopType: '3',
  },
  {
    stopSequence: '21',
    routeId: 'Z',
    stopId: 'M21',
    stopType: '3',
  },
  // Updated stopId from M22
  {
    stopSequence: '22',
    routeId: 'Z',
    stopId: 'A38',
    stopType: '3',
  },
  {
    stopSequence: '23',
    routeId: 'Z',
    stopId: 'M23',
    stopType: '3',
  },
];

export default stopsOnRoutes;
