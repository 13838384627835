import { SubwayStopEntrance } from '../subway-types';

// Do not change this file content.
// run "yarn update-stop-entrances" to re-generate this file.
const otsStopsEntrances: SubwayStopEntrance[] = [
  {
    entranceId: 'A40-entrance-0',
    lat: 40.699405,
    lon: -73.991413,
    stopId: 'A40',
  },
  {
    entranceId: 'A40-entrance-1',
    lat: 40.699112,
    lon: -73.98892,
    stopId: 'A40',
  },
  {
    entranceId: 'A40-entrance-2',
    lat: 40.699212,
    lon: -73.988274,
    stopId: 'A40',
  },
  {
    entranceId: 'F03-entrance-1',
    lat: 40.707873,
    lon: -73.802999,
    stopId: 'F03',
  },
  {
    entranceId: 'F03-entrance-2',
    lat: 40.707563,
    lon: -73.802611,
    stopId: 'F03',
  },
  {
    entranceId: 'F03-entrance-0',
    lat: 40.707473,
    lon: -73.802797,
    stopId: 'F03',
  },
  {
    entranceId: 'F03-entrance-5',
    lat: 40.707063,
    lon: -73.80462,
    stopId: 'F03',
  },
  {
    entranceId: 'F03-entrance-3',
    lat: 40.707062,
    lon: -73.804332,
    stopId: 'F03',
  },
  {
    entranceId: 'F03-entrance-4',
    lat: 40.707358,
    lon: -73.804498,
    stopId: 'F03',
  },
  {
    entranceId: 'G13-entrance-1',
    lat: 40.742066,
    lon: -73.881039,
    stopId: 'G13',
  },
  {
    entranceId: 'G13-entrance-2',
    lat: 40.741861,
    lon: -73.881457,
    stopId: 'G13',
  },
  {
    entranceId: 'G13-entrance-0',
    lat: 40.741729,
    lon: -73.881135,
    stopId: 'G13',
  },
  {
    entranceId: 'G13-entrance-3',
    lat: 40.742937,
    lon: -73.882708,
    stopId: 'G13',
  },
  {
    entranceId: 'G13-entrance-4',
    lat: 40.742791,
    lon: -73.883025,
    stopId: 'G13',
  },
  {
    entranceId: 'D24-entrance-6',
    lat: 40.683407,
    lon: -73.976507,
    stopId: 'D24',
  },
  {
    entranceId: 'D24-entrance-7',
    lat: 40.685237,
    lon: -73.977678,
    stopId: 'D24',
  },
  {
    entranceId: 'D24-entrance-4',
    lat: 40.683407,
    lon: -73.976507,
    stopId: 'D24',
  },
  {
    entranceId: 'D24-entrance-5',
    lat: 40.683407,
    lon: -73.976507,
    stopId: 'D24',
  },
  {
    entranceId: 'D24-entrance-2',
    lat: 40.683407,
    lon: -73.976507,
    stopId: 'D24',
  },
  {
    entranceId: 'D24-entrance-3',
    lat: 40.683407,
    lon: -73.976507,
    stopId: 'D24',
  },
  {
    entranceId: 'D24-entrance-0',
    lat: 40.683451,
    lon: -73.976461,
    stopId: 'D24',
  },
  {
    entranceId: 'D24-entrance-1',
    lat: 40.68343,
    lon: -73.976466,
    stopId: 'D24',
  },
  {
    entranceId: '612-entrance-0',
    lat: 40.827298,
    lon: -73.886256,
    stopId: '612',
  },
  {
    entranceId: '612-entrance-1',
    lat: 40.827295,
    lon: -73.886082,
    stopId: '612',
  },
  {
    entranceId: 'B04-entrance-0',
    lat: 40.754131,
    lon: -73.942491,
    stopId: 'B04',
  },
  {
    entranceId: 'B04-entrance-1',
    lat: 40.754195,
    lon: -73.942521,
    stopId: 'B04',
  },
  {
    entranceId: 'B04-entrance-2',
    lat: 40.754069,
    lon: -73.942132,
    stopId: 'B04',
  },
  {
    entranceId: 'B04-entrance-3',
    lat: 40.754255,
    lon: -73.941935,
    stopId: 'B04',
  },
  {
    entranceId: '502-entrance-0',
    lat: 40.87933,
    lon: -73.837858,
    stopId: '502',
  },
  {
    entranceId: '502-entrance-1',
    lat: 40.879519,
    lon: -73.837958,
    stopId: '502',
  },
  {
    entranceId: 'L17-entrance-2',
    lat: 40.699807,
    lon: -73.910999,
    stopId: 'L17',
  },
  {
    entranceId: 'L17-entrance-0',
    lat: 40.699814,
    lon: -73.911295,
    stopId: 'L17',
  },
  {
    entranceId: 'L17-entrance-1',
    lat: 40.699468,
    lon: -73.910648,
    stopId: 'L17',
  },
  {
    entranceId: '103-entrance-0',
    lat: 40.884813,
    lon: -73.900923,
    stopId: '103',
  },
  {
    entranceId: '103-entrance-1',
    lat: 40.884868,
    lon: -73.900598,
    stopId: '103',
  },
  {
    entranceId: '103-entrance-2',
    lat: 40.885226,
    lon: -73.900439,
    stopId: '103',
  },
  {
    entranceId: 'G08-entrance-1',
    lat: 40.72149,
    lon: -73.843389,
    stopId: 'G08',
  },
  {
    entranceId: 'G08-entrance-2',
    lat: 40.722269,
    lon: -73.845153,
    stopId: 'G08',
  },
  {
    entranceId: 'G08-entrance-3',
    lat: 40.721409,
    lon: -73.844632,
    stopId: 'G08',
  },
  {
    entranceId: 'G08-entrance-4',
    lat: 40.721916,
    lon: -73.844352,
    stopId: 'G08',
  },
  {
    entranceId: 'G08-entrance-0',
    lat: 40.7211,
    lon: -73.844039,
    stopId: 'G08',
  },
  {
    entranceId: '607-entrance-0',
    lat: 40.834093,
    lon: -73.851857,
    stopId: '607',
  },
  {
    entranceId: '607-entrance-1',
    lat: 40.834321,
    lon: -73.85144,
    stopId: '607',
  },
  {
    entranceId: '208-entrance-0',
    lat: 40.877779,
    lon: -73.866185,
    stopId: '208',
  },
  {
    entranceId: '208-entrance-1',
    lat: 40.877839,
    lon: -73.866421,
    stopId: '208',
  },
  {
    entranceId: 'A17-entrance-0',
    lat: 40.800002,
    lon: -73.958882,
    stopId: 'A17',
  },
  {
    entranceId: 'A17-entrance-1',
    lat: 40.800002,
    lon: -73.958448,
    stopId: 'A17',
  },
  {
    entranceId: 'A17-entrance-2',
    lat: 40.800866,
    lon: -73.958405,
    stopId: 'A17',
  },
  {
    entranceId: 'F21-entrance-3',
    lat: 40.680944,
    lon: -73.994247,
    stopId: 'F21',
  },
  {
    entranceId: 'F21-entrance-4',
    lat: 40.681115,
    lon: -73.994434,
    stopId: 'F21',
  },
  {
    entranceId: 'F21-entrance-0',
    lat: 40.679299,
    lon: -73.995698,
    stopId: 'F21',
  },
  {
    entranceId: 'F21-entrance-1',
    lat: 40.679344,
    lon: -73.995288,
    stopId: 'F21',
  },
  {
    entranceId: 'F21-entrance-2',
    lat: 40.680806,
    lon: -73.99435,
    stopId: 'F21',
  },
  {
    entranceId: '640-entrance-8',
    lat: 40.713899,
    lon: -74.003642,
    stopId: '640',
  },
  {
    entranceId: '640-entrance-4',
    lat: 40.711824,
    lon: -74.005057,
    stopId: '640',
  },
  {
    entranceId: '640-entrance-5',
    lat: 40.713721,
    lon: -74.003734,
    stopId: '640',
  },
  {
    entranceId: '640-entrance-6',
    lat: 40.713721,
    lon: -74.003734,
    stopId: '640',
  },
  {
    entranceId: '640-entrance-7',
    lat: 40.713839,
    lon: -74.003864,
    stopId: '640',
  },
  {
    entranceId: '640-entrance-0',
    lat: 40.712868,
    lon: -74.004806,
    stopId: '640',
  },
  {
    entranceId: '640-entrance-1',
    lat: 40.712723,
    lon: -74.004971,
    stopId: '640',
  },
  {
    entranceId: '640-entrance-2',
    lat: 40.712333,
    lon: -74.004387,
    stopId: '640',
  },
  {
    entranceId: '640-entrance-3',
    lat: 40.711857,
    lon: -74.005114,
    stopId: '640',
  },
  {
    entranceId: '420-entrance-2',
    lat: 40.704795,
    lon: -74.014454,
    stopId: '420',
  },
  {
    entranceId: '420-entrance-3',
    lat: 40.704157,
    lon: -74.014509,
    stopId: '420',
  },
  {
    entranceId: '420-entrance-0',
    lat: 40.704629,
    lon: -74.013502,
    stopId: '420',
  },
  {
    entranceId: '420-entrance-1',
    lat: 40.704825,
    lon: -74.014572,
    stopId: '420',
  },
  {
    entranceId: 'D42-entrance-0',
    lat: 40.575499,
    lon: -73.976873,
    stopId: 'D42',
  },
  {
    entranceId: 'D42-entrance-1',
    lat: 40.575929,
    lon: -73.976747,
    stopId: 'D42',
  },
  {
    entranceId: 'D42-entrance-2',
    lat: 40.575752,
    lon: -73.97674,
    stopId: 'D42',
  },
  {
    entranceId: 'D42-entrance-3',
    lat: 40.576464,
    lon: -73.974862,
    stopId: 'D42',
  },
  {
    entranceId: 'J20-entrance-0',
    lat: 40.683478,
    lon: -73.872548,
    stopId: 'J20',
  },
  {
    entranceId: 'J20-entrance-1',
    lat: 40.683588,
    lon: -73.8726,
    stopId: 'J20',
  },
  {
    entranceId: '131-entrance-0',
    lat: 40.74092,
    lon: -73.997698,
    stopId: '131',
  },
  {
    entranceId: '131-entrance-5',
    lat: 40.741617,
    lon: -73.997197,
    stopId: '131',
  },
  {
    entranceId: '131-entrance-3',
    lat: 40.741206,
    lon: -73.998035,
    stopId: '131',
  },
  {
    entranceId: '131-entrance-4',
    lat: 40.741783,
    lon: -73.997557,
    stopId: '131',
  },
  {
    entranceId: '131-entrance-1',
    lat: 40.741015,
    lon: -73.99758,
    stopId: '131',
  },
  {
    entranceId: '131-entrance-2',
    lat: 40.741081,
    lon: -73.998105,
    stopId: '131',
  },
  {
    entranceId: 'B17-entrance-0',
    lat: 40.618834,
    lon: -73.999195,
    stopId: 'B17',
  },
  {
    entranceId: 'B17-entrance-1',
    lat: 40.619133,
    lon: -73.99924,
    stopId: 'B17',
  },
  {
    entranceId: 'B17-entrance-2',
    lat: 40.61877,
    lon: -73.998995,
    stopId: 'B17',
  },
  {
    entranceId: 'B17-entrance-3',
    lat: 40.618889,
    lon: -73.998832,
    stopId: 'B17',
  },
  {
    entranceId: 'B17-entrance-4',
    lat: 40.62036,
    lon: -73.99855,
    stopId: 'B17',
  },
  {
    entranceId: 'B17-entrance-5',
    lat: 40.620379,
    lon: -73.998764,
    stopId: 'B17',
  },
  {
    entranceId: 'D37-entrance-0',
    lat: 40.598991,
    lon: -73.955828,
    stopId: 'D37',
  },
  {
    entranceId: '625-entrance-3',
    lat: 40.785903,
    lon: -73.950695,
    stopId: '625',
  },
  {
    entranceId: '625-entrance-1',
    lat: 40.78564,
    lon: -73.95093,
    stopId: '625',
  },
  {
    entranceId: '625-entrance-2',
    lat: 40.786055,
    lon: -73.951057,
    stopId: '625',
  },
  {
    entranceId: 'F16-entrance-0',
    lat: 40.712727,
    lon: -73.990126,
    stopId: 'F16',
  },
  {
    entranceId: '625-entrance-0',
    lat: 40.785843,
    lon: -73.951232,
    stopId: '625',
  },
  {
    entranceId: 'F16-entrance-3',
    lat: 40.714404,
    lon: -73.989918,
    stopId: 'F16',
  },
  {
    entranceId: 'F16-entrance-1',
    lat: 40.713906,
    lon: -73.990014,
    stopId: 'F16',
  },
  {
    entranceId: 'F16-entrance-2',
    lat: 40.714194,
    lon: -73.990352,
    stopId: 'F16',
  },
  {
    entranceId: '108-entrance-3',
    lat: 40.864599,
    lon: -73.918681,
    stopId: '108',
  },
  {
    entranceId: '108-entrance-0',
    lat: 40.864745,
    lon: -73.918864,
    stopId: '108',
  },
  {
    entranceId: '108-entrance-1',
    lat: 40.864283,
    lon: -73.919222,
    stopId: '108',
  },
  {
    entranceId: '108-entrance-2',
    lat: 40.86423,
    lon: -73.918951,
    stopId: '108',
  },
  {
    entranceId: 'M09-entrance-0',
    lat: 40.698717,
    lon: -73.918655,
    stopId: 'M09',
  },
  {
    entranceId: 'M09-entrance-1',
    lat: 40.698913,
    lon: -73.918771,
    stopId: 'M09',
  },
  {
    entranceId: '402-entrance-0',
    lat: 40.880077,
    lon: -73.88447,
    stopId: '402',
  },
  {
    entranceId: '402-entrance-1',
    lat: 40.879899,
    lon: -73.884244,
    stopId: '402',
  },
  {
    entranceId: '402-entrance-2',
    lat: 40.879503,
    lon: -73.885138,
    stopId: '402',
  },
  {
    entranceId: '402-entrance-3',
    lat: 40.879343,
    lon: -73.88488,
    stopId: '402',
  },
  {
    entranceId: '415-entrance-0',
    lat: 40.818281,
    lon: -73.927251,
    stopId: '415',
  },
  {
    entranceId: '415-entrance-1',
    lat: 40.818405,
    lon: -73.927101,
    stopId: '415',
  },
  {
    entranceId: '415-entrance-2',
    lat: 40.818259,
    lon: -73.927659,
    stopId: '415',
  },
  {
    entranceId: '415-entrance-3',
    lat: 40.818373,
    lon: -73.927609,
    stopId: '415',
  },
  {
    entranceId: '249-entrance-0',
    lat: 40.669261,
    lon: -73.941948,
    stopId: '249',
  },
  {
    entranceId: '249-entrance-1',
    lat: 40.669289,
    lon: -73.942463,
    stopId: '249',
  },
  {
    entranceId: 'R13-entrance-6',
    lat: 40.764859,
    lon: -73.973998,
    stopId: 'R13',
  },
  {
    entranceId: 'R13-entrance-0',
    lat: 40.765156,
    lon: -73.972501,
    stopId: 'R13',
  },
  {
    entranceId: 'R13-entrance-1',
    lat: 40.764728,
    lon: -73.972396,
    stopId: 'R13',
  },
  {
    entranceId: 'R13-entrance-4',
    lat: 40.764599,
    lon: -73.974228,
    stopId: 'R13',
  },
  {
    entranceId: 'R13-entrance-5',
    lat: 40.764935,
    lon: -73.974191,
    stopId: 'R13',
  },
  {
    entranceId: 'R13-entrance-2',
    lat: 40.764849,
    lon: -73.972306,
    stopId: 'R13',
  },
  {
    entranceId: 'R13-entrance-3',
    lat: 40.764657,
    lon: -73.972229,
    stopId: 'R13',
  },
  {
    entranceId: 'D11-entrance-6',
    lat: 40.827539,
    lon: -73.924156,
    stopId: 'D11',
  },
  {
    entranceId: 'D11-entrance-4',
    lat: 40.827372,
    lon: -73.924217,
    stopId: 'D11',
  },
  {
    entranceId: 'D11-entrance-5',
    lat: 40.827572,
    lon: -73.924028,
    stopId: 'D11',
  },
  {
    entranceId: 'D11-entrance-2',
    lat: 40.827545,
    lon: -73.926202,
    stopId: 'D11',
  },
  {
    entranceId: 'D11-entrance-3',
    lat: 40.828011,
    lon: -73.925588,
    stopId: 'D11',
  },
  {
    entranceId: 'D11-entrance-0',
    lat: 40.827488,
    lon: -73.926004,
    stopId: 'D11',
  },
  {
    entranceId: 'D11-entrance-1',
    lat: 40.827471,
    lon: -73.92625,
    stopId: 'D11',
  },
  {
    entranceId: '236-entrance-3',
    lat: 40.680709,
    lon: -73.975241,
    stopId: '236',
  },
  {
    entranceId: '236-entrance-4',
    lat: 40.680951,
    lon: -73.975448,
    stopId: '236',
  },
  {
    entranceId: '236-entrance-0',
    lat: 40.680695,
    lon: -73.974748,
    stopId: '236',
  },
  {
    entranceId: '236-entrance-1',
    lat: 40.680911,
    lon: -73.97496,
    stopId: '236',
  },
  {
    entranceId: '236-entrance-2',
    lat: 40.680832,
    lon: -73.975534,
    stopId: '236',
  },
  {
    entranceId: 'R41-entrance-1',
    lat: 40.641435,
    lon: -74.017561,
    stopId: 'R41',
  },
  {
    entranceId: 'R41-entrance-2',
    lat: 40.641778,
    lon: -74.017722,
    stopId: 'R41',
  },
  {
    entranceId: 'R41-entrance-3',
    lat: 40.641633,
    lon: -74.017362,
    stopId: 'R41',
  },
  {
    entranceId: 'R41-entrance-4',
    lat: 40.640682,
    lon: -74.018857,
    stopId: 'R41',
  },
  {
    entranceId: 'R41-entrance-5',
    lat: 40.640501,
    lon: -74.018531,
    stopId: 'R41',
  },
  {
    entranceId: 'R41-entrance-0',
    lat: 40.641606,
    lon: -74.017897,
    stopId: 'R41',
  },
  {
    entranceId: '638-entrance-3',
    lat: 40.72239,
    lon: -73.997437,
    stopId: '638',
  },
  {
    entranceId: '638-entrance-1',
    lat: 40.722235,
    lon: -73.996818,
    stopId: '638',
  },
  {
    entranceId: '638-entrance-2',
    lat: 40.722468,
    lon: -73.997397,
    stopId: '638',
  },
  {
    entranceId: '638-entrance-0',
    lat: 40.722116,
    lon: -73.996912,
    stopId: '638',
  },
  {
    entranceId: 'D16-entrance-7',
    lat: 40.755227,
    lon: -73.984054,
    stopId: 'D16',
  },
  {
    entranceId: 'D16-entrance-5',
    lat: 40.755021,
    lon: -73.983777,
    stopId: 'D16',
  },
  {
    entranceId: 'D16-entrance-6',
    lat: 40.755061,
    lon: -73.984174,
    stopId: 'D16',
  },
  {
    entranceId: 'D16-entrance-3',
    lat: 40.753713,
    lon: -73.985237,
    stopId: 'D16',
  },
  {
    entranceId: 'D16-entrance-4',
    lat: 40.754599,
    lon: -73.983889,
    stopId: 'D16',
  },
  {
    entranceId: 'D16-entrance-1',
    lat: 40.75339,
    lon: -73.984854,
    stopId: 'D16',
  },
  {
    entranceId: 'D16-entrance-2',
    lat: 40.753513,
    lon: -73.984766,
    stopId: 'D16',
  },
  {
    entranceId: 'D16-entrance-0',
    lat: 40.753037,
    lon: -73.985606,
    stopId: 'D16',
  },
  {
    entranceId: 'D09-entrance-0',
    lat: 40.839751,
    lon: -73.912743,
    stopId: 'D09',
  },
  {
    entranceId: 'D09-entrance-1',
    lat: 40.840097,
    lon: -73.913333,
    stopId: 'D09',
  },
  {
    entranceId: 'D09-entrance-2',
    lat: 40.838618,
    lon: -73.914191,
    stopId: 'D09',
  },
  {
    entranceId: 'D09-entrance-3',
    lat: 40.838991,
    lon: -73.914037,
    stopId: 'D09',
  },
  {
    entranceId: 'D09-entrance-4',
    lat: 40.838421,
    lon: -73.913626,
    stopId: 'D09',
  },
  {
    entranceId: '210-entrance-0',
    lat: 40.865605,
    lon: -73.867476,
    stopId: '210',
  },
  {
    entranceId: '210-entrance-1',
    lat: 40.865257,
    lon: -73.867246,
    stopId: '210',
  },
  {
    entranceId: 'G21-entrance-2',
    lat: 40.748697,
    lon: -73.937364,
    stopId: 'G21',
  },
  {
    entranceId: 'G21-entrance-3',
    lat: 40.748807,
    lon: -73.937057,
    stopId: 'G21',
  },
  {
    entranceId: 'G21-entrance-0',
    lat: 40.748705,
    lon: -73.938471,
    stopId: 'G21',
  },
  {
    entranceId: 'G21-entrance-1',
    lat: 40.749086,
    lon: -73.937896,
    stopId: 'G21',
  },
  {
    entranceId: 'G21-entrance-6',
    lat: 40.749857,
    lon: -73.936198,
    stopId: 'G21',
  },
  {
    entranceId: 'G21-entrance-4',
    lat: 40.748466,
    lon: -73.938244,
    stopId: 'G21',
  },
  {
    entranceId: 'G21-entrance-5',
    lat: 40.749577,
    lon: -73.936094,
    stopId: 'G21',
  },
  {
    entranceId: 'F34-entrance-1',
    lat: 40.609041,
    lon: -73.972904,
    stopId: 'F34',
  },
  {
    entranceId: 'F34-entrance-0',
    lat: 40.608615,
    lon: -73.973097,
    stopId: 'F34',
  },
  {
    entranceId: 'N04-entrance-0',
    lat: 40.625606,
    lon: -73.99683,
    stopId: 'N04',
  },
  {
    entranceId: 'N04-entrance-1',
    lat: 40.624246,
    lon: -73.995962,
    stopId: 'N04',
  },
  {
    entranceId: '111-entrance-0',
    lat: 40.849435,
    lon: -73.933428,
    stopId: '111',
  },
  {
    entranceId: '111-entrance-1',
    lat: 40.849098,
    lon: -73.933747,
    stopId: '111',
  },
  {
    entranceId: '244-entrance-0',
    lat: 40.65064,
    lon: -73.949429,
    stopId: '244',
  },
  {
    entranceId: '244-entrance-1',
    lat: 40.65091,
    lon: -73.949416,
    stopId: '244',
  },
  {
    entranceId: '244-entrance-2',
    lat: 40.650894,
    lon: -73.949819,
    stopId: '244',
  },
  {
    entranceId: '244-entrance-3',
    lat: 40.650969,
    lon: -73.949714,
    stopId: '244',
  },
  {
    entranceId: '251-entrance-2',
    lat: 40.66516,
    lon: -73.92297,
    stopId: '251',
  },
  {
    entranceId: '251-entrance-0',
    lat: 40.664891,
    lon: -73.922675,
    stopId: '251',
  },
  {
    entranceId: '251-entrance-1',
    lat: 40.664887,
    lon: -73.923109,
    stopId: '251',
  },
  {
    entranceId: '712-entrance-0',
    lat: 40.745531,
    lon: -73.903248,
    stopId: '712',
  },
  {
    entranceId: '712-entrance-1',
    lat: 40.745717,
    lon: -73.903063,
    stopId: '712',
  },
  {
    entranceId: '712-entrance-2',
    lat: 40.745692,
    lon: -73.903234,
    stopId: '712',
  },
  {
    entranceId: 'B12-entrance-0',
    lat: 40.646337,
    lon: -73.994489,
    stopId: 'B12',
  },
  {
    entranceId: '216-entrance-0',
    lat: 40.829903,
    lon: -73.89202,
    stopId: '216',
  },
  {
    entranceId: '216-entrance-3',
    lat: 40.830177,
    lon: -73.891482,
    stopId: '216',
  },
  {
    entranceId: '216-entrance-1',
    lat: 40.830369,
    lon: -73.892015,
    stopId: '216',
  },
  {
    entranceId: '216-entrance-2',
    lat: 40.830074,
    lon: -73.891486,
    stopId: '216',
  },
  {
    entranceId: 'A09-entrance-2',
    lat: 40.841928,
    lon: -73.939455,
    stopId: 'A09',
  },
  {
    entranceId: 'A09-entrance-3',
    lat: 40.84176,
    lon: -73.939067,
    stopId: 'A09',
  },
  {
    entranceId: 'A09-entrance-0',
    lat: 40.840741,
    lon: -73.939252,
    stopId: 'A09',
  },
  {
    entranceId: 'A09-entrance-1',
    lat: 40.84186,
    lon: -73.939569,
    stopId: 'A09',
  },
  {
    entranceId: 'D03-entrance-0',
    lat: 40.872135,
    lon: -73.887459,
    stopId: 'D03',
  },
  {
    entranceId: 'D03-entrance-3',
    lat: 40.874084,
    lon: -73.887112,
    stopId: 'D03',
  },
  {
    entranceId: 'D03-entrance-4',
    lat: 40.874025,
    lon: -73.886466,
    stopId: 'D03',
  },
  {
    entranceId: 'D03-entrance-1',
    lat: 40.872198,
    lon: -73.887788,
    stopId: 'D03',
  },
  {
    entranceId: 'D03-entrance-2',
    lat: 40.872468,
    lon: -73.887894,
    stopId: 'D03',
  },
  {
    entranceId: 'G26-entrance-0',
    lat: 40.73007,
    lon: -73.954377,
    stopId: 'G26',
  },
  {
    entranceId: 'G26-entrance-3',
    lat: 40.732258,
    lon: -73.954379,
    stopId: 'G26',
  },
  {
    entranceId: 'G26-entrance-4',
    lat: 40.732216,
    lon: -73.954784,
    stopId: 'G26',
  },
  {
    entranceId: 'G26-entrance-1',
    lat: 40.730122,
    lon: -73.954049,
    stopId: 'G26',
  },
  {
    entranceId: 'G26-entrance-2',
    lat: 40.730278,
    lon: -73.954488,
    stopId: 'G26',
  },
  {
    entranceId: '302-entrance-1',
    lat: 40.820662,
    lon: -73.936275,
    stopId: '302',
  },
  {
    entranceId: '302-entrance-2',
    lat: 40.820161,
    lon: -73.936253,
    stopId: '302',
  },
  {
    entranceId: '302-entrance-0',
    lat: 40.820343,
    lon: -73.936508,
    stopId: '302',
  },
  {
    entranceId: '302-entrance-3',
    lat: 40.820602,
    lon: -73.935933,
    stopId: '302',
  },
  {
    entranceId: 'D15-entrance-13',
    lat: 40.758486,
    lon: -73.981158,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-14',
    lat: 40.758615,
    lon: -73.981095,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-10',
    lat: 40.758024,
    lon: -73.982079,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-11',
    lat: 40.757981,
    lon: -73.98154,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-12',
    lat: 40.757862,
    lon: -73.981735,
    stopId: 'D15',
  },
  {
    entranceId: '231-entrance-0',
    lat: 40.69759,
    lon: -73.993275,
    stopId: '231',
  },
  {
    entranceId: '231-entrance-1',
    lat: 40.697649,
    lon: -73.99306,
    stopId: '231',
  },
  {
    entranceId: '633-entrance-6',
    lat: 40.743351,
    lon: -73.984302,
    stopId: '633',
  },
  {
    entranceId: '633-entrance-4',
    lat: 40.743484,
    lon: -73.98428,
    stopId: '633',
  },
  {
    entranceId: '633-entrance-5',
    lat: 40.743394,
    lon: -73.984409,
    stopId: '633',
  },
  {
    entranceId: '633-entrance-2',
    lat: 40.743304,
    lon: -73.983857,
    stopId: '633',
  },
  {
    entranceId: '633-entrance-3',
    lat: 40.743257,
    lon: -73.983738,
    stopId: '633',
  },
  {
    entranceId: '633-entrance-0',
    lat: 40.743128,
    lon: -73.983806,
    stopId: '633',
  },
  {
    entranceId: '633-entrance-1',
    lat: 40.743167,
    lon: -73.983896,
    stopId: '633',
  },
  {
    entranceId: '257-entrance-0',
    lat: 40.666285,
    lon: -73.883276,
    stopId: '257',
  },
  {
    entranceId: '257-entrance-1',
    lat: 40.666413,
    lon: -73.883307,
    stopId: '257',
  },
  {
    entranceId: 'R21-entrance-1',
    lat: 40.730578,
    lon: -73.992216,
    stopId: 'R21',
  },
  {
    entranceId: 'R21-entrance-2',
    lat: 40.729678,
    lon: -73.993051,
    stopId: 'R21',
  },
  {
    entranceId: 'R21-entrance-0',
    lat: 40.730477,
    lon: -73.992286,
    stopId: 'R21',
  },
  {
    entranceId: 'R21-entrance-5',
    lat: 40.730716,
    lon: -73.992517,
    stopId: 'R21',
  },
  {
    entranceId: 'R21-entrance-6',
    lat: 40.730773,
    lon: -73.992641,
    stopId: 'R21',
  },
  {
    entranceId: 'R21-entrance-3',
    lat: 40.730642,
    lon: -73.992737,
    stopId: 'R21',
  },
  {
    entranceId: 'R21-entrance-4',
    lat: 40.730592,
    lon: -73.99262,
    stopId: 'R21',
  },
  {
    entranceId: 'R21-entrance-7',
    lat: 40.729778,
    lon: -73.993262,
    stopId: 'R21',
  },
  {
    entranceId: '407-entrance-0',
    lat: 40.862562,
    lon: -73.901367,
    stopId: '407',
  },
  {
    entranceId: '407-entrance-1',
    lat: 40.862592,
    lon: -73.90097,
    stopId: '407',
  },
  {
    entranceId: '407-entrance-2',
    lat: 40.86289,
    lon: -73.901262,
    stopId: '407',
  },
  {
    entranceId: '407-entrance-3',
    lat: 40.862814,
    lon: -73.900815,
    stopId: '407',
  },
  {
    entranceId: 'F01-entrance-7',
    lat: 40.712892,
    lon: -73.783363,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-8',
    lat: 40.712548,
    lon: -73.783427,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-5',
    lat: 40.71294,
    lon: -73.783179,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-6',
    lat: 40.712669,
    lon: -73.783274,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-3',
    lat: 40.713113,
    lon: -73.782624,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-4',
    lat: 40.712723,
    lon: -73.783035,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-1',
    lat: 40.713207,
    lon: -73.782119,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-2',
    lat: 40.712723,
    lon: -73.782637,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-0',
    lat: 40.712865,
    lon: -73.782418,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-9',
    lat: 40.71264,
    lon: -73.784411,
    stopId: 'F01',
  },
  {
    entranceId: 'D29-entrance-0',
    lat: 40.644272,
    lon: -73.964528,
    stopId: 'D29',
  },
  {
    entranceId: '211-entrance-2',
    lat: 40.856337,
    lon: -73.867521,
    stopId: '211',
  },
  {
    entranceId: '211-entrance-3',
    lat: 40.858041,
    lon: -73.867718,
    stopId: '211',
  },
  {
    entranceId: '211-entrance-0',
    lat: 40.856338,
    lon: -73.867544,
    stopId: '211',
  },
  {
    entranceId: '211-entrance-1',
    lat: 40.856403,
    lon: -73.867771,
    stopId: '211',
  },
  {
    entranceId: 'R34-entrance-0',
    lat: 40.665299,
    lon: -73.992729,
    stopId: 'R34',
  },
  {
    entranceId: 'R34-entrance-1',
    lat: 40.665058,
    lon: -73.993002,
    stopId: 'R34',
  },
  {
    entranceId: 'R34-entrance-2',
    lat: 40.665269,
    lon: -73.993245,
    stopId: 'R34',
  },
  {
    entranceId: '211-entrance-4',
    lat: 40.858029,
    lon: -73.867481,
    stopId: '211',
  },
  {
    entranceId: 'A33-entrance-1',
    lat: 40.725595,
    lon: -74.004326,
    stopId: 'A33',
  },
  {
    entranceId: 'A33-entrance-2',
    lat: 40.726208,
    lon: -74.00399,
    stopId: 'A33',
  },
  {
    entranceId: 'A33-entrance-0',
    lat: 40.725583,
    lon: -74.003747,
    stopId: 'A33',
  },
  {
    entranceId: 'D31-entrance-0',
    lat: 40.635034,
    lon: -73.962736,
    stopId: 'D31',
  },
  {
    entranceId: 'G20-entrance-0',
    lat: 40.752232,
    lon: -73.928775,
    stopId: 'G20',
  },
  {
    entranceId: 'G20-entrance-1',
    lat: 40.752198,
    lon: -73.929263,
    stopId: 'G20',
  },
  {
    entranceId: 'G20-entrance-2',
    lat: 40.751868,
    lon: -73.930585,
    stopId: 'G20',
  },
  {
    entranceId: 'G20-entrance-3',
    lat: 40.752238,
    lon: -73.927662,
    stopId: 'G20',
  },
  {
    entranceId: 'G20-entrance-4',
    lat: 40.75193,
    lon: -73.927141,
    stopId: 'G20',
  },
  {
    entranceId: 'L24-entrance-0',
    lat: 40.67538,
    lon: -73.902612,
    stopId: 'L24',
  },
  {
    entranceId: 'L24-entrance-1',
    lat: 40.675609,
    lon: -73.902767,
    stopId: 'L24',
  },
  {
    entranceId: 'L24-entrance-2',
    lat: 40.675607,
    lon: -73.902672,
    stopId: 'L24',
  },
  {
    entranceId: 'N03-entrance-0',
    lat: 40.632179,
    lon: -74.006658,
    stopId: 'N03',
  },
  {
    entranceId: 'N03-entrance-1',
    lat: 40.630609,
    lon: -74.004067,
    stopId: 'N03',
  },
  {
    entranceId: '724-entrance-1',
    lat: 40.754175,
    lon: -73.982871,
    stopId: '724',
  },
  {
    entranceId: '724-entrance-2',
    lat: 40.754213,
    lon: -73.982984,
    stopId: '724',
  },
  {
    entranceId: '724-entrance-0',
    lat: 40.753409,
    lon: -73.98113,
    stopId: '724',
  },
  {
    entranceId: '128-entrance-7',
    lat: 40.74979,
    lon: -73.991721,
    stopId: '128',
  },
  {
    entranceId: '128-entrance-8',
    lat: 40.75081,
    lon: -73.990481,
    stopId: '128',
  },
  {
    entranceId: '128-entrance-5',
    lat: 40.750982,
    lon: -73.990899,
    stopId: '128',
  },
  {
    entranceId: '128-entrance-6',
    lat: 40.751187,
    lon: -73.990791,
    stopId: '128',
  },
  {
    entranceId: '128-entrance-3',
    lat: 40.750313,
    lon: -73.990854,
    stopId: '128',
  },
  {
    entranceId: '128-entrance-4',
    lat: 40.750476,
    lon: -73.991254,
    stopId: '128',
  },
  {
    entranceId: '128-entrance-1',
    lat: 40.750077,
    lon: -73.99082,
    stopId: '128',
  },
  {
    entranceId: '128-entrance-2',
    lat: 40.750283,
    lon: -73.99065,
    stopId: '128',
  },
  {
    entranceId: '128-entrance-0',
    lat: 40.749656,
    lon: -73.99113,
    stopId: '128',
  },
  {
    entranceId: '128-entrance-9',
    lat: 40.75102,
    lon: -73.990323,
    stopId: '128',
  },
  {
    entranceId: 'B20-entrance-0',
    lat: 40.604439,
    lon: -73.998164,
    stopId: 'B20',
  },
  {
    entranceId: 'B20-entrance-1',
    lat: 40.604587,
    lon: -73.998007,
    stopId: 'B20',
  },
  {
    entranceId: 'B20-entrance-2',
    lat: 40.604631,
    lon: -73.998498,
    stopId: 'B20',
  },
  {
    entranceId: 'B20-entrance-3',
    lat: 40.604789,
    lon: -73.998349,
    stopId: 'B20',
  },
  {
    entranceId: 'R25-entrance-0',
    lat: 40.710871,
    lon: -74.010767,
    stopId: 'R25',
  },
  {
    entranceId: 'R25-entrance-1',
    lat: 40.711065,
    lon: -74.010644,
    stopId: 'R25',
  },
  {
    entranceId: 'R25-entrance-2',
    lat: 40.710201,
    lon: -74.011198,
    stopId: 'R25',
  },
  {
    entranceId: 'R25-entrance-3',
    lat: 40.710435,
    lon: -74.011066,
    stopId: 'R25',
  },
  {
    entranceId: '243-entrance-1',
    lat: 40.656171,
    lon: -73.950346,
    stopId: '243',
  },
  {
    entranceId: '243-entrance-0',
    lat: 40.657184,
    lon: -73.949976,
    stopId: '243',
  },
  {
    entranceId: '711-entrance-0',
    lat: 40.746509,
    lon: -73.896268,
    stopId: '711',
  },
  {
    entranceId: '711-entrance-1',
    lat: 40.746148,
    lon: -73.896396,
    stopId: '711',
  },
  {
    entranceId: '224-entrance-2',
    lat: 40.813896,
    lon: -73.940831,
    stopId: '224',
  },
  {
    entranceId: '224-entrance-3',
    lat: 40.814188,
    lon: -73.940618,
    stopId: '224',
  },
  {
    entranceId: '224-entrance-0',
    lat: 40.814024,
    lon: -73.941117,
    stopId: '224',
  },
  {
    entranceId: '224-entrance-1',
    lat: 40.814316,
    lon: -73.94091,
    stopId: '224',
  },
  {
    entranceId: 'D17-entrance-0',
    lat: 40.749845,
    lon: -73.988254,
    stopId: 'D17',
  },
  {
    entranceId: 'D17-entrance-1',
    lat: 40.750088,
    lon: -73.988142,
    stopId: 'D17',
  },
  {
    entranceId: 'D17-entrance-2',
    lat: 40.750674,
    lon: -73.988031,
    stopId: 'D17',
  },
  {
    entranceId: 'D17-entrance-3',
    lat: 40.750271,
    lon: -73.987066,
    stopId: 'D17',
  },
  {
    entranceId: 'D17-entrance-4',
    lat: 40.75088,
    lon: -73.988096,
    stopId: 'D17',
  },
  {
    entranceId: 'D17-entrance-5',
    lat: 40.750647,
    lon: -73.987346,
    stopId: 'D17',
  },
  {
    entranceId: 'D17-entrance-6',
    lat: 40.750404,
    lon: -73.986989,
    stopId: 'D17',
  },
  {
    entranceId: 'D17-entrance-7',
    lat: 40.74895,
    lon: -73.988623,
    stopId: 'D17',
  },
  {
    entranceId: 'D17-entrance-8',
    lat: 40.748746,
    lon: -73.988774,
    stopId: 'D17',
  },
  {
    entranceId: '229-entrance-5',
    lat: 40.708477,
    lon: -74.006825,
    stopId: '229',
  },
  {
    entranceId: '229-entrance-3',
    lat: 40.709017,
    lon: -74.006881,
    stopId: '229',
  },
  {
    entranceId: '229-entrance-4',
    lat: 40.708945,
    lon: -74.006943,
    stopId: '229',
  },
  {
    entranceId: '229-entrance-1',
    lat: 40.709692,
    lon: -74.006226,
    stopId: '229',
  },
  {
    entranceId: '229-entrance-2',
    lat: 40.709404,
    lon: -74.005983,
    stopId: '229',
  },
  {
    entranceId: '229-entrance-0',
    lat: 40.709464,
    lon: -74.006342,
    stopId: '229',
  },
  {
    entranceId: '408-entrance-0',
    lat: 40.858267,
    lon: -73.904099,
    stopId: '408',
  },
  {
    entranceId: '408-entrance-1',
    lat: 40.858453,
    lon: -73.90369,
    stopId: '408',
  },
  {
    entranceId: '408-entrance-2',
    lat: 40.858477,
    lon: -73.904088,
    stopId: '408',
  },
  {
    entranceId: 'A20-entrance-0',
    lat: 40.785195,
    lon: -73.969596,
    stopId: 'A20',
  },
  {
    entranceId: 'A20-entrance-3',
    lat: 40.786769,
    lon: -73.968448,
    stopId: 'A20',
  },
  {
    entranceId: 'A20-entrance-4',
    lat: 40.786115,
    lon: -73.969011,
    stopId: 'A20',
  },
  {
    entranceId: 'A20-entrance-1',
    lat: 40.785282,
    lon: -73.969662,
    stopId: 'A20',
  },
  {
    entranceId: 'A20-entrance-2',
    lat: 40.78548,
    lon: -73.969458,
    stopId: 'A20',
  },
  {
    entranceId: '115-entrance-3',
    lat: 40.821391,
    lon: -73.953906,
    stopId: '115',
  },
  {
    entranceId: '115-entrance-1',
    lat: 40.821348,
    lon: -73.954408,
    stopId: '115',
  },
  {
    entranceId: '115-entrance-2',
    lat: 40.821454,
    lon: -73.953856,
    stopId: '115',
  },
  {
    entranceId: '115-entrance-0',
    lat: 40.821584,
    lon: -73.954223,
    stopId: '115',
  },
  {
    entranceId: 'B16-entrance-0',
    lat: 40.627299,
    lon: -73.996465,
    stopId: 'B16',
  },
  {
    entranceId: 'B16-entrance-1',
    lat: 40.62711,
    lon: -73.99687,
    stopId: 'B16',
  },
  {
    entranceId: 'B16-entrance-2',
    lat: 40.627367,
    lon: -73.996729,
    stopId: 'B16',
  },
  {
    entranceId: 'G34-entrance-2',
    lat: 40.688753,
    lon: -73.96019,
    stopId: 'G34',
  },
  {
    entranceId: 'G34-entrance-0',
    lat: 40.688942,
    lon: -73.960215,
    stopId: 'G34',
  },
  {
    entranceId: 'G34-entrance-1',
    lat: 40.688781,
    lon: -73.959871,
    stopId: 'G34',
  },
  {
    entranceId: 'F15-entrance-0',
    lat: 40.718781,
    lon: -73.988233,
    stopId: 'F15',
  },
  {
    entranceId: 'F15-entrance-1',
    lat: 40.718778,
    lon: -73.987961,
    stopId: 'F15',
  },
  {
    entranceId: 'F15-entrance-2',
    lat: 40.718372,
    lon: -73.988445,
    stopId: 'F15',
  },
  {
    entranceId: 'F15-entrance-3',
    lat: 40.718207,
    lon: -73.988273,
    stopId: 'F15',
  },
  {
    entranceId: '632-entrance-3',
    lat: 40.745585,
    lon: -73.982198,
    stopId: '632',
  },
  {
    entranceId: '632-entrance-4',
    lat: 40.745641,
    lon: -73.982103,
    stopId: '632',
  },
  {
    entranceId: '632-entrance-5',
    lat: 40.746447,
    lon: -73.982147,
    stopId: '632',
  },
  {
    entranceId: '632-entrance-6',
    lat: 40.746632,
    lon: -73.981882,
    stopId: '632',
  },
  {
    entranceId: '632-entrance-0',
    lat: 40.746263,
    lon: -73.981663,
    stopId: '632',
  },
  {
    entranceId: '632-entrance-1',
    lat: 40.746242,
    lon: -73.9816,
    stopId: '632',
  },
  {
    entranceId: '632-entrance-2',
    lat: 40.74655,
    lon: -73.981533,
    stopId: '632',
  },
  {
    entranceId: '632-entrance-7',
    lat: 40.745723,
    lon: -73.982531,
    stopId: '632',
  },
  {
    entranceId: '632-entrance-8',
    lat: 40.745821,
    lon: -73.982576,
    stopId: '632',
  },
  {
    entranceId: 'M11-entrance-1',
    lat: 40.696987,
    lon: -73.935559,
    stopId: 'M11',
  },
  {
    entranceId: 'M11-entrance-0',
    lat: 40.696893,
    lon: -73.935461,
    stopId: 'M11',
  },
  {
    entranceId: '619-entrance-2',
    lat: 40.810241,
    lon: -73.925267,
    stopId: '619',
  },
  {
    entranceId: '619-entrance-3',
    lat: 40.810019,
    lon: -73.925438,
    stopId: '619',
  },
  {
    entranceId: '619-entrance-0',
    lat: 40.810282,
    lon: -73.925386,
    stopId: '619',
  },
  {
    entranceId: '619-entrance-1',
    lat: 40.810102,
    lon: -73.925641,
    stopId: '619',
  },
  {
    entranceId: '619-entrance-6',
    lat: 40.810638,
    lon: -73.926883,
    stopId: '619',
  },
  {
    entranceId: '619-entrance-4',
    lat: 40.810831,
    lon: -73.926655,
    stopId: '619',
  },
  {
    entranceId: '619-entrance-5',
    lat: 40.810712,
    lon: -73.92705,
    stopId: '619',
  },
  {
    entranceId: 'G33-entrance-1',
    lat: 40.689757,
    lon: -73.951612,
    stopId: 'G33',
  },
  {
    entranceId: 'G33-entrance-2',
    lat: 40.689543,
    lon: -73.954998,
    stopId: 'G33',
  },
  {
    entranceId: 'G33-entrance-3',
    lat: 40.689367,
    lon: -73.954957,
    stopId: 'G33',
  },
  {
    entranceId: 'G33-entrance-4',
    lat: 40.6895,
    lon: -73.955353,
    stopId: 'G33',
  },
  {
    entranceId: 'G33-entrance-5',
    lat: 40.68933,
    lon: -73.955318,
    stopId: 'G33',
  },
  {
    entranceId: 'G33-entrance-0',
    lat: 40.689995,
    lon: -73.951616,
    stopId: 'G33',
  },
  {
    entranceId: '124-entrance-0',
    lat: 40.773829,
    lon: -73.982433,
    stopId: '124',
  },
  {
    entranceId: '124-entrance-1',
    lat: 40.773576,
    lon: -73.982425,
    stopId: '124',
  },
  {
    entranceId: '124-entrance-2',
    lat: 40.773457,
    lon: -73.982004,
    stopId: '124',
  },
  {
    entranceId: '124-entrance-3',
    lat: 40.773682,
    lon: -73.982015,
    stopId: '124',
  },
  {
    entranceId: 'A24-entrance-9',
    lat: 40.768649,
    lon: -73.981184,
    stopId: 'A24',
  },
  {
    entranceId: 'A24-entrance-5',
    lat: 40.767643,
    lon: -73.982563,
    stopId: 'A24',
  },
  {
    entranceId: 'A24-entrance-6',
    lat: 40.766745,
    lon: -73.983408,
    stopId: 'A24',
  },
  {
    entranceId: 'A24-entrance-7',
    lat: 40.766416,
    lon: -73.983301,
    stopId: 'A24',
  },
  {
    entranceId: 'A24-entrance-8',
    lat: 40.767367,
    lon: -73.982137,
    stopId: 'A24',
  },
  {
    entranceId: 'A24-entrance-1',
    lat: 40.766488,
    lon: -73.982708,
    stopId: 'A24',
  },
  {
    entranceId: 'A24-entrance-2',
    lat: 40.767677,
    lon: -73.982526,
    stopId: 'A24',
  },
  {
    entranceId: 'A24-entrance-3',
    lat: 40.767659,
    lon: -73.982542,
    stopId: 'A24',
  },
  {
    entranceId: 'A24-entrance-4',
    lat: 40.766725,
    lon: -73.982563,
    stopId: 'A24',
  },
  {
    entranceId: 'A24-entrance-0',
    lat: 40.766944,
    lon: -73.982941,
    stopId: 'A24',
  },
  {
    entranceId: '137-entrance-0',
    lat: 40.715444,
    lon: -74.009453,
    stopId: '137',
  },
  {
    entranceId: '137-entrance-1',
    lat: 40.715349,
    lon: -74.009211,
    stopId: '137',
  },
  {
    entranceId: '137-entrance-2',
    lat: 40.715736,
    lon: -74.009328,
    stopId: '137',
  },
  {
    entranceId: '137-entrance-3',
    lat: 40.715576,
    lon: -74.009031,
    stopId: '137',
  },
  {
    entranceId: '137-entrance-4',
    lat: 40.715539,
    lon: -74.009427,
    stopId: '137',
  },
  {
    entranceId: 'A46-entrance-3',
    lat: 40.680262,
    lon: -73.949635,
    stopId: 'A46',
  },
  {
    entranceId: 'A46-entrance-1',
    lat: 40.680572,
    lon: -73.949753,
    stopId: 'A46',
  },
  {
    entranceId: 'A46-entrance-2',
    lat: 40.680228,
    lon: -73.949414,
    stopId: 'A46',
  },
  {
    entranceId: 'A46-entrance-0',
    lat: 40.680547,
    lon: -73.949515,
    stopId: 'A46',
  },
  {
    entranceId: 'A11-entrance-3',
    lat: 40.831139,
    lon: -73.941447,
    stopId: 'A11',
  },
  {
    entranceId: 'A11-entrance-4',
    lat: 40.831031,
    lon: -73.941149,
    stopId: 'A11',
  },
  {
    entranceId: 'A11-entrance-5',
    lat: 40.830921,
    lon: -73.9411,
    stopId: 'A11',
  },
  {
    entranceId: 'A11-entrance-0',
    lat: 40.830744,
    lon: -73.941597,
    stopId: 'A11',
  },
  {
    entranceId: 'A11-entrance-1',
    lat: 40.830854,
    lon: -73.941638,
    stopId: 'A11',
  },
  {
    entranceId: 'A11-entrance-2',
    lat: 40.831106,
    lon: -73.94155,
    stopId: 'A11',
  },
  {
    entranceId: 'D30-entrance-0',
    lat: 40.641314,
    lon: -73.963996,
    stopId: 'D30',
  },
  {
    entranceId: 'G07-entrance-0',
    lat: 40.702731,
    lon: -73.81727,
    stopId: 'G07',
  },
  {
    entranceId: 'G07-entrance-1',
    lat: 40.701241,
    lon: -73.816577,
    stopId: 'G07',
  },
  {
    entranceId: 'G14-entrance-0',
    lat: 40.746784,
    lon: -73.890958,
    stopId: 'G14',
  },
  {
    entranceId: 'G14-entrance-1',
    lat: 40.746597,
    lon: -73.890955,
    stopId: 'G14',
  },
  {
    entranceId: 'G14-entrance-2',
    lat: 40.747238,
    lon: -73.892146,
    stopId: 'G14',
  },
  {
    entranceId: 'G14-entrance-3',
    lat: 40.747034,
    lon: -73.892555,
    stopId: 'G14',
  },
  {
    entranceId: 'G14-entrance-4',
    lat: 40.745988,
    lon: -73.890208,
    stopId: 'G14',
  },
  {
    entranceId: 'G14-entrance-5',
    lat: 40.746391,
    lon: -73.890285,
    stopId: 'G14',
  },
  {
    entranceId: 'G14-entrance-6',
    lat: 40.746527,
    lon: -73.891461,
    stopId: 'G14',
  },
  {
    entranceId: 'A59-entrance-3',
    lat: 40.679361,
    lon: -73.860284,
    stopId: 'A59',
  },
  {
    entranceId: 'A59-entrance-1',
    lat: 40.679498,
    lon: -73.858049,
    stopId: 'A59',
  },
  {
    entranceId: 'A59-entrance-2',
    lat: 40.679215,
    lon: -73.860291,
    stopId: 'A59',
  },
  {
    entranceId: 'A59-entrance-0',
    lat: 40.679371,
    lon: -73.858024,
    stopId: 'A59',
  },
  {
    entranceId: 'L25-entrance-0',
    lat: 40.668527,
    lon: -73.901765,
    stopId: 'L25',
  },
  {
    entranceId: '726-entrance-10',
    lat: 40.755737,
    lon: -74.000221,
    stopId: '726',
  },
  {
    entranceId: 'L12-entrance-3',
    lat: 40.712017,
    lon: -73.940976,
    stopId: 'L12',
  },
  {
    entranceId: 'L12-entrance-1',
    lat: 40.712182,
    lon: -73.940668,
    stopId: 'L12',
  },
  {
    entranceId: 'L12-entrance-2',
    lat: 40.711791,
    lon: -73.940742,
    stopId: 'L12',
  },
  {
    entranceId: 'L12-entrance-0',
    lat: 40.711912,
    lon: -73.940433,
    stopId: 'L12',
  },
  {
    entranceId: 'H13-entrance-0',
    lat: 40.58554,
    lon: -73.820345,
    stopId: 'H13',
  },
  {
    entranceId: 'H13-entrance-1',
    lat: 40.585342,
    lon: -73.820249,
    stopId: 'H13',
  },
  {
    entranceId: 'H13-entrance-2',
    lat: 40.585208,
    lon: -73.820595,
    stopId: 'H13',
  },
  {
    entranceId: '705-entrance-2',
    lat: 40.751551,
    lon: -73.855628,
    stopId: '705',
  },
  {
    entranceId: '705-entrance-0',
    lat: 40.75161,
    lon: -73.856065,
    stopId: '705',
  },
  {
    entranceId: '705-entrance-1',
    lat: 40.751423,
    lon: -73.855996,
    stopId: '705',
  },
  {
    entranceId: '207-entrance-0',
    lat: 40.883723,
    lon: -73.86287,
    stopId: '207',
  },
  {
    entranceId: '207-entrance-1',
    lat: 40.883439,
    lon: -73.862743,
    stopId: '207',
  },
  {
    entranceId: '230-entrance-3',
    lat: 40.706385,
    lon: -74.009658,
    stopId: '230',
  },
  {
    entranceId: '230-entrance-4',
    lat: 40.706272,
    lon: -74.009447,
    stopId: '230',
  },
  {
    entranceId: '230-entrance-1',
    lat: 40.706392,
    lon: -74.009332,
    stopId: '230',
  },
  {
    entranceId: '230-entrance-2',
    lat: 40.707431,
    lon: -74.008778,
    stopId: '230',
  },
  {
    entranceId: '230-entrance-5',
    lat: 40.706755,
    lon: -74.008804,
    stopId: '230',
  },
  {
    entranceId: '230-entrance-0',
    lat: 40.706506,
    lon: -74.009544,
    stopId: '230',
  },
  {
    entranceId: '606-entrance-0',
    lat: 40.836954,
    lon: -73.846653,
    stopId: '606',
  },
  {
    entranceId: '606-entrance-1',
    lat: 40.836613,
    lon: -73.846726,
    stopId: '606',
  },
  {
    entranceId: 'F02-entrance-0',
    lat: 40.710563,
    lon: -73.792853,
    stopId: 'F02',
  },
  {
    entranceId: 'F02-entrance-1',
    lat: 40.710968,
    lon: -73.792761,
    stopId: 'F02',
  },
  {
    entranceId: 'F02-entrance-2',
    lat: 40.710495,
    lon: -73.793022,
    stopId: 'F02',
  },
  {
    entranceId: 'F02-entrance-3',
    lat: 40.71083,
    lon: -73.793248,
    stopId: 'F02',
  },
  {
    entranceId: 'F02-entrance-4',
    lat: 40.709812,
    lon: -73.794712,
    stopId: 'F02',
  },
  {
    entranceId: 'F02-entrance-5',
    lat: 40.710154,
    lon: -73.794922,
    stopId: 'F02',
  },
  {
    entranceId: 'F02-entrance-6',
    lat: 40.709852,
    lon: -73.794564,
    stopId: 'F02',
  },
  {
    entranceId: 'F02-entrance-7',
    lat: 40.710184,
    lon: -73.79473,
    stopId: 'F02',
  },
  {
    entranceId: 'A60-entrance-0',
    lat: 40.679828,
    lon: -73.850401,
    stopId: 'A60',
  },
  {
    entranceId: 'A60-entrance-1',
    lat: 40.679973,
    lon: -73.850413,
    stopId: 'A60',
  },
  {
    entranceId: 'N09-entrance-1',
    lat: 40.598512,
    lon: -73.979335,
    stopId: 'N09',
  },
  {
    entranceId: 'N09-entrance-0',
    lat: 40.596459,
    lon: -73.978943,
    stopId: 'N09',
  },
  {
    entranceId: 'A53-entrance-0',
    lat: 40.672479,
    lon: -73.890164,
    stopId: 'A53',
  },
  {
    entranceId: 'A53-entrance-1',
    lat: 40.67285,
    lon: -73.890257,
    stopId: 'A53',
  },
  {
    entranceId: 'A53-entrance-2',
    lat: 40.672545,
    lon: -73.890381,
    stopId: 'A53',
  },
  {
    entranceId: 'A53-entrance-3',
    lat: 40.672771,
    lon: -73.890584,
    stopId: 'A53',
  },
  {
    entranceId: 'D04-entrance-2',
    lat: 40.866181,
    lon: -73.894306,
    stopId: 'D04',
  },
  {
    entranceId: 'D04-entrance-3',
    lat: 40.866369,
    lon: -73.894521,
    stopId: 'D04',
  },
  {
    entranceId: 'D04-entrance-4',
    lat: 40.866127,
    lon: -73.893982,
    stopId: 'D04',
  },
  {
    entranceId: 'D04-entrance-0',
    lat: 40.868114,
    lon: -73.893195,
    stopId: 'D04',
  },
  {
    entranceId: 'D04-entrance-1',
    lat: 40.867688,
    lon: -73.892739,
    stopId: 'D04',
  },
  {
    entranceId: 'J17-entrance-0',
    lat: 40.691285,
    lon: -73.866425,
    stopId: 'J17',
  },
  {
    entranceId: 'J17-entrance-1',
    lat: 40.691479,
    lon: -73.86661,
    stopId: 'J17',
  },
  {
    entranceId: 'M04-entrance-0',
    lat: 40.705955,
    lon: -73.896232,
    stopId: 'M04',
  },
  {
    entranceId: 'M04-entrance-1',
    lat: 40.706115,
    lon: -73.895559,
    stopId: 'M04',
  },
  {
    entranceId: 'S01-entrance-0',
    lat: 40.681051,
    lon: -73.95584,
    stopId: 'S01',
  },
  {
    entranceId: '256-entrance-0',
    lat: 40.665431,
    lon: -73.888386,
    stopId: '256',
  },
  {
    entranceId: '256-entrance-1',
    lat: 40.66574,
    lon: -73.888613,
    stopId: '256',
  },
  {
    entranceId: 'R05-entrance-1',
    lat: 40.761773,
    lon: -73.925399,
    stopId: 'R05',
  },
  {
    entranceId: 'R05-entrance-2',
    lat: 40.762217,
    lon: -73.925349,
    stopId: 'R05',
  },
  {
    entranceId: 'R05-entrance-0',
    lat: 40.76188,
    lon: -73.925638,
    stopId: 'R05',
  },
  {
    entranceId: 'R18-entrance-1',
    lat: 40.745299,
    lon: -73.988579,
    stopId: 'R18',
  },
  {
    entranceId: 'R18-entrance-2',
    lat: 40.745281,
    lon: -73.988923,
    stopId: 'R18',
  },
  {
    entranceId: 'R18-entrance-0',
    lat: 40.745089,
    lon: -73.988472,
    stopId: 'R18',
  },
  {
    entranceId: 'R18-entrance-3',
    lat: 40.745488,
    lon: -73.98901,
    stopId: 'R18',
  },
  {
    entranceId: 'L05-entrance-3',
    lat: 40.733277,
    lon: -73.986931,
    stopId: 'L05',
  },
  {
    entranceId: 'L05-entrance-0',
    lat: 40.732961,
    lon: -73.986657,
    stopId: 'L05',
  },
  {
    entranceId: 'L05-entrance-1',
    lat: 40.733134,
    lon: -73.987041,
    stopId: 'L05',
  },
  {
    entranceId: 'L05-entrance-2',
    lat: 40.733122,
    lon: -73.986548,
    stopId: 'L05',
  },
  {
    entranceId: 'H06-entrance-2',
    lat: 40.591123,
    lon: -73.79646,
    stopId: 'H06',
  },
  {
    entranceId: 'H06-entrance-3',
    lat: 40.590951,
    lon: -73.796176,
    stopId: 'H06',
  },
  {
    entranceId: 'H06-entrance-0',
    lat: 40.590744,
    lon: -73.797789,
    stopId: 'H06',
  },
  {
    entranceId: 'H06-entrance-1',
    lat: 40.590901,
    lon: -73.796609,
    stopId: 'H06',
  },
  {
    entranceId: 'S04-entrance-0',
    lat: 40.670588,
    lon: -73.959093,
    stopId: 'S04',
  },
  {
    entranceId: '135-entrance-1',
    lat: 40.722497,
    lon: -74.005994,
    stopId: '135',
  },
  {
    entranceId: '135-entrance-2',
    lat: 40.722932,
    lon: -74.00662,
    stopId: '135',
  },
  {
    entranceId: '135-entrance-3',
    lat: 40.722878,
    lon: -74.006518,
    stopId: '135',
  },
  {
    entranceId: '135-entrance-0',
    lat: 40.722569,
    lon: -74.00608,
    stopId: '135',
  },
  {
    entranceId: '419-entrance-4',
    lat: 40.707315,
    lon: -74.012161,
    stopId: '419',
  },
  {
    entranceId: '419-entrance-5',
    lat: 40.707428,
    lon: -74.012067,
    stopId: '419',
  },
  {
    entranceId: '419-entrance-2',
    lat: 40.707577,
    lon: -74.011731,
    stopId: '419',
  },
  {
    entranceId: '419-entrance-3',
    lat: 40.708436,
    lon: -74.011298,
    stopId: '419',
  },
  {
    entranceId: '419-entrance-0',
    lat: 40.70719,
    lon: -74.011997,
    stopId: '419',
  },
  {
    entranceId: '419-entrance-1',
    lat: 40.707446,
    lon: -74.011848,
    stopId: '419',
  },
  {
    entranceId: '419-entrance-6',
    lat: 40.707577,
    lon: -74.011927,
    stopId: '419',
  },
  {
    entranceId: '419-entrance-7',
    lat: 40.707671,
    lon: -74.011854,
    stopId: '419',
  },
  {
    entranceId: '639-entrance-0',
    lat: 40.718155,
    lon: -74.000317,
    stopId: '639',
  },
  {
    entranceId: '639-entrance-1',
    lat: 40.718197,
    lon: -74.000388,
    stopId: '639',
  },
  {
    entranceId: '639-entrance-2',
    lat: 40.718358,
    lon: -74.000216,
    stopId: '639',
  },
  {
    entranceId: '639-entrance-3',
    lat: 40.718506,
    lon: -74.000826,
    stopId: '639',
  },
  {
    entranceId: '639-entrance-4',
    lat: 40.718638,
    lon: -74.000623,
    stopId: '639',
  },
  {
    entranceId: 'D15-entrance-6',
    lat: 40.758623,
    lon: -73.981566,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-7',
    lat: 40.758977,
    lon: -73.981352,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-8',
    lat: 40.759105,
    lon: -73.981252,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-9',
    lat: 40.757801,
    lon: -73.981788,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-2',
    lat: 40.759953,
    lon: -73.980117,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-3',
    lat: 40.760125,
    lon: -73.980499,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-4',
    lat: 40.759301,
    lon: -73.980594,
    stopId: 'D15',
  },
  {
    entranceId: 'D15-entrance-5',
    lat: 40.758996,
    lon: -73.980788,
    stopId: 'D15',
  },
  {
    entranceId: '250-entrance-0',
    lat: 40.6687,
    lon: -73.931702,
    stopId: '250',
  },
  {
    entranceId: '250-entrance-1',
    lat: 40.66896,
    lon: -73.931675,
    stopId: '250',
  },
  {
    entranceId: 'D15-entrance-0',
    lat: 40.759896,
    lon: -73.980706,
    stopId: 'D15',
  },
  {
    entranceId: '250-entrance-2',
    lat: 40.668708,
    lon: -73.931883,
    stopId: '250',
  },
  {
    entranceId: 'D15-entrance-1',
    lat: 40.760502,
    lon: -73.980218,
    stopId: 'D15',
  },
  {
    entranceId: '250-entrance-3',
    lat: 40.668969,
    lon: -73.931874,
    stopId: '250',
  },
  {
    entranceId: '250-entrance-4',
    lat: 40.668838,
    lon: -73.934239,
    stopId: '250',
  },
  {
    entranceId: '250-entrance-5',
    lat: 40.669102,
    lon: -73.934213,
    stopId: '250',
  },
  {
    entranceId: 'H14-entrance-0',
    lat: 40.583187,
    lon: -73.827388,
    stopId: 'H14',
  },
  {
    entranceId: 'H14-entrance-3',
    lat: 40.583435,
    lon: -73.827232,
    stopId: 'H14',
  },
  {
    entranceId: 'H14-entrance-1',
    lat: 40.583369,
    lon: -73.827474,
    stopId: 'H14',
  },
  {
    entranceId: 'H14-entrance-2',
    lat: 40.583248,
    lon: -73.827163,
    stopId: 'H14',
  },
  {
    entranceId: 'F35-entrance-2',
    lat: 40.602197,
    lon: -73.97264,
    stopId: 'F35',
  },
  {
    entranceId: 'F35-entrance-3',
    lat: 40.602178,
    lon: -73.972379,
    stopId: 'F35',
  },
  {
    entranceId: 'F35-entrance-0',
    lat: 40.604007,
    lon: -73.972393,
    stopId: 'F35',
  },
  {
    entranceId: 'F35-entrance-1',
    lat: 40.603999,
    lon: -73.972124,
    stopId: 'F35',
  },
  {
    entranceId: '713-entrance-1',
    lat: 40.744067,
    lon: -73.91308,
    stopId: '713',
  },
  {
    entranceId: '713-entrance-2',
    lat: 40.744453,
    lon: -73.911282,
    stopId: '713',
  },
  {
    entranceId: '713-entrance-0',
    lat: 40.743933,
    lon: -73.913003,
    stopId: '713',
  },
  {
    entranceId: '713-entrance-3',
    lat: 40.74459,
    lon: -73.911294,
    stopId: '713',
  },
  {
    entranceId: 'R27-entrance-0',
    lat: 40.701938,
    lon: -74.012588,
    stopId: 'R27',
  },
  {
    entranceId: 'R27-entrance-5',
    lat: 40.704096,
    lon: -74.013293,
    stopId: 'R27',
  },
  {
    entranceId: 'R27-entrance-1',
    lat: 40.702108,
    lon: -74.012642,
    stopId: 'R27',
  },
  {
    entranceId: 'R27-entrance-2',
    lat: 40.702108,
    lon: -74.012642,
    stopId: 'R27',
  },
  {
    entranceId: 'R27-entrance-3',
    lat: 40.704015,
    lon: -74.013284,
    stopId: 'R27',
  },
  {
    entranceId: 'R27-entrance-4',
    lat: 40.704114,
    lon: -74.013,
    stopId: 'R27',
  },
  {
    entranceId: 'A31-entrance-0',
    lat: 40.740938,
    lon: -74.001919,
    stopId: 'A31',
  },
  {
    entranceId: 'A31-entrance-3',
    lat: 40.741046,
    lon: -74.001284,
    stopId: 'A31',
  },
  {
    entranceId: 'A31-entrance-4',
    lat: 40.739949,
    lon: -74.002655,
    stopId: 'A31',
  },
  {
    entranceId: 'A31-entrance-1',
    lat: 40.741235,
    lon: -74.001715,
    stopId: 'A31',
  },
  {
    entranceId: 'A31-entrance-2',
    lat: 40.740944,
    lon: -74.001402,
    stopId: 'A31',
  },
  {
    entranceId: 'A31-entrance-7',
    lat: 40.739776,
    lon: -74.002256,
    stopId: 'A31',
  },
  {
    entranceId: 'A31-entrance-5',
    lat: 40.74041,
    lon: -74.00175,
    stopId: 'A31',
  },
  {
    entranceId: 'A31-entrance-6',
    lat: 40.740598,
    lon: -74.002185,
    stopId: 'A31',
  },
  {
    entranceId: 'N10-entrance-0',
    lat: 40.592724,
    lon: -73.978227,
    stopId: 'N10',
  },
  {
    entranceId: 'M23-entrance-8',
    lat: 40.706887,
    lon: -74.010687,
    stopId: 'M23',
  },
  {
    entranceId: 'M23-entrance-0',
    lat: 40.706142,
    lon: -74.011128,
    stopId: 'M23',
  },
  {
    entranceId: 'M23-entrance-1',
    lat: 40.705961,
    lon: -74.011229,
    stopId: 'M23',
  },
  {
    entranceId: 'M23-entrance-2',
    lat: 40.706001,
    lon: -74.011497,
    stopId: 'M23',
  },
  {
    entranceId: 'M23-entrance-3',
    lat: 40.706439,
    lon: -74.011263,
    stopId: 'M23',
  },
  {
    entranceId: 'M23-entrance-4',
    lat: 40.705747,
    lon: -74.011554,
    stopId: 'M23',
  },
  {
    entranceId: 'M23-entrance-5',
    lat: 40.708027,
    lon: -74.009814,
    stopId: 'M23',
  },
  {
    entranceId: 'M23-entrance-6',
    lat: 40.706911,
    lon: -74.010494,
    stopId: 'M23',
  },
  {
    entranceId: 'M23-entrance-7',
    lat: 40.707117,
    lon: -74.010821,
    stopId: 'M23',
  },
  {
    entranceId: '621-entrance-0',
    lat: 40.804251,
    lon: -73.937658,
    stopId: '621',
  },
  {
    entranceId: '621-entrance-1',
    lat: 40.804164,
    lon: -73.937444,
    stopId: '621',
  },
  {
    entranceId: '621-entrance-2',
    lat: 40.804597,
    lon: -73.9374,
    stopId: '621',
  },
  {
    entranceId: '621-entrance-3',
    lat: 40.804517,
    lon: -73.937186,
    stopId: '621',
  },
  {
    entranceId: 'R45-entrance-3',
    lat: 40.617587,
    lon: -74.030696,
    stopId: 'R45',
  },
  {
    entranceId: 'R45-entrance-4',
    lat: 40.617363,
    lon: -74.030404,
    stopId: 'R45',
  },
  {
    entranceId: 'R45-entrance-1',
    lat: 40.615893,
    lon: -74.03086,
    stopId: 'R45',
  },
  {
    entranceId: 'R45-entrance-2',
    lat: 40.616189,
    lon: -74.031234,
    stopId: 'R45',
  },
  {
    entranceId: 'R45-entrance-0',
    lat: 40.616021,
    lon: -74.031383,
    stopId: 'R45',
  },
  {
    entranceId: 'F07-entrance-0',
    lat: 40.717895,
    lon: -73.836743,
    stopId: 'F07',
  },
  {
    entranceId: 'F07-entrance-1',
    lat: 40.719061,
    lon: -73.837916,
    stopId: 'F07',
  },
  {
    entranceId: 'F07-entrance-2',
    lat: 40.718574,
    lon: -73.838257,
    stopId: 'F07',
  },
  {
    entranceId: '401-entrance-1',
    lat: 40.886737,
    lon: -73.87812,
    stopId: '401',
  },
  {
    entranceId: '401-entrance-0',
    lat: 40.886819,
    lon: -73.878376,
    stopId: '401',
  },
  {
    entranceId: 'D28-entrance-0',
    lat: 40.649427,
    lon: -73.963356,
    stopId: 'D28',
  },
  {
    entranceId: 'D28-entrance-1',
    lat: 40.651482,
    lon: -73.962732,
    stopId: 'D28',
  },
  {
    entranceId: 'D43-entrance-0',
    lat: 40.5769,
    lon: -73.980846,
    stopId: 'D43',
  },
  {
    entranceId: 'D43-entrance-1',
    lat: 40.576241,
    lon: -73.981077,
    stopId: 'D43',
  },
  {
    entranceId: 'B23-entrance-0',
    lat: 40.588022,
    lon: -73.983735,
    stopId: 'B23',
  },
  {
    entranceId: 'B23-entrance-1',
    lat: 40.588066,
    lon: -73.983498,
    stopId: 'B23',
  },
  {
    entranceId: 'B23-entrance-2',
    lat: 40.588305,
    lon: -73.983791,
    stopId: 'B23',
  },
  {
    entranceId: 'B23-entrance-3',
    lat: 40.588348,
    lon: -73.983543,
    stopId: 'B23',
  },
  {
    entranceId: 'L03-entrance-0',
    lat: 40.734755,
    lon: -73.990991,
    stopId: 'L03',
  },
  {
    entranceId: 'L03-entrance-1',
    lat: 40.734859,
    lon: -73.991235,
    stopId: 'L03',
  },
  {
    entranceId: 'F22-entrance-0',
    lat: 40.674725,
    lon: -73.997505,
    stopId: 'F22',
  },
  {
    entranceId: 'H01-entrance-0',
    lat: 40.672208,
    lon: -73.834589,
    stopId: 'H01',
  },
  {
    entranceId: '130-entrance-0',
    lat: 40.74391,
    lon: -73.995608,
    stopId: '130',
  },
  {
    entranceId: '130-entrance-1',
    lat: 40.74412,
    lon: -73.995348,
    stopId: '130',
  },
  {
    entranceId: '130-entrance-2',
    lat: 40.744017,
    lon: -73.995901,
    stopId: '130',
  },
  {
    entranceId: '130-entrance-3',
    lat: 40.744313,
    lon: -73.995811,
    stopId: '130',
  },
  {
    entranceId: '626-entrance-0',
    lat: 40.779468,
    lon: -73.955905,
    stopId: '626',
  },
  {
    entranceId: '626-entrance-1',
    lat: 40.779718,
    lon: -73.955719,
    stopId: '626',
  },
  {
    entranceId: '626-entrance-2',
    lat: 40.779427,
    lon: -73.95581,
    stopId: '626',
  },
  {
    entranceId: '626-entrance-3',
    lat: 40.77972,
    lon: -73.955596,
    stopId: '626',
  },
  {
    entranceId: '626-entrance-4',
    lat: 40.779661,
    lon: -73.955302,
    stopId: '626',
  },
  {
    entranceId: '626-entrance-5',
    lat: 40.779577,
    lon: -73.955304,
    stopId: '626',
  },
  {
    entranceId: '626-entrance-6',
    lat: 40.779284,
    lon: -73.955443,
    stopId: '626',
  },
  {
    entranceId: '626-entrance-7',
    lat: 40.77933,
    lon: -73.955545,
    stopId: '626',
  },
  {
    entranceId: '418-entrance-18',
    lat: 40.71012,
    lon: -74.00916,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-19',
    lat: 40.71012,
    lon: -74.00916,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-16',
    lat: 40.710497,
    lon: -74.009209,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-17',
    lat: 40.710369,
    lon: -74.008927,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-14',
    lat: 40.710497,
    lon: -74.009209,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-15',
    lat: 40.710497,
    lon: -74.009209,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-12',
    lat: 40.710369,
    lon: -74.008927,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-13',
    lat: 40.710497,
    lon: -74.009209,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-10',
    lat: 40.710582,
    lon: -74.008566,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-11',
    lat: 40.710582,
    lon: -74.008566,
    stopId: '418',
  },
  {
    entranceId: '406-entrance-2',
    lat: 40.867766,
    lon: -73.897039,
    stopId: '406',
  },
  {
    entranceId: '406-entrance-0',
    lat: 40.867606,
    lon: -73.897418,
    stopId: '406',
  },
  {
    entranceId: '406-entrance-1',
    lat: 40.86762,
    lon: -73.897152,
    stopId: '406',
  },
  {
    entranceId: '227-entrance-1',
    lat: 40.799033,
    lon: -73.952058,
    stopId: '227',
  },
  {
    entranceId: '227-entrance-0',
    lat: 40.798497,
    lon: -73.952672,
    stopId: '227',
  },
  {
    entranceId: 'B08-entrance-10',
    lat: 40.764854,
    lon: -73.966425,
    stopId: 'B08',
  },
  {
    entranceId: 'B08-entrance-11',
    lat: 40.764854,
    lon: -73.966425,
    stopId: 'B08',
  },
  {
    entranceId: '255-entrance-0',
    lat: 40.664869,
    lon: -73.893779,
    stopId: '255',
  },
  {
    entranceId: '255-entrance-1',
    lat: 40.664575,
    lon: -73.894117,
    stopId: '255',
  },
  {
    entranceId: 'R32-entrance-1',
    lat: 40.677279,
    lon: -73.983327,
    stopId: 'R32',
  },
  {
    entranceId: 'R32-entrance-2',
    lat: 40.677171,
    lon: -73.98302,
    stopId: 'R32',
  },
  {
    entranceId: 'R32-entrance-0',
    lat: 40.677001,
    lon: -73.983133,
    stopId: 'R32',
  },
  {
    entranceId: 'R32-entrance-3',
    lat: 40.677135,
    lon: -73.983416,
    stopId: 'R32',
  },
  {
    entranceId: 'J24-entrance-0',
    lat: 40.676755,
    lon: -73.899834,
    stopId: 'J24',
  },
  {
    entranceId: 'J24-entrance-1',
    lat: 40.676803,
    lon: -73.89951,
    stopId: 'J24',
  },
  {
    entranceId: '501-entrance-0',
    lat: 40.88798,
    lon: -73.831195,
    stopId: '501',
  },
  {
    entranceId: 'N02-entrance-0',
    lat: 40.634552,
    lon: -74.010726,
    stopId: 'N02',
  },
  {
    entranceId: 'B10-entrance-0',
    lat: 40.763874,
    lon: -73.977358,
    stopId: 'B10',
  },
  {
    entranceId: 'B10-entrance-1',
    lat: 40.764227,
    lon: -73.97743,
    stopId: 'B10',
  },
  {
    entranceId: 'B10-entrance-2',
    lat: 40.763998,
    lon: -73.977264,
    stopId: 'B10',
  },
  {
    entranceId: 'B10-entrance-3',
    lat: 40.764652,
    lon: -73.977119,
    stopId: 'B10',
  },
  {
    entranceId: 'B10-entrance-4',
    lat: 40.764564,
    lon: -73.97683,
    stopId: 'B10',
  },
  {
    entranceId: 'B10-entrance-5',
    lat: 40.763482,
    lon: -73.977974,
    stopId: 'B10',
  },
  {
    entranceId: 'B10-entrance-6',
    lat: 40.763392,
    lon: -73.977674,
    stopId: 'B10',
  },
  {
    entranceId: 'B10-entrance-7',
    lat: 40.763831,
    lon: -73.977718,
    stopId: 'B10',
  },
  {
    entranceId: '721-entrance-6',
    lat: 40.742351,
    lon: -73.952723,
    stopId: '721',
  },
  {
    entranceId: '721-entrance-7',
    lat: 40.742432,
    lon: -73.953156,
    stopId: '721',
  },
  {
    entranceId: '721-entrance-4',
    lat: 40.742496,
    lon: -73.952482,
    stopId: '721',
  },
  {
    entranceId: '721-entrance-5',
    lat: 40.742593,
    lon: -73.95285,
    stopId: '721',
  },
  {
    entranceId: '721-entrance-2',
    lat: 40.742588,
    lon: -73.954252,
    stopId: '721',
  },
  {
    entranceId: '721-entrance-3',
    lat: 40.742654,
    lon: -73.954232,
    stopId: '721',
  },
  {
    entranceId: '721-entrance-0',
    lat: 40.742759,
    lon: -73.953893,
    stopId: '721',
  },
  {
    entranceId: '721-entrance-1',
    lat: 40.742803,
    lon: -73.953955,
    stopId: '721',
  },
  {
    entranceId: 'R01-entrance-1',
    lat: 40.77481,
    lon: -73.912151,
    stopId: 'R01',
  },
  {
    entranceId: 'R01-entrance-2',
    lat: 40.775025,
    lon: -73.911891,
    stopId: 'R01',
  },
  {
    entranceId: 'R01-entrance-3',
    lat: 40.774938,
    lon: -73.912337,
    stopId: 'R01',
  },
  {
    entranceId: 'R01-entrance-0',
    lat: 40.775149,
    lon: -73.912074,
    stopId: 'R01',
  },
  {
    entranceId: '613-entrance-2',
    lat: 40.820614,
    lon: -73.891519,
    stopId: '613',
  },
  {
    entranceId: '613-entrance-0',
    lat: 40.820591,
    lon: -73.890836,
    stopId: '613',
  },
  {
    entranceId: '613-entrance-1',
    lat: 40.820819,
    lon: -73.890442,
    stopId: '613',
  },
  {
    entranceId: '214-entrance-1',
    lat: 40.840434,
    lon: -73.880005,
    stopId: '214',
  },
  {
    entranceId: '214-entrance-2',
    lat: 40.840354,
    lon: -73.879833,
    stopId: '214',
  },
  {
    entranceId: '214-entrance-0',
    lat: 40.840815,
    lon: -73.879623,
    stopId: '214',
  },
  {
    entranceId: '214-entrance-3',
    lat: 40.840639,
    lon: -73.879555,
    stopId: '214',
  },
  {
    entranceId: '214-entrance-4',
    lat: 40.841078,
    lon: -73.879397,
    stopId: '214',
  },
  {
    entranceId: '104-entrance-1',
    lat: 40.879168,
    lon: -73.904751,
    stopId: '104',
  },
  {
    entranceId: '104-entrance-2',
    lat: 40.878694,
    lon: -73.904821,
    stopId: '104',
  },
  {
    entranceId: '104-entrance-0',
    lat: 40.878775,
    lon: -73.905024,
    stopId: '104',
  },
  {
    entranceId: '104-entrance-3',
    lat: 40.879091,
    lon: -73.904538,
    stopId: '104',
  },
  {
    entranceId: 'J19-entrance-2',
    lat: 40.689596,
    lon: -73.873555,
    stopId: 'J19',
  },
  {
    entranceId: 'J19-entrance-0',
    lat: 40.690142,
    lon: -73.87163,
    stopId: 'J19',
  },
  {
    entranceId: 'J19-entrance-1',
    lat: 40.68947,
    lon: -73.873449,
    stopId: 'J19',
  },
  {
    entranceId: 'R19-entrance-0',
    lat: 40.74108,
    lon: -73.989083,
    stopId: 'R19',
  },
  {
    entranceId: 'R19-entrance-1',
    lat: 40.741111,
    lon: -73.98892,
    stopId: 'R19',
  },
  {
    entranceId: 'R19-entrance-2',
    lat: 40.741378,
    lon: -73.988895,
    stopId: 'R19',
  },
  {
    entranceId: 'R19-entrance-3',
    lat: 40.741539,
    lon: -73.988989,
    stopId: 'R19',
  },
  {
    entranceId: 'R19-entrance-4',
    lat: 40.740643,
    lon: -73.989217,
    stopId: 'R19',
  },
  {
    entranceId: 'R19-entrance-5',
    lat: 40.741208,
    lon: -73.989639,
    stopId: 'R19',
  },
  {
    entranceId: 'R19-entrance-6',
    lat: 40.741913,
    lon: -73.989428,
    stopId: 'R19',
  },
  {
    entranceId: '414-entrance-5',
    lat: 40.827461,
    lon: -73.926362,
    stopId: '414',
  },
  {
    entranceId: '414-entrance-6',
    lat: 40.827399,
    lon: -73.926394,
    stopId: '414',
  },
  {
    entranceId: '414-entrance-3',
    lat: 40.827605,
    lon: -73.926282,
    stopId: '414',
  },
  {
    entranceId: '414-entrance-4',
    lat: 40.827529,
    lon: -73.926322,
    stopId: '414',
  },
  {
    entranceId: '414-entrance-1',
    lat: 40.827942,
    lon: -73.926,
    stopId: '414',
  },
  {
    entranceId: '414-entrance-2',
    lat: 40.827854,
    lon: -73.925745,
    stopId: '414',
  },
  {
    entranceId: '414-entrance-0',
    lat: 40.827759,
    lon: -73.9261,
    stopId: '414',
  },
  {
    entranceId: 'A64-entrance-0',
    lat: 40.684732,
    lon: -73.830898,
    stopId: 'A64',
  },
  {
    entranceId: 'A64-entrance-1',
    lat: 40.684874,
    lon: -73.830943,
    stopId: 'A64',
  },
  {
    entranceId: 'A64-entrance-2',
    lat: 40.683972,
    lon: -73.832816,
    stopId: 'A64',
  },
  {
    entranceId: 'A64-entrance-3',
    lat: 40.684111,
    lon: -73.832889,
    stopId: 'A64',
  },
  {
    entranceId: 'J31-entrance-0',
    lat: 40.69293,
    lon: -73.928257,
    stopId: 'J31',
  },
  {
    entranceId: 'J31-entrance-1',
    lat: 40.692929,
    lon: -73.92794,
    stopId: 'J31',
  },
  {
    entranceId: '122-entrance-3',
    lat: 40.783985,
    lon: -73.979662,
    stopId: '122',
  },
  {
    entranceId: '122-entrance-0',
    lat: 40.784066,
    lon: -73.980051,
    stopId: '122',
  },
  {
    entranceId: '122-entrance-1',
    lat: 40.783765,
    lon: -73.980274,
    stopId: '122',
  },
  {
    entranceId: '122-entrance-2',
    lat: 40.78362,
    lon: -73.97991,
    stopId: '122',
  },
  {
    entranceId: 'A06-entrance-0',
    lat: 40.852759,
    lon: -73.936636,
    stopId: 'A06',
  },
  {
    entranceId: 'A06-entrance-3',
    lat: 40.850717,
    lon: -73.938214,
    stopId: 'A06',
  },
  {
    entranceId: 'A06-entrance-4',
    lat: 40.85087,
    lon: -73.938537,
    stopId: 'A06',
  },
  {
    entranceId: 'A06-entrance-1',
    lat: 40.850752,
    lon: -73.93813,
    stopId: 'A06',
  },
  {
    entranceId: 'A06-entrance-2',
    lat: 40.850778,
    lon: -73.938472,
    stopId: 'A06',
  },
  {
    entranceId: 'G19-entrance-1',
    lat: 40.758279,
    lon: -73.919433,
    stopId: 'G19',
  },
  {
    entranceId: 'G19-entrance-2',
    lat: 40.756583,
    lon: -73.921108,
    stopId: 'G19',
  },
  {
    entranceId: 'G19-entrance-3',
    lat: 40.756673,
    lon: -73.920622,
    stopId: 'G19',
  },
  {
    entranceId: 'D10-entrance-1',
    lat: 40.834286,
    lon: -73.917562,
    stopId: 'D10',
  },
  {
    entranceId: 'D10-entrance-2',
    lat: 40.834788,
    lon: -73.917838,
    stopId: 'D10',
  },
  {
    entranceId: 'D10-entrance-3',
    lat: 40.834609,
    lon: -73.917245,
    stopId: 'D10',
  },
  {
    entranceId: 'D10-entrance-4',
    lat: 40.833111,
    lon: -73.919399,
    stopId: 'D10',
  },
  {
    entranceId: 'D10-entrance-0',
    lat: 40.834466,
    lon: -73.918155,
    stopId: 'D10',
  },
  {
    entranceId: 'D10-entrance-5',
    lat: 40.832912,
    lon: -73.918822,
    stopId: 'D10',
  },
  {
    entranceId: 'G19-entrance-0',
    lat: 40.758143,
    lon: -73.919842,
    stopId: 'G19',
  },
  {
    entranceId: '618-entrance-0',
    lat: 40.807747,
    lon: -73.919347,
    stopId: '618',
  },
  {
    entranceId: '618-entrance-1',
    lat: 40.80758,
    lon: -73.918957,
    stopId: '618',
  },
  {
    entranceId: '618-entrance-2',
    lat: 40.807467,
    lon: -73.919448,
    stopId: '618',
  },
  {
    entranceId: '618-entrance-3',
    lat: 40.807355,
    lon: -73.919219,
    stopId: '618',
  },
  {
    entranceId: '117-entrance-3',
    lat: 40.808245,
    lon: -73.963495,
    stopId: '117',
  },
  {
    entranceId: '117-entrance-4',
    lat: 40.807535,
    lon: -73.964013,
    stopId: '117',
  },
  {
    entranceId: '117-entrance-1',
    lat: 40.808099,
    lon: -73.963603,
    stopId: '117',
  },
  {
    entranceId: '117-entrance-2',
    lat: 40.8084,
    lon: -73.963845,
    stopId: '117',
  },
  {
    entranceId: '117-entrance-0',
    lat: 40.808251,
    lon: -73.963955,
    stopId: '117',
  },
  {
    entranceId: 'R14-entrance-7',
    lat: 40.764179,
    lon: -73.980683,
    stopId: 'R14',
  },
  {
    entranceId: 'B18-entrance-1',
    lat: 40.612847,
    lon: -74.000721,
    stopId: 'B18',
  },
  {
    entranceId: 'B18-entrance-2',
    lat: 40.614018,
    lon: -74.000378,
    stopId: 'B18',
  },
  {
    entranceId: 'B18-entrance-0',
    lat: 40.612855,
    lon: -74.000892,
    stopId: 'B18',
  },
  {
    entranceId: 'B18-entrance-3',
    lat: 40.614054,
    lon: -74.000585,
    stopId: 'B18',
  },
  {
    entranceId: 'R14-entrance-3',
    lat: 40.765639,
    lon: -73.979764,
    stopId: 'R14',
  },
  {
    entranceId: 'R14-entrance-4',
    lat: 40.764258,
    lon: -73.981278,
    stopId: 'R14',
  },
  {
    entranceId: 'R14-entrance-5',
    lat: 40.764057,
    lon: -73.980803,
    stopId: 'R14',
  },
  {
    entranceId: 'R14-entrance-6',
    lat: 40.764377,
    lon: -73.981167,
    stopId: 'R14',
  },
  {
    entranceId: 'R14-entrance-0',
    lat: 40.765365,
    lon: -73.980341,
    stopId: 'R14',
  },
  {
    entranceId: 'R14-entrance-1',
    lat: 40.765298,
    lon: -73.980033,
    stopId: 'R14',
  },
  {
    entranceId: 'R14-entrance-2',
    lat: 40.765796,
    lon: -73.980012,
    stopId: 'R14',
  },
  {
    entranceId: 'A57-entrance-0',
    lat: 40.676735,
    lon: -73.865491,
    stopId: 'A57',
  },
  {
    entranceId: '209-entrance-0',
    lat: 40.871631,
    lon: -73.867283,
    stopId: '209',
  },
  {
    entranceId: '209-entrance-1',
    lat: 40.871215,
    lon: -73.867043,
    stopId: '209',
  },
  {
    entranceId: 'A44-entrance-5',
    lat: 40.682853,
    lon: -73.964624,
    stopId: 'A44',
  },
  {
    entranceId: 'A44-entrance-3',
    lat: 40.683287,
    lon: -73.966722,
    stopId: 'A44',
  },
  {
    entranceId: 'A44-entrance-4',
    lat: 40.683167,
    lon: -73.964696,
    stopId: 'A44',
  },
  {
    entranceId: 'A44-entrance-1',
    lat: 40.683589,
    lon: -73.966787,
    stopId: 'A44',
  },
  {
    entranceId: 'A44-entrance-2',
    lat: 40.683336,
    lon: -73.966995,
    stopId: 'A44',
  },
  {
    entranceId: 'A44-entrance-0',
    lat: 40.683659,
    lon: -73.967057,
    stopId: 'A44',
  },
  {
    entranceId: 'M10-entrance-0',
    lat: 40.698033,
    lon: -73.926371,
    stopId: 'M10',
  },
  {
    entranceId: 'M10-entrance-1',
    lat: 40.697876,
    lon: -73.926525,
    stopId: 'M10',
  },
  {
    entranceId: 'F27-entrance-5',
    lat: 40.644908,
    lon: -73.980019,
    stopId: 'F27',
  },
  {
    entranceId: 'F27-entrance-3',
    lat: 40.643006,
    lon: -73.97966,
    stopId: 'F27',
  },
  {
    entranceId: 'F27-entrance-4',
    lat: 40.645042,
    lon: -73.979573,
    stopId: 'F27',
  },
  {
    entranceId: 'F27-entrance-1',
    lat: 40.642692,
    lon: -73.97926,
    stopId: 'F27',
  },
  {
    entranceId: 'F27-entrance-2',
    lat: 40.643147,
    lon: -73.979342,
    stopId: 'F27',
  },
  {
    entranceId: 'F27-entrance-0',
    lat: 40.642571,
    lon: -73.979579,
    stopId: 'F27',
  },
  {
    entranceId: 'L11-entrance-2',
    lat: 40.714439,
    lon: -73.94419,
    stopId: 'L11',
  },
  {
    entranceId: 'L11-entrance-3',
    lat: 40.714433,
    lon: -73.944546,
    stopId: 'L11',
  },
  {
    entranceId: 'L11-entrance-0',
    lat: 40.714661,
    lon: -73.944287,
    stopId: 'L11',
  },
  {
    entranceId: 'L11-entrance-1',
    lat: 40.714613,
    lon: -73.94457,
    stopId: 'L11',
  },
  {
    entranceId: 'J13-entrance-0',
    lat: 40.696771,
    lon: -73.837042,
    stopId: 'J13',
  },
  {
    entranceId: 'J13-entrance-1',
    lat: 40.697055,
    lon: -73.837156,
    stopId: 'J13',
  },
  {
    entranceId: 'F14-entrance-1',
    lat: 40.7232,
    lon: -73.988863,
    stopId: 'F14',
  },
  {
    entranceId: 'F14-entrance-2',
    lat: 40.72395,
    lon: -73.991298,
    stopId: 'F14',
  },
  {
    entranceId: 'F14-entrance-0',
    lat: 40.722807,
    lon: -73.988942,
    stopId: 'F14',
  },
  {
    entranceId: 'F14-entrance-3',
    lat: 40.723665,
    lon: -73.991651,
    stopId: 'F14',
  },
  {
    entranceId: '247-entrance-0',
    lat: 40.632359,
    lon: -73.947389,
    stopId: '247',
  },
  {
    entranceId: '247-entrance-1',
    lat: 40.632324,
    lon: -73.947482,
    stopId: '247',
  },
  {
    entranceId: '247-entrance-2',
    lat: 40.63275,
    lon: -73.947375,
    stopId: '247',
  },
  {
    entranceId: '247-entrance-3',
    lat: 40.632868,
    lon: -73.947547,
    stopId: '247',
  },
  {
    entranceId: '247-entrance-4',
    lat: 40.631845,
    lon: -73.947665,
    stopId: '247',
  },
  {
    entranceId: '247-entrance-5',
    lat: 40.63327,
    lon: -73.947929,
    stopId: '247',
  },
  {
    entranceId: '247-entrance-6',
    lat: 40.633297,
    lon: -73.947836,
    stopId: '247',
  },
  {
    entranceId: '142-entrance-3',
    lat: 40.701864,
    lon: -74.01317,
    stopId: '142',
  },
  {
    entranceId: '142-entrance-4',
    lat: 40.701864,
    lon: -74.01317,
    stopId: '142',
  },
  {
    entranceId: '142-entrance-1',
    lat: 40.701615,
    lon: -74.012975,
    stopId: '142',
  },
  {
    entranceId: '142-entrance-2',
    lat: 40.701615,
    lon: -74.012975,
    stopId: '142',
  },
  {
    entranceId: '142-entrance-7',
    lat: 40.702073,
    lon: -74.012635,
    stopId: '142',
  },
  {
    entranceId: '142-entrance-5',
    lat: 40.701309,
    lon: -74.013677,
    stopId: '142',
  },
  {
    entranceId: '142-entrance-6',
    lat: 40.701944,
    lon: -74.012592,
    stopId: '142',
  },
  {
    entranceId: '142-entrance-0',
    lat: 40.701615,
    lon: -74.012975,
    stopId: '142',
  },
  {
    entranceId: 'D22-entrance-0',
    lat: 40.718155,
    lon: -73.993452,
    stopId: 'D22',
  },
  {
    entranceId: 'D22-entrance-1',
    lat: 40.718191,
    lon: -73.993577,
    stopId: 'D22',
  },
  {
    entranceId: 'D22-entrance-2',
    lat: 40.718265,
    lon: -73.993877,
    stopId: 'D22',
  },
  {
    entranceId: '720-entrance-0',
    lat: 40.7425,
    lon: -73.948609,
    stopId: '720',
  },
  {
    entranceId: '720-entrance-1',
    lat: 40.742405,
    lon: -73.94811,
    stopId: '720',
  },
  {
    entranceId: '720-entrance-2',
    lat: 40.742422,
    lon: -73.948195,
    stopId: '720',
  },
  {
    entranceId: '110-entrance-0',
    lat: 40.855374,
    lon: -73.929536,
    stopId: '110',
  },
  {
    entranceId: '110-entrance-1',
    lat: 40.856413,
    lon: -73.932605,
    stopId: '110',
  },
  {
    entranceId: 'H07-entrance-0',
    lat: 40.592269,
    lon: -73.789578,
    stopId: 'H07',
  },
  {
    entranceId: 'H07-entrance-1',
    lat: 40.59249,
    lon: -73.789161,
    stopId: 'H07',
  },
  {
    entranceId: 'H07-entrance-2',
    lat: 40.592292,
    lon: -73.789153,
    stopId: 'H07',
  },
  {
    entranceId: 'H07-entrance-3',
    lat: 40.592478,
    lon: -73.78941,
    stopId: 'H07',
  },
  {
    entranceId: 'G15-entrance-1',
    lat: 40.74933,
    lon: -73.897382,
    stopId: 'G15',
  },
  {
    entranceId: 'G15-entrance-0',
    lat: 40.749145,
    lon: -73.897495,
    stopId: 'G15',
  },
  {
    entranceId: 'A19-entrance-1',
    lat: 40.792285,
    lon: -73.964423,
    stopId: 'A19',
  },
  {
    entranceId: 'A19-entrance-2',
    lat: 40.792591,
    lon: -73.964316,
    stopId: 'A19',
  },
  {
    entranceId: 'A19-entrance-0',
    lat: 40.791642,
    lon: -73.965009,
    stopId: 'A19',
  },
  {
    entranceId: '706-entrance-0',
    lat: 40.749787,
    lon: -73.862389,
    stopId: '706',
  },
  {
    entranceId: '706-entrance-1',
    lat: 40.749973,
    lon: -73.862425,
    stopId: '706',
  },
  {
    entranceId: '706-entrance-2',
    lat: 40.749775,
    lon: -73.862537,
    stopId: '706',
  },
  {
    entranceId: '706-entrance-3',
    lat: 40.749964,
    lon: -73.862546,
    stopId: '706',
  },
  {
    entranceId: '614-entrance-3',
    lat: 40.816367,
    lon: -73.895924,
    stopId: '614',
  },
  {
    entranceId: '614-entrance-1',
    lat: 40.81652,
    lon: -73.896224,
    stopId: '614',
  },
  {
    entranceId: '614-entrance-2',
    lat: 40.816137,
    lon: -73.896133,
    stopId: '614',
  },
  {
    entranceId: '614-entrance-0',
    lat: 40.816263,
    lon: -73.896506,
    stopId: '614',
  },
  {
    entranceId: 'D08-entrance-2',
    lat: 40.846461,
    lon: -73.909796,
    stopId: 'D08',
  },
  {
    entranceId: 'D08-entrance-3',
    lat: 40.846354,
    lon: -73.909047,
    stopId: 'D08',
  },
  {
    entranceId: 'D08-entrance-0',
    lat: 40.844933,
    lon: -73.911212,
    stopId: 'D08',
  },
  {
    entranceId: 'D08-entrance-1',
    lat: 40.844939,
    lon: -73.910885,
    stopId: 'D08',
  },
  {
    entranceId: '601-entrance-0',
    lat: 40.852377,
    lon: -73.825986,
    stopId: '601',
  },
  {
    entranceId: '601-entrance-1',
    lat: 40.853012,
    lon: -73.827172,
    stopId: '601',
  },
  {
    entranceId: '601-entrance-2',
    lat: 40.852824,
    lon: -73.827035,
    stopId: '601',
  },
  {
    entranceId: 'M16-entrance-4',
    lat: 40.708403,
    lon: -73.958124,
    stopId: 'M16',
  },
  {
    entranceId: 'M16-entrance-5',
    lat: 40.708681,
    lon: -73.958927,
    stopId: 'M16',
  },
  {
    entranceId: 'M16-entrance-2',
    lat: 40.708788,
    lon: -73.958826,
    stopId: 'M16',
  },
  {
    entranceId: 'M16-entrance-3',
    lat: 40.708271,
    lon: -73.957749,
    stopId: 'M16',
  },
  {
    entranceId: 'M16-entrance-0',
    lat: 40.708409,
    lon: -73.957689,
    stopId: 'M16',
  },
  {
    entranceId: 'M16-entrance-1',
    lat: 40.708529,
    lon: -73.958048,
    stopId: 'M16',
  },
  {
    entranceId: '719-entrance-0',
    lat: 40.746308,
    lon: -73.945713,
    stopId: '719',
  },
  {
    entranceId: '719-entrance-1',
    lat: 40.74613,
    lon: -73.945349,
    stopId: '719',
  },
  {
    entranceId: '719-entrance-2',
    lat: 40.746232,
    lon: -73.945322,
    stopId: '719',
  },
  {
    entranceId: 'A51-entrance-0',
    lat: 40.678334,
    lon: -73.903922,
    stopId: 'A51',
  },
  {
    entranceId: 'N07-entrance-1',
    lat: 40.610572,
    lon: -73.981613,
    stopId: 'N07',
  },
  {
    entranceId: 'N07-entrance-0',
    lat: 40.612989,
    lon: -73.982084,
    stopId: 'N07',
  },
  {
    entranceId: 'R06-entrance-2',
    lat: 40.756796,
    lon: -73.92974,
    stopId: 'R06',
  },
  {
    entranceId: 'R06-entrance-0',
    lat: 40.756478,
    lon: -73.929993,
    stopId: 'R06',
  },
  {
    entranceId: 'R06-entrance-1',
    lat: 40.756379,
    lon: -73.929766,
    stopId: 'R06',
  },
  {
    entranceId: 'G06-entrance-3',
    lat: 40.700872,
    lon: -73.807673,
    stopId: 'G06',
  },
  {
    entranceId: 'G06-entrance-0',
    lat: 40.700401,
    lon: -73.807976,
    stopId: 'G06',
  },
  {
    entranceId: 'G06-entrance-1',
    lat: 40.70081,
    lon: -73.807923,
    stopId: 'G06',
  },
  {
    entranceId: 'G06-entrance-2',
    lat: 40.700554,
    lon: -73.807353,
    stopId: 'G06',
  },
  {
    entranceId: 'L29-entrance-1',
    lat: 40.64556,
    lon: -73.902524,
    stopId: 'L29',
  },
  {
    entranceId: 'L29-entrance-2',
    lat: 40.645806,
    lon: -73.901414,
    stopId: 'L29',
  },
  {
    entranceId: 'L29-entrance-0',
    lat: 40.64556,
    lon: -73.902524,
    stopId: 'L29',
  },
  {
    entranceId: '109-entrance-0',
    lat: 40.861183,
    lon: -73.92511,
    stopId: '109',
  },
  {
    entranceId: '109-entrance-1',
    lat: 40.861187,
    lon: -73.925078,
    stopId: '109',
  },
  {
    entranceId: 'N08-entrance-0',
    lat: 40.605107,
    lon: -73.980578,
    stopId: 'N08',
  },
  {
    entranceId: 'N08-entrance-1',
    lat: 40.603212,
    lon: -73.980199,
    stopId: 'N08',
  },
  {
    entranceId: 'G28-entrance-5',
    lat: 40.725573,
    lon: -73.951603,
    stopId: 'G28',
  },
  {
    entranceId: 'G28-entrance-3',
    lat: 40.723894,
    lon: -73.950665,
    stopId: 'G28',
  },
  {
    entranceId: 'G28-entrance-4',
    lat: 40.725446,
    lon: -73.951974,
    stopId: 'G28',
  },
  {
    entranceId: 'G28-entrance-1',
    lat: 40.723765,
    lon: -73.950566,
    stopId: 'G28',
  },
  {
    entranceId: 'G28-entrance-2',
    lat: 40.72378,
    lon: -73.950985,
    stopId: 'G28',
  },
  {
    entranceId: 'G28-entrance-0',
    lat: 40.72365,
    lon: -73.950929,
    stopId: 'G28',
  },
  {
    entranceId: 'A24-entrance-10',
    lat: 40.768719,
    lon: -73.981788,
    stopId: 'A24',
  },
  {
    entranceId: '242-entrance-0',
    lat: 40.66329,
    lon: -73.951044,
    stopId: '242',
  },
  {
    entranceId: '242-entrance-1',
    lat: 40.663226,
    lon: -73.95067,
    stopId: '242',
  },
  {
    entranceId: 'F06-entrance-4',
    lat: 40.713678,
    lon: -73.830042,
    stopId: 'F06',
  },
  {
    entranceId: 'F06-entrance-5',
    lat: 40.714084,
    lon: -73.829936,
    stopId: 'F06',
  },
  {
    entranceId: 'F06-entrance-2',
    lat: 40.714658,
    lon: -73.832113,
    stopId: 'F06',
  },
  {
    entranceId: 'F06-entrance-3',
    lat: 40.714011,
    lon: -73.831154,
    stopId: 'F06',
  },
  {
    entranceId: 'F06-entrance-0',
    lat: 40.714951,
    lon: -73.831514,
    stopId: 'F06',
  },
  {
    entranceId: 'F06-entrance-1',
    lat: 40.715014,
    lon: -73.831591,
    stopId: 'F06',
  },
  {
    entranceId: 'A65-entrance-2',
    lat: 40.68572,
    lon: -73.827092,
    stopId: 'A65',
  },
  {
    entranceId: 'A65-entrance-3',
    lat: 40.685868,
    lon: -73.827107,
    stopId: 'A65',
  },
  {
    entranceId: 'A65-entrance-0',
    lat: 40.686103,
    lon: -73.824314,
    stopId: 'A65',
  },
  {
    entranceId: 'A65-entrance-1',
    lat: 40.686234,
    lon: -73.824378,
    stopId: 'A65',
  },
  {
    entranceId: 'A05-entrance-0',
    lat: 40.858899,
    lon: -73.932702,
    stopId: 'A05',
  },
  {
    entranceId: 'A05-entrance-1',
    lat: 40.858886,
    lon: -73.934226,
    stopId: 'A05',
  },
  {
    entranceId: 'A52-entrance-2',
    lat: 40.67443,
    lon: -73.896776,
    stopId: 'A52',
  },
  {
    entranceId: 'A52-entrance-3',
    lat: 40.674567,
    lon: -73.896364,
    stopId: 'A52',
  },
  {
    entranceId: 'A52-entrance-0',
    lat: 40.674242,
    lon: -73.896645,
    stopId: 'A52',
  },
  {
    entranceId: 'A52-entrance-1',
    lat: 40.674268,
    lon: -73.896312,
    stopId: 'A52',
  },
  {
    entranceId: 'L16-entrance-7',
    lat: 40.703353,
    lon: -73.917957,
    stopId: 'L16',
  },
  {
    entranceId: 'L16-entrance-3',
    lat: 40.70448,
    lon: -73.919184,
    stopId: 'L16',
  },
  {
    entranceId: 'L16-entrance-4',
    lat: 40.703461,
    lon: -73.917606,
    stopId: 'L16',
  },
  {
    entranceId: 'L16-entrance-5',
    lat: 40.70354,
    lon: -73.917771,
    stopId: 'L16',
  },
  {
    entranceId: 'L16-entrance-6',
    lat: 40.703248,
    lon: -73.91772,
    stopId: 'L16',
  },
  {
    entranceId: 'L16-entrance-0',
    lat: 40.704153,
    lon: -73.919273,
    stopId: 'L16',
  },
  {
    entranceId: 'L16-entrance-1',
    lat: 40.704379,
    lon: -73.919026,
    stopId: 'L16',
  },
  {
    entranceId: 'L16-entrance-2',
    lat: 40.704326,
    lon: -73.919452,
    stopId: 'L16',
  },
  {
    entranceId: 'R29-entrance-12',
    lat: 40.692315,
    lon: -73.985172,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-13',
    lat: 40.692031,
    lon: -73.985395,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-10',
    lat: 40.692359,
    lon: -73.986181,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-11',
    lat: 40.69198,
    lon: -73.98519,
    stopId: 'R29',
  },
  {
    entranceId: '136-entrance-4',
    lat: 40.719888,
    lon: -74.006925,
    stopId: '136',
  },
  {
    entranceId: '136-entrance-2',
    lat: 40.718965,
    lon: -74.007121,
    stopId: '136',
  },
  {
    entranceId: '136-entrance-3',
    lat: 40.719251,
    lon: -74.007058,
    stopId: '136',
  },
  {
    entranceId: '136-entrance-0',
    lat: 40.719001,
    lon: -74.006675,
    stopId: '136',
  },
  {
    entranceId: '136-entrance-1',
    lat: 40.719918,
    lon: -74.00662,
    stopId: '136',
  },
  {
    entranceId: '129-entrance-0',
    lat: 40.747264,
    lon: -73.993611,
    stopId: '129',
  },
  {
    entranceId: '129-entrance-1',
    lat: 40.747392,
    lon: -73.99351,
    stopId: '129',
  },
  {
    entranceId: '129-entrance-2',
    lat: 40.746787,
    lon: -73.993955,
    stopId: '129',
  },
  {
    entranceId: '129-entrance-3',
    lat: 40.746594,
    lon: -73.993555,
    stopId: '129',
  },
  {
    entranceId: '129-entrance-4',
    lat: 40.747036,
    lon: -73.993138,
    stopId: '129',
  },
  {
    entranceId: '129-entrance-5',
    lat: 40.747216,
    lon: -73.993121,
    stopId: '129',
  },
  {
    entranceId: 'F36-entrance-0',
    lat: 40.596701,
    lon: -73.973402,
    stopId: 'F36',
  },
  {
    entranceId: 'F36-entrance-1',
    lat: 40.596897,
    lon: -73.973137,
    stopId: 'F36',
  },
  {
    entranceId: 'F36-entrance-2',
    lat: 40.595053,
    lon: -73.973623,
    stopId: 'F36',
  },
  {
    entranceId: 'F36-entrance-3',
    lat: 40.595027,
    lon: -73.973382,
    stopId: 'F36',
  },
  {
    entranceId: 'R40-entrance-4',
    lat: 40.645619,
    lon: -74.013688,
    stopId: 'R40',
  },
  {
    entranceId: 'R40-entrance-2',
    lat: 40.644756,
    lon: -74.014586,
    stopId: 'R40',
  },
  {
    entranceId: 'R40-entrance-3',
    lat: 40.644601,
    lon: -74.014309,
    stopId: 'R40',
  },
  {
    entranceId: 'R40-entrance-0',
    lat: 40.644653,
    lon: -74.01469,
    stopId: 'R40',
  },
  {
    entranceId: 'R40-entrance-1',
    lat: 40.644493,
    lon: -74.014419,
    stopId: 'R40',
  },
  {
    entranceId: 'R33-entrance-0',
    lat: 40.670409,
    lon: -73.988472,
    stopId: 'R33',
  },
  {
    entranceId: 'R33-entrance-1',
    lat: 40.67055,
    lon: -73.988763,
    stopId: 'R33',
  },
  {
    entranceId: 'A18-entrance-0',
    lat: 40.796263,
    lon: -73.96161,
    stopId: 'A18',
  },
  {
    entranceId: 'R26-entrance-8',
    lat: 40.707225,
    lon: -74.013454,
    stopId: 'R26',
  },
  {
    entranceId: 'R26-entrance-2',
    lat: 40.708028,
    lon: -74.012696,
    stopId: 'R26',
  },
  {
    entranceId: 'R26-entrance-3',
    lat: 40.706345,
    lon: -74.014,
    stopId: 'R26',
  },
  {
    entranceId: 'R26-entrance-0',
    lat: 40.707647,
    lon: -74.012924,
    stopId: 'R26',
  },
  {
    entranceId: 'R26-entrance-1',
    lat: 40.707902,
    lon: -74.012774,
    stopId: 'R26',
  },
  {
    entranceId: 'R26-entrance-6',
    lat: 40.70795,
    lon: -74.012963,
    stopId: 'R26',
  },
  {
    entranceId: 'R26-entrance-7',
    lat: 40.708083,
    lon: -74.012863,
    stopId: 'R26',
  },
  {
    entranceId: 'R26-entrance-4',
    lat: 40.707495,
    lon: -74.013273,
    stopId: 'R26',
  },
  {
    entranceId: 'R26-entrance-5',
    lat: 40.707659,
    lon: -74.013166,
    stopId: 'R26',
  },
  {
    entranceId: 'A25-entrance-4',
    lat: 40.761691,
    lon: -73.986805,
    stopId: 'A25',
  },
  {
    entranceId: 'A25-entrance-5',
    lat: 40.762085,
    lon: -73.985829,
    stopId: 'A25',
  },
  {
    entranceId: 'A25-entrance-2',
    lat: 40.762452,
    lon: -73.986299,
    stopId: 'A25',
  },
  {
    entranceId: 'A25-entrance-3',
    lat: 40.761741,
    lon: -73.986924,
    stopId: 'A25',
  },
  {
    entranceId: 'A25-entrance-0',
    lat: 40.761968,
    lon: -73.986662,
    stopId: 'A25',
  },
  {
    entranceId: 'A25-entrance-1',
    lat: 40.762312,
    lon: -73.986378,
    stopId: 'A25',
  },
  {
    entranceId: 'A25-entrance-6',
    lat: 40.762216,
    lon: -73.985757,
    stopId: 'A25',
  },
  {
    entranceId: 'A25-entrance-7',
    lat: 40.7629,
    lon: -73.985412,
    stopId: 'A25',
  },
  {
    entranceId: 'E01-entrance-0',
    lat: 40.713333,
    lon: -74.009033,
    stopId: 'E01',
  },
  {
    entranceId: 'E01-entrance-1',
    lat: 40.712681,
    lon: -74.009891,
    stopId: 'E01',
  },
  {
    entranceId: 'E01-entrance-2',
    lat: 40.713286,
    lon: -74.009511,
    stopId: 'E01',
  },
  {
    entranceId: 'E01-entrance-3',
    lat: 40.713036,
    lon: -74.009245,
    stopId: 'E01',
  },
  {
    entranceId: 'E01-entrance-4',
    lat: 40.713435,
    lon: -74.009292,
    stopId: 'E01',
  },
  {
    entranceId: 'E01-entrance-5',
    lat: 40.711793,
    lon: -74.010165,
    stopId: 'E01',
  },
  {
    entranceId: 'E01-entrance-6',
    lat: 40.71171,
    lon: -74.010204,
    stopId: 'E01',
  },
  {
    entranceId: 'E01-entrance-7',
    lat: 40.712258,
    lon: -74.010306,
    stopId: 'E01',
  },
  {
    entranceId: '235-entrance-0',
    lat: 40.684976,
    lon: -73.97788,
    stopId: '235',
  },
  {
    entranceId: 'M22-entrance-5',
    lat: 40.70941,
    lon: -74.008306,
    stopId: 'M22',
  },
  {
    entranceId: 'M22-entrance-3',
    lat: 40.709523,
    lon: -74.008334,
    stopId: 'M22',
  },
  {
    entranceId: 'M22-entrance-4',
    lat: 40.70941,
    lon: -74.008306,
    stopId: 'M22',
  },
  {
    entranceId: 'M22-entrance-1',
    lat: 40.710166,
    lon: -74.007464,
    stopId: 'M22',
  },
  {
    entranceId: 'M22-entrance-2',
    lat: 40.710232,
    lon: -74.007614,
    stopId: 'M22',
  },
  {
    entranceId: 'M22-entrance-0',
    lat: 40.710098,
    lon: -74.007628,
    stopId: 'M22',
  },
  {
    entranceId: '248-entrance-0',
    lat: 40.669711,
    lon: -73.950247,
    stopId: '248',
  },
  {
    entranceId: '248-entrance-1',
    lat: 40.669741,
    lon: -73.950798,
    stopId: '248',
  },
  {
    entranceId: '634-entrance-9',
    lat: 40.739484,
    lon: -73.986558,
    stopId: '634',
  },
  {
    entranceId: '634-entrance-0',
    lat: 40.740248,
    lon: -73.986729,
    stopId: '634',
  },
  {
    entranceId: '634-entrance-1',
    lat: 40.74008,
    lon: -73.986607,
    stopId: '634',
  },
  {
    entranceId: '634-entrance-2',
    lat: 40.740421,
    lon: -73.986548,
    stopId: '634',
  },
  {
    entranceId: '634-entrance-3',
    lat: 40.740187,
    lon: -73.986015,
    stopId: '634',
  },
  {
    entranceId: '634-entrance-4',
    lat: 40.73999,
    lon: -73.986223,
    stopId: '634',
  },
  {
    entranceId: '634-entrance-5',
    lat: 40.739986,
    lon: -73.986338,
    stopId: '634',
  },
  {
    entranceId: '634-entrance-6',
    lat: 40.739691,
    lon: -73.987062,
    stopId: '634',
  },
  {
    entranceId: '634-entrance-7',
    lat: 40.739486,
    lon: -73.9871,
    stopId: '634',
  },
  {
    entranceId: '634-entrance-8',
    lat: 40.739374,
    lon: -73.98678,
    stopId: '634',
  },
  {
    entranceId: 'R39-entrance-2',
    lat: 40.649265,
    lon: -74.009457,
    stopId: 'R39',
  },
  {
    entranceId: 'R39-entrance-3',
    lat: 40.649546,
    lon: -74.009602,
    stopId: 'R39',
  },
  {
    entranceId: 'R39-entrance-0',
    lat: 40.649389,
    lon: -74.009333,
    stopId: 'R39',
  },
  {
    entranceId: 'R39-entrance-1',
    lat: 40.649424,
    lon: -74.009728,
    stopId: 'R39',
  },
  {
    entranceId: 'J12-entrance-0',
    lat: 40.700254,
    lon: -73.829332,
    stopId: 'J12',
  },
  {
    entranceId: 'J12-entrance-3',
    lat: 40.700596,
    lon: -73.827239,
    stopId: 'J12',
  },
  {
    entranceId: 'J12-entrance-1',
    lat: 40.70052,
    lon: -73.829231,
    stopId: 'J12',
  },
  {
    entranceId: 'J12-entrance-2',
    lat: 40.700808,
    lon: -73.82739,
    stopId: 'J12',
  },
  {
    entranceId: '505-entrance-0',
    lat: 40.854544,
    lon: -73.860248,
    stopId: '505',
  },
  {
    entranceId: 'A45-entrance-0',
    lat: 40.681256,
    lon: -73.95559,
    stopId: 'A45',
  },
  {
    entranceId: 'L10-entrance-0',
    lat: 40.713908,
    lon: -73.949262,
    stopId: 'L10',
  },
  {
    entranceId: 'L10-entrance-1',
    lat: 40.71421,
    lon: -73.949313,
    stopId: 'L10',
  },
  {
    entranceId: 'F23-entrance-1',
    lat: 40.669835,
    lon: -73.988673,
    stopId: 'F23',
  },
  {
    entranceId: 'F23-entrance-2',
    lat: 40.669716,
    lon: -73.988758,
    stopId: 'F23',
  },
  {
    entranceId: 'F23-entrance-3',
    lat: 40.670122,
    lon: -73.989257,
    stopId: 'F23',
  },
  {
    entranceId: 'F23-entrance-4',
    lat: 40.670192,
    lon: -73.98935,
    stopId: 'F23',
  },
  {
    entranceId: 'F23-entrance-5',
    lat: 40.670169,
    lon: -73.98937,
    stopId: 'F23',
  },
  {
    entranceId: 'F23-entrance-0',
    lat: 40.669902,
    lon: -73.988787,
    stopId: 'F23',
  },
  {
    entranceId: 'F30-entrance-3',
    lat: 40.628708,
    lon: -73.976646,
    stopId: 'F30',
  },
  {
    entranceId: 'F30-entrance-1',
    lat: 40.630562,
    lon: -73.976995,
    stopId: 'F30',
  },
  {
    entranceId: 'F30-entrance-2',
    lat: 40.628674,
    lon: -73.976892,
    stopId: 'F30',
  },
  {
    entranceId: 'F30-entrance-0',
    lat: 40.630531,
    lon: -73.977251,
    stopId: 'F30',
  },
  {
    entranceId: 'R20-entrance-2',
    lat: 40.736523,
    lon: -73.990447,
    stopId: 'R20',
  },
  {
    entranceId: 'R20-entrance-3',
    lat: 40.735311,
    lon: -73.990265,
    stopId: 'R20',
  },
  {
    entranceId: '123-entrance-2',
    lat: 40.778585,
    lon: -73.981923,
    stopId: '123',
  },
  {
    entranceId: '123-entrance-3',
    lat: 40.778437,
    lon: -73.981947,
    stopId: '123',
  },
  {
    entranceId: '123-entrance-0',
    lat: 40.779146,
    lon: -73.981822,
    stopId: '123',
  },
  {
    entranceId: '123-entrance-1',
    lat: 40.778875,
    lon: -73.981877,
    stopId: '123',
  },
  {
    entranceId: 'R20-entrance-0',
    lat: 40.735278,
    lon: -73.991058,
    stopId: 'R20',
  },
  {
    entranceId: 'R20-entrance-1',
    lat: 40.736339,
    lon: -73.990603,
    stopId: 'R20',
  },
  {
    entranceId: '116-entrance-0',
    lat: 40.815143,
    lon: -73.958439,
    stopId: '116',
  },
  {
    entranceId: '116-entrance-1',
    lat: 40.815688,
    lon: -73.958554,
    stopId: '116',
  },
  {
    entranceId: '116-entrance-2',
    lat: 40.815307,
    lon: -73.958826,
    stopId: '116',
  },
  {
    entranceId: '116-entrance-3',
    lat: 40.81523,
    lon: -73.958366,
    stopId: '116',
  },
  {
    entranceId: '301-entrance-0',
    lat: 40.824069,
    lon: -73.936981,
    stopId: '301',
  },
  {
    entranceId: '215-entrance-0',
    lat: 40.837325,
    lon: -73.888048,
    stopId: '215',
  },
  {
    entranceId: '215-entrance-1',
    lat: 40.837599,
    lon: -73.887755,
    stopId: '215',
  },
  {
    entranceId: '215-entrance-2',
    lat: 40.837241,
    lon: -73.88756,
    stopId: '215',
  },
  {
    entranceId: '215-entrance-3',
    lat: 40.837306,
    lon: -73.887287,
    stopId: '215',
  },
  {
    entranceId: 'A30-entrance-10',
    lat: 40.746427,
    lon: -73.99735,
    stopId: 'A30',
  },
  {
    entranceId: 'G22-entrance-0',
    lat: 40.746214,
    lon: -73.945115,
    stopId: 'G22',
  },
  {
    entranceId: 'G22-entrance-1',
    lat: 40.746869,
    lon: -73.943522,
    stopId: 'G22',
  },
  {
    entranceId: 'G22-entrance-2',
    lat: 40.746243,
    lon: -73.944011,
    stopId: 'G22',
  },
  {
    entranceId: '228-entrance-0',
    lat: 40.712712,
    lon: -74.007766,
    stopId: '228',
  },
  {
    entranceId: 'A38-entrance-0',
    lat: 40.710692,
    lon: -74.008428,
    stopId: 'A38',
  },
  {
    entranceId: 'A38-entrance-1',
    lat: 40.709647,
    lon: -74.006791,
    stopId: 'A38',
  },
  {
    entranceId: '627-entrance-0',
    lat: 40.773669,
    lon: -73.960135,
    stopId: '627',
  },
  {
    entranceId: '627-entrance-3',
    lat: 40.773636,
    lon: -73.96006,
    stopId: '627',
  },
  {
    entranceId: '627-entrance-4',
    lat: 40.773526,
    lon: -73.959734,
    stopId: '627',
  },
  {
    entranceId: '627-entrance-1',
    lat: 40.773779,
    lon: -73.959954,
    stopId: '627',
  },
  {
    entranceId: '627-entrance-2',
    lat: 40.773779,
    lon: -73.960041,
    stopId: '627',
  },
  {
    entranceId: '627-entrance-7',
    lat: 40.773603,
    lon: -73.959578,
    stopId: '627',
  },
  {
    entranceId: '627-entrance-5',
    lat: 40.773631,
    lon: -73.959659,
    stopId: '627',
  },
  {
    entranceId: '627-entrance-6',
    lat: 40.773493,
    lon: -73.959659,
    stopId: '627',
  },
  {
    entranceId: '413-entrance-0',
    lat: 40.8356,
    lon: -73.921131,
    stopId: '413',
  },
  {
    entranceId: '413-entrance-1',
    lat: 40.835746,
    lon: -73.921562,
    stopId: '413',
  },
  {
    entranceId: '413-entrance-2',
    lat: 40.835726,
    lon: -73.921059,
    stopId: '413',
  },
  {
    entranceId: '413-entrance-3',
    lat: 40.835873,
    lon: -73.92149,
    stopId: '413',
  },
  {
    entranceId: 'G35-entrance-7',
    lat: 40.688157,
    lon: -73.965579,
    stopId: 'G35',
  },
  {
    entranceId: 'G35-entrance-3',
    lat: 40.687875,
    lon: -73.967931,
    stopId: 'G35',
  },
  {
    entranceId: 'G35-entrance-4',
    lat: 40.68846,
    lon: -73.965749,
    stopId: 'G35',
  },
  {
    entranceId: 'G35-entrance-5',
    lat: 40.68846,
    lon: -73.965749,
    stopId: 'G35',
  },
  {
    entranceId: 'G35-entrance-6',
    lat: 40.688157,
    lon: -73.965579,
    stopId: 'G35',
  },
  {
    entranceId: 'G35-entrance-0',
    lat: 40.688114,
    lon: -73.967575,
    stopId: 'G35',
  },
  {
    entranceId: 'G35-entrance-1',
    lat: 40.688114,
    lon: -73.967575,
    stopId: 'G35',
  },
  {
    entranceId: 'G35-entrance-2',
    lat: 40.687875,
    lon: -73.967931,
    stopId: 'G35',
  },
  {
    entranceId: '726-entrance-9',
    lat: 40.755737,
    lon: -74.000221,
    stopId: '726',
  },
  {
    entranceId: '726-entrance-7',
    lat: 40.755737,
    lon: -74.000221,
    stopId: '726',
  },
  {
    entranceId: '726-entrance-8',
    lat: 40.755737,
    lon: -74.000221,
    stopId: '726',
  },
  {
    entranceId: '726-entrance-5',
    lat: 40.754931,
    lon: -74.00092,
    stopId: '726',
  },
  {
    entranceId: '726-entrance-6',
    lat: 40.754905,
    lon: -74.001321,
    stopId: '726',
  },
  {
    entranceId: '726-entrance-3',
    lat: 40.754931,
    lon: -74.00092,
    stopId: '726',
  },
  {
    entranceId: '726-entrance-4',
    lat: 40.754931,
    lon: -74.00092,
    stopId: '726',
  },
  {
    entranceId: '726-entrance-1',
    lat: 40.754931,
    lon: -74.00092,
    stopId: '726',
  },
  {
    entranceId: '726-entrance-2',
    lat: 40.754931,
    lon: -74.00092,
    stopId: '726',
  },
  {
    entranceId: '726-entrance-0',
    lat: 40.754931,
    lon: -74.00092,
    stopId: '726',
  },
  {
    entranceId: 'D35-entrance-2',
    lat: 40.608319,
    lon: -73.957352,
    stopId: 'D35',
  },
  {
    entranceId: 'D35-entrance-0',
    lat: 40.608835,
    lon: -73.957769,
    stopId: 'D35',
  },
  {
    entranceId: 'D35-entrance-1',
    lat: 40.609062,
    lon: -73.957808,
    stopId: 'D35',
  },
  {
    entranceId: '901-entrance-4',
    lat: 40.753087,
    lon: -73.979537,
    stopId: '901',
  },
  {
    entranceId: '901-entrance-3',
    lat: 40.752846,
    lon: -73.979798,
    stopId: '901',
  },
  {
    entranceId: '901-entrance-2',
    lat: 40.752184,
    lon: -73.978048,
    stopId: '901',
  },
  {
    entranceId: '901-entrance-1',
    lat: 40.752279,
    lon: -73.97828,
    stopId: '901',
  },
  {
    entranceId: '901-entrance-0',
    lat: 40.752218,
    lon: -73.978396,
    stopId: '901',
  },
  {
    entranceId: 'R36-entrance-0',
    lat: 40.65449,
    lon: -74.004499,
    stopId: 'R36',
  },
  {
    entranceId: 'R36-entrance-2',
    lat: 40.654676,
    lon: -74.004306,
    stopId: 'R36',
  },
  {
    entranceId: 'R36-entrance-1',
    lat: 40.654365,
    lon: -74.004113,
    stopId: 'R36',
  },
  {
    entranceId: 'D19-entrance-1',
    lat: 40.737572,
    lon: -73.997111,
    stopId: 'D19',
  },
  {
    entranceId: 'D19-entrance-0',
    lat: 40.737423,
    lon: -73.99722,
    stopId: 'D19',
  },
  {
    entranceId: 'D19-entrance-5',
    lat: 40.738529,
    lon: -73.995685,
    stopId: 'D19',
  },
  {
    entranceId: 'D19-entrance-4',
    lat: 40.738678,
    lon: -73.995632,
    stopId: 'D19',
  },
  {
    entranceId: 'D19-entrance-3',
    lat: 40.73737,
    lon: -73.997079,
    stopId: 'D19',
  },
  {
    entranceId: 'D19-entrance-2',
    lat: 40.737519,
    lon: -73.99697,
    stopId: 'D19',
  },
  {
    entranceId: 'D19-entrance-9',
    lat: 40.737316,
    lon: -73.996508,
    stopId: 'D19',
  },
  {
    entranceId: 'D19-entrance-8',
    lat: 40.737286,
    lon: -73.996437,
    stopId: 'D19',
  },
  {
    entranceId: 'D19-entrance-7',
    lat: 40.738718,
    lon: -73.996121,
    stopId: 'D19',
  },
  {
    entranceId: 'D19-entrance-6',
    lat: 40.738838,
    lon: -73.996023,
    stopId: 'D19',
  },
  {
    entranceId: 'G36-entrance-1',
    lat: 40.68716,
    lon: -73.976865,
    stopId: 'G36',
  },
  {
    entranceId: 'G36-entrance-0',
    lat: 40.687159,
    lon: -73.97629,
    stopId: 'G36',
  },
  {
    entranceId: 'F26-entrance-1',
    lat: 40.651608,
    lon: -73.975596,
    stopId: 'F26',
  },
  {
    entranceId: 'F26-entrance-0',
    lat: 40.651834,
    lon: -73.976076,
    stopId: 'F26',
  },
  {
    entranceId: 'F26-entrance-3',
    lat: 40.649854,
    lon: -73.975815,
    stopId: 'F26',
  },
  {
    entranceId: 'F26-entrance-2',
    lat: 40.652665,
    lon: -73.975901,
    stopId: 'F26',
  },
  {
    entranceId: '241-entrance-0',
    lat: 40.668166,
    lon: -73.950862,
    stopId: '241',
  },
  {
    entranceId: '241-entrance-1',
    lat: 40.668145,
    lon: -73.950458,
    stopId: '241',
  },
  {
    entranceId: 'D06-entrance-3',
    lat: 40.856039,
    lon: -73.900449,
    stopId: 'D06',
  },
  {
    entranceId: 'D06-entrance-2',
    lat: 40.856237,
    lon: -73.901115,
    stopId: 'D06',
  },
  {
    entranceId: 'D06-entrance-1',
    lat: 40.855864,
    lon: -73.900484,
    stopId: 'D06',
  },
  {
    entranceId: 'D06-entrance-0',
    lat: 40.856038,
    lon: -73.90114,
    stopId: 'D06',
  },
  {
    entranceId: 'F39-entrance-1',
    lat: 40.580547,
    lon: -73.974778,
    stopId: 'F39',
  },
  {
    entranceId: 'F39-entrance-0',
    lat: 40.580576,
    lon: -73.974443,
    stopId: 'F39',
  },
  {
    entranceId: 'D01-entrance-2',
    lat: 40.874021,
    lon: -73.877501,
    stopId: 'D01',
  },
  {
    entranceId: 'D01-entrance-1',
    lat: 40.875087,
    lon: -73.87961,
    stopId: 'D01',
  },
  {
    entranceId: 'D01-entrance-0',
    lat: 40.874902,
    lon: -73.879267,
    stopId: 'D01',
  },
  {
    entranceId: 'D01-entrance-3',
    lat: 40.874262,
    lon: -73.877533,
    stopId: 'D01',
  },
  {
    entranceId: '213-entrance-1',
    lat: 40.841453,
    lon: -73.872892,
    stopId: '213',
  },
  {
    entranceId: '213-entrance-0',
    lat: 40.841223,
    lon: -73.873499,
    stopId: '213',
  },
  {
    entranceId: 'R23-entrance-6',
    lat: 40.719166,
    lon: -74.001774,
    stopId: 'R23',
  },
  {
    entranceId: 'R23-entrance-5',
    lat: 40.719218,
    lon: -74.00185,
    stopId: 'R23',
  },
  {
    entranceId: 'R23-entrance-2',
    lat: 40.719408,
    lon: -74.002158,
    stopId: 'R23',
  },
  {
    entranceId: 'R23-entrance-1',
    lat: 40.71966,
    lon: -74.002019,
    stopId: 'R23',
  },
  {
    entranceId: 'R23-entrance-4',
    lat: 40.719498,
    lon: -74.001606,
    stopId: 'R23',
  },
  {
    entranceId: 'R23-entrance-3',
    lat: 40.71946,
    lon: -74.002236,
    stopId: 'R23',
  },
  {
    entranceId: 'R23-entrance-0',
    lat: 40.719585,
    lon: -74.00193,
    stopId: 'R23',
  },
  {
    entranceId: '254-entrance-1',
    lat: 40.663361,
    lon: -73.903063,
    stopId: '254',
  },
  {
    entranceId: '254-entrance-0',
    lat: 40.663426,
    lon: -73.902636,
    stopId: '254',
  },
  {
    entranceId: 'G31-entrance-0',
    lat: 40.699582,
    lon: -73.950164,
    stopId: 'G31',
  },
  {
    entranceId: 'G31-entrance-1',
    lat: 40.699985,
    lon: -73.949895,
    stopId: 'G31',
  },
  {
    entranceId: 'M14-entrance-1',
    lat: 40.707248,
    lon: -73.954326,
    stopId: 'M14',
  },
  {
    entranceId: 'M14-entrance-0',
    lat: 40.707117,
    lon: -73.95437,
    stopId: 'M14',
  },
  {
    entranceId: 'M14-entrance-3',
    lat: 40.706654,
    lon: -73.952553,
    stopId: 'M14',
  },
  {
    entranceId: 'M14-entrance-2',
    lat: 40.706505,
    lon: -73.952595,
    stopId: 'M14',
  },
  {
    entranceId: '405-entrance-0',
    lat: 40.873528,
    lon: -73.889754,
    stopId: '405',
  },
  {
    entranceId: 'H10-entrance-2',
    lat: 40.59967,
    lon: -73.761596,
    stopId: 'H10',
  },
  {
    entranceId: 'H10-entrance-1',
    lat: 40.599427,
    lon: -73.761807,
    stopId: 'H10',
  },
  {
    entranceId: 'H10-entrance-0',
    lat: 40.599708,
    lon: -73.761959,
    stopId: 'H10',
  },
  {
    entranceId: 'J30-entrance-1',
    lat: 40.690142,
    lon: -73.923062,
    stopId: 'J30',
  },
  {
    entranceId: 'J30-entrance-0',
    lat: 40.69003,
    lon: -73.923161,
    stopId: 'J30',
  },
  {
    entranceId: 'J15-entrance-2',
    lat: 40.694077,
    lon: -73.850476,
    stopId: 'J15',
  },
  {
    entranceId: 'J15-entrance-1',
    lat: 40.693563,
    lon: -73.852608,
    stopId: 'J15',
  },
  {
    entranceId: 'J15-entrance-0',
    lat: 40.693451,
    lon: -73.852556,
    stopId: 'J15',
  },
  {
    entranceId: 'B22-entrance-3',
    lat: 40.597952,
    lon: -73.987442,
    stopId: 'B22',
  },
  {
    entranceId: 'B22-entrance-2',
    lat: 40.597751,
    lon: -73.987112,
    stopId: 'B22',
  },
  {
    entranceId: 'B22-entrance-1',
    lat: 40.5981,
    lon: -73.987279,
    stopId: 'B22',
  },
  {
    entranceId: 'B22-entrance-0',
    lat: 40.597889,
    lon: -73.986958,
    stopId: 'B22',
  },
  {
    entranceId: '121-entrance-1',
    lat: 40.788515,
    lon: -73.976718,
    stopId: '121',
  },
  {
    entranceId: '121-entrance-0',
    lat: 40.788349,
    lon: -73.976935,
    stopId: '121',
  },
  {
    entranceId: '121-entrance-4',
    lat: 40.78902,
    lon: -73.97633,
    stopId: '121',
  },
  {
    entranceId: '121-entrance-3',
    lat: 40.788272,
    lon: -73.976083,
    stopId: '121',
  },
  {
    entranceId: '121-entrance-2',
    lat: 40.788073,
    lon: -73.976288,
    stopId: '121',
  },
  {
    entranceId: '226-entrance-1',
    lat: 40.802229,
    lon: -73.949734,
    stopId: '226',
  },
  {
    entranceId: '226-entrance-0',
    lat: 40.801945,
    lon: -73.949943,
    stopId: '226',
  },
  {
    entranceId: '226-entrance-3',
    lat: 40.802111,
    lon: -73.949437,
    stopId: '226',
  },
  {
    entranceId: '226-entrance-2',
    lat: 40.801822,
    lon: -73.949651,
    stopId: '226',
  },
  {
    entranceId: 'A30-entrance-1',
    lat: 40.745299,
    lon: -73.998145,
    stopId: 'A30',
  },
  {
    entranceId: 'A30-entrance-0',
    lat: 40.745102,
    lon: -73.998365,
    stopId: 'A30',
  },
  {
    entranceId: 'A30-entrance-3',
    lat: 40.746603,
    lon: -73.997801,
    stopId: 'A30',
  },
  {
    entranceId: 'A30-entrance-2',
    lat: 40.746732,
    lon: -73.997711,
    stopId: 'A30',
  },
  {
    entranceId: 'A30-entrance-5',
    lat: 40.745505,
    lon: -73.998642,
    stopId: 'A30',
  },
  {
    entranceId: 'A30-entrance-4',
    lat: 40.745278,
    lon: -73.998771,
    stopId: 'A30',
  },
  {
    entranceId: 'A30-entrance-7',
    lat: 40.746483,
    lon: -73.997142,
    stopId: 'A30',
  },
  {
    entranceId: 'A30-entrance-6',
    lat: 40.746535,
    lon: -73.997271,
    stopId: 'A30',
  },
  {
    entranceId: 'A30-entrance-9',
    lat: 40.745814,
    lon: -73.997852,
    stopId: 'A30',
  },
  {
    entranceId: 'A30-entrance-8',
    lat: 40.74611,
    lon: -73.998145,
    stopId: 'A30',
  },
  {
    entranceId: '635-entrance-1',
    lat: 40.73418,
    lon: -73.989549,
    stopId: '635',
  },
  {
    entranceId: '635-entrance-0',
    lat: 40.734406,
    lon: -73.989437,
    stopId: '635',
  },
  {
    entranceId: '635-entrance-3',
    lat: 40.735184,
    lon: -73.989793,
    stopId: '635',
  },
  {
    entranceId: '635-entrance-2',
    lat: 40.734222,
    lon: -73.989674,
    stopId: '635',
  },
  {
    entranceId: '635-entrance-5',
    lat: 40.734444,
    lon: -73.990184,
    stopId: '635',
  },
  {
    entranceId: '635-entrance-4',
    lat: 40.734495,
    lon: -73.990311,
    stopId: '635',
  },
  {
    entranceId: '622-entrance-1',
    lat: 40.798852,
    lon: -73.9416,
    stopId: '622',
  },
  {
    entranceId: '622-entrance-0',
    lat: 40.798466,
    lon: -73.941882,
    stopId: '622',
  },
  {
    entranceId: '622-entrance-3',
    lat: 40.798742,
    lon: -73.941406,
    stopId: '622',
  },
  {
    entranceId: '622-entrance-2',
    lat: 40.798404,
    lon: -73.941647,
    stopId: '622',
  },
  {
    entranceId: 'G18-entrance-3',
    lat: 40.755813,
    lon: -73.912642,
    stopId: 'G18',
  },
  {
    entranceId: 'G18-entrance-2',
    lat: 40.756624,
    lon: -73.914407,
    stopId: 'G18',
  },
  {
    entranceId: 'G18-entrance-1',
    lat: 40.756029,
    lon: -73.91218,
    stopId: 'G18',
  },
  {
    entranceId: 'G18-entrance-0',
    lat: 40.756868,
    lon: -73.914303,
    stopId: 'G18',
  },
  {
    entranceId: 'F31-entrance-3',
    lat: 40.624301,
    lon: -73.975817,
    stopId: 'F31',
  },
  {
    entranceId: 'F31-entrance-2',
    lat: 40.624263,
    lon: -73.976049,
    stopId: 'F31',
  },
  {
    entranceId: 'F31-entrance-1',
    lat: 40.626163,
    lon: -73.976177,
    stopId: 'F31',
  },
  {
    entranceId: 'F31-entrance-0',
    lat: 40.626133,
    lon: -73.976407,
    stopId: 'F31',
  },
  {
    entranceId: '709-entrance-2',
    lat: 40.747797,
    lon: -73.884028,
    stopId: '709',
  },
  {
    entranceId: '709-entrance-1',
    lat: 40.747453,
    lon: -73.883973,
    stopId: '709',
  },
  {
    entranceId: '709-entrance-0',
    lat: 40.747812,
    lon: -73.883876,
    stopId: '709',
  },
  {
    entranceId: 'M19-entrance-1',
    lat: 40.720291,
    lon: -73.993411,
    stopId: 'M19',
  },
  {
    entranceId: 'M19-entrance-0',
    lat: 40.719977,
    lon: -73.993576,
    stopId: 'M19',
  },
  {
    entranceId: '707-entrance-1',
    lat: 40.749266,
    lon: -73.86922,
    stopId: '707',
  },
  {
    entranceId: '707-entrance-0',
    lat: 40.749095,
    lon: -73.86912,
    stopId: '707',
  },
  {
    entranceId: '707-entrance-3',
    lat: 40.749213,
    lon: -73.869696,
    stopId: '707',
  },
  {
    entranceId: '707-entrance-2',
    lat: 40.749028,
    lon: -73.869574,
    stopId: '707',
  },
  {
    entranceId: '239-entrance-1',
    lat: 40.670732,
    lon: -73.957696,
    stopId: '239',
  },
  {
    entranceId: '239-entrance-0',
    lat: 40.670485,
    lon: -73.957806,
    stopId: '239',
  },
  {
    entranceId: '239-entrance-3',
    lat: 40.670922,
    lon: -73.957764,
    stopId: '239',
  },
  {
    entranceId: '239-entrance-2',
    lat: 40.670337,
    lon: -73.957973,
    stopId: '239',
  },
  {
    entranceId: 'G05-entrance-1',
    lat: 40.702821,
    lon: -73.799843,
    stopId: 'G05',
  },
  {
    entranceId: 'G05-entrance-0',
    lat: 40.701855,
    lon: -73.80174,
    stopId: 'G05',
  },
  {
    entranceId: 'G05-entrance-5',
    lat: 40.702324,
    lon: -73.80001,
    stopId: 'G05',
  },
  {
    entranceId: 'G05-entrance-4',
    lat: 40.702674,
    lon: -73.799786,
    stopId: 'G05',
  },
  {
    entranceId: 'G05-entrance-3',
    lat: 40.701886,
    lon: -73.801754,
    stopId: 'G05',
  },
  {
    entranceId: 'G05-entrance-2',
    lat: 40.702311,
    lon: -73.799991,
    stopId: 'G05',
  },
  {
    entranceId: 'G05-entrance-8',
    lat: 40.70164,
    lon: -73.802478,
    stopId: 'G05',
  },
  {
    entranceId: 'G05-entrance-7',
    lat: 40.701874,
    lon: -73.801747,
    stopId: 'G05',
  },
  {
    entranceId: 'G05-entrance-6',
    lat: 40.702234,
    lon: -73.801859,
    stopId: 'G05',
  },
  {
    entranceId: '714-entrance-3',
    lat: 40.74324,
    lon: -73.918813,
    stopId: '714',
  },
  {
    entranceId: '714-entrance-2',
    lat: 40.743105,
    lon: -73.918848,
    stopId: '714',
  },
  {
    entranceId: '714-entrance-1',
    lat: 40.743217,
    lon: -73.918564,
    stopId: '714',
  },
  {
    entranceId: '714-entrance-0',
    lat: 40.743064,
    lon: -73.918601,
    stopId: '714',
  },
  {
    entranceId: '714-entrance-5',
    lat: 40.743134,
    lon: -73.917895,
    stopId: '714',
  },
  {
    entranceId: '714-entrance-4',
    lat: 40.742987,
    lon: -73.917936,
    stopId: '714',
  },
  {
    entranceId: 'H15-entrance-0',
    lat: 40.58051,
    lon: -73.837418,
    stopId: 'H15',
  },
  {
    entranceId: 'L14-entrance-0',
    lat: 40.706317,
    lon: -73.931696,
    stopId: 'L14',
  },
  {
    entranceId: 'L14-entrance-2',
    lat: 40.706073,
    lon: -73.933633,
    stopId: 'L14',
  },
  {
    entranceId: 'L14-entrance-1',
    lat: 40.70621,
    lon: -73.93196,
    stopId: 'L14',
  },
  {
    entranceId: 'D21-entrance-0',
    lat: 40.725202,
    lon: -73.996583,
    stopId: 'D21',
  },
  {
    entranceId: 'D21-entrance-2',
    lat: 40.725344,
    lon: -73.995612,
    stopId: 'D21',
  },
  {
    entranceId: 'D21-entrance-1',
    lat: 40.725499,
    lon: -73.996387,
    stopId: 'D21',
  },
  {
    entranceId: 'D21-entrance-4',
    lat: 40.725011,
    lon: -73.995745,
    stopId: 'D21',
  },
  {
    entranceId: 'D21-entrance-3',
    lat: 40.724987,
    lon: -73.995628,
    stopId: 'D21',
  },
  {
    entranceId: 'H08-entrance-1',
    lat: 40.59286,
    lon: -73.776109,
    stopId: 'H08',
  },
  {
    entranceId: 'H08-entrance-0',
    lat: 40.593106,
    lon: -73.775743,
    stopId: 'H08',
  },
  {
    entranceId: 'H08-entrance-3',
    lat: 40.592897,
    lon: -73.775715,
    stopId: 'H08',
  },
  {
    entranceId: 'H08-entrance-2',
    lat: 40.593079,
    lon: -73.775985,
    stopId: 'H08',
  },
  {
    entranceId: '602-entrance-1',
    lat: 40.846795,
    lon: -73.832405,
    stopId: '602',
  },
  {
    entranceId: '602-entrance-0',
    lat: 40.846891,
    lon: -73.832639,
    stopId: '602',
  },
  {
    entranceId: '602-entrance-2',
    lat: 40.847185,
    lon: -73.832264,
    stopId: '602',
  },
  {
    entranceId: 'G10-entrance-5',
    lat: 40.728847,
    lon: -73.859668,
    stopId: 'G10',
  },
  {
    entranceId: 'G10-entrance-4',
    lat: 40.729574,
    lon: -73.861751,
    stopId: 'G10',
  },
  {
    entranceId: 'G10-entrance-6',
    lat: 40.729774,
    lon: -73.860489,
    stopId: 'G10',
  },
  {
    entranceId: 'G10-entrance-1',
    lat: 40.729265,
    lon: -73.860814,
    stopId: 'G10',
  },
  {
    entranceId: 'G10-entrance-0',
    lat: 40.729953,
    lon: -73.862699,
    stopId: 'G10',
  },
  {
    entranceId: 'G10-entrance-3',
    lat: 40.730037,
    lon: -73.861343,
    stopId: 'G10',
  },
  {
    entranceId: 'G10-entrance-2',
    lat: 40.730417,
    lon: -73.862373,
    stopId: 'G10',
  },
  {
    entranceId: 'R03-entrance-3',
    lat: 40.77087,
    lon: -73.916935,
    stopId: 'R03',
  },
  {
    entranceId: 'R03-entrance-2',
    lat: 40.771009,
    lon: -73.917167,
    stopId: 'R03',
  },
  {
    entranceId: 'R03-entrance-1',
    lat: 40.77017,
    lon: -73.917755,
    stopId: 'R03',
  },
  {
    entranceId: 'R03-entrance-0',
    lat: 40.770313,
    lon: -73.917978,
    stopId: 'R03',
  },
  {
    entranceId: '221-entrance-0',
    lat: 40.816315,
    lon: -73.917543,
    stopId: '221',
  },
  {
    entranceId: '221-entrance-2',
    lat: 40.816265,
    lon: -73.917456,
    stopId: '221',
  },
  {
    entranceId: '221-entrance-1',
    lat: 40.816242,
    lon: -73.917934,
    stopId: '221',
  },
  {
    entranceId: 'R44-entrance-1',
    lat: 40.622583,
    lon: -74.028315,
    stopId: 'R44',
  },
  {
    entranceId: 'R44-entrance-0',
    lat: 40.622656,
    lon: -74.028547,
    stopId: 'R44',
  },
  {
    entranceId: 'R44-entrance-2',
    lat: 40.623498,
    lon: -74.028204,
    stopId: 'R44',
  },
  {
    entranceId: 'F11-entrance-1',
    lat: 40.75812,
    lon: -73.970958,
    stopId: 'F11',
  },
  {
    entranceId: 'F11-entrance-0',
    lat: 40.758329,
    lon: -73.970567,
    stopId: 'F11',
  },
  {
    entranceId: 'F11-entrance-3',
    lat: 40.757475,
    lon: -73.969255,
    stopId: 'F11',
  },
  {
    entranceId: 'F11-entrance-2',
    lat: 40.757784,
    lon: -73.971098,
    stopId: 'F11',
  },
  {
    entranceId: 'F11-entrance-5',
    lat: 40.757685,
    lon: -73.969125,
    stopId: 'F11',
  },
  {
    entranceId: 'F11-entrance-4',
    lat: 40.757718,
    lon: -73.969161,
    stopId: 'F11',
  },
  {
    entranceId: '221-entrance-4',
    lat: 40.816033,
    lon: -73.918064,
    stopId: '221',
  },
  {
    entranceId: '221-entrance-3',
    lat: 40.815881,
    lon: -73.917522,
    stopId: '221',
  },
  {
    entranceId: '221-entrance-5',
    lat: 40.815853,
    lon: -73.917759,
    stopId: '221',
  },
  {
    entranceId: 'D34-entrance-0',
    lat: 40.617928,
    lon: -73.959454,
    stopId: 'D34',
  },
  {
    entranceId: 'D34-entrance-1',
    lat: 40.61814,
    lon: -73.95946,
    stopId: 'D34',
  },
  {
    entranceId: '615-entrance-3',
    lat: 40.812027,
    lon: -73.903869,
    stopId: '615',
  },
  {
    entranceId: '615-entrance-2',
    lat: 40.811887,
    lon: -73.904145,
    stopId: '615',
  },
  {
    entranceId: '615-entrance-1',
    lat: 40.812367,
    lon: -73.903797,
    stopId: '615',
  },
  {
    entranceId: '615-entrance-0',
    lat: 40.812331,
    lon: -73.904102,
    stopId: '615',
  },
  {
    entranceId: 'R31-entrance-1',
    lat: 40.683907,
    lon: -73.97887,
    stopId: 'R31',
  },
  {
    entranceId: 'R31-entrance-0',
    lat: 40.683889,
    lon: -73.978442,
    stopId: 'R31',
  },
  {
    entranceId: 'R31-entrance-3',
    lat: 40.683928,
    lon: -73.978412,
    stopId: 'R31',
  },
  {
    entranceId: 'R31-entrance-2',
    lat: 40.683905,
    lon: -73.978879,
    stopId: 'R31',
  },
  {
    entranceId: 'D39-entrance-1',
    lat: 40.585734,
    lon: -73.954371,
    stopId: 'D39',
  },
  {
    entranceId: 'D39-entrance-0',
    lat: 40.587302,
    lon: -73.954224,
    stopId: 'D39',
  },
  {
    entranceId: '701-entrance-8',
    lat: 40.759946,
    lon: -73.828414,
    stopId: '701',
  },
  {
    entranceId: '701-entrance-7',
    lat: 40.759895,
    lon: -73.828573,
    stopId: '701',
  },
  {
    entranceId: '701-entrance-6',
    lat: 40.760048,
    lon: -73.828444,
    stopId: '701',
  },
  {
    entranceId: '701-entrance-5',
    lat: 40.760004,
    lon: -73.82863,
    stopId: '701',
  },
  {
    entranceId: '701-entrance-4',
    lat: 40.759404,
    lon: -73.830333,
    stopId: '701',
  },
  {
    entranceId: '701-entrance-3',
    lat: 40.759587,
    lon: -73.830408,
    stopId: '701',
  },
  {
    entranceId: '701-entrance-2',
    lat: 40.759451,
    lon: -73.829927,
    stopId: '701',
  },
  {
    entranceId: '701-entrance-1',
    lat: 40.759706,
    lon: -73.829943,
    stopId: '701',
  },
  {
    entranceId: '701-entrance-0',
    lat: 40.759542,
    lon: -73.829885,
    stopId: '701',
  },
  {
    entranceId: 'J23-entrance-1',
    lat: 40.677793,
    lon: -73.891553,
    stopId: 'J23',
  },
  {
    entranceId: 'J23-entrance-0',
    lat: 40.677775,
    lon: -73.891689,
    stopId: 'J23',
  },
  {
    entranceId: 'F24-entrance-3',
    lat: 40.665993,
    lon: -73.979181,
    stopId: 'F24',
  },
  {
    entranceId: 'F24-entrance-2',
    lat: 40.665593,
    lon: -73.979509,
    stopId: 'F24',
  },
  {
    entranceId: 'F24-entrance-5',
    lat: 40.667004,
    lon: -73.981331,
    stopId: 'F24',
  },
  {
    entranceId: 'F24-entrance-4',
    lat: 40.666649,
    lon: -73.981624,
    stopId: 'F24',
  },
  {
    entranceId: 'F24-entrance-7',
    lat: 40.667112,
    lon: -73.981716,
    stopId: 'F24',
  },
  {
    entranceId: 'F24-entrance-6',
    lat: 40.666751,
    lon: -73.981851,
    stopId: 'F24',
  },
  {
    entranceId: 'F24-entrance-1',
    lat: 40.665494,
    lon: -73.979278,
    stopId: 'F24',
  },
  {
    entranceId: 'F24-entrance-0',
    lat: 40.665835,
    lon: -73.979007,
    stopId: 'F24',
  },
  {
    entranceId: 'F01-entrance-11',
    lat: 40.712575,
    lon: -73.784669,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-10',
    lat: 40.712295,
    lon: -73.78428,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-13',
    lat: 40.712483,
    lon: -73.785064,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-12',
    lat: 40.71224,
    lon: -73.784456,
    stopId: 'F01',
  },
  {
    entranceId: 'F01-entrance-14',
    lat: 40.712242,
    lon: -73.784968,
    stopId: 'F01',
  },
  {
    entranceId: 'H02-entrance-1',
    lat: 40.669339,
    lon: -73.834721,
    stopId: 'H02',
  },
  {
    entranceId: 'H02-entrance-0',
    lat: 40.667018,
    lon: -73.83348,
    stopId: 'H02',
  },
  {
    entranceId: 'L01-entrance-0',
    lat: 40.73955,
    lon: -74.002389,
    stopId: 'L01',
  },
  {
    entranceId: 'L01-entrance-1',
    lat: 40.739734,
    lon: -74.002804,
    stopId: 'L01',
  },
  {
    entranceId: 'G16-entrance-1',
    lat: 40.753428,
    lon: -73.906847,
    stopId: 'G16',
  },
  {
    entranceId: 'G16-entrance-0',
    lat: 40.753086,
    lon: -73.907119,
    stopId: 'G16',
  },
  {
    entranceId: '234-entrance-0',
    lat: 40.688235,
    lon: -73.980639,
    stopId: '234',
  },
  {
    entranceId: '234-entrance-2',
    lat: 40.688243,
    lon: -73.980356,
    stopId: '234',
  },
  {
    entranceId: '234-entrance-1',
    lat: 40.688053,
    lon: -73.980506,
    stopId: '234',
  },
  {
    entranceId: '234-entrance-3',
    lat: 40.688085,
    lon: -73.980243,
    stopId: '234',
  },
  {
    entranceId: '630-entrance-6',
    lat: 40.757126,
    lon: -73.971721,
    stopId: '630',
  },
  {
    entranceId: '630-entrance-5',
    lat: 40.756999,
    lon: -73.971803,
    stopId: '630',
  },
  {
    entranceId: '630-entrance-8',
    lat: 40.757059,
    lon: -73.97157,
    stopId: '630',
  },
  {
    entranceId: '630-entrance-7',
    lat: 40.756964,
    lon: -73.971712,
    stopId: '630',
  },
  {
    entranceId: '630-entrance-0',
    lat: 40.757123,
    lon: -73.972117,
    stopId: '630',
  },
  {
    entranceId: '630-entrance-2',
    lat: 40.757157,
    lon: -73.972196,
    stopId: '630',
  },
  {
    entranceId: '630-entrance-1',
    lat: 40.757293,
    lon: -73.972108,
    stopId: '630',
  },
  {
    entranceId: '630-entrance-4',
    lat: 40.756686,
    lon: -73.97251,
    stopId: '630',
  },
  {
    entranceId: '630-entrance-3',
    lat: 40.757298,
    lon: -73.971939,
    stopId: '630',
  },
  {
    entranceId: 'G29-entrance-5',
    lat: 40.711775,
    lon: -73.951043,
    stopId: 'G29',
  },
  {
    entranceId: 'G29-entrance-4',
    lat: 40.71172,
    lon: -73.951412,
    stopId: 'G29',
  },
  {
    entranceId: 'G29-entrance-3',
    lat: 40.713744,
    lon: -73.951455,
    stopId: 'G29',
  },
  {
    entranceId: 'G29-entrance-2',
    lat: 40.713747,
    lon: -73.951768,
    stopId: 'G29',
  },
  {
    entranceId: 'G29-entrance-1',
    lat: 40.713927,
    lon: -73.951737,
    stopId: 'G29',
  },
  {
    entranceId: 'G29-entrance-0',
    lat: 40.71414,
    lon: -73.95135,
    stopId: 'G29',
  },
  {
    entranceId: 'L27-entrance-0',
    lat: 40.659041,
    lon: -73.899409,
    stopId: 'L27',
  },
  {
    entranceId: '101-entrance-1',
    lat: 40.889683,
    lon: -73.897925,
    stopId: '101',
  },
  {
    entranceId: '101-entrance-0',
    lat: 40.889783,
    lon: -73.898432,
    stopId: '101',
  },
  {
    entranceId: '101-entrance-3',
    lat: 40.889946,
    lon: -73.897798,
    stopId: '101',
  },
  {
    entranceId: '101-entrance-2',
    lat: 40.890136,
    lon: -73.898257,
    stopId: '101',
  },
  {
    entranceId: '610-entrance-2',
    lat: 40.829641,
    lon: -73.874447,
    stopId: '610',
  },
  {
    entranceId: '610-entrance-1',
    lat: 40.829371,
    lon: -73.874731,
    stopId: '610',
  },
  {
    entranceId: '610-entrance-0',
    lat: 40.829517,
    lon: -73.87486,
    stopId: '610',
  },
  {
    entranceId: '220-entrance-3',
    lat: 40.816873,
    lon: -73.907379,
    stopId: '220',
  },
  {
    entranceId: '220-entrance-2',
    lat: 40.816589,
    lon: -73.907893,
    stopId: '220',
  },
  {
    entranceId: '220-entrance-1',
    lat: 40.816349,
    lon: -73.907836,
    stopId: '220',
  },
  {
    entranceId: '220-entrance-0',
    lat: 40.816201,
    lon: -73.907942,
    stopId: '220',
  },
  {
    entranceId: 'B06-entrance-0',
    lat: 40.759016,
    lon: -73.953505,
    stopId: 'B06',
  },
  {
    entranceId: '504-entrance-0',
    lat: 40.858804,
    lon: -73.855515,
    stopId: '504',
  },
  {
    entranceId: 'F05-entrance-2',
    lat: 40.708911,
    lon: -73.82014,
    stopId: 'F05',
  },
  {
    entranceId: 'F05-entrance-1',
    lat: 40.708108,
    lon: -73.820055,
    stopId: 'F05',
  },
  {
    entranceId: 'F05-entrance-0',
    lat: 40.709478,
    lon: -73.819639,
    stopId: 'F05',
  },
  {
    entranceId: 'D26-entrance-1',
    lat: 40.662482,
    lon: -73.962285,
    stopId: 'D26',
  },
  {
    entranceId: 'D26-entrance-0',
    lat: 40.660792,
    lon: -73.962109,
    stopId: 'D26',
  },
  {
    entranceId: 'D26-entrance-2',
    lat: 40.662727,
    lon: -73.962249,
    stopId: 'D26',
  },
  {
    entranceId: 'A10-entrance-1',
    lat: 40.835278,
    lon: -73.939625,
    stopId: 'A10',
  },
  {
    entranceId: 'A10-entrance-0',
    lat: 40.835192,
    lon: -73.940146,
    stopId: 'A10',
  },
  {
    entranceId: 'A10-entrance-2',
    lat: 40.835936,
    lon: -73.940137,
    stopId: 'A10',
  },
  {
    entranceId: '628-entrance-1',
    lat: 40.767817,
    lon: -73.964355,
    stopId: '628',
  },
  {
    entranceId: '628-entrance-0',
    lat: 40.76787,
    lon: -73.964244,
    stopId: '628',
  },
  {
    entranceId: '628-entrance-4',
    lat: 40.767863,
    lon: -73.963898,
    stopId: '628',
  },
  {
    entranceId: '628-entrance-3',
    lat: 40.767981,
    lon: -73.964178,
    stopId: '628',
  },
  {
    entranceId: '628-entrance-2',
    lat: 40.767703,
    lon: -73.963979,
    stopId: '628',
  },
  {
    entranceId: 'D40-entrance-1',
    lat: 40.577931,
    lon: -73.960524,
    stopId: 'D40',
  },
  {
    entranceId: 'D40-entrance-0',
    lat: 40.577986,
    lon: -73.960258,
    stopId: 'D40',
  },
  {
    entranceId: 'D40-entrance-7',
    lat: 40.577425,
    lon: -73.961719,
    stopId: 'D40',
  },
  {
    entranceId: 'D40-entrance-6',
    lat: 40.577387,
    lon: -73.961912,
    stopId: 'D40',
  },
  {
    entranceId: 'D40-entrance-3',
    lat: 40.577697,
    lon: -73.960438,
    stopId: 'D40',
  },
  {
    entranceId: 'D40-entrance-2',
    lat: 40.577746,
    lon: -73.960231,
    stopId: 'D40',
  },
  {
    entranceId: 'D40-entrance-5',
    lat: 40.577656,
    lon: -73.961792,
    stopId: 'D40',
  },
  {
    entranceId: 'D40-entrance-4',
    lat: 40.577618,
    lon: -73.961994,
    stopId: 'D40',
  },
  {
    entranceId: '609-entrance-1',
    lat: 40.831466,
    lon: -73.86741,
    stopId: '609',
  },
  {
    entranceId: '609-entrance-0',
    lat: 40.831765,
    lon: -73.867078,
    stopId: '609',
  },
  {
    entranceId: '623-entrance-3',
    lat: 40.794811,
    lon: -73.944235,
    stopId: '623',
  },
  {
    entranceId: '623-entrance-2',
    lat: 40.794682,
    lon: -73.944319,
    stopId: '623',
  },
  {
    entranceId: '623-entrance-1',
    lat: 40.794927,
    lon: -73.944502,
    stopId: '623',
  },
  {
    entranceId: '623-entrance-0',
    lat: 40.794804,
    lon: -73.944613,
    stopId: '623',
  },
  {
    entranceId: '114-entrance-2',
    lat: 40.826513,
    lon: -73.950162,
    stopId: '114',
  },
  {
    entranceId: '114-entrance-1',
    lat: 40.826199,
    lon: -73.950392,
    stopId: '114',
  },
  {
    entranceId: '114-entrance-0',
    lat: 40.826657,
    lon: -73.95052,
    stopId: '114',
  },
  {
    entranceId: 'L28-entrance-1',
    lat: 40.651278,
    lon: -73.898862,
    stopId: 'L28',
  },
  {
    entranceId: 'L28-entrance-0',
    lat: 40.651462,
    lon: -73.899191,
    stopId: 'L28',
  },
  {
    entranceId: 'L15-entrance-5',
    lat: 40.707071,
    lon: -73.923877,
    stopId: 'L15',
  },
  {
    entranceId: 'L15-entrance-4',
    lat: 40.707229,
    lon: -73.923725,
    stopId: 'L15',
  },
  {
    entranceId: 'L15-entrance-3',
    lat: 40.70632,
    lon: -73.922062,
    stopId: 'L15',
  },
  {
    entranceId: 'L15-entrance-2',
    lat: 40.706082,
    lon: -73.9223,
    stopId: 'L15',
  },
  {
    entranceId: 'L15-entrance-1',
    lat: 40.706228,
    lon: -73.92191,
    stopId: 'L15',
  },
  {
    entranceId: 'L15-entrance-0',
    lat: 40.705943,
    lon: -73.922036,
    stopId: 'L15',
  },
  {
    entranceId: 'A14-entrance-1',
    lat: 40.818558,
    lon: -73.947543,
    stopId: 'A14',
  },
  {
    entranceId: 'A14-entrance-0',
    lat: 40.817125,
    lon: -73.948225,
    stopId: 'A14',
  },
  {
    entranceId: 'A14-entrance-3',
    lat: 40.816892,
    lon: -73.947818,
    stopId: 'A14',
  },
  {
    entranceId: 'A14-entrance-2',
    lat: 40.817188,
    lon: -73.947665,
    stopId: 'A14',
  },
  {
    entranceId: 'A14-entrance-5',
    lat: 40.818354,
    lon: -73.947213,
    stopId: 'A14',
  },
  {
    entranceId: 'A14-entrance-4',
    lat: 40.816867,
    lon: -73.947953,
    stopId: 'A14',
  },
  {
    entranceId: '702-entrance-0',
    lat: 40.754777,
    lon: -73.845737,
    stopId: '702',
  },
  {
    entranceId: '702-entrance-2',
    lat: 40.754657,
    lon: -73.846014,
    stopId: '702',
  },
  {
    entranceId: '702-entrance-1',
    lat: 40.754394,
    lon: -73.845832,
    stopId: '702',
  },
  {
    entranceId: '702-entrance-3',
    lat: 40.754768,
    lon: -73.845598,
    stopId: '702',
  },
  {
    entranceId: '233-entrance-3',
    lat: 40.690025,
    lon: -73.984248,
    stopId: '233',
  },
  {
    entranceId: '233-entrance-2',
    lat: 40.690972,
    lon: -73.985448,
    stopId: '233',
  },
  {
    entranceId: '233-entrance-1',
    lat: 40.690931,
    lon: -73.98525,
    stopId: '233',
  },
  {
    entranceId: '233-entrance-0',
    lat: 40.690388,
    lon: -73.985299,
    stopId: '233',
  },
  {
    entranceId: '233-entrance-4',
    lat: 40.690433,
    lon: -73.984323,
    stopId: '233',
  },
  {
    entranceId: '412-entrance-2',
    lat: 40.840076,
    lon: -73.917648,
    stopId: '412',
  },
  {
    entranceId: '412-entrance-1',
    lat: 40.839877,
    lon: -73.917791,
    stopId: '412',
  },
  {
    entranceId: '412-entrance-0',
    lat: 40.83996,
    lon: -73.918018,
    stopId: '412',
  },
  {
    entranceId: '201-entrance-0',
    lat: 40.903597,
    lon: -73.85036,
    stopId: '201',
  },
  {
    entranceId: '118-entrance-2',
    lat: 40.804257,
    lon: -73.966422,
    stopId: '118',
  },
  {
    entranceId: '201-entrance-2',
    lat: 40.903509,
    lon: -73.850124,
    stopId: '201',
  },
  {
    entranceId: '201-entrance-1',
    lat: 40.903283,
    lon: -73.850321,
    stopId: '201',
  },
  {
    entranceId: '118-entrance-1',
    lat: 40.803926,
    lon: -73.966647,
    stopId: '118',
  },
  {
    entranceId: '118-entrance-0',
    lat: 40.80439,
    lon: -73.966786,
    stopId: '118',
  },
  {
    entranceId: 'D27-entrance-1',
    lat: 40.654251,
    lon: -73.961909,
    stopId: 'D27',
  },
  {
    entranceId: 'D27-entrance-0',
    lat: 40.655221,
    lon: -73.961516,
    stopId: 'D27',
  },
  {
    entranceId: 'G11-entrance-3',
    lat: 40.733188,
    lon: -73.868605,
    stopId: 'G11',
  },
  {
    entranceId: 'G11-entrance-2',
    lat: 40.733555,
    lon: -73.869588,
    stopId: 'G11',
  },
  {
    entranceId: 'G11-entrance-1',
    lat: 40.733354,
    lon: -73.870983,
    stopId: 'G11',
  },
  {
    entranceId: 'G11-entrance-0',
    lat: 40.733041,
    lon: -73.870334,
    stopId: 'G11',
  },
  {
    entranceId: 'M21-entrance-1',
    lat: 40.714199,
    lon: -74.003187,
    stopId: 'M21',
  },
  {
    entranceId: 'M21-entrance-0',
    lat: 40.712699,
    lon: -74.004099,
    stopId: 'M21',
  },
  {
    entranceId: 'F38-entrance-0',
    lat: 40.590521,
    lon: -73.974242,
    stopId: 'F38',
  },
  {
    entranceId: 'F38-entrance-1',
    lat: 40.590266,
    lon: -73.974051,
    stopId: 'F38',
  },
  {
    entranceId: 'G30-entrance-1',
    lat: 40.70549,
    lon: -73.950554,
    stopId: 'G30',
  },
  {
    entranceId: 'G30-entrance-0',
    lat: 40.705114,
    lon: -73.950062,
    stopId: 'G30',
  },
  {
    entranceId: 'G30-entrance-3',
    lat: 40.705204,
    lon: -73.95032,
    stopId: 'G30',
  },
  {
    entranceId: 'G30-entrance-2',
    lat: 40.705495,
    lon: -73.950089,
    stopId: 'G30',
  },
  {
    entranceId: 'A41-entrance-10',
    lat: 40.691559,
    lon: -73.987208,
    stopId: 'A41',
  },
  {
    entranceId: 'A41-entrance-11',
    lat: 40.6917,
    lon: -73.987461,
    stopId: 'A41',
  },
  {
    entranceId: '715-entrance-0',
    lat: 40.74371,
    lon: -73.924161,
    stopId: '715',
  },
  {
    entranceId: '715-entrance-2',
    lat: 40.743743,
    lon: -73.924417,
    stopId: '715',
  },
  {
    entranceId: '715-entrance-1',
    lat: 40.743882,
    lon: -73.924112,
    stopId: '715',
  },
  {
    entranceId: '715-entrance-3',
    lat: 40.743911,
    lon: -73.924385,
    stopId: '715',
  },
  {
    entranceId: 'G24-entrance-2',
    lat: 40.744552,
    lon: -73.948425,
    stopId: 'G24',
  },
  {
    entranceId: 'G24-entrance-1',
    lat: 40.744771,
    lon: -73.948658,
    stopId: 'G24',
  },
  {
    entranceId: 'G24-entrance-0',
    lat: 40.744625,
    lon: -73.949117,
    stopId: 'G24',
  },
  {
    entranceId: 'A27-entrance-1',
    lat: 40.755989,
    lon: -73.991049,
    stopId: 'A27',
  },
  {
    entranceId: 'A27-entrance-0',
    lat: 40.757286,
    lon: -73.989582,
    stopId: 'A27',
  },
  {
    entranceId: 'A27-entrance-3',
    lat: 40.756101,
    lon: -73.990887,
    stopId: 'A27',
  },
  {
    entranceId: 'A27-entrance-2',
    lat: 40.755801,
    lon: -73.990576,
    stopId: 'A27',
  },
  {
    entranceId: 'A27-entrance-5',
    lat: 40.758401,
    lon: -73.988673,
    stopId: 'A27',
  },
  {
    entranceId: 'A27-entrance-4',
    lat: 40.758567,
    lon: -73.989053,
    stopId: 'A27',
  },
  {
    entranceId: 'A27-entrance-7',
    lat: 40.757929,
    lon: -73.989547,
    stopId: 'A27',
  },
  {
    entranceId: 'A27-entrance-6',
    lat: 40.758685,
    lon: -73.98899,
    stopId: 'A27',
  },
  {
    entranceId: 'A27-entrance-8',
    lat: 40.757463,
    lon: -73.989984,
    stopId: 'A27',
  },
  {
    entranceId: 'R35-entrance-1',
    lat: 40.660489,
    lon: -73.99822,
    stopId: 'R35',
  },
  {
    entranceId: 'R35-entrance-0',
    lat: 40.660323,
    lon: -73.997952,
    stopId: 'R35',
  },
  {
    entranceId: '418-entrance-2',
    lat: 40.710355,
    lon: -74.009661,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-1',
    lat: 40.709672,
    lon: -74.009996,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-4',
    lat: 40.711039,
    lon: -74.009218,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-3',
    lat: 40.71104,
    lon: -74.009523,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-0',
    lat: 40.709778,
    lon: -74.010224,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-9',
    lat: 40.710652,
    lon: -74.00897,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-6',
    lat: 40.710652,
    lon: -74.00897,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-5',
    lat: 40.711171,
    lon: -74.009519,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-8',
    lat: 40.710652,
    lon: -74.00897,
    stopId: '418',
  },
  {
    entranceId: '418-entrance-7',
    lat: 40.710652,
    lon: -74.00897,
    stopId: '418',
  },
  {
    entranceId: 'R28-entrance-1',
    lat: 40.694232,
    lon: -73.992635,
    stopId: 'R28',
  },
  {
    entranceId: 'R28-entrance-0',
    lat: 40.694416,
    lon: -73.992619,
    stopId: 'R28',
  },
  {
    entranceId: 'R28-entrance-2',
    lat: 40.694475,
    lon: -73.99283,
    stopId: 'R28',
  },
  {
    entranceId: '246-entrance-2',
    lat: 40.640743,
    lon: -73.948597,
    stopId: '246',
  },
  {
    entranceId: '246-entrance-1',
    lat: 40.639883,
    lon: -73.948701,
    stopId: '246',
  },
  {
    entranceId: '246-entrance-0',
    lat: 40.639924,
    lon: -73.948161,
    stopId: '246',
  },
  {
    entranceId: 'D14-entrance-3',
    lat: 40.763388,
    lon: -73.982495,
    stopId: 'D14',
  },
  {
    entranceId: 'D14-entrance-2',
    lat: 40.763141,
    lon: -73.982666,
    stopId: 'D14',
  },
  {
    entranceId: 'D14-entrance-1',
    lat: 40.762793,
    lon: -73.98169,
    stopId: 'D14',
  },
  {
    entranceId: 'D14-entrance-0',
    lat: 40.762937,
    lon: -73.981578,
    stopId: 'D14',
  },
  {
    entranceId: 'A43-entrance-1',
    lat: 40.685559,
    lon: -73.9729,
    stopId: 'A43',
  },
  {
    entranceId: 'A43-entrance-0',
    lat: 40.685789,
    lon: -73.97276,
    stopId: 'A43',
  },
  {
    entranceId: 'A43-entrance-3',
    lat: 40.6857,
    lon: -73.973526,
    stopId: 'A43',
  },
  {
    entranceId: 'A43-entrance-2',
    lat: 40.685969,
    lon: -73.973021,
    stopId: 'A43',
  },
  {
    entranceId: 'A43-entrance-5',
    lat: 40.686041,
    lon: -73.974278,
    stopId: 'A43',
  },
  {
    entranceId: 'A43-entrance-4',
    lat: 40.686077,
    lon: -73.973308,
    stopId: 'A43',
  },
  {
    entranceId: 'A43-entrance-7',
    lat: 40.686119,
    lon: -73.974517,
    stopId: 'A43',
  },
  {
    entranceId: 'A43-entrance-6',
    lat: 40.686409,
    lon: -73.974347,
    stopId: 'A43',
  },
  {
    entranceId: 'A43-entrance-8',
    lat: 40.686553,
    lon: -73.974606,
    stopId: 'A43',
  },
  {
    entranceId: '134-entrance-7',
    lat: 40.728686,
    lon: -74.005517,
    stopId: '134',
  },
  {
    entranceId: '134-entrance-6',
    lat: 40.728561,
    lon: -74.00554,
    stopId: '134',
  },
  {
    entranceId: '134-entrance-5',
    lat: 40.727983,
    lon: -74.00565,
    stopId: '134',
  },
  {
    entranceId: '134-entrance-4',
    lat: 40.72784,
    lon: -74.005681,
    stopId: '134',
  },
  {
    entranceId: '134-entrance-3',
    lat: 40.727989,
    lon: -74.005258,
    stopId: '134',
  },
  {
    entranceId: '134-entrance-2',
    lat: 40.727739,
    lon: -74.005305,
    stopId: '134',
  },
  {
    entranceId: '134-entrance-1',
    lat: 40.728656,
    lon: -74.005086,
    stopId: '134',
  },
  {
    entranceId: '134-entrance-0',
    lat: 40.728472,
    lon: -74.005188,
    stopId: '134',
  },
  {
    entranceId: 'B19-entrance-2',
    lat: 40.607897,
    lon: -74.001628,
    stopId: 'B19',
  },
  {
    entranceId: 'B19-entrance-1',
    lat: 40.607723,
    lon: -74.001894,
    stopId: 'B19',
  },
  {
    entranceId: 'B19-entrance-0',
    lat: 40.607602,
    lon: -74.001491,
    stopId: 'B19',
  },
  {
    entranceId: 'M01-entrance-0',
    lat: 40.712082,
    lon: -73.88954,
    stopId: 'M01',
  },
  {
    entranceId: 'J14-entrance-0',
    lat: 40.695033,
    lon: -73.843298,
    stopId: 'J14',
  },
  {
    entranceId: 'J14-entrance-1',
    lat: 40.695267,
    lon: -73.843223,
    stopId: 'J14',
  },
  {
    entranceId: 'R15-entrance-5',
    lat: 40.760514,
    lon: -73.983466,
    stopId: 'R15',
  },
  {
    entranceId: 'R15-entrance-4',
    lat: 40.760323,
    lon: -73.983486,
    stopId: 'R15',
  },
  {
    entranceId: 'R15-entrance-7',
    lat: 40.760602,
    lon: -73.983863,
    stopId: 'R15',
  },
  {
    entranceId: 'R15-entrance-6',
    lat: 40.760489,
    lon: -73.983944,
    stopId: 'R15',
  },
  {
    entranceId: 'A07-entrance-1',
    lat: 40.847944,
    lon: -73.939679,
    stopId: 'A07',
  },
  {
    entranceId: 'R15-entrance-1',
    lat: 40.759426,
    lon: -73.984871,
    stopId: 'R15',
  },
  {
    entranceId: 'A07-entrance-0',
    lat: 40.847809,
    lon: -73.939807,
    stopId: 'A07',
  },
  {
    entranceId: 'R15-entrance-0',
    lat: 40.759369,
    lon: -73.984739,
    stopId: 'R15',
  },
  {
    entranceId: 'R15-entrance-3',
    lat: 40.759302,
    lon: -73.984395,
    stopId: 'R15',
  },
  {
    entranceId: 'R15-entrance-2',
    lat: 40.759058,
    lon: -73.984468,
    stopId: 'R15',
  },
  {
    entranceId: 'A07-entrance-5',
    lat: 40.846407,
    lon: -73.939861,
    stopId: 'A07',
  },
  {
    entranceId: 'A07-entrance-4',
    lat: 40.846352,
    lon: -73.940287,
    stopId: 'A07',
  },
  {
    entranceId: 'A07-entrance-3',
    lat: 40.847992,
    lon: -73.939564,
    stopId: 'A07',
  },
  {
    entranceId: 'A07-entrance-2',
    lat: 40.847859,
    lon: -73.939303,
    stopId: 'A07',
  },
  {
    entranceId: 'L02-entrance-1',
    lat: 40.737149,
    lon: -73.996578,
    stopId: 'L02',
  },
  {
    entranceId: 'L02-entrance-0',
    lat: 40.737197,
    lon: -73.996704,
    stopId: 'L02',
  },
  {
    entranceId: 'H03-entrance-1',
    lat: 40.66016,
    lon: -73.830372,
    stopId: 'H03',
  },
  {
    entranceId: 'H03-entrance-0',
    lat: 40.660372,
    lon: -73.830369,
    stopId: 'H03',
  },
  {
    entranceId: 'F18-entrance-0',
    lat: 40.70138,
    lon: -73.986655,
    stopId: 'F18',
  },
  {
    entranceId: 'R08-entrance-1',
    lat: 40.752491,
    lon: -73.932936,
    stopId: 'R08',
  },
  {
    entranceId: 'R08-entrance-0',
    lat: 40.752901,
    lon: -73.932903,
    stopId: 'R08',
  },
  {
    entranceId: 'A50-entrance-5',
    lat: 40.678142,
    lon: -73.910875,
    stopId: 'A50',
  },
  {
    entranceId: 'A50-entrance-4',
    lat: 40.678017,
    lon: -73.910661,
    stopId: 'A50',
  },
  {
    entranceId: 'A50-entrance-3',
    lat: 40.67846,
    lon: -73.910971,
    stopId: 'A50',
  },
  {
    entranceId: 'A50-entrance-2',
    lat: 40.678513,
    lon: -73.910773,
    stopId: 'A50',
  },
  {
    entranceId: 'A50-entrance-1',
    lat: 40.678317,
    lon: -73.913651,
    stopId: 'A50',
  },
  {
    entranceId: 'A50-entrance-0',
    lat: 40.678594,
    lon: -73.913732,
    stopId: 'A50',
  },
  {
    entranceId: 'A63-entrance-1',
    lat: 40.68212,
    lon: -73.836836,
    stopId: 'A63',
  },
  {
    entranceId: 'A63-entrance-0',
    lat: 40.681994,
    lon: -73.836742,
    stopId: 'A63',
  },
  {
    entranceId: 'D07-entrance-2',
    lat: 40.849968,
    lon: -73.905226,
    stopId: 'D07',
  },
  {
    entranceId: 'D07-entrance-1',
    lat: 40.850124,
    lon: -73.905841,
    stopId: 'D07',
  },
  {
    entranceId: 'D07-entrance-4',
    lat: 40.851176,
    lon: -73.904263,
    stopId: 'D07',
  },
  {
    entranceId: 'D07-entrance-3',
    lat: 40.851052,
    lon: -73.905093,
    stopId: 'D07',
  },
  {
    entranceId: 'D07-entrance-0',
    lat: 40.849864,
    lon: -73.906084,
    stopId: 'D07',
  },
  {
    entranceId: 'Q03-entrance-1',
    lat: 40.769105,
    lon: -73.958609,
    stopId: 'Q03',
  },
  {
    entranceId: 'Q03-entrance-0',
    lat: 40.769105,
    lon: -73.958609,
    stopId: 'Q03',
  },
  {
    entranceId: 'Q03-entrance-3',
    lat: 40.767058,
    lon: -73.959971,
    stopId: 'Q03',
  },
  {
    entranceId: 'Q03-entrance-2',
    lat: 40.769105,
    lon: -73.958609,
    stopId: 'Q03',
  },
  {
    entranceId: 'Q03-entrance-5',
    lat: 40.767058,
    lon: -73.959971,
    stopId: 'Q03',
  },
  {
    entranceId: 'Q03-entrance-4',
    lat: 40.767058,
    lon: -73.959971,
    stopId: 'Q03',
  },
  {
    entranceId: 'Q03-entrance-7',
    lat: 40.767241,
    lon: -73.95938,
    stopId: 'Q03',
  },
  {
    entranceId: 'Q03-entrance-6',
    lat: 40.767241,
    lon: -73.95938,
    stopId: 'Q03',
  },
  {
    entranceId: 'Q03-entrance-9',
    lat: 40.767059,
    lon: -73.959505,
    stopId: 'Q03',
  },
  {
    entranceId: 'Q03-entrance-8',
    lat: 40.767059,
    lon: -73.959505,
    stopId: 'Q03',
  },
  {
    entranceId: '253-entrance-1',
    lat: 40.662703,
    lon: -73.909033,
    stopId: '253',
  },
  {
    entranceId: '253-entrance-0',
    lat: 40.662401,
    lon: -73.908809,
    stopId: '253',
  },
  {
    entranceId: 'N06-entrance-0',
    lat: 40.617341,
    lon: -73.98484,
    stopId: 'N06',
  },
  {
    entranceId: '245-entrance-1',
    lat: 40.645016,
    lon: -73.949213,
    stopId: '245',
  },
  {
    entranceId: '245-entrance-0',
    lat: 40.645046,
    lon: -73.948684,
    stopId: '245',
  },
  {
    entranceId: '708-entrance-0',
    lat: 40.748318,
    lon: -73.876432,
    stopId: '708',
  },
  {
    entranceId: '708-entrance-2',
    lat: 40.748582,
    lon: -73.876591,
    stopId: '708',
  },
  {
    entranceId: '708-entrance-1',
    lat: 40.74859,
    lon: -73.876461,
    stopId: '708',
  },
  {
    entranceId: 'H09-entrance-3',
    lat: 40.595469,
    lon: -73.767919,
    stopId: 'H09',
  },
  {
    entranceId: 'H09-entrance-2',
    lat: 40.595445,
    lon: -73.768313,
    stopId: 'H09',
  },
  {
    entranceId: 'H09-entrance-1',
    lat: 40.595297,
    lon: -73.768206,
    stopId: 'H09',
  },
  {
    entranceId: 'H09-entrance-0',
    lat: 40.595617,
    lon: -73.768032,
    stopId: 'H09',
  },
  {
    entranceId: '204-entrance-1',
    lat: 40.898323,
    lon: -73.854288,
    stopId: '204',
  },
  {
    entranceId: '204-entrance-0',
    lat: 40.89862,
    lon: -73.854316,
    stopId: '204',
  },
  {
    entranceId: 'J29-entrance-0',
    lat: 40.685847,
    lon: -73.915807,
    stopId: 'J29',
  },
  {
    entranceId: 'J29-entrance-1',
    lat: 40.685946,
    lon: -73.915713,
    stopId: 'J29',
  },
  {
    entranceId: 'L08-entrance-3',
    lat: 40.716991,
    lon: -73.956257,
    stopId: 'L08',
  },
  {
    entranceId: 'L08-entrance-2',
    lat: 40.716888,
    lon: -73.956367,
    stopId: 'L08',
  },
  {
    entranceId: 'L08-entrance-1',
    lat: 40.717821,
    lon: -73.957568,
    stopId: 'L08',
  },
  {
    entranceId: 'L08-entrance-0',
    lat: 40.717714,
    lon: -73.957695,
    stopId: 'L08',
  },
  {
    entranceId: 'M18-entrance-1',
    lat: 40.718551,
    lon: -73.98765,
    stopId: 'M18',
  },
  {
    entranceId: 'M18-entrance-0',
    lat: 40.71841,
    lon: -73.987181,
    stopId: 'M18',
  },
  {
    entranceId: '603-entrance-0',
    lat: 40.843935,
    lon: -73.836386,
    stopId: '603',
  },
  {
    entranceId: '603-entrance-1',
    lat: 40.843609,
    lon: -73.836478,
    stopId: '603',
  },
  {
    entranceId: 'L13-entrance-1',
    lat: 40.707819,
    lon: -73.939749,
    stopId: 'L13',
  },
  {
    entranceId: 'L13-entrance-0',
    lat: 40.707723,
    lon: -73.940044,
    stopId: 'L13',
  },
  {
    entranceId: 'D20-entrance-3',
    lat: 40.733259,
    lon: -73.999615,
    stopId: 'D20',
  },
  {
    entranceId: 'D20-entrance-2',
    lat: 40.733067,
    lon: -73.999728,
    stopId: 'D20',
  },
  {
    entranceId: 'D20-entrance-1',
    lat: 40.731091,
    lon: -74.001127,
    stopId: 'D20',
  },
  {
    entranceId: 'D20-entrance-0',
    lat: 40.731133,
    lon: -74.001534,
    stopId: 'D20',
  },
  {
    entranceId: 'D20-entrance-4',
    lat: 40.733072,
    lon: -74.000192,
    stopId: 'D20',
  },
  {
    entranceId: 'L26-entrance-1',
    lat: 40.663749,
    lon: -73.900482,
    stopId: 'L26',
  },
  {
    entranceId: 'L26-entrance-0',
    lat: 40.663742,
    lon: -73.900522,
    stopId: 'L26',
  },
  {
    entranceId: 'N05-entrance-0',
    lat: 40.620062,
    lon: -73.98941,
    stopId: 'N05',
  },
  {
    entranceId: 'N05-entrance-1',
    lat: 40.621306,
    lon: -73.991469,
    stopId: 'N05',
  },
  {
    entranceId: 'R04-entrance-1',
    lat: 40.7667,
    lon: -73.921406,
    stopId: 'R04',
  },
  {
    entranceId: 'R04-entrance-0',
    lat: 40.766787,
    lon: -73.921629,
    stopId: 'R04',
  },
  {
    entranceId: 'R04-entrance-3',
    lat: 40.766963,
    lon: -73.921192,
    stopId: 'R04',
  },
  {
    entranceId: 'R04-entrance-2',
    lat: 40.767052,
    lon: -73.921409,
    stopId: 'R04',
  },
  {
    entranceId: '112-entrance-1',
    lat: 40.841024,
    lon: -73.940083,
    stopId: '112',
  },
  {
    entranceId: '112-entrance-0',
    lat: 40.840859,
    lon: -73.940209,
    stopId: '112',
  },
  {
    entranceId: '112-entrance-2',
    lat: 40.840717,
    lon: -73.93972,
    stopId: '112',
  },
  {
    entranceId: 'B08-entrance-3',
    lat: 40.764866,
    lon: -73.966422,
    stopId: 'B08',
  },
  {
    entranceId: 'B08-entrance-2',
    lat: 40.764721,
    lon: -73.966552,
    stopId: 'B08',
  },
  {
    entranceId: 'B08-entrance-1',
    lat: 40.764881,
    lon: -73.966411,
    stopId: 'B08',
  },
  {
    entranceId: 'B08-entrance-0',
    lat: 40.764874,
    lon: -73.966431,
    stopId: 'B08',
  },
  {
    entranceId: 'B08-entrance-7',
    lat: 40.763893,
    lon: -73.964566,
    stopId: 'B08',
  },
  {
    entranceId: 'B08-entrance-6',
    lat: 40.763893,
    lon: -73.964566,
    stopId: 'B08',
  },
  {
    entranceId: 'B08-entrance-5',
    lat: 40.764063,
    lon: -73.96496,
    stopId: 'B08',
  },
  {
    entranceId: 'B08-entrance-4',
    lat: 40.764145,
    lon: -73.964527,
    stopId: 'B08',
  },
  {
    entranceId: 'B08-entrance-9',
    lat: 40.764854,
    lon: -73.966425,
    stopId: 'B08',
  },
  {
    entranceId: 'B08-entrance-8',
    lat: 40.764741,
    lon: -73.966578,
    stopId: 'B08',
  },
  {
    entranceId: '631-entrance-1',
    lat: 40.751653,
    lon: -73.977753,
    stopId: '631',
  },
  {
    entranceId: '631-entrance-0',
    lat: 40.751741,
    lon: -73.977691,
    stopId: '631',
  },
  {
    entranceId: '631-entrance-5',
    lat: 40.751801,
    lon: -73.975294,
    stopId: '631',
  },
  {
    entranceId: '631-entrance-4',
    lat: 40.751721,
    lon: -73.977197,
    stopId: '631',
  },
  {
    entranceId: '631-entrance-3',
    lat: 40.751647,
    lon: -73.977382,
    stopId: '631',
  },
  {
    entranceId: '631-entrance-2',
    lat: 40.751352,
    lon: -73.976396,
    stopId: '631',
  },
  {
    entranceId: '631-entrance-7',
    lat: 40.751701,
    lon: -73.976087,
    stopId: '631',
  },
  {
    entranceId: '631-entrance-6',
    lat: 40.751345,
    lon: -73.975409,
    stopId: '631',
  },
  {
    entranceId: '232-entrance-0',
    lat: 40.693838,
    lon: -73.990119,
    stopId: '232',
  },
  {
    entranceId: '232-entrance-2',
    lat: 40.693728,
    lon: -73.99063,
    stopId: '232',
  },
  {
    entranceId: '232-entrance-1',
    lat: 40.693569,
    lon: -73.990599,
    stopId: '232',
  },
  {
    entranceId: '411-entrance-1',
    lat: 40.844389,
    lon: -73.914578,
    stopId: '411',
  },
  {
    entranceId: '411-entrance-0',
    lat: 40.844702,
    lon: -73.91465,
    stopId: '411',
  },
  {
    entranceId: 'R22-entrance-3',
    lat: 40.724344,
    lon: -73.997875,
    stopId: 'R22',
  },
  {
    entranceId: 'R22-entrance-2',
    lat: 40.72426,
    lon: -73.997957,
    stopId: 'R22',
  },
  {
    entranceId: 'R22-entrance-1',
    lat: 40.72422,
    lon: -73.997633,
    stopId: 'R22',
  },
  {
    entranceId: 'R22-entrance-0',
    lat: 40.724124,
    lon: -73.997686,
    stopId: 'R22',
  },
  {
    entranceId: 'A21-entrance-3',
    lat: 40.780319,
    lon: -73.973179,
    stopId: 'A21',
  },
  {
    entranceId: 'A21-entrance-2',
    lat: 40.780895,
    lon: -73.972918,
    stopId: 'A21',
  },
  {
    entranceId: 'A21-entrance-1',
    lat: 40.782254,
    lon: -73.971741,
    stopId: 'A21',
  },
  {
    entranceId: 'A21-entrance-0',
    lat: 40.781976,
    lon: -73.972027,
    stopId: 'A21',
  },
  {
    entranceId: 'J21-entrance-1',
    lat: 40.681643,
    lon: -73.878986,
    stopId: 'J21',
  },
  {
    entranceId: 'J21-entrance-0',
    lat: 40.68178,
    lon: -73.879012,
    stopId: 'J21',
  },
  {
    entranceId: 'A49-entrance-2',
    lat: 40.679164,
    lon: -73.92187,
    stopId: 'A49',
  },
  {
    entranceId: 'A49-entrance-1',
    lat: 40.678984,
    lon: -73.921442,
    stopId: 'A49',
  },
  {
    entranceId: 'A49-entrance-0',
    lat: 40.678702,
    lon: -73.921552,
    stopId: 'A49',
  },
  {
    entranceId: 'Q04-entrance-2',
    lat: 40.77602,
    lon: -73.95292,
    stopId: 'Q04',
  },
  {
    entranceId: 'Q04-entrance-1',
    lat: 40.77602,
    lon: -73.95292,
    stopId: 'Q04',
  },
  {
    entranceId: 'Q04-entrance-0',
    lat: 40.77602,
    lon: -73.95292,
    stopId: 'Q04',
  },
  {
    entranceId: 'Q04-entrance-6',
    lat: 40.77773,
    lon: -73.951165,
    stopId: 'Q04',
  },
  {
    entranceId: 'Q04-entrance-5',
    lat: 40.77773,
    lon: -73.951165,
    stopId: 'Q04',
  },
  {
    entranceId: 'Q04-entrance-4',
    lat: 40.777543,
    lon: -73.950737,
    stopId: 'Q04',
  },
  {
    entranceId: 'Q04-entrance-3',
    lat: 40.777543,
    lon: -73.950737,
    stopId: 'Q04',
  },
  {
    entranceId: 'M05-entrance-1',
    lat: 40.704443,
    lon: -73.902389,
    stopId: 'M05',
  },
  {
    entranceId: 'M05-entrance-0',
    lat: 40.704729,
    lon: -73.902243,
    stopId: 'M05',
  },
  {
    entranceId: 'D33-entrance-2',
    lat: 40.625363,
    lon: -73.960654,
    stopId: 'D33',
  },
  {
    entranceId: 'D33-entrance-1',
    lat: 40.625307,
    lon: -73.960969,
    stopId: 'D33',
  },
  {
    entranceId: 'D33-entrance-0',
    lat: 40.62513,
    lon: -73.960792,
    stopId: 'D33',
  },
  {
    entranceId: 'F12-entrance-0',
    lat: 40.760422,
    lon: -73.976146,
    stopId: 'F12',
  },
  {
    entranceId: 'F12-entrance-4',
    lat: 40.759556,
    lon: -73.974068,
    stopId: 'F12',
  },
  {
    entranceId: 'F12-entrance-3',
    lat: 40.759721,
    lon: -73.973858,
    stopId: 'F12',
  },
  {
    entranceId: 'F12-entrance-2',
    lat: 40.760267,
    lon: -73.975638,
    stopId: 'F12',
  },
  {
    entranceId: 'F12-entrance-1',
    lat: 40.760373,
    lon: -73.975562,
    stopId: 'F12',
  },
  {
    entranceId: '409-entrance-2',
    lat: 40.85367,
    lon: -73.907654,
    stopId: '409',
  },
  {
    entranceId: '409-entrance-1',
    lat: 40.853181,
    lon: -73.907782,
    stopId: '409',
  },
  {
    entranceId: '409-entrance-0',
    lat: 40.853313,
    lon: -73.907977,
    stopId: '409',
  },
  {
    entranceId: '409-entrance-3',
    lat: 40.853596,
    lon: -73.907393,
    stopId: '409',
  },
  {
    entranceId: 'B13-entrance-3',
    lat: 40.641379,
    lon: -73.994188,
    stopId: 'B13',
  },
  {
    entranceId: 'B13-entrance-2',
    lat: 40.64136,
    lon: -73.994411,
    stopId: 'B13',
  },
  {
    entranceId: 'B13-entrance-1',
    lat: 40.64018,
    lon: -73.99422,
    stopId: 'B13',
  },
  {
    entranceId: 'B13-entrance-0',
    lat: 40.640185,
    lon: -73.994423,
    stopId: 'B13',
  },
  {
    entranceId: 'D05-entrance-4',
    lat: 40.862296,
    lon: -73.896885,
    stopId: 'D05',
  },
  {
    entranceId: 'D05-entrance-3',
    lat: 40.861322,
    lon: -73.897355,
    stopId: 'D05',
  },
  {
    entranceId: 'D05-entrance-5',
    lat: 40.862608,
    lon: -73.896662,
    stopId: 'D05',
  },
  {
    entranceId: 'D05-entrance-0',
    lat: 40.861401,
    lon: -73.898098,
    stopId: 'D05',
  },
  {
    entranceId: 'D05-entrance-2',
    lat: 40.861592,
    lon: -73.898019,
    stopId: 'D05',
  },
  {
    entranceId: 'D05-entrance-1',
    lat: 40.861147,
    lon: -73.897424,
    stopId: 'D05',
  },
  {
    entranceId: '217-entrance-1',
    lat: 40.824358,
    lon: -73.892724,
    stopId: '217',
  },
  {
    entranceId: '217-entrance-0',
    lat: 40.824177,
    lon: -73.893114,
    stopId: '217',
  },
  {
    entranceId: '217-entrance-2',
    lat: 40.824118,
    lon: -73.892731,
    stopId: '217',
  },
  {
    entranceId: 'F25-entrance-5',
    lat: 40.6614,
    lon: -73.979762,
    stopId: 'F25',
  },
  {
    entranceId: 'F25-entrance-4',
    lat: 40.661287,
    lon: -73.979395,
    stopId: 'F25',
  },
  {
    entranceId: 'F25-entrance-3',
    lat: 40.660763,
    lon: -73.978992,
    stopId: 'F25',
  },
  {
    entranceId: 'F25-entrance-2',
    lat: 40.660705,
    lon: -73.979522,
    stopId: 'F25',
  },
  {
    entranceId: 'F25-entrance-1',
    lat: 40.660198,
    lon: -73.979787,
    stopId: 'F25',
  },
  {
    entranceId: 'F25-entrance-0',
    lat: 40.659436,
    lon: -73.979951,
    stopId: 'F25',
  },
  {
    entranceId: '629-entrance-4',
    lat: 40.762835,
    lon: -73.967554,
    stopId: '629',
  },
  {
    entranceId: '629-entrance-3',
    lat: 40.762266,
    lon: -73.968479,
    stopId: '629',
  },
  {
    entranceId: '629-entrance-6',
    lat: 40.762986,
    lon: -73.96785,
    stopId: '629',
  },
  {
    entranceId: '629-entrance-5',
    lat: 40.762069,
    lon: -73.968016,
    stopId: '629',
  },
  {
    entranceId: '629-entrance-0',
    lat: 40.762836,
    lon: -73.96804,
    stopId: '629',
  },
  {
    entranceId: '629-entrance-2',
    lat: 40.762414,
    lon: -73.968371,
    stopId: '629',
  },
  {
    entranceId: '629-entrance-1',
    lat: 40.76266,
    lon: -73.967625,
    stopId: '629',
  },
  {
    entranceId: '616-entrance-2',
    lat: 40.80803,
    lon: -73.90772,
    stopId: '616',
  },
  {
    entranceId: '616-entrance-1',
    lat: 40.808322,
    lon: -73.908002,
    stopId: '616',
  },
  {
    entranceId: '616-entrance-3',
    lat: 40.80834,
    lon: -73.907618,
    stopId: '616',
  },
  {
    entranceId: '616-entrance-0',
    lat: 40.808179,
    lon: -73.908065,
    stopId: '616',
  },
  {
    entranceId: 'R42-entrance-1',
    lat: 40.634768,
    lon: -74.023339,
    stopId: 'R42',
  },
  {
    entranceId: 'R42-entrance-0',
    lat: 40.634827,
    lon: -74.02358,
    stopId: 'R42',
  },
  {
    entranceId: 'R42-entrance-2',
    lat: 40.635468,
    lon: -74.023313,
    stopId: 'R42',
  },
  {
    entranceId: 'F04-entrance-3',
    lat: 40.705341,
    lon: -73.81186,
    stopId: 'F04',
  },
  {
    entranceId: 'F04-entrance-2',
    lat: 40.705538,
    lon: -73.809717,
    stopId: 'F04',
  },
  {
    entranceId: 'F04-entrance-1',
    lat: 40.705945,
    lon: -73.809489,
    stopId: 'F04',
  },
  {
    entranceId: 'F04-entrance-0',
    lat: 40.705591,
    lon: -73.809481,
    stopId: 'F04',
  },
  {
    entranceId: 'F04-entrance-4',
    lat: 40.704906,
    lon: -73.811956,
    stopId: 'F04',
  },
  {
    entranceId: '902-entrance-0',
    lat: 40.756476,
    lon: -73.986311,
    stopId: '902',
  },
  {
    entranceId: '503-entrance-1',
    lat: 40.870117,
    lon: -73.845869,
    stopId: '503',
  },
  {
    entranceId: '503-entrance-0',
    lat: 40.870039,
    lon: -73.845724,
    stopId: '503',
  },
  {
    entranceId: '127-entrance-8',
    lat: 40.755334,
    lon: -73.9872,
    stopId: '127',
  },
  {
    entranceId: '127-entrance-5',
    lat: 40.755575,
    lon: -73.986311,
    stopId: '127',
  },
  {
    entranceId: '127-entrance-4',
    lat: 40.755554,
    lon: -73.987679,
    stopId: '127',
  },
  {
    entranceId: '127-entrance-7',
    lat: 40.755248,
    lon: -73.987362,
    stopId: '127',
  },
  {
    entranceId: '127-entrance-6',
    lat: 40.755747,
    lon: -73.986875,
    stopId: '127',
  },
  {
    entranceId: '127-entrance-1',
    lat: 40.756042,
    lon: -73.987242,
    stopId: '127',
  },
  {
    entranceId: '127-entrance-0',
    lat: 40.756241,
    lon: -73.987133,
    stopId: '127',
  },
  {
    entranceId: '127-entrance-3',
    lat: 40.754568,
    lon: -73.987827,
    stopId: '127',
  },
  {
    entranceId: '127-entrance-2',
    lat: 40.754691,
    lon: -73.988067,
    stopId: '127',
  },
  {
    entranceId: '237-entrance-1',
    lat: 40.67511,
    lon: -73.970822,
    stopId: '237',
  },
  {
    entranceId: '237-entrance-0',
    lat: 40.67495,
    lon: -73.97099,
    stopId: '237',
  },
  {
    entranceId: '237-entrance-3',
    lat: 40.675286,
    lon: -73.970825,
    stopId: '237',
  },
  {
    entranceId: '237-entrance-2',
    lat: 40.675142,
    lon: -73.971216,
    stopId: '237',
  },
  {
    entranceId: 'G09-entrance-3',
    lat: 40.726367,
    lon: -73.851592,
    stopId: 'G09',
  },
  {
    entranceId: 'G09-entrance-2',
    lat: 40.72603,
    lon: -73.852186,
    stopId: 'G09',
  },
  {
    entranceId: 'G09-entrance-1',
    lat: 40.727073,
    lon: -73.853226,
    stopId: 'G09',
  },
  {
    entranceId: 'G09-entrance-0',
    lat: 40.726598,
    lon: -73.853534,
    stopId: 'G09',
  },
  {
    entranceId: '125-entrance-1',
    lat: 40.769227,
    lon: -73.9824,
    stopId: '125',
  },
  {
    entranceId: '125-entrance-0',
    lat: 40.768777,
    lon: -73.982106,
    stopId: '125',
  },
  {
    entranceId: '125-entrance-3',
    lat: 40.767654,
    lon: -73.981423,
    stopId: '125',
  },
  {
    entranceId: '125-entrance-2',
    lat: 40.769365,
    lon: -73.982392,
    stopId: '125',
  },
  {
    entranceId: '125-entrance-4',
    lat: 40.767642,
    lon: -73.981235,
    stopId: '125',
  },
  {
    entranceId: 'A36-entrance-8',
    lat: 40.7142,
    lon: -74.008357,
    stopId: 'A36',
  },
  {
    entranceId: 'A36-entrance-7',
    lat: 40.714539,
    lon: -74.008428,
    stopId: 'A36',
  },
  {
    entranceId: 'A36-entrance-6',
    lat: 40.714313,
    lon: -74.008584,
    stopId: 'A36',
  },
  {
    entranceId: 'A36-entrance-1',
    lat: 40.714873,
    lon: -74.008162,
    stopId: 'A36',
  },
  {
    entranceId: 'A36-entrance-0',
    lat: 40.713753,
    lon: -74.009046,
    stopId: 'A36',
  },
  {
    entranceId: 'A36-entrance-5',
    lat: 40.713949,
    lon: -74.00896,
    stopId: 'A36',
  },
  {
    entranceId: 'A36-entrance-4',
    lat: 40.714992,
    lon: -74.007739,
    stopId: 'A36',
  },
  {
    entranceId: 'A36-entrance-3',
    lat: 40.715099,
    lon: -74.007989,
    stopId: 'A36',
  },
  {
    entranceId: 'A36-entrance-2',
    lat: 40.714777,
    lon: -74.007903,
    stopId: 'A36',
  },
  {
    entranceId: '138-entrance-1',
    lat: 40.71085,
    lon: -74.012563,
    stopId: '138',
  },
  {
    entranceId: '138-entrance-0',
    lat: 40.711089,
    lon: -74.012473,
    stopId: '138',
  },
  {
    entranceId: '636-entrance-1',
    lat: 40.729968,
    lon: -73.99141,
    stopId: '636',
  },
  {
    entranceId: '636-entrance-0',
    lat: 40.730084,
    lon: -73.990838,
    stopId: '636',
  },
  {
    entranceId: 'R17-entrance-1',
    lat: 40.749891,
    lon: -73.987495,
    stopId: 'R17',
  },
  {
    entranceId: 'R17-entrance-0',
    lat: 40.74959,
    lon: -73.98762,
    stopId: 'R17',
  },
  {
    entranceId: 'R17-entrance-3',
    lat: 40.748477,
    lon: -73.988288,
    stopId: 'R17',
  },
  {
    entranceId: 'R17-entrance-2',
    lat: 40.748294,
    lon: -73.987976,
    stopId: 'R17',
  },
  {
    entranceId: 'L21-entrance-0',
    lat: 40.6823,
    lon: -73.905149,
    stopId: 'L21',
  },
  {
    entranceId: 'F32-entrance-0',
    lat: 40.620797,
    lon: -73.975479,
    stopId: 'F32',
  },
  {
    entranceId: 'F32-entrance-2',
    lat: 40.621162,
    lon: -73.975467,
    stopId: 'F32',
  },
  {
    entranceId: 'F32-entrance-1',
    lat: 40.620825,
    lon: -73.975138,
    stopId: 'F32',
  },
  {
    entranceId: 'M20-entrance-0',
    lat: 40.717823,
    lon: -74.000326,
    stopId: 'M20',
  },
  {
    entranceId: 'A34-entrance-4',
    lat: 40.71987,
    lon: -74.005038,
    stopId: 'A34',
  },
  {
    entranceId: 'A34-entrance-3',
    lat: 40.721967,
    lon: -74.005133,
    stopId: 'A34',
  },
  {
    entranceId: 'A34-entrance-2',
    lat: 40.721651,
    lon: -74.005634,
    stopId: 'A34',
  },
  {
    entranceId: 'A34-entrance-1',
    lat: 40.720758,
    lon: -74.005399,
    stopId: 'A34',
  },
  {
    entranceId: 'A34-entrance-0',
    lat: 40.720704,
    lon: -74.004999,
    stopId: 'A34',
  },
  {
    entranceId: 'J16-entrance-1',
    lat: 40.692666,
    lon: -73.859098,
    stopId: 'J16',
  },
  {
    entranceId: 'A47-entrance-3',
    lat: 40.679728,
    lon: -73.941362,
    stopId: 'A47',
  },
  {
    entranceId: 'A47-entrance-2',
    lat: 40.679804,
    lon: -73.941119,
    stopId: 'A47',
  },
  {
    entranceId: 'A47-entrance-1',
    lat: 40.680057,
    lon: -73.940483,
    stopId: 'A47',
  },
  {
    entranceId: 'A47-entrance-0',
    lat: 40.68001,
    lon: -73.940247,
    stopId: 'A47',
  },
  {
    entranceId: 'J16-entrance-0',
    lat: 40.692381,
    lon: -73.858975,
    stopId: 'J16',
  },
  {
    entranceId: 'R09-entrance-0',
    lat: 40.750835,
    lon: -73.939671,
    stopId: 'R09',
  },
  {
    entranceId: 'R09-entrance-1',
    lat: 40.750302,
    lon: -73.940245,
    stopId: 'R09',
  },
  {
    entranceId: 'A54-entrance-3',
    lat: 40.673989,
    lon: -73.880869,
    stopId: 'A54',
  },
  {
    entranceId: 'A54-entrance-2',
    lat: 40.674234,
    lon: -73.880927,
    stopId: 'A54',
  },
  {
    entranceId: 'A54-entrance-1',
    lat: 40.674303,
    lon: -73.880749,
    stopId: 'A54',
  },
  {
    entranceId: 'A54-entrance-0',
    lat: 40.67397,
    lon: -73.880667,
    stopId: 'A54',
  },
  {
    entranceId: 'M13-entrance-0',
    lat: 40.704279,
    lon: -73.948324,
    stopId: 'M13',
  },
  {
    entranceId: '219-entrance-4',
    lat: 40.819684,
    lon: -73.901348,
    stopId: '219',
  },
  {
    entranceId: '219-entrance-3',
    lat: 40.819562,
    lon: -73.901498,
    stopId: '219',
  },
  {
    entranceId: '219-entrance-2',
    lat: 40.819368,
    lon: -73.901966,
    stopId: '219',
  },
  {
    entranceId: '219-entrance-1',
    lat: 40.819887,
    lon: -73.901666,
    stopId: '219',
  },
  {
    entranceId: '219-entrance-0',
    lat: 40.819583,
    lon: -73.901958,
    stopId: '219',
  },
  {
    entranceId: 'M13-entrance-2',
    lat: 40.703454,
    lon: -73.946536,
    stopId: 'M13',
  },
  {
    entranceId: 'M13-entrance-1',
    lat: 40.704396,
    lon: -73.948223,
    stopId: 'M13',
  },
  {
    entranceId: 'M13-entrance-3',
    lat: 40.703321,
    lon: -73.946613,
    stopId: 'M13',
  },
  {
    entranceId: 'D13-entrance-1',
    lat: 40.823915,
    lon: -73.945027,
    stopId: 'D13',
  },
  {
    entranceId: 'D13-entrance-0',
    lat: 40.823791,
    lon: -73.944726,
    stopId: 'D13',
  },
  {
    entranceId: 'D13-entrance-3',
    lat: 40.824028,
    lon: -73.944409,
    stopId: 'D13',
  },
  {
    entranceId: 'D13-entrance-2',
    lat: 40.824244,
    lon: -73.944931,
    stopId: 'D13',
  },
  {
    entranceId: 'D13-entrance-5',
    lat: 40.825581,
    lon: -73.943444,
    stopId: 'D13',
  },
  {
    entranceId: 'D13-entrance-4',
    lat: 40.825622,
    lon: -73.943796,
    stopId: 'D13',
  },
  {
    entranceId: '608-entrance-0',
    lat: 40.833225,
    lon: -73.860888,
    stopId: '608',
  },
  {
    entranceId: 'R11-entrance-3',
    lat: 40.762255,
    lon: -73.965918,
    stopId: 'R11',
  },
  {
    entranceId: 'R11-entrance-2',
    lat: 40.761996,
    lon: -73.966105,
    stopId: 'R11',
  },
  {
    entranceId: 'R11-entrance-1',
    lat: 40.762353,
    lon: -73.966155,
    stopId: 'R11',
  },
  {
    entranceId: 'R11-entrance-0',
    lat: 40.762092,
    lon: -73.96634,
    stopId: 'R11',
  },
  {
    entranceId: '132-entrance-7',
    lat: 40.737846,
    lon: -73.99993,
    stopId: '132',
  },
  {
    entranceId: '132-entrance-6',
    lat: 40.737197,
    lon: -74.0004,
    stopId: '132',
  },
  {
    entranceId: '132-entrance-5',
    lat: 40.737388,
    lon: -74.000815,
    stopId: '132',
  },
  {
    entranceId: '132-entrance-4',
    lat: 40.738013,
    lon: -74.000329,
    stopId: '132',
  },
  {
    entranceId: '132-entrance-3',
    lat: 40.738358,
    lon: -73.999656,
    stopId: '132',
  },
  {
    entranceId: '132-entrance-2',
    lat: 40.738585,
    lon: -73.999405,
    stopId: '132',
  },
  {
    entranceId: '132-entrance-1',
    lat: 40.738775,
    lon: -73.999711,
    stopId: '132',
  },
  {
    entranceId: '132-entrance-0',
    lat: 40.738484,
    lon: -73.999914,
    stopId: '132',
  },
  {
    entranceId: 'H12-entrance-3',
    lat: 40.587814,
    lon: -73.814037,
    stopId: 'H12',
  },
  {
    entranceId: 'H12-entrance-2',
    lat: 40.587999,
    lon: -73.814117,
    stopId: 'H12',
  },
  {
    entranceId: 'H12-entrance-1',
    lat: 40.587929,
    lon: -73.813755,
    stopId: 'H12',
  },
  {
    entranceId: 'H12-entrance-0',
    lat: 40.58808,
    lon: -73.813907,
    stopId: 'H12',
  },
  {
    entranceId: 'A03-entrance-6',
    lat: 40.865446,
    lon: -73.927035,
    stopId: 'A03',
  },
  {
    entranceId: 'A03-entrance-3',
    lat: 40.865296,
    lon: -73.927411,
    stopId: 'A03',
  },
  {
    entranceId: 'A03-entrance-2',
    lat: 40.865362,
    lon: -73.928059,
    stopId: 'A03',
  },
  {
    entranceId: 'A03-entrance-5',
    lat: 40.865342,
    lon: -73.926964,
    stopId: 'A03',
  },
  {
    entranceId: 'A03-entrance-4',
    lat: 40.865248,
    lon: -73.927279,
    stopId: 'A03',
  },
  {
    entranceId: 'A03-entrance-1',
    lat: 40.865744,
    lon: -73.927172,
    stopId: 'A03',
  },
  {
    entranceId: 'A03-entrance-0',
    lat: 40.865758,
    lon: -73.927266,
    stopId: 'A03',
  },
  {
    entranceId: 'B15-entrance-1',
    lat: 40.631538,
    lon: -73.995643,
    stopId: 'B15',
  },
  {
    entranceId: 'B15-entrance-0',
    lat: 40.631389,
    lon: -73.995262,
    stopId: 'B15',
  },
  {
    entranceId: 'B15-entrance-2',
    lat: 40.631709,
    lon: -73.995283,
    stopId: 'B15',
  },
  {
    entranceId: '107-entrance-1',
    lat: 40.869793,
    lon: -73.91517,
    stopId: '107',
  },
  {
    entranceId: '107-entrance-0',
    lat: 40.869412,
    lon: -73.915455,
    stopId: '107',
  },
  {
    entranceId: '107-entrance-3',
    lat: 40.869729,
    lon: -73.914954,
    stopId: '107',
  },
  {
    entranceId: '107-entrance-2',
    lat: 40.869315,
    lon: -73.915216,
    stopId: '107',
  },
  {
    entranceId: 'R24-entrance-2',
    lat: 40.713521,
    lon: -74.006697,
    stopId: 'R24',
  },
  {
    entranceId: 'R24-entrance-1',
    lat: 40.713437,
    lon: -74.00676,
    stopId: 'R24',
  },
  {
    entranceId: 'R24-entrance-0',
    lat: 40.71311,
    lon: -74.007045,
    stopId: 'R24',
  },
  {
    entranceId: 'A41-entrance-5',
    lat: 40.693153,
    lon: -73.987408,
    stopId: 'A41',
  },
  {
    entranceId: 'A41-entrance-4',
    lat: 40.693491,
    lon: -73.987089,
    stopId: 'A41',
  },
  {
    entranceId: 'A41-entrance-7',
    lat: 40.693243,
    lon: -73.987481,
    stopId: 'A41',
  },
  {
    entranceId: 'A41-entrance-6',
    lat: 40.693153,
    lon: -73.987484,
    stopId: 'A41',
  },
  {
    entranceId: 'A41-entrance-1',
    lat: 40.692336,
    lon: -73.987494,
    stopId: 'A41',
  },
  {
    entranceId: 'A41-entrance-0',
    lat: 40.692393,
    lon: -73.987443,
    stopId: 'A41',
  },
  {
    entranceId: 'A41-entrance-3',
    lat: 40.693243,
    lon: -73.987402,
    stopId: 'A41',
  },
  {
    entranceId: 'A41-entrance-2',
    lat: 40.692394,
    lon: -73.987491,
    stopId: 'A41',
  },
  {
    entranceId: 'A41-entrance-9',
    lat: 40.692329,
    lon: -73.987167,
    stopId: 'A41',
  },
  {
    entranceId: 'A41-entrance-8',
    lat: 40.692397,
    lon: -73.987162,
    stopId: 'A41',
  },
  {
    entranceId: 'A16-entrance-1',
    lat: 40.804639,
    lon: -73.955559,
    stopId: 'A16',
  },
  {
    entranceId: 'A16-entrance-0',
    lat: 40.804312,
    lon: -73.955643,
    stopId: 'A16',
  },
  {
    entranceId: 'A16-entrance-3',
    lat: 40.804172,
    lon: -73.955131,
    stopId: 'A16',
  },
  {
    entranceId: 'A16-entrance-2',
    lat: 40.804428,
    lon: -73.955024,
    stopId: 'A16',
  },
  {
    entranceId: '206-entrance-1',
    lat: 40.88766,
    lon: -73.860444,
    stopId: '206',
  },
  {
    entranceId: '206-entrance-0',
    lat: 40.887709,
    lon: -73.860666,
    stopId: '206',
  },
  {
    entranceId: '206-entrance-2',
    lat: 40.887972,
    lon: -73.860517,
    stopId: '206',
  },
  {
    entranceId: 'D41-entrance-5',
    lat: 40.576487,
    lon: -73.969243,
    stopId: 'D41',
  },
  {
    entranceId: 'D41-entrance-4',
    lat: 40.576507,
    lon: -73.969445,
    stopId: 'D41',
  },
  {
    entranceId: 'D41-entrance-3',
    lat: 40.576234,
    lon: -73.967561,
    stopId: 'D41',
  },
  {
    entranceId: 'D41-entrance-2',
    lat: 40.576209,
    lon: -73.967875,
    stopId: 'D41',
  },
  {
    entranceId: 'D41-entrance-1',
    lat: 40.576445,
    lon: -73.967591,
    stopId: 'D41',
  },
  {
    entranceId: 'D41-entrance-0',
    lat: 40.576423,
    lon: -73.967894,
    stopId: 'D41',
  },
  {
    entranceId: '252-entrance-0',
    lat: 40.661558,
    lon: -73.915225,
    stopId: '252',
  },
  {
    entranceId: '252-entrance-2',
    lat: 40.661401,
    lon: -73.915543,
    stopId: '252',
  },
  {
    entranceId: '252-entrance-1',
    lat: 40.661689,
    lon: -73.91524,
    stopId: '252',
  },
  {
    entranceId: 'R43-entrance-2',
    lat: 40.630221,
    lon: -74.025459,
    stopId: 'R43',
  },
  {
    entranceId: 'R43-entrance-1',
    lat: 40.629503,
    lon: -74.025452,
    stopId: 'R43',
  },
  {
    entranceId: 'R43-entrance-0',
    lat: 40.62955,
    lon: -74.025731,
    stopId: 'R43',
  },
  {
    entranceId: 'A42-entrance-1',
    lat: 40.688396,
    lon: -73.984216,
    stopId: 'A42',
  },
  {
    entranceId: 'A42-entrance-0',
    lat: 40.689085,
    lon: -73.985941,
    stopId: 'A42',
  },
  {
    entranceId: '637-entrance-1',
    lat: 40.725755,
    lon: -73.994367,
    stopId: '637',
  },
  {
    entranceId: '637-entrance-0',
    lat: 40.725726,
    lon: -73.994579,
    stopId: '637',
  },
  {
    entranceId: '637-entrance-3',
    lat: 40.725137,
    lon: -73.994938,
    stopId: '637',
  },
  {
    entranceId: '637-entrance-2',
    lat: 40.725845,
    lon: -73.99432,
    stopId: '637',
  },
  {
    entranceId: '637-entrance-5',
    lat: 40.725958,
    lon: -73.994829,
    stopId: '637',
  },
  {
    entranceId: '637-entrance-4',
    lat: 40.724888,
    lon: -73.995291,
    stopId: '637',
  },
  {
    entranceId: '637-entrance-6',
    lat: 40.726047,
    lon: -73.994759,
    stopId: '637',
  },
  {
    entranceId: '238-entrance-1',
    lat: 40.671789,
    lon: -73.964301,
    stopId: '238',
  },
  {
    entranceId: '238-entrance-0',
    lat: 40.672122,
    lon: -73.964154,
    stopId: '238',
  },
  {
    entranceId: 'L06-entrance-1',
    lat: 40.731483,
    lon: -73.982145,
    stopId: 'L06',
  },
  {
    entranceId: 'L06-entrance-0',
    lat: 40.731397,
    lon: -73.982112,
    stopId: 'L06',
  },
  {
    entranceId: 'L06-entrance-3',
    lat: 40.731118,
    lon: -73.982563,
    stopId: 'L06',
  },
  {
    entranceId: 'L06-entrance-2',
    lat: 40.73105,
    lon: -73.982625,
    stopId: 'L06',
  },
  {
    entranceId: '133-entrance-4',
    lat: 40.733652,
    lon: -74.003006,
    stopId: '133',
  },
  {
    entranceId: '133-entrance-3',
    lat: 40.733634,
    lon: -74.003123,
    stopId: '133',
  },
  {
    entranceId: '133-entrance-0',
    lat: 40.733402,
    lon: -74.002748,
    stopId: '133',
  },
  {
    entranceId: '133-entrance-2',
    lat: 40.733467,
    lon: -74.003076,
    stopId: '133',
  },
  {
    entranceId: '133-entrance-1',
    lat: 40.733515,
    lon: -74.003147,
    stopId: '133',
  },
  {
    entranceId: 'A15-entrance-2',
    lat: 40.811007,
    lon: -73.952615,
    stopId: 'A15',
  },
  {
    entranceId: 'A15-entrance-1',
    lat: 40.810526,
    lon: -73.952583,
    stopId: 'A15',
  },
  {
    entranceId: 'A15-entrance-0',
    lat: 40.810879,
    lon: -73.952318,
    stopId: 'A15',
  },
  {
    entranceId: 'S03-entrance-1',
    lat: 40.675208,
    lon: -73.957591,
    stopId: 'S03',
  },
  {
    entranceId: 'A15-entrance-5',
    lat: 40.81199,
    lon: -73.951745,
    stopId: 'A15',
  },
  {
    entranceId: 'S03-entrance-0',
    lat: 40.674516,
    lon: -73.957932,
    stopId: 'S03',
  },
  {
    entranceId: 'A15-entrance-4',
    lat: 40.811858,
    lon: -73.951351,
    stopId: 'A15',
  },
  {
    entranceId: 'A15-entrance-3',
    lat: 40.810636,
    lon: -73.952886,
    stopId: 'A15',
  },
  {
    entranceId: 'A28-entrance-9',
    lat: 40.752427,
    lon: -73.993679,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-4',
    lat: 40.752766,
    lon: -73.992596,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-3',
    lat: 40.752796,
    lon: -73.992669,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-2',
    lat: 40.752689,
    lon: -73.992742,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-1',
    lat: 40.752161,
    lon: -73.993109,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-8',
    lat: 40.752178,
    lon: -73.993701,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-7',
    lat: 40.753032,
    lon: -73.993188,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-6',
    lat: 40.753053,
    lon: -73.993256,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-5',
    lat: 40.752877,
    lon: -73.993244,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-0',
    lat: 40.75202,
    lon: -73.993352,
    stopId: 'A28',
  },
  {
    entranceId: '119-entrance-4',
    lat: 40.799584,
    lon: -73.968091,
    stopId: '119',
  },
  {
    entranceId: '119-entrance-3',
    lat: 40.799223,
    lon: -73.968271,
    stopId: '119',
  },
  {
    entranceId: '119-entrance-0',
    lat: 40.799282,
    lon: -73.968679,
    stopId: '119',
  },
  {
    entranceId: '119-entrance-2',
    lat: 40.79941,
    lon: -73.968612,
    stopId: '119',
  },
  {
    entranceId: '119-entrance-1',
    lat: 40.799142,
    lon: -73.968314,
    stopId: '119',
  },
  {
    entranceId: 'M06-entrance-1',
    lat: 40.702835,
    lon: -73.907894,
    stopId: 'M06',
  },
  {
    entranceId: 'M06-entrance-0',
    lat: 40.70278,
    lon: -73.907515,
    stopId: 'M06',
  },
  {
    entranceId: 'Q05-entrance-5',
    lat: 40.783107,
    lon: -73.94782,
    stopId: 'Q05',
  },
  {
    entranceId: 'Q05-entrance-4',
    lat: 40.782802,
    lon: -73.94834,
    stopId: 'Q05',
  },
  {
    entranceId: 'Q05-entrance-7',
    lat: 40.783107,
    lon: -73.94782,
    stopId: 'Q05',
  },
  {
    entranceId: 'Q05-entrance-6',
    lat: 40.783107,
    lon: -73.94782,
    stopId: 'Q05',
  },
  {
    entranceId: 'Q05-entrance-1',
    lat: 40.784166,
    lon: -73.947418,
    stopId: 'Q05',
  },
  {
    entranceId: 'Q05-entrance-0',
    lat: 40.784166,
    lon: -73.947418,
    stopId: 'Q05',
  },
  {
    entranceId: 'Q05-entrance-3',
    lat: 40.782802,
    lon: -73.94834,
    stopId: 'Q05',
  },
  {
    entranceId: 'Q05-entrance-2',
    lat: 40.784166,
    lon: -73.947418,
    stopId: 'Q05',
  },
  {
    entranceId: 'M12-entrance-3',
    lat: 40.699838,
    lon: -73.940185,
    stopId: 'M12',
  },
  {
    entranceId: 'M12-entrance-2',
    lat: 40.699707,
    lon: -73.940294,
    stopId: 'M12',
  },
  {
    entranceId: 'M12-entrance-1',
    lat: 40.700851,
    lon: -73.941707,
    stopId: 'M12',
  },
  {
    entranceId: 'M12-entrance-0',
    lat: 40.700627,
    lon: -73.942177,
    stopId: 'M12',
  },
  {
    entranceId: 'M12-entrance-5',
    lat: 40.699903,
    lon: -73.940238,
    stopId: 'M12',
  },
  {
    entranceId: 'M12-entrance-4',
    lat: 40.699741,
    lon: -73.940318,
    stopId: 'M12',
  },
  {
    entranceId: 'F09-entrance-3',
    lat: 40.747706,
    lon: -73.944626,
    stopId: 'F09',
  },
  {
    entranceId: 'F09-entrance-2',
    lat: 40.748195,
    lon: -73.947163,
    stopId: 'F09',
  },
  {
    entranceId: 'F09-entrance-1',
    lat: 40.747954,
    lon: -73.94724,
    stopId: 'F09',
  },
  {
    entranceId: 'F09-entrance-0',
    lat: 40.747371,
    lon: -73.944287,
    stopId: 'F09',
  },
  {
    entranceId: 'R16-entrance-3',
    lat: 40.754507,
    lon: -73.987108,
    stopId: 'R16',
  },
  {
    entranceId: 'R16-entrance-2',
    lat: 40.754301,
    lon: -73.986623,
    stopId: 'R16',
  },
  {
    entranceId: 'R16-entrance-1',
    lat: 40.754335,
    lon: -73.987097,
    stopId: 'R16',
  },
  {
    entranceId: 'R16-entrance-0',
    lat: 40.754172,
    lon: -73.98668,
    stopId: 'R16',
  },
  {
    entranceId: 'A55-entrance-2',
    lat: 40.675274,
    lon: -73.872115,
    stopId: 'A55',
  },
  {
    entranceId: 'A55-entrance-1',
    lat: 40.675533,
    lon: -73.871742,
    stopId: 'A55',
  },
  {
    entranceId: 'A55-entrance-0',
    lat: 40.675301,
    lon: -73.871801,
    stopId: 'A55',
  },
  {
    entranceId: 'A55-entrance-3',
    lat: 40.675478,
    lon: -73.872109,
    stopId: 'A55',
  },
  {
    entranceId: 'A61-entrance-1',
    lat: 40.680305,
    lon: -73.845159,
    stopId: 'A61',
  },
  {
    entranceId: 'A61-entrance-0',
    lat: 40.680158,
    lon: -73.845131,
    stopId: 'A61',
  },
  {
    entranceId: 'A61-entrance-3',
    lat: 40.680774,
    lon: -73.842558,
    stopId: 'A61',
  },
  {
    entranceId: 'A61-entrance-2',
    lat: 40.680611,
    lon: -73.842537,
    stopId: 'A61',
  },
  {
    entranceId: 'L19-entrance-1',
    lat: 40.696069,
    lon: -73.904665,
    stopId: 'L19',
  },
  {
    entranceId: 'L19-entrance-0',
    lat: 40.696094,
    lon: -73.90443,
    stopId: 'L19',
  },
  {
    entranceId: 'L19-entrance-3',
    lat: 40.69599,
    lon: -73.905265,
    stopId: 'L19',
  },
  {
    entranceId: 'L19-entrance-2',
    lat: 40.695871,
    lon: -73.905143,
    stopId: 'L19',
  },
  {
    entranceId: 'L19-entrance-5',
    lat: 40.695201,
    lon: -73.903764,
    stopId: 'L19',
  },
  {
    entranceId: 'L19-entrance-4',
    lat: 40.695186,
    lon: -73.903065,
    stopId: 'L19',
  },
  {
    entranceId: 'D18-entrance-0',
    lat: 40.742659,
    lon: -73.992489,
    stopId: 'D18',
  },
  {
    entranceId: 'D18-entrance-2',
    lat: 40.74288,
    lon: -73.992506,
    stopId: 'D18',
  },
  {
    entranceId: 'D18-entrance-1',
    lat: 40.742715,
    lon: -73.992617,
    stopId: 'D18',
  },
  {
    entranceId: 'D18-entrance-4',
    lat: 40.743121,
    lon: -73.993099,
    stopId: 'D18',
  },
  {
    entranceId: 'D18-entrance-3',
    lat: 40.742825,
    lon: -73.992383,
    stopId: 'D18',
  },
  {
    entranceId: 'D18-entrance-6',
    lat: 40.742977,
    lon: -73.993188,
    stopId: 'D18',
  },
  {
    entranceId: 'D18-entrance-5',
    lat: 40.743066,
    lon: -73.992971,
    stopId: 'D18',
  },
  {
    entranceId: 'D18-entrance-7',
    lat: 40.742922,
    lon: -73.993066,
    stopId: 'D18',
  },
  {
    entranceId: 'R29-entrance-2',
    lat: 40.692029,
    lon: -73.985383,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-1',
    lat: 40.692241,
    lon: -73.9852,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-0',
    lat: 40.69204,
    lon: -73.985177,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-9',
    lat: 40.692052,
    lon: -73.986191,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-8',
    lat: 40.692258,
    lon: -73.986114,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-7',
    lat: 40.692114,
    lon: -73.986107,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-6',
    lat: 40.69208,
    lon: -73.986191,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-5',
    lat: 40.692118,
    lon: -73.986106,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-4',
    lat: 40.692261,
    lon: -73.986085,
    stopId: 'R29',
  },
  {
    entranceId: 'R29-entrance-3',
    lat: 40.692341,
    lon: -73.986171,
    stopId: 'R29',
  },
  {
    entranceId: '205-entrance-0',
    lat: 40.893215,
    lon: -73.857344,
    stopId: '205',
  },
  {
    entranceId: '205-entrance-1',
    lat: 40.893597,
    lon: -73.857405,
    stopId: '205',
  },
  {
    entranceId: '611-entrance-1',
    lat: 40.82846,
    lon: -73.879362,
    stopId: '611',
  },
  {
    entranceId: '611-entrance-0',
    lat: 40.828688,
    lon: -73.879018,
    stopId: '611',
  },
  {
    entranceId: 'F29-entrance-0',
    lat: 40.635434,
    lon: -73.978289,
    stopId: 'F29',
  },
  {
    entranceId: 'F29-entrance-2',
    lat: 40.637093,
    lon: -73.978463,
    stopId: 'F29',
  },
  {
    entranceId: 'F29-entrance-1',
    lat: 40.635389,
    lon: -73.977914,
    stopId: 'F29',
  },
  {
    entranceId: 'F29-entrance-3',
    lat: 40.637107,
    lon: -73.978257,
    stopId: 'F29',
  },
  {
    entranceId: '604-entrance-0',
    lat: 40.840128,
    lon: -73.842875,
    stopId: '604',
  },
  {
    entranceId: '423-entrance-4',
    lat: 40.692489,
    lon: -73.991193,
    stopId: '423',
  },
  {
    entranceId: '423-entrance-3',
    lat: 40.692588,
    lon: -73.990572,
    stopId: '423',
  },
  {
    entranceId: '423-entrance-2',
    lat: 40.692548,
    lon: -73.990099,
    stopId: '423',
  },
  {
    entranceId: '423-entrance-1',
    lat: 40.692299,
    lon: -73.990301,
    stopId: '423',
  },
  {
    entranceId: '423-entrance-0',
    lat: 40.69234,
    lon: -73.99056,
    stopId: '423',
  },
  {
    entranceId: 'D25-entrance-1',
    lat: 40.677324,
    lon: -73.972212,
    stopId: 'D25',
  },
  {
    entranceId: 'D25-entrance-0',
    lat: 40.677365,
    lon: -73.972788,
    stopId: 'D25',
  },
  {
    entranceId: '723-entrance-0',
    lat: 40.750851,
    lon: -73.975078,
    stopId: '723',
  },
  {
    entranceId: 'A02-entrance-0',
    lat: 40.867799,
    lon: -73.921431,
    stopId: 'A02',
  },
  {
    entranceId: 'A02-entrance-4',
    lat: 40.868036,
    lon: -73.919284,
    stopId: 'A02',
  },
  {
    entranceId: 'A02-entrance-3',
    lat: 40.868284,
    lon: -73.919607,
    stopId: 'A02',
  },
  {
    entranceId: 'A02-entrance-2',
    lat: 40.867653,
    lon: -73.920949,
    stopId: 'A02',
  },
  {
    entranceId: 'A02-entrance-1',
    lat: 40.867512,
    lon: -73.92133,
    stopId: 'A02',
  },
  {
    entranceId: 'J28-entrance-1',
    lat: 40.683417,
    lon: -73.911242,
    stopId: 'J28',
  },
  {
    entranceId: 'J28-entrance-0',
    lat: 40.683313,
    lon: -73.911334,
    stopId: 'J28',
  },
  {
    entranceId: 'D32-entrance-1',
    lat: 40.629884,
    lon: -73.961882,
    stopId: 'D32',
  },
  {
    entranceId: 'D32-entrance-0',
    lat: 40.62993,
    lon: -73.961477,
    stopId: 'D32',
  },
  {
    entranceId: 'B14-entrance-3',
    lat: 40.636405,
    lon: -73.994673,
    stopId: 'B14',
  },
  {
    entranceId: 'B14-entrance-2',
    lat: 40.636415,
    lon: -73.994879,
    stopId: 'B14',
  },
  {
    entranceId: 'B14-entrance-1',
    lat: 40.636139,
    lon: -73.994702,
    stopId: 'B14',
  },
  {
    entranceId: 'B14-entrance-0',
    lat: 40.636133,
    lon: -73.994915,
    stopId: 'B14',
  },
  {
    entranceId: '106-entrance-0',
    lat: 40.874437,
    lon: -73.910529,
    stopId: '106',
  },
  {
    entranceId: '106-entrance-1',
    lat: 40.874308,
    lon: -73.909795,
    stopId: '106',
  },
  {
    entranceId: '416-entrance-0',
    lat: 40.813328,
    lon: -73.930004,
    stopId: '416',
  },
  {
    entranceId: '416-entrance-2',
    lat: 40.813363,
    lon: -73.929847,
    stopId: '416',
  },
  {
    entranceId: '416-entrance-1',
    lat: 40.813091,
    lon: -73.929689,
    stopId: '416',
  },
  {
    entranceId: 'H11-entrance-0',
    lat: 40.604657,
    lon: -73.754178,
    stopId: 'H11',
  },
  {
    entranceId: '120-entrance-2',
    lat: 40.792923,
    lon: -73.972824,
    stopId: '120',
  },
  {
    entranceId: '120-entrance-1',
    lat: 40.79307,
    lon: -73.973174,
    stopId: '120',
  },
  {
    entranceId: '120-entrance-4',
    lat: 40.793065,
    lon: -73.972722,
    stopId: '120',
  },
  {
    entranceId: '120-entrance-3',
    lat: 40.793209,
    lon: -73.973074,
    stopId: '120',
  },
  {
    entranceId: '120-entrance-5',
    lat: 40.794611,
    lon: -73.972053,
    stopId: '120',
  },
  {
    entranceId: '716-entrance-1',
    lat: 40.744724,
    lon: -73.931533,
    stopId: '716',
  },
  {
    entranceId: '716-entrance-0',
    lat: 40.744568,
    lon: -73.93157,
    stopId: '716',
  },
  {
    entranceId: '716-entrance-5',
    lat: 40.744658,
    lon: -73.930865,
    stopId: '716',
  },
  {
    entranceId: '716-entrance-4',
    lat: 40.744494,
    lon: -73.930894,
    stopId: '716',
  },
  {
    entranceId: '716-entrance-3',
    lat: 40.744763,
    lon: -73.931794,
    stopId: '716',
  },
  {
    entranceId: '716-entrance-2',
    lat: 40.744594,
    lon: -73.931829,
    stopId: '716',
  },
  {
    entranceId: '120-entrance-0',
    lat: 40.794329,
    lon: -73.972042,
    stopId: '120',
  },
  {
    entranceId: 'D12-entrance-0',
    lat: 40.830313,
    lon: -73.936687,
    stopId: 'D12',
  },
  {
    entranceId: '617-entrance-3',
    lat: 40.805047,
    lon: -73.913524,
    stopId: '617',
  },
  {
    entranceId: '617-entrance-2',
    lat: 40.805171,
    lon: -73.913933,
    stopId: '617',
  },
  {
    entranceId: '617-entrance-1',
    lat: 40.805155,
    lon: -73.913208,
    stopId: '617',
  },
  {
    entranceId: '617-entrance-0',
    lat: 40.805234,
    lon: -73.913391,
    stopId: '617',
  },
  {
    entranceId: 'G12-entrance-1',
    lat: 40.736277,
    lon: -73.876424,
    stopId: 'G12',
  },
  {
    entranceId: 'G12-entrance-0',
    lat: 40.735888,
    lon: -73.876886,
    stopId: 'G12',
  },
  {
    entranceId: 'G12-entrance-3',
    lat: 40.737354,
    lon: -73.877191,
    stopId: 'G12',
  },
  {
    entranceId: 'G12-entrance-2',
    lat: 40.736075,
    lon: -73.877167,
    stopId: 'G12',
  },
  {
    entranceId: 'G12-entrance-4',
    lat: 40.737477,
    lon: -73.877424,
    stopId: 'G12',
  },
  {
    entranceId: '225-entrance-3',
    lat: 40.807875,
    lon: -73.945229,
    stopId: '225',
  },
  {
    entranceId: '225-entrance-2',
    lat: 40.807548,
    lon: -73.945463,
    stopId: '225',
  },
  {
    entranceId: '225-entrance-1',
    lat: 40.807995,
    lon: -73.945524,
    stopId: '225',
  },
  {
    entranceId: '225-entrance-0',
    lat: 40.807681,
    lon: -73.945752,
    stopId: '225',
  },
  {
    entranceId: '218-entrance-0',
    lat: 40.8224,
    lon: -73.896508,
    stopId: '218',
  },
  {
    entranceId: '218-entrance-2',
    lat: 40.822095,
    lon: -73.896681,
    stopId: '218',
  },
  {
    entranceId: '218-entrance-1',
    lat: 40.822447,
    lon: -73.896719,
    stopId: '218',
  },
  {
    entranceId: 'A28-entrance-11',
    lat: 40.751432,
    lon: -73.993538,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-10',
    lat: 40.751291,
    lon: -73.993887,
    stopId: 'A28',
  },
  {
    entranceId: '624-entrance-1',
    lat: 40.790193,
    lon: -73.947649,
    stopId: '624',
  },
  {
    entranceId: 'A28-entrance-13',
    lat: 40.751561,
    lon: -73.994243,
    stopId: 'A28',
  },
  {
    entranceId: '624-entrance-0',
    lat: 40.790279,
    lon: -73.947856,
    stopId: '624',
  },
  {
    entranceId: 'A28-entrance-12',
    lat: 40.751677,
    lon: -73.994107,
    stopId: 'A28',
  },
  {
    entranceId: 'A28-entrance-14',
    lat: 40.751539,
    lon: -73.994181,
    stopId: 'A28',
  },
  {
    entranceId: 'G32-entrance-1',
    lat: 40.6955,
    lon: -73.94896,
    stopId: 'G32',
  },
  {
    entranceId: 'G32-entrance-0',
    lat: 40.695436,
    lon: -73.94948,
    stopId: 'G32',
  },
  {
    entranceId: '410-entrance-1',
    lat: 40.848501,
    lon: -73.911633,
    stopId: '410',
  },
  {
    entranceId: '410-entrance-0',
    lat: 40.848385,
    lon: -73.912003,
    stopId: '410',
  },
  {
    entranceId: 'D38-entrance-0',
    lat: 40.59502,
    lon: -73.95512,
    stopId: 'D38',
  },
  {
    entranceId: 'A48-entrance-0',
    lat: 40.679095,
    lon: -73.930044,
    stopId: 'A48',
  },
  {
    entranceId: 'A48-entrance-2',
    lat: 40.67953,
    lon: -73.932021,
    stopId: 'A48',
  },
  {
    entranceId: 'A48-entrance-1',
    lat: 40.679487,
    lon: -73.929725,
    stopId: 'A48',
  },
  {
    entranceId: 'A48-entrance-3',
    lat: 40.679346,
    lon: -73.931987,
    stopId: 'A48',
  },
  {
    entranceId: 'J22-entrance-1',
    lat: 40.679558,
    lon: -73.88562,
    stopId: 'J22',
  },
  {
    entranceId: 'J22-entrance-0',
    lat: 40.679675,
    lon: -73.885701,
    stopId: 'J22',
  },
  {
    entranceId: 'F33-entrance-3',
    lat: 40.61616,
    lon: -73.97427,
    stopId: 'F33',
  },
  {
    entranceId: 'F33-entrance-2',
    lat: 40.616121,
    lon: -73.974507,
    stopId: 'F33',
  },
  {
    entranceId: 'F33-entrance-1',
    lat: 40.614304,
    lon: -73.97391,
    stopId: 'F33',
  },
  {
    entranceId: 'F33-entrance-0',
    lat: 40.614279,
    lon: -73.97418,
    stopId: 'F33',
  },
  {
    entranceId: '139-entrance-0',
    lat: 40.708107,
    lon: -74.013445,
    stopId: '139',
  },
  {
    entranceId: '139-entrance-4',
    lat: 40.707564,
    lon: -74.013853,
    stopId: '139',
  },
  {
    entranceId: '139-entrance-3',
    lat: 40.706597,
    lon: -74.014115,
    stopId: '139',
  },
  {
    entranceId: '139-entrance-2',
    lat: 40.706527,
    lon: -74.014144,
    stopId: '139',
  },
  {
    entranceId: '139-entrance-1',
    lat: 40.706493,
    lon: -74.014319,
    stopId: '139',
  },
  {
    entranceId: '139-entrance-5',
    lat: 40.708191,
    lon: -74.013582,
    stopId: '139',
  },
  {
    entranceId: '126-entrance-2',
    lat: 40.761212,
    lon: -73.983357,
    stopId: '126',
  },
  {
    entranceId: '126-entrance-1',
    lat: 40.761282,
    lon: -73.983853,
    stopId: '126',
  },
  {
    entranceId: '126-entrance-0',
    lat: 40.761111,
    lon: -73.983436,
    stopId: '126',
  },
  {
    entranceId: '126-entrance-5',
    lat: 40.761561,
    lon: -73.984154,
    stopId: '126',
  },
  {
    entranceId: '126-entrance-4',
    lat: 40.761432,
    lon: -73.98437,
    stopId: '126',
  },
  {
    entranceId: '126-entrance-3',
    lat: 40.761367,
    lon: -73.983782,
    stopId: '126',
  },
  {
    entranceId: 'L20-entrance-0',
    lat: 40.688805,
    lon: -73.904741,
    stopId: 'L20',
  },
  {
    entranceId: 'R30-entrance-4',
    lat: 40.691562,
    lon: -73.982048,
    stopId: 'R30',
  },
  {
    entranceId: 'R30-entrance-3',
    lat: 40.69154,
    lon: -73.982565,
    stopId: 'R30',
  },
  {
    entranceId: 'R30-entrance-2',
    lat: 40.68974,
    lon: -73.981156,
    stopId: 'R30',
  },
  {
    entranceId: 'R30-entrance-1',
    lat: 40.689817,
    lon: -73.981082,
    stopId: 'R30',
  },
  {
    entranceId: 'R30-entrance-0',
    lat: 40.68981,
    lon: -73.981651,
    stopId: 'R30',
  },
  {
    entranceId: 'A22-entrance-0',
    lat: 40.776217,
    lon: -73.976247,
    stopId: 'A22',
  },
  {
    entranceId: 'A22-entrance-2',
    lat: 40.77494,
    lon: -73.977144,
    stopId: 'A22',
  },
  {
    entranceId: 'A22-entrance-1',
    lat: 40.776431,
    lon: -73.975977,
    stopId: 'A22',
  },
  {
    entranceId: '113-entrance-0',
    lat: 40.834268,
    lon: -73.945183,
    stopId: '113',
  },
  {
    entranceId: '113-entrance-3',
    lat: 40.833832,
    lon: -73.944753,
    stopId: '113',
  },
  {
    entranceId: '113-entrance-2',
    lat: 40.83386,
    lon: -73.94462,
    stopId: '113',
  },
  {
    entranceId: '113-entrance-1',
    lat: 40.834221,
    lon: -73.945073,
    stopId: '113',
  },
  {
    entranceId: 'B21-entrance-3',
    lat: 40.60215,
    lon: -73.993948,
    stopId: 'B21',
  },
  {
    entranceId: 'B21-entrance-0',
    lat: 40.601726,
    lon: -73.993696,
    stopId: 'B21',
  },
  {
    entranceId: 'B21-entrance-2',
    lat: 40.601993,
    lon: -73.99413,
    stopId: 'B21',
  },
  {
    entranceId: 'B21-entrance-1',
    lat: 40.601898,
    lon: -73.993538,
    stopId: 'B21',
  },
  {
    entranceId: '710-entrance-0',
    lat: 40.747025,
    lon: -73.891413,
    stopId: '710',
  },
  {
    entranceId: '212-entrance-2',
    lat: 40.849128,
    lon: -73.868213,
    stopId: '212',
  },
  {
    entranceId: '212-entrance-1',
    lat: 40.849169,
    lon: -73.868356,
    stopId: '212',
  },
  {
    entranceId: '212-entrance-0',
    lat: 40.848762,
    lon: -73.868382,
    stopId: '212',
  },
  {
    entranceId: 'F20-entrance-1',
    lat: 40.686727,
    lon: -73.990269,
    stopId: 'F20',
  },
  {
    entranceId: 'F20-entrance-0',
    lat: 40.686584,
    lon: -73.990362,
    stopId: 'F20',
  },
  {
    entranceId: 'F20-entrance-5',
    lat: 40.685445,
    lon: -73.991131,
    stopId: 'F20',
  },
  {
    entranceId: 'F20-entrance-4',
    lat: 40.685579,
    lon: -73.991465,
    stopId: 'F20',
  },
  {
    entranceId: 'F20-entrance-3',
    lat: 40.686868,
    lon: -73.990526,
    stopId: 'F20',
  },
  {
    entranceId: 'F20-entrance-2',
    lat: 40.686708,
    lon: -73.990629,
    stopId: 'F20',
  },
];

export default otsStopsEntrances;
