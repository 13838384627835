export type SegmentStrategyIndex = number | string;
export type StrategiesForSegments = {
  [segmentId: string]: SegmentStrategyIndex;
};

/**
 * Selects a Vignelli shape or "line strategy" between specific pairs of stations.
 * The lookup index is the segment ID, which is formed from 2 stop IDs.
 * The order of the stops in the segment ID is significant.
 * These are kept in alphabetical order for readability.
 */
const strategiesForSegments: StrategiesForSegments = {
  '118_119': 7,
  '122_123': 9,
  '123_124': 9,
  '124_A24': 9,
  '126_127': 5,
  '127_128': 0,
  '127_631': 0,
  '127_726': 1,
  '127_R17': 7,
  '132_133': 0,
  '132_137': 6,
  '133_134': 9, // Christopher St Sheridan Sq to Houston (1/2/3)
  '134_135': 0,
  '135_136': 0,
  '136_137': 9, // Franklin to Chambers (1/2/3)
  '137_228': 2,
  '138_139': 7,
  '139_142': 2,
  '215_216': 9,
  '220_221': 5,
  '221_222': 1,
  '222_302': 4,
  '227_119': 4,
  '227_120': 4,
  '228_229': 1,
  '229_230': 0,
  '230_231': 2,
  '231_232': '10 .5 .3',
  '232_233': 0,
  '232_234': 2,
  '233_234': 0,
  '234_235': 9,
  '235_236': 10,
  '235_239': 5,
  '235_D25': 5,
  '235_D26': 8,
  // NOTE: This will usually not matter because Bergen to Grand Army Plaza now goes through 7 Av (D25)
  '236_237': 6,
  '236_D25': 10,
  '237_238': 0,
  '237_D26': 9,
  '238_239': 6,
  '239_241': 5,
  '239_248': 5,
  '241_242': 10,
  '248_249': 6,
  '249_250': 5,
  '251_252': 10,
  '301_302': 1,
  '413_D11': 2,
  '416_621': 7,
  '418_419': 5,
  '420_232': 2,
  '616_617': 2,
  '619_621': 1,
  '630_631': 7,
  // Below are the 42 St Shuttle's 2 stops in either direction: 631 -> 127, 127 -> 631.
  // This is a workaround for patternGraph causing the draw order to be reversed.
  // TODO: fix the ordering
  '631_127': 0,
  '634_R20': 0,
  '640_418': 6,
  '716_R09': 7,
  '719_G24': 1,
  '720_721': 2,
  '724_127': 0,
  '901_127': 1,
  A03_A05: 9,
  A11_A12: '10 0.1 0.3',
  A12_A14: 7,
  A14_A15: 6,
  A15_A16: 5,
  A24_126: 6,
  A24_D14: 2,
  A31_A32: 7,
  A32_A33: 9,
  A32_D21: 2,
  A33_A34: 0, // Spring to Canal (A/C/E)
  A34_A36: 9, // Canal to Chambers (A/C/E)
  // Obsoleted since E route was redefined to run through Chambers
  // A34_E01: 6, // Canal to World Trade Center (A/C/E)
  A36_A38: '10 .24 .1', // Chambers to Fulton (A/C)
  A36_E01: 2, // Chambers to World Trade Center (E)
  A38_A40: 2,
  A38_M23: 2,
  A40_A41: '10 .39 .3',
  A41_A42: 2,
  A41_F20: '10 .3 .55',
  // Jay St MetroTech to DeKalb is tricky: it goes up then back down
  A41_R30: 9,
  // TODO:
  // alternate Jay St MetroTech to DeKalb
  // This strategy currently doesn't handle rerouting of W through Jay St so well; needs a math fix
  // TODO: fix radius for the R train being calculated too big
  // A41_R30: '10 -.25 .2',
  A42_A43: 6,
  A42_F20: 1,
  A43_A44: 5,
  A44_A45: 9,
  A50_J27: 10,
  A52_A53: 9,
  B08_B10: 1,
  B08_R13: 3,
  B08_R14: 1,
  B10_D14: 2,
  B10_D15: 7,
  B19_B20: 9,
  B20_B21: 0,
  B21_B22: 9,
  B22_B23: 1,
  B23_D43: 2,
  D10_D11: 2,
  D11_222: 1,
  D11_D12: 1,
  // 155 St to 145 St B/D: this will be a good strategy when the math for it is fixed
  D12_A12: 7,
  D14_A25: 1,
  D14_D15: 1,
  D20_D21: 2,
  D21_D22: 1,
  D21_F14: 0,
  D21_M18: 1,
  D22_R30: 9,
  D25_237: 9,
  D25_D26: 10,
  D26_D27: 9,
  D26_D28: 5,
  D27_D28: 10,
  D28_D29: 10,
  D39_D40: 0,
  F02_F03: 0,
  F03_F04: 0,
  F04_F05: 5,
  F09_F11: 0,
  F12_D15: 1,
  F14_F15: 1,
  F14_M18: 1,
  F15_F16: 6,
  F16_F18: 9,
  F18_A41: 9,
  F21_F22: 2,
  F23_F24: 0,
  F24_F25: 1,
  F25_F26: 4,
  F26_F27: 6,
  F34_F35: 9,
  G05_G06: 0,
  G06_G07: 5,
  G06_J12: 8,
  G07_G06: 6,
  G08_G09: '10 .3 .1',
  G08_G14: 3,
  G09_G10: '10 .1 .3',
  G11_G12: 6,
  G12_G13: 5,
  G14_B04: 7,
  G18_G19: 1,
  G19_G20: 6,
  G20_B04: 5,
  G20_G21: 5,
  G21_F09: 2,
  G21_R11: 0,
  G24_G26: 6,

  G32_G33: 9,
  G36_A42: 9,
  H04_H06: 9,
  H04_H12: 9,
  H06_H04: 6,
  H08_H09: 9,
  J19_J20: 10,
  J23_J24: 9,
  J27_A52: 6,
  J27_J28: 9,
  J27_L24: 9,
  L02_R20: 0,
  L06_L08: 10,
  L17_M06: 9,
  L21_J27: 9,
  M04_M01: 5,
  M08_M06: 8,
  M09_L17: 9,
  M11_M10: 10,
  M14_M16: 0,
  M16_M14: 0,
  // Brown line is right-to-left
  // Negative coefficients because direction is reversed
  M16_M18: '10 -0.2 -0.005',
  M18_F16: 7,
  // Orange line is left-to-right
  M18_M16: '10 0.005 0.2',
  M18_M19: 0,
  M19_Q01: 1,
  M21_A38: '10 .5 .2',
  N06_N07: 9,
  R23_R24: 10,
  Q01_R30: '10 0.05 0.8',
  Q03_B08: 2,
  R08_R09: 2,
  R09_719: 1,
  R09_R11: 0,
  R11_R13: 0,
  R13_R14: 1,
  R17_R18: 9,
  R18_R19: 0,
  R19_R20: 9,
  R20_636: 7,
  R20_L05: 1,
  R20_R21: 7,
  R22_R23: 0,
  R22_Q01: 7,
  R23_R30: '10 0.05 0.8',
  R24_R25: 7,
  R25_R26: 0,
  R26_R27: 2,
  R28_A41: 1,
  R28_R29: 7,
  R28_R30: 6,
  R29_R30: 6,
  R30_234: 9,
  R30_235: 10,
  R30_R31: '10 .30 .6',
  R31_R32: 9,
  R36_B12: 10,
  R41_N02: 9,
  R41_R42: 9,
  D42_D43: 9,
  D41_D42: 9,
  D40_D41: 9,
  F39_D42: 9,
  N10_D43: 2,
  D38_D39: 9,
  N09_N10: 5,
  S01_S03: 6,
  S03_S04: 5,
  S04_D26: 9,
  L10_L11: 7,
  L11_L12: 1,
  L12_L13: 2,
  L13_L14: 6,
  L14_L15: 5,
  L20_L21: 9,
  L19_L20: 1,
};

export default strategiesForSegments;
