import { LineABAnglesStrategy, LineABStrategy, Point } from './geometry-types';
import { fromPolarDegrees, intersect2Lines } from './point-utils';
import { SegmentStrategyIndex } from '../maps/strategies-for-segments';

const signOf = (x: number): 1 | -1 => (x > 0 ? 1 : -1);

function fixFlip(width: number, height: number): 1 | -1 {
  return (signOf(width) * signOf(height)) as 1 | -1;
}

export const direct: LineABStrategy = lineAB => lineAB;

export const stepBefore: LineABStrategy = ([a, b]) => {
  const elbow: Point = [b[0], a[1]];
  return [a, elbow, b];
};

export const stepAfter: LineABStrategy = ([a, b]) => {
  const elbow: Point = [a[0], b[1]];
  return [a, elbow, b];
};

export const stepHorizontal: LineABStrategy = ([a, b]) => {
  const width = b[0] - a[0];
  let elbowA: Point;
  let elbowB: Point;

  elbowA = [a[0] + width / 2, a[1]];
  elbowB = [a[0] + width / 2, b[1]];

  return [a, elbowA, elbowB, b];
};

export const stepVertical: LineABStrategy = ([a, b]) => {
  const height = b[1] - a[1];
  let elbowA: Point;
  let elbowB: Point;

  elbowA = [a[0], a[1] + height / 2];
  elbowB = [b[0], a[1] + height / 2];

  return [a, elbowA, elbowB, b];
};

export const diagonalBefore: LineABStrategy = ([a, b]) => {
  const width = b[0] - a[0];
  const height = b[1] - a[1];
  if (width === height) {
    return [a, b];
  }

  let elbow: Point;

  if (Math.abs(width / height) >= 1) {
    elbow = [a[0] + height * fixFlip(width, height), a[1] + height];
  } else {
    elbow = [a[0] + width, a[1] + width * fixFlip(width, height)];
  }

  return [a, elbow, b];
};

export const diagonalAfter: LineABStrategy = ([a, b]) => {
  const width = b[0] - a[0];
  const height = b[1] - a[1];
  if (width === height) {
    return [a, b];
  }

  let elbow: Point;

  if (Math.abs(width / height) >= 1) {
    elbow = [b[0] - height * fixFlip(width, height), a[1]];
  } else {
    elbow = [a[0], b[1] - width * fixFlip(width, height)];
  }

  return [a, elbow, b];
};

export const diagonalInside: LineABStrategy = ([a, b]) => {
  const width = b[0] - a[0];
  const height = b[1] - a[1];
  if (width === height) {
    return [a, b];
  }

  let elbowA: Point;
  let elbowB: Point;

  if (Math.abs(width / height) >= 1) {
    const halfDiff = (width - height * fixFlip(width, height)) / 2;
    elbowA = [a[0] + halfDiff, a[1]];
    elbowB = [b[0] - halfDiff, b[1]];
  } else {
    const halfDiff = (height - width * fixFlip(width, height)) / 2;
    elbowA = [a[0], a[1] + halfDiff];
    elbowB = [b[0], b[1] - halfDiff];
  }

  return [a, elbowA, elbowB, b];
};

export const diagonalOutside: LineABStrategy = ([a, b]) => {
  const width = b[0] - a[0];
  const height = b[1] - a[1];
  if (width === height) {
    return [a, b];
  }

  let elbowA: Point;
  let elbowB: Point;

  if (Math.abs(width / height) >= 1) {
    const elbowXDiff = (height / 2) * fixFlip(width, height);
    const elbowY = a[1] + height / 2;
    elbowA = [a[0] + elbowXDiff, elbowY];
    elbowB = [b[0] - elbowXDiff, elbowY];
  } else {
    const elbowYDiff = (width / 2) * fixFlip(width, height);
    const elbowX = a[0] + width / 2;
    elbowA = [elbowX, a[1] + elbowYDiff];
    elbowB = [elbowX, b[1] - elbowYDiff];
  }

  return [a, elbowA, elbowB, b];
};

export const stepHorizontalOutsideUBottom: LineABStrategy = ([a, b]) => {
  const space = -20;
  let elbowA: Point;
  let elbowB: Point;

  if (a[1] - b[1] > 0) {
    elbowA = [a[0], b[1] + space];
    elbowB = [b[0], b[1] + space];
  } else {
    elbowA = [a[0], a[1] + space];
    elbowB = [b[0], a[1] + space];
  }

  return [a, elbowA, elbowB, b];
};

export const angled: LineABAnglesStrategy = (
  [a, b],
  { angleADegrees, angleBDegrees }
) => {
  const radius = 1;
  const a2 = fromPolarDegrees(radius, angleADegrees, a);
  const b2 = fromPolarDegrees(radius, angleBDegrees, b);
  const intersection = intersect2Lines(a, a2, b2, b);
  return [a, intersection, b];
};

export const angledS: LineABAnglesStrategy = (
  [a, b],
  { angleADegrees, angleBDegrees, distanceRatioA = 0.2, distanceRatioB = 0.2 }
) => {
  const dx = b[0] - a[0];
  const dy = b[1] - a[1];
  const distanceAB = Math.sqrt(dx * dx + dy * dy);
  // const distanceRatioA = 0.2;
  // const distanceRatioB = 0.2;
  // TODO: use these values to make Grand St -> Dekalb Av pass over Manhattan bridge
  // const distanceRatioA = 0.09;
  // const distanceRatioB = 0.02;
  const distanceFromA = distanceAB * distanceRatioA;
  const distanceFromB = distanceAB * distanceRatioB;
  const a2 = fromPolarDegrees(distanceFromA, angleADegrees, a);
  const b2 = fromPolarDegrees(distanceFromB, angleBDegrees, b);
  return [a, a2, b2, b];
};

export const strategies = [
  direct,
  stepBefore,
  stepAfter,
  stepHorizontal,
  stepVertical,
  diagonalBefore,
  diagonalAfter,
  diagonalInside,
  diagonalOutside,
  // TODO: check whether this is getting clobbered by 'angled' taking index 9
  stepHorizontalOutsideUBottom,
];

export const getStrategyByIndex = (
  index: SegmentStrategyIndex
): LineABStrategy => strategies[index] || direct;
