import React from 'react';
import styled from 'styled-components/macro';
import { SubwayRouteIds } from '../../subway-data/subway-types';
import { SubwayRouteIconG } from '../../maps/subway-sign-svg';
import { RoutesUnavailable } from '../../subway-data';

const Wrapper = styled.svg`
  display: inline-block;
  transform-origin: top left;
`;

export const StationStatusRoutesIcons: React.FC<{
  routeIds: SubwayRouteIds;
  routesUnavailableForStation?: RoutesUnavailable;
  width: number;
  widthPadded?: number;
}> = ({
  routeIds,
  routesUnavailableForStation = {},
  width = 0,
  widthPadded,
}) => {
  return (
    <Wrapper
      xmlns="http://www.w3.org/2000/svg"
      // The SVG has internal translate that moves the items a little
      // The +2 fixes the final wrapper width to fit the icon correctly
      height={width + 2}
      width={(widthPadded || width + 2) * routeIds.length}
    >
      {routeIds &&
        routeIds.map((routeId, index) => {
          return (
            <g
              key={routeId}
              transform={`translate(${index * (widthPadded || width)}, 0)`}
            >
              <SubwayRouteIconG
                routeId={routeId}
                width={width}
                inactive={routesUnavailableForStation[routeId]}
                strokeWidth={0}
              />
            </g>
          );
        })}
    </Wrapper>
  );
};
