import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from '../models';
import {
  Station,
  StopId,
  StopListsByRoute,
  SubwayRouteId,
} from '../subway-data/subway-types';
import { TrainStatusByStopId } from '../subway-data/station-status-types';
import { isPhone } from '../utils/deviceDetector.utils';
import StationStatus from '../components/StationStatus/StationStatus';
import ViewBox from '../components/ViewBox';
import Tray, { TrayPosition } from '../components/Tray';
import {
  getMapSelectedRouteId,
  getMapSelectedStation,
  getMapTrainStatusByStopId,
  getMapElevatorEscalatorStatus,
  getMapCurrentTime,
  getMapTimeFilter,
  getMapRoutesWithSituation,
} from '../selectors/map/basic';
import {
  RoutesUnavailable,
  RoutesUnidirectional,
  TimeFilter,
  RoutesWithSituation,
} from '../subway-data';
import { ElevatorEscalatorStatus } from '../subway-data/elevator-escalator-status-types';
import { getMapRoutesUnavailableForSelectedStation } from '../selectors/map/getMapRoutesUnavailableForSelectedStation';
import { getMapStopListsByRoute } from '../selectors/map/getMapStopListsByRoute';
import { listUnidirectionalServices } from '../utils/unidirectionalServices';
import { getTimeFilterFromDate } from '../utils/date.utils';

interface StationStatusContainerProps {
  currentTime: TimeFilter;
  currentTrayPosition: TrayPosition;
  elevatorEscalatorStatus: ElevatorEscalatorStatus;
  routesUnavailableForStation: RoutesUnavailable;
  routesWithSituation: RoutesWithSituation;
  selectedStation: Station | null;
  shortcutsMenuOpen?: boolean;
  stationViewOpened: boolean;
  stopListsByRoute: StopListsByRoute;
  timeFilter: TimeFilter;
  trainStatusByStopId: TrainStatusByStopId;
  translate: boolean;
  setCurrentTrayPosition: (value: TrayPosition) => void;
  setSelectedRouteId: (value: SubwayRouteId | '') => void;
  setStationViewOpened: (value: boolean) => void;
  setTimeFilter: (timeFilter: TimeFilter) => void;
  updateStopTrainTimes: (stopId: StopId) => void;
  updateElevatorEscalatorStatus: () => void;
}

const StationStatusContainer: React.FC<StationStatusContainerProps> = ({
  currentTime,
  currentTrayPosition,
  elevatorEscalatorStatus,
  routesUnavailableForStation,
  routesWithSituation,
  selectedStation,
  setCurrentTrayPosition,
  setSelectedRouteId,
  setStationViewOpened,
  setTimeFilter,
  shortcutsMenuOpen = false,
  stationViewOpened,
  stopListsByRoute,
  timeFilter,
  trainStatusByStopId,
  translate,
  updateElevatorEscalatorStatus,
  updateStopTrainTimes,
}) => {
  const onAutoRefresh = useCallback(() => {
    if (!!selectedStation) {
      updateStopTrainTimes(selectedStation.stopId);
      updateElevatorEscalatorStatus();
    }
  }, [selectedStation, updateElevatorEscalatorStatus, updateStopTrainTimes]);

  const selectStationAndCloseView = useCallback(
    (routeId: SubwayRouteId | '') => {
      setSelectedRouteId(routeId);
      setStationViewOpened(false);
    },
    [setSelectedRouteId, setStationViewOpened]
  );
  const resetTimeFilter = useCallback(() => {
    setTimeFilter(getTimeFilterFromDate());
  }, [setTimeFilter]);

  const selectedStationUnidirectionalService =
    selectedStation?.transfers.reduce((acc, cur) => {
      const unidirectionalService = listUnidirectionalServices(
        stopListsByRoute[cur]
      );
      acc[cur] = unidirectionalService.length > 0;
      return acc;
    }, {} as RoutesUnidirectional) || {};

  if (isPhone()) {
    return (
      <Tray
        currentTrayPosition={currentTrayPosition}
        defaultPosition={TrayPosition.twoThirds}
        isOpen={stationViewOpened}
        onCloseCallback={() => setStationViewOpened(false)}
        setCurrentTrayPosition={setCurrentTrayPosition}
      >
        <StationStatus
          currentTime={currentTime}
          routesWithSituation={routesWithSituation}
          routesUnavailableForStation={routesUnavailableForStation}
          unidirectionalRoutesForStation={selectedStationUnidirectionalService}
          selectedStation={selectedStation}
          trainStatusByStopId={trainStatusByStopId}
          equipmentOutageListings={elevatorEscalatorStatus}
          setSelectedRouteId={selectStationAndCloseView}
          onAutoRefresh={onAutoRefresh}
          onCloseClick={() => setStationViewOpened(false)}
          useTimers={stationViewOpened}
          timeFilter={timeFilter}
          resetTimeFilter={resetTimeFilter}
        />
      </Tray>
    );
  }

  return (
    <ViewBox
      isOpen={stationViewOpened}
      shortcutsMenuOpen={shortcutsMenuOpen}
      translate={translate}
    >
      <StationStatus
        currentTime={currentTime}
        routesUnavailableForStation={routesUnavailableForStation}
        unidirectionalRoutesForStation={selectedStationUnidirectionalService}
        selectedStation={selectedStation}
        setSelectedRouteId={selectStationAndCloseView}
        routesWithSituation={routesWithSituation}
        trainStatusByStopId={trainStatusByStopId}
        equipmentOutageListings={elevatorEscalatorStatus}
        onAutoRefresh={onAutoRefresh}
        onCloseClick={() => setStationViewOpened(false)}
        useTimers={stationViewOpened}
        timeFilter={timeFilter}
        resetTimeFilter={resetTimeFilter}
      />
    </ViewBox>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentTime: getMapCurrentTime(state),
  currentTrayPosition: state.ui.currentTrayPosition,
  elevatorEscalatorStatus: getMapElevatorEscalatorStatus(state),
  routesUnavailableForStation: getMapRoutesUnavailableForSelectedStation(state),
  routesWithSituation: getMapRoutesWithSituation(state),
  selectedRouteId: getMapSelectedRouteId(state),
  selectedStation: getMapSelectedStation(state),
  stationViewOpened: state.ui.stationViewOpened,
  timeFilter: getMapTimeFilter(state),
  trainStatusByStopId: getMapTrainStatusByStopId(state),
  stopListsByRoute: getMapStopListsByRoute(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setCurrentTrayPosition: dispatch.ui.setCurrentTrayPosition,
  setTimeFilter: dispatch.map.setTimeFilter,
  setSelectedRouteId: dispatch.map.setSelectedRouteId,
  setStationViewOpened: dispatch.ui.setStationViewOpened,
  updateStopTrainTimes: dispatch.map.updateStopTrainTimes,
  updateElevatorEscalatorStatus: dispatch.map.updateElevatorEscalatorStatus,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationStatusContainer);
