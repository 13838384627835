import { createSelector } from 'reselect';

import { Stations } from '../../subway-data/subway-types';

import { getMapAvailableStopIdsForAllRoutes } from './getMapAvailableStopIds';
import { getMapStations } from './getMapStations';

export const getMapConnectedStations = createSelector(
  getMapAvailableStopIdsForAllRoutes,
  getMapStations,
  (
    availableStopIdsForAllRoutes: string[],
    mapStations: Stations
  ): Stations[] => {
    const groupedStations: { [key: string]: Stations } = mapStations.reduce(
      (complex, station) => {
        // Only add station with available stops
        if (
          availableStopIdsForAllRoutes.some(stopId => stopId === station.stopId)
        ) {
          complex[station.Complex_ID] = complex[station.Complex_ID] || [];
          complex[station.Complex_ID].push(station);
        }

        return complex;
      },
      {}
    );

    const complexesWithMoreThanOneStation = Object.values(
      groupedStations
    ).filter(stationsList => stationsList.length > 1);

    return complexesWithMoreThanOneStation;
  }
);
