import React, { useRef, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components/macro';
import Icon, { IconTypes, IconContainer } from '../Icon';
import {
  notTouch,
  withFocusStyle,
  withTouchPressStyle,
  desktopExtraLarge,
  tablet,
} from '../../utils/theme';
import useDebugClick from '../../utils/useDebugClick';

type CurrentLocationProps = {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onDevMenuClick?: () => void;
};

export const CurrentLocationContainer = styled.button`
  width: 50px;
  height: 50px;
  background-color: ${p => p.theme.colors.button.bg};
  color: ${p =>
    p['data-disabled'] ? 'rgba(0, 0, 0, 0.4)' : p.theme.colors.button.color};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${p => p.theme.boxShadow};
  cursor: pointer;
  outline: none;

  ${p => withFocusStyle(p.theme)}
  ${withTouchPressStyle()}

  transition: ${p => p.theme.transition};

  ${notTouch(css`
    &[data-disabled='false']:hover {
      background-color: ${p => p.theme.colors.button.bgActive};
      color: ${p => p.theme.colors.button.colorActive};
    }

    width: 32px;
    height: 32px;

    svg {
      transform: scale(0.8);
    }
  `)}

  ${tablet(css`
    width: 32px;
    height: 32px;

    svg {
      transform: scale(0.8);
    }
  `)};

  ${desktopExtraLarge(css`
    width: 40px;
    height: 40px;

    svg {
      transform: scale(1);
    }
  `)};

  div {
    display: block;
  }
`;

const CurrentLocation: React.FC<CurrentLocationProps> = ({
  onClick,
  onDevMenuClick,
  disabled,
  ...props
}) => {
  const containerRef = useRef<HTMLButtonElement>(null);

  useDebugClick({ callback: onDevMenuClick!, ref: containerRef });

  return (
    <CurrentLocationContainer
      data-cy="location-control"
      data-disabled={disabled}
      onClick={onClick}
      ref={containerRef}
      {...props}
    >
      <IconContainer>
        <Icon type={IconTypes.Location} />
      </IconContainer>
    </CurrentLocationContainer>
  );
};

export default CurrentLocation;
