import {
  getPatternGraphDateTimeStrings,
  getTimesAllDay,
  now,
} from '../../utils/date.utils';
import { OtpRouteId, RouteDirection, StopLists } from '../subway-types';
import loadRouteStopsAtTime from '../../services/loadRouteStopsAtTime';
import zip from 'lodash/zip';
import flatten from 'lodash/flatten';

interface TimeTableRow {
  routeId: OtpRouteId;
  directionId: RouteDirection;
  dateString: string;
  timeString: string;
  numStops: number;
}

type PatternGraphTimeTable = TimeTableRow[];

const fetchRouteStopsTimeTable = async ({
  routeId,
  date = now(),
  minutesBetweenTimes = 60,
  enableParallel = false,
}: {
  routeId: OtpRouteId;
  date?: Date;
  minutesBetweenTimes?: number;
  enableParallel?: boolean;
}): Promise<PatternGraphTimeTable> => {
  const dateTimes: Date[] = getTimesAllDay(date, minutesBetweenTimes);
  const directionIds: RouteDirection[] = ['0', '1'];

  type Input = {
    routeId: OtpRouteId;
    date: Date;
    directionId: RouteDirection;
  };

  type Output = StopLists;

  const inputs: Input[] = [];
  for (const directionId of directionIds) {
    for (const dateTime of dateTimes) {
      inputs.push({
        routeId,
        date: dateTime,
        directionId,
      });
    }
  }

  let outputs: Output[] = [];
  if (enableParallel) {
    outputs = await Promise.all(inputs.map(loadRouteStopsAtTime));
  } else {
    for (const input of inputs) {
      outputs.push(await loadRouteStopsAtTime(input));
    }
  }

  // This type cast is here to assert optimistically that pair items will not be undefined,
  // because zip cautiously assumes items could be undefined, in case the arrays are different lengths
  const inputOutputPairs = zip(inputs, outputs) as [Input, Output][];
  const rows: TimeTableRow[] = inputOutputPairs.map(([input, output]) => {
    const { dateString, timeString } = getPatternGraphDateTimeStrings(
      input.date
    );
    const row: TimeTableRow = {
      routeId,
      directionId: input.directionId,
      dateString,
      timeString,
      numStops: flatten(output).length,
    };
    return row;
  });

  return rows;
};

export default fetchRouteStopsTimeTable;
