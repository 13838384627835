/**
 * Complex stations at which more than one stopId is present.
 * Maps the primary stopId (used in our current logic/data) to secondary ones used in other data sources.
 * These are kept in alphabetical order for readability.
 */
export const complexStations: { [stopId: string]: string[] } = {
  127: ['127', 'R16', '902', '725'], // Times Sq-42 St' ! check for all lines
  222: ['222', '415'], // 149 St - Grand Concourse
  232: ['232', '423'], //Borough Hall
  235: ['235', 'D24'], // Atlantic Av - Barclays Ctr (2,3,4,5,Q,B)
  631: ['631', '723', '901'], // Grand Central - 42 St
  719: ['719', 'G22'], // Court Sq
  A12: ['A12', 'D13'], // 145 St
  A24: ['A24', '125'], // 59 St - Columbus Circle
  A32: ['A32', 'D20'], // W 4 St
  A38: ['A38', 'M22'], // Fulton St
  A41: ['A41', 'R29'], //Jay St - MetroTech
  D11: ['D11', '414'], // 161 St - Yankee Stadium
  J27: ['J27', 'L22', 'A51'], // Broadway Jct
  L17: ['L17', 'M08'], // Myrtle - Wyckoff Avs
  M18: ['M18', 'F15'], // Delancey St
  Q01: ['M20', '639'], // Canal St - (4,5,6,J,Z)
  R23: ['Q01', 'R23', 'M20', '639'], // Canal St - (R,W,N,Q)
  R09: ['R09', '718'], // Queensboro Plaza
  R17: ['R17', 'D17'], // 34 St Herald Sq
  R20: ['R20', 'L03', '635'], // Union Sq - 14 St
};

/**
 * Handles cases where a patternGraph stop ID does not exist in our map's
 * customized data, due to manual station data joining.
 */
export const findPrimaryStopIdFromAlternative = (
  alternativeStopId: string
): string => {
  return (
    Object.keys(complexStations).find(key =>
      complexStations[key].includes(alternativeStopId)
    ) ?? alternativeStopId
  );
};
