import { OtpRouteId, SubwayRouteId } from './subway-types';
import { lighten } from 'polished';

/**
 * These colors are from MTA's Vignelli 2012 diagram "MTA maps.sketch"
 */
export const subwayRouteColors: {
  [K in SubwayRouteId | OtpRouteId]: string;
} = {
  '1': '#FF714D',
  '2': '#FF714D',
  '3': '#FF714D',
  '4': '#3CBE3C',
  '5': '#3CBE3C',
  '6': '#3CBE3C',
  '7': '#BF60BF',
  A: '#33BBFF',
  C: '#33BBFF',
  E: '#33BBFF',
  G: '#AED92B',
  B: '#FF9600',
  D: '#FF9600',
  F: '#FF9600',
  M: '#FF9600',
  J: '#B37F2D',
  Z: '#B37F2D',
  L: '#898888',
  GS: '#545661',
  H: '#545661',
  FS: '#545661',
  N: '#FFD700',
  Q: '#FFD700',
  R: '#FFD700',
  W: '#FFD700',
  SI: '#33BBFF',
  // OTP ids
  '6X': '#3CBE3C',
  '7X': '#BF60BF',
  FX: '#FF9600',
};

/**
 * These colors are from MTA's website
 */
export const subwayRouteHoverColors: {
  [K in SubwayRouteId | OtpRouteId]: string;
} = {
  '1': '#EE0900',
  '2': '#EE0900',
  '3': '#EE0900',
  '4': '#057500',
  '5': '#057500',
  '6': '#057500',
  '7': '#B200A2',
  A: '#0039A6',
  C: '#0039A6',
  E: '#0039A6',
  G: '#6CBE45',
  B: '#FF6800',
  D: '#FF6800',
  F: '#FF6800',
  M: '#FF6800',
  J: '#824100',
  Z: '#824100',
  L: '#5c5b5b',
  GS: '#3c3e42',
  H: '#3c3e42',
  FS: '#3c3e42',
  N: '#FCBB0A',
  Q: '#FCBB0A',
  R: '#FCBB0A',
  W: '#FCBB0A',
  SI: '#0039A6',
  // OTP ids
  '6X': '#057500',
  '7X': '#B200A2',
  FX: '#FF6800',
};

/**
 * These colors are from MTA's website
 */

const lightenAmount = 0.3;

export const subwayRouteInactiveColors: {
  [K in SubwayRouteId | OtpRouteId]: string;
} = {
  '1': lighten(lightenAmount, '#FF714D'),
  '2': lighten(lightenAmount, '#FF714D'),
  '3': lighten(lightenAmount, '#FF714D'),
  '4': lighten(lightenAmount, '#3CBE3C'),
  '5': lighten(lightenAmount, '#3CBE3C'),
  '6': lighten(lightenAmount, '#3CBE3C'),
  '7': lighten(lightenAmount, '#BF60BF'),
  A: lighten(lightenAmount, '#33BBFF'),
  C: lighten(lightenAmount, '#33BBFF'),
  E: lighten(lightenAmount, '#33BBFF'),
  G: lighten(lightenAmount, '#AED92B'),
  B: lighten(lightenAmount, '#FF9600'),
  D: lighten(lightenAmount, '#FF9600'),
  F: lighten(lightenAmount, '#FF9600'),
  M: lighten(lightenAmount, '#FF9600'),
  J: lighten(lightenAmount, '#B37F2D'),
  Z: lighten(lightenAmount, '#B37F2D'),
  L: lighten(0.2, '#898888'), // L train is an exception at 0.2
  GS: lighten(lightenAmount, '#545661'),
  H: lighten(lightenAmount, '#545661'),
  FS: lighten(lightenAmount, '#545661'),
  N: lighten(lightenAmount, '#FFD700'),
  Q: lighten(lightenAmount, '#FFD700'),
  R: lighten(lightenAmount, '#FFD700'),
  W: lighten(lightenAmount, '#FFD700'),
  SI: lighten(lightenAmount, '#33BBFF'),
  // OTP ids
  '6X': lighten(lightenAmount, '#3CBE3C'),
  '7X': lighten(lightenAmount, '#BF60BF'),
  FX: lighten(lightenAmount, '#FF9600'),
};

export const subwayRouteHasDarkText: { [K in SubwayRouteId]?: boolean } = {
  N: true,
  Q: true,
  R: true,
  W: true,
};

export const subwayRouteHasGreyColor: { [K in SubwayRouteId]?: boolean } = {
  L: true,
  H: true,
  FS: true,
  GS: true,
};
