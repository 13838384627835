import React from 'react';

export enum ShortcutIconsEnum {
  ArrowUp = 'arrowUp',
  ArrowDown = 'arrowDown',
  ArrowLeft = 'arrowLeft',
  ArrowRight = 'arrowRight',
  Minus = 'minus',
  Plus = 'plus',
}

interface ShortcutIconsProps {
  type: ShortcutIconsEnum;
}

const ShortcutIcons: React.FC<ShortcutIconsProps> = ({ type }) => {
  switch (type) {
    case ShortcutIconsEnum.ArrowUp:
    case ShortcutIconsEnum.ArrowLeft:
    case ShortcutIconsEnum.ArrowRight:
    case ShortcutIconsEnum.ArrowDown:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
          <path
            fill="none"
            fillRule="nonzero"
            stroke="#000"
            strokeLinejoin="round"
            d="M10.7136817 13.99923121L7.2711455 9.6178216c-.3412134-.4342717-.2657748-1.0629265.1684968-1.4041399C7.6158279 8.0752501 7.8333999 8 8.0574638 8h6.88507236c.55228475 0 1 .4477153 1 1 0 .2240639-.07525015.441636-.21368167.6178216l-3.44253615 4.38140961c-.34121344.43427162-.96986824.5097102-1.40413994.16849678a.99999813.99999813 0 01-.1684967-.16849678z"
          />
        </svg>
      );
    case ShortcutIconsEnum.Minus:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
          <g fill="#000" fillRule="nonzero" transform="rotate(-90 11.5 10.5)">
            <rect width="1" height="7" x="10" y="7" rx=".5" />
          </g>
        </svg>
      );
    case ShortcutIconsEnum.Plus:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
          <g fill="#000" fillRule="nonzero" transform="rotate(-90 11.5 4.5)">
            <rect width="1" height="9" x="4" rx=".5" />
            <rect
              width="1"
              height="9"
              x="4"
              rx=".5"
              transform="rotate(90 4.5 4.5)"
            />
          </g>
        </svg>
      );
    default:
      return null;
  }
};

export default ShortcutIcons;
