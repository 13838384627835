import { Feature, Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style } from 'ol/style';

import { Point } from '../../geometry/geometry-types';
import { drawDot, drawTriangle } from '../../geometry/paths';
import { RoutesUnavailable } from '../../subway-data';
import { Stop } from '../../subway-data/subway-types';
import { getDevQaUrlData } from '../../utils/url.utils';

import { RENDER_BUFFER_ICONS, STATION_SCALE } from '../maps-constants';
import { getMapTheme } from '../maps-theme';
import { getRouteLineWidth } from '../maps-utils';
import { LayerRenderingProps } from '../subway-openlayers-graphics';

const getStyle = (
  routesUnavailable: RoutesUnavailable,
  useDarkMap: boolean
): Style => {
  return new Style({
    // Use a custom renderer because ol.style.Circle is pixel-doubling on high pixel-ratio displays
    renderer: (coordinates, state) => {
      const { feature, resolution } = state;
      const stop = feature.get('stop') as Stop;
      const rotationAngle = feature.get('rotationAngle') as number;
      const isUnavailable = routesUnavailable[stop.routeId] || stop.unavailable;
      const colorsToUse = getMapTheme(useDarkMap).dot;

      const radius = (3 / resolution) * STATION_SCALE;
      const dotColor = isUnavailable
        ? colorsToUse.colorUnavailable
        : colorsToUse.color;
      const direction = getDevQaUrlData().direction ?? stop.direction;

      if (direction !== undefined) {
        return drawTriangle(
          coordinates as Point,
          state,
          getRouteLineWidth(state.pixelRatio, resolution),
          dotColor,
          rotationAngle
        );
      } else {
        return drawDot(coordinates as Point, state, radius, dotColor);
      }
    },
  });
};

let subwayStationDotsLayer: VectorLayer;
export const addSubwayStationDotsLayer = ({
  allFeaturesForAllRoutes,
  map,
  routeId,
  routesUnavailable,
  soloFeaturesForCurrentRoute,
  useDarkMap,
}: Pick<LayerRenderingProps, 'routeId' | 'routesUnavailable'> & {
  allFeaturesForAllRoutes: Feature[];
  map: Map;
  routesUnavailable: RoutesUnavailable;
  soloFeaturesForCurrentRoute: Feature[];
  useDarkMap: boolean;
}) => {
  const features = (routeId
    ? soloFeaturesForCurrentRoute
    : allFeaturesForAllRoutes
  ).filter(feature => {
    const stop: Stop | undefined = feature.get('stop');
    // e.g. Hide the dot for A on Lafayette Av because the A train doesn't stop there
    return stop && stop.stopType !== '2';
  });
  const style = getStyle(routesUnavailable, useDarkMap);

  if (subwayStationDotsLayer) {
    subwayStationDotsLayer.setSource(
      new VectorSource({
        features,
      })
    );
    subwayStationDotsLayer.setStyle(style);
  } else {
    subwayStationDotsLayer = new VectorLayer({
      minZoom: 12.999,
      renderBuffer: RENDER_BUFFER_ICONS,
      source: new VectorSource({
        features,
      }),
      style,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(subwayStationDotsLayer);
  }
};
