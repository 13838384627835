import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components/macro';
import App, { AppProps } from '../components/App';
import GlobalStyles from '../components/GlobalStyles';
import { AppState, AppDispatch } from '../models';
import {
  getMapAda,
  getMapCenter,
  getMapZoom,
  getMapSelectedRouteId,
  getUseDarkMap,
  getMapTimeFilter,
} from '../selectors/map/basic';
import { getMapRoutesUnavailable } from '../selectors/map/getMapRoutesUnavailable';
import { getUIInitialized } from '../selectors/ui';
import { ServiceStatus } from '../subway-data/subway-types';
import theme, { darkTheme } from '../utils/theme';
import UrlState from '../utils/url.utils';
import OpenLayersBitmap from '../maps/OpenLayersBitmap';
import { getMapRouteStatus } from '../selectors/map/getMapRouteStatus';

const ContainerWrapper = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
`;

interface AppContainerProps extends AppProps {
  // State
  initialized: boolean;
  coordinates: {
    lat: number;
    lon: number;
  };
  serviceStatus: ServiceStatus;
  // Actions
  bootstrapMap: () => void;
  setInitialized: (initialized: boolean) => void;
}
const AppContainer: React.FC<AppContainerProps> = props => {
  const {
    ada,
    bootstrapMap,
    coordinates,
    initialized,
    selectedRouteId,
    setInitialized,
    useDarkMap,
    vaccineLocationsVisible,
    zoom,
  } = props;

  useEffect(() => {
    bootstrapMap();
  }, [bootstrapMap]);

  // Sync URL hash to changes in map view
  useEffect(() => {
    // Don't update the URL on initial firing
    // This allows initializing from custom URLs instead of overwriting to default coordinates
    if (initialized) {
      const { lat, lon } = coordinates;
      UrlState.set({
        ada,
        lat,
        lon,
        selectedRouteId,
        vaccineLocations: vaccineLocationsVisible,
        zoom,
      });
    } else {
      setInitialized(true);
    }
  }, [
    ada,
    coordinates,
    initialized,
    selectedRouteId,
    setInitialized,
    vaccineLocationsVisible,
    zoom,
  ]);

  return (
    <ThemeProvider theme={useDarkMap ? darkTheme : theme}>
      <>
        <GlobalStyles />
        <ContainerWrapper>
          <OpenLayersBitmap />
          <App {...props} />
        </ContainerWrapper>
      </>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  ada: getMapAda(state),
  airportTerminalViewOpened: state.ui.airportTerminalViewOpened,
  currentTime: state.map.currentTime,
  coordinates: getMapCenter(state),
  controlsTranslate: state.ui.controlsTranslate,
  currentTrayPosition: state.ui.currentTrayPosition,
  emergencyAlert: state.map.emergencyAlert,
  emergencyAlertOpened: state.ui.emergencyAlertOpened,
  errorType: state.ui.errorType,
  geolocationEnabled: state.ui.geolocationEnabled,
  initialized: getUIInitialized(state),
  routeMenuOpened: state.ui.routeMenuOpened,
  routesUnavailable: getMapRoutesUnavailable(state),
  selectedRouteId: getMapSelectedRouteId(state),
  selectedRouteStatus: getMapRouteStatus(state),
  shortcutsMenuOpen: state.ui.shortcutsMenuOpen,
  stationViewOpened: state.ui.stationViewOpened,
  statusViewOpened: state.ui.statusViewOpened,
  timeFilter: getMapTimeFilter(state),
  useDarkMap: getUseDarkMap(state),
  vaccineLocations: state.map.vaccineLocations,
  vaccineLocationsVisible: state.map.vaccineLocationsVisible,
  vaccinationViewOpened: state.ui.vaccinationViewOpened,
  zoom: getMapZoom(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  bootstrapMap: dispatch.map.bootstrapMap,
  centerMapOnGeolocation: dispatch.map.centerMapOnGeolocation,
  requestCloseErrorModal: dispatch.ui.requestCloseErrorModal,
  setAirportTerminalViewOpened: dispatch.ui.setAirportTerminalViewOpened,
  setCloseAllOverlays: dispatch.ui.setCloseAllOverlays,
  setCurrentTrayPosition: dispatch.ui.setCurrentTrayPosition,
  setEmergencyAlertOpened: dispatch.ui.setEmergencyAlertOpened,
  setInitialized: dispatch.ui.setInitialized,
  setIsDebugMenuOpen: dispatch.ui.setIsDebugMenuOpen,
  setRouteMenuOpened: dispatch.ui.setRouteMenuOpened,
  setSelectedRouteId: dispatch.map.setSelectedRouteId,
  setShortcutsMenuOpen: dispatch.ui.setShortcutsMenuOpen,
  setStationViewOpened: dispatch.ui.setStationViewOpened,
  setStatusViewOpened: dispatch.ui.setStatusViewOpened,
  setVaccinationViewOpened: dispatch.ui.setVaccinationViewOpened,
  setTimeFilter: dispatch.map.setTimeFilter,
  setMapZoom: dispatch.map.setMapZoom,
  toggleADA: dispatch.map.toggleADA,
  toggleVaccineLocationsVisible: dispatch.map.toggleVaccineLocationsVisible,
  zoomIn: dispatch.map.zoomIn,
  zoomOut: dispatch.map.zoomOut,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
