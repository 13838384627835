import {
  orderedSubwayRouteIds,
  RoutesWithSituation,
  TimeFilter,
} from '../subway-data';
import loadRouteStatus from './loadRouteStatus';
import { RouteStatus, ServiceStatus } from '../subway-data/subway-types';
import { CACHE_EXPIRACY_DURATION } from '../config';

const OKAY_CATEGORIES = ['Weekend Service', 'Weekday Service'];

// TODO: return route status data instead of firing dispatch + returning partial data
const getRoutesWithSituation = async (
  timeFilter: TimeFilter,
  cachedServiceStatus: ServiceStatus,
  dispatch
): Promise<RoutesWithSituation> => {
  const timestamp = new Date().getTime();
  const routesWithSituation: RoutesWithSituation = {};
  const promises: Promise<RouteStatus>[] = [];

  orderedSubwayRouteIds.forEach(routeId => {
    const cachedRouteStatus: RouteStatus | undefined =
      cachedServiceStatus[timeFilter][routeId];

    if (
      cachedRouteStatus &&
      timestamp - cachedRouteStatus.lastUpdated < CACHE_EXPIRACY_DURATION
    ) {
      // const isDisabledRouteDuringWeekend = routeId === 'B' || routeId === 'Z';
      // const isAvailable =
      //   timeFilter !== 'weekend' || !isDisabledRouteDuringWeekend;
      const hasSituation =
        cachedRouteStatus.statusDetails.filter(status => {
          if (status && status.statusSummary) {
            return !OKAY_CATEGORIES.includes(status.statusSummary);
          }

          return false;
        }).length > 0;

      routesWithSituation[routeId] = hasSituation;
    } else {
      promises.push(loadRouteStatus(routeId, timeFilter));
    }
  });

  const routeStatusList = await Promise.all(promises);

  // TODO: clean up and unify with the cache logic above
  routeStatusList.forEach(routeStatus => {
    // const isDisabledRouteDuringWeekend =
    //   routeStatus.routeId === 'B' || routeStatus.routeId === 'Z';
    // const isAvailable =
    //   timeFilter !== 'weekend' || !isDisabledRouteDuringWeekend;

    const hasSituation =
      routeStatus.statusDetails.filter(status => {
        if (status && status.statusSummary) {
          return !OKAY_CATEGORIES.includes(status.statusSummary);
        }

        return false;
      }).length > 0;

    routesWithSituation[routeStatus.routeId] = hasSituation;
  });

  dispatch.map.setRoutesStatus({
    timeFilter,
    routeStatusList,
  });

  return routesWithSituation;
};

export default getRoutesWithSituation;
