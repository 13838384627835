import round from 'lodash/round';
import { Feature, Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Stroke, Text, Fill } from 'ol/style';
import { StyleFunction } from 'ol/style/Style';

import theme from '../../utils/theme';

import { EMPTY_STYLE, RENDER_BUFFER_LABELS } from '../maps-constants';
import { getMapTheme } from '../maps-theme';
import { ZoomLevel } from '../map-types';
import { getOlMapZoom, getProportionalValueForZoom } from '../maps-utils';

const getStyleFunction = (useDarkMap: boolean): StyleFunction => (
  feature,
  resolution
) => {
  const lineLabel = feature.get('lineLabel') as string;
  const colorsToUse = getMapTheme(useDarkMap).labels;
  let currentZoom = getOlMapZoom(resolution);

  if (!lineLabel) {
    return EMPTY_STYLE;
  }

  const fontSize = round(
    getProportionalValueForZoom(currentZoom, {
      [ZoomLevel.z15]: 11,
      [ZoomLevel.z16]: 13,
      [ZoomLevel.z17]: 18,
      [ZoomLevel.z18]: 30,
    }),
    2
  );
  const offsetY = round(
    getProportionalValueForZoom(currentZoom, {
      [ZoomLevel.z14]: 0,
      [ZoomLevel.z15]: 5,
      [ZoomLevel.z17]: 10,
      [ZoomLevel.z18]: 18,
    }),
    2
  );

  return new Style({
    text: new Text({
      fill: new Fill({
        color: colorsToUse.active,
      }),
      font: `bold ${fontSize}px ${theme.fonts.default}`,
      offsetY,
      placement: 'line',
      stroke: new Stroke({
        color: colorsToUse.outline,
        width: 2,
      }),
      rotation: 180,
      text: lineLabel,
      textAlign: feature.get('lineLabelAlign') as string,
      textBaseline: 'top',
    }),
  });
};

let airportRouteLabelsLayer: VectorLayer;
export const addAirportRouteLabelsLayer = ({
  airportsRouteStationsFeatures,
  map,
  useDarkMap,
}: {
  airportsRouteStationsFeatures: Feature[];
  map: Map;
  useDarkMap: boolean;
}) => {
  if (airportRouteLabelsLayer) {
    airportRouteLabelsLayer.setSource(
      new VectorSource({
        features: airportsRouteStationsFeatures,
      })
    );
    airportRouteLabelsLayer.setStyle(getStyleFunction(useDarkMap));
  } else {
    airportRouteLabelsLayer = new VectorLayer({
      minZoom: 13.999,
      renderBuffer: RENDER_BUFFER_LABELS,
      source: new VectorSource({
        features: airportsRouteStationsFeatures,
      }),
      style: getStyleFunction(useDarkMap),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(airportRouteLabelsLayer);
  }
};
