export enum EquipmentType {
  'ES' = 'Escalator',
  'EL' = 'Elevator',
}

type EquipmentTypeKey = keyof typeof EquipmentType;

type YesOrNo = 'Y' | 'N';

export interface NYCEquipmentsPayload {
  NYCEquipments: NYCEquipments;
}

export interface NYCEquipments {
  responsecode: string;
  message: string;
  equipment: EquipmentListing[];
}

export interface EquipmentListing {
  station: string;
  borough: string;
  trainno: string;
  equipmentno: string;
  equipmenttype: EquipmentTypeKey;
  serving: string;
  ADA: YesOrNo;
  isactive: YesOrNo;
  shortdescription: string;
  linesservedbyelevator: string;
  elevatorsgtfsstopid: string;
  elevatormrn: string;
  stationcomplexid: string;
}

export interface NYCOutagesPayload {
  NYCOutages: NYCOutages;
}

export interface NYCOutages {
  responsecode: string;
  message: string;
  outage: OutageListing[];
}

export interface OutageListing {
  station: string;
  borough: string;
  trainno: string;
  equipment: string;
  equipmenttype: EquipmentTypeKey;
  serving: string;
  ADA: YesOrNo;
  outagedate: string;
  estimatedreturntoservice: string;
  reason: string;
  isupcomingoutage: YesOrNo;
  ismaintenanceoutage: YesOrNo;
}

export interface ElevatorEscalatorStatus {
  outages: {
    [stopId: string]: OutageListing[];
  };
  equipmentTotalsByStopId: {
    [stopId: string]: number;
  };
}

export type EquipmentOutageByEquipmentNumber = {
  [equipmentno: string]: OutageListing;
};

export interface FilteredOutage {
  listing: OutageListing;
  count: number;
}
