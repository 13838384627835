import React, { useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import FocusTrap from 'focus-trap-react';
import ModalOverlay from '../ModalOverlay';
import Icon, { IconContainer, IconTypes } from '../Icon';
import { desktop } from '../../utils/theme';
import { ShortcutIconsEnum } from './ShortcutsIcon';
import ShortcutsList, { Item } from './ShortcutsList';
import { USE_VACCINE_LOCATIONS_ON_MAP } from '../../maps/maps-constants';

const shortcutsLists: { items: Item[] }[] = [
  {
    items: [
      { type: 'heading', value: 'Map Selected' },
      {
        type: 'shortcut',
        label: 'Move Left',
        keys: [ShortcutIconsEnum.ArrowLeft],
      },
      {
        type: 'shortcut',
        label: 'Move Right',
        keys: [ShortcutIconsEnum.ArrowRight],
      },
      {
        type: 'shortcut',
        label: 'Move Up',
        keys: [ShortcutIconsEnum.ArrowUp],
      },
      {
        type: 'shortcut',
        label: 'Move Down',
        keys: [ShortcutIconsEnum.ArrowDown],
      },
      {
        type: 'shortcut',
        label: 'Zoom In',
        keys: [ShortcutIconsEnum.Plus],
      },
      {
        type: 'shortcut',
        label: 'Zoom Out',
        keys: [ShortcutIconsEnum.Minus],
      },
      {
        type: 'shortcut',
        label: 'Close modal',
        keys: ['ESC'],
      },
      {
        type: 'shortcut',
        label: 'Show your location',
        keys: ['Ctrl', 'Shift', 'L'],
      },
      {
        type: 'shortcut',
        label: 'Open Keyboard Shortcuts Modal',
        keys: ['Shift', '?'],
      },
    ],
  },
  {
    items: [
      { type: 'heading', value: 'Filtering Route' },
      {
        type: 'shortcut',
        label: 'Select Route A',
        keys: ['A'],
      },
      {
        type: 'shortcut',
        label: 'Select Route B',
        keys: ['B'],
      },
      {
        type: 'shortcut',
        label: 'Select Route C',
        keys: ['C'],
      },
      {
        type: 'shortcut',
        label: 'Select Route D',
        keys: ['D'],
      },
      {
        type: 'shortcut',
        label: 'Select Route E',
        keys: ['E'],
      },
      {
        type: 'shortcut',
        label: 'Select Route F',
        keys: ['F'],
      },
      {
        type: 'shortcut',
        label: 'Select Route G',
        keys: ['G'],
      },
      {
        type: 'shortcut',
        label: 'Select Route J',
        keys: ['J'],
      },
      {
        type: 'shortcut',
        label: 'Select Route L',
        keys: ['L'],
      },
      {
        type: 'shortcut',
        label: 'Select Route M',
        keys: ['M'],
      },
      {
        type: 'shortcut',
        label: 'Select Route N',
        keys: ['N'],
      },
      {
        type: 'shortcut',
        label: 'Select Route Q',
        keys: ['Q'],
      },
    ],
  },
  {
    items: [
      { type: 'spacer', spacing: 48 },
      {
        type: 'shortcut',
        label: 'Select Route R',
        keys: ['R'],
      },
      {
        type: 'shortcut',
        label: 'Select Route S',
        keys: ['S'],
      },
      {
        type: 'shortcut',
        label: 'Select Route SIR',
        keys: ['Shift', 'S'],
      },
      {
        type: 'shortcut',
        label: 'Select Route SF',
        keys: ['Shift', 'F'],
      },
      {
        type: 'shortcut',
        label: 'Select Route SR',
        keys: ['Shift', 'R'],
      },
      {
        type: 'shortcut',
        label: 'Select Route W',
        keys: ['W'],
      },
      {
        type: 'shortcut',
        label: 'Select Route Z',
        keys: ['Z'],
      },
      {
        type: 'shortcut',
        label: 'Select Route 1',
        keys: ['1'],
      },
      {
        type: 'shortcut',
        label: 'Select Route 2',
        keys: ['2'],
      },
      {
        type: 'shortcut',
        label: 'Select Route 3',
        keys: ['3'],
      },
      {
        type: 'shortcut',
        label: 'Select Route 4',
        keys: ['4'],
      },
      {
        type: 'shortcut',
        label: 'Select Route 5',
        keys: ['5'],
      },
    ],
  },
  {
    items: [
      { type: 'spacer', spacing: 48 },
      {
        type: 'shortcut',
        label: 'Select Route 6',
        keys: ['6'],
      },
      {
        type: 'shortcut',
        label: 'Select Route 7',
        keys: ['7'],
      },
      { type: 'spacer', spacing: 39 },
      {
        type: 'heading',
        value: 'Full Map',
      },
      {
        type: 'shortcut',
        label: 'Toggle Time Filter',
        keys: ['T'],
      },
      {
        type: 'shortcut',
        label: 'Toggle a11y filter',
        keys: ['Shift', 'A'],
      },
      USE_VACCINE_LOCATIONS_ON_MAP
        ? {
            type: 'shortcut',
            label: 'Toggle vaccine locations',
            keys: ['Shift', 'V'],
          }
        : { type: 'shortcut' },
    ],
  },
];

const ModalWrapper = styled(ModalOverlay)<{ isOpen: boolean }>`
  align-items: center;
  display: flex !important;
  justify-content: center;
  visibility: hidden;
  pointer-events: none;

  ${p =>
    p.isOpen &&
    css`
      visibility: initial;
      pointer-events: all;
    `};
`;

const ModalContainer = styled.div<{ isOpen: boolean }>`
  background: ${p => p.theme.colors.white};
  overflow-y: scroll;
  padding: 24px;
  height: 100vh;
  opacity: 0;
  transition: ${p => p.theme.modalTransition};
  transition-delay: 0;
  width: 100%;

  ${p =>
    p.isOpen &&
    css`
      opacity: 1;
      transition-delay: 0.5s;
    `}

  ${desktop(css`
    height: initial;
    overflow-y: hidden;
    padding: 50px 70px;
    width: 75vw;
  `)}
`;

const ModalHeader = styled.header`
  border-top: 2px solid ${p => p.theme.colors.black};
  display: flex;
  justify-content: space-between;
  padding-top: 8px;

  h1 {
    font-size: 4.2rem;
    letter-spacing: -0.01rem;
    line-height: 1.24;
  }

  button {
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    height: 30px;
    justify-content: center;
    width: 30px;

    &:focus.focus-visible {
      border: 1px dashed ${p => p.theme.colors.black};
    }
  }
`;

const ModalContent = styled.div`
  ${desktop(css`
    column-gap: 48px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 56px;
  `)}
`;

interface ShortcutsModalProps {
  closeModal: () => void;
  isOpen: boolean;
}
const ShortcutsModal: React.FC<ShortcutsModalProps> = ({
  closeModal,
  isOpen,
}) => {
  const header = useCallback(() => {
    return (
      <ModalHeader>
        <h1>Keyboard Shortcuts</h1>
        <button onClick={closeModal}>
          <IconContainer>
            <Icon type={IconTypes.Cross} />
          </IconContainer>
        </button>
      </ModalHeader>
    );
  }, [closeModal]);

  return (
    <ModalWrapper isOpen={isOpen} color="transparent">
      <ModalContainer isOpen={isOpen}>
        {isOpen ? <FocusTrap>{header()}</FocusTrap> : header()}
        <ModalContent>
          {shortcutsLists.map((list, listIndex) => (
            <ShortcutsList key={`l-${listIndex}`} items={list.items} />
          ))}
        </ModalContent>
      </ModalContainer>
    </ModalWrapper>
  );
};

export default ShortcutsModal;
