import { differenceInDays } from './date.utils';
import { UIErrorType } from '../models/ui';
import { LOCAL_STORAGE_ERROR_PROMPTS_KEY } from '../config';

interface LocalStorageErrorPrompts {
  values: {
    [key in UIErrorType]?: number;
  };
}

const storeErrorPromptsObject = (
  errorPrompts: LocalStorageErrorPrompts
): void => {
  const value = JSON.stringify(errorPrompts);
  localStorage.setItem(LOCAL_STORAGE_ERROR_PROMPTS_KEY, value);
};

const getErrorPromptsObject = (): LocalStorageErrorPrompts => {
  const value = localStorage.getItem(LOCAL_STORAGE_ERROR_PROMPTS_KEY);

  if (value) {
    return JSON.parse(value);
  }

  return { values: {} };
};

export const setLastSeenPrompt = (
  errorType: UIErrorType,
  date: number = new Date().getTime()
): void => {
  const errorPrompts = getErrorPromptsObject();
  errorPrompts.values[errorType] = date;

  storeErrorPromptsObject(errorPrompts);
};

export const getDaysCountLastSeenPrompt = (errorType: UIErrorType): number => {
  const object = getErrorPromptsObject();
  const today = new Date().getTime();

  if (object.values[errorType] === undefined) {
    return -1;
  }

  const difference = differenceInDays(object.values[errorType]!, today);

  return difference;
};
