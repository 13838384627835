import xml2js from 'xml2js-es6-promise';
import {
  ElevatorEscalatorStatus,
  EquipmentOutageByEquipmentNumber,
  NYCOutagesPayload,
  NYCEquipmentsPayload,
} from '../subway-data/elevator-escalator-status-types';
import { CACHE_EXPIRACY_DURATION } from '../config';

// All Elevators and Escalators in the NYC Subway System.
// Updated only when there is a change in the equipment inventory.
const ELEVATOR_ESCALATOR_EQUIPMENT_ENDPOINT =
  'https://api-endpoint.mta.info/Dataservice/mtagtfsfeeds/nyct%2fnyct_ene_equipments.xml';

// Elevator/Escalator equipments that are undergoing scheduled maintainence or is known to be not working.
// Refreshed every 5 minutes.
const ELEVATOR_ESCALATOR_STATUS_ENDPOINT =
  'https://api-endpoint.mta.info/Dataservice/mtagtfsfeeds/nyct%2fnyct_ene.xml';

// TODO:  verify if we need a specific key (this is one I generated in MTA dev portal)
const xApiKey = 'KjuU3wmp257OhPmBHxrbu81pVTV0jOK3aVN0LnXu';
const headers = {
  'x-api-key': xApiKey,
};

export const loadXMLtoJSON = async (endpoint: string) => {
  try {
    const payload = await fetch(endpoint, { headers })
      .then(res => res.text())
      .then(xml => xml2js(xml, { explicitArray: false }));

    return payload;
  } catch (err) {
    console.error(err);
  }
};

let lastElevatorEscalatorUpdate: number;
let mapStopIdToEquipment: ElevatorEscalatorStatus;
export const loadElevatorEscalatorStatusWithStopId = async () => {
  const timestamp = new Date().getTime();

  if (
    !lastElevatorEscalatorUpdate ||
    timestamp - lastElevatorEscalatorUpdate > CACHE_EXPIRACY_DURATION
  ) {
    lastElevatorEscalatorUpdate = new Date().getTime();

    try {
      const elevatorEscalatorEquipmentsPayload: NYCEquipmentsPayload = await loadXMLtoJSON(
        ELEVATOR_ESCALATOR_EQUIPMENT_ENDPOINT
      );
      const elevatorEscalatorOutagesPayload: NYCOutagesPayload = await loadXMLtoJSON(
        ELEVATOR_ESCALATOR_STATUS_ENDPOINT
      );

      const equipmentOutageByEquipmentNo: EquipmentOutageByEquipmentNumber = {};
      elevatorEscalatorOutagesPayload?.NYCOutages.outage.forEach(equipment => {
        equipmentOutageByEquipmentNo[equipment.equipment] = equipment;
      });

      // Generate an Up-to-date mapping of current equipment to GTFS StopId(s)
      mapStopIdToEquipment = {
        outages: {},
        equipmentTotalsByStopId: {},
      };
      if (elevatorEscalatorEquipmentsPayload.NYCEquipments.equipment) {
        elevatorEscalatorEquipmentsPayload?.NYCEquipments.equipment.forEach(
          equipment => {
            const { equipmentno } = equipment;
            const associatedStopIds = equipment.elevatorsgtfsstopid.split('/');

            associatedStopIds?.forEach(stopId => {
              if (equipmentOutageByEquipmentNo[equipmentno]) {
                if (!mapStopIdToEquipment.outages[stopId]) {
                  mapStopIdToEquipment.outages[stopId] = [];
                }
                mapStopIdToEquipment.outages[stopId].push(
                  equipmentOutageByEquipmentNo[equipmentno]
                );
              }
              if (!mapStopIdToEquipment.equipmentTotalsByStopId[stopId]) {
                mapStopIdToEquipment.equipmentTotalsByStopId[stopId] = 0;
              }
              mapStopIdToEquipment.equipmentTotalsByStopId[stopId]++;
            });
          }
        );
      }
    } catch (err) {
      console.error(
        'Error encountered in loading elevator/escalator status:',
        err
      );
    }
  }

  return mapStopIdToEquipment;
};
