import { SubwayStationUnified } from './subway-types';

/**
 * Based on
 * http://web.mta.info/developers/data/nyct/subway/Stations.csv
 */
const stationsUnified: SubwayStationUnified[] = [
  {
    Station_ID: 1,
    Complex_ID: 1,
    stopId: 'R01',
    Division: 'BMT',
    Line: 'Astoria',
    // stopName: 'Astoria - Ditmars Blvd',
    Borough: 'Q',
    Daytime_Routes: 'N W',
    Structure: 'Elevated',
    lat: 40.775036,
    lon: -73.912034,
    North_Direction_Label: '',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['W', 'N'],
    lineIcons: ['N', 'W'],
  },
  {
    Station_ID: 2,
    Complex_ID: 2,
    stopId: 'R03',
    Division: 'BMT',
    Line: 'Astoria',
    // stopName: 'Astoria Blvd',
    Borough: 'Q',
    Daytime_Routes: 'N W',
    Structure: 'Elevated',
    lat: 40.770258,
    lon: -73.917843,
    North_Direction_Label: 'Ditmars Blvd',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['W', 'N'],
  },
  {
    Station_ID: 3,
    Complex_ID: 3,
    stopId: 'R04',
    Division: 'BMT',
    Line: 'Astoria',
    // stopName: '30 Av',
    Borough: 'Q',
    Daytime_Routes: 'N W',
    Structure: 'Elevated',
    lat: 40.766779,
    lon: -73.921479,
    North_Direction_Label: 'Astoria - Ditmars Blvd',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['W', 'N'],
  },
  {
    Station_ID: 4,
    Complex_ID: 4,
    stopId: 'R05',
    Division: 'BMT',
    Line: 'Astoria',
    // stopName: 'Broadway',
    Borough: 'Q',
    Daytime_Routes: 'N W',
    Structure: 'Elevated',
    lat: 40.76182,
    lon: -73.925508,
    North_Direction_Label: 'Astoria - Ditmars Blvd',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['W', 'N'],
  },
  {
    Station_ID: 5,
    Complex_ID: 5,
    stopId: 'R06',
    Division: 'BMT',
    Line: 'Astoria',
    // stopName: '36 Av',
    Borough: 'Q',
    Daytime_Routes: 'N W',
    Structure: 'Elevated',
    lat: 40.756804,
    lon: -73.929575,
    North_Direction_Label: 'Astoria - Ditmars Blvd',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['W', 'N'],
  },
  {
    Station_ID: 6,
    Complex_ID: 6,
    stopId: 'R08',
    Division: 'BMT',
    Line: 'Astoria',
    // stopName: '39 Av-Dutch Kills',
    Borough: 'Q',
    Daytime_Routes: 'N W',
    Structure: 'Elevated',
    lat: 40.752882,
    lon: -73.932755,
    North_Direction_Label: 'Astoria - Ditmars Blvd',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['W', 'N'],
  },
  {
    Station_ID: 7,
    Complex_ID: 613,
    stopId: 'R11',
    Division: 'BMT',
    Line: 'Astoria',
    // stopName: 'Lexington Av/59 St',
    Borough: 'M',
    Daytime_Routes: 'N W R',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.762256,
    lon: -73.967504,
    // real position
    // lat: 40.762399, // Edited for station alignment
    // lon: -73.96742, // Edited for station alignment
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N'],
    angle: -90,
  },
  {
    Station_ID: 8,
    Complex_ID: 8,
    stopId: 'R13',
    Division: 'BMT',
    Line: 'Astoria',
    // stopName: '5 Av/59 St',
    Borough: 'M',
    Daytime_Routes: 'N W R',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.764736,
    lon: -73.973438,
    // real position
    // lat: 40.764811,
    // lon: -73.973347,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N'],
    angle: -90,
  },
  {
    Station_ID: 9,
    Complex_ID: 9,
    stopId: 'R14',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: '57 St - 7 Av',
    Borough: 'M',
    Daytime_Routes: 'N Q R W',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.76468,
    lon: -73.98,
    // real position
    // lat: 40.764664,
    // lon: -73.980658,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
    angle: 0,
  },
  {
    Station_ID: 10,
    Complex_ID: 10,
    stopId: 'R15',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: '49 St',
    Borough: 'M',
    Daytime_Routes: 'N R W',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.75983,
    lon: -73.98355,
    // real position
    // lat: 40.759901,
    // lon: -73.984139,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
  },
  // {
  //   Station_ID: 11,
  //   Complex_ID: 611,
  //   stopId: 'R16',
  //   Division: 'BMT',
  //   Line: 'Broadway - Brighton',
  //   // stopName: 'Times Sq-42 St',
  //   Borough: 'M',
  //   Daytime_Routes: 'N Q R W',
  //   Structure: 'Subway',
  //   // lat: 40.754672,
  //   // lon: -73.986754,
  //   // EDITED - copied from stop 127
  //   lat: 40.75529,
  //   lon: -73.987495,
  //   North_Direction_Label: 'Uptown & Queens',
  //   South_Direction_Label: 'Downtown & Brooklyn',
  //   // EDITED
  //   Daytime_Routes_Array: ['1', '2', '3', 'R', 'W', 'N', 'Q', '7', 'S'],
  // },
  {
    Station_ID: 12,
    Complex_ID: 607,
    stopId: 'R17',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: '34 St - Herald Sq',
    Borough: 'M',
    Daytime_Routes: 'N Q R W',
    Structure: 'Subway',
    lat: 40.749567,
    lon: -73.98765, // Edited to align with 23 St B/D/F
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    // EDITED
    Daytime_Routes_Array: ['B', 'D', 'F', 'M', 'R', 'W', 'N', 'Q'],
    angle: 0,
  },
  {
    Station_ID: 13,
    Complex_ID: 13,
    stopId: 'R18',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: '28 St',
    Borough: 'M',
    Daytime_Routes: 'R W',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.745505,
    lon: -73.988415,
    // real position
    // lat: 40.745494,
    // lon: -73.988691,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
    angle: 20,
  },
  {
    Station_ID: 14,
    Complex_ID: 14,
    stopId: 'R19',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: '23 St',
    Borough: 'M',
    Daytime_Routes: 'R W',
    Structure: 'Subway',
    lat: 40.741303,
    lon: -73.989344,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
    angle: 20,
  },
  {
    Station_ID: 15,
    Complex_ID: 602,
    stopId: 'R20',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: '14 St - Union Sq',
    Borough: 'M',
    Daytime_Routes: 'N Q R W',
    Structure: 'Subway',
    lat: 40.73480006, // Edited for station alignment
    lon: -73.990768, // Edited for station alignment
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    // EDITED
    Daytime_Routes_Array: ['L', 'R', 'W', 'N', 'Q', '4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 16,
    Complex_ID: 16,
    stopId: 'R21',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: '8 St - NYU',
    Borough: 'M',
    Daytime_Routes: 'R W',
    Structure: 'Subway',
    lat: 40.7307, // Edited for station alignment
    lon: -73.992629,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
  },
  {
    Station_ID: 17,
    Complex_ID: 17,
    stopId: 'R22',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Prince St',
    Borough: 'M',
    Daytime_Routes: 'R W',
    Structure: 'Subway',
    lat: 40.724329,
    lon: -73.997702,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
  },
  // EDITED: commented out to remove duplicate label when unifying `Canal St`
  {
    Station_ID: 18,
    Complex_ID: 623,
    stopId: 'R23',
    Division: 'BMT',
    Line: 'Broadway',
    // stopName: 'Canal St',
    Borough: 'M',
    Daytime_Routes: 'R W',
    Structure: 'Subway',
    lat: 40.719527,
    lon: -74.001775,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
    angle: 0,
  },
  {
    Station_ID: 19,
    Complex_ID: 623,
    stopId: 'Q01',
    Division: 'BMT',
    Line: 'Manhattan Bridge',
    // stopName: 'Canal St',
    Borough: 'M',
    Daytime_Routes: 'N Q',
    Structure: 'Subway',
    lat: 40.718383,
    lon: -74.0,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    // EDITED
    Daytime_Routes_Array: ['4', '5', '6', 'J', 'Z'],
    angle: 0,
  },
  {
    Station_ID: 20,
    Complex_ID: 20,
    stopId: 'R24',
    Division: 'BMT',
    Line: 'Broadway',
    // stopName: 'City Hall',
    Borough: 'M',
    Daytime_Routes: 'R W',
    Structure: 'Subway',
    lat: 40.713282,
    lon: -74.006978,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
    angle: 0,
  },
  {
    Station_ID: 21,
    Complex_ID: 624,
    stopId: 'R25',
    Division: 'BMT',
    Line: 'Broadway',
    // stopName: 'Cortlandt St',
    Borough: 'M',
    Daytime_Routes: 'R W',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.71062,
    lon: -74.010863,
    // real position
    // lat: 40.710668,
    // lon: -74.011029,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
  },
  {
    Station_ID: 22,
    Complex_ID: 22,
    stopId: 'R26',
    Division: 'BMT',
    Line: 'Broadway',
    // stopName: 'Rector St',
    Borough: 'M',
    Daytime_Routes: 'R W',
    Structure: 'Subway',
    lat: 40.70722,
    lon: -74.013342,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
  },
  {
    Station_ID: 23,
    Complex_ID: 635,
    stopId: 'R27',
    Division: 'BMT',
    Line: 'Broadway',
    // stopName: 'Whitehall St',
    Borough: 'M',
    Daytime_Routes: 'R W',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.70291,
    lon: -74.013052,
    // real position
    // lat: 40.703087,
    // lon: -74.012994,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Brooklyn',
    Daytime_Routes_Array: ['N', 'Q', 'W', 'R'],
    angle: -90,
    lineIcons: ['R'],
  },
  {
    Station_ID: 24,
    Complex_ID: 620,
    stopId: 'R28',
    Division: 'BMT',
    Line: 'Broadway',
    // stopName: 'Court St',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.6941,
    lon: -73.9923, // Edited to give a bit more room to curve up towards Jay St Metrotech
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Bay Ridge - 95 St',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
    angle: 90,
  },
  // EDITED: unified with F/A/C
  // {
  //   Station_ID: 25,
  //   Complex_ID: 636,
  //   stopId: 'R29',
  //   Division: 'BMT',
  //   Line: 'Broadway',
  //   // stopName: 'Jay St - MetroTech',
  //   Borough: 'Bk',
  //   Daytime_Routes: 'R',
  //   Structure: 'Subway',
  //   lat: 40.69218,
  //   lon: -73.985942,
  //   North_Direction_Label: 'Manhattan',
  //   South_Direction_Label: 'Bay Ridge - 95 St',
  //   Daytime_Routes_Array: ['R'],
  // },
  {
    Station_ID: 26,
    Complex_ID: 26,
    stopId: 'R30',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'DeKalb Av',
    Borough: 'Bk',
    Daytime_Routes: 'B Q R',
    Structure: 'Subway',
    // Moved north to avoid yellow lines colliding with green between Bowling Green & Borough Hall
    lat: 40.6909,
    lon: -73.982,
    // original position
    // lat: 40.690635,
    // lon: -73.981824,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island - Bay Ridge',
    // EDITED for 'N' & 'D' NIGHTS_ONLY
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q', 'B', 'D'],
    angle: 51.68,
  },
  {
    Station_ID: 27,
    Complex_ID: 617,
    stopId: 'R31',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: 'Atlantic Av - Barclays Ctr',
    Borough: 'Bk',
    Daytime_Routes: 'D N R',
    Structure: 'Subway',
    lat: 40.68455,
    lon: -73.9786,
    // original position
    // lat: 40.683666,
    // lon: -73.97881,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island - Bay Ridge',
    // Q normally goes through 235 but ordering is defined here (and south to Coney Island) to handle rerouting
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q', 'D'],
    angle: 52,
  },
  {
    Station_ID: 28,
    Complex_ID: 28,
    stopId: 'R32',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: 'Union St',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.677316,
    lon: -73.98311,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Bay Ridge - 95 St',
    // EDITED: added N/D for NIGHTS_ONLY
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q', 'D'],
    angle: 0,
  },
  {
    Station_ID: 29,
    Complex_ID: 608,
    stopId: 'R33',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: '4 Av-9 St',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.670847,
    lon: -73.988302,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Bay Ridge - 95 St',
    // EDITED
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q', 'D'],
    angle: 0,
  },
  {
    Station_ID: 30,
    Complex_ID: 30,
    stopId: 'R34',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: 'Prospect Av',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.665414,
    lon: -73.992872,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Bay Ridge - 95 St',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q', 'D'],
    angle: 0,
  },
  {
    Station_ID: 31,
    Complex_ID: 31,
    stopId: 'R35',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: '25 St',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.660397,
    lon: -73.998091,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Bay Ridge - 95 St',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q', 'D'],
    angle: 0,
  },
  {
    Station_ID: 32,
    Complex_ID: 32,
    stopId: 'R36',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: '36 St',
    Borough: 'Bk',
    Daytime_Routes: 'D N R',
    Structure: 'Subway',
    lat: 40.655144,
    lon: -74.003549,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island - Bay Ridge',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q', 'D'],
    angle: -8.2,
  },
  {
    Station_ID: 33,
    Complex_ID: 33,
    stopId: 'R39',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: '45 St',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.648939,
    lon: -74.010006,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Bay Ridge - 95 St',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
    angle: 0,
  },
  {
    Station_ID: 34,
    Complex_ID: 34,
    stopId: 'R40',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: '53 St',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.645069,
    lon: -74.014034,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Bay Ridge - 95 St',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
    angle: 0,
  },
  {
    Station_ID: 35,
    Complex_ID: 35,
    stopId: 'R41',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: '59 St',
    Borough: 'Bk',
    Daytime_Routes: 'N R',
    Structure: 'Subway',
    lat: 40.641362,
    lon: -74.017881,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island - Bay Ridge',
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q'],
    angle: -10,
  },
  {
    Station_ID: 36,
    Complex_ID: 36,
    stopId: 'R42',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: 'Bay Ridge Av',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.634967,
    lon: -74.023377,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '95 St',
    Daytime_Routes_Array: ['R'],
    angle: 10,
  },
  {
    Station_ID: 37,
    Complex_ID: 37,
    stopId: 'R43',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: '77 St',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.629742,
    lon: -74.02551,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '95 St',
    Daytime_Routes_Array: ['R'],
  },
  {
    Station_ID: 38,
    Complex_ID: 38,
    stopId: 'R44',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: '86 St',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.622687,
    lon: -74.028398,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '95 St',
    Daytime_Routes_Array: ['R'],
  },
  {
    Station_ID: 39,
    Complex_ID: 39,
    stopId: 'R45',
    Division: 'BMT',
    Line: '4th Av',
    // stopName: 'Bay Ridge - 95 St',
    Borough: 'Bk',
    Daytime_Routes: 'R',
    Structure: 'Subway',
    lat: 40.616622,
    lon: -74.030876,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['R'],
    lineIcons: ['R'],
  },
  // EDITED: commented out to remove duplicate label when unifying D24 with 235
  // {
  //   Station_ID: 40,
  //   Complex_ID: 617,
  //   stopId: 'D24',
  //   Division: 'BMT',
  //   Line: 'Broadway - Brighton',
  //   // stopName: 'Atlantic Av - Barclays Ctr',
  //   Borough: 'Bk',
  //   Daytime_Routes: 'B Q',
  //   Structure: 'Subway',
  //   lat: 40.68446,
  //   lon: -73.97689,
  //   North_Direction_Label: 'Manhattan',
  //   South_Direction_Label: 'Brighton Beach & Coney Island',
  //   Daytime_Routes_Array: ['B', 'Q'],
  // },
  {
    Station_ID: 41,
    Complex_ID: 41,
    stopId: 'D25',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: '7 Av',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Subway',
    lat: 40.67705,
    lon: -73.9725,
    // original position
    // lat: 40.67705,
    // lon: -73.972367,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    Daytime_Routes_Array: ['2', '3', '4', '5', 'Q', 'B'],
    angle: 53,
  },
  {
    Station_ID: 42,
    Complex_ID: 42,
    stopId: 'D26',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Prospect Park',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.661614,
    lon: -73.962246,
    North_Direction_Label: 'Manhattan & Franklin Av',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    Daytime_Routes_Array: ['Q', 'B', 'FS'],
    angle: 45,
    lineIcons: ['FS'],
  },
  {
    Station_ID: 43,
    Complex_ID: 43,
    stopId: 'D27',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Parkside Av',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.655292,
    lon: -73.961495,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    // EDITED
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 16,
  },
  {
    Station_ID: 44,
    Complex_ID: 44,
    stopId: 'D28',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Church Av',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.650527,
    lon: -73.962982,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 16,
  },
  {
    Station_ID: 45,
    Complex_ID: 45,
    stopId: 'D29',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Beverley Rd',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.644031,
    lon: -73.964492,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    // EDITED
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 33,
  },
  {
    Station_ID: 46,
    Complex_ID: 46,
    stopId: 'D30',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Cortelyou Rd',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.640927,
    lon: -73.963891,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    // EDITED
    Daytime_Routes_Array: ['Q', 'B'],
    // angle: 45,
  },
  {
    Station_ID: 47,
    Complex_ID: 47,
    stopId: 'D31',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Newkirk Plaza',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.635082,
    lon: -73.962793,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    Daytime_Routes_Array: ['Q', 'B'],
    // angle: 45,
  },
  {
    Station_ID: 48,
    Complex_ID: 48,
    stopId: 'D32',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Avenue H',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.62927,
    lon: -73.961639,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 45,
  },
  {
    Station_ID: 49,
    Complex_ID: 49,
    stopId: 'D33',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Avenue J',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.625039,
    lon: -73.960803,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 45,
  },
  {
    Station_ID: 50,
    Complex_ID: 50,
    stopId: 'D34',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Avenue M',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.617618,
    lon: -73.959399,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 45,
  },
  {
    Station_ID: 51,
    Complex_ID: 51,
    stopId: 'D35',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Kings Hwy',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.60867,
    lon: -73.957734,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 45,
  },
  {
    Station_ID: 52,
    Complex_ID: 52,
    stopId: 'D37',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Avenue U',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.5993,
    lon: -73.955929,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    // EDITED
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 45,
  },
  {
    Station_ID: 53,
    Complex_ID: 53,
    stopId: 'D38',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Neck Rd',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.595246,
    lon: -73.955161,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    // EDITED
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 38,
  },
  {
    Station_ID: 54,
    Complex_ID: 54,
    stopId: 'D39',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Sheepshead Bay',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Open Cut',
    lat: 40.586896,
    lon: -73.954155,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Brighton Beach & Coney Island',
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 0,
  },
  {
    Station_ID: 55,
    Complex_ID: 55,
    stopId: 'D40',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Brighton Beach',
    Borough: 'Bk',
    Daytime_Routes: 'B Q',
    Structure: 'Elevated',
    lat: 40.577621,
    lon: -73.961376,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['Q', 'B'],
    angle: 0,
    lineIcons: ['B'],
  },
  {
    Station_ID: 56,
    Complex_ID: 56,
    stopId: 'D41',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'Ocean Pkwy',
    Borough: 'Bk',
    Daytime_Routes: 'Q',
    Structure: 'Elevated',
    lat: 40.576312,
    lon: -73.968501,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Stillwell Av',
    Daytime_Routes_Array: ['Q'],
    angle: 180 - 59,
  },
  {
    Station_ID: 57,
    Complex_ID: 57,
    stopId: 'D42',
    Division: 'BMT',
    Line: 'Broadway - Brighton',
    // stopName: 'W 8 St - NY Aquarium',
    Borough: 'Bk',
    Daytime_Routes: 'F Q',
    Structure: 'Elevated',
    lat: 40.576127,
    lon: -73.975939,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Stillwell Av',
    Daytime_Routes_Array: ['F', 'Q'],
    angle: -55,
  },
  {
    Station_ID: 58,
    Complex_ID: 58,
    stopId: 'D43',
    Division: 'BMT',
    Line: 'Sea Beach / West End / Culver / Brighton',
    // stopName: 'Coney Island - Stillwell Av',
    Borough: 'Bk',
    Daytime_Routes: 'D F N Q',
    Structure: 'Viaduct',
    lat: 40.577422,
    lon: -73.981233,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['Q', 'F', 'D', 'N'],
    angle: 90,
    lineIcons: ['D', 'F', 'N', 'Q'],
  },
  {
    Station_ID: 59,
    Complex_ID: 59,
    stopId: 'B12',
    Division: 'BMT',
    Line: 'West End',
    // stopName: '9 Av',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Open Cut',
    lat: 40.646292,
    lon: -73.994324,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
    angle: 28,
  },
  {
    Station_ID: 60,
    Complex_ID: 60,
    stopId: 'B13',
    Division: 'BMT',
    Line: 'West End',
    // stopName: 'Fort Hamilton Pkwy',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.640914,
    lon: -73.994304,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
  },
  {
    Station_ID: 61,
    Complex_ID: 61,
    stopId: 'B14',
    Division: 'BMT',
    Line: 'West End',
    // stopName: '50 St',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.63626,
    lon: -73.994791,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
  },
  {
    Station_ID: 62,
    Complex_ID: 62,
    stopId: 'B15',
    Division: 'BMT',
    Line: 'West End',
    // stopName: '55 St',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.631435,
    lon: -73.995476,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
  },
  {
    Station_ID: 63,
    Complex_ID: 615,
    stopId: 'B16',
    Division: 'BMT',
    Line: 'West End',
    // stopName: '62 St',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.626472,
    lon: -73.996895,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
  },
  {
    Station_ID: 64,
    Complex_ID: 64,
    stopId: 'B17',
    Division: 'BMT',
    Line: 'West End',
    // stopName: '71 St',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.619589,
    lon: -73.998864,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
  },
  {
    Station_ID: 65,
    Complex_ID: 65,
    stopId: 'B18',
    Division: 'BMT',
    Line: 'West End',
    // stopName: '79 St',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.613501,
    lon: -74.00061,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
  },
  {
    Station_ID: 66,
    Complex_ID: 66,
    stopId: 'B19',
    Division: 'BMT',
    Line: 'West End',
    // stopName: '18 Av',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.607954,
    lon: -74.001736,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
    angle: 20,
  },
  {
    Station_ID: 67,
    Complex_ID: 67,
    stopId: 'B20',
    Division: 'BMT',
    Line: 'West End',
    // stopName: '20 Av',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.604556,
    lon: -73.998168,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
    angle: 80,
  },
  {
    Station_ID: 68,
    Complex_ID: 68,
    stopId: 'B21',
    Division: 'BMT',
    Line: 'West End',
    // stopName: 'Bay Pkwy',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.601875,
    lon: -73.993728,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
    angle: 80,
  },
  {
    Station_ID: 69,
    Complex_ID: 69,
    stopId: 'B22',
    Division: 'BMT',
    Line: 'West End',
    // stopName: '25 Av',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.597704,
    lon: -73.986829,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
    angle: 90,
  },
  {
    Station_ID: 70,
    Complex_ID: 70,
    stopId: 'B23',
    Division: 'BMT',
    Line: 'West End',
    // stopName: 'Bay 50 St',
    Borough: 'Bk',
    Daytime_Routes: 'D',
    Structure: 'Elevated',
    lat: 40.588841,
    lon: -73.983765,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['D'],
  },
  {
    Station_ID: 71,
    Complex_ID: 71,
    stopId: 'N02',
    Division: 'BMT',
    Line: 'Sea Beach',
    // stopName: '8 Av',
    Borough: 'Bk',
    Daytime_Routes: 'N',
    Structure: 'Open Cut',
    lat: 40.635064,
    lon: -74.011719,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['W', 'N', 'Q'],
    angle: 81,
  },
  {
    Station_ID: 72,
    Complex_ID: 72,
    stopId: 'N03',
    Division: 'BMT',
    Line: 'Sea Beach',
    // stopName: 'Fort Hamilton Pkwy',
    Borough: 'Bk',
    Daytime_Routes: 'N',
    Structure: 'Open Cut',
    lat: 40.631386,
    lon: -74.005351,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['W', 'N', 'Q'],
    angle: 90,
  },
  {
    Station_ID: 73,
    Complex_ID: 615,
    stopId: 'N04',
    Division: 'BMT',
    Line: 'Sea Beach',
    // stopName: 'New Utrecht Av',
    Borough: 'Bk',
    Daytime_Routes: 'N',
    Structure: 'Open Cut',
    lat: 40.624842,
    lon: -73.996353,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['W', 'N', 'Q'],
    // actual angle is 75, but line letter placement is currently better at 90
    angle: 90,
  },
  {
    Station_ID: 74,
    Complex_ID: 74,
    stopId: 'N05',
    Division: 'BMT',
    Line: 'Sea Beach',
    // stopName: '18 Av',
    Borough: 'Bk',
    Daytime_Routes: 'N',
    Structure: 'Open Cut',
    lat: 40.620671,
    lon: -73.990414,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['W', 'N', 'Q'],
    angle: 90,
  },
  {
    Station_ID: 75,
    Complex_ID: 75,
    stopId: 'N06',
    Division: 'BMT',
    Line: 'Sea Beach',
    // stopName: '20 Av',
    Borough: 'Bk',
    Daytime_Routes: 'N',
    Structure: 'Open Cut',
    lat: 40.61741,
    lon: -73.985026,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['W', 'N', 'Q'],
    angle: 80,
  },
  {
    Station_ID: 76,
    Complex_ID: 76,
    stopId: 'N07',
    Division: 'BMT',
    Line: 'Sea Beach',
    // stopName: 'Bay Pkwy',
    Borough: 'Bk',
    Daytime_Routes: 'N',
    Structure: 'Open Cut',
    lat: 40.611815,
    lon: -73.981848,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['W', 'N', 'Q'],
    angle: 38,
  },
  {
    Station_ID: 77,
    Complex_ID: 77,
    stopId: 'N08',
    Division: 'BMT',
    Line: 'Sea Beach',
    // stopName: 'Kings Hwy',
    Borough: 'Bk',
    Daytime_Routes: 'N',
    Structure: 'Open Cut',
    lat: 40.603923,
    lon: -73.980353,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['W', 'N', 'Q'],
  },
  {
    Station_ID: 78,
    Complex_ID: 78,
    stopId: 'N09',
    Division: 'BMT',
    Line: 'Sea Beach',
    // stopName: 'Avenue U',
    Borough: 'Bk',
    Daytime_Routes: 'N',
    Structure: 'Open Cut',
    lat: 40.597473,
    lon: -73.979137,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['W', 'N', 'Q'],
  },
  {
    Station_ID: 79,
    Complex_ID: 79,
    stopId: 'N10',
    Division: 'BMT',
    Line: 'Sea Beach',
    // stopName: '86 St',
    Borough: 'Bk',
    Daytime_Routes: 'N',
    Structure: 'Open Cut',
    lat: 40.592721,
    lon: -73.97823,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['W', 'N', 'Q'],
  },
  {
    Station_ID: 80,
    Complex_ID: 80,
    stopId: 'J12',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: '121 St',
    Borough: 'Q',
    Daytime_Routes: 'J Z',
    Structure: 'Elevated',
    lat: 40.700492,
    lon: -73.828294,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 135,
  },
  {
    Station_ID: 81,
    Complex_ID: 81,
    stopId: 'J13',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: '111 St',
    Borough: 'Q',
    Daytime_Routes: 'J',
    Structure: 'Elevated',
    lat: 40.697418,
    lon: -73.836345,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 135,
  },
  {
    Station_ID: 82,
    Complex_ID: 82,
    stopId: 'J14',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: '104 St',
    Borough: 'Q',
    Daytime_Routes: 'J Z',
    Structure: 'Elevated',
    lat: 40.695178,
    lon: -73.84433,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 135,
  },
  {
    Station_ID: 83,
    Complex_ID: 83,
    stopId: 'J15',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Woodhaven Blvd',
    Borough: 'Q',
    Daytime_Routes: 'J Z',
    Structure: 'Elevated',
    lat: 40.693879,
    lon: -73.851576,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 135,
  },
  {
    Station_ID: 84,
    Complex_ID: 84,
    stopId: 'J16',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: '85 St - Forest Pkwy',
    Borough: 'Q',
    Daytime_Routes: 'J',
    Structure: 'Elevated',
    lat: 40.692435,
    lon: -73.86001,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 135,
  },
  {
    Station_ID: 85,
    Complex_ID: 85,
    stopId: 'J17',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: '75 St',
    Borough: 'Q',
    Daytime_Routes: 'J Z',
    Structure: 'Elevated',
    lat: 40.691324,
    lon: -73.867139,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 135,
  },
  {
    Station_ID: 86,
    Complex_ID: 86,
    stopId: 'J19',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Cypress Hills',
    Borough: 'Bk',
    Daytime_Routes: 'J',
    Structure: 'Elevated',
    lat: 40.689941,
    lon: -73.87255,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['J', 'Z'],
    angle: -40,
  },
  {
    Station_ID: 87,
    Complex_ID: 87,
    stopId: 'J20',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Crescent St',
    Borough: 'Bk',
    Daytime_Routes: 'J Z',
    Structure: 'Elevated',
    lat: 40.683194,
    lon: -73.873785,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['J', 'Z'],
    angle: -40,
  },
  {
    Station_ID: 88,
    Complex_ID: 88,
    stopId: 'J21',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Norwood Av',
    Borough: 'Bk',
    Daytime_Routes: 'J Z',
    Structure: 'Elevated',
    lat: 40.68141,
    lon: -73.880039,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 135,
  },
  {
    Station_ID: 89,
    Complex_ID: 89,
    stopId: 'J22',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Cleveland St',
    Borough: 'Bk',
    Daytime_Routes: 'J',
    Structure: 'Elevated',
    lat: 40.679947,
    lon: -73.884639,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 112,
  },
  {
    Station_ID: 90,
    Complex_ID: 90,
    stopId: 'J23',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Van Siclen Av',
    Borough: 'Bk',
    Daytime_Routes: 'J Z',
    Structure: 'Elevated',
    lat: 40.678024,
    lon: -73.891688,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 135,
  },
  {
    Station_ID: 91,
    Complex_ID: 91,
    stopId: 'J24',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Alabama Av',
    Borough: 'Bk',
    Daytime_Routes: 'J',
    Structure: 'Elevated',
    // Align with blue lines from Broadway Junction
    lat: 40.67725,
    lon: -73.8987,
    // original position
    // lat: 40.676992,
    // lon: -73.898654,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 90,
  },
  {
    Station_ID: 92,
    Complex_ID: 621,
    stopId: 'J27',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Broadway Junction',
    Borough: 'Bk',
    Daytime_Routes: 'J Z',
    Structure: 'Elevated',
    lat: 40.679498,
    lon: -73.904512,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    // EDITED
    Daytime_Routes_Array: ['L', 'A', 'C', 'Z', 'J'],
    angle: 90,
  },
  {
    Station_ID: 93,
    Complex_ID: 93,
    stopId: 'J28',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Chauncey St',
    Borough: 'Bk',
    Daytime_Routes: 'J Z',
    Structure: 'Elevated',
    lat: 40.682893,
    lon: -73.910456,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 80,
  },
  {
    Station_ID: 94,
    Complex_ID: 94,
    stopId: 'J29',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Halsey St',
    Borough: 'Bk',
    Daytime_Routes: 'J',
    Structure: 'Elevated',
    lat: 40.68637,
    lon: -73.916559,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 90,
  },
  {
    Station_ID: 95,
    Complex_ID: 95,
    stopId: 'J30',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Gates Av',
    Borough: 'Bk',
    Daytime_Routes: 'J Z',
    Structure: 'Elevated',
    lat: 40.68963,
    lon: -73.92227,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 90,
  },
  {
    Station_ID: 96,
    Complex_ID: 96,
    stopId: 'J31',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Kosciuszko St',
    Borough: 'Bk',
    Daytime_Routes: 'J',
    Structure: 'Elevated',
    lat: 40.693342,
    lon: -73.928814,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J'],
    angle: 90,
  },
  {
    Station_ID: 97,
    Complex_ID: 97,
    stopId: 'M11',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Myrtle Av',
    Borough: 'Bk',
    Daytime_Routes: 'J M Z',
    Structure: 'Elevated',
    lat: 40.697207,
    lon: -73.935657,
    North_Direction_Label: 'Jamaica - Middle Village',
    South_Direction_Label: 'Manhattan',
    // EDITED
    Daytime_Routes_Array: ['Z', 'J', 'M'],
    angle: 80,
  },
  {
    Station_ID: 98,
    Complex_ID: 98,
    stopId: 'M12',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Flushing Av',
    Borough: 'Bk',
    Daytime_Routes: 'J M',
    Structure: 'Elevated',
    lat: 40.70026,
    lon: -73.941126,
    North_Direction_Label: 'Jamaica - Middle Village',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J', 'M'],
    angle: 90,
  },
  {
    Station_ID: 99,
    Complex_ID: 99,
    stopId: 'M13',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Lorimer St',
    Borough: 'Bk',
    Daytime_Routes: 'J M',
    Structure: 'Elevated',
    lat: 40.703869,
    lon: -73.947408,
    North_Direction_Label: 'Jamaica - Middle Village',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J', 'M'],
    angle: 90,
  },
  {
    Station_ID: 100,
    Complex_ID: 100,
    stopId: 'M14',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Hewes St',
    Borough: 'Bk',
    Daytime_Routes: 'J M',
    Structure: 'Elevated',
    lat: 40.70687,
    lon: -73.953431,
    North_Direction_Label: 'Jamaica - Middle Village',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J', 'M'],
    angle: 90,
  },
  {
    Station_ID: 101,
    Complex_ID: 101,
    stopId: 'M16',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Marcy Av',
    Borough: 'Bk',
    Daytime_Routes: 'J M Z',
    Structure: 'Elevated',
    lat: 40.708359,
    lon: -73.957,
    // original position
    // lat: 40.708359,
    // lon: -73.957757,
    North_Direction_Label: 'Jamaica - Middle Village',
    South_Direction_Label: 'Manhattan',
    // EDITED
    Daytime_Routes_Array: ['Z', 'J', 'M'],
    angle: 90,
  },
  {
    Station_ID: 102,
    Complex_ID: 625,
    stopId: 'M18',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Delancey St - Essex St',
    Borough: 'M',
    Daytime_Routes: 'J M Z',
    Structure: 'Subway',
    lat: 40.7182,
    lon: -73.9874,
    // original position
    // lat: 40.718315,
    // lon: -73.987437,
    North_Direction_Label: 'Brooklyn',
    South_Direction_Label: 'Broad St (JZ) - Uptown (M)',
    Daytime_Routes_Array: ['F', 'A', 'C', 'Z', 'J', 'M'],
    perpendicularStack: ['Z', 'J', 'M'],
    angle: 0,
  },
  {
    Station_ID: 103,
    Complex_ID: 103,
    stopId: 'M19',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Bowery',
    Borough: 'M',
    Daytime_Routes: 'J Z',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.720331,
    lon: -73.993298,
    // real position
    // lat: 40.72028,
    // lon: -73.993915,
    North_Direction_Label: 'Brooklyn',
    South_Direction_Label: 'Broad St',
    Daytime_Routes_Array: ['J', 'Z'],
    angle: -90,
  },
  // EDITED: commented out to remove duplicate label when unifying `Canal St`
  // {
  //   Station_ID: 104,
  //   Complex_ID: 623,
  //   stopId: 'M20',
  //   Division: 'BMT',
  //   Line: 'Jamaica',
  //   // stopName: 'Canal St',
  //   Borough: 'M',
  //   Daytime_Routes: 'J Z',
  //   Structure: 'Subway',
  //   lat: 40.718092,
  //   lon: -73.999892,
  //   North_Direction_Label: 'Brooklyn',
  //   South_Direction_Label: 'Broad St',
  //   Daytime_Routes_Array: ['J', 'Z'],
  // },
  {
    Station_ID: 105,
    Complex_ID: 622,
    stopId: 'M21',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Chambers St',
    Borough: 'M',
    Daytime_Routes: 'J Z',
    Structure: 'Subway',
    lat: 40.713243,
    lon: -74.00352,
    North_Direction_Label: 'Brooklyn',
    South_Direction_Label: 'Broad St',
    Daytime_Routes_Array: ['J', 'Z'],
    angle: 0,
  },
  // Unified with other Fulton St
  // {
  //   Station_ID: 106,
  //   Complex_ID: 628,
  //   stopId: 'M22',
  //   Division: 'BMT',
  //   Line: 'Jamaica',
  //   // stopName: 'Fulton St',
  //   Borough: 'M',
  //   Daytime_Routes: 'J Z',
  //   Structure: 'Subway',
  //   lat: 40.710374,
  //   lon: -74.007582,
  //   North_Direction_Label: 'Brooklyn',
  //   South_Direction_Label: 'Broad St',
  //   Daytime_Routes_Array: ['J', 'Z'],
  // },
  {
    Station_ID: 107,
    Complex_ID: 107,
    stopId: 'M23',
    Division: 'BMT',
    Line: 'Jamaica',
    // stopName: 'Broad St',
    Borough: 'M',
    Daytime_Routes: 'J Z',
    Structure: 'Subway',
    lat: 40.706476,
    lon: -74.011056,
    North_Direction_Label: 'Brooklyn',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['J', 'Z'],
    angle: -90,
    lineIcons: ['J', 'Z'],
  },
  {
    Station_ID: 108,
    Complex_ID: 108,
    stopId: 'M01',
    Division: 'BMT',
    Line: 'Myrtle Av',
    // stopName: 'Middle Village - Metropolitan Av',
    Borough: 'Q',
    Daytime_Routes: 'M',
    Structure: 'Elevated',
    lat: 40.711396,
    lon: -73.889601,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['M'],
    lineIcons: ['M'],
  },
  {
    Station_ID: 109,
    Complex_ID: 109,
    stopId: 'M04',
    Division: 'BMT',
    Line: 'Myrtle Av',
    // stopName: 'Fresh Pond Rd',
    Borough: 'Q',
    Daytime_Routes: 'M',
    Structure: 'Elevated',
    lat: 40.706186,
    lon: -73.895877,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Metropolitan Av',
    Daytime_Routes_Array: ['M'],
  },
  {
    Station_ID: 110,
    Complex_ID: 110,
    stopId: 'M05',
    Division: 'BMT',
    Line: 'Myrtle Av',
    // stopName: 'Forest Av',
    Borough: 'Q',
    Daytime_Routes: 'M',
    Structure: 'Elevated',
    lat: 40.704423,
    lon: -73.903077,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Metropolitan Av',
    Daytime_Routes_Array: ['M'],
  },
  {
    Station_ID: 111,
    Complex_ID: 111,
    stopId: 'M06',
    Division: 'BMT',
    Line: 'Myrtle Av',
    // stopName: 'Seneca Av',
    Borough: 'Q',
    Daytime_Routes: 'M',
    Structure: 'Elevated',
    lat: 40.702762,
    lon: -73.90774,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Metropolitan Av',
    Daytime_Routes_Array: ['M'],
    angle: 145,
  },
  // EDITED: commented out to remove duplicate label when unifying `Myrtle - Wyckoff Avs`
  // {
  //   Station_ID: 112,
  //   Complex_ID: 630,
  //   stopId: 'M08',
  //   Division: 'BMT',
  //   Line: 'Myrtle Av',
  //   // stopName: 'Myrtle - Wyckoff Avs',
  //   Borough: 'Bk',
  //   Daytime_Routes: 'M',
  //   Structure: 'Elevated',
  //   lat: 40.69943,
  //   lon: -73.912385,
  //   North_Direction_Label: 'Manhattan',
  //   South_Direction_Label: 'Metropolitan Av',
  //   Daytime_Routes_Array: ['M'],
  // },
  {
    Station_ID: 113,
    Complex_ID: 113,
    stopId: 'M09',
    Division: 'BMT',
    Line: 'Myrtle Av',
    // stopName: 'Knickerbocker Av',
    Borough: 'Bk',
    Daytime_Routes: 'M',
    Structure: 'Elevated',
    lat: 40.698664,
    lon: -73.919711,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Metropolitan Av',
    Daytime_Routes_Array: ['M'],
    angle: 125,
  },
  {
    Station_ID: 114,
    Complex_ID: 114,
    stopId: 'M10',
    Division: 'BMT',
    Line: 'Myrtle Av',
    // stopName: 'Central Av',
    Borough: 'Bk',
    Daytime_Routes: 'M',
    Structure: 'Elevated',
    lat: 40.697857,
    lon: -73.927397,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Metropolitan Av',
    Daytime_Routes_Array: ['M'],
    angle: 130,
  },
  {
    Station_ID: 115,
    Complex_ID: 618,
    stopId: 'L01',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: '8 Av',
    Borough: 'M',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    // real position
    // lat: 40.739777,
    // lon: -74.002578,
    // fake position to improve the transfer link visualization
    lat: 40.739644,
    lon: -74.002203,
    North_Direction_Label: '',
    South_Direction_Label: 'Brooklyn',
    Daytime_Routes_Array: ['L'],
    lineIcons: ['L'],
    angle: 90,
  },
  {
    Station_ID: 116,
    Complex_ID: 601,
    stopId: 'L02',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: '6 Av',
    Borough: 'M',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    // real position
    // lat: 40.737335,
    // lon: -73.996786,
    // fake position to improve the transfer link visualization
    lat: 40.737641,
    lon: -73.99748,
    North_Direction_Label: '8 Av',
    South_Direction_Label: 'Brooklyn',
    Daytime_Routes_Array: ['L'],
    angle: 90,
  },
  // EDITED: commented out to remove duplicate label when unifying `Union Sq - 14 St`
  // {
  //   Station_ID: 117,
  //   Complex_ID: 602,
  //   stopId: 'L03',
  //   Division: 'BMT',
  //   Line: 'Canarsie',
  //   // stopName: 'Union Sq - 14 St',
  //   Borough: 'M',
  //   Daytime_Routes: 'L',
  //   Structure: 'Subway',
  //   lat: 40.734789,
  //   lon: -73.99073,
  //   North_Direction_Label: '8 Av',
  //   South_Direction_Label: 'Brooklyn',
  //   Daytime_Routes_Array: ['L'],
  // },
  {
    Station_ID: 118,
    Complex_ID: 118,
    stopId: 'L05',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: '3 Av',
    Borough: 'M',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.732849,
    lon: -73.986122,
    North_Direction_Label: '8 Av',
    South_Direction_Label: 'Brooklyn',
    Daytime_Routes_Array: ['L'],
    angle: 90,
  },
  {
    Station_ID: 119,
    Complex_ID: 119,
    stopId: 'L06',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: '1 Av',
    Borough: 'M',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.730953,
    lon: -73.981628,
    North_Direction_Label: '8 Av',
    South_Direction_Label: 'Brooklyn',
    Daytime_Routes_Array: ['L'],
    angle: 90,
  },
  {
    Station_ID: 120,
    Complex_ID: 120,
    stopId: 'L08',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Bedford Av',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.717304,
    lon: -73.956872,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
    angle: 90,
  },
  {
    Station_ID: 121,
    Complex_ID: 629,
    stopId: 'L10',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Lorimer St',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.714063,
    lon: -73.950275,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 122,
    Complex_ID: 122,
    stopId: 'L11',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Graham Av',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.714565,
    lon: -73.944053,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 123,
    Complex_ID: 123,
    stopId: 'L12',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Grand St',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.711926,
    lon: -73.94067,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 124,
    Complex_ID: 124,
    stopId: 'L13',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Montrose Av',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.707739,
    lon: -73.93985,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 125,
    Complex_ID: 125,
    stopId: 'L14',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Morgan Av',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.706152,
    lon: -73.933147,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 126,
    Complex_ID: 126,
    stopId: 'L15',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Jefferson St',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.706607,
    lon: -73.922913,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 127,
    Complex_ID: 127,
    stopId: 'L16',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'DeKalb Av',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.703811,
    lon: -73.918425,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 128,
    Complex_ID: 630,
    stopId: 'L17',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Myrtle - Wyckoff Avs',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.699814,
    lon: -73.911586,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    // EDITED
    Daytime_Routes_Array: ['L', 'M'],
    angle: 0,
  },
  {
    Station_ID: 129,
    Complex_ID: 129,
    stopId: 'L19',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Halsey St',
    Borough: 'Q',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.695602,
    lon: -73.904084,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 130,
    Complex_ID: 130,
    stopId: 'L20',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Wilson Av',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.688764,
    lon: -73.904046,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 131,
    Complex_ID: 131,
    stopId: 'L21',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Bushwick Av - Aberdeen St',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Subway',
    lat: 40.682829,
    lon: -73.905249,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
    angle: 25,
  },
  // EDITED: commented out to remove duplicate label when unifying `Broadway Jct`
  // {
  //   Station_ID: 132,
  //   Complex_ID: 621,
  //   stopId: 'L22',
  //   Division: 'BMT',
  //   Line: 'Canarsie',
  //   // stopName: 'Broadway Jct',
  //   Borough: 'Bk',
  //   Daytime_Routes: 'L',
  //   Structure: 'Elevated',
  //   lat: 40.678856,
  //   lon: -73.90324,
  //   North_Direction_Label: 'Manhattan',
  //   South_Direction_Label: 'Canarsie - Rockaway Parkway',
  //   Daytime_Routes_Array: ['L'],
  // },
  {
    Station_ID: 133,
    Complex_ID: 133,
    stopId: 'L24',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Atlantic Av',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Elevated',
    lat: 40.675345,
    lon: -73.903097,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
    angle: 35,
  },
  {
    Station_ID: 134,
    Complex_ID: 134,
    stopId: 'L25',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Sutter Av',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Elevated',
    lat: 40.669367,
    lon: -73.901975,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
    angle: 35,
  },
  {
    Station_ID: 135,
    Complex_ID: 135,
    stopId: 'L26',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Livonia Av',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Elevated',
    lat: 40.664038,
    lon: -73.900571,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 136,
    Complex_ID: 136,
    stopId: 'L27',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'New Lots Av',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'Elevated',
    lat: 40.658733,
    lon: -73.899232,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 137,
    Complex_ID: 137,
    stopId: 'L28',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'E 105 St',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'At Grade',
    lat: 40.650573,
    lon: -73.899485,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Canarsie - Rockaway Parkway',
    Daytime_Routes_Array: ['L'],
  },
  {
    Station_ID: 138,
    Complex_ID: 138,
    stopId: 'L29',
    Division: 'BMT',
    Line: 'Canarsie',
    // stopName: 'Canarsie - Rockaway Pkwy',
    Borough: 'Bk',
    Daytime_Routes: 'L',
    Structure: 'At Grade',
    lat: 40.646654,
    lon: -73.90185,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['L'],
    lineIcons: ['L'],
  },
  {
    Station_ID: 139,
    Complex_ID: 627,
    stopId: 'S01',
    Division: 'BMT',
    Line: 'Franklin Shuttle',
    // stopName: 'Franklin Av',
    Borough: 'Bk',
    Daytime_Routes: 'S',
    Structure: 'Elevated',
    lat: 40.680596,
    lon: -73.955827,
    North_Direction_Label: '',
    South_Direction_Label: 'Prospect Park',
    Daytime_Routes_Array: ['FS'],
    lineIcons: ['FS'],
  },
  {
    Station_ID: 141,
    Complex_ID: 141,
    stopId: 'S03',
    Division: 'BMT',
    Line: 'Franklin Shuttle',
    // stopName: 'Park Pl',
    Borough: 'Bk',
    Daytime_Routes: 'S',
    Structure: 'Open Cut',
    lat: 40.674772,
    lon: -73.957624,
    North_Direction_Label: 'Franklin Av',
    South_Direction_Label: 'Prospect Park',
    Daytime_Routes_Array: ['FS'],
  },
  {
    Station_ID: 142,
    Complex_ID: 626,
    stopId: 'S04',
    Division: 'BMT',
    Line: 'Franklin Shuttle',
    // stopName: 'Botanic Garden',
    Borough: 'Bk',
    Daytime_Routes: 'S',
    Structure: 'Open Cut',
    // real position
    // lat: 40.670343,
    // lon: -73.959245,
    // fake position to improve the transfer link visualization
    lat: 40.669986,
    lon: -73.959695,
    North_Direction_Label: 'Franklin Av',
    South_Direction_Label: 'Prospect Park',
    Daytime_Routes_Array: ['FS'],
  },
  {
    Station_ID: 143,
    Complex_ID: 143,
    stopId: 'A02',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Inwood - 207 St',
    Borough: 'M',
    Daytime_Routes: 'A',
    Structure: 'Subway',
    lat: 40.868072,
    lon: -73.919899,
    North_Direction_Label: '',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A'],
    lineIcons: ['A'],
  },
  {
    Station_ID: 144,
    Complex_ID: 144,
    stopId: 'A03',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Dyckman St',
    Borough: 'M',
    Daytime_Routes: 'A',
    Structure: 'Subway',
    lat: 40.865491,
    lon: -73.927271,
    North_Direction_Label: '207 St',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A'],
    angle: -35,
  },
  {
    Station_ID: 145,
    Complex_ID: 145,
    stopId: 'A05',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '190 St',
    Borough: 'M',
    Daytime_Routes: 'A',
    Structure: 'Subway',
    lat: 40.859022,
    lon: -73.93418,
    North_Direction_Label: '207 St',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A'],
    angle: 8,
  },
  {
    Station_ID: 146,
    Complex_ID: 146,
    stopId: 'A06',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '181 St',
    Borough: 'M',
    Daytime_Routes: 'A',
    Structure: 'Subway',
    lat: 40.851695,
    lon: -73.937969,
    North_Direction_Label: '207 St',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 147,
    Complex_ID: 147,
    stopId: 'A07',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '175 St',
    Borough: 'M',
    Daytime_Routes: 'A',
    Structure: 'Subway',
    lat: 40.847391,
    lon: -73.939704,
    North_Direction_Label: '207 St',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 148,
    Complex_ID: 605,
    stopId: 'A09',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '168 St',
    Borough: 'M',
    Daytime_Routes: 'A C',
    Structure: 'Subway',
    lat: 40.840719,
    lon: -73.939561,
    North_Direction_Label: '207 St',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C'],
    lineIcons: ['C'],
  },
  {
    Station_ID: 149,
    Complex_ID: 149,
    stopId: 'A10',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '163 St - Amsterdam Av',
    Borough: 'M',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.836013,
    lon: -73.939892,
    North_Direction_Label: 'Uptown',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C'],
  },
  {
    Station_ID: 150,
    Complex_ID: 150,
    stopId: 'A11',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '155 St',
    Borough: 'M',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.830518,
    lon: -73.941514,
    North_Direction_Label: 'Uptown',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C'],
    angle: 15,
  },
  {
    Station_ID: 151,
    Complex_ID: 151,
    stopId: 'A12',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '145 St',
    Borough: 'M',
    Daytime_Routes: 'A C',
    Structure: 'Subway',
    lat: 40.824783,
    lon: -73.944216,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
    angle: 0,
  },
  // EDITED: unified with 'A12' - 145 St
  // {
  //   Station_ID: 151,
  //   Complex_ID: 151,
  //   stopId: 'D13',
  //   Division: 'IND',
  //   Line: 'Concourse',
  //   // stopName: '145 St',
  //   Borough: 'M',
  //   Daytime_Routes: 'B D',
  //   Structure: 'Subway',
  //   lat: 40.824783,
  //   lon: -73.944216,
  //   North_Direction_Label: 'Uptown & The Bronx',
  //   South_Direction_Label: 'Downtown & Brooklyn',
  //   Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
  // },
  {
    Station_ID: 152,
    Complex_ID: 152,
    stopId: 'A14',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '135 St',
    Borough: 'M',
    Daytime_Routes: 'B C',
    Structure: 'Subway',
    lat: 40.817894,
    lon: -73.947649,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
    angle: 0,
  },
  {
    Station_ID: 153,
    Complex_ID: 153,
    stopId: 'A15',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '125 St',
    Borough: 'M',
    Daytime_Routes: 'A B C D',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.810526,
    lon: -73.952642,
    // real position
    // lat: 40.811109,
    // lon: -73.952343,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
    angle: 45,
  },
  {
    Station_ID: 154,
    Complex_ID: 154,
    stopId: 'A16',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '116 St',
    Borough: 'M',
    Daytime_Routes: 'B C',
    Structure: 'Subway',
    lat: 40.805085,
    lon: -73.954882,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
    angle: 0,
  },
  {
    Station_ID: 155,
    Complex_ID: 155,
    stopId: 'A17',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Cathedral Pkwy 110 St',
    Borough: 'M',
    Daytime_Routes: 'B C',
    Structure: 'Subway',
    lat: 40.800603,
    lon: -73.958161,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
    angle: 0,
  },
  {
    Station_ID: 156,
    Complex_ID: 156,
    stopId: 'A18',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '103 St',
    Borough: 'M',
    Daytime_Routes: 'B C',
    Structure: 'Subway',
    lat: 40.796092,
    lon: -73.961454,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
    angle: 0,
  },
  {
    Station_ID: 157,
    Complex_ID: 157,
    stopId: 'A19',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '96 St',
    Borough: 'M',
    Daytime_Routes: 'B C',
    Structure: 'Subway',
    lat: 40.791642,
    lon: -73.964696,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
    angle: 0,
  },
  {
    Station_ID: 158,
    Complex_ID: 158,
    stopId: 'A20',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '86 St',
    Borough: 'M',
    Daytime_Routes: 'B C',
    Structure: 'Subway',
    lat: 40.785868,
    lon: -73.968916,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
    angle: 0,
  },
  {
    Station_ID: 159,
    Complex_ID: 159,
    stopId: 'A21',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '81 St - Museum of Natural History',
    Borough: 'M',
    Daytime_Routes: 'B C',
    Structure: 'Subway',
    lat: 40.781433,
    lon: -73.972143,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
    angle: 0,
  },
  {
    Station_ID: 160,
    Complex_ID: 160,
    stopId: 'A22',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '72 St',
    Borough: 'M',
    Daytime_Routes: 'B C',
    Structure: 'Subway',
    lat: 40.775594,
    lon: -73.97641,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'B', 'D'],
    angle: 0,
  },
  {
    Station_ID: 161,
    Complex_ID: 614,
    stopId: 'A24',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '59 St - Columbus Circle',
    Borough: 'M',
    Daytime_Routes: 'A B C D',
    Structure: 'Subway',
    lat: 40.768396, // Edited for station alignment
    lon: -73.981736,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['1', '2', '3', 'A', 'C', 'B', 'D', 'F', 'M'],
    angle: 0,
  },
  {
    Station_ID: 162,
    Complex_ID: 162,
    stopId: 'A25',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '50 St',
    Borough: 'M',
    Daytime_Routes: 'C E',
    Structure: 'Subway',
    lat: 40.762456,
    lon: -73.985984,
    North_Direction_Label: 'Uptown - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    // EDITED: added A NIGHTS_ONLY
    Daytime_Routes_Array: ['A', 'C', 'E', 'B', 'D', 'F', 'M'],
    angle: 0,
  },
  {
    Station_ID: 163,
    Complex_ID: 611,
    stopId: 'A27',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '42 St - Port Authority',
    Borough: 'M',
    Daytime_Routes: 'A C E',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.757108,
    lon: -73.98986,
    // real position
    // lat: 40.757308,
    // lon: -73.989735,
    North_Direction_Label: 'Uptown - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'E', 'B', 'D', 'F', 'M'],
    angle: 0,
  },
  {
    Station_ID: 164,
    Complex_ID: 164,
    stopId: 'A28',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '34 St - Penn Station',
    Borough: 'M',
    Daytime_Routes: 'A C E',
    Structure: 'Subway',
    lat: 40.752287,
    lon: -73.993391,
    North_Direction_Label: 'Uptown - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'E', 'B', 'D', 'F', 'M'],
    angle: 0,
  },
  {
    Station_ID: 165,
    Complex_ID: 165,
    stopId: 'A30',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '23 St',
    Borough: 'M',
    Daytime_Routes: 'C E',
    Structure: 'Subway',
    lat: 40.745906,
    lon: -73.998041,
    North_Direction_Label: 'Uptown - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    // EDITED: added A NIGHTS_ONLY
    Daytime_Routes_Array: ['A', 'C', 'E', 'B', 'D', 'F', 'M'],
    angle: 0,
  },
  {
    Station_ID: 166,
    Complex_ID: 618,
    stopId: 'A31',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: '14 St',
    Borough: 'M',
    Daytime_Routes: 'A C E',
    Structure: 'Subway',
    lat: 40.740893,
    lon: -74.00169,
    North_Direction_Label: 'Uptown - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'E', 'B', 'D', 'F', 'M'],
    angle: 0,
  },
  // A32 and D20 are effectively the same station, with the same coords.
  // But they're separate in this data, which makes it difficult to coordinate the 7 dots/lines.
  // TODO: unify these
  // Actually, they have the same Station_ID: 167
  {
    Station_ID: 167,
    Complex_ID: 167,
    stopId: 'A32',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'W 4 St',
    Borough: 'M',
    Daytime_Routes: 'A C E',
    Structure: 'Subway',
    lat: 40.7323, // Edited for station alignment
    lon: -74.0008, // Edited for station alignment
    North_Direction_Label: 'Uptown - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    // EDITED
    Daytime_Routes_Array: ['A', 'C', 'E', 'B', 'D', 'F', 'M'],
    angle: 0,
  },
  // Commented out as we are using A32 as the primary stopId for this station
  // {
  //   Station_ID: 167,
  //   Complex_ID: 167,
  //   stopId: 'D20',
  //   Division: 'IND',
  //   Line: '6th Av - Culver',
  //   // stopName: 'W 4 St',
  //   Borough: 'M',
  //   Daytime_Routes: 'B D F M',
  //   Structure: 'Subway',
  //   lat: 40.732338,
  //   lon: -74.000495,
  //   North_Direction_Label: 'Uptown - Queens',
  //   South_Direction_Label: 'Downtown & Brooklyn',
  //   // EDITED
  //   Daytime_Routes_Array: ['A', 'C', 'E', 'B', 'D', 'F', 'M'],
  //   angle: 0,
  // },
  {
    Station_ID: 168,
    Complex_ID: 168,
    stopId: 'A33',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Spring St',
    Borough: 'M',
    Daytime_Routes: 'C E',
    Structure: 'Subway',
    lat: 40.7256,
    lon: -74.004,
    // original position
    // lat: 40.726227,
    // lon: -74.003739,
    North_Direction_Label: 'Uptown - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    // EDITED: added A
    Daytime_Routes_Array: ['A', 'C', 'E', 'D'],
    angle: 24,
  },
  {
    Station_ID: 169,
    Complex_ID: 169,
    stopId: 'A34',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Canal St',
    Borough: 'M',
    Daytime_Routes: 'A C E',
    Structure: 'Subway',
    lat: 40.720824,
    lon: -74.0047,
    // original position
    // lon: -74.005229,
    North_Direction_Label: 'Uptown - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'E', 'D'],
    angle: 20,
  },
  {
    Station_ID: 170,
    Complex_ID: 624,
    stopId: 'A36',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Chambers St',
    Borough: 'M',
    Daytime_Routes: 'A C',
    Structure: 'Subway',
    lat: 40.714111,
    lon: -74.008585,
    North_Direction_Label: 'Uptown',
    South_Direction_Label: 'Downtown & Brooklyn',
    // EDITED: added E
    Daytime_Routes_Array: ['A', 'C', 'E'],
    angle: 0,
  },
  {
    Station_ID: 171,
    Complex_ID: 624,
    stopId: 'E01',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'World Trade Center',
    Borough: 'M',
    Daytime_Routes: 'E',
    Structure: 'Subway',
    // real position
    // lat: 40.712582,
    // lon: -74.009781,
    // fake position to improve the transfer link visualization
    lat: 40.71272,
    lon: -74.009842,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['E', 'D'],
    lineIcons: ['E'],
    angle: 90,
  },
  {
    Station_ID: 172,
    Complex_ID: 628,
    stopId: 'A38',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Fulton St',
    Borough: 'M',
    Daytime_Routes: 'A C',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.709974,
    lon: -74.00794,
    // real position
    // lat: 40.710197,
    // lon: -74.007691,
    North_Direction_Label: 'Uptown',
    South_Direction_Label: 'Brooklyn',
    Daytime_Routes_Array: ['A', 'C', 'J', 'Z'],
    angle: 0,
  },
  {
    Station_ID: 173,
    Complex_ID: 173,
    stopId: 'A40',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'High St',
    Borough: 'Bk',
    Daytime_Routes: 'A C',
    Structure: 'Subway',
    lat: 40.699337,
    lon: -73.990531,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid - Lefferts - Rockaways',
    Daytime_Routes_Array: ['A', 'C'],
    angle: 90,
  },
  {
    Station_ID: 174,
    Complex_ID: 636,
    stopId: 'A41',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Jay St - MetroTech',
    Borough: 'Bk',
    Daytime_Routes: 'A C F',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.691961,
    lon: -73.986316,
    // real position
    // lat: 40.692338,
    // lon: -73.987342,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid - Lefferts - Rockaways - Coney Island',
    // EDITED
    Daytime_Routes_Array: ['F', 'A', 'C', 'Q', 'N', 'W', 'R'],
    // perpendicularStack: ['R'],
    angle: 0,
  },
  {
    Station_ID: 175,
    Complex_ID: 175,
    stopId: 'A42',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Hoyt - Schermerhorn St',
    Borough: 'Bk',
    Daytime_Routes: 'A C G',
    Structure: 'Subway',
    lat: 40.688484,
    lon: -73.985001,
    North_Direction_Label: 'Manhattan - Church Av',
    South_Direction_Label: 'Euclid Av & Queens - Court Sq',
    Daytime_Routes_Array: ['G', 'A', 'C'],
    angle: 90,
  },
  {
    Station_ID: 176,
    Complex_ID: 176,
    stopId: 'A43',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Lafayette Av',
    Borough: 'Bk',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.686113,
    lon: -73.973946,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid Av',
    // EDITED: added 'A' but it's NIGHTS_ONLY
    Daytime_Routes_Array: ['A', 'C'],
    angle: 135,
  },
  {
    Station_ID: 177,
    Complex_ID: 177,
    stopId: 'A44',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Clinton - Washington Avs',
    Borough: 'Bk',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.683263,
    lon: -73.965838,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid Av',
    // EDITED: added 'A' but it's NIGHTS_ONLY
    Daytime_Routes_Array: ['A', 'C'],
    angle: 90,
  },
  {
    Station_ID: 178,
    Complex_ID: 627,
    stopId: 'A45',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Franklin Av',
    Borough: 'Bk',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.68138,
    lon: -73.956848,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid Av',
    // EDITED: added 'A' but it's NIGHTS_ONLY
    Daytime_Routes_Array: ['A', 'C'],
    angle: 110,
  },
  {
    Station_ID: 179,
    Complex_ID: 179,
    stopId: 'A46',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Nostrand Av',
    Borough: 'Bk',
    Daytime_Routes: 'A C',
    Structure: 'Subway',
    lat: 40.680438,
    lon: -73.950426,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid - Lefferts - Rockaways',
    Daytime_Routes_Array: ['A', 'C'],
    angle: 110,
  },
  {
    Station_ID: 180,
    Complex_ID: 180,
    stopId: 'A47',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Kingston - Throop Avs',
    Borough: 'Bk',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.679921,
    lon: -73.940858,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid Av',
    Daytime_Routes_Array: ['A', 'C'],
    angle: 115,
  },
  {
    Station_ID: 181,
    Complex_ID: 181,
    stopId: 'A48',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Utica Av',
    Borough: 'Bk',
    Daytime_Routes: 'A C',
    Structure: 'Subway',
    lat: 40.679364,
    lon: -73.930729,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid - Lefferts - Rockaways',
    Daytime_Routes_Array: ['A', 'C'],
  },
  {
    Station_ID: 182,
    Complex_ID: 182,
    stopId: 'A49',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Ralph Av',
    Borough: 'Bk',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.678822,
    lon: -73.920786,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid Av',
    Daytime_Routes_Array: ['A', 'C'],
    angle: 115,
  },
  {
    Station_ID: 183,
    Complex_ID: 183,
    stopId: 'A50',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Rockaway Av',
    Borough: 'Bk',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.67834,
    lon: -73.911946,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid Av',
    Daytime_Routes_Array: ['A', 'C'],
    angle: 115,
  },
  // EDITED: commented out to remove duplicate label when unifying `Broadway Jct`
  // {
  //   Station_ID: 184,
  //   Complex_ID: 621,
  //   stopId: 'A51',
  //   Division: 'IND',
  //   Line: '8th Av - Fulton St',
  //   // stopName: 'Broadway Jct',
  //   Borough: 'Bk',
  //   Daytime_Routes: 'A C',
  //   Structure: 'Subway',
  //   lat: 40.678334,
  //   lon: -73.905316,
  //   North_Direction_Label: 'Manhattan',
  //   South_Direction_Label: 'Euclid - Lefferts - Rockaways',
  //   Daytime_Routes_Array: ['A', 'C'],
  // },
  {
    Station_ID: 185,
    Complex_ID: 185,
    stopId: 'A52',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Liberty Av',
    Borough: 'Bk',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.674542,
    lon: -73.896548,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid Av',
    Daytime_Routes_Array: ['A', 'C'],
    angle: 43,
  },
  {
    Station_ID: 186,
    Complex_ID: 186,
    stopId: 'A53',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Van Siclen Av',
    Borough: 'Bk',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.67271,
    lon: -73.890358,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid Av',
    Daytime_Routes_Array: ['A', 'C'],
    angle: 129,
  },
  {
    Station_ID: 187,
    Complex_ID: 187,
    stopId: 'A54',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Shepherd Av',
    Borough: 'Bk',
    Daytime_Routes: 'C',
    Structure: 'Subway',
    lat: 40.67413,
    lon: -73.88075,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Euclid Av',
    Daytime_Routes_Array: ['A', 'C'],
  },
  {
    Station_ID: 188,
    Complex_ID: 188,
    stopId: 'A55',
    Division: 'IND',
    Line: '8th Av - Fulton St',
    // stopName: 'Euclid Av',
    Borough: 'Bk',
    Daytime_Routes: 'A C',
    Structure: 'Subway',
    lat: 40.675377,
    lon: -73.872106,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Lefferts - Rockaways',
    Daytime_Routes_Array: ['A', 'C'],
    lineIcons: ['C'],
  },
  {
    Station_ID: 189,
    Complex_ID: 189,
    stopId: 'A57',
    Division: 'IND',
    Line: 'Liberty Av',
    // stopName: 'Grant Av',
    Borough: 'Bk',
    Daytime_Routes: 'A',
    Structure: 'Subway',
    lat: 40.677044,
    lon: -73.86505,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Lefferts - Rockaways',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 190,
    Complex_ID: 190,
    stopId: 'A59',
    Division: 'IND',
    Line: 'Liberty Av',
    // stopName: '80 St',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Elevated',
    lat: 40.679371,
    lon: -73.858992,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Lefferts - Rockaways',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 191,
    Complex_ID: 191,
    stopId: 'A60',
    Division: 'IND',
    Line: 'Liberty Av',
    // stopName: '88 St',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Elevated',
    lat: 40.679843,
    lon: -73.85147,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Lefferts - Rockaways',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 192,
    Complex_ID: 192,
    stopId: 'A61',
    Division: 'IND',
    Line: 'Liberty Av',
    // stopName: 'Rockaway Blvd',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Elevated',
    lat: 40.680429,
    lon: -73.843853,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Lefferts - Rockaways',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 193,
    Complex_ID: 193,
    stopId: 'A63',
    Division: 'IND',
    Line: 'Liberty Av',
    // stopName: '104 St',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Elevated',
    lat: 40.681711,
    lon: -73.837683,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Lefferts Blvd',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 194,
    Complex_ID: 194,
    stopId: 'A64',
    Division: 'IND',
    Line: 'Liberty Av',
    // stopName: '111 St',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Elevated',
    lat: 40.684331,
    lon: -73.832163,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Lefferts Blvd',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 195,
    Complex_ID: 195,
    stopId: 'A65',
    Division: 'IND',
    Line: 'Liberty Av',
    // stopName: 'Ozone Park - Lefferts Blvd',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Elevated',
    lat: 40.685951,
    lon: -73.825798,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['A'],
    angle: 0,
    lineIcons: ['A'],
  },
  {
    Station_ID: 196,
    Complex_ID: 196,
    stopId: 'H01',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Aqueduct Racetrack',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'At Grade',
    lat: 40.672097,
    lon: -73.835919,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Rockaways',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 197,
    Complex_ID: 197,
    stopId: 'H02',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Aqueduct - N Conduit Av',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'At Grade',
    lat: 40.668234,
    lon: -73.834058,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Rockaways',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 198,
    Complex_ID: 198,
    stopId: 'H03',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Howard Beach - JFK Airport',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'At Grade',
    lat: 40.660476,
    lon: -73.830301,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Rockaways',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 199,
    Complex_ID: 199,
    stopId: 'H04',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Broad Channel',
    Borough: 'Q',
    Daytime_Routes: 'A S',
    Structure: 'At Grade',
    lat: 40.608382,
    lon: -73.815925,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Rockaways',
    Daytime_Routes_Array: ['H', 'A'],
    angle: 45,
    lineIcons: ['H'],
  },
  {
    Station_ID: 200,
    Complex_ID: 200,
    stopId: 'H12',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Beach 90 St',
    Borough: 'Q',
    Daytime_Routes: 'A S',
    Structure: 'Viaduct',
    lat: 40.588034,
    lon: -73.813641,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Rockaway Park',
    Daytime_Routes_Array: ['A', 'H'],
    angle: 147,
  },
  {
    Station_ID: 201,
    Complex_ID: 201,
    stopId: 'H13',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Beach 98 St',
    Borough: 'Q',
    Daytime_Routes: 'A S',
    Structure: 'Viaduct',
    lat: 40.585307,
    lon: -73.820558,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Rockaway Park',
    Daytime_Routes_Array: ['A', 'H'],
    angle: 135,
  },
  {
    Station_ID: 202,
    Complex_ID: 202,
    stopId: 'H14',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Beach 105 St',
    Borough: 'Q',
    Daytime_Routes: 'A S',
    Structure: 'Viaduct',
    lat: 40.583209,
    lon: -73.827559,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Rockaway Park',
    Daytime_Routes_Array: ['A', 'H'],
    angle: 135,
  },
  {
    Station_ID: 203,
    Complex_ID: 203,
    stopId: 'H15',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Rockaway Park - Beach 116 St',
    Borough: 'Q',
    Daytime_Routes: 'A S',
    Structure: 'At Grade',
    lat: 40.580903,
    lon: -73.835592,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['A', 'H'],
    angle: 135,
    lineIcons: ['A', 'H'],
  },
  {
    Station_ID: 204,
    Complex_ID: 204,
    stopId: 'H06',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Beach 67 St',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Viaduct',
    lat: 40.590927,
    lon: -73.796924,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Far Rockaway',
    Daytime_Routes_Array: ['A'],
    angle: 125,
  },
  {
    Station_ID: 205,
    Complex_ID: 205,
    stopId: 'H07',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Beach 60 St',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Viaduct',
    lat: 40.592374,
    lon: -73.788522,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Far Rockaway',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 206,
    Complex_ID: 206,
    stopId: 'H08',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Beach 44 St',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Viaduct',
    lat: 40.592943,
    lon: -73.776013,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Far Rockaway',
    Daytime_Routes_Array: ['A'],
    angle: 125,
  },
  {
    Station_ID: 207,
    Complex_ID: 207,
    stopId: 'H09',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Beach 36 St',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Viaduct',
    lat: 40.595398,
    lon: -73.768175,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Far Rockaway',
    Daytime_Routes_Array: ['A'],
    angle: 159,
  },
  {
    Station_ID: 208,
    Complex_ID: 208,
    stopId: 'H10',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Beach 25 St',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Viaduct',
    lat: 40.600066,
    lon: -73.761353,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Far Rockaway',
    Daytime_Routes_Array: ['A'],
  },
  {
    Station_ID: 209,
    Complex_ID: 209,
    stopId: 'H11',
    Division: 'IND',
    Line: 'Rockaway',
    // stopName: 'Far Rockaway - Mott Av',
    Borough: 'Q',
    Daytime_Routes: 'A',
    Structure: 'Viaduct',
    lat: 40.603995,
    lon: -73.755405,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['A'],
    lineIcons: ['A'],
    angle: -22,
  },
  {
    Station_ID: 210,
    Complex_ID: 210,
    stopId: 'D01',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: 'Norwood - 205 St',
    Borough: 'Bx',
    Daytime_Routes: 'D',
    Structure: 'Subway',
    lat: 40.874811,
    lon: -73.878855,
    North_Direction_Label: '',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['D'],
    lineIcons: ['D'],
  },
  {
    Station_ID: 211,
    Complex_ID: 211,
    stopId: 'D03',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: 'Bedford Park Blvd',
    Borough: 'Bx',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.873244,
    lon: -73.887138,
    North_Direction_Label: 'Norwood - 205 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['B', 'D'],
    lineIcons: ['B'],
  },
  {
    Station_ID: 212,
    Complex_ID: 212,
    stopId: 'D04',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: 'Kingsbridge Rd',
    Borough: 'Bx',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.866978,
    lon: -73.893509,
    North_Direction_Label: 'Bedford Pk Blvd & 205 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['B', 'D'],
  },
  {
    Station_ID: 213,
    Complex_ID: 213,
    stopId: 'D05',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: 'Fordham Rd',
    Borough: 'Bx',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.861296,
    lon: -73.897749,
    North_Direction_Label: 'Bedford Pk Blvd & 205 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['B', 'D'],
  },
  {
    Station_ID: 214,
    Complex_ID: 214,
    stopId: 'D06',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: '182-183 Sts',
    Borough: 'Bx',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.856093,
    lon: -73.900741,
    North_Direction_Label: 'Bedford Pk Blvd & 205 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['B', 'D'],
  },
  {
    Station_ID: 215,
    Complex_ID: 215,
    stopId: 'D07',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: 'Tremont Av',
    Borough: 'Bx',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.85041,
    lon: -73.905227,
    North_Direction_Label: 'Bedford Pk Blvd & 205 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['B', 'D'],
  },
  {
    Station_ID: 216,
    Complex_ID: 216,
    stopId: 'D08',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: '174-175 Sts',
    Borough: 'Bx',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.8459,
    lon: -73.910136,
    North_Direction_Label: 'Bedford Pk Blvd & 205 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['B', 'D'],
  },
  {
    Station_ID: 217,
    Complex_ID: 217,
    stopId: 'D09',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: '170 St',
    Borough: 'Bx',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.839306,
    lon: -73.9134,
    North_Direction_Label: 'Bedford Pk Blvd & 205 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['B', 'D'],
  },
  {
    Station_ID: 218,
    Complex_ID: 218,
    stopId: 'D10',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: '167 St',
    Borough: 'Bx',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.833771,
    lon: -73.91844,
    North_Direction_Label: 'Bedford Pk Blvd & 205 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['B', 'D'],
  },
  {
    Station_ID: 219,
    Complex_ID: 604,
    stopId: 'D11',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: '161 St - Yankee Stadium',
    Borough: 'Bx',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.827905,
    lon: -73.925651,
    North_Direction_Label: 'Bedford Pk Blvd & 205 St',
    South_Direction_Label: 'Manhattan',
    // EDITED
    Daytime_Routes_Array: ['B', 'D', '4'],
    angle: -90,
  },
  {
    Station_ID: 220,
    Complex_ID: 220,
    stopId: 'D12',
    Division: 'IND',
    Line: 'Concourse',
    // stopName: '155 St',
    Borough: 'M',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.830135,
    lon: -73.938209,
    North_Direction_Label: 'The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['B', 'D'],
  },
  {
    Station_ID: 221,
    Complex_ID: 221,
    stopId: 'B04',
    Division: 'IND',
    Line: '63rd St',
    // stopName: '21 St - Queensbridge',
    Borough: 'Q',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.754446,
    lon: -73.942039,
    // real position
    // lat: 40.754203,
    // lon: -73.942836,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['F'],
    angle: 90,
  },
  {
    Station_ID: 222,
    Complex_ID: 222,
    stopId: 'B06',
    Division: 'IND',
    Line: '63rd St',
    // stopName: 'Roosevelt Island',
    Borough: 'M',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    lat: 40.759145,
    lon: -73.95326,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['F'],
    angle: 90,
  },
  {
    Station_ID: 223,
    Complex_ID: 223,
    stopId: 'B08',
    Division: 'IND',
    Line: '63rd St',
    // stopName: 'Lexington Av/63 St',
    Borough: 'M',
    Daytime_Routes: 'F Q',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.764649,
    lon: -73.966114,
    // real position
    // lat: 40.764629,
    // lon: -73.966113,
    North_Direction_Label: 'Uptown - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    // Edited: added other yellow lines which are sometimes rerouted here
    Daytime_Routes_Array: ['R', 'W', 'N', 'Q', 'F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 224,
    Complex_ID: 224,
    stopId: 'B10',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: '57 St',
    Borough: 'M',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    lat: 40.76385, // Edited for station alignment
    lon: -73.97745,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['E', 'F'],
    angle: 0,
  },
  {
    Station_ID: 225,
    Complex_ID: 225,
    stopId: 'D15',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: '47-50 Sts - Rockefeller Ctr',
    Borough: 'M',
    Daytime_Routes: 'B D F M',
    Structure: 'Subway',
    lat: 40.758663,
    lon: -73.981329,
    North_Direction_Label: 'Uptown & The Bronx - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['B', 'D', 'F', 'M'],
    angle: 0,
  },
  {
    Station_ID: 226,
    Complex_ID: 609,
    stopId: 'D16',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: '42 St - Bryant Pk',
    Borough: 'M',
    Daytime_Routes: 'B D F M',
    Structure: 'Subway',
    lat: 40.754222,
    lon: -73.984569,
    North_Direction_Label: 'Uptown & The Bronx - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['B', 'D', 'F', 'M'],
    angle: 0,
  },
  // Commented out as we are using 'D16' as the primary stopId for this station
  // {
  //   Station_ID: 227,
  //   Complex_ID: 607,
  //   stopId: 'D17',
  //   Division: 'IND',
  //   Line: '6th Av - Culver',
  //   // stopName: '34 St - Herald Sq',
  //   Borough: 'M',
  //   Daytime_Routes: 'B D F M',
  //   Structure: 'Subway',
  //   // lat: 40.749719,
  //   // lon: -73.987823,
  //   // EDITED: copied from stop R17
  //   lat: 40.749567,
  //   lon: -73.98795,
  //   North_Direction_Label: 'Uptown & The Bronx - Queens',
  //   South_Direction_Label: 'Downtown & Brooklyn',
  //   // EDITED
  //   Daytime_Routes_Array: ['B', 'D', 'F', 'M', 'R', 'W', 'N', 'Q'],
  // },
  {
    Station_ID: 228,
    Complex_ID: 228,
    stopId: 'D18',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: '23 St',
    Borough: 'M',
    Daytime_Routes: 'F M',
    Structure: 'Subway',
    lat: 40.742878,
    lon: -73.992821,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['B', 'D', 'F', 'M'],
    angle: 0,
  },
  {
    Station_ID: 229,
    Complex_ID: 601,
    stopId: 'D19',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: '14 St',
    Borough: 'M',
    Daytime_Routes: 'F M',
    Structure: 'Subway',
    lat: 40.738228,
    lon: -73.996209,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['B', 'D', 'F', 'M'],
    angle: 0,
  },
  {
    Station_ID: 230,
    Complex_ID: 619,
    stopId: 'D21',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Broadway-Lafayette St',
    Borough: 'M',
    Daytime_Routes: 'B D F M',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.725383,
    lon: -73.995844,
    // real position
    // lat: 40.725297,
    // lon: -73.996204,
    North_Direction_Label: 'Uptown & The Bronx - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['B', 'D', 'F', 'M', 'A', 'C'],
    angle: 90,
  },
  {
    Station_ID: 231,
    Complex_ID: 231,
    stopId: 'D22',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Grand St',
    Borough: 'M',
    Daytime_Routes: 'B D',
    Structure: 'Subway',
    lat: 40.718267,
    lon: -73.993753,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Brooklyn',
    Daytime_Routes_Array: ['B', 'D'],
    angle: 0,
  },
  {
    Station_ID: 232,
    Complex_ID: 232,
    stopId: 'F14',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: '2 Av',
    Borough: 'M',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.72315,
    lon: -73.9902,
    // real position
    // lat: 40.723402,
    // lon: -73.989938,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['F', 'M', 'A', 'C'],
  },
  // {
  //   Station_ID: 233,
  //   Complex_ID: 625,
  //   stopId: 'F15',
  //   Division: 'IND',
  //   Line: '6th Av - Culver',
  //   // stopName: 'Delancey St',
  //   Borough: 'M',
  //   Daytime_Routes: 'F',
  //   Structure: 'Subway',
  //   lat: 40.718611,
  //   lon: -73.988114,
  //   North_Direction_Label: 'Uptown & Queens',
  //   South_Direction_Label: 'Downtown & Brooklyn',
  //   Daytime_Routes_Array: ['F'],
  // },
  {
    Station_ID: 234,
    Complex_ID: 234,
    stopId: 'F16',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'East Broadway',
    Borough: 'M',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.714196,
    lon: -73.990559,
    // real position
    // lat: 40.713715,
    // lon: -73.990173,
    North_Direction_Label: 'Uptown & Queens',
    South_Direction_Label: 'Brooklyn',
    // A/C are often rerouted along the F
    Daytime_Routes_Array: ['F', 'A', 'C'],
    angle: 0,
  },
  {
    Station_ID: 235,
    Complex_ID: 235,
    stopId: 'F18',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'York St',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    lat: 40.701397,
    lon: -73.986751,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    // A/C are often rerouted along the F
    Daytime_Routes_Array: ['F', 'A', 'C'],
    angle: 51.68,
  },
  {
    Station_ID: 236,
    Complex_ID: 236,
    stopId: 'F20',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Bergen St',
    Borough: 'Bk',
    Daytime_Routes: 'F G',
    Structure: 'Subway',
    lat: 40.686145,
    lon: -73.9907,
    // original position
    // lon: -73.990862,
    North_Direction_Label: 'Manhattan - Queens',
    South_Direction_Label: 'Church Av - Coney Island',
    Daytime_Routes_Array: ['F', 'G'],
    angle: 0,
  },
  {
    Station_ID: 237,
    Complex_ID: 237,
    stopId: 'F21',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Carroll St',
    Borough: 'Bk',
    Daytime_Routes: 'F G',
    Structure: 'Subway',
    lat: 40.680303,
    lon: -73.995048,
    North_Direction_Label: 'Manhattan - Queens',
    South_Direction_Label: 'Church Av - Coney Island',
    Daytime_Routes_Array: ['F', 'G'],
    angle: 0,
  },
  {
    Station_ID: 238,
    Complex_ID: 238,
    stopId: 'F22',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Smith - 9 Sts',
    Borough: 'Bk',
    Daytime_Routes: 'F G',
    Structure: 'Viaduct',
    // fake position to improve the line visual
    lat: 40.673292,
    lon: -73.996082,
    // real position
    // lat: 40.67358,
    // lon: -73.995959,
    North_Direction_Label: 'Manhattan - Queens',
    South_Direction_Label: 'Church Av - Coney Island',
    Daytime_Routes_Array: ['F', 'G'],
    angle: 90,
  },
  {
    Station_ID: 239,
    Complex_ID: 608,
    stopId: 'F23',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: '4 Av-9 St',
    Borough: 'Bk',
    Daytime_Routes: 'F G',
    Structure: 'Viaduct',
    // fake position to improve the line visual
    lat: 40.670524,
    lon: -73.989442,
    // real position
    // lat: 40.670272,
    // lon: -73.989779,
    North_Direction_Label: 'Manhattan - Queens',
    South_Direction_Label: 'Church Av - Coney Island',
    Daytime_Routes_Array: ['F', 'G'],
    angle: 90,
  },
  {
    Station_ID: 240,
    Complex_ID: 240,
    stopId: 'F24',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: '7 Av',
    Borough: 'Bk',
    Daytime_Routes: 'F G',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.666588,
    lon: -73.980094,
    // real position
    // lat: 40.666271,
    // lon: -73.980305,
    North_Direction_Label: 'Manhattan - Queens',
    South_Direction_Label: 'Church Av - Coney Island',
    Daytime_Routes_Array: ['F', 'G'],
    angle: 90,
  },
  {
    Station_ID: 241,
    Complex_ID: 241,
    stopId: 'F25',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: '15 St - Prospect Park',
    Borough: 'Bk',
    Daytime_Routes: 'F G',
    Structure: 'Subway',
    lat: 40.660365,
    lon: -73.979493,
    North_Direction_Label: 'Manhattan - Queens',
    South_Direction_Label: 'Church Av - Coney Island',
    Daytime_Routes_Array: ['F', 'G'],
  },
  {
    Station_ID: 242,
    Complex_ID: 242,
    stopId: 'F26',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Fort Hamilton Pkwy',
    Borough: 'Bk',
    Daytime_Routes: 'F G',
    Structure: 'Subway',
    lat: 40.650782,
    lon: -73.975776,
    North_Direction_Label: 'Manhattan - Queens',
    South_Direction_Label: 'Church Av - Coney Island',
    Daytime_Routes_Array: ['F', 'G'],
  },
  {
    Station_ID: 243,
    Complex_ID: 243,
    stopId: 'F27',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Church Av',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    lat: 40.644041,
    lon: -73.979678,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    // EDITED - added 'G'
    Daytime_Routes_Array: ['F', 'G'],
    angle: 45,
    lineIcons: ['G'], //issue with this station
  },
  {
    Station_ID: 244,
    Complex_ID: 244,
    stopId: 'F29',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Ditmas Av',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Elevated',
    lat: 40.636119,
    lon: -73.978172,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['F'],
  },
  {
    Station_ID: 245,
    Complex_ID: 245,
    stopId: 'F30',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: '18 Av',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Elevated',
    lat: 40.629755,
    lon: -73.976971,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['F'],
  },
  {
    Station_ID: 246,
    Complex_ID: 246,
    stopId: 'F31',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Avenue I',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Elevated',
    lat: 40.625322,
    lon: -73.976127,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['F'],
  },
  {
    Station_ID: 247,
    Complex_ID: 247,
    stopId: 'F32',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Bay Pkwy',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Elevated',
    lat: 40.620769,
    lon: -73.975264,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['F'],
  },
  {
    Station_ID: 248,
    Complex_ID: 248,
    stopId: 'F33',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Avenue N',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Elevated',
    lat: 40.61514,
    lon: -73.974197,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['F'],
  },
  {
    Station_ID: 249,
    Complex_ID: 249,
    stopId: 'F34',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Avenue P',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Elevated',
    lat: 40.608944,
    lon: -73.973022,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['F'],
    angle: 38,
  },
  {
    Station_ID: 250,
    Complex_ID: 250,
    stopId: 'F35',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Kings Hwy',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Elevated',
    lat: 40.603217,
    lon: -73.972361,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['F'],
    angle: 30,
  },
  {
    Station_ID: 251,
    Complex_ID: 251,
    stopId: 'F36',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Avenue U',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Elevated',
    lat: 40.596063,
    lon: -73.973357,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['F'],
  },
  {
    Station_ID: 252,
    Complex_ID: 252,
    stopId: 'F38',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Avenue X',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Elevated',
    lat: 40.58962,
    lon: -73.97425,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Coney Island',
    Daytime_Routes_Array: ['F'],
  },
  {
    Station_ID: 253,
    Complex_ID: 253,
    stopId: 'F39',
    Division: 'IND',
    Line: '6th Av - Culver',
    // stopName: 'Neptune Av',
    Borough: 'Bk',
    Daytime_Routes: 'F',
    Structure: 'Elevated',
    lat: 40.581011,
    lon: -73.974574,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Stillwell Av',
    Daytime_Routes_Array: ['F'],
    angle: 27,
  },
  {
    Station_ID: 254,
    Complex_ID: 254,
    stopId: 'F01',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Jamaica - 179 St',
    Borough: 'Q',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    lat: 40.712646,
    lon: -73.783817,
    North_Direction_Label: '',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['F', 'E'],
    lineIcons: ['F', 'E'],
  },
  {
    Station_ID: 255,
    Complex_ID: 255,
    stopId: 'F02',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: '169 St',
    Borough: 'Q',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    lat: 40.71047,
    lon: -73.793604,
    North_Direction_Label: '179 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['F', 'E'],
  },
  {
    Station_ID: 256,
    Complex_ID: 256,
    stopId: 'F03',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Parsons Blvd',
    Borough: 'Q',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    lat: 40.707564,
    lon: -73.803326,
    North_Direction_Label: '179 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['F', 'E'],
  },
  {
    Station_ID: 257,
    Complex_ID: 257,
    stopId: 'F04',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Sutphin Blvd',
    Borough: 'Q',
    Daytime_Routes: 'F',
    Structure: 'Subway',
    lat: 40.70546,
    lon: -73.810708,
    North_Direction_Label: '179 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['F', 'E'],
    angle: -45,
  },
  {
    Station_ID: 258,
    Complex_ID: 258,
    stopId: 'F05',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Briarwood - Van Wyck Blvd',
    Borough: 'Q',
    Daytime_Routes: 'E F',
    Structure: 'Subway',
    lat: 40.709179,
    lon: -73.820574,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 259,
    Complex_ID: 259,
    stopId: 'F06',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Kew Gardens - Union Tpke',
    Borough: 'Q',
    Daytime_Routes: 'E F',
    Structure: 'Subway',
    lat: 40.714441,
    lon: -73.831008,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 260,
    Complex_ID: 260,
    stopId: 'F07',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: '75 Av',
    Borough: 'Q',
    Daytime_Routes: 'E F',
    Structure: 'Subway',
    lat: 40.718331,
    lon: -73.837324,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 261,
    Complex_ID: 261,
    stopId: 'G08',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Forest Hills - 71 Av',
    Borough: 'Q',
    Daytime_Routes: 'E F M R',
    Structure: 'Subway',
    lat: 40.721691,
    lon: -73.844521,
    North_Direction_Label: 'Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: -90,
    lineIcons: ['R', 'M'],
  },
  {
    Station_ID: 262,
    Complex_ID: 262,
    stopId: 'G09',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: '67 Av',
    Borough: 'Q',
    Daytime_Routes: 'M R',
    Structure: 'Subway',
    lat: 40.726523,
    lon: -73.852719,
    North_Direction_Label: 'Forest Hills',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 263,
    Complex_ID: 263,
    stopId: 'G10',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: '63 Dr - Rego Park',
    Borough: 'Q',
    Daytime_Routes: 'M R',
    Structure: 'Subway',
    lat: 40.729846,
    lon: -73.861604,
    North_Direction_Label: 'Forest Hills',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 264,
    Complex_ID: 264,
    stopId: 'G11',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Woodhaven Blvd',
    Borough: 'Q',
    Daytime_Routes: 'M R',
    Structure: 'Subway',
    lat: 40.733106,
    lon: -73.869229,
    North_Direction_Label: 'Forest Hills',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 265,
    Complex_ID: 265,
    stopId: 'G12',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Grand Av - Newtown',
    Borough: 'Q',
    Daytime_Routes: 'M R',
    Structure: 'Subway',
    lat: 40.737015,
    lon: -73.877223,
    North_Direction_Label: 'Forest Hills',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: -135,
  },
  {
    Station_ID: 266,
    Complex_ID: 266,
    stopId: 'G13',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Elmhurst Av',
    Borough: 'Q',
    Daytime_Routes: 'M R',
    Structure: 'Subway',
    lat: 40.742454,
    lon: -73.882017,
    North_Direction_Label: 'Forest Hills',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 267,
    Complex_ID: 616,
    stopId: 'G14',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Jackson Hts - Roosevelt Av',
    Borough: 'Q',
    Daytime_Routes: 'E F M R',
    Structure: 'Subway',
    lat: 40.746644,
    lon: -73.891338,
    North_Direction_Label: 'Forest Hills - Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 268,
    Complex_ID: 268,
    stopId: 'G15',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: '65 St',
    Borough: 'Q',
    Daytime_Routes: 'M R',
    Structure: 'Subway',
    lat: 40.749669,
    lon: -73.898453,
    North_Direction_Label: 'Forest Hills',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 269,
    Complex_ID: 269,
    stopId: 'G16',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Northern Blvd',
    Borough: 'Q',
    Daytime_Routes: 'M R',
    Structure: 'Subway',
    lat: 40.752885,
    lon: -73.906006,
    North_Direction_Label: 'Forest Hills',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 270,
    Complex_ID: 270,
    stopId: 'G18',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: '46 St',
    Borough: 'Q',
    Daytime_Routes: 'M R',
    Structure: 'Subway',
    lat: 40.756312,
    lon: -73.913333,
    North_Direction_Label: 'Forest Hills',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: -90,
  },
  {
    Station_ID: 271,
    Complex_ID: 271,
    stopId: 'G19',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Steinway St',
    Borough: 'Q',
    Daytime_Routes: 'M R',
    Structure: 'Subway',
    lat: 40.756879,
    lon: -73.92074,
    North_Direction_Label: 'Forest Hills',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    angle: 0,
  },
  {
    Station_ID: 272,
    Complex_ID: 272,
    stopId: 'G20',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: '36 St',
    Borough: 'Q',
    Daytime_Routes: 'M R',
    Structure: 'Subway',
    lat: 40.752039,
    lon: -73.928781,
    North_Direction_Label: 'Forest Hills',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'F', 'E'],
    // angle: 135,
  },
  {
    Station_ID: 273,
    Complex_ID: 273,
    stopId: 'G21',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Queens Plaza',
    Borough: 'Q',
    Daytime_Routes: 'E M R',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.74953,
    lon: -73.936864,
    // real position
    // lat: 40.748973,
    // lon: -73.937243,
    North_Direction_Label: 'Forest Hills - Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['R', 'M', 'E'],
    angle: 0,
  },
  {
    Station_ID: 274,
    Complex_ID: 606,
    stopId: 'F09',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Court Sq - 23 St',
    Borough: 'Q',
    Daytime_Routes: 'E M',
    Structure: 'Subway',
    lat: 40.747846,
    lon: -73.946,
    North_Direction_Label: 'Forest Hills - Jamaica',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['E', 'M'],
    angle: 90,
  },
  {
    Station_ID: 275,
    Complex_ID: 612,
    stopId: 'F11',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: 'Lexington Av/53 St',
    Borough: 'M',
    Daytime_Routes: 'E M',
    Structure: 'Subway',
    lat: 40.757552,
    lon: -73.969055,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['E', 'M'],
    angle: 90,
  },
  {
    Station_ID: 276,
    Complex_ID: 276,
    stopId: 'F12',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: '5 Av/53 St',
    Borough: 'M',
    Daytime_Routes: 'E M',
    Structure: 'Subway',
    lat: 40.760167,
    lon: -73.975224,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['E', 'M'],
    angle: 90,
  },
  {
    Station_ID: 277,
    Complex_ID: 277,
    stopId: 'D14',
    Division: 'IND',
    Line: 'Queens Blvd',
    // stopName: '7 Av',
    Borough: 'M',
    Daytime_Routes: 'B D E',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.763229,
    lon: -73.982434,
    // real position
    // lat: 40.762862,
    // Moved to the right to disambiguate it from the yellow R/W/N/Q lines
    // lon: -73.981537,
    North_Direction_Label: 'Uptown & The Bronx - Queens',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['E', 'B', 'D'],
    angle: 90,
  },
  {
    Station_ID: 278,
    Complex_ID: 278,
    stopId: 'G05',
    Division: 'IND',
    Line: 'Queens - Archer',
    // stopName: 'Jamaica Center - Parsons/Archer',
    Borough: 'Q',
    Daytime_Routes: 'E J Z',
    Structure: 'Subway',
    lat: 40.702147,
    lon: -73.801109,
    North_Direction_Label: '',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J', 'E'],
    angle: 135,
    lineIcons: ['Z', 'J', 'E'],
  },
  {
    Station_ID: 279,
    Complex_ID: 279,
    stopId: 'G06',
    Division: 'IND',
    Line: 'Queens - Archer',
    // stopName: 'Sutphin Blvd - Archer Av - JFK Airport',
    Borough: 'Q',
    Daytime_Routes: 'E J Z',
    Structure: 'Subway',
    lat: 40.700486,
    lon: -73.807969,
    North_Direction_Label: 'Jamaica Center',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['Z', 'J', 'E'],
    angle: 135,
  },
  {
    Station_ID: 280,
    Complex_ID: 280,
    stopId: 'G07',
    Division: 'IND',
    Line: 'Queens - Archer',
    // stopName: 'Jamaica - Van Wyck',
    Borough: 'Q',
    Daytime_Routes: 'E',
    Structure: 'Subway',
    lat: 40.702566,
    lon: -73.816859,
    North_Direction_Label: 'Jamaica Center',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['E'],
    angle: 90,
  },
  // {
  //   Station_ID: 281,
  //   Complex_ID: 606,
  //   stopId: 'G22',
  //   Division: 'IND',
  //   Line: 'Crosstown',
  //   // stopName: 'Court Sq',
  //   Borough: 'Q',
  //   Daytime_Routes: 'G',
  //   Structure: 'Subway',
  //   lat: 40.746554,
  //   lon: -73.943832,
  //   North_Direction_Label: '',
  //   South_Direction_Label: 'Brooklyn',
  //   Daytime_Routes_Array: ['G'],
  // },
  {
    Station_ID: 282,
    Complex_ID: 282,
    stopId: 'G24',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: '21 St',
    Borough: 'Q',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.744065,
    lon: -73.949724,
    North_Direction_Label: 'Court Sq',
    South_Direction_Label: 'Brooklyn',
    Daytime_Routes_Array: ['G'],
  },
  {
    Station_ID: 283,
    Complex_ID: 283,
    stopId: 'G26',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: 'Greenpoint Av',
    Borough: 'Bk',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.731352,
    lon: -73.954449,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Church Av',
    Daytime_Routes_Array: ['G'],
  },
  {
    Station_ID: 284,
    Complex_ID: 284,
    stopId: 'G28',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: 'Nassau Av',
    Borough: 'Bk',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.724635,
    lon: -73.951277,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Church Av',
    Daytime_Routes_Array: ['G'],
  },
  {
    Station_ID: 285,
    Complex_ID: 629,
    stopId: 'G29',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: 'Metropolitan Av',
    Borough: 'Bk',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.712792,
    lon: -73.951418,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Church Av',
    Daytime_Routes_Array: ['G'],
  },
  {
    Station_ID: 286,
    Complex_ID: 286,
    stopId: 'G30',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: 'Broadway',
    Borough: 'Bk',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.706092,
    lon: -73.950308,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Church Av',
    Daytime_Routes_Array: ['G'],
  },
  {
    Station_ID: 287,
    Complex_ID: 287,
    stopId: 'G31',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: 'Flushing Av',
    Borough: 'Bk',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.700377,
    lon: -73.950234,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Church Av',
    Daytime_Routes_Array: ['G'],
  },
  {
    Station_ID: 288,
    Complex_ID: 288,
    stopId: 'G32',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: 'Myrtle - Willoughby Avs',
    Borough: 'Bk',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.694568,
    lon: -73.949046,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Church Av',
    Daytime_Routes_Array: ['G'],
    angle: 90 - 52,
  },
  {
    Station_ID: 289,
    Complex_ID: 289,
    stopId: 'G33',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: 'Bedford - Nostrand Avs',
    Borough: 'Bk',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.689627,
    lon: -73.953522,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Church Av',
    Daytime_Routes_Array: ['G'],
    angle: 90 + 37,
  },
  {
    Station_ID: 290,
    Complex_ID: 290,
    stopId: 'G34',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: 'Classon Av',
    Borough: 'Bk',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.688873,
    lon: -73.96007,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Church Av',
    Daytime_Routes_Array: ['G'],
  },
  {
    Station_ID: 291,
    Complex_ID: 291,
    stopId: 'G35',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: 'Clinton - Washington Avs',
    Borough: 'Bk',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.688089,
    lon: -73.966839,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Church Av',
    Daytime_Routes_Array: ['G'],
  },
  {
    Station_ID: 292,
    Complex_ID: 292,
    stopId: 'G36',
    Division: 'IND',
    Line: 'Crosstown',
    // stopName: 'Fulton St',
    Borough: 'Bk',
    Daytime_Routes: 'G',
    Structure: 'Subway',
    lat: 40.687119,
    lon: -73.975375,
    North_Direction_Label: 'Queens',
    South_Direction_Label: 'Church Av',
    Daytime_Routes_Array: ['G'],
    angle: 128,
  },
  {
    Station_ID: 293,
    Complex_ID: 293,
    stopId: '101',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Van Cortlandt Park - 242 St',
    Borough: 'Bx',
    Daytime_Routes: '1',
    Structure: 'Elevated',
    lat: 40.889248,
    lon: -73.898583,
    North_Direction_Label: '',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['1'],
    lineIcons: ['1'],
  },
  {
    Station_ID: 294,
    Complex_ID: 294,
    stopId: '103',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '238 St',
    Borough: 'Bx',
    Daytime_Routes: '1',
    Structure: 'Elevated',
    lat: 40.884667,
    lon: -73.90087,
    North_Direction_Label: '242 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 295,
    Complex_ID: 295,
    stopId: '104',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '231 St',
    Borough: 'Bx',
    Daytime_Routes: '1',
    Structure: 'Elevated',
    lat: 40.878856,
    lon: -73.904834,
    North_Direction_Label: '242 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 296,
    Complex_ID: 296,
    stopId: '106',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Marble Hill - 225 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Elevated',
    lat: 40.874561,
    lon: -73.909831,
    North_Direction_Label: '242 St',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 297,
    Complex_ID: 297,
    stopId: '107',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '215 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Elevated',
    lat: 40.869444,
    lon: -73.915279,
    North_Direction_Label: 'The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 298,
    Complex_ID: 298,
    stopId: '108',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '207 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Elevated',
    lat: 40.864621,
    lon: -73.918822,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 299,
    Complex_ID: 299,
    stopId: '109',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Dyckman St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Elevated',
    lat: 40.860531,
    lon: -73.925536,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 300,
    Complex_ID: 300,
    stopId: '110',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '191 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.855225,
    lon: -73.929412,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 301,
    Complex_ID: 301,
    stopId: '111',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '181 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.849505,
    lon: -73.933596,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 302,
    Complex_ID: 605,
    stopId: '112',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '168 St - Washington Hts',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.840556,
    lon: -73.940133,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 303,
    Complex_ID: 303,
    stopId: '113',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '157 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.834041,
    lon: -73.94489,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 304,
    Complex_ID: 304,
    stopId: '114',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '145 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.826551,
    lon: -73.95036,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 305,
    Complex_ID: 305,
    stopId: '115',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '137 St - City College',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.822008,
    lon: -73.953676,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 306,
    Complex_ID: 306,
    stopId: '116',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '125 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Elevated',
    lat: 40.815581,
    lon: -73.958372,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 307,
    Complex_ID: 307,
    stopId: '117',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '116 St - Columbia University',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.807722,
    lon: -73.96411,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 308,
    Complex_ID: 308,
    stopId: '118',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Cathedral Pkwy',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.803967,
    lon: -73.966847,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 309,
    Complex_ID: 309,
    stopId: '119',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '103 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.799446,
    lon: -73.968379,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1', '2', '3'], // Edited for station alignment
    angle: 0,
  },
  {
    Station_ID: 310,
    Complex_ID: 310,
    stopId: '120',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '96 St',
    Borough: 'M',
    Daytime_Routes: '1 2 3',
    Structure: 'Subway',
    lat: 40.793919,
    lon: -73.972323,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['1', '2', '3'],
    angle: 0,
  },
  {
    Station_ID: 311,
    Complex_ID: 311,
    stopId: '121',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '86 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.788644,
    lon: -73.976218,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1', '2', '3'],
    angle: 0,
  },
  {
    Station_ID: 312,
    Complex_ID: 312,
    stopId: '122',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '79 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.783934,
    lon: -73.979917,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1', '2', '3'],
  },
  {
    Station_ID: 313,
    Complex_ID: 313,
    stopId: '123',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '72 St',
    Borough: 'M',
    Daytime_Routes: '1 2 3',
    Structure: 'Subway',
    lat: 40.778453,
    lon: -73.98197,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['1', '2', '3'],
    angle: 20,
  },
  {
    Station_ID: 314,
    Complex_ID: 314,
    stopId: '124',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '66 St - Lincoln Center',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.77344,
    lon: -73.982209,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1', '2', '3'],
    angle: 35,
  },
  // EDITED: commented out to remove duplicate label when unifying `Columbus Circle`
  // {
  //   Station_ID: 315,
  //   Complex_ID: 614,
  //   stopId: '125',
  //   Division: 'IRT',
  //   Line: 'Broadway - 7Av',
  //   // stopName: '59 St - Columbus Circle',
  //   Borough: 'M',
  //   Daytime_Routes: '1',
  //   Structure: 'Subway',
  //   lat: 40.768247,
  //   lon: -73.981929,
  //   North_Direction_Label: 'Uptown & The Bronx',
  //   South_Direction_Label: 'Downtown',
  //   Daytime_Routes_Array: ['1'],
  //   angle: 0,
  // },
  {
    Station_ID: 316,
    Complex_ID: 316,
    stopId: '126',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '50 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.761728,
    lon: -73.983849,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1', '2', '3'],
  },
  {
    Station_ID: 317,
    Complex_ID: 611,
    stopId: '127',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Times Sq-42 St',
    Borough: 'M',
    Daytime_Routes: '1 2 3',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.75575,
    lon: -73.9866,
    // real position
    // lat: 40.75529,
    // lon: -73.987495,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    // EDITED
    Daytime_Routes_Array: ['1', '2', '3', 'R', 'W', 'N', 'Q', 'GS', '7'],
    perpendicularStack: ['GS', '7'],
    lineIcons: ['GS'],
    angle: 0,
  },
  {
    Station_ID: 318,
    Complex_ID: 318,
    stopId: '128',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '34 St - Penn Station',
    Borough: 'M',
    Daytime_Routes: '1 2 3',
    Structure: 'Subway',
    lat: 40.750373,
    lon: -73.991057,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['1', '2', '3'],
  },
  {
    Station_ID: 319,
    Complex_ID: 319,
    stopId: '129',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '28 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.747215,
    lon: -73.993365,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1', '2', '3'],
  },
  {
    Station_ID: 320,
    Complex_ID: 320,
    stopId: '130',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '23 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.744081,
    lon: -73.995657,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1', '2', '3'],
  },
  {
    Station_ID: 321,
    Complex_ID: 321,
    stopId: '131',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '18 St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.74104,
    lon: -73.997871,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1', '2', '3'],
  },
  {
    Station_ID: 322,
    Complex_ID: 601,
    stopId: '132',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: '14 St',
    Borough: 'M',
    Daytime_Routes: '1 2 3',
    Structure: 'Subway',
    lat: 40.737826,
    lon: -74.000201,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['1', '2', '3'],
    angle: 0,
  },
  {
    Station_ID: 323,
    Complex_ID: 323,
    stopId: '133',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Christopher St - Sheridan Sq',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.7337,
    lon: -74.0032,
    // original position
    // lat: 40.733422,
    // lon: -74.002906,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    // EDITED
    Daytime_Routes_Array: ['1', '2', '3'],
  },
  {
    Station_ID: 324,
    Complex_ID: 324,
    stopId: '134',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Houston St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.728251,
    lon: -74.005367,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    // EDITED
    Daytime_Routes_Array: ['1', '2', '3'],
    angle: 20,
  },
  {
    Station_ID: 325,
    Complex_ID: 325,
    stopId: '135',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Canal St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.722854,
    lon: -74.006277,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    // EDITED
    Daytime_Routes_Array: ['1', '2', '3'],
  },
  {
    Station_ID: 326,
    Complex_ID: 326,
    stopId: '136',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Franklin St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.719318,
    lon: -74.006886,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    // EDITED
    Daytime_Routes_Array: ['1', '2', '3'],
    angle: 20,
  },
  {
    Station_ID: 327,
    Complex_ID: 327,
    stopId: '137',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Chambers St',
    Borough: 'M',
    Daytime_Routes: '1 2 3',
    Structure: 'Subway',
    lat: 40.715478,
    lon: -74.009266,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['1', '2', '3'],
    angle: 0,
  },
  {
    Station_ID: 328,
    Complex_ID: 328,
    stopId: '138',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'WTC Cortlandt',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.711835,
    lon: -74.012188,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 329,
    Complex_ID: 329,
    stopId: '139',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'Rector St',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.707513,
    lon: -74.013783,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['1'],
  },
  {
    Station_ID: 330,
    Complex_ID: 635,
    stopId: '142',
    Division: 'IRT',
    Line: 'Broadway - 7Av',
    // stopName: 'South Ferry',
    Borough: 'M',
    Daytime_Routes: '1',
    Structure: 'Subway',
    lat: 40.702068,
    lon: -74.013664,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['1'],
    lineIcons: ['1'],
    angle: 90,
  },
  {
    Station_ID: 331,
    Complex_ID: 624,
    stopId: '228',
    Division: 'IRT',
    Line: 'Clark St',
    // stopName: 'Park Place',
    Borough: 'M',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.713051,
    lon: -74.008811,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['2', '3'],
  },
  {
    Station_ID: 332,
    Complex_ID: 628,
    stopId: '229',
    Division: 'IRT',
    Line: 'Clark St',
    // stopName: 'Fulton St',
    Borough: 'M',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.709445,
    lon: -74.006681,
    // real position
    // lat: 40.709416,
    // lon: -74.006571,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['2', '3'],
  },
  {
    Station_ID: 333,
    Complex_ID: 333,
    stopId: '230',
    Division: 'IRT',
    Line: 'Clark St',
    // stopName: 'Wall St',
    Borough: 'M',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.706536,
    lon: -74.008825,
    // real position
    // lat: 40.706821,
    // lon: -74.0091,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Brooklyn',
    Daytime_Routes_Array: ['2', '3'],
    angle: 0,
  },
  {
    Station_ID: 334,
    Complex_ID: 334,
    stopId: '231',
    Division: 'IRT',
    Line: 'Clark St',
    // stopName: 'Clark St',
    Borough: 'Bk',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.697466,
    lon: -73.993086,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush - New Lots',
    Daytime_Routes_Array: ['2', '3'],
    angle: 90,
  },
  {
    Station_ID: 335,
    Complex_ID: 620,
    stopId: '232',
    Division: 'IRT',
    Line: 'Clark St',
    // stopName: 'Borough Hall',
    Borough: 'Bk',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.6926,
    lon: -73.989998,
    // original position
    // lat: 40.693219,
    // lon: -73.989998,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush - New Lots',
    // EDITED
    Daytime_Routes_Array: ['2', '3', '4', '5'],
    angle: 90,
  },
  {
    Station_ID: 336,
    Complex_ID: 336,
    stopId: '233',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Hoyt St',
    Borough: 'Bk',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.69052, // Edited for station alignment with Nevins St
    lon: -73.985065,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush - New Lots',
    Daytime_Routes_Array: ['2', '3', '4', '5'],
    angle: 90,
  },
  {
    Station_ID: 337,
    Complex_ID: 337,
    stopId: '234',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Nevins St',
    Borough: 'Bk',
    Daytime_Routes: '2 3 4 5',
    Structure: 'Subway',
    lat: 40.6887, // Edited for station alignment with Hoyt St
    lon: -73.980492,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush - Utica - New Lots',
    Daytime_Routes_Array: ['2', '3', '4', '5', 'Q', 'B'],
    angle: 90,
  },
  {
    Station_ID: 338,
    Complex_ID: 617,
    stopId: '235',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Atlantic Av - Barclays Ctr',
    Borough: 'Bk',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.685,
    lon: -73.9773,
    // original position
    // lat: 40.684359,
    // lon: -73.977666,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush - New Lots',
    // EDITED
    Daytime_Routes_Array: ['2', '3', '4', '5', 'Q', 'B'],
    angle: 53,
  },
  {
    Station_ID: 339,
    Complex_ID: 339,
    stopId: '236',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Bergen St',
    Borough: 'Bk',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.680829,
    lon: -73.9748,
    // original position
    // lat: 40.680829,
    // lon: -73.975098,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush - New Lots',
    // EDITED: added 4 for now but it's NIGHTS_ONLY
    Daytime_Routes_Array: ['2', '3', '4', '5', 'Q', 'B'],
    angle: 53,
  },
  {
    Station_ID: 340,
    Complex_ID: 340,
    stopId: '237',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Grand Army Plaza',
    Borough: 'Bk',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.6745,
    lon: -73.97,
    // original position
    // lat: 40.675235,
    // lon: -73.971046,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush - New Lots',
    // EDITED: added 4 for now but it's NIGHTS_ONLY
    Daytime_Routes_Array: ['2', '3', '4', '5' /*, 'Q', 'B'*/],
    angle: 90,
  },
  {
    Station_ID: 341,
    Complex_ID: 341,
    stopId: '238',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Eastern Pkwy - Brooklyn Museum',
    Borough: 'Bk',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.671987,
    lon: -73.964375,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush - New Lots',
    // EDITED: added 4 for now but it's NIGHTS_ONLY
    Daytime_Routes_Array: ['2', '3', '4', '5'],
    angle: 90,
  },
  {
    Station_ID: 342,
    Complex_ID: 626,
    stopId: '239',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Franklin Av-Medgar Evers College',
    Borough: 'Bk',
    Daytime_Routes: '2 3 4 5',
    Structure: 'Subway',
    lat: 40.670682,
    lon: -73.958131,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush - Utica - New Lots',
    Daytime_Routes_Array: ['2', '3', '4', '5'],
    angle: 135,
  },
  {
    Station_ID: 343,
    Complex_ID: 343,
    stopId: '248',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Nostrand Av',
    Borough: 'Bk',
    Daytime_Routes: '3',
    Structure: 'Subway',
    lat: 40.669847,
    lon: -73.950466,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'New Lots',
    Daytime_Routes_Array: ['3', '4', '5'],
    angle: 90,
  },
  {
    Station_ID: 344,
    Complex_ID: 344,
    stopId: '249',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Kingston Av',
    Borough: 'Bk',
    Daytime_Routes: '3',
    Structure: 'Subway',
    lat: 40.669399,
    lon: -73.942161,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'New Lots',
    Daytime_Routes_Array: ['3', '4', '5'],
  },
  {
    Station_ID: 345,
    Complex_ID: 345,
    stopId: '250',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Crown Hts - Utica Av',
    Borough: 'Bk',
    Daytime_Routes: '3 4',
    Structure: 'Subway',
    lat: 40.668897,
    lon: -73.932942,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'New Lots',
    Daytime_Routes_Array: ['3', '4', '5'],
    angle: 90,
    lineIcons: ['4'],
  },
  {
    Station_ID: 346,
    Complex_ID: 346,
    stopId: '251',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Sutter Av - Rutland Rd',
    Borough: 'Bk',
    Daytime_Routes: '3',
    Structure: 'Elevated',
    lat: 40.664717,
    lon: -73.92261,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'New Lots',
    Daytime_Routes_Array: ['3', '4', '5'],
    angle: 90,
  },
  {
    Station_ID: 347,
    Complex_ID: 347,
    stopId: '252',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Saratoga Av',
    Borough: 'Bk',
    Daytime_Routes: '3',
    Structure: 'Elevated',
    lat: 40.661453,
    lon: -73.916327,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'New Lots',
    Daytime_Routes_Array: ['3', '4', '5'],
    angle: 130,
  },
  {
    Station_ID: 348,
    Complex_ID: 348,
    stopId: '253',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Rockaway Av',
    Borough: 'Bk',
    Daytime_Routes: '3',
    Structure: 'Elevated',
    lat: 40.662549,
    lon: -73.908946,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'New Lots',
    Daytime_Routes_Array: ['3', '4', '5'],
  },
  {
    Station_ID: 349,
    Complex_ID: 349,
    stopId: '254',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Junius St',
    Borough: 'Bk',
    Daytime_Routes: '3',
    Structure: 'Elevated',
    lat: 40.663515,
    lon: -73.902447,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'New Lots',
    Daytime_Routes_Array: ['3', '4', '5'],
  },
  {
    Station_ID: 350,
    Complex_ID: 350,
    stopId: '255',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Pennsylvania Av',
    Borough: 'Bk',
    Daytime_Routes: '3',
    Structure: 'Elevated',
    lat: 40.664635,
    lon: -73.894895,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'New Lots',
    Daytime_Routes_Array: ['3', '4', '5'],
  },
  {
    Station_ID: 351,
    Complex_ID: 351,
    stopId: '256',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'Van Siclen Av',
    Borough: 'Bk',
    Daytime_Routes: '3',
    Structure: 'Elevated',
    lat: 40.665449,
    lon: -73.889395,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'New Lots',
    Daytime_Routes_Array: ['3', '4', '5'],
  },
  {
    Station_ID: 352,
    Complex_ID: 352,
    stopId: '257',
    Division: 'IRT',
    Line: 'Eastern Pky',
    // stopName: 'New Lots Av',
    Borough: 'Bk',
    Daytime_Routes: '3',
    Structure: 'Elevated',
    lat: 40.666235,
    lon: -73.884079,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['3', '4', '5'],
    angle: 130,
    lineIcons: ['3'],
  },
  {
    Station_ID: 353,
    Complex_ID: 353,
    stopId: '241',
    Division: 'IRT',
    Line: 'Nostrand',
    // stopName: 'President St-Medgar Evers College',
    Borough: 'Bk',
    Daytime_Routes: '2 5',
    Structure: 'Subway',
    lat: 40.667883,
    lon: -73.950683,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush',
    Daytime_Routes_Array: ['2', '5'],
    angle: 90,
  },
  {
    Station_ID: 354,
    Complex_ID: 354,
    stopId: '242',
    Division: 'IRT',
    Line: 'Nostrand',
    // stopName: 'Sterling St',
    Borough: 'Bk',
    Daytime_Routes: '2 5',
    Structure: 'Subway',
    lat: 40.662742,
    lon: -73.95085,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush',
    Daytime_Routes_Array: ['2', '5'],
    angle: 35,
  },
  {
    Station_ID: 355,
    Complex_ID: 355,
    stopId: '243',
    Division: 'IRT',
    Line: 'Nostrand',
    // stopName: 'Winthrop St',
    Borough: 'Bk',
    Daytime_Routes: '2 5',
    Structure: 'Subway',
    lat: 40.656652,
    lon: -73.9502,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush',
    Daytime_Routes_Array: ['2', '5'],
    angle: 35,
  },
  {
    Station_ID: 356,
    Complex_ID: 356,
    stopId: '244',
    Division: 'IRT',
    Line: 'Nostrand',
    // stopName: 'Church Av',
    Borough: 'Bk',
    Daytime_Routes: '2 5',
    Structure: 'Subway',
    lat: 40.650843,
    lon: -73.949575,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush',
    Daytime_Routes_Array: ['2', '5'],
    angle: 35,
  },
  {
    Station_ID: 357,
    Complex_ID: 357,
    stopId: '245',
    Division: 'IRT',
    Line: 'Nostrand',
    // stopName: 'Beverly Rd',
    Borough: 'Bk',
    Daytime_Routes: '2 5',
    Structure: 'Subway',
    lat: 40.645098,
    lon: -73.948959,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush',
    Daytime_Routes_Array: ['2', '5'],
    angle: 35,
  },
  {
    Station_ID: 358,
    Complex_ID: 358,
    stopId: '246',
    Division: 'IRT',
    Line: 'Nostrand',
    // stopName: 'Newkirk Av',
    Borough: 'Bk',
    Daytime_Routes: '2 5',
    Structure: 'Subway',
    lat: 40.639967,
    lon: -73.948411,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: 'Flatbush',
    Daytime_Routes_Array: ['2', '5'],
    angle: 35,
  },
  {
    Station_ID: 359,
    Complex_ID: 359,
    stopId: '247',
    Division: 'IRT',
    Line: 'Nostrand',
    // stopName: 'Flatbush Av - Brooklyn College',
    Borough: 'Bk',
    Daytime_Routes: '2 5',
    Structure: 'Subway',
    lat: 40.632836,
    lon: -73.947642,
    North_Direction_Label: 'Manhattan',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['2', '5'],
    angle: 35,
    lineIcons: ['2', '5'],
  },
  {
    Station_ID: 360,
    Complex_ID: 360,
    stopId: '601',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Pelham Bay Park',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.852462,
    lon: -73.828121,
    North_Direction_Label: '',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
    lineIcons: ['6'],
  },
  {
    Station_ID: 361,
    Complex_ID: 361,
    stopId: '602',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Buhre Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.84681,
    lon: -73.832569,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 362,
    Complex_ID: 362,
    stopId: '603',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Middletown Rd',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.843863,
    lon: -73.836322,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 363,
    Complex_ID: 363,
    stopId: '604',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Westchester Sq - E Tremont Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.839892,
    lon: -73.842952,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 364,
    Complex_ID: 364,
    stopId: '606',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Zerega Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.836488,
    lon: -73.847036,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 365,
    Complex_ID: 365,
    stopId: '607',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Castle Hill Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.834255,
    lon: -73.851222,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 366,
    Complex_ID: 366,
    stopId: '608',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Parkchester',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.833226,
    lon: -73.860816,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 367,
    Complex_ID: 367,
    stopId: '609',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'St Lawrence Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.831509,
    lon: -73.867618,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 368,
    Complex_ID: 368,
    stopId: '610',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Morrison Av- Sound View',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.829521,
    lon: -73.874516,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 369,
    Complex_ID: 369,
    stopId: '611',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Elder Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.828584,
    lon: -73.879159,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 370,
    Complex_ID: 370,
    stopId: '612',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Whitlock Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Elevated',
    lat: 40.826525,
    lon: -73.886283,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 371,
    Complex_ID: 371,
    stopId: '613',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Hunts Point Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.820948,
    lon: -73.890549,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 372,
    Complex_ID: 372,
    stopId: '614',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Longwood Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.816104,
    lon: -73.896435,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 373,
    Complex_ID: 373,
    stopId: '615',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'E 149 St',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.812118,
    lon: -73.904098,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 374,
    Complex_ID: 374,
    stopId: '616',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: "E 143 St - St Mary's St",
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.808719,
    lon: -73.907657,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 375,
    Complex_ID: 375,
    stopId: '617',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Cypress Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.805368,
    lon: -73.914042,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 376,
    Complex_ID: 376,
    stopId: '618',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: 'Brook Av',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.807566,
    lon: -73.91924,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 377,
    Complex_ID: 377,
    stopId: '619',
    Division: 'IRT',
    Line: 'Pelham',
    // stopName: '3 Av - 138 St',
    Borough: 'Bx',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.810476,
    lon: -73.926138,
    North_Direction_Label: 'Pelham Bay Park',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['6'],
  },
  {
    Station_ID: 378,
    Complex_ID: 378,
    stopId: '401',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: 'Woodlawn',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.886037,
    lon: -73.878751,
    North_Direction_Label: '',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
    lineIcons: ['4'],
  },
  {
    Station_ID: 379,
    Complex_ID: 379,
    stopId: '402',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: 'Mosholu Pkwy',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.87975,
    lon: -73.884655,
    North_Direction_Label: 'Woodlawn',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
  },
  {
    Station_ID: 380,
    Complex_ID: 380,
    stopId: '405',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: 'Bedford Park Blvd - Lehman College',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.873412,
    lon: -73.890064,
    North_Direction_Label: 'Woodlawn',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
  },
  {
    Station_ID: 381,
    Complex_ID: 381,
    stopId: '406',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: 'Kingsbridge Rd',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.86776,
    lon: -73.897174,
    North_Direction_Label: 'Woodlawn',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
  },
  {
    Station_ID: 382,
    Complex_ID: 382,
    stopId: '407',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: 'Fordham Rd',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.862803,
    lon: -73.901034,
    North_Direction_Label: 'Woodlawn',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
  },
  {
    Station_ID: 383,
    Complex_ID: 383,
    stopId: '408',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: '183 St',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.858407,
    lon: -73.903879,
    North_Direction_Label: 'Woodlawn',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
  },
  {
    Station_ID: 384,
    Complex_ID: 384,
    stopId: '409',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: 'Burnside Av',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.853453,
    lon: -73.907684,
    North_Direction_Label: 'Woodlawn',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
  },
  {
    Station_ID: 385,
    Complex_ID: 385,
    stopId: '410',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: '176 St',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.84848,
    lon: -73.911794,
    North_Direction_Label: 'Woodlawn',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
  },
  {
    Station_ID: 386,
    Complex_ID: 386,
    stopId: '411',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: 'Mt Eden Av',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.844434,
    lon: -73.914685,
    North_Direction_Label: 'Woodlawn',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
  },
  {
    Station_ID: 387,
    Complex_ID: 387,
    stopId: '412',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: '170 St',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.840075,
    lon: -73.917791,
    North_Direction_Label: 'Woodlawn',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
  },
  {
    Station_ID: 388,
    Complex_ID: 388,
    stopId: '413',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: '167 St',
    Borough: 'Bx',
    Daytime_Routes: '4',
    Structure: 'Elevated',
    lat: 40.835537,
    lon: -73.9214,
    North_Direction_Label: 'Woodlawn',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4'],
  },
  // EDITED: commented out to remove duplicate label when unifying `161 St - Yankee Stadium`
  // {
  //   Station_ID: 389,
  //   Complex_ID: 604,
  //   stopId: '414',
  //   Division: 'IRT',
  //   Line: 'Jerome Av',
  //   // stopName: '161 St - Yankee Stadium',
  //   Borough: 'Bx',
  //   Daytime_Routes: '4',
  //   Structure: 'Elevated',
  //   lat: 40.827994,
  //   lon: -73.925831,
  //   North_Direction_Label: 'Woodlawn',
  //   South_Direction_Label: 'Manhattan',
  //   Daytime_Routes_Array: ['4'],
  // },
  // EDITED: commented out to remove duplicate label when unifying `149 St - Grand Concourse`
  // {
  //   Station_ID: 390,
  //   Complex_ID: 603,
  //   stopId: '415',
  //   Division: 'IRT',
  //   Line: 'Jerome Av',
  //   // stopName: '149 St - Grand Concourse',
  //   Borough: 'Bx',
  //   Daytime_Routes: '4',
  //   Structure: 'Subway',
  //   lat: 40.818375,
  //   lon: -73.927351,
  //   North_Direction_Label: 'Woodlawn',
  //   South_Direction_Label: 'Manhattan',
  //   Daytime_Routes_Array: ['4'],
  // },
  {
    Station_ID: 391,
    Complex_ID: 391,
    stopId: '416',
    Division: 'IRT',
    Line: 'Jerome Av',
    // stopName: '138 St - Grand Concourse',
    Borough: 'Bx',
    Daytime_Routes: '4 5',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.813374,
    lon: -73.930309,
    // real position
    // lat: 40.813224,
    // lon: -73.929849,
    North_Direction_Label: 'Woodlawn - Eastchester Dyre Av',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['4', '5'],
  },
  {
    Station_ID: 392,
    Complex_ID: 392,
    stopId: '621',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '125 St',
    Borough: 'M',
    Daytime_Routes: '4 5 6',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.804375,
    lon: -73.937439,
    // real position
    // lat: 40.804138,
    // lon: -73.937594,
    North_Direction_Label: 'The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 393,
    Complex_ID: 393,
    stopId: '622',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '116 St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.798629,
    lon: -73.941617,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 394,
    Complex_ID: 394,
    stopId: '623',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '110 St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.79502,
    lon: -73.94425,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 395,
    Complex_ID: 395,
    stopId: '624',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '103 St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.7906,
    lon: -73.947478,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 396,
    Complex_ID: 396,
    stopId: '625',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '96 St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.785672,
    lon: -73.95107,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 397,
    Complex_ID: 397,
    stopId: '626',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '86 St',
    Borough: 'M',
    Daytime_Routes: '4 5 6',
    Structure: 'Subway',
    lat: 40.779492,
    lon: -73.955589,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 398,
    Complex_ID: 398,
    stopId: '627',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '77 St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.77362,
    lon: -73.959874,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 399,
    Complex_ID: 399,
    stopId: '628',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '68 St - Hunter College',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.768141,
    lon: -73.96387,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 400,
    Complex_ID: 613,
    stopId: '629',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '59 St',
    Borough: 'M',
    Daytime_Routes: '4 5 6',
    Structure: 'Subway',
    lat: 40.76227, // Edited for station alignment
    lon: -73.96825, // Edited for station alignment
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 401,
    Complex_ID: 612,
    stopId: '630',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '51 St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.757107,
    lon: -73.97192,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 402,
    Complex_ID: 610,
    stopId: '631',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: 'Grand Central - 42 St',
    Borough: 'M',
    Daytime_Routes: '4 5 6',
    Structure: 'Subway',
    // Updated to be aligned with road
    lat: 40.752051,
    lon: -73.9779,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    // UPDATED
    Daytime_Routes_Array: ['7', 'GS', '4', '5', '6'],
    perpendicularStack: ['GS', '7'],
    lineIcons: ['GS'],
    angle: 0,
  },
  {
    Station_ID: 403,
    Complex_ID: 403,
    stopId: '632',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '33 St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.746081,
    lon: -73.982076,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
  },
  {
    Station_ID: 404,
    Complex_ID: 404,
    stopId: '633',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '28 St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.74307,
    lon: -73.984264,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
  },
  {
    Station_ID: 405,
    Complex_ID: 405,
    stopId: '634',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: '23 St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.739864,
    lon: -73.986599,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  // EDITED: commented out to remove duplicate label when unifying `Union Sq - 14 St`
  // {
  //   Station_ID: 406,
  //   Complex_ID: 602,
  //   stopId: '635',
  //   Division: 'IRT',
  //   Line: 'Lexington Av',
  //   // stopName: '14 St - Union Sq',
  //   Borough: 'M',
  //   Daytime_Routes: '4 5 6',
  //   Structure: 'Subway',
  //   lat: 40.734673,
  //   lon: -73.989951,
  //   North_Direction_Label: 'Uptown & The Bronx',
  //   South_Direction_Label: 'Downtown & Brooklyn',
  //   Daytime_Routes_Array: ['4', '5', '6'],
  // },
  {
    Station_ID: 407,
    Complex_ID: 407,
    stopId: '636',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: 'Astor Pl',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.7301, // Edited for station alignment
    lon: -73.99125, // Edited for station alignment
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  {
    Station_ID: 408,
    Complex_ID: 619,
    stopId: '637',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: 'Bleecker St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.7259, // Edited for station alignment
    lon: -73.99454, // Edited for station alignment
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
  },
  {
    Station_ID: 409,
    Complex_ID: 409,
    stopId: '638',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: 'Spring St',
    Borough: 'M',
    Daytime_Routes: '6',
    Structure: 'Subway',
    lat: 40.72235, // Edited for station alignment
    lon: -73.997355, // Edited for station alignment
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown',
    Daytime_Routes_Array: ['4', '5', '6'],
    angle: 0,
  },
  // EDITED: commented out to remove duplicate label when uniting `Canal St`
  // {
  //   Station_ID: 410,
  //   Complex_ID: 623,
  //   stopId: '639',
  //   Division: 'IRT',
  //   Line: 'Lexington Av',
  //   // stopName: 'Canal St',
  //   Borough: 'M',
  //   Daytime_Routes: '6',
  //   Structure: 'Subway',
  //   lat: 40.718803,
  //   lon: -74.000193,
  //   North_Direction_Label: 'Uptown & The Bronx',
  //   South_Direction_Label: 'Downtown',
  //   Daytime_Routes_Array: ['6'],
  // },
  {
    Station_ID: 411,
    Complex_ID: 622,
    stopId: '640',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: 'Brooklyn Bridge - City Hall',
    Borough: 'M',
    Daytime_Routes: '4 5 6',
    Structure: 'Subway',
    lat: 40.713065,
    lon: -74.00409,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['4', '5', '6'],
    lineIcons: ['6'],
    angle: 0,
  },
  {
    Station_ID: 412,
    Complex_ID: 628,
    stopId: '418',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: 'Fulton St',
    Borough: 'M',
    Daytime_Routes: '4 5',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.71063,
    lon: -74.009376,
    // real position
    // lat: 40.710368,
    // lon: -74.009509,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['4', '5'],
  },
  {
    Station_ID: 413,
    Complex_ID: 413,
    stopId: '419',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: 'Wall St',
    Borough: 'M',
    Daytime_Routes: '4 5',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.707556,
    lon: -74.011893,
    // real position
    // lat: 40.707557,
    // lon: -74.011862,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['4', '5'],
    // angle: -45,
    angle: 0,
  },
  {
    Station_ID: 414,
    Complex_ID: 414,
    stopId: '420',
    Division: 'IRT',
    Line: 'Lexington Av',
    // stopName: 'Bowling Green',
    Borough: 'M',
    Daytime_Routes: '4 5',
    Structure: 'Subway',
    lat: 40.70485,
    lon: -74.0139,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Brooklyn',
    Daytime_Routes_Array: ['4', '5'],
    // angle: 45,
    angle: 0,
  },
  // EDITED: commented out to remove duplicate label when unifying `Borough Hall`
  // {
  //   Station_ID: 415,
  //   Complex_ID: 620,
  //   stopId: '423',
  //   Division: 'IRT',
  //   Line: 'Eastern Pky',
  //   // stopName: 'Borough Hall',
  //   Borough: 'Bk',
  //   Daytime_Routes: '4 5',
  //   Structure: 'Subway',
  //   lat: 40.692404,
  //   lon: -73.990151,
  //   North_Direction_Label: 'Manhattan',
  //   South_Direction_Label: 'Flatbush - Utica',
  //   Daytime_Routes_Array: ['4', '5'],
  //   angle: 90,
  // },
  {
    Station_ID: 416,
    Complex_ID: 416,
    stopId: '201',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Wakefield - 241 St',
    Borough: 'Bx',
    Daytime_Routes: '2',
    Structure: 'Elevated',
    lat: 40.903125,
    lon: -73.85062,
    North_Direction_Label: '',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2'],
    lineIcons: ['2'],
  },
  {
    Station_ID: 417,
    Complex_ID: 417,
    stopId: '204',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Nereid Av',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.898379,
    lon: -73.854376,
    North_Direction_Label: 'Wakefield - 241 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
    lineIcons: ['5'],
  },
  {
    Station_ID: 418,
    Complex_ID: 418,
    stopId: '205',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: '233 St',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.893193,
    lon: -73.857473,
    North_Direction_Label: 'Wakefield - 241 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 419,
    Complex_ID: 419,
    stopId: '206',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: '225 St',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.888022,
    lon: -73.860341,
    North_Direction_Label: 'Wakefield - 241 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 420,
    Complex_ID: 420,
    stopId: '207',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: '219 St',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.883895,
    lon: -73.862633,
    North_Direction_Label: 'Wakefield - 241 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 421,
    Complex_ID: 421,
    stopId: '208',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Gun Hill Rd',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.87785,
    lon: -73.866256,
    North_Direction_Label: 'Wakefield - 241 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 422,
    Complex_ID: 422,
    stopId: '209',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Burke Av',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.871356,
    lon: -73.867164,
    North_Direction_Label: 'Wakefield - 241 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 423,
    Complex_ID: 423,
    stopId: '210',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Allerton Av',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.865462,
    lon: -73.867352,
    North_Direction_Label: 'Wakefield - 241 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 424,
    Complex_ID: 424,
    stopId: '211',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Pelham Pkwy',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.857192,
    lon: -73.867615,
    North_Direction_Label: 'Wakefield - 241 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 425,
    Complex_ID: 425,
    stopId: '212',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Bronx Park East',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.848828,
    lon: -73.868457,
    North_Direction_Label: 'Wakefield - 241 St',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 426,
    Complex_ID: 426,
    stopId: '213',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'E 180 St',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.841894,
    lon: -73.873488,
    North_Direction_Label: 'Wakefield - Eastchester',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 427,
    Complex_ID: 427,
    stopId: '214',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'West Farms Sq - E Tremont Av',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.840295,
    lon: -73.880049,
    North_Direction_Label: 'Wakefield - Eastchester',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 428,
    Complex_ID: 428,
    stopId: '215',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: '174 St',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.837288,
    lon: -73.887734,
    North_Direction_Label: 'Wakefield - Eastchester',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
    angle: -33,
  },
  {
    Station_ID: 429,
    Complex_ID: 429,
    stopId: '216',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Freeman St',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.829993,
    lon: -73.891865,
    North_Direction_Label: 'Wakefield - Eastchester',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
    angle: 20,
  },
  {
    Station_ID: 430,
    Complex_ID: 430,
    stopId: '217',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Simpson St',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.824073,
    lon: -73.893064,
    North_Direction_Label: 'Wakefield - Eastchester',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 431,
    Complex_ID: 431,
    stopId: '218',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Intervale Av',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.822181,
    lon: -73.896736,
    North_Direction_Label: 'Wakefield - Eastchester',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 432,
    Complex_ID: 432,
    stopId: '219',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Prospect Av',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.819585,
    lon: -73.90177,
    North_Direction_Label: 'Wakefield - Eastchester',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 433,
    Complex_ID: 433,
    stopId: '220',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Jackson Av',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Elevated',
    lat: 40.81649,
    lon: -73.907807,
    North_Direction_Label: 'Wakefield - Eastchester',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['2', '5'],
  },
  {
    Station_ID: 434,
    Complex_ID: 434,
    stopId: '221',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: '3 Av - 149 St',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Subway',
    lat: 40.816109,
    lon: -73.917757,
    North_Direction_Label: 'Wakefield - Eastchester',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['5', '2'], // Edited for station alignment
    angle: 90,
  },
  {
    Station_ID: 435,
    Complex_ID: 603,
    stopId: '222',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: '149 St - Grand Concourse',
    Borough: 'Bx',
    Daytime_Routes: '2 5',
    Structure: 'Subway',
    lat: 40.81841,
    lon: -73.926718,
    North_Direction_Label: 'Wakefield - Eastchester',
    South_Direction_Label: 'Manhattan',
    // EDITED
    Daytime_Routes_Array: ['2', '4', '5'],
    angle: 0,
  },
  {
    Station_ID: 436,
    Complex_ID: 436,
    stopId: '301',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Harlem - 148 St',
    Borough: 'M',
    Daytime_Routes: '3',
    Structure: 'Subway',
    lat: 40.82388,
    lon: -73.93647,
    North_Direction_Label: '',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['3'],
    lineIcons: ['3'],
    angle: -90,
  },
  {
    Station_ID: 437,
    Complex_ID: 437,
    stopId: '302',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: '145 St',
    Borough: 'M',
    Daytime_Routes: '3',
    Structure: 'Subway',
    lat: 40.820421,
    lon: -73.936245,
    North_Direction_Label: '148 St',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['2', '3'],
  },
  {
    Station_ID: 438,
    Complex_ID: 438,
    stopId: '224',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: '135 St',
    Borough: 'M',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.814229,
    lon: -73.94077,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['2', '3'],
  },
  {
    Station_ID: 439,
    Complex_ID: 439,
    stopId: '225',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: '125 St',
    Borough: 'M',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.807754,
    lon: -73.945495,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['2', '3'],
  },
  {
    Station_ID: 440,
    Complex_ID: 440,
    stopId: '226',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: '116 St',
    Borough: 'M',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.802098,
    lon: -73.949625,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['2', '3'],
  },
  {
    Station_ID: 441,
    Complex_ID: 441,
    stopId: '227',
    Division: 'IRT',
    Line: 'Lenox - White Plains Rd',
    // stopName: 'Central Park North 110 St',
    Borough: 'M',
    Daytime_Routes: '2 3',
    Structure: 'Subway',
    lat: 40.799075,
    lon: -73.951822,
    North_Direction_Label: 'Uptown & The Bronx',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['2', '3'],
  },
  {
    Station_ID: 442,
    Complex_ID: 442,
    stopId: '501',
    Division: 'IRT',
    Line: 'Dyre Av',
    // stopName: 'Eastchester - Dyre Av',
    Borough: 'Bx',
    Daytime_Routes: '5',
    Structure: 'At Grade',
    lat: 40.8883,
    lon: -73.830834,
    North_Direction_Label: '',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['5'],
    lineIcons: ['5'],
  },
  {
    Station_ID: 443,
    Complex_ID: 443,
    stopId: '502',
    Division: 'IRT',
    Line: 'Dyre Av',
    // stopName: 'Baychester Av',
    Borough: 'Bx',
    Daytime_Routes: '5',
    Structure: 'Open Cut',
    lat: 40.878663,
    lon: -73.838591,
    North_Direction_Label: 'Eastchester - Dyre Av',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['5'],
  },
  {
    Station_ID: 444,
    Complex_ID: 444,
    stopId: '503',
    Division: 'IRT',
    Line: 'Dyre Av',
    // stopName: 'Gun Hill Rd',
    Borough: 'Bx',
    Daytime_Routes: '5',
    Structure: 'Open Cut',
    lat: 40.869526,
    lon: -73.846384,
    North_Direction_Label: 'Eastchester - Dyre Av',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['5'],
  },
  {
    Station_ID: 445,
    Complex_ID: 445,
    stopId: '504',
    Division: 'IRT',
    Line: 'Dyre Av',
    // stopName: 'Pelham Pkwy',
    Borough: 'Bx',
    Daytime_Routes: '5',
    Structure: 'Open Cut',
    lat: 40.858985,
    lon: -73.855359,
    North_Direction_Label: 'Eastchester - Dyre Av',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['5'],
  },
  {
    Station_ID: 446,
    Complex_ID: 446,
    stopId: '505',
    Division: 'IRT',
    Line: 'Dyre Av',
    // stopName: 'Morris Park',
    Borough: 'Bx',
    Daytime_Routes: '5',
    Structure: 'Open Cut',
    lat: 40.854364,
    lon: -73.860495,
    North_Direction_Label: 'Eastchester - Dyre Av',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['5'],
  },
  {
    Station_ID: 447,
    Complex_ID: 447,
    stopId: '701',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: 'Flushing - Main St',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Subway',
    lat: 40.7596,
    lon: -73.83003,
    North_Direction_Label: '',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
    lineIcons: ['7'],
  },
  {
    Station_ID: 448,
    Complex_ID: 448,
    stopId: '702',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: 'Mets - Willets Point',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.754622,
    lon: -73.845625,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 449,
    Complex_ID: 449,
    stopId: '705',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '111 St',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.75173,
    lon: -73.855334,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 450,
    Complex_ID: 450,
    stopId: '706',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '103 St - Corona Plaza',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.749865,
    lon: -73.8627,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 451,
    Complex_ID: 451,
    stopId: '707',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: 'Junction Blvd',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.749145,
    lon: -73.869527,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 452,
    Complex_ID: 452,
    stopId: '708',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '90 St - Elmhurst Av',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.748408,
    lon: -73.876613,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 453,
    Complex_ID: 453,
    stopId: '709',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '82 St - Jackson Hts',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.747659,
    lon: -73.883697,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 454,
    Complex_ID: 616,
    stopId: '710',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '74 St - Broadway',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    // real position
    // lat: 40.746848,
    // lon: -73.891394,
    // fake position to improve the transfer link visualization
    lat: 40.747024,
    lon: -73.891318,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 455,
    Complex_ID: 455,
    stopId: '711',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '69 St',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.746325,
    lon: -73.896403,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 456,
    Complex_ID: 456,
    stopId: '712',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: 'Woodside - 61 St',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.74563,
    lon: -73.902984,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 457,
    Complex_ID: 457,
    stopId: '713',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '52 St',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.744149,
    lon: -73.912549,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 458,
    Complex_ID: 458,
    stopId: '714',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '46 St',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.743132,
    lon: -73.918435,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 459,
    Complex_ID: 459,
    stopId: '715',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '40 St',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.743781,
    lon: -73.924016,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 460,
    Complex_ID: 460,
    stopId: '716',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '33 St',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.744587,
    lon: -73.930997,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  // EDITED: commented out to remove duplicate label when unifying `Queensboro Plaza`
  // {
  //   Station_ID: 461,
  //   Complex_ID: 461,
  //   stopId: '718',
  //   Division: 'IRT',
  //   Line: 'Flushing',
  //   // stopName: 'Queensboro Plaza',
  //   Borough: 'Q',
  //   Daytime_Routes: '7',
  //   Structure: 'Elevated',
  //   lat: 40.750582,
  //   lon: -73.940202,
  //   North_Direction_Label: 'Astoria - Flushing',
  //   South_Direction_Label: 'Manhattan',
  //   Daytime_Routes_Array: ['7'],
  // },
  {
    Station_ID: 461,
    Complex_ID: 461,
    stopId: 'R09',
    Division: 'BMT',
    Line: 'Astoria',
    // stopName: 'Queensboro Plaza',
    Borough: 'Q',
    Daytime_Routes: 'N W',
    Structure: 'Elevated',
    // fake position to improve the line visual
    lat: 40.750648,
    lon: -73.940143,
    // real position
    // lat: 40.750582,
    // lon: -73.940202,
    North_Direction_Label: 'Astoria - Flushing',
    South_Direction_Label: 'Manhattan',
    // EDITED
    Daytime_Routes_Array: ['R', 'W', 'N', '7'],
    angle: -90,
  },
  {
    Station_ID: 462,
    Complex_ID: 606,
    stopId: '719',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: 'Court Sq',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Elevated',
    lat: 40.747023,
    lon: -73.945264,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['G', '7'],
    lineIcons: ['G'],
    angle: 0,
  },
  {
    Station_ID: 463,
    Complex_ID: 463,
    stopId: '720',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: 'Hunters Point Av',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.742309,
    lon: -73.948614,
    // real position
    // lat: 40.742216,
    // lon: -73.948916,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
  },
  {
    Station_ID: 464,
    Complex_ID: 464,
    stopId: '721',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: 'Vernon Blvd - Jackson Av',
    Borough: 'Q',
    Daytime_Routes: '7',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.74215,
    lon: -73.953875,
    // real position
    // lat: 40.742626,
    // lon: -73.953581,
    North_Direction_Label: 'Flushing',
    South_Direction_Label: 'Manhattan',
    Daytime_Routes_Array: ['7'],
    angle: 90,
  },
  // EDITED: commented out to remove duplicate label when unifying `Grand Central - 42 St`
  //
  // {
  //   Station_ID: 465,
  //   Complex_ID: 610,
  //   stopId: '723',
  //   Division: 'IRT',
  //   Line: 'Flushing',
  //   // stopName: 'Grand Central - 42 St',
  //   Borough: 'M',
  //   Daytime_Routes: '7',
  //   Structure: 'Subway',
  //   lat: 40.751431,
  //   lon: -73.976041,
  //   North_Direction_Label: 'Queens',
  //   South_Direction_Label: '34 St - Hudson Yards',
  //   Daytime_Routes_Array: ['7'],
  // },
  {
    Station_ID: 466,
    Complex_ID: 609,
    stopId: '724',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '5 Av',
    Borough: 'M',
    Daytime_Routes: '7',
    Structure: 'Subway',
    // fake position to improve the line visual
    lat: 40.75402,
    lon: -73.98222,
    // real position
    // lat: 40.753821,
    // lon: -73.981963,
    North_Direction_Label: 'Queens',
    South_Direction_Label: '34 St - Hudson Yards',
    Daytime_Routes_Array: ['7'],
    angle: 90,
  },
  // EDITED: commented out to remove duplicate label when unifying `Times Sq`
  // {
  //   Station_ID: 467,
  //   Complex_ID: 611,
  //   stopId: '725',
  //   Division: 'IRT',
  //   Line: 'Flushing',
  //   // stopName: 'Times Sq-42 St',
  //   Borough: 'M',
  //   Daytime_Routes: '7',
  //   Structure: 'Subway',
  //   // lat: 40.755477,
  //   // lon: -73.987691,
  //   // EDITED - copied from stop 127
  //   lat: 40.75529,
  //   lon: -73.987495,
  //   North_Direction_Label: 'Queens',
  //   South_Direction_Label: '34 St - Hudson Yards',
  //   // EDITED
  //   Daytime_Routes_Array: ['1', '2', '3', 'R', 'W', 'N', 'Q', '7', 'S'],
  // },
  // EDITED: commented out to remove duplicate label when unifying `Times Sq`
  // {
  //   Station_ID: 468,
  //   Complex_ID: 611,
  //   stopId: '902',
  //   Division: 'IRT',
  //   Line: 'Lexington - Shuttle',
  //   // stopName: 'Times Sq-42 St',
  //   Borough: 'M',
  //   Daytime_Routes: 'S',
  //   Structure: 'Subway',
  //   // lat: 40.755983,
  //   // lon: -73.986229,
  //   // EDITED - copied from stop 127
  //   lat: 40.75529,
  //   lon: -73.987495,
  //   North_Direction_Label: '',
  //   South_Direction_Label: 'Grand Central',
  //   // EDITED
  //   Daytime_Routes_Array: ['1', '2', '3', 'R', 'W', 'N', 'Q', '7', 'S'],
  // },
  // EDITED: commented out to remove duplicate label when unifying `Grand Central - 42 St`
  // {
  //   Station_ID: 469,
  //   Complex_ID: 610,
  //   stopId: '901',
  //   Division: 'IRT',
  //   Line: 'Lexington - Shuttle',
  //   // stopName: 'Grand Central - 42 St',
  //   Borough: 'M',
  //   Daytime_Routes: 'S',
  //   Structure: 'Subway',
  //   lat: 40.752769,
  //   lon: -73.979189,
  //   North_Direction_Label: 'Times Sq',
  //   South_Direction_Label: '',
  //   Daytime_Routes_Array: ['S'],
  // },
  {
    Station_ID: 471,
    Complex_ID: 471,
    stopId: '726',
    Division: 'IRT',
    Line: 'Flushing',
    // stopName: '34 St-Hudson Yards',
    Borough: 'M',
    Daytime_Routes: '7',
    Structure: 'Subway',
    lat: 40.755882,
    lon: -74.00191,
    North_Direction_Label: 'Queens',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['7'],
    lineIcons: ['7'],
  },
  {
    Station_ID: 475,
    Complex_ID: 475,
    stopId: 'Q05',
    Division: 'IND',
    Line: 'Second Av',
    // stopName: '96 St',
    Borough: 'M',
    Daytime_Routes: 'Q',
    Structure: 'Subway',
    lat: 40.784318,
    lon: -73.947152,
    North_Direction_Label: '',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['Q'],
    lineIcons: ['Q'],
  },
  {
    Station_ID: 476,
    Complex_ID: 476,
    stopId: 'Q04',
    Division: 'IND',
    Line: 'Second Av',
    // stopName: '86 St',
    Borough: 'M',
    Daytime_Routes: 'Q',
    Structure: 'Subway',
    lat: 40.777891,
    lon: -73.951787,
    North_Direction_Label: 'Uptown',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['Q'],
  },
  {
    Station_ID: 477,
    Complex_ID: 477,
    stopId: 'Q03',
    Division: 'IND',
    Line: 'Second Av',
    // stopName: '72 St',
    Borough: 'M',
    Daytime_Routes: 'Q',
    Structure: 'Subway',
    lat: 40.768799,
    lon: -73.958424,
    North_Direction_Label: 'Uptown',
    South_Direction_Label: 'Downtown & Brooklyn',
    Daytime_Routes_Array: ['Q'],
  },
  {
    Station_ID: 501,
    Complex_ID: 501,
    stopId: 'S31',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'St George',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Open Cut',
    lat: 40.643748,
    lon: -74.073643,
    North_Direction_Label: '',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
    lineIcons: ['SI'],
  },
  {
    Station_ID: 502,
    Complex_ID: 502,
    stopId: 'S30',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Tompkinsville',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'At Grade',
    lat: 40.636949,
    lon: -74.074835,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 503,
    Complex_ID: 503,
    stopId: 'S29',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Stapleton',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Elevated',
    lat: 40.627915,
    lon: -74.075162,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 504,
    Complex_ID: 504,
    stopId: 'S28',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Clifton',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Elevated',
    lat: 40.621319,
    lon: -74.071402,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 505,
    Complex_ID: 505,
    stopId: 'S27',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Grasmere',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Open Cut',
    lat: 40.603117,
    lon: -74.084087,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 506,
    Complex_ID: 506,
    stopId: 'S26',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Old Town',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Embankment',
    lat: 40.596612,
    lon: -74.087368,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 507,
    Complex_ID: 507,
    stopId: 'S25',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Dongan Hills',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Embankment',
    lat: 40.588849,
    lon: -74.09609,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 508,
    Complex_ID: 508,
    stopId: 'S24',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Jefferson Av',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Embankment',
    lat: 40.583591,
    lon: -74.103338,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 509,
    Complex_ID: 509,
    stopId: 'S23',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Grant City',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Open Cut',
    lat: 40.578965,
    lon: -74.109704,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 510,
    Complex_ID: 510,
    stopId: 'S22',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'New Dorp',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Open Cut',
    lat: 40.57348,
    lon: -74.11721,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 511,
    Complex_ID: 511,
    stopId: 'S21',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Oakwood Heights',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Open Cut',
    lat: 40.56511,
    lon: -74.12632,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 512,
    Complex_ID: 512,
    stopId: 'S20',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Bay Terrace',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Embankment',
    lat: 40.5564,
    lon: -74.136907,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 513,
    Complex_ID: 513,
    stopId: 'S19',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Great Kills',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Open Cut',
    lat: 40.551231,
    lon: -74.151399,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 514,
    Complex_ID: 514,
    stopId: 'S18',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Eltingville',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Embankment',
    lat: 40.544601,
    lon: -74.16457,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 515,
    Complex_ID: 515,
    stopId: 'S17',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Annadale',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Open Cut',
    lat: 40.54046,
    lon: -74.178217,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 516,
    Complex_ID: 516,
    stopId: 'S16',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Huguenot',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Open Cut',
    lat: 40.533674,
    lon: -74.191794,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 517,
    Complex_ID: 517,
    stopId: 'S15',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: "Prince's Bay",
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Open Cut',
    lat: 40.525507,
    lon: -74.200064,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 518,
    Complex_ID: 518,
    stopId: 'S14',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Pleasant Plains',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Embankment',
    lat: 40.52241,
    lon: -74.217847,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 519,
    Complex_ID: 519,
    stopId: 'S13',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Richmond Valley',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'Open Cut',
    lat: 40.519631,
    lon: -74.229141,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
  {
    Station_ID: 522,
    Complex_ID: 522,
    stopId: 'S09',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Tottenville',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'At Grade',
    lat: 40.512764,
    lon: -74.251961,
    North_Direction_Label: 'St George',
    South_Direction_Label: '',
    Daytime_Routes_Array: ['SI'],
    lineIcons: ['SI'],
  },
  {
    Station_ID: 523,
    Complex_ID: 523,
    stopId: 'S11',
    Division: 'SIR',
    Line: 'Staten Island',
    // stopName: 'Arthur Kill',
    Borough: 'SI',
    Daytime_Routes: 'SIR',
    Structure: 'At Grade',
    lat: 40.516578,
    lon: -74.242096,
    North_Direction_Label: 'St George',
    South_Direction_Label: 'Tottenville',
    Daytime_Routes_Array: ['SI'],
  },
];

export default stationsUnified;
