import {
  OtpRouteId,
  Station,
  Stop,
  SubwayRouteId,
} from '../subway-data/subway-types';
import { MANHATTAN_DEGREES, subwayRouteIdFromOtpRouteId } from '../subway-data';
import {
  add,
  fromPolar,
  parallelSpacingAtIndex,
  rotateDegrees,
} from '../geometry/point-utils';
import { projectStation } from './subway-openlayers-graphics';
import { Point } from '../geometry/geometry-types';
import { toRadians } from 'ol/math';
import { STATION_SPACING } from './maps-constants';

export const getLineIndicesAtStation = ({
  routeId,
  station,
}: {
  routeId: OtpRouteId;
  station: Station;
}): { index: number; maxIndex: number } => {
  const { transfersAndPassthroughs = [] } = station;
  return {
    index: transfersAndPassthroughs.indexOf(
      subwayRouteIdFromOtpRouteId(routeId)
    ),
    maxIndex: transfersAndPassthroughs.length - 1,
  };
};

export const getParallelSpacingForStop = (
  stop: Stop,
  station: Station,
  spacing = 20
) => {
  const { routeId } = stop;
  const { index, maxIndex } = getLineIndicesAtStation({ routeId, station });
  return parallelSpacingAtIndex(spacing, index, maxIndex);
};

/**
 * Calculates the absolute position of a station point,
 * taking into account its main center lon/lat
 * and its index within the cluster of dots for routes at that station.
 * @param stop
 * @param mode
 * @param spacing The distance between dots.
 */
export const getStopPoint = ({
  stop,
  station,
  spacing = STATION_SPACING,
  stationAngles,
}: {
  stop: Stop;
  station: Station;
  spacing?: number;
  stationAngles: { [stopId: string]: number };
}): Point => {
  // TODO: save projected coordinates as static data
  const projected = projectStation(station);
  const angleDegrees = stationAngles[stop.stopId];
  const parallelSpacing = getParallelSpacingForStop(stop, station, spacing);
  let point = fromPolar(
    parallelSpacing,
    toRadians(angleDegrees - MANHATTAN_DEGREES),
    projected
  );

  if (station.perpendicularStack) {
    const lastInStack =
      station.perpendicularStack[station.perpendicularStack.length - 1];
    const side: 'left' | 'right' =
      station.transfersAndPassthroughs.indexOf(lastInStack) === 0
        ? 'left'
        : 'right';
    if (side === 'right') {
      const stackedIndex = station.perpendicularStack.indexOf(
        stop.routeId as SubwayRouteId
      );
      if (stackedIndex > 0) {
        const offsetDisplacement = spacing * stackedIndex;
        const stackedOffset = rotateDegrees(
          [-offsetDisplacement, offsetDisplacement],
          -MANHATTAN_DEGREES
        );
        point = add(point, stackedOffset);
      }
    } else if (side === 'left') {
      const stackedIndex = station.perpendicularStack.indexOf(
        stop.routeId as SubwayRouteId
      );
      if (stackedIndex > 0) {
        const offsetDisplacement = spacing * stackedIndex;
        const stackedOffset = rotateDegrees(
          [offsetDisplacement, offsetDisplacement],
          -MANHATTAN_DEGREES
        );
        point = add(point, stackedOffset);
      }
    }
  }
  return point;
};
