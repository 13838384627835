export type NotRunningServiceMessage = {
  title: string;
  subtitle: string;
};

export const lineNotRunningServiceMessage: NotRunningServiceMessage = {
  title: `This train isn't running right now`,
  subtitle: `Please check back later for updates.`,
};

export const notRunningAtStationServiceMessage: NotRunningServiceMessage = {
  title: 'No trains at this station in the next hour',
  subtitle: 'Please choose another station or check back later for updates.',
};

export const noScheduledWorkServiceMessage: NotRunningServiceMessage = {
  title: 'Good service',
  subtitle: "You're ready to ride. Stand clear of the closing doors, please.",
};

export const noServiceaAtStationMessage: NotRunningServiceMessage = {
  title: 'No service at this station',
  subtitle: 'Trains that usually stop here are running on other train lines.',
};

export const selectedTimeDifferentFromCurrentMessage = (
  selectedTime: string
) => `
  ${selectedTime}'s planned service
`;

export const mapTitle = 'Live Subway Map';
