import { createSelector } from 'reselect';
import { getRoutesUnavailableForStop } from '../../maps/maps-utils';
import { RoutesUnavailable } from '../../subway-data';

import { Station, StopListsByRoute } from '../../subway-data/subway-types';

import { getMapSelectedStation } from './basic';
import { getMapStopListsByRouteRaw } from './getMapStopListsByRoute';

export const getMapRoutesUnavailableForSelectedStation = createSelector(
  getMapStopListsByRouteRaw,
  getMapSelectedStation,
  (
    mapStopListsByRoute: Partial<StopListsByRoute>,
    selectedStation: Station | null
  ): RoutesUnavailable => {
    if (selectedStation) {
      const { stopId, transfers } = selectedStation;

      return getRoutesUnavailableForStop(
        stopId,
        transfers,
        mapStopListsByRoute
      );
    }

    return {};
  }
);
