import UrlState from '../utils/url.utils';
import { AppState } from '.';
import {
  getDaysCountLastSeenPrompt,
  setLastSeenPrompt,
} from '../utils/localStorage.utils';
import { content } from '../containers/ErrorModal';
import { TrayPosition } from '../components/Tray';
import {
  setSelectedStationPinLayerSource,
  setVaccineLocationsFeatureSelectedStyle,
} from '../maps/layers';
import { isPhone } from '../utils/deviceDetector.utils';

export type UIErrorType = 'ie11' | 'geolocation' | 'pwa';

export interface UIState {
  airportTerminalViewOpened: boolean;
  routeMenuOpened: boolean;
  statusViewOpened: boolean;
  geolocationEnabled: boolean;
  isDebugMenuOpen: boolean;
  controlsTranslate: number;
  currentTrayPosition: TrayPosition;
  lastInteraction: number;
  lastUpdate: number;
  initialized: boolean;
  errorType: UIErrorType | null;
  isAwaitingServiceStatus: boolean;
  stationViewOpened: boolean;
  emergencyAlertOpened: boolean;
  isEmergencyAlertDismissed: boolean;
  shortcutsMenuOpen: boolean;
  vaccinationViewOpened: boolean;
}

export const initialUIState: Readonly<UIState> = {
  airportTerminalViewOpened: false,
  routeMenuOpened: !isPhone() && !UrlState.get().selectedRouteId,
  statusViewOpened: false,
  errorType: null,
  geolocationEnabled: false,
  controlsTranslate: 0,
  currentTrayPosition: TrayPosition.hidden,
  isDebugMenuOpen: false,
  lastInteraction: new Date().getTime(),
  lastUpdate: 0,
  initialized: false,
  isAwaitingServiceStatus: false,
  stationViewOpened: false,
  emergencyAlertOpened: false,
  isEmergencyAlertDismissed: false,
  shortcutsMenuOpen: false,
  vaccinationViewOpened: false,
};

const ui = {
  state: initialUIState,

  reducers: {
    setCloseAllOverlays(state: UIState) {
      state.airportTerminalViewOpened = false;
      state.currentTrayPosition = TrayPosition.hidden;
      state.emergencyAlertOpened = false;
      state.routeMenuOpened = false;
      state.stationViewOpened = false;
      state.statusViewOpened = false;
      state.vaccinationViewOpened = false;
    },

    setInitialized(state: UIState, initialized: boolean) {
      state.initialized = initialized;
    },

    setAirportTerminalViewOpened(state: UIState, newStatus: boolean) {
      if (state.airportTerminalViewOpened !== newStatus) {
        state.airportTerminalViewOpened = newStatus;
      }
    },

    setRouteMenuOpened(state: UIState, isOpen: boolean) {
      if ((isOpen && !state.stationViewOpened) || !isOpen) {
        state.routeMenuOpened = isOpen;
      }
    },

    setStatusViewOpened(state: UIState, isOpen: boolean) {
      if (
        (isOpen && !state.routeMenuOpened && !state.stationViewOpened) ||
        !isOpen
      ) {
        state.statusViewOpened = isOpen;
      }
    },

    setVaccinationViewOpened(state: UIState, newStatus: boolean) {
      if (state.vaccinationViewOpened !== newStatus) {
        state.vaccinationViewOpened = newStatus;
      }
    },

    setErrorType(state: UIState, errorType: UIState['errorType']) {
      state.errorType = errorType;
    },

    setGeolocationEnabled(state: UIState, geolocationEnabled: boolean) {
      state.geolocationEnabled = geolocationEnabled;
    },

    setIsDebugMenuOpen(state: UIState, isDebugMenuOpen: boolean) {
      state.isDebugMenuOpen = isDebugMenuOpen;
    },

    setCurrentTrayPosition(state: UIState, position: TrayPosition) {
      state.currentTrayPosition = position;
    },

    setLastInteraction(state: UIState, lastInteraction: number) {
      state.lastInteraction = lastInteraction;
    },

    setLastUpdate(state: UIState, lastUpdate: number = new Date().getTime()) {
      state.lastUpdate = lastUpdate;
    },

    setAwaitingServiceStatus(state: UIState, isAwaitingServiceStatus: boolean) {
      state.isAwaitingServiceStatus = isAwaitingServiceStatus;
    },

    setStationViewOpened(state: UIState, stationViewOpened: boolean) {
      state.stationViewOpened = stationViewOpened;
    },

    setEmergencyAlertOpened(state: UIState, emergencyAlertOpened: boolean) {
      state.emergencyAlertOpened = emergencyAlertOpened;
    },

    setEmergencyAlertDismissed(
      state: UIState,
      isEmergencyAlertDismissed: boolean
    ) {
      state.isEmergencyAlertDismissed = isEmergencyAlertDismissed;
    },

    setShortcutsMenuOpen(state: UIState, isOpen: boolean) {
      state.shortcutsMenuOpen = isOpen;
    },
  },

  effects: dispatch => ({
    requestOpenErrorModal(errorType: UIErrorType) {
      if (content[errorType].daysBetweenPrompts === -1) {
        dispatch.ui.setErrorType(errorType);
        return;
      }

      const daysCount = getDaysCountLastSeenPrompt(errorType);

      if (
        daysCount === -1 ||
        daysCount > content[errorType].daysBetweenPrompts
      ) {
        dispatch.ui.setErrorType(errorType);
      }
    },

    requestCloseErrorModal(payload, state: AppState) {
      setLastSeenPrompt(state.ui.errorType as UIErrorType);
      dispatch.ui.setErrorType(null);
    },

    setAirportTerminalViewOpened(isOpen: boolean, state: AppState) {
      dispatch.ui.toggleEmergencyAlertView();

      if (!isOpen) {
        setSelectedStationPinLayerSource();
        dispatch.map.setSelectedAirportTerminalId(null);
        !isPhone() && dispatch.ui.setRouteMenuOpened(true);
      } else {
        setVaccineLocationsFeatureSelectedStyle(
          state.map.ada,
          state.map.useDarkMap
        );
      }
    },

    setStationViewOpened(isOpen: boolean, state: AppState) {
      dispatch.ui.toggleEmergencyAlertView();

      if (!isOpen) {
        setSelectedStationPinLayerSource();
        dispatch.map.setSelectedStation(null);
        !isPhone() && dispatch.ui.setRouteMenuOpened(true);
      } else {
        setVaccineLocationsFeatureSelectedStyle(
          state.map.ada,
          state.map.useDarkMap
        );
      }
    },

    setStatusViewOpened() {
      dispatch.ui.toggleEmergencyAlertView();
    },

    setVaccinationViewOpened(isOpen: boolean, state: AppState) {
      dispatch.ui.toggleEmergencyAlertView();

      if (!isOpen) {
        setVaccineLocationsFeatureSelectedStyle(
          state.map.ada,
          state.map.useDarkMap
        );
        dispatch.map.setSelectedVaccineLocationId(null);
        !isPhone() && dispatch.ui.setRouteMenuOpened(true);
      } else {
        setSelectedStationPinLayerSource();
      }
    },

    setRouteMenuOpened(isOpen: boolean) {
      // This is a unique desktop scenario.
      // When the user closes the menu, we force the emergency alert to stay
      // open, even if the user dismissed the alert before.
      const forceEmergencyAlertToOpen = !isOpen && !isPhone();

      dispatch.ui.toggleEmergencyAlertView(forceEmergencyAlertToOpen);
    },

    toggleEmergencyAlertView(forceOpen: boolean, state: AppState) {
      if (state.map.emergencyAlert) {
        const {
          airportTerminalViewOpened,
          routeMenuOpened,
          stationViewOpened,
          statusViewOpened,
          vaccinationViewOpened,
        } = state.ui;
        const arePanelsClosed =
          !airportTerminalViewOpened &&
          !stationViewOpened &&
          !statusViewOpened &&
          !vaccinationViewOpened;
        const shouldReopenWithMenu =
          (!isPhone() && routeMenuOpened) ||
          (!routeMenuOpened && !state.ui.isEmergencyAlertDismissed);

        if (
          forceOpen ||
          (!state.map.selectedRouteId &&
            shouldReopenWithMenu &&
            arePanelsClosed)
        ) {
          dispatch.ui.setEmergencyAlertOpened(true);
        } else {
          dispatch.ui.setEmergencyAlertOpened(false);
        }
      }
    },
  }),
};

export default ui;
