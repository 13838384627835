import styled from 'styled-components/macro';
import { animated } from 'react-spring';

const TrayContainer = styled(animated.div)`
  position: fixed;
  top: 100%;
  right: 0;
  left: 0;
  z-index: ${p => p.theme.zIndex.serviceStatusMobile};
  width: 100vw;
  height: 100%;
`;

export default TrayContainer;
