import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { TimeFilter } from '../../subway-data';
import { timeLabels } from '../../utils/date.utils';
import { subTitleSmall } from '../Text';
import theme, { withFocusStyle, withTouchPressStyle } from '../../utils/theme';
import debounce from 'lodash/debounce';

export type TimeToggleDisplay = 'default' | 'large';
interface ItemDimensions {
  scale: number;
  translate: number;
}

const TimeToggleLabel = styled.p`
  ${subTitleSmall};
`;

// Toggle UI for desktop
const Swiper = styled.div<{ scale: number; translate: number }>`
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  &:before {
    background: ${p => p.theme.colors.black};
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 0;
    transform: ${p =>
      `scaleX(${p.scale}) translateX(${p.translate /
        p.scale}px) translateY(-1px)`};
    transform-origin: left;
    transition: transform 400ms ease;
    width: 100%;
    z-index: 1;
  }
`;

const SwiperItemTitle = styled(TimeToggleLabel)`
  color: ${p => p.theme.colors.black};
  opacity: 0.6;
  transition: opacity 200ms ease;
  transform-origin: left;
`;

const SwiperItem = styled.button<{ isActive: boolean }>`
  background: ${p => p.theme.colors.white};
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 33px;
  margin: 0;
  outline: 0;
  padding: 8px 0 0;

  /* Force theme as it's on
  a white background */
  ${withFocusStyle(theme, 0)}

  &:hover {
    ${SwiperItemTitle} {
      opacity: 1;
    }
  }

  ${p =>
    p.isActive &&
    css`
      ${SwiperItemTitle} {
        opacity: 1;
      }
    `};
`;

const switchButtonBorderRadius = '25px';

// Toggle UI for mobile
const SwitchButton = styled.button`
  align-items: center;
  background-color: ${p => p.theme.colors.button.bg};
  border-radius: ${switchButtonBorderRadius};
  box-shadow: ${p => p.theme.boxShadow};
  color: ${p => p.theme.colors.button.color};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  position: relative;
  width: 140px;

  ${p => withFocusStyle(p.theme)};
  ${p => withTouchPressStyle(p.theme.transition)};

  &::before {
    border-radius: ${switchButtonBorderRadius};
  }
`;

const LoadingBarWrapper = styled.div`
  border-radius: ${switchButtonBorderRadius};
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
`;

const loadingAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(-10%);
  }
`;

const LoadingBar = styled.div`
  animation: ${loadingAnimation} 3s linear;
  background-color: ${p => p.theme.colors.black};
  bottom: 0;
  height: 3px;
  opacity: 0.1;
  position: absolute;
  width: 100%;
`;

const getBreakpoint = () =>
  window.innerWidth >= theme.media.extraLargeMin ? 'extraLarge' : 'large';

interface TimeToggleProps {
  currentTime: TimeFilter;
  display: TimeToggleDisplay;
  isAwaitingServiceStatus: boolean;
  isOpen?: boolean;
  selectedTime: TimeFilter;
  onToggle: (toggledTime?: TimeFilter) => void;
}
const TimeToggle: React.FC<TimeToggleProps> = ({
  currentTime,
  display,
  isAwaitingServiceStatus,
  isOpen,
  onToggle,
  selectedTime,
  ...props
}) => {
  const labelsMap = timeLabels[currentTime]; // Labels for current time
  const labelsKeys = Object.keys(labelsMap);
  const currentLabelIndex = labelsKeys.indexOf(selectedTime); // Index of label for selected time

  const [swiperItemsDimensions, setSwiperItemsDimensions] = useState<
    ItemDimensions[]
  >([]);
  const dimensionsRef = useRef<ItemDimensions[]>(swiperItemsDimensions);
  const breakpointRef = useRef<string>(getBreakpoint());
  const swiperRef = useRef<HTMLDivElement | null>(null);

  const getSwiperItemsDimensions = useCallback(() => {
    const swiper = swiperRef.current;
    if (!swiper) return;

    // Reset dimensions as the swiper has changed.
    setSwiperItemsDimensions([]);

    const {
      width: swiperWidth,
      left: swiperLeft,
    } = swiper.getBoundingClientRect();
    const swiperChildren = swiper.children;

    let childrenDimensions: ItemDimensions[] = [];
    for (let i = 0; i < swiperChildren.length; i++) {
      const child = swiperChildren[i];

      const {
        left: chilLeft,
        width: childWidth,
      } = child.getBoundingClientRect();

      const dimensions: ItemDimensions = {
        scale: childWidth / (swiperWidth || 1),
        translate: chilLeft - swiperLeft,
      };

      childrenDimensions = [...childrenDimensions, dimensions];
    }

    // Update with new swiper dimensions.
    setSwiperItemsDimensions(childrenDimensions);
  }, []);

  // If breakpoint changes, retrigger swiper dimensions calculations.
  const resizeListener = useCallback(() => {
    const updatedBreakpoint = getBreakpoint();
    if (updatedBreakpoint !== breakpointRef.current) {
      getSwiperItemsDimensions();
      breakpointRef.current = updatedBreakpoint;
    }
  }, [getSwiperItemsDimensions]);

  const debouncedResizeListener = debounce(resizeListener, 500);

  // Adds debounced listener to window resize events.
  useEffect(() => {
    window.addEventListener('resize', debouncedResizeListener);
    return () => window.removeEventListener('resize', debouncedResizeListener);
  }, [debouncedResizeListener]);

  // Re-calculates dimensions on swiper change.
  useEffect(() => {
    getSwiperItemsDimensions();
  }, [getSwiperItemsDimensions, swiperRef]);

  // If dimensions have changed, the sizing has been reset and the active styling has been removed.
  // This re-adds the active styling of the currently selected time filter.
  useEffect(() => {
    if (swiperItemsDimensions !== dimensionsRef.current) {
      onToggle(labelsKeys[currentLabelIndex] as TimeFilter);
      dimensionsRef.current = swiperItemsDimensions;
    }
  }, [currentLabelIndex, labelsKeys, onToggle, swiperItemsDimensions]);

  if (display === 'large') {
    const indicator = swiperItemsDimensions[currentLabelIndex] || {
      scale: 1,
      translate: 0,
    };

    return (
      <Swiper ref={swiperRef} {...indicator}>
        {labelsKeys.map((key, keyIndex) => {
          const timeFilter = key as TimeFilter;

          return (
            <SwiperItem
              key={`${currentTime}-${timeFilter}`}
              // Only set to active if the state holds this button's dimensions, otherwise it'll break calculations and cause misalignments.
              isActive={
                swiperItemsDimensions[keyIndex] && selectedTime === timeFilter
              }
              onClick={() => onToggle(timeFilter)}
            >
              <SwiperItemTitle>{labelsMap[timeFilter].label}</SwiperItemTitle>
            </SwiperItem>
          );
        })}
      </Swiper>
    );
  }

  const nextLabelKey =
    labelsKeys[
      currentLabelIndex === labelsKeys.length - 1 ? 0 : currentLabelIndex + 1
    ];
  return (
    <SwitchButton
      aria-label={`Filter time. Current: ${labelsMap[selectedTime].label}, next: ${labelsMap[nextLabelKey].label}`}
      onClick={() => onToggle()}
      tabIndex={isOpen ? 0 : -1}
      {...props}
    >
      {isAwaitingServiceStatus && (
        <LoadingBarWrapper>
          <LoadingBar />
        </LoadingBarWrapper>
      )}
      <>
        <TimeToggleLabel>{[labelsMap[selectedTime].label]}</TimeToggleLabel>
      </>
    </SwitchButton>
  );
};

export default TimeToggle;
