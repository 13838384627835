import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { betaLabel, subTitleSmall } from '../Text';
import { withTouchPressStyle } from '../../utils/theme';
import { mapTitle } from '../ui-constants';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

interface BetaButtonInterface {
  showBeta?: boolean;
  hasShadow?: boolean;
}
const BetaLabel = styled.div`
  ${betaLabel};
  background: ${p => p.theme.colors.blue};
  border-radius: 6px;
  bottom: -8px;
  padding: 2px 4px;
  position: absolute;
`;

const BetaButtonPlaceholderOuter = styled.div<
  Pick<BetaButtonInterface, 'hasShadow'>
>`
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${p => (p.hasShadow ? p.theme.boxShadow : 0)};
  position: relative;

  svg {
    transform: scale(0.95);
    margin-left: 2px;
  }
`;

const getTitleShadow = (useDarkMap: boolean) => {
  const color = useDarkMap ? '#000' : '#fff';
  const shadow = `0 0 2px ${color}`;

  return `${shadow}, ${shadow}, ${shadow}, ${shadow}`;
};

const Title = styled.h1<{ isVisible: boolean; useDarkMap: boolean }>`
  ${subTitleSmall};
  color: ${p => (p.useDarkMap ? p.theme.colors.white : p.theme.colors.black)};
  margin-left: 20px;
  opacity: ${p => (p.isVisible ? 1 : 0)};
  text-shadow: ${p => getTitleShadow(p.useDarkMap)};
  transition: opacity 400ms ease;
`;

const BetaButtonIcon: React.FC<BetaButtonInterface> = ({
  showBeta,
  hasShadow,
}) => (
  <BetaButtonPlaceholderOuter hasShadow={hasShadow}>
    <svg width="28" height="30" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M.024 6.414C2.736 2.538 7.236 0 12.324 0c8.286 0 15 6.714 15 15s-6.714 15-15 15C7.44 30 3.096 27.66.36 24.048"
          fill="#000"
        />
        <path
          d="M2.04 23.748s0-5.244-.048-5.838c0-.57-.282-3.852-.222-4.404l.156.006 1.968 9.918 2.754-.486 1.602-9.186h.18c.084.564-.144 3.228-.144 3.798-.048.594-.048 5.1-.048 5.1l2.79-.486V8.34l-4.356-.756-1.224 8.472c-.042 0-1.236-8.904-1.236-8.904L0 6.42v17.628M19.764 19.266l1.344-.174.174 1.284 1.572-.27-1.506-9.954-1.872-.324L17.58 21.03l1.998-.354zM11.754 12.03l2.112.186.006 9.456 2.58-.45v-8.778l1.668.138V9.57l-6.366-1.104z"
          fill="#FFF"
        />
        <path fill="#000" d="M20.364 13.536l-.3 3.336.774-.048-.288-3.288z" />
      </g>
    </svg>
    {showBeta && <BetaLabel>beta</BetaLabel>}
  </BetaButtonPlaceholderOuter>
);

const BetaButtonContainer = styled.button<
  Pick<BetaButtonInterface, 'hasShadow'>
>`
  ${withTouchPressStyle()};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${p => p.theme.colors.button.bg};
  box-shadow: ${p => (p.hasShadow ? p.theme.boxShadow : 0)};
`;

interface BetaButtonProps extends BetaButtonInterface {
  action?: () => void;
  zoom?: number;
  useDarkMap?: boolean;
}
const BetaButton: React.FC<BetaButtonProps> = ({
  action,
  showBeta = true,
  hasShadow = true,
  useDarkMap = false,
  zoom,
}) => {
  const [titleVisible, setTitleVisible] = useState<boolean>(true);

  const interactionListener = useCallback(() => {
    if (titleVisible) setTitleVisible(false);
  }, [titleVisible, setTitleVisible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTitleVisible(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (zoom === 11) {
      const delay = setTimeout(() => {
        setTitleVisible(true);
      }, 2000);

      return () => clearTimeout(delay);
    }
  }, [zoom]);

  useEffect(() => {
    window.addEventListener('touchmove', interactionListener);
    window.addEventListener('click', interactionListener);

    return () => {
      window.removeEventListener('touchmove', interactionListener);
      window.removeEventListener('click', interactionListener);
    };
  }, [interactionListener]);

  return (
    <Wrapper>
      <BetaButtonContainer hasShadow={hasShadow} onClick={action}>
        <BetaButtonIcon showBeta={showBeta} hasShadow={hasShadow} />
      </BetaButtonContainer>
      <Title isVisible={titleVisible} useDarkMap={useDarkMap}>
        {mapTitle}
      </Title>
    </Wrapper>
  );
};

export default BetaButton;
