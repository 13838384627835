/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { Line, line as d3_line } from 'd3-shape';
import { Path, path as d3_path } from 'd3-path';
import {
  LineAB,
  LineABC,
  LineABCD,
  LineABStrategy,
  Point,
} from './geometry-types';
import {
  add,
  fromPolar,
  normalizeAngle,
  normalizeAngleDegrees,
  parallelSpacingAtIndex,
  pointsNormalAngles,
  getSegmentsNormalAngles,
  getSegmentsNormalAnglesDegrees,
  rotatePoints,
  distance,
} from './point-utils';
import { direct } from './path-strategies';
import { toRadians } from 'ol/math';
import { State } from 'ol/render';
import { SegmentStrategyIndex } from '../maps/strategies-for-segments';

/**
 * Returns a sequence of n integers starting from 0 (ending at n - 1).
 * @example getIntegers(3)
 * > [0, 1, 2]
 */
const getIntegers = (n: number) => Array.from(Array(n).keys());

const getLinePathDataForPoints: Line<Point> = d3_line<Point>()
  .x(t => t[0])
  .y(t => t[1]);

interface LineABParallelPathProps {
  points: LineAB;
  strategy?: LineABStrategy;
  radius?: number;
  strokeWidth?: number;
  spacing?: number;
  parallel?: number;
  showEndPoints?: boolean;
  leftHanded?: boolean;
  debug?: boolean;
}

export const LineABParallelPath: FC<LineABParallelPathProps> = ({
  points,
  strategy = direct,
  radius = 15,
  strokeWidth = 10,
  spacing = 12,
  parallel = 1,
  showEndPoints = false,
  leftHanded = false,
  debug = false,
}) => {
  const newPoints = strategy(points);

  return (
    <ParallelPath
      {...{
        points: newPoints,
        radius,
        strokeWidth,
        spacing,
        parallel,
        showEndPoints,
        leftHanded,
        debug,
      }}
    />
  );
};

interface ParallelPathProps {
  points: Point[];
  radius?: number;
  strokeWidth?: number;
  spacing?: number;
  parallel?: number;
  leftHanded?: boolean;
  showEndPoints?: boolean;
  debug?: boolean;
}

export const ParallelPath: FC<ParallelPathProps> = ({
  points,
  radius = 15,
  strokeWidth = 10,
  spacing = 12,
  parallel = 1,
  leftHanded = false,
  showEndPoints = false,
  debug = false,
}) => {
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];
  const parallelLines = parallelLinesForPoints({
    points,
    radius,
    parallel,
    spacing,
  });

  const roundedPaths = roundedPathsForPoints({
    radius,
    spacing,
    parallelLines,
    leftHanded,
  });

  const pathData = roundedPaths.map(String).join(' ');

  let normalsPaths: Path[] = [];
  if (debug) {
    const normalAngles = pointsNormalAngles(points);
    normalsPaths = normalAngles.map((normalAngle, i) => {
      const p = points[i];
      const normalVector = fromPolar(10 + spacing, normalAngle);
      const normalLineStart = p;
      const normalLineEnd = add(p, normalVector);
      const linePath = d3_path();
      linePath.moveTo(normalLineStart[0], normalLineStart[1]);
      linePath.lineTo(normalLineEnd[0], normalLineEnd[1]);
      return linePath;
    });
  }

  let segNormalPaths: Path[] = [];
  if (debug) {
    const segmentsNormalAngles = getSegmentsNormalAngles(points);
    segNormalPaths = segmentsNormalAngles.map((segNormalAngle, i) => {
      // const normalAngle = normalAngles[i];
      const [ax, ay] = points[i];
      const [bx, by] = points[i + 1];
      const midpoint: Point = [(ax + bx) / 2, (ay + by) / 2];
      const normalVector = fromPolar(10 + spacing, segNormalAngle);
      const normalLineStart = midpoint;
      const normalLineEnd = add(midpoint, normalVector);
      const linePath = d3_path();
      linePath.moveTo(normalLineStart[0], normalLineStart[1]);
      linePath.lineTo(normalLineEnd[0], normalLineEnd[1]);
      return linePath;
    });
  }

  return (
    <g>
      <path
        d={pathData}
        stroke="darkred"
        strokeWidth={strokeWidth}
        strokeLinecap="square"
        strokeLinejoin="miter"
        fill="none"
      />
      {showEndPoints && (
        <>
          <circle cx={firstPoint[0]} cy={firstPoint[1]} r="5" fill="green" />
          <circle cx={lastPoint[0]} cy={lastPoint[1]} r="5" fill="red" />
        </>
      )}
      <g id="Vertices normal vectors">
        <path
          d={normalsPaths.map(String).join(' ')}
          stroke="blue"
          strokeOpacity={0.5}
          strokeWidth={4}
          strokeLinecap="round"
          fill="none"
        />
      </g>
      <g id="Segments normal vectors">
        <path
          d={segNormalPaths.map(String).join(' ')}
          stroke="orange"
          strokeOpacity={0.5}
          strokeWidth={4}
          strokeLinecap="round"
          fill="none"
        />
      </g>
    </g>
  );
};

export const drawDot = (
  [centerX, centerY]: Point,
  state: State,
  radius = 0,
  fillStyle = 'black'
) => {
  const { context, pixelRatio } = state;
  context.fillStyle = fillStyle;
  context.beginPath();
  context.arc(centerX, centerY, radius * pixelRatio, 0, 2 * Math.PI);
  context.fill();
};

export const drawTriangle = (
  [centerX, centerY]: Point,
  state: State,
  width = 0,
  fillStyle = 'black',
  rotationAngle = 0
) => {
  const { context } = state;
  const triangleHeight = width / 4;
  const trianglePoints: Point[] = [
    [0, -triangleHeight],
    [triangleHeight * 2, triangleHeight],
    [-triangleHeight * 2, triangleHeight],
  ];
  const [a, b, c] = rotatePoints(trianglePoints, toRadians(rotationAngle));
  context.fillStyle = fillStyle;
  context.beginPath();
  context.moveTo(centerX + a[0], centerY + a[1]);
  context.lineTo(centerX + b[0], centerY + b[1]);
  context.lineTo(centerX + c[0], centerY + c[1]);
  context.closePath();
  context.fill();
};

export const drawRoundSingleLineForPoints = (
  points: Point[],
  radii: number | (number | undefined)[] = 0,
  context: Path = d3_path(),
  strategyIndex?: SegmentStrategyIndex
): Path => {
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];
  context.moveTo(firstPoint[0], firstPoint[1]);

  // Handle 3-point i.e. 2-segment paths
  if (points.length === 3) {
    const radius = radii[1] || radii || 0;
    const middlePoint = points[1];
    let safeRadius = radius;
    if (strategyIndex === 1 || strategyIndex === 2) {
      const width = Math.abs(lastPoint[0] - firstPoint[0]);
      const height = Math.abs(lastPoint[1] - firstPoint[1]);
      const smallestDimension = width < height ? width : height;
      safeRadius = Math.min(radius, smallestDimension);
    } else {
      // For freeform strategies, calculate a safe radius using the Law of Cosines
      const p1 = firstPoint;
      const p2 = middlePoint;
      const p3 = lastPoint;
      const lengthP1P2 = distance(p1, p2);
      const lengthP2P3 = distance(p2, p3);
      const lengthP1P3 = distance(p1, p3);
      const lengthShortest = Math.min(lengthP1P2, lengthP2P3);
      const angleP2 = Math.acos(
        (lengthP1P2 * lengthP1P2 +
          lengthP2P3 * lengthP2P3 -
          lengthP1P3 * lengthP1P3) /
          (2 * lengthP1P2 * lengthP2P3)
      );

      const maxRadius = Math.tan(angleP2 * 0.5) * lengthShortest;
      // Clamp to 80% of the maximum calculated radius to be safe and avoid zig-zag artifacts,
      // e.g. the red S-curve from Clark St. to Borough Hall.
      // TODO: With that S-curve, the switchback would need to be bisected in the middle for an accurate calculation.
      // I.e. The line strategy produces 4 points A, B, C, D.
      // The maxRadius calculation finds a radius that would fit in A-B-C or B-C-D.
      // But because the curve changes direction in the S,
      // it should be calculated using the midpoint M of B-C,
      // so then the radius would need to fit in A-B-M or M-C-D.
      safeRadius = Math.min(maxRadius, radius);
    }
    // d3-path arcTo optimizes a radius of 0 to be a straight line
    context.arcTo(
      middlePoint[0],
      middlePoint[1],
      lastPoint[0],
      lastPoint[1],
      safeRadius
    );
    // Handle 4-point i.e. 3-segment paths
  } else if (points.length === 4) {
    const width = Math.abs(lastPoint[0] - firstPoint[0]);
    const height = Math.abs(lastPoint[1] - firstPoint[1]);
    const smallestDimension = width < height ? width : height;

    for (let i = 1; i < points.length - 1; i++) {
      const radius = radii[i] || radii || 0;
      const middlePoint = points[i];
      const prevPoint = points[i - 1];
      const nextPoint = points[i + 1];

      let safeRadius = radius;
      // For horizontal/vertical strategies, it's easier to calculate a safe radius using width and height
      if (strategyIndex === 3 || strategyIndex === 4) {
        safeRadius = Math.min(radius, smallestDimension * 0.5);
      } else if (strategyIndex === 7 || strategyIndex === 8) {
        safeRadius = Math.min(radius, smallestDimension);
      } else {
        // For freeform strategies, calculate a safe radius using the Law of Cosines
        const p1 = prevPoint;
        const p2 = middlePoint;
        const p3 = nextPoint;
        const lengthP1P2 = distance(p1, p2);
        const lengthP2P3 = distance(p2, p3);
        const lengthP1P3 = distance(p1, p3);
        const lengthShortest = Math.min(lengthP1P2, lengthP2P3);
        const angleP2 = Math.acos(
          (lengthP1P2 * lengthP1P2 +
            lengthP2P3 * lengthP2P3 -
            lengthP1P3 * lengthP1P3) /
            (2 * lengthP1P2 * lengthP2P3)
        );

        const maxRadius = Math.tan(angleP2 * 0.5) * lengthShortest;
        // Clamp to 80% of the maximum calculated radius to be safe and avoid zig-zag artifacts,
        // e.g. the red S-curve from Clark St. to Borough Hall.
        // TODO: With that S-curve, the switchback would need to be bisected in the middle for an accurate calculation.
        // I.e. The line strategy produces 4 points A, B, C, D.
        // The maxRadius calculation finds a radius that would fit in A-B-C or B-C-D.
        // But because the curve changes direction in the S,
        // it should be calculated using the midpoint M of B-C,
        // so then the radius would need to fit in A-B-M or M-C-D.
        safeRadius = Math.min(maxRadius * 0.8, radius);
      }

      // d3-path arcTo optimizes a radius of 0 to be a straight line
      context.arcTo(
        middlePoint[0],
        middlePoint[1],
        nextPoint[0],
        nextPoint[1],
        safeRadius
      );
    }
  }

  context.lineTo(lastPoint[0], lastPoint[1]);

  const debug = false;
  if (debug) {
    // Draw containing triangles for the arcTo paths
    context.moveTo(points[0][0], points[0][1]);
    context.lineTo(points[1][0], points[1][1]);
    context.lineTo(points[2]?.[0], points[2]?.[1]);
    context.lineTo(points[3]?.[0], points[3]?.[1]);
  }
  return context;
};

const roundLineABC = ([a, b, c]: LineABC, radius = 0): Path => {
  const roundPath = d3_path();
  const width = c[0] - a[0];
  const height = c[1] - a[1];
  const safeRadius = Math.min(radius, Math.abs(width), Math.abs(height));
  roundPath.moveTo(a[0], a[1]);
  // d3-path arcTo optimizes a radius of 0 to be a straight line
  roundPath.arcTo(b[0], b[1], c[0], c[1], safeRadius);
  roundPath.lineTo(c[0], c[1]);
  return roundPath;
};

// TODO: handle more cases
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fixRadius = (a: Point, b: Point, c: Point, radius: number): number => {
  const width = b[0] - a[0];
  const height = b[1] - a[1];
  return Math.min(radius, Math.abs(width) / 2, Math.abs(height) / 2);
};

// TODO: delete if no longer necessary
const roundLineABCD = ([a, b, c, d]: LineABCD, radius = 0): Path => {
  const roundPath = d3_path();
  const width = c[0] - a[0];
  const height = c[1] - a[1];
  const safeRadius = Math.min(
    radius,
    Math.abs(width) / 20,
    Math.abs(height) / 20
  );
  roundPath.moveTo(a[0], a[1]);
  roundPath.arcTo(b[0], b[1], c[0], c[1], safeRadius);
  roundPath.arcTo(c[0], c[1], d[0], d[1], safeRadius);
  roundPath.lineTo(d[0], d[1]);
  return roundPath;
};

// TODO: delete if no longer necessary
const parallelLineABCAtIndex = ({
  index,
  normalAngles,
  points,
  parallel = 1,
  radius = 0,
  spacing = radius,
}: ParallelLineABCProps & {
  index: number;
  maxIndex: number;
  normalAngles: number[];
}): LineABC => {
  const [a, b, c] = points;
  const maxIndex = parallel - 1;
  const spacingAtIndex = parallelSpacingAtIndex(spacing, index, maxIndex);
  const aShifted = add(a, fromPolar(spacingAtIndex, normalAngles[0]));
  const cShifted = add(c, fromPolar(spacingAtIndex, normalAngles[2]));
  // Shift point b (elbow)
  // The elbow's radial distance to the parallel line is <= the parallel distance.
  // Reverse the projection of the elbow's normal vector onto the first normal.
  // In this diagram, radiusElbow is hypotenuse a, s is a₁:
  // https://en.wikipedia.org/wiki/Vector_projection
  const radiusElbow =
    spacingAtIndex / Math.cos(normalAngles[1] - normalAngles[0]);
  const bShifted = add(b, fromPolar(radiusElbow, normalAngles[1]));
  return [aShifted, bShifted, cShifted];
};

// TODO: to handle more map scenarios
// (like how the 2 & 3 trains between Chambers St and Park Pl
// start at a 3-dot station and ends at a 2-dot station)
// allow start index and last index to be different and
// allow start parallel and end parallel to be different.
const parallelLineForPointsAtIndex = ({
  points,
  parallel = 1,
  spacing,
  index,
  normalAngles = pointsNormalAngles(points),
}: {
  points: Point[];
  parallel?: number;
  spacing: number;
  index: number;
  normalAngles?: number[];
}): Point[] => {
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];
  // const middlePoints = points.slice(1, -1);
  const maxIndex = parallel - 1;
  const spacingAtIndex = parallelSpacingAtIndex(spacing, index, maxIndex);
  const firstPointShifted = add(
    firstPoint,
    fromPolar(spacingAtIndex, normalAngles[0])
  );
  const lastPointShifted = add(
    lastPoint,
    fromPolar(spacingAtIndex, normalAngles[normalAngles.length - 1])
  );

  // Shift middle points (elbows)
  // The elbow's radial distance to the parallel line is <= the parallel distance.
  // Reverse the projection of the elbow's normal vector onto the first normal.
  // In this diagram:
  // https://en.wikipedia.org/wiki/Vector_projection
  // The hypotenuse a is greater than a₁ most of the time.
  // a  = a₁ / cos θ
  // a  = radiusElbow
  // a₁ = spacingAtIndex
  // θ  = normalAngleDiff

  const middlePointsShifted: Point[] = [];

  // TODO: calculate the normals just once and pass them in
  const segmentsNormalAngles = getSegmentsNormalAngles(points);
  for (let i = 1; i < points.length - 1; i++) {
    const middlePoint = points[i];
    const segmentNormalAngleDiff =
      normalizeAngle(segmentsNormalAngles[i] - segmentsNormalAngles[i - 1]) / 2;
    const radiusElbow = spacingAtIndex / Math.cos(segmentNormalAngleDiff);
    const bShifted = add(middlePoint, fromPolar(radiusElbow, normalAngles[i]));
    middlePointsShifted.push(bShifted);
  }

  return [firstPointShifted, ...middlePointsShifted, lastPointShifted];
};

interface ParallelLineABCProps {
  points: LineABC;
  parallel?: number;
  radius?: number;
  spacing?: number;
}

interface ParallelLineForPointsProps {
  points: Point[];
  parallel?: number;
  radius?: number;
  spacing?: number;
}

interface GetRadiiAtLineIndexProps {
  points: Point[];
  radius: number;
  spacing: number;
  lineIndex: number;
  maxIndex: number;
  segmentsNormalAnglesDegrees: number[];
  leftHanded?: boolean;
}

/**
 *
 * @param points
 * @param radius
 * @param spacing
 * @param lineIndex
 * @param maxIndex
 * @param segmentsNormalAnglesDegrees
 * @param leftHanded Regular Cartesian and map coordinates are right-handed, where y increases upward on the screen. Canvas and SVG coordinates are left-handed, with y increasing downward.
 * @see https://en.wikipedia.org/wiki/Cartesian_coordinate_system#In_two_dimensions
 */
export const getRadiiAtLineIndex = ({
  points,
  radius,
  spacing,
  lineIndex,
  maxIndex,
  segmentsNormalAnglesDegrees,
  leftHanded = false,
}: GetRadiiAtLineIndexProps): (number | undefined)[] => {
  const numPoints = points.length;
  const currentLineRadii: number[] = [];
  for (let pointIndex = 1; pointIndex < numPoints - 1; pointIndex++) {
    if (radius <= 0) {
      currentLineRadii[pointIndex] = 0;
    } else {
      const p1 = points[pointIndex - 1];
      const p2 = points[pointIndex];
      const p3 = points[pointIndex + 1];

      // https://stackoverflow.com/questions/17592800/how-to-find-the-orientation-of-three-points-in-a-two-dimensional-space-given-coo/17594055
      const crossProduct =
        (p2[1] - p1[1]) * (p3[0] - p2[0]) - (p2[0] - p1[0]) * (p3[1] - p2[1]);

      const cornerIsClockwise = leftHanded
        ? crossProduct < 0
        : crossProduct > 0;

      let realIndex = lineIndex;
      // If curving to the right, the right radius is the smallest
      if (cornerIsClockwise) {
        // reverse the radii order
        realIndex = maxIndex - lineIndex;
      }

      const middleRadius = radius + spacing;
      const radiusAtIndex =
        middleRadius + parallelSpacingAtIndex(spacing, realIndex, maxIndex);
      // Prevent negative radius which triggers an error in D3's arcTo()
      currentLineRadii[pointIndex] = radiusAtIndex > 0 ? radiusAtIndex : 0;
    }
  }
  return currentLineRadii;
};

const roundedPathsForPoints = ({
  parallelLines,
  spacing,
  radius,
  leftHanded = false,
}: {
  parallelLines: Point[][];
  spacing: number;
  radius: number;
  leftHanded?: boolean;
}): Path[] => {
  const parallel = parallelLines.length;
  const maxIndex = parallel - 1;
  // TODO: pass in normals instead of recalculating
  const segmentsNormalAngles = getSegmentsNormalAnglesDegrees(parallelLines[0]);
  const indices = getIntegers(parallel);

  const linesRadii: (number | undefined)[][] = indices.map(lineIndex => {
    return getRadiiAtLineIndex({
      points: parallelLines[0],
      radius,
      spacing,
      lineIndex,
      maxIndex,
      segmentsNormalAnglesDegrees: segmentsNormalAngles,
      leftHanded,
    });
  });

  const roundedPaths = parallelLines.map((line, lineIndex) =>
    drawRoundSingleLineForPoints(line, linesRadii[lineIndex])
  );
  return roundedPaths;
};

const parallelLinesForPoints = ({
  points,
  parallel = 1,
  radius = 0,
  spacing = radius,
}: ParallelLineForPointsProps): Point[][] => {
  if (parallel === 1) {
    // return [roundLineForPoints(points, [radius])];
    return [points];
  }
  if (parallel >= 1) {
    const indices = getIntegers(parallel);
    // These angles are constant across the iteration, so calculate just once
    const normalAngles: number[] = pointsNormalAngles(points);

    const parallelLines: Point[][] = indices.map(index =>
      parallelLineForPointsAtIndex({
        index,
        points,
        normalAngles,
        parallel,
        spacing,
      })
    );
    return parallelLines;
  }
  return [];
};
