import { ZoomLevel } from './map-types';

export const getLineDashValue = (lineWidth: number, resolution: number) => {
  return [0, (lineWidth * 2) / resolution];
};

/**
 * We set fixed widths per zoom to block the dash space and size recalculation.
 * During that recalculation, the line creates an animated side effect because
 * the position of the dots changes during zoom changes.
 */
export const getAirportsLineWidth = (
  currentZoom: number,
  isDashed?: boolean
): number => {
  const values = [
    [12.4, 60],
    [12.8, 40],
    [13, 35],
    [13.4, 24],
    [14.4, 16],
    [19, 13],
  ];

  for (const [zoom, width] of values) {
    if (currentZoom < zoom) {
      // On lower zooms, the dotted line gets too thin using the same
      // width as the solid line. The multiplier will force it to look
      // thick and visually similar to the solid line width.
      if (isDashed && currentZoom < ZoomLevel.z15) {
        return width * 1.5;
      }

      return width;
    }
  }

  return values[0][1];
};
