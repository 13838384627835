import { createSelector } from 'reselect';
import { MapState } from '../../models/map';
import { AppState } from '../../models';
import { RouteDisplayMode } from '../../maps/map-types';

// Root: the state

export const getMapState = (state: AppState): MapState => state.map;

// First level: child nodes

export const getMapAda = createSelector(
  getMapState,
  (mapState: MapState) => mapState.ada
);

export const getMapCenter = createSelector(
  getMapState,
  (mapState: MapState) => ({ lon: mapState.lon, lat: mapState.lat })
);

export const getMapCurrentTime = createSelector(
  getMapState,
  (mapState: MapState) => mapState.currentTime
);

export const getMapGeolocation = createSelector(
  getMapState,
  (mapState: MapState) => mapState.geolocation
);

export const getMapMode = createSelector(
  getMapState,
  (mapState: MapState): RouteDisplayMode =>
    mapState.selectedRouteId ? 'solo' : 'all'
);

export const getMapRoutesWithSituation = createSelector(
  getMapState,
  (mapState: MapState) => mapState.routesWithSituation
);

export const getMapSelectedAirportTerminalId = createSelector(
  getMapState,
  (mapState: MapState) => mapState.selectedAirportTerminalId
);

export const getMapSelectedRouteId = createSelector(
  getMapState,
  (mapState: MapState) => mapState.selectedRouteId
);

export const getMapSelectedStation = createSelector(
  getMapState,
  (mapState: MapState) => mapState.selectedStation
);

export const getMapServiceStatus = createSelector(
  getMapState,
  (mapState: MapState) => mapState.serviceStatus
);

export const getMapTrainStatusByStopId = createSelector(
  getMapState,
  (mapState: MapState) => mapState.trainStatusByStopId
);

export const getMapStopListsByTimeFilterAndRoute = createSelector(
  getMapState,
  (mapState: MapState) => mapState.stopListsByTimeFilterAndRoute
);

export const getMapStrategiesForSegments = createSelector(
  getMapState,
  (mapState: MapState) => mapState.strategiesForSegments
);

export const getMapTimeFilter = createSelector(
  getMapState,
  (mapState: MapState) => mapState.timeFilter
);

export const getMapZoom = createSelector(
  getMapState,
  (mapState: MapState) => mapState.zoom
);

export const getMapElevatorEscalatorStatus = createSelector(
  getMapState,
  (mapState: MapState) => mapState.elevatorEscalatorStatus
);

export const getUseDarkMap = createSelector(
  getMapState,
  (mapState: MapState) => mapState.useDarkMap
);

export const getMapVaccineLocations = createSelector(
  getMapState,
  (mapState: MapState) => mapState.vaccineLocations
);

export const getSelectedMapVaccineLocations = createSelector(
  getMapState,
  (mapState: MapState) => {
    const { selectedVaccineLocationId, vaccineLocations } = mapState;

    return (
      vaccineLocations?.find(
        location => location.attributes.id === selectedVaccineLocationId
      ) || null
    );
  }
);
