import React, { MouseEventHandler } from 'react';
import styled from 'styled-components/macro';
import Icon, { IconTypes, IconContainer } from '../Icon';
import { withFocusStyle } from '../../utils/theme';

type CloseButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const CloseButtonContainer = styled.button`
  color: ${p => p.theme.colors.black};
  cursor: pointer;
  outline: none;

  ${p => withFocusStyle(p.theme)};
`;

const CloseButton: React.FC<CloseButtonProps> = ({ onClick, ...props }) => {
  return (
    <CloseButtonContainer
      data-cy="close-train-times"
      onClick={onClick}
      {...props}
      aria-label="close-train-times"
    >
      <IconContainer>
        <Icon type={IconTypes.Cross} />
      </IconContainer>
    </CloseButtonContainer>
  );
};

export default CloseButton;
