export interface LinkType {
  url: string;
  title: string;
  target?: '_blank';
}

export const links: LinkType[] = [
  { url: 'about.html', title: 'About' },
  {
    url: 'https://new.mta.info/privacy-policy',
    title: 'Privacy Policy',
    target: '_blank',
  },
  {
    url: 'https://new.mta.info/terms-and-conditions',
    title: 'Terms & Conditions',
    target: '_blank',
  },
  {
    url: 'https://forms.gle/tKU5soiywh8RurhE8',
    title: 'Send Feedback',
    target: '_blank',
  },
];
