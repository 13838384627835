import React from 'react';
import { connect } from 'react-redux';
import { TimeFilter } from '../subway-data';
import { AppDispatch, AppState } from '../models';
import TimeToggle from '../components/Controls/TimeToggle';
import { getMapCurrentTime, getMapTimeFilter } from '../selectors/map/basic';

interface PillTimeToggleProps {
  isOpen: boolean;
  timeFilter: TimeFilter;
  currentTime: TimeFilter;
  setTimeFilter: (timeFilter: TimeFilter) => void;
  isAwaitingServiceStatus: boolean;
  routeMenuOpened: boolean;
  stationViewOpened: boolean;
}

export const timeFilterValues: { [key in TimeFilter]: TimeFilter[] } = {
  weekday: ['weekday', 'weeknight', 'weekend'],
  weeknight: ['weeknight', 'weekday', 'weekend'],
  weekend: ['weekend', 'weekday', 'weeknight'],
};

const PillTimeToggle: React.FC<PillTimeToggleProps> = ({
  currentTime,
  timeFilter,
  setTimeFilter,
  isAwaitingServiceStatus,
  routeMenuOpened,
  stationViewOpened,
}) => {
  /*
   * Show the right TimeFilter when clicking the
   * pill according to pre-defined order from design
   */
  const timeFilterValueInOrder = timeFilterValues[currentTime];
  const currentValueIndex = timeFilterValueInOrder.findIndex(
    el => el === timeFilter
  );
  const nextTimeFilter =
    timeFilterValueInOrder.length > currentValueIndex + 1
      ? timeFilterValueInOrder[currentValueIndex + 1]
      : timeFilterValueInOrder[0];

  return (
    <TimeToggle
      data-cy="timefilter-pill"
      display="default"
      currentTime={currentTime}
      selectedTime={timeFilter}
      isOpen={!routeMenuOpened && !stationViewOpened}
      onToggle={() => {
        setTimeFilter(nextTimeFilter);
      }}
      isAwaitingServiceStatus={isAwaitingServiceStatus}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  timeFilter: getMapTimeFilter(state),
  currentTime: getMapCurrentTime(state),
  isOpen: !state.ui.routeMenuOpened && !state.ui.stationViewOpened,
  isAwaitingServiceStatus: state.ui.isAwaitingServiceStatus,
  routeMenuOpened: state.ui.routeMenuOpened,
  stationViewOpened: state.ui.stationViewOpened,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setTimeFilter: dispatch.map.setTimeFilter,
});

export default connect(mapStateToProps, mapDispatchToProps)(PillTimeToggle);
