import { Feature, Map } from 'ol';
import { Style } from 'ol/style';
import { RenderFunction } from 'ol/style/Style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { RENDER_BUFFER_ICONS, STATION_SPACING } from '../maps-constants';
import { getRouteLineWidth } from '../maps-utils';
import { RouteSegment } from '../subway-openlayers-graphics';
import { Point } from '../../geometry/geometry-types';
import {
  drawRoundSingleLineForPoints,
  getRadiiAtLineIndex,
} from '../../geometry/paths';
import { RouteDirection, SubwayRouteId } from '../../subway-data/subway-types';
import { TrainTime } from '../../subway-data/station-status-types';
import {
  MOVING_TRAIN_LENGTH,
  MOVING_TRAIN_MAX_SECONDS,
  MOVING_TRAIN_MIN_ZOOM,
  MOVING_TRAIN_SPEED,
} from '../../config';
import { easePolyIn } from 'd3-ease';
import { movingTrains } from '../maps-theme';
import { getRadiusBase } from './utils/getRadiusBase';

const interpolateTrainArrivalSeconds = (
  lastUpdatedTrainArrivalSeconds: number,
  lastUpdatedEpoch: number
): number => {
  const elapsedSinceLastUpdatedMS = Date.now() - lastUpdatedEpoch;
  return lastUpdatedTrainArrivalSeconds - elapsedSinceLastUpdatedMS / 1000;
};

const trainEasing = easePolyIn.exponent(1.5);

const lineDashOffsetForTrain = ({
  trainArrivalSeconds,
  trainDashLength,
  trainSpeed,
  resolution,
}: {
  trainArrivalSeconds: number;
  trainDashLength: number;
  trainSpeed: number;
  resolution: number;
}) => {
  const maxSeconds = MOVING_TRAIN_MAX_SECONDS;
  const arrivalProgress = trainArrivalSeconds / maxSeconds;
  const easedArrivalProgress = trainEasing(arrivalProgress);
  const distanceToFrontOfTrain =
    (easedArrivalProgress * maxSeconds * trainSpeed) / resolution;
  // Make the train "disappear into the dot" by making its back arrive at arrival time of 0
  const distanceToBackOfTrain = distanceToFrontOfTrain - trainDashLength;
  // The dash offset needs to be negated
  return -distanceToBackOfTrain;
};

const routesNeedingWhiteTrains: SubwayRouteId[] = ['L', 'FS', 'GS', 'H'];

/** Map of train trip IDs to trainArrivalSeconds, to enable a ratchet effect where trains never move backward.  */
const trainTimesCache: { [tripId: string]: number } = {};

const renderRouteSegmentTrain: RenderFunction = (coordinates, state) => {
  const { context, feature, pixelRatio, resolution } = state;
  const routeSegment = feature.get('routeSegment') as RouteSegment;
  const {
    routeId,
    segmentsNormalAnglesDegrees,
    strategizedPoints,
    strategyIndex,
    routeIndex,
    maxIndex,
  } = routeSegment;

  const directionId = feature.get('directionId') as RouteDirection;

  const points = coordinates as Point[];

  context.beginPath();
  context.setLineDash([]);

  const radiusBase = getRadiusBase(resolution);
  const radii = getRadiiAtLineIndex({
    points: strategizedPoints,
    radius: radiusBase,
    spacing: (STATION_SPACING * pixelRatio) / resolution,
    lineIndex: routeIndex,
    maxIndex,
    segmentsNormalAnglesDegrees,
  });

  // Draw "worm" for moving train
  // Currently the moving trains theme is the same for dark and light modes
  context.lineCap = movingTrains.lineCap;
  context.strokeStyle = movingTrains.forColoredLines;
  // To improve contrast on grey lines, change moving train to white
  if (routesNeedingWhiteTrains.includes(routeId)) {
    context.strokeStyle = movingTrains.forGreyLines;
  }
  // Some color combinations result in an appearance of
  // moving trains slightly outside the lines (due to monitor subpixels),
  // so make the trains slightly thinner.
  const trainsThinnerFactor = 0.05;
  context.lineWidth = getRouteLineWidth(
    pixelRatio,
    resolution + trainsThinnerFactor
  );
  context.save();
  const trainDashLength = (MOVING_TRAIN_LENGTH * pixelRatio) / resolution;
  // Make a single train dot using a short dash and very long gap
  context.setLineDash([trainDashLength, 1000000]);

  const lastUpdate = feature.get('lastUpdate') as number;
  const trainTime = feature.get('trainTime') as TrainTime;
  const { tripId, time } = trainTime;
  const trainArrivalSeconds = interpolateTrainArrivalSeconds(time, lastUpdate);
  const trainHasArrived = trainArrivalSeconds <= 0;
  if (trainHasArrived) {
    delete trainTimesCache[tripId];
    return;
  }
  const oldTrainArrivalSeconds = trainTimesCache[tripId];
  const arrivalTimeChange = trainArrivalSeconds - oldTrainArrivalSeconds;
  // Often the MTA data for train arrival times does not count down in a predictable fashion.
  // E.g. a certain train may be listed as 3 minutes away for much longer than 3 minutes.
  // Or a fresh data update may give a new ETA that is farther away than previously,
  // as if the train moved backward. To avoid a jarring visual of trains moving backward,
  // we have a ratchet effect where a train will only move forward on the track.
  // If the new data would have made the train move backward, the train stays in the same spot.
  const movedBackward = arrivalTimeChange > 0;
  if (movedBackward) {
    return;
  }
  trainTimesCache[tripId] = trainArrivalSeconds;

  // Move the train worm along the curve, proportionally to the zoom
  context.lineDashOffset = lineDashOffsetForTrain({
    trainArrivalSeconds,
    trainDashLength,
    trainSpeed: MOVING_TRAIN_SPEED,
    resolution,
  });

  const pointsInOrder = directionId === '0' ? points : [...points].reverse();
  drawRoundSingleLineForPoints(pointsInOrder, radii, context, strategyIndex);
  context.stroke();

  context.restore();
};

let movingTrainsLayer: VectorLayer;

export const updateMovingTrainsLayer = (
  map: Map,
  movingTrainsFeatures: Feature[]
) => {
  if (movingTrainsLayer) {
    movingTrainsLayer.setSource(
      new VectorSource({
        features: movingTrainsFeatures,
      })
    );
  } else {
    movingTrainsLayer = new VectorLayer({
      renderBuffer: RENDER_BUFFER_ICONS,
      source: new VectorSource({
        features: movingTrainsFeatures,
      }),
      style: new Style({
        renderer: renderRouteSegmentTrain,
      }),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      minZoom: MOVING_TRAIN_MIN_ZOOM,
    });

    map.addLayer(movingTrainsLayer);
  }
};
