import { TimeFilter } from '.';

export interface LonLat {
  lon: number;
  lat: number;
}

/**
 * The interface has the list of all the subway stops related to the current
 * airport station/terminal. It also includes the stops related to previous
 * stations/terminals to create the right path when I have a selected subway
 * route.
 */
export interface HasRequiredSubwayStopIds {
  requiredSubwayStopIds?: string[];
}

export interface OtpRoute {
  /**
   * Usually this is shortName prefixed by 'MTASBWY:',
   * except 'SIR' becomes 'SI'
   * and 'S' is split into 'H', 'FS' and 'GS'
   * @example 'MTASBWY:A'
   * @example 'MTASBWY:SI'
   */
  id: string;

  /**
   * @example 'A'
   * @example 'SIR'
   */
  shortName: OtpRouteShortName;

  /**
   * @example '8 Avenue Express'
   * @example 'Staten Island Railway'
   */
  longName: string;

  // TODO: remove from data because it's always the same
  mode: 'SUBWAY';

  /** otp-routes is missing color for shuttles */
  color?: string;

  // TODO: remove from data because it's always the same
  agencyName: 'MTA New York City Transit';

  /**
   * @example 'MTASBWY__A'
   * @example 'MTASBWY__SI'
   */
  paramId: string;

  /** integer >= 1 */
  sortOrder: number;

  // TODO: remove from data because it's always the same
  routeType: 1;

  regionalFareCardAccepted: boolean;
}

/**
 * Generally:
 * 0 is northbound
 * 1 is southbound
 */
export type RouteDirection = '0' | '1';

export interface SubwayStopId {
  /** @example 'Q01' */
  stopId: string;
}

export interface SubwayStopName {
  /** @example 'Atlantic Av - Barclays Ctr' */
  stopName: string;
}

export interface SubwayStopIdName extends SubwayStopId, SubwayStopName {}

export interface SubwayStop extends LonLat, SubwayStopIdName {}
export interface SubwayStopEntrance extends LonLat {
  entranceId: string;
  stopId: string;
}

/**
 * Levels:
 * 1 is accessible
 * 2 is accessible Northbound only
 * 3 is accessible Southbound only
 */
export type AccessibilityLevel = '1' | '2' | '3';

export interface SubwayStopAccessibility {
  ada: boolean;
  level?: AccessibilityLevel;
}

export interface SubwayRouteIds extends ReadonlyArray<SubwayRouteId> {}

export interface Station
  extends SubwayStationUnified,
    SubwayStopAccessibility,
    SubwayStopName {
  readonly transfers: SubwayRouteIds;
  readonly transfersAndPassthroughs: SubwayRouteIds;
}

export interface Stations extends ReadonlyArray<Station> {}

export type OtpStopType = '0' | '1' | '2' | '3' | '4';

export interface OtpStopOnRoute extends SubwayStopId {
  /** @example 'A', 'B', 'H', 'FS', 'GS', 'SI' */
  routeId: OtpRouteId;

  /**
   * '0' seems to indicate the train always stops at this stop.
   * '2' seems to indicate the train goes through (in daytime?) without stopping,
   * '3' seems to indicate the train stops at this stop during weekends,
   * e.g. the A train is '2' for Lafayette Av and it doesn't stop there except at nights.
   * @todo Figure out what these all mean.
   */
  stopType: OtpStopType;

  /** @todo Figure out what this means */
  stopStatus?: '-1' | '-3';

  /** @example '1' */
  stopSequence: string;

  // This prop helps to remove duplicated stations
  // created to handle forks in a route on static data.
  isDuplicated?: boolean;

  // Most of the rerouting situations move the route to use stops from another
  // route. If there are no skipped stops, it will work fine and draw the same
  // path and curves
  // When we have skipped stops, we need to add the stop list that the route
  // should follow to add the skipped stops.
  isRerouting?: boolean;
}

export interface StopBase extends SubwayStopIdName {
  direction?: RouteDirection;
  isTerminal?: boolean;
}

export interface Stop extends StopBase, OtpStopOnRoute, LonLat {
  // The stop is unavailable if we don't receive data from
  // PG and used the static stop data to replace it
  unavailable?: boolean;
}

export interface RawSubwayStationUnified extends LonLat, SubwayStopId {
  Station_ID: number;

  Complex_ID: number;

  Division: 'BMT' | 'IND' | 'IRT' | 'SIR';

  /** @example '8th Av - Fulton St' */
  Line: string;

  Borough: 'Bk' | 'Bx' | 'M' | 'Q' | 'SI';

  /** @example 'D N R' */
  Daytime_Routes: string;

  /** @example 'Subway' */
  Structure:
    | 'Subway'
    | 'Elevated'
    | 'Open Cut'
    | 'Viaduct'
    | 'At Grade'
    | 'Embankment';

  North_Direction_Label: string;

  South_Direction_Label: string;
}

export interface SubwayStationUnified extends RawSubwayStationUnified {
  Daytime_Routes_Array: SubwayRouteIds;
  perpendicularStack?: SubwayRouteIds;
  /** The angle perpendicular to the route's geometry (i.e. the normal) in degrees. */
  angle?: number;
  // Line Icons to be displayed at terminal stations and select locations along routes
  lineIcons?: SubwayRouteIds;
}

export interface AirportTerminal extends HasRequiredSubwayStopIds {
  center: LonLat;
  isBusTerminal?: boolean;
  name: string;
  nameShort: string;
  radius: number;
  terminalId: string;
  stations: {
    angle: number;
    name: string;
    nameShort: string;
  }[];
}

export interface AirportRouteStation
  extends HasRequiredSubwayStopIds,
    LonLat,
    SubwayStopIdName {
  isBusStation?: boolean;
  isTransfer?: boolean;
  labelPositionVertical?: string;
  lineLabel?: string;
  lineLabelAlign?: string;
  nextStopId?: string;
  pathStrategyIndex?: number;
  placeStationDotAsFirst?: boolean;
  stopAbbr?: string;
  subwayStopId?: string;
  terminal?: {
    angle: number;
    terminalId: string;
  };
}

export interface StopIdList extends ReadonlyArray<StopId> {}

export interface StopIdLists extends ReadonlyArray<StopIdList> {}

export interface StopList extends ReadonlyArray<Stop> {}

export interface StopLists extends ReadonlyArray<StopList> {}

export interface StopListsByRoute extends Record<SubwayRouteId, StopLists> {}

//////// PATTERN GRAPH ////////

export interface PatternGraphRoute {
  /** @example 'G31' */
  stop: string;

  /** @example 'G' */
  route: OtpRouteShortName;
}

export interface PatternGraphAttribute {
  routes: PatternGraphRoute[];

  /** @example 'Flushing Av' */
  name: string;

  isTerminal: boolean;

  shuttling: boolean;

  color: string[];
}

export interface PatternGraphSuccessor {
  /** @example 'MTASBWY_G30N' */
  id: string;

  /**
   * @example 1
   * @todo When is this not 1?
   */
  routeType: number;
}

export interface PatternGraphNode {
  /** @example 'MTASBWY_G31N' */
  id: string;

  attribute: PatternGraphAttribute;

  successors: PatternGraphSuccessor[];
}

export interface PatternGraph {
  nodes: PatternGraphNode[];
}

/**
 * Similar to SubwayRouteId but:
 * - adds 6X, 7X
 */
export type OtpRouteShortName =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'J'
  | 'L'
  | 'M'
  | 'N'
  | 'Q'
  | 'R'
  | 'S'
  | 'SR'
  | 'SF'
  | 'W'
  | 'Z'
  | 'SIR'
  | '7X'
  | '6X'
  | 'FX';

/**
 * Similar to SubwayRouteId but:
 * - changes SIR to SI
 * - splits the shuttle into H, FS, GS
 * Current stopsForRoute night data isn't showing 6X or 7X
 * but patternGraph has 6X and 7X
 */
export type OtpRouteId =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'J'
  | 'L'
  | 'M'
  | 'N'
  | 'Q'
  | 'R'
  | 'W'
  | 'Z'
  // "SI" instead of "SIR"
  | 'SI'
  // Shuttles: http://web.mta.info/nyct/service/sline.htm
  // "H" instead of "SR" - Rockaway Park Shuttle
  | 'H'
  // "FS instead of "SF" - Franklin Ave Shuttle
  | 'FS'
  // "GS" instead of "S" - 42 Street Shuttle
  | 'GS'
  // added
  | '6X'
  | '7X'
  | 'FX';

export type SubwayRouteId = Exclude<OtpRouteId, '6X' | '7X' | 'FX'>;
// These marker types serve as documentation for various strings
export type StopId = string;
export type DateString = string;
export type HTMLString = string;

export interface RouteStatusMeta {
  human_readable_active_period?: object;
}

export interface RouteStatusDetail {
  statusId?: string;
  statusSummary: string;
  statusHeader?: HTMLString;
  statusDescription: HTMLString;
  statusTime?: string;
  statusCategory?: string;
  statusLastUpdated?: string;
  statusHumanReadable?: any;
  direction?: '0' | '1';
}

export interface RouteStatus {
  routeId: SubwayRouteId | string;
  lastUpdated: number;
  statusDetails: RouteStatusDetail[];
}

export type ServiceStatus = {
  [key in TimeFilter]: {
    [key in SubwayRouteId]?: RouteStatus;
  };
};

export interface BoroughLabel extends LonLat {
  name: string;
}

export enum SkippedEdgeStation {
  first,
  last,
  none,
}

// Check more infos about the object at
// src/subway-data/connected-stations-customization.ts
export interface ConnectedStationsCustomization {
  connectStationDots?: boolean;
  parallelSpacingAtFirstIndex: boolean;
  pathStrategyIndex?: number;
  stopId: string;
}
