import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components/macro';
import Icon, { IconContainer, IconTypes } from '../Icon';
import theme, {
  withFocusStyle,
  withTouchPressStyle,
  notTouch,
  landscapePhone,
  desktopLarge,
} from '../../utils/theme';
import { ANIMATION_DURATION } from '../ViewBox';

const MinimizeButtonInner = styled.button<MinimizeButtonProps>`
  width: 30px;
  height: 30px;
  pointer-events: auto;

  cursor: pointer;
  opacity: 1;

  ${// Forces to use the light theme because it's inside a white area
  withFocusStyle(theme)}
  ${withTouchPressStyle()}

  &::before {
    border-radius: 0px;
  }

  ${({ isEmergencyAlert, routeMenuOpened, theme }) =>
    isEmergencyAlert &&
    `
    visibility: ${routeMenuOpened ? 'hidden' : 'visible'};
    opacity: ${routeMenuOpened ? 0 : 1};
    transition: ${theme.transition};
    transition-property: opacity;
    transition-duration: ${ANIMATION_DURATION}ms;
    `}
`;

const MinimizeMenuButtonInner = styled(MinimizeButtonInner)`
  padding-left: 9px;
  padding-top: 15px;
  margin-top: -15px;

  ${notTouch(css`
    margin-right: -15px;
  `)}

  ${landscapePhone(css`
    margin-right: -8px;
  `)};

  ${desktopLarge(css`
    margin-right: -9px;
  `)};
`;

const MinimizeEmergencyAlertButtonInner = styled(MinimizeButtonInner)`
  position: absolute;
  right: 0;
  margin-right: 6px;

  &::before {
    margin-top: -15px;
    margin-left: -10px;
  }
`;

const MinimizeButtonSymbol = styled.span`
  display: flex;
  width: 12px;
  border-top: 2px solid black;
  height: 100%;
  pointer-events: none;
`;

const CloseButton = styled.button`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  pointer-events: auto;
  width: 30px;

  ${// Forces to use the light theme because it's inside a white area
  withFocusStyle(theme)}
  ${withTouchPressStyle()}
`;

interface MinimizeButtonProps {
  isEmergencyAlert?: boolean;
  routeMenuOpened?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  type?: 'close';
}
const MinimizeButton: React.FC<MinimizeButtonProps> = ({
  isEmergencyAlert,
  onClick,
  type,
  routeMenuOpened,
}) => {
  if (type === 'close') {
    return (
      <CloseButton aria-label="close" onClick={onClick}>
        <IconContainer>
          <Icon type={IconTypes.Cross} />
        </IconContainer>
      </CloseButton>
    );
  }

  const MinimizeButtonInner = isEmergencyAlert
    ? MinimizeEmergencyAlertButtonInner
    : MinimizeMenuButtonInner;
  return (
    <MinimizeButtonInner
      onClick={onClick}
      aria-label="Minimize"
      isEmergencyAlert={isEmergencyAlert}
      routeMenuOpened={routeMenuOpened}
    >
      <MinimizeButtonSymbol />
    </MinimizeButtonInner>
  );
};

export default MinimizeButton;
