import { SubwayStop } from '../subway-types';

// Do not change this file content.
// run "yarn update-stops" to re-generate this file.
const otpStops: SubwayStop[] = [
  {
    stopId: '501',
    stopName: 'Eastchester-Dyre Av',
    lat: 40.888298,
    lon: -73.830838,
  },
  {
    stopId: '502',
    stopName: 'Baychester Av',
    lat: 40.87866,
    lon: -73.838593,
  },
  {
    stopId: '505',
    stopName: 'Morris Park',
    lat: 40.854362,
    lon: -73.860495,
  },
  {
    stopId: '504',
    stopName: 'Pelham Pkwy',
    lat: 40.858982,
    lon: -73.855361,
  },
  {
    stopId: '503',
    stopName: 'Gun Hill Rd',
    lat: 40.870231,
    lon: -73.845806,
  },
  {
    stopId: 'B23',
    stopName: 'Bay 50 St',
    lat: 40.588838,
    lon: -73.983768,
  },
  {
    stopId: 'B22',
    stopName: '25 Av',
    lat: 40.597703,
    lon: -73.986829,
  },
  {
    stopId: 'B21',
    stopName: 'Bay Pkwy',
    lat: 40.601874,
    lon: -73.993731,
  },
  {
    stopId: 'B20',
    stopName: '20 Av',
    lat: 40.604554,
    lon: -73.99817,
  },
  {
    stopId: 'B08',
    stopName: 'Lexington Av/63 St',
    lat: 40.764627,
    lon: -73.966113,
  },
  {
    stopId: 'B10',
    stopName: '57 St',
    lat: 40.763972,
    lon: -73.97745,
  },
  {
    stopId: 'B15',
    stopName: '55 St',
    lat: 40.631433,
    lon: -73.995478,
  },
  {
    stopId: 'B14',
    stopName: '50 St',
    lat: 40.636259,
    lon: -73.994792,
  },
  {
    stopId: 'B13',
    stopName: 'Fort Hamilton Pkwy',
    lat: 40.640912,
    lon: -73.994306,
  },
  {
    stopId: 'B12',
    stopName: '9 Av',
    lat: 40.646291,
    lon: -73.994327,
  },
  {
    stopId: 'B19',
    stopName: '18 Av',
    lat: 40.607952,
    lon: -74.001737,
  },
  {
    stopId: 'B18',
    stopName: '79 St',
    lat: 40.613501,
    lon: -74.000612,
  },
  {
    stopId: 'B17',
    stopName: '71 St',
    lat: 40.619587,
    lon: -73.998866,
  },
  {
    stopId: 'B16',
    stopName: '62 St',
    lat: 40.626468,
    lon: -73.996899,
  },
  {
    stopId: 'B06',
    stopName: 'Roosevelt Island',
    lat: 40.759143,
    lon: -73.953261,
  },
  {
    stopId: 'B04',
    stopName: '21 St-Queensbridge',
    lat: 40.754203,
    lon: -73.942837,
  },
  {
    stopId: '902',
    stopName: 'Times Sq-42 St',
    lat: 40.755976,
    lon: -73.986233,
  },
  {
    stopId: '901',
    stopName: 'Grand Central-42 St',
    lat: 40.752767,
    lon: -73.97919,
  },
  {
    stopId: 'F35',
    stopName: 'Kings Hwy',
    lat: 40.603216,
    lon: -73.972364,
  },
  {
    stopId: 'F34',
    stopName: 'Avenue P',
    lat: 40.608944,
    lon: -73.973024,
  },
  {
    stopId: 'F33',
    stopName: 'Avenue N',
    lat: 40.615139,
    lon: -73.974198,
  },
  {
    stopId: 'F32',
    stopName: 'Bay Pkwy',
    lat: 40.620768,
    lon: -73.975265,
  },
  {
    stopId: 'F39',
    stopName: 'Neptune Av',
    lat: 40.581009,
    lon: -73.974577,
  },
  {
    stopId: 'F38',
    stopName: 'Avenue X',
    lat: 40.589619,
    lon: -73.974252,
  },
  {
    stopId: 'F36',
    stopName: 'Avenue U',
    lat: 40.596062,
    lon: -73.973358,
  },
  {
    stopId: 'F23',
    stopName: '4 Av-9 St',
    lat: 40.670271,
    lon: -73.989782,
  },
  {
    stopId: 'F22',
    stopName: 'Smith-9 Sts',
    lat: 40.673578,
    lon: -73.995962,
  },
  {
    stopId: 'F21',
    stopName: 'Carroll St',
    lat: 40.6803,
    lon: -73.99505,
  },
  {
    stopId: 'F20',
    stopName: 'Bergen St',
    lat: 40.686143,
    lon: -73.990862,
  },
  {
    stopId: 'F27',
    stopName: 'Church Av',
    lat: 40.644038,
    lon: -73.979681,
  },
  {
    stopId: 'F26',
    stopName: 'Fort Hamilton Pkwy',
    lat: 40.650779,
    lon: -73.975777,
  },
  {
    stopId: 'F25',
    stopName: '15 St-Prospect Park',
    lat: 40.660365,
    lon: -73.979495,
  },
  {
    stopId: 'F24',
    stopName: '7 Av',
    lat: 40.66627,
    lon: -73.980306,
  },
  {
    stopId: 'F29',
    stopName: 'Ditmas Av',
    lat: 40.636119,
    lon: -73.978172,
  },
  {
    stopId: 'F31',
    stopName: 'Avenue I',
    lat: 40.625319,
    lon: -73.97613,
  },
  {
    stopId: 'F30',
    stopName: '18 Av',
    lat: 40.629753,
    lon: -73.976975,
  },
  {
    stopId: 'F11',
    stopName: 'Lexington Av/53 St',
    lat: 40.757551,
    lon: -73.969056,
  },
  {
    stopId: 'F15',
    stopName: 'Delancey St-Essex St',
    lat: 40.718609,
    lon: -73.988116,
  },
  {
    stopId: 'F14',
    stopName: '2 Av',
    lat: 40.723399,
    lon: -73.989941,
  },
  {
    stopId: 'F12',
    stopName: '5 Av/53 St',
    lat: 40.760165,
    lon: -73.975228,
  },
  {
    stopId: 'F18',
    stopName: 'York St',
    lat: 40.701395,
    lon: -73.986754,
  },
  {
    stopId: 'F16',
    stopName: 'East Broadway',
    lat: 40.713712,
    lon: -73.990175,
  },
  {
    stopId: 'F03',
    stopName: 'Parsons Blvd',
    lat: 40.707563,
    lon: -73.803329,
  },
  {
    stopId: 'F02',
    stopName: '169 St',
    lat: 40.710468,
    lon: -73.793607,
  },
  {
    stopId: 'F01',
    stopName: 'Jamaica-179 St',
    lat: 40.712644,
    lon: -73.783817,
  },
  {
    stopId: 'F07',
    stopName: '75 Av',
    lat: 40.71833,
    lon: -73.837325,
  },
  {
    stopId: 'F06',
    stopName: 'Kew Gardens-Union Tpke',
    lat: 40.714439,
    lon: -73.83101,
  },
  {
    stopId: 'F05',
    stopName: 'Briarwood',
    lat: 40.709177,
    lon: -73.820575,
  },
  {
    stopId: 'F04',
    stopName: 'Sutphin Blvd',
    lat: 40.705459,
    lon: -73.810709,
  },
  {
    stopId: 'F09',
    stopName: 'Court Sq-23 St',
    lat: 40.747845,
    lon: -73.946003,
  },
  {
    stopId: 'S31',
    stopName: 'St George',
    lat: 40.643748,
    lon: -74.073644,
  },
  {
    stopId: 'S30',
    stopName: 'Tompkinsville',
    lat: 40.636946,
    lon: -74.074836,
  },
  {
    stopId: 'S20',
    stopName: 'Bay Terrace',
    lat: 40.556399,
    lon: -74.13691,
  },
  {
    stopId: 'S24',
    stopName: 'Jefferson Av',
    lat: 40.583591,
    lon: -74.103339,
  },
  {
    stopId: 'S23',
    stopName: 'Grant City',
    lat: 40.578962,
    lon: -74.109705,
  },
  {
    stopId: 'S22',
    stopName: 'New Dorp',
    lat: 40.573479,
    lon: -74.117212,
  },
  {
    stopId: 'S21',
    stopName: 'Oakwood Heights',
    lat: 40.565108,
    lon: -74.126321,
  },
  {
    stopId: 'S29',
    stopName: 'Stapleton',
    lat: 40.627915,
    lon: -74.075165,
  },
  {
    stopId: 'S28',
    stopName: 'Clifton',
    lat: 40.621319,
    lon: -74.071405,
  },
  {
    stopId: 'S27',
    stopName: 'Grasmere',
    lat: 40.603115,
    lon: -74.08409,
  },
  {
    stopId: 'S26',
    stopName: 'Old Town',
    lat: 40.59661,
    lon: -74.08737,
  },
  {
    stopId: 'S25',
    stopName: 'Dongan Hills',
    lat: 40.588846,
    lon: -74.096092,
  },
  {
    stopId: 'S11',
    stopName: 'Arthur Kill',
    lat: 40.516577,
    lon: -74.242098,
  },
  {
    stopId: 'S17',
    stopName: 'Annadale',
    lat: 40.540459,
    lon: -74.17822,
  },
  {
    stopId: 'S16',
    stopName: 'Huguenot',
    lat: 40.533674,
    lon: -74.191796,
  },
  {
    stopId: 'S15',
    stopName: "Prince's Bay",
    lat: 40.525505,
    lon: -74.200066,
  },
  {
    stopId: 'S14',
    stopName: 'Pleasant Plains',
    lat: 40.522409,
    lon: -74.217849,
  },
  {
    stopId: 'S13',
    stopName: 'Richmond Valley',
    lat: 40.51963,
    lon: -74.229142,
  },
  {
    stopId: 'S19',
    stopName: 'Great Kills',
    lat: 40.551231,
    lon: -74.151402,
  },
  {
    stopId: 'S18',
    stopName: 'Eltingville',
    lat: 40.544599,
    lon: -74.164573,
  },
  {
    stopId: 'S04',
    stopName: 'Botanic Garden',
    lat: 40.670342,
    lon: -73.959248,
  },
  {
    stopId: 'S03',
    stopName: 'Park Pl',
    lat: 40.67477,
    lon: -73.957625,
  },
  {
    stopId: 'S01',
    stopName: 'Franklin Av',
    lat: 40.680594,
    lon: -73.955833,
  },
  {
    stopId: 'S09',
    stopName: 'Tottenville',
    lat: 40.512763,
    lon: -74.251961,
  },
  {
    stopId: 'J31',
    stopName: 'Kosciuszko St',
    lat: 40.693341,
    lon: -73.928814,
  },
  {
    stopId: 'J30',
    stopName: 'Gates Av',
    lat: 40.689628,
    lon: -73.922273,
  },
  {
    stopId: '420',
    stopName: 'Bowling Green',
    lat: 40.704815,
    lon: -74.014067,
  },
  {
    stopId: 'J23',
    stopName: 'Van Siclen Av',
    lat: 40.678023,
    lon: -73.89169,
  },
  {
    stopId: '423',
    stopName: 'Borough Hall',
    lat: 40.692399,
    lon: -73.990155,
  },
  {
    stopId: 'J22',
    stopName: 'Cleveland St',
    lat: 40.679947,
    lon: -73.88464,
  },
  {
    stopId: 'J21',
    stopName: 'Norwood Av',
    lat: 40.681407,
    lon: -73.88004,
  },
  {
    stopId: 'J20',
    stopName: 'Crescent St',
    lat: 40.683191,
    lon: -73.873786,
  },
  {
    stopId: 'J27',
    stopName: 'Broadway Junction',
    lat: 40.679497,
    lon: -73.904514,
  },
  {
    stopId: 'J24',
    stopName: 'Alabama Av',
    lat: 40.67699,
    lon: -73.898654,
  },
  {
    stopId: 'J29',
    stopName: 'Halsey St',
    lat: 40.68637,
    lon: -73.91656,
  },
  {
    stopId: 'J28',
    stopName: 'Chauncey St',
    lat: 40.682893,
    lon: -73.910458,
  },
  {
    stopId: '640',
    stopName: 'Brooklyn Bridge-City Hall',
    lat: 40.713063,
    lon: -74.004131,
  },
  {
    stopId: '630',
    stopName: '51 St',
    lat: 40.757105,
    lon: -73.97192,
  },
  {
    stopId: '635',
    stopName: '14 St-Union Sq',
    lat: 40.734673,
    lon: -73.989953,
  },
  {
    stopId: '633',
    stopName: '28 St',
    lat: 40.743069,
    lon: -73.984265,
  },
  {
    stopId: '634',
    stopName: '23 St',
    lat: 40.739863,
    lon: -73.986602,
  },
  {
    stopId: '632',
    stopName: '33 St',
    lat: 40.746081,
    lon: -73.982079,
  },
  {
    stopId: '631',
    stopName: 'Grand Central-42 St',
    lat: 40.751775,
    lon: -73.976849,
  },
  {
    stopId: '639',
    stopName: 'Canal St',
    lat: 40.718801,
    lon: -74.000196,
  },
  {
    stopId: '638',
    stopName: 'Spring St',
    lat: 40.7223,
    lon: -73.997144,
  },
  {
    stopId: '637',
    stopName: 'Bleecker St',
    lat: 40.725915,
    lon: -73.994662,
  },
  {
    stopId: '636',
    stopName: 'Astor Pl',
    lat: 40.730053,
    lon: -73.991073,
  },
  {
    stopId: '622',
    stopName: '116 St',
    lat: 40.798628,
    lon: -73.941617,
  },
  {
    stopId: '623',
    stopName: '110 St',
    lat: 40.795019,
    lon: -73.94425,
  },
  {
    stopId: 'L21',
    stopName: 'Bushwick Av-Aberdeen St',
    lat: 40.682827,
    lon: -73.905252,
  },
  {
    stopId: '621',
    stopName: '125 St',
    lat: 40.804136,
    lon: -73.937595,
  },
  {
    stopId: 'L20',
    stopName: 'Wilson Av',
    lat: 40.688762,
    lon: -73.904046,
  },
  {
    stopId: '627',
    stopName: '77 St',
    lat: 40.773617,
    lon: -73.959875,
  },
  {
    stopId: 'L25',
    stopName: 'Sutter Av',
    lat: 40.669367,
    lon: -73.901977,
  },
  {
    stopId: '626',
    stopName: '86 St',
    lat: 40.77949,
    lon: -73.955591,
  },
  {
    stopId: 'L24',
    stopName: 'Atlantic Av',
    lat: 40.675343,
    lon: -73.903098,
  },
  {
    stopId: '625',
    stopName: '96 St',
    lat: 40.785672,
    lon: -73.951073,
  },
  {
    stopId: '624',
    stopName: '103 St',
    lat: 40.790599,
    lon: -73.947481,
  },
  {
    stopId: 'L22',
    stopName: 'Broadway Junction',
    lat: 40.678853,
    lon: -73.90324,
  },
  {
    stopId: 'L29',
    stopName: 'Canarsie-Rockaway Pkwy',
    lat: 40.646653,
    lon: -73.901851,
  },
  {
    stopId: 'L28',
    stopName: 'East 105 St',
    lat: 40.650571,
    lon: -73.899488,
  },
  {
    stopId: 'L27',
    stopName: 'New Lots Av',
    lat: 40.658732,
    lon: -73.899235,
  },
  {
    stopId: '629',
    stopName: '59 St',
    lat: 40.762525,
    lon: -73.96797,
  },
  {
    stopId: '628',
    stopName: '68 St-Hunter College',
    lat: 40.76814,
    lon: -73.963871,
  },
  {
    stopId: 'L26',
    stopName: 'Livonia Av',
    lat: 40.664037,
    lon: -73.900572,
  },
  {
    stopId: '610',
    stopName: 'Morrison Av-Soundview',
    lat: 40.829519,
    lon: -73.874516,
  },
  {
    stopId: '615',
    stopName: 'E 149 St',
    lat: 40.812116,
    lon: -73.904101,
  },
  {
    stopId: 'L13',
    stopName: 'Montrose Av',
    lat: 40.707739,
    lon: -73.93985,
  },
  {
    stopId: '614',
    stopName: 'Longwood Av',
    lat: 40.816103,
    lon: -73.896438,
  },
  {
    stopId: 'L12',
    stopName: 'Grand St',
    lat: 40.711926,
    lon: -73.940672,
  },
  {
    stopId: '613',
    stopName: 'Hunts Point Av',
    lat: 40.820947,
    lon: -73.890549,
  },
  {
    stopId: 'L11',
    stopName: 'Graham Av',
    lat: 40.714565,
    lon: -73.944054,
  },
  {
    stopId: '611',
    stopName: 'Elder Av',
    lat: 40.828582,
    lon: -73.879159,
  },
  {
    stopId: '612',
    stopName: 'Whitlock Av',
    lat: 40.826524,
    lon: -73.886285,
  },
  {
    stopId: 'L10',
    stopName: 'Lorimer St',
    lat: 40.71406,
    lon: -73.950276,
  },
  {
    stopId: 'L17',
    stopName: 'Myrtle-Wyckoff Avs',
    lat: 40.699814,
    lon: -73.911587,
  },
  {
    stopId: '619',
    stopName: '3 Av-138 St',
    lat: 40.810471,
    lon: -73.926142,
  },
  {
    stopId: 'L16',
    stopName: 'DeKalb Av',
    lat: 40.703809,
    lon: -73.918428,
  },
  {
    stopId: '618',
    stopName: 'Brook Av',
    lat: 40.807564,
    lon: -73.919241,
  },
  {
    stopId: '617',
    stopName: 'Cypress Av',
    lat: 40.805366,
    lon: -73.914043,
  },
  {
    stopId: 'L15',
    stopName: 'Jefferson St',
    lat: 40.706607,
    lon: -73.922917,
  },
  {
    stopId: '616',
    stopName: "E 143 St-St Mary's St",
    lat: 40.808718,
    lon: -73.907661,
  },
  {
    stopId: 'L14',
    stopName: 'Morgan Av',
    lat: 40.706149,
    lon: -73.93315,
  },
  {
    stopId: 'L19',
    stopName: 'Halsey St',
    lat: 40.695602,
    lon: -73.904085,
  },
  {
    stopId: '603',
    stopName: 'Middletown Rd',
    lat: 40.843862,
    lon: -73.836325,
  },
  {
    stopId: 'L01',
    stopName: '8 Av',
    lat: 40.739775,
    lon: -74.002578,
  },
  {
    stopId: '602',
    stopName: 'Buhre Av',
    lat: 40.846807,
    lon: -73.832572,
  },
  {
    stopId: '601',
    stopName: 'Pelham Bay Park',
    lat: 40.852461,
    lon: -73.828123,
  },
  {
    stopId: 'L05',
    stopName: '3 Av',
    lat: 40.732848,
    lon: -73.986124,
  },
  {
    stopId: '607',
    stopName: 'Castle Hill Av',
    lat: 40.834254,
    lon: -73.851224,
  },
  {
    stopId: '606',
    stopName: 'Zerega Av',
    lat: 40.836488,
    lon: -73.847038,
  },
  {
    stopId: 'L03',
    stopName: '14 St-Union Sq.',
    lat: 40.734788,
    lon: -73.990733,
  },
  {
    stopId: '604',
    stopName: 'Westchester Sq-E Tremont Av',
    lat: 40.839891,
    lon: -73.842952,
  },
  {
    stopId: 'L02',
    stopName: '6 Av',
    lat: 40.737334,
    lon: -73.996788,
  },
  {
    stopId: 'L08',
    stopName: 'Bedford Av',
    lat: 40.717297,
    lon: -73.956878,
  },
  {
    stopId: '609',
    stopName: 'St Lawrence Av',
    lat: 40.831508,
    lon: -73.867619,
  },
  {
    stopId: 'L06',
    stopName: '1 Av',
    lat: 40.730949,
    lon: -73.981629,
  },
  {
    stopId: '608',
    stopName: 'Parkchester',
    lat: 40.833224,
    lon: -73.860816,
  },
  {
    stopId: '142',
    stopName: 'South Ferry',
    lat: 40.702066,
    lon: -74.013666,
  },
  {
    stopId: 'G32',
    stopName: 'Myrtle-Willoughby Avs',
    lat: 40.694566,
    lon: -73.949047,
  },
  {
    stopId: '133',
    stopName: 'Christopher St-Sheridan Sq',
    lat: 40.73342,
    lon: -74.002906,
  },
  {
    stopId: 'G31',
    stopName: 'Flushing Av',
    lat: 40.700376,
    lon: -73.950236,
  },
  {
    stopId: '132',
    stopName: '14 St',
    lat: 40.737824,
    lon: -74.000203,
  },
  {
    stopId: 'G30',
    stopName: 'Broadway',
    lat: 40.706092,
    lon: -73.95031,
  },
  {
    stopId: '131',
    stopName: '18 St',
    lat: 40.741038,
    lon: -73.997874,
  },
  {
    stopId: '130',
    stopName: '23 St',
    lat: 40.74408,
    lon: -73.995659,
  },
  {
    stopId: '137',
    stopName: 'Chambers St',
    lat: 40.715477,
    lon: -74.009269,
  },
  {
    stopId: 'G36',
    stopName: 'Fulton St',
    lat: 40.687118,
    lon: -73.975378,
  },
  {
    stopId: '136',
    stopName: 'Franklin St',
    lat: 40.719316,
    lon: -74.006889,
  },
  {
    stopId: 'G35',
    stopName: 'Clinton-Washington Avs',
    lat: 40.688088,
    lon: -73.96684,
  },
  {
    stopId: '135',
    stopName: 'Canal St',
    lat: 40.722854,
    lon: -74.006277,
  },
  {
    stopId: 'G34',
    stopName: 'Classon Av',
    lat: 40.688872,
    lon: -73.960071,
  },
  {
    stopId: '134',
    stopName: 'Houston St',
    lat: 40.72825,
    lon: -74.00537,
  },
  {
    stopId: 'G33',
    stopName: 'Bedford-Nostrand Avs',
    lat: 40.689625,
    lon: -73.953523,
  },
  {
    stopId: '138',
    stopName: 'WTC Cortlandt',
    lat: 40.712063,
    lon: -74.010019,
  },
  {
    stopId: '139',
    stopName: 'Rector St',
    lat: 40.707506,
    lon: -74.013789,
  },
  {
    stopId: 'G20',
    stopName: '36 St',
    lat: 40.752038,
    lon: -73.928782,
  },
  {
    stopId: '121',
    stopName: '86 St',
    lat: 40.788642,
    lon: -73.976221,
  },
  {
    stopId: '120',
    stopName: '96 St',
    lat: 40.793917,
    lon: -73.972325,
  },
  {
    stopId: '125',
    stopName: '59 St-Columbus Circle',
    lat: 40.768247,
    lon: -73.981932,
  },
  {
    stopId: 'G24',
    stopName: '21 St',
    lat: 40.744064,
    lon: -73.949725,
  },
  {
    stopId: '124',
    stopName: '66 St-Lincoln Center',
    lat: 40.773439,
    lon: -73.982211,
  },
  {
    stopId: '123',
    stopName: '72 St',
    lat: 40.778451,
    lon: -73.981972,
  },
  {
    stopId: 'G22',
    stopName: 'Court Sq',
    lat: 40.746552,
    lon: -73.943833,
  },
  {
    stopId: 'G21',
    stopName: 'Queens Plaza',
    lat: 40.748971,
    lon: -73.937244,
  },
  {
    stopId: '122',
    stopName: '79 St',
    lat: 40.783932,
    lon: -73.97992,
  },
  {
    stopId: 'G28',
    stopName: 'Nassau Av',
    lat: 40.724632,
    lon: -73.951278,
  },
  {
    stopId: '129',
    stopName: '28 St',
    lat: 40.747215,
    lon: -73.993366,
  },
  {
    stopId: '127',
    stopName: 'Times Sq-42 St',
    lat: 40.755288,
    lon: -73.987497,
  },
  {
    stopId: '128',
    stopName: '34 St-Penn Station',
    lat: 40.750372,
    lon: -73.991066,
  },
  {
    stopId: 'G26',
    stopName: 'Greenpoint Av',
    lat: 40.731352,
    lon: -73.954455,
  },
  {
    stopId: '126',
    stopName: '50 St',
    lat: 40.761727,
    lon: -73.983853,
  },
  {
    stopId: 'G29',
    stopName: 'Metropolitan Av',
    lat: 40.712792,
    lon: -73.951418,
  },
  {
    stopId: '113',
    stopName: '157 St',
    lat: 40.83404,
    lon: -73.94489,
  },
  {
    stopId: 'G12',
    stopName: 'Grand Av-Newtown',
    lat: 40.737013,
    lon: -73.877224,
  },
  {
    stopId: '112',
    stopName: '168 St-Washington Hts',
    lat: 40.840554,
    lon: -73.940135,
  },
  {
    stopId: 'G11',
    stopName: 'Woodhaven Blvd',
    lat: 40.733105,
    lon: -73.869231,
  },
  {
    stopId: 'G10',
    stopName: '63 Dr-Rego Park',
    lat: 40.729844,
    lon: -73.861604,
  },
  {
    stopId: '111',
    stopName: '181 St',
    lat: 40.849504,
    lon: -73.933597,
  },
  {
    stopId: '110',
    stopName: '191 St',
    lat: 40.855223,
    lon: -73.929412,
  },
  {
    stopId: '118',
    stopName: 'Cathedral Pkwy (110 St)',
    lat: 40.803966,
    lon: -73.966849,
  },
  {
    stopId: 'G16',
    stopName: 'Northern Blvd',
    lat: 40.752885,
    lon: -73.906009,
  },
  {
    stopId: '116',
    stopName: '125 St',
    lat: 40.815579,
    lon: -73.958372,
  },
  {
    stopId: '117',
    stopName: '116 St-Columbia University',
    lat: 40.80772,
    lon: -73.964113,
  },
  {
    stopId: 'G15',
    stopName: '65 St',
    lat: 40.749668,
    lon: -73.898454,
  },
  {
    stopId: '115',
    stopName: '137 St-City College',
    lat: 40.822008,
    lon: -73.953678,
  },
  {
    stopId: 'G14',
    stopName: 'Jackson Hts-Roosevelt Av',
    lat: 40.746642,
    lon: -73.891341,
  },
  {
    stopId: '114',
    stopName: '145 St',
    lat: 40.826549,
    lon: -73.950362,
  },
  {
    stopId: 'G13',
    stopName: 'Elmhurst Av',
    lat: 40.742452,
    lon: -73.882019,
  },
  {
    stopId: 'G19',
    stopName: 'Steinway St',
    lat: 40.756877,
    lon: -73.920742,
  },
  {
    stopId: 'G18',
    stopName: '46 St',
    lat: 40.75631,
    lon: -73.913334,
  },
  {
    stopId: '119',
    stopName: '103 St',
    lat: 40.799445,
    lon: -73.968382,
  },
  {
    stopId: '101',
    stopName: 'Van Cortlandt Park-242 St',
    lat: 40.889246,
    lon: -73.898584,
  },
  {
    stopId: '106',
    stopName: 'Marble Hill-225 St',
    lat: 40.874561,
    lon: -73.909834,
  },
  {
    stopId: '104',
    stopName: '231 St',
    lat: 40.878855,
    lon: -73.904834,
  },
  {
    stopId: '103',
    stopName: '238 St',
    lat: 40.884667,
    lon: -73.900872,
  },
  {
    stopId: 'G08',
    stopName: 'Forest Hills-71 Av',
    lat: 40.721689,
    lon: -73.844524,
  },
  {
    stopId: '109',
    stopName: 'Dyckman St',
    lat: 40.860531,
    lon: -73.925537,
  },
  {
    stopId: 'G07',
    stopName: 'Jamaica-Van Wyck',
    lat: 40.702565,
    lon: -73.816861,
  },
  {
    stopId: '108',
    stopName: '207 St',
    lat: 40.864619,
    lon: -73.918824,
  },
  {
    stopId: 'G06',
    stopName: 'Sutphin Blvd-Archer Av-JFK Airport',
    lat: 40.700485,
    lon: -73.807971,
  },
  {
    stopId: '107',
    stopName: '215 St',
    lat: 40.869442,
    lon: -73.915281,
  },
  {
    stopId: 'G05',
    stopName: 'Jamaica Center-Parsons/Archer',
    lat: 40.702146,
    lon: -73.801112,
  },
  {
    stopId: 'G09',
    stopName: '67 Av',
    lat: 40.72652,
    lon: -73.852721,
  },
  {
    stopId: 'M19',
    stopName: 'Bowery',
    lat: 40.720278,
    lon: -73.993918,
  },
  {
    stopId: '724',
    stopName: '5 Av',
    lat: 40.753819,
    lon: -73.981965,
  },
  {
    stopId: 'M22',
    stopName: 'Fulton St',
    lat: 40.710372,
    lon: -74.007584,
  },
  {
    stopId: '723',
    stopName: 'Grand Central-42 St',
    lat: 40.75143,
    lon: -73.976047,
  },
  {
    stopId: 'M21',
    stopName: 'Chambers St',
    lat: 40.713236,
    lon: -74.003402,
  },
  {
    stopId: '721',
    stopName: 'Vernon Blvd-Jackson Av',
    lat: 40.742625,
    lon: -73.953583,
  },
  {
    stopId: 'M20',
    stopName: 'Canal St',
    lat: 40.718091,
    lon: -73.999895,
  },
  {
    stopId: '720',
    stopName: 'Hunters Point Av',
    lat: 40.742214,
    lon: -73.948919,
  },
  {
    stopId: '726',
    stopName: '34 St-Hudson Yards',
    lat: 40.75588,
    lon: -74.001912,
  },
  {
    stopId: '725',
    stopName: 'Times Sq-42 St',
    lat: 40.755477,
    lon: -73.987697,
  },
  {
    stopId: 'M23',
    stopName: 'Broad St',
    lat: 40.706475,
    lon: -74.011059,
  },
  {
    stopId: 'M09',
    stopName: 'Knickerbocker Av',
    lat: 40.698663,
    lon: -73.919711,
  },
  {
    stopId: 'M08',
    stopName: 'Myrtle-Wyckoff Avs',
    lat: 40.699427,
    lon: -73.912388,
  },
  {
    stopId: '709',
    stopName: '82 St-Jackson Hts',
    lat: 40.747659,
    lon: -73.883698,
  },
  {
    stopId: '712',
    stopName: 'Woodside-61 St',
    lat: 40.745628,
    lon: -73.902985,
  },
  {
    stopId: 'M10',
    stopName: 'Central Av',
    lat: 40.697854,
    lon: -73.9274,
  },
  {
    stopId: '710',
    stopName: '74 St-Broadway',
    lat: 40.746848,
    lon: -73.891396,
  },
  {
    stopId: '711',
    stopName: '69 St',
    lat: 40.746324,
    lon: -73.896407,
  },
  {
    stopId: '716',
    stopName: '33 St-Rawson St',
    lat: 40.744584,
    lon: -73.931,
  },
  {
    stopId: 'M14',
    stopName: 'Hewes St',
    lat: 40.70687,
    lon: -73.953433,
  },
  {
    stopId: '715',
    stopName: '40 St-Lowery St',
    lat: 40.743779,
    lon: -73.924016,
  },
  {
    stopId: 'M13',
    stopName: 'Lorimer St',
    lat: 40.703867,
    lon: -73.94741,
  },
  {
    stopId: '714',
    stopName: '46 St-Bliss St',
    lat: 40.743132,
    lon: -73.918438,
  },
  {
    stopId: 'M12',
    stopName: 'Flushing Av',
    lat: 40.700258,
    lon: -73.941129,
  },
  {
    stopId: '713',
    stopName: '52 St',
    lat: 40.744149,
    lon: -73.912553,
  },
  {
    stopId: 'M11',
    stopName: 'Myrtle Av',
    lat: 40.697205,
    lon: -73.935658,
  },
  {
    stopId: 'M18',
    stopName: 'Delancey St-Essex St',
    lat: 40.718313,
    lon: -73.98744,
  },
  {
    stopId: '719',
    stopName: 'Court Sq',
    lat: 40.74702,
    lon: -73.945266,
  },
  {
    stopId: 'M16',
    stopName: 'Marcy Av',
    lat: 40.708358,
    lon: -73.957761,
  },
  {
    stopId: '718',
    stopName: 'Queensboro Plaza',
    lat: 40.750577,
    lon: -73.940209,
  },
  {
    stopId: 'M01',
    stopName: 'Middle Village-Metropolitan Av',
    lat: 40.711394,
    lon: -73.889602,
  },
  {
    stopId: '702',
    stopName: 'Mets-Willets Point',
    lat: 40.754622,
    lon: -73.845626,
  },
  {
    stopId: '701',
    stopName: 'Flushing-Main St',
    lat: 40.759598,
    lon: -73.830031,
  },
  {
    stopId: 'M06',
    stopName: 'Seneca Av',
    lat: 40.70276,
    lon: -73.907741,
  },
  {
    stopId: '708',
    stopName: '90 St-Elmhurst Av',
    lat: 40.748407,
    lon: -73.876615,
  },
  {
    stopId: 'M05',
    stopName: 'Forest Av',
    lat: 40.704423,
    lon: -73.903078,
  },
  {
    stopId: '707',
    stopName: 'Junction Blvd',
    lat: 40.749144,
    lon: -73.869528,
  },
  {
    stopId: 'M04',
    stopName: 'Fresh Pond Rd',
    lat: 40.706185,
    lon: -73.89588,
  },
  {
    stopId: '706',
    stopName: '103 St-Corona Plaza',
    lat: 40.749865,
    lon: -73.862702,
  },
  {
    stopId: '705',
    stopName: '111 St',
    lat: 40.751728,
    lon: -73.855337,
  },
  {
    stopId: 'D39',
    stopName: 'Sheepshead Bay',
    lat: 40.586895,
    lon: -73.954155,
  },
  {
    stopId: 'D38',
    stopName: 'Neck Rd',
    lat: 40.595245,
    lon: -73.955163,
  },
  {
    stopId: 'D41',
    stopName: 'Ocean Pkwy',
    lat: 40.57631,
    lon: -73.968502,
  },
  {
    stopId: 'D40',
    stopName: 'Brighton Beach',
    lat: 40.57762,
    lon: -73.961379,
  },
  {
    stopId: 'D43',
    stopName: 'Coney Island-Stillwell Av',
    lat: 40.57742,
    lon: -73.981236,
  },
  {
    stopId: 'D42',
    stopName: 'W 8 St-NY Aquarium',
    lat: 40.576126,
    lon: -73.97594,
  },
  {
    stopId: 'D29',
    stopName: 'Beverley Rd',
    lat: 40.64403,
    lon: -73.964495,
  },
  {
    stopId: 'D28',
    stopName: 'Church Av',
    lat: 40.650525,
    lon: -73.962984,
  },
  {
    stopId: 'D27',
    stopName: 'Parkside Av',
    lat: 40.65529,
    lon: -73.961498,
  },
  {
    stopId: 'D26',
    stopName: 'Prospect Park',
    lat: 40.661612,
    lon: -73.962247,
  },
  {
    stopId: 'D33',
    stopName: 'Avenue J',
    lat: 40.625037,
    lon: -73.960804,
  },
  {
    stopId: 'D32',
    stopName: 'Avenue H',
    lat: 40.629268,
    lon: -73.96164,
  },
  {
    stopId: 'D31',
    stopName: 'Newkirk Plaza',
    lat: 40.63508,
    lon: -73.962796,
  },
  {
    stopId: 'D30',
    stopName: 'Cortelyou Rd',
    lat: 40.640925,
    lon: -73.963892,
  },
  {
    stopId: 'D37',
    stopName: 'Avenue U',
    lat: 40.599298,
    lon: -73.955932,
  },
  {
    stopId: 'D35',
    stopName: 'Kings Hwy',
    lat: 40.60867,
    lon: -73.957735,
  },
  {
    stopId: 'D34',
    stopName: 'Avenue M',
    lat: 40.617617,
    lon: -73.959403,
  },
  {
    stopId: 'D17',
    stopName: '34 St-Herald Sq',
    lat: 40.749717,
    lon: -73.987823,
  },
  {
    stopId: 'D16',
    stopName: '42 St-Bryant Pk',
    lat: 40.754222,
    lon: -73.984571,
  },
  {
    stopId: 'D15',
    stopName: '47-50 Sts-Rockefeller Ctr',
    lat: 40.758661,
    lon: -73.98133,
  },
  {
    stopId: 'D14',
    stopName: '7 Av',
    lat: 40.762862,
    lon: -73.981637,
  },
  {
    stopId: 'D19',
    stopName: '14 St',
    lat: 40.738227,
    lon: -73.996212,
  },
  {
    stopId: 'D18',
    stopName: '23 St',
    lat: 40.742877,
    lon: -73.992822,
  },
  {
    stopId: 'D21',
    stopName: 'Broadway-Lafayette St',
    lat: 40.725296,
    lon: -73.996207,
  },
  {
    stopId: 'D20',
    stopName: 'W 4 St-Wash Sq',
    lat: 40.732335,
    lon: -74.000498,
  },
  {
    stopId: 'D25',
    stopName: '7 Av',
    lat: 40.677048,
    lon: -73.972367,
  },
  {
    stopId: 'D24',
    stopName: 'Atlantic Av-Barclays Ctr',
    lat: 40.684457,
    lon: -73.976893,
  },
  {
    stopId: 'D22',
    stopName: 'Grand St',
    lat: 40.718267,
    lon: -73.993754,
  },
  {
    stopId: 'D05',
    stopName: 'Fordham Rd',
    lat: 40.861295,
    lon: -73.89775,
  },
  {
    stopId: 'D04',
    stopName: 'Kingsbridge Rd',
    lat: 40.866976,
    lon: -73.893511,
  },
  {
    stopId: 'D03',
    stopName: 'Bedford Park Blvd',
    lat: 40.873243,
    lon: -73.887139,
  },
  {
    stopId: 'D09',
    stopName: '170 St',
    lat: 40.839305,
    lon: -73.9134,
  },
  {
    stopId: 'D08',
    stopName: '174-175 Sts',
    lat: 40.845898,
    lon: -73.910136,
  },
  {
    stopId: 'D07',
    stopName: 'Tremont Av',
    lat: 40.850408,
    lon: -73.905229,
  },
  {
    stopId: 'D06',
    stopName: '182-183 Sts',
    lat: 40.856091,
    lon: -73.900742,
  },
  {
    stopId: 'D13',
    stopName: '145 St',
    lat: 40.824781,
    lon: -73.944216,
  },
  {
    stopId: 'D12',
    stopName: '155 St',
    lat: 40.830133,
    lon: -73.938211,
  },
  {
    stopId: 'D11',
    stopName: '161 St-Yankee Stadium',
    lat: 40.827905,
    lon: -73.925651,
  },
  {
    stopId: 'D10',
    stopName: '167 St',
    lat: 40.833769,
    lon: -73.918441,
  },
  {
    stopId: 'D01',
    stopName: 'Norwood-205 St',
    lat: 40.87481,
    lon: -73.878856,
  },
  {
    stopId: 'Q01',
    stopName: 'Canal St',
    lat: 40.718382,
    lon: -74.000462,
  },
  {
    stopId: 'Q05',
    stopName: '96 St',
    lat: 40.784316,
    lon: -73.947155,
  },
  {
    stopId: 'Q04',
    stopName: '86 St',
    lat: 40.77789,
    lon: -73.951788,
  },
  {
    stopId: 'Q03',
    stopName: '72 St',
    lat: 40.768797,
    lon: -73.958427,
  },
  {
    stopId: '254',
    stopName: 'Junius St',
    lat: 40.663513,
    lon: -73.902447,
  },
  {
    stopId: '253',
    stopName: 'Rockaway Av',
    lat: 40.662549,
    lon: -73.908946,
  },
  {
    stopId: '252',
    stopName: 'Saratoga Av',
    lat: 40.661453,
    lon: -73.916328,
  },
  {
    stopId: '251',
    stopName: 'Sutter Av-Rutland Rd',
    lat: 40.664716,
    lon: -73.922613,
  },
  {
    stopId: '257',
    stopName: 'New Lots Av',
    lat: 40.666234,
    lon: -73.88408,
  },
  {
    stopId: '256',
    stopName: 'Van Siclen Av',
    lat: 40.665448,
    lon: -73.889399,
  },
  {
    stopId: '255',
    stopName: 'Pennsylvania Av',
    lat: 40.664634,
    lon: -73.894898,
  },
  {
    stopId: '242',
    stopName: 'Sterling St',
    lat: 40.662741,
    lon: -73.950852,
  },
  {
    stopId: '241',
    stopName: 'President St-Medgar Evers College',
    lat: 40.667879,
    lon: -73.950688,
  },
  {
    stopId: '246',
    stopName: 'Newkirk Av - Little Haiti',
    lat: 40.639966,
    lon: -73.948413,
  },
  {
    stopId: '245',
    stopName: 'Beverly Rd',
    lat: 40.645096,
    lon: -73.948961,
  },
  {
    stopId: '244',
    stopName: 'Church Av',
    lat: 40.650842,
    lon: -73.949576,
  },
  {
    stopId: '243',
    stopName: 'Winthrop St',
    lat: 40.656652,
    lon: -73.950201,
  },
  {
    stopId: '248',
    stopName: 'Nostrand Av',
    lat: 40.669846,
    lon: -73.950469,
  },
  {
    stopId: '249',
    stopName: 'Kingston Av',
    lat: 40.669397,
    lon: -73.942162,
  },
  {
    stopId: '247',
    stopName: 'Flatbush Av-Brooklyn College',
    lat: 40.632833,
    lon: -73.947644,
  },
  {
    stopId: '250',
    stopName: 'Crown Hts-Utica Av',
    lat: 40.668895,
    lon: -73.932942,
  },
  {
    stopId: '230',
    stopName: 'Wall St',
    lat: 40.706821,
    lon: -74.009103,
  },
  {
    stopId: '234',
    stopName: 'Nevins St',
    lat: 40.688244,
    lon: -73.980494,
  },
  {
    stopId: '233',
    stopName: 'Hoyt St',
    lat: 40.690543,
    lon: -73.985067,
  },
  {
    stopId: '232',
    stopName: 'Borough Hall',
    lat: 40.693218,
    lon: -73.990003,
  },
  {
    stopId: '231',
    stopName: 'Clark St',
    lat: 40.697465,
    lon: -73.99309,
  },
  {
    stopId: '239',
    stopName: 'Franklin Av-Medgar Evers College',
    lat: 40.670682,
    lon: -73.958134,
  },
  {
    stopId: '237',
    stopName: 'Grand Army Plaza',
    lat: 40.675234,
    lon: -73.971048,
  },
  {
    stopId: '238',
    stopName: 'Eastern Pkwy-Brooklyn Museum',
    lat: 40.671984,
    lon: -73.964377,
  },
  {
    stopId: '236',
    stopName: 'Bergen St',
    lat: 40.680826,
    lon: -73.975101,
  },
  {
    stopId: '235',
    stopName: 'Atlantic Av-Barclays Ctr',
    lat: 40.684359,
    lon: -73.977668,
  },
  {
    stopId: '222',
    stopName: '149 St-Grand Concourse',
    lat: 40.81841,
    lon: -73.926721,
  },
  {
    stopId: '221',
    stopName: '3 Av-149 St',
    lat: 40.816108,
    lon: -73.917757,
  },
  {
    stopId: '220',
    stopName: 'Jackson Av',
    lat: 40.816489,
    lon: -73.907809,
  },
  {
    stopId: '226',
    stopName: '116 St',
    lat: 40.802097,
    lon: -73.949625,
  },
  {
    stopId: '227',
    stopName: 'Central Park North (110 St)',
    lat: 40.799075,
    lon: -73.951823,
  },
  {
    stopId: '225',
    stopName: '125 St',
    lat: 40.807753,
    lon: -73.945497,
  },
  {
    stopId: '224',
    stopName: '135 St',
    lat: 40.814228,
    lon: -73.940773,
  },
  {
    stopId: '229',
    stopName: 'Fulton St',
    lat: 40.709416,
    lon: -74.006574,
  },
  {
    stopId: '228',
    stopName: 'Park Place',
    lat: 40.713049,
    lon: -74.008813,
  },
  {
    stopId: '210',
    stopName: 'Allerton Av',
    lat: 40.86546,
    lon: -73.867354,
  },
  {
    stopId: '214',
    stopName: 'West Farms Sq-E Tremont Av',
    lat: 40.840294,
    lon: -73.880051,
  },
  {
    stopId: 'H13',
    stopName: 'Beach 98 St',
    lat: 40.585306,
    lon: -73.820559,
  },
  {
    stopId: '213',
    stopName: 'E 180 St',
    lat: 40.841891,
    lon: -73.873488,
  },
  {
    stopId: 'H12',
    stopName: 'Beach 90 St',
    lat: 40.588033,
    lon: -73.813641,
  },
  {
    stopId: '212',
    stopName: 'Bronx Park East',
    lat: 40.848827,
    lon: -73.868458,
  },
  {
    stopId: 'H11',
    stopName: 'Far Rockaway-Mott Av',
    lat: 40.603995,
    lon: -73.755406,
  },
  {
    stopId: '211',
    stopName: 'Pelham Pkwy',
    lat: 40.85719,
    lon: -73.867617,
  },
  {
    stopId: 'H10',
    stopName: 'Beach 25 St',
    lat: 40.600065,
    lon: -73.761356,
  },
  {
    stopId: '219',
    stopName: 'Prospect Av',
    lat: 40.819583,
    lon: -73.901773,
  },
  {
    stopId: '218',
    stopName: 'Intervale Av',
    lat: 40.822181,
    lon: -73.896737,
  },
  {
    stopId: '217',
    stopName: 'Simpson St',
    lat: 40.824071,
    lon: -73.893065,
  },
  {
    stopId: 'H15',
    stopName: 'Rockaway Park-Beach 116 St',
    lat: 40.580902,
    lon: -73.835593,
  },
  {
    stopId: '215',
    stopName: '174 St',
    lat: 40.837288,
    lon: -73.887736,
  },
  {
    stopId: '216',
    stopName: 'Freeman St',
    lat: 40.82999,
    lon: -73.891867,
  },
  {
    stopId: 'H14',
    stopName: 'Beach 105 St',
    lat: 40.583207,
    lon: -73.82756,
  },
  {
    stopId: 'H01',
    stopName: 'Aqueduct Racetrack',
    lat: 40.668232,
    lon: -73.834059,
  },
  {
    stopId: '201',
    stopName: 'Wakefield-241 St',
    lat: 40.903123,
    lon: -73.850622,
  },
  {
    stopId: '207',
    stopName: '219 St',
    lat: 40.883894,
    lon: -73.862634,
  },
  {
    stopId: '206',
    stopName: '225 St',
    lat: 40.888021,
    lon: -73.860344,
  },
  {
    stopId: 'H04',
    stopName: 'Broad Channel',
    lat: 40.608382,
    lon: -73.815927,
  },
  {
    stopId: '204',
    stopName: 'Nereid Av',
    lat: 40.898377,
    lon: -73.854377,
  },
  {
    stopId: '205',
    stopName: '233 St',
    lat: 40.893192,
    lon: -73.857475,
  },
  {
    stopId: 'H03',
    stopName: 'Howard Beach-JFK Airport',
    lat: 40.660474,
    lon: -73.830304,
  },
  {
    stopId: 'H02',
    stopName: 'Aqueduct-N Conduit Av',
    lat: 40.668232,
    lon: -73.834059,
  },
  {
    stopId: 'H09',
    stopName: 'Beach 36 St',
    lat: 40.595396,
    lon: -73.768177,
  },
  {
    stopId: 'H08',
    stopName: 'Beach 44 St',
    lat: 40.59294,
    lon: -73.776017,
  },
  {
    stopId: '209',
    stopName: 'Burke Av',
    lat: 40.871355,
    lon: -73.867165,
  },
  {
    stopId: 'H07',
    stopName: 'Beach 60 St',
    lat: 40.592373,
    lon: -73.788523,
  },
  {
    stopId: '208',
    stopName: 'Gun Hill Rd',
    lat: 40.877849,
    lon: -73.866259,
  },
  {
    stopId: 'H06',
    stopName: 'Beach 67 St',
    lat: 40.590926,
    lon: -73.796926,
  },
  {
    stopId: '412',
    stopName: '170 St',
    lat: 40.840075,
    lon: -73.917793,
  },
  {
    stopId: '411',
    stopName: 'Mt Eden Av',
    lat: 40.844432,
    lon: -73.914686,
  },
  {
    stopId: '410',
    stopName: '176 St',
    lat: 40.848479,
    lon: -73.911797,
  },
  {
    stopId: 'J15',
    stopName: 'Woodhaven Blvd',
    lat: 40.693878,
    lon: -73.851579,
  },
  {
    stopId: '416',
    stopName: '138 St-Grand Concourse',
    lat: 40.813223,
    lon: -73.929849,
  },
  {
    stopId: 'J14',
    stopName: '104 St',
    lat: 40.695177,
    lon: -73.84433,
  },
  {
    stopId: '415',
    stopName: '149 St-Grand Concourse',
    lat: 40.818374,
    lon: -73.927352,
  },
  {
    stopId: 'J13',
    stopName: '111 St',
    lat: 40.697413,
    lon: -73.83635,
  },
  {
    stopId: '413',
    stopName: '167 St',
    lat: 40.835537,
    lon: -73.921403,
  },
  {
    stopId: '414',
    stopName: '161 St-Yankee Stadium',
    lat: 40.827993,
    lon: -73.925834,
  },
  {
    stopId: 'J12',
    stopName: '121 St',
    lat: 40.700491,
    lon: -73.828295,
  },
  {
    stopId: 'J19',
    stopName: 'Cypress Hills',
    lat: 40.689941,
    lon: -73.872551,
  },
  {
    stopId: '419',
    stopName: 'Wall St',
    lat: 40.707555,
    lon: -74.011865,
  },
  {
    stopId: 'J17',
    stopName: '75 St-Elderts Ln',
    lat: 40.691322,
    lon: -73.867141,
  },
  {
    stopId: '418',
    stopName: 'Fulton St',
    lat: 40.710367,
    lon: -74.009511,
  },
  {
    stopId: 'J16',
    stopName: '85 St-Forest Pkwy',
    lat: 40.692434,
    lon: -73.860012,
  },
  {
    stopId: 'A61',
    stopName: 'Rockaway Blvd',
    lat: 40.680426,
    lon: -73.843855,
  },
  {
    stopId: 'A60',
    stopName: '88 St',
    lat: 40.679843,
    lon: -73.851471,
  },
  {
    stopId: 'A65',
    stopName: 'Ozone Park-Lefferts Blvd',
    lat: 40.685951,
    lon: -73.8258,
  },
  {
    stopId: 'A64',
    stopName: '111 St',
    lat: 40.684328,
    lon: -73.832165,
  },
  {
    stopId: 'A63',
    stopName: '104 St',
    lat: 40.681709,
    lon: -73.837685,
  },
  {
    stopId: '405',
    stopName: 'Bedford Park Blvd-Lehman College',
    lat: 40.87341,
    lon: -73.890065,
  },
  {
    stopId: '402',
    stopName: 'Mosholu Pkwy',
    lat: 40.879748,
    lon: -73.884655,
  },
  {
    stopId: '401',
    stopName: 'Woodlawn',
    lat: 40.886034,
    lon: -73.878752,
  },
  {
    stopId: '409',
    stopName: 'Burnside Av',
    lat: 40.853453,
    lon: -73.907686,
  },
  {
    stopId: '408',
    stopName: '183 St',
    lat: 40.858407,
    lon: -73.90388,
  },
  {
    stopId: '407',
    stopName: 'Fordham Rd',
    lat: 40.862802,
    lon: -73.901035,
  },
  {
    stopId: '406',
    stopName: 'Kingsbridge Rd',
    lat: 40.86776,
    lon: -73.897177,
  },
  {
    stopId: 'A50',
    stopName: 'Rockaway Av',
    lat: 40.678338,
    lon: -73.911949,
  },
  {
    stopId: 'A54',
    stopName: 'Shepherd Av',
    lat: 40.674129,
    lon: -73.880751,
  },
  {
    stopId: 'A53',
    stopName: 'Van Siclen Av',
    lat: 40.67271,
    lon: -73.890362,
  },
  {
    stopId: 'A52',
    stopName: 'Liberty Av',
    lat: 40.67454,
    lon: -73.896548,
  },
  {
    stopId: 'A51',
    stopName: 'Broadway Junction',
    lat: 40.678333,
    lon: -73.905322,
  },
  {
    stopId: 'A57',
    stopName: 'Grant Av',
    lat: 40.677042,
    lon: -73.86505,
  },
  {
    stopId: 'A55',
    stopName: 'Euclid Av',
    lat: 40.675376,
    lon: -73.872107,
  },
  {
    stopId: 'A59',
    stopName: '80 St',
    lat: 40.679369,
    lon: -73.858994,
  },
  {
    stopId: 'A42',
    stopName: 'Hoyt-Schermerhorn Sts',
    lat: 40.688483,
    lon: -73.985001,
  },
  {
    stopId: 'A41',
    stopName: 'Jay St-MetroTech',
    lat: 40.692336,
    lon: -73.987344,
  },
  {
    stopId: 'A40',
    stopName: 'High St',
    lat: 40.699337,
    lon: -73.990534,
  },
  {
    stopId: 'A46',
    stopName: 'Nostrand Av',
    lat: 40.680435,
    lon: -73.950426,
  },
  {
    stopId: 'A45',
    stopName: 'Franklin Av',
    lat: 40.68138,
    lon: -73.956848,
  },
  {
    stopId: 'A44',
    stopName: 'Clinton-Washington Avs',
    lat: 40.683263,
    lon: -73.965841,
  },
  {
    stopId: 'A43',
    stopName: 'Lafayette Av',
    lat: 40.686112,
    lon: -73.973947,
  },
  {
    stopId: 'A49',
    stopName: 'Ralph Av',
    lat: 40.678821,
    lon: -73.920787,
  },
  {
    stopId: 'A48',
    stopName: 'Utica Av',
    lat: 40.679363,
    lon: -73.930731,
  },
  {
    stopId: 'A47',
    stopName: 'Kingston-Throop Avs',
    lat: 40.679919,
    lon: -73.940858,
  },
  {
    stopId: 'A30',
    stopName: '23 St',
    lat: 40.745905,
    lon: -73.998043,
  },
  {
    stopId: 'A34',
    stopName: 'Canal St',
    lat: 40.720823,
    lon: -74.005231,
  },
  {
    stopId: 'A33',
    stopName: 'Spring St',
    lat: 40.726225,
    lon: -74.003741,
  },
  {
    stopId: 'A32',
    stopName: 'W 4 St-Wash Sq',
    lat: 40.732335,
    lon: -74.000498,
  },
  {
    stopId: 'A31',
    stopName: '14 St',
    lat: 40.74089,
    lon: -74.001691,
  },
  {
    stopId: 'A38',
    stopName: 'Fulton St',
    lat: 40.710197,
    lon: -74.007695,
  },
  {
    stopId: 'A36',
    stopName: 'Chambers St',
    lat: 40.71411,
    lon: -74.008586,
  },
  {
    stopId: 'A22',
    stopName: '72 St',
    lat: 40.775593,
    lon: -73.976413,
  },
  {
    stopId: 'A21',
    stopName: '81 St-Museum of Natural History',
    lat: 40.781433,
    lon: -73.972145,
  },
  {
    stopId: 'A20',
    stopName: '86 St',
    lat: 40.785867,
    lon: -73.968916,
  },
  {
    stopId: 'A25',
    stopName: '50 St',
    lat: 40.762454,
    lon: -73.985984,
  },
  {
    stopId: 'A24',
    stopName: '59 St-Columbus Circle',
    lat: 40.768296,
    lon: -73.981739,
  },
  {
    stopId: 'A28',
    stopName: '34 St-Penn Station',
    lat: 40.752284,
    lon: -73.993391,
  },
  {
    stopId: 'A27',
    stopName: '42 St-Port Authority Bus Terminal',
    lat: 40.757307,
    lon: -73.989738,
  },
  {
    stopId: 'A10',
    stopName: '163 St-Amsterdam Av',
    lat: 40.836011,
    lon: -73.939893,
  },
  {
    stopId: 'A14',
    stopName: '135 St',
    lat: 40.817892,
    lon: -73.94765,
  },
  {
    stopId: 'A12',
    stopName: '145 St',
    lat: 40.824781,
    lon: -73.944216,
  },
  {
    stopId: 'A11',
    stopName: '155 St',
    lat: 40.830516,
    lon: -73.941515,
  },
  {
    stopId: 'A18',
    stopName: '103 St',
    lat: 40.79609,
    lon: -73.961457,
  },
  {
    stopId: 'A17',
    stopName: 'Cathedral Pkwy (110 St)',
    lat: 40.800601,
    lon: -73.958161,
  },
  {
    stopId: 'A16',
    stopName: '116 St',
    lat: 40.805084,
    lon: -73.954883,
  },
  {
    stopId: 'A15',
    stopName: '125 St',
    lat: 40.811107,
    lon: -73.952346,
  },
  {
    stopId: 'A19',
    stopName: '96 St',
    lat: 40.79164,
    lon: -73.964698,
  },
  {
    stopId: 'A02',
    stopName: 'Inwood-207 St',
    lat: 40.868072,
    lon: -73.9199,
  },
  {
    stopId: 'A06',
    stopName: '181 St',
    lat: 40.851693,
    lon: -73.93797,
  },
  {
    stopId: 'A05',
    stopName: '190 St',
    lat: 40.859021,
    lon: -73.934183,
  },
  {
    stopId: 'A03',
    stopName: 'Dyckman St',
    lat: 40.865491,
    lon: -73.927272,
  },
  {
    stopId: 'A09',
    stopName: '168 St',
    lat: 40.840719,
    lon: -73.939564,
  },
  {
    stopId: 'A07',
    stopName: '175 St',
    lat: 40.847388,
    lon: -73.939706,
  },
  {
    stopId: 'N07',
    stopName: 'Bay Pkwy',
    lat: 40.611813,
    lon: -73.98185,
  },
  {
    stopId: 'N06',
    stopName: '20 Av',
    lat: 40.617408,
    lon: -73.985029,
  },
  {
    stopId: 'N05',
    stopName: '18 Av',
    lat: 40.620667,
    lon: -73.990418,
  },
  {
    stopId: 'N04',
    stopName: 'New Utrecht Av',
    lat: 40.62484,
    lon: -73.996358,
  },
  {
    stopId: 'N09',
    stopName: 'Avenue U',
    lat: 40.597473,
    lon: -73.979138,
  },
  {
    stopId: 'N08',
    stopName: 'Kings Hwy',
    lat: 40.603921,
    lon: -73.980356,
  },
  {
    stopId: 'N10',
    stopName: '86 St',
    lat: 40.592718,
    lon: -73.978233,
  },
  {
    stopId: 'N03',
    stopName: 'Fort Hamilton Pkwy',
    lat: 40.631384,
    lon: -74.005354,
  },
  {
    stopId: 'N02',
    stopName: '8 Av',
    lat: 40.635064,
    lon: -74.011725,
  },
  {
    stopId: 'E01',
    stopName: 'World Trade Center',
    lat: 40.712581,
    lon: -74.009781,
  },
  {
    stopId: 'R43',
    stopName: '77 St',
    lat: 40.62974,
    lon: -74.025513,
  },
  {
    stopId: 'R42',
    stopName: 'Bay Ridge Av',
    lat: 40.634965,
    lon: -74.023379,
  },
  {
    stopId: 'R41',
    stopName: '59 St',
    lat: 40.641356,
    lon: -74.017889,
  },
  {
    stopId: 'R40',
    stopName: '53 St',
    lat: 40.645069,
    lon: -74.014037,
  },
  {
    stopId: 'R45',
    stopName: 'Bay Ridge-95 St',
    lat: 40.616622,
    lon: -74.030876,
  },
  {
    stopId: 'R44',
    stopName: '86 St',
    lat: 40.622683,
    lon: -74.028404,
  },
  {
    stopId: 'R31',
    stopName: 'Atlantic Av-Barclays Ctr',
    lat: 40.683665,
    lon: -73.978812,
  },
  {
    stopId: 'R30',
    stopName: 'DeKalb Av',
    lat: 40.690634,
    lon: -73.981827,
  },
  {
    stopId: 'R35',
    stopName: '25 St',
    lat: 40.660395,
    lon: -73.998091,
  },
  {
    stopId: 'R34',
    stopName: 'Prospect Av',
    lat: 40.665412,
    lon: -73.992875,
  },
  {
    stopId: 'R33',
    stopName: '4 Av-9 St',
    lat: 40.670846,
    lon: -73.988303,
  },
  {
    stopId: 'R32',
    stopName: 'Union St',
    lat: 40.677313,
    lon: -73.983113,
  },
  {
    stopId: 'R39',
    stopName: '45 St',
    lat: 40.648938,
    lon: -74.010009,
  },
  {
    stopId: 'R36',
    stopName: '36 St',
    lat: 40.655142,
    lon: -74.003551,
  },
  {
    stopId: 'R23',
    stopName: 'Canal St',
    lat: 40.719525,
    lon: -74.001777,
  },
  {
    stopId: 'R22',
    stopName: 'Prince St',
    lat: 40.724328,
    lon: -73.997703,
  },
  {
    stopId: 'R21',
    stopName: '8 St-NYU',
    lat: 40.730327,
    lon: -73.992631,
  },
  {
    stopId: 'R20',
    stopName: '14 St-Union Sq',
    lat: 40.735736,
    lon: -73.990568,
  },
  {
    stopId: 'R28',
    stopName: 'Court St',
    lat: 40.694098,
    lon: -73.99178,
  },
  {
    stopId: 'R27',
    stopName: 'Whitehall St-South Ferry',
    lat: 40.703086,
    lon: -74.012995,
  },
  {
    stopId: 'R26',
    stopName: 'Rector St',
    lat: 40.707218,
    lon: -74.013345,
  },
  {
    stopId: 'R25',
    stopName: 'Cortlandt St',
    lat: 40.710668,
    lon: -74.011032,
  },
  {
    stopId: 'R24',
    stopName: 'City Hall',
    lat: 40.713279,
    lon: -74.006981,
  },
  {
    stopId: 'R29',
    stopName: 'Jay St-MetroTech',
    lat: 40.692179,
    lon: -73.985944,
  },
  {
    stopId: 'R11',
    stopName: 'Lexington Av/59 St',
    lat: 40.762659,
    lon: -73.967259,
  },
  {
    stopId: 'R16',
    stopName: 'Times Sq-42 St',
    lat: 40.754669,
    lon: -73.986764,
  },
  {
    stopId: 'R15',
    stopName: '49 St',
    lat: 40.7599,
    lon: -73.984142,
  },
  {
    stopId: 'R14',
    stopName: '57 St-7 Av',
    lat: 40.764662,
    lon: -73.980661,
  },
  {
    stopId: 'R13',
    stopName: '5 Av/59 St',
    lat: 40.76481,
    lon: -73.97335,
  },
  {
    stopId: 'R19',
    stopName: '23 St',
    lat: 40.741301,
    lon: -73.989346,
  },
  {
    stopId: 'R18',
    stopName: '28 St',
    lat: 40.745491,
    lon: -73.988691,
  },
  {
    stopId: 'R17',
    stopName: '34 St-Herald Sq',
    lat: 40.749566,
    lon: -73.987952,
  },
  {
    stopId: 'R03',
    stopName: 'Astoria Blvd',
    lat: 40.770258,
    lon: -73.917845,
  },
  {
    stopId: 'R01',
    stopName: 'Astoria-Ditmars Blvd',
    lat: 40.775034,
    lon: -73.912036,
  },
  {
    stopId: 'R08',
    stopName: '39 Av-Dutch Kills',
    lat: 40.752882,
    lon: -73.932756,
  },
  {
    stopId: 'R06',
    stopName: '36 Av',
    lat: 40.756803,
    lon: -73.929577,
  },
  {
    stopId: 'R05',
    stopName: 'Broadway',
    lat: 40.761818,
    lon: -73.92551,
  },
  {
    stopId: 'R04',
    stopName: '30 Av',
    lat: 40.766778,
    lon: -73.921482,
  },
  {
    stopId: 'R09',
    stopName: 'Queensboro Plaza',
    lat: 40.75058,
    lon: -73.940205,
  },
  {
    stopId: '302',
    stopName: '145 St',
    lat: 40.820419,
    lon: -73.936248,
  },
  {
    stopId: '301',
    stopName: 'Harlem-148 St',
    lat: 40.82388,
    lon: -73.936472,
  },
];

export default otpStops;
