import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from '../models';
import Tray, { TrayPosition } from '../components/Tray';
import EmergencyAlertContent from '../components/EmergencyAlertContent';
import { isPhone } from '../utils/deviceDetector.utils';
import { EmergencyAlert } from '../subway-data/otp/emergency-alerts-types';

interface EmergencyAlertContainerProps {
  currentTrayPosition: TrayPosition;
  emergencyAlert: EmergencyAlert;
  emergencyAlertOpened: boolean;
  setCurrentTrayPosition: (value: TrayPosition) => void;
  setEmergencyAlertDismissed: (emergencyAlertDismissed: boolean) => void;
  setEmergencyAlertOpened: (value: boolean) => void;
  setVaccineLocationsVisible: (value: boolean) => void;
}
const EmergencyAlertContainer: React.FC<EmergencyAlertContainerProps> = ({
  currentTrayPosition,
  emergencyAlert,
  emergencyAlertOpened,
  setCurrentTrayPosition,
  setEmergencyAlertDismissed,
  setEmergencyAlertOpened,
  setVaccineLocationsVisible,
}) => {
  const trayPositionOnOpen =
    emergencyAlert?.priority === 'emergency'
      ? TrayPosition.twoThirds
      : TrayPosition.small;

  const onCloseCallback = useCallback(() => {
    setEmergencyAlertOpened(false);
    setEmergencyAlertDismissed(true);
  }, [setEmergencyAlertDismissed, setEmergencyAlertOpened]);

  return isPhone() ? (
    <Tray
      currentTrayPosition={currentTrayPosition}
      closeOnSwipe={true}
      defaultPosition={trayPositionOnOpen}
      isOpen={emergencyAlertOpened}
      onCloseCallback={onCloseCallback}
      setCurrentTrayPosition={setCurrentTrayPosition}
    >
      <EmergencyAlertContent
        containerType="Tray"
        emergencyAlertContent={emergencyAlert}
        isSmallPosition={
          emergencyAlertOpened && currentTrayPosition === TrayPosition.small
        }
        onCloseCallback={onCloseCallback}
        setVaccineLocationsVisible={setVaccineLocationsVisible}
      />
    </Tray>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  currentTrayPosition: state.ui.currentTrayPosition,
  emergencyAlert: state.map.emergencyAlert,
  emergencyAlertOpened: state.ui.emergencyAlertOpened,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setCurrentTrayPosition: dispatch.ui.setCurrentTrayPosition,
  setEmergencyAlertDismissed: dispatch.ui.setEmergencyAlertDismissed,
  setEmergencyAlertOpened: dispatch.ui.setEmergencyAlertOpened,
  setVaccineLocationsVisible: dispatch.map.setVaccineLocationsVisible,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmergencyAlertContainer);
