import { css, keyframes } from 'styled-components/macro';
import { isPhone } from './deviceDetector.utils';

export const IS_TOUCH =
  'ontouchstart' in window || window!.navigator['msMaxTouchPoints'] > 0;

const tilesDuration = '2s';
const tilesEase = 'linear';

const tilesDarkFilters = {
  twilight: 'invert(.5) grayscale(.5) brightness(1)',
  dark: 'invert(1) grayscale(1) brightness(2)',
};

const sunrise = keyframes`
  0% {
    filter: ${tilesDarkFilters.dark};
  }

  40% {
    filter: ${tilesDarkFilters.twilight};
  }

  // Without this extra keyframe, Safari doesn't clear the filters
  // but leaves them at their default values, decreasing frame rate.
  99.99% {
    filter: none;
  }

  100% {
    filter: none;
  }
`;

const sunset = keyframes`
  0% {
    filter: none;
  }

  40% {
    filter: ${tilesDarkFilters.twilight};
  }

  100% {
    filter: ${tilesDarkFilters.dark};
  }
`;

const theme = {
  media: {
    small: 460,
    desktopLargeMin: 640,
    mobileLandscapeMax: 1024,
    extraLargeMin: 1680,
  },
  fonts: {
    default: 'Helvetica Neue, OpenSans, Arial, sans-serif',
  },
  sizes: {
    landscapeContent: '640px',
    panelDesktopLarge: '240px',
    panelDesktopExtraLarge: '300px',
  },
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.2s ease-in-out',
  modalTransition: 'opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1)',
  margin: '20px',
  marginBottomExtra: '35px',
  colors: {
    black: '#000000',
    gray: '#dddddd',
    gray7B: '#7b7b7b',
    gray7F: '#7f7f7f',
    grayD8: '#d8d8d8',
    grayF7: '#f7f7f7',
    white: '#ffffff',
    yellow: '#ffd700',
    lightGray: '#e5e5e5',
    textGray: '#757575',
    blue: '#0039A6',
    red: '#CD493C',
    orange: '#BF581E',
    button: {
      bg: '#fff',
      bgActive: '#000',
      color: '#000',
      colorActive: '#fff',
    },
    focus: {
      color: 'rgba(1, 1, 1, 0)',
      colorActive: 'rgba(1, 1, 1, 1)',
    },
  },
  zIndex: {
    serviceStatusDesktop: 3,
    controls: 4,
    routeMenu: 5,
    modals: 90,
    spinner: 95,
    serviceStatusMobile: 100,
  },
  transitionDelay: {
    trayAnimate: '400ms',
  },
  tilesAnimation: css`
    ${sunrise} ${tilesDuration} ${tilesEase} forwards
  `,
};

// TODO
// Create a better colors object for the theme and review the UI to use the theme colors
export const darkTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    button: {
      bg: '#fff',
      bgActive: '#888',
      color: '#000',
      colorActive: '#fff',
    },
    focus: {
      color: 'rgba(255, 255, 255, 0)',
      colorActive: 'rgba(255, 255, 255, 1)',
    },
  },
  tilesAnimation: css`
    ${sunset} ${tilesDuration} ${tilesEase} forwards
  `,
};

export const tablet = inner => {
  if (!isPhone()) {
    return css`
      @media screen and (min-width: ${theme.media.desktopLargeMin}px) {
        ${inner};
      }
    `;
  }

  return null;
};

export const desktop = inner => css`
  @media (min-width: ${theme.media.mobileLandscapeMax}px) {
    ${inner};
  }
`;

export const desktopLarge = inner => {
  return notTouch(css`
    @media (min-width: ${theme.media.desktopLargeMin}px) {
      ${inner};
    }
  `);
};

export const desktopLargeOnly = inner => {
  return notTouch(css`
    @media (min-width: ${theme.media.desktopLargeMin}px) and (max-width: ${theme
        .media.extraLargeMin - 1}px) {
      ${inner};
    }
  `);
};

export const desktopExtraLarge = inner => {
  return notTouch(css`
    @media (min-width: ${theme.media.extraLargeMin}px) {
      ${inner};
    }
  `);
};

export const notTouch = inner => {
  if (!IS_TOUCH) {
    return css`
      ${inner}
    `;
  }

  return null;
};

export const touch = inner => {
  if (IS_TOUCH) {
    return css`
      ${inner}
    `;
  }

  return null;
};

export const landscapePhone = inner => {
  return touch(css`
    @media screen and (orientation: landscape) and (max-height: 500px) {
      ${inner};
    }
  `);
};

export const withFocusStyle = (theme, radius = 50) => css`
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 7px);
    height: calc(100% + 7px);
    border: 1px dashed ${theme.colors.focus.color};
    border-radius: ${radius}%;
    transform: translate(-50%, -50%);
    transition: ${theme.transition};
  }

  &:focus.focus-visible {
    &::before {
      border-color: ${theme.colors.focus.colorActive};
    }
  }
`;

export const withTouchPressStyle = (otherTransition?: string) => css`
  transform: scale(1);
  transition: transform 0.15s ease-in-out
    ${otherTransition && `, ${otherTransition}`};

  &:active {
    transform: scale(0.92);
  }
`;

export default theme;
