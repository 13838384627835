import {
  createNodeLinksListFromDag,
  createPatternGraphDag,
  EnhancedD3DagNodeLink,
  fetchPatternGraphForRoute,
} from '../subway-data/patternGraph';
import {
  OtpRouteId,
  PatternGraphNode,
  RouteDirection,
} from '../subway-data/subway-types';
import { now } from '../utils/date.utils';

const loadRouteStopsAtTimeInOneDirection = async ({
  date = now(),
  directionId = '1',
  routeId,
}: {
  date?: Date;
  directionId?: RouteDirection;
  routeId: OtpRouteId;
}): Promise<EnhancedD3DagNodeLink<PatternGraphNode>[]> => {
  // Prevent empty string from infiltrating routeId
  if (!routeId) return [];

  const patternGraph = await fetchPatternGraphForRoute({
    date,
    directionId,
    routeId,
  });

  const dag = createPatternGraphDag({
    directionId,
    patternGraph,
    routeId,
  });

  return createNodeLinksListFromDag({
    dag,
    directionId,
    routeId,
  });
};

export default loadRouteStopsAtTimeInOneDirection;
