import flattenDeep from 'lodash/flattenDeep';
import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';

import {
  Station,
  Stations,
  Stop,
  StopList,
  StopListsByRoute,
  SubwayRouteId,
} from '../../subway-data/subway-types';

import { getMapSelectedRouteId } from './basic';
import { getMapConnectedStations } from './getMapConnectedStations';
import { getMapStations } from './getMapStations';
import { getMapStopListsByRoute } from './getMapStopListsByRoute';

const getUniqStopsList = createSelector(
  getMapStopListsByRoute,
  (stopListsByRoute: StopListsByRoute): StopList => {
    const stopsAll: StopList = uniqBy(
      flattenDeep<Stop>(Object.values(stopListsByRoute)).filter(
        stop => stop.stopType !== '2'
      ),
      stop => `${stop.routeId}-${stop.stopId}`
    );

    return stopsAll;
  }
);

export const getMapStationsAndStopsForSelectedRoute = createSelector(
  getMapConnectedStations,
  getMapSelectedRouteId,
  getMapStations,
  getUniqStopsList,
  (
    connectedStations: Stations[],
    selectedRouteId: SubwayRouteId | '',
    stations: Stations,
    uniqStopsList: StopList
  ): { stations: Stations; stops: StopList } => {
    const connectedStationsByComplexId: {
      [key: number]: Station[];
    } = connectedStations.reduce((acc, stations) => {
      acc[stations[0].Complex_ID] = stations;
      return acc;
    }, {});

    let validStationsForRoute: Station[] = [];
    let validStopsForRoute: Stop[] = [];

    if (selectedRouteId) {
      const stationsForRouteWithoutTransfers = stations.filter(station => {
        const stop = uniqStopsList.find(
          stop =>
            stop.stopId === station.stopId && stop.routeId === selectedRouteId
        );
        return !!stop;
      });

      stationsForRouteWithoutTransfers.forEach(station => {
        const complexId = station.Complex_ID;
        const stationsOnTheComplex = connectedStationsByComplexId[
          complexId
        ]?.filter(station => {
          return uniqStopsList.some(stop => stop.stopId === station.stopId);
        });

        if (stationsOnTheComplex) {
          validStationsForRoute.push(...stationsOnTheComplex);
        } else {
          validStationsForRoute.push(station);
        }
      });

      validStationsForRoute.forEach(station => {
        const stops = uniqStopsList.filter(
          stop => station.stopId === stop.stopId
        );

        if (stops.length) {
          validStopsForRoute.push(...stops);
        }
      });
    }

    return {
      stations: validStationsForRoute,
      stops: validStopsForRoute,
    };
  }
);
