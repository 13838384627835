import { Feature, Map } from 'ol';
import { Style, Stroke, Fill } from 'ol/style';
import { StyleFunction } from 'ol/style/Style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import {
  getAirportsLineWidth,
  getLineDashValue,
} from '../airports-openlayers-graphics';
import {
  HOVERABLE_FEATURE_LABEL_CLASS_NAME,
  RENDER_BUFFER_ROUTES,
  SELECTABLE_FEATURE_LABEL_CLASS_NAME,
} from '../maps-constants';
import { getMapTheme } from '../maps-theme';
import { getOlMapZoom } from '../maps-utils';

const getStyleFunction = (useDarkMap: boolean): StyleFunction => (
  feature,
  resolution
) => {
  const currentZoom = getOlMapZoom(resolution);
  const showAsActive = feature.get('showAsActive') as boolean;
  const useDash = feature.get('useDash') as boolean;
  const colorsToUse = getMapTheme(useDarkMap);
  let lineWidthPerZoom = getAirportsLineWidth(currentZoom, useDash);

  return new Style({
    fill: new Fill({
      color: 'transparent',
    }),
    stroke: new Stroke({
      color: showAsActive
        ? colorsToUse.airportLines
        : colorsToUse.lines.notSelected,
      lineDash: useDash
        ? getLineDashValue(lineWidthPerZoom, resolution)
        : [0, 0],
      width: lineWidthPerZoom / resolution,
    }),
  });
};

let airportTerminalLinesLayer: VectorLayer;
export const addAirportTerminalLinesLayer = ({
  airportsTerminalsFeatures,
  map,
  useDarkMap,
}: {
  airportsTerminalsFeatures: Feature[];
  map: Map;
  useDarkMap: boolean;
}) => {
  if (airportTerminalLinesLayer) {
    airportTerminalLinesLayer.setSource(
      new VectorSource({
        features: airportsTerminalsFeatures,
      })
    );
    airportTerminalLinesLayer.setStyle(getStyleFunction(useDarkMap));
  } else {
    airportTerminalLinesLayer = new VectorLayer({
      className: `${SELECTABLE_FEATURE_LABEL_CLASS_NAME} ${HOVERABLE_FEATURE_LABEL_CLASS_NAME}`,
      renderBuffer: RENDER_BUFFER_ROUTES,
      source: new VectorSource({
        features: airportsTerminalsFeatures,
      }),
      style: getStyleFunction(useDarkMap),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(airportTerminalLinesLayer);
  }
};
