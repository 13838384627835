import { Style } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import {
  Station,
  Stations,
  StopList,
  SubwayRouteId,
} from '../subway-data/subway-types';
import { RoutesUnavailable } from '../subway-data';
import { FeatureLike } from 'ol/Feature';
import { Point } from '../geometry/geometry-types';
import { getStopPoint } from './subway-stops.utils';
import { SegmentStrategyIndex } from './strategies-for-segments';

export interface LayerRenderingProps {
  routeId: SubwayRouteId | '';
  routesUnavailable?: RoutesUnavailable;
  isADAFilterActive?: boolean;
}

export const projectStation = (station: Station): Point =>
  fromLonLat([station.lon, station.lat]) as Point;

export interface SingleStyleFunction {
  (feature: FeatureLike, resolution: number): Style | Style[];
}

///////////// SUBWAY LINES

export const stationPointsForSubwayRouteId = (
  stops: StopList,
  stationAngles: { [stopId: string]: number },
  stations: Stations
): Point[] => {
  return stops.map(stop => {
    // TODO: optimize lookup
    const station = stations.find(
      someStation => someStation.stopId === stop.stopId
    )!;
    // Only showing one route at a time, so station has 1 dot and angle is irrelevant
    return getStopPoint({
      stop,
      station,
      stationAngles,
    });
  });
};

/** A route segment is one section of the train line between two stations, for a specific route. */
export interface RouteSegment {
  isOneDirection: boolean;
  routeId: SubwayRouteId;
  segmentId: string;
  stopIds: [string, string];
  stopNames: [string, string];
  strategyIndex: SegmentStrategyIndex;
  originalPoints: Point[];
  strategizedPoints: Point[];
  /** Matches original points space, not rotated by 29° to match screen space */
  normalAnglesDegrees: number[];
  segmentsNormalAnglesDegrees: number[];
  // TODO: index and parallel (or maxIndex) at each end of the segment
  routeIndex: number;
  maxIndex: number;
  nextRouteIndex: number;
  nextMaxIndex: number;
  trainDistance?: number;
  unavailable?: boolean;
}
