import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import ModalOverlay from '../components/ModalOverlay';
import Modal from '../components/Modal';
import { AppState, AppDispatch } from '../models';
import { UIErrorType, UIState } from '../models/ui';
import theme from '../utils/theme';

const Description = styled.p`
  line-height: 1.6;
`;

interface ErrorModalProps {
  errorType: UIState['errorType'];
  requestCloseErrorModal: () => void;
}

export const content: {
  [key in UIErrorType]: {
    title: string;
    description: string;
    renderFooter?: React.ComponentType;
    // -1 always displays the modal when requested
    daysBetweenPrompts: number;
  };
} = {
  geolocation: {
    daysBetweenPrompts: -1,
    title: 'Location not detected',
    description: 'Please refresh browser to update current location.',
  },
  ie11: {
    daysBetweenPrompts: 14,
    title: 'Your browser is out-of-date',
    description:
      'Please consider upgrading your browser in order to view the MTA Map correctly.',
  },
  pwa: {
    daysBetweenPrompts: 14,
    title: 'Install MTA Map',
    description:
      "Install the MTA Map on your home screen for quick and easy access when you're on the go",
    renderFooter: () => (
      <p>
        <span>Tap</span>
        <svg width="20" height="27" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path d="M-8-5h36v36H-8z" />
            <path
              d="M7.5 7.5v1h-6v17h17v-17h-6v-1h7v19H.5v-19h7z"
              fill="#FFF"
            />
            <path
              d="M10.5 1.990558V17h-1V1.991783L6.57016 4.927002l-.740936-.74887L10 .001224V0l.001412.001414L14.172 4.178131l-.740937.74887L10.5 1.990559z"
              fill="#FFF"
            />
          </g>
        </svg>

        <span>then "Add to home Screen"</span>
      </p>
    ),
  },
};

const ErrorModal: React.FC<ErrorModalProps> = ({
  errorType,
  requestCloseErrorModal,
}) => {
  if (!errorType) {
    return null;
  }

  return (
    <>
      <ModalOverlay zIndex={theme.zIndex.modals - 1} />

      <Modal
        isOpen={true}
        onRequestClose={() => {
          requestCloseErrorModal();
        }}
        title={content[errorType].title}
        renderFooter={content[errorType].renderFooter}
      >
        <Description>{content[errorType].description}</Description>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  errorType: state.ui.errorType,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  requestCloseErrorModal: dispatch.ui.requestCloseErrorModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
