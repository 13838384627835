import { StopLists } from '../subway-data/subway-types';
import flatten from 'lodash/flatten';

export interface UnidirectionalService {
  direction: string;
  startStop: string;
  endStop: string;
}

export const listUnidirectionalServices = (
  routeStops: StopLists | undefined
) => {
  const stopsWithDirections = routeStops?.map(stops =>
    stops.reduce((acc, cur, index) => {
      const next = index < stops.length - 1 ? stops[index + 1] : undefined;
      const prev = index > 0 ? stops[index - 1] : undefined;

      if ((cur.direction && !next) || (!cur.direction && !!prev?.direction)) {
        const last = acc[acc.length - 1];
        acc = [
          ...acc.slice(0, acc.length - 1),
          { ...last, endStop: cur.stopName },
        ];
      }

      if (cur.direction && !prev) {
        acc.push({
          direction: cur.direction,
          startStop: cur.stopName,
          endStop: cur.stopName,
        });
      }

      if (!cur.direction && next?.direction) {
        acc.push({
          direction: next.direction,
          startStop: cur.stopName,
          endStop: cur.stopName,
        });
      }

      return acc;
    }, [] as UnidirectionalService[])
  );

  return flatten(stopsWithDirections);
};

export const servicesIntoReadableString = (
  servicesList: UnidirectionalService[]
) =>
  servicesList.map(service => {
    const direction = {
      '0': 'northbound',
      '1': 'southbound',
    }[service.direction];

    const stops = {
      '0': `${service.endStop} to ${service.startStop}`,
      '1': `${service.startStop} to ${service.endStop}`,
    }[service.direction];

    return `Trains are running ${direction} from ${stops}.`;
  });
