export type RouteDisplayMode = 'all' | 'solo';

export enum ZoomLevel {
  'z11' = 11,
  'z12' = 12,
  'z13' = 13,
  'z14' = 14,
  'z15' = 15,
  'z16' = 16,
  'z17' = 17,
  'z18' = 18,
}
