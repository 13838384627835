import { useEffect, RefObject } from 'react';

const CLICKS_TO_OPEN = 5;
const LATENCY_BETWEEN_CLICKS = 300;

export default function useDebugClick({
  ref,
  callback,
}: {
  ref: RefObject<HTMLButtonElement>;
  callback: () => void;
}) {
  useEffect(() => {
    const elem = ref.current;

    let timerID: number;
    let clickCount = 0;

    function handler() {
      clickCount = clickCount + 1;

      if (clickCount === CLICKS_TO_OPEN) {
        clickCount = 0;
        callback();
      }

      clearTimeout(timerID);

      timerID = setTimeout(() => {
        clickCount = 0;
      }, LATENCY_BETWEEN_CLICKS);
    }

    if (elem) elem.addEventListener('click', handler, { passive: true });

    return () => {
      clearTimeout(timerID);
      if (elem) elem.removeEventListener('click', handler);
    };
  }, [ref, callback]);
}
