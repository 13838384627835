import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components/macro';
import Icon, { IconTypes, IconContainer } from '../Icon';
import {
  notTouch,
  withFocusStyle,
  withTouchPressStyle,
  desktopExtraLarge,
  tablet,
} from '../../utils/theme';

type AccessibilityModeProps = {
  isActive?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const AccessibilityModeContainer = styled.button<AccessibilityModeProps>`
  width: 50px;
  height: 50px;
  background-color: ${p =>
    p.isActive ? p.theme.colors.button.bgActive : p.theme.colors.button.bg};
  color: ${p =>
    p.isActive
      ? p.theme.colors.button.colorActive
      : p.theme.colors.button.color};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${p => p.theme.boxShadow};
  cursor: pointer;
  outline: none;

  ${p => withFocusStyle(p.theme)}
  ${withTouchPressStyle()}

  transition: ${p => p.theme.transition};

  ${notTouch(css`
    height: 32px;
    width: 32px;

    svg {
      transform: scale(0.8);
    }

    &:hover {
      background-color: ${p => p.theme.colors.button.bgActive};
      color: ${p => p.theme.colors.button.colorActive};
    }
  `)}

  ${tablet(css`
    height: 32px;
    width: 32px;

    svg {
      transform: scale(0.8);
    }
  `)}

  ${desktopExtraLarge(css`
    height: 40px;
    width: 40px;

    svg {
      transform: scale(1);
    }
  `)}
`;

const AccessibilityMode: React.FC<AccessibilityModeProps> = ({
  isActive,
  onClick,
  ...props
}) => (
  <AccessibilityModeContainer
    data-cy="accessibility-control"
    isActive={isActive}
    onClick={onClick}
    {...props}
  >
    <IconContainer>
      <Icon type={IconTypes.Accessibility} />
    </IconContainer>
  </AccessibilityModeContainer>
);

export default AccessibilityMode;
