import { Feature, Map } from 'ol';
import { Style } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import { drawDot } from '../../geometry/paths';
import { Point } from '../../geometry/geometry-types';

import { RENDER_BUFFER_ICONS, STATION_SCALE } from '../maps-constants';
import { getMapTheme } from '../maps-theme';

const getStyle = (useDarkMap: boolean) =>
  new Style({
    renderer: (coordinates, state) => {
      const { resolution } = state;
      const dynamicRadius = (3 / resolution) * STATION_SCALE;
      const colorsToUse = getMapTheme(useDarkMap).dot;

      drawDot(coordinates as Point, state, dynamicRadius, colorsToUse.color);
    },
  });

let airportStationsDotsLayer: VectorLayer;
export const addAirportStationsDotsLayer = ({
  airportsStationsDotsFeatures,
  map,
  useDarkMap,
}: {
  airportsStationsDotsFeatures: Feature[];
  map: Map;
  useDarkMap: boolean;
}) => {
  if (airportStationsDotsLayer) {
    airportStationsDotsLayer.setSource(
      new VectorSource({
        features: airportsStationsDotsFeatures,
      })
    );
    airportStationsDotsLayer.setStyle(getStyle(useDarkMap));
  } else {
    airportStationsDotsLayer = new VectorLayer({
      minZoom: 12.999,
      renderBuffer: RENDER_BUFFER_ICONS,
      source: new VectorSource({
        features: airportsStationsDotsFeatures,
      }),
      style: getStyle(useDarkMap),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });
    map.addLayer(airportStationsDotsLayer);
  }
};
