import flatten from 'lodash/flatten';
import { createSelector } from 'reselect';

import { RoutesUnavailable } from '../../subway-data';
import { StopListsByRoute } from '../../subway-data/subway-types';

import { getMapStopListsByRouteRaw } from './getMapStopListsByRoute';

export const getMapRoutesUnavailable = createSelector(
  getMapStopListsByRouteRaw,
  (mapStopListsByRoute: Partial<StopListsByRoute>): RoutesUnavailable => {
    const routesUnavailable: RoutesUnavailable = {};
    for (const routeId of Object.keys(mapStopListsByRoute)) {
      const stopsForRoute = flatten(mapStopListsByRoute[routeId] || []);
      routesUnavailable[routeId] = !stopsForRoute.length;
    }
    return routesUnavailable;
  }
);
