import {
  init,
  RematchDispatch,
  RematchRootState,
  RematchStore,
} from '@rematch/core';
import immerPlugin from '@rematch/immer';
import ui from './ui';
import map from './map';

const models = {
  ui,
  map,
};

export type AppModels = typeof models;
export type AppDispatch = RematchDispatch<AppModels>;
export type AppState = RematchRootState<AppModels>;

export const createStore = function(
  models: AppModels
): RematchStore<AppModels> {
  // generate Redux store using Rematch
  const immer = immerPlugin();
  return init({ models, plugins: [immer] });
};

export default models;
