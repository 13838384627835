import { TimeFilter } from '../subway-data';
import {
  isWeekend,
  MORNING_HOURS_BOUND,
  NIGHT_HOURS_BOUND,
  overridableNow,
} from './date.utils';

// The night mode colors should change after 8pm
const NIGHT_HOURS_FOR_DARK_MODE = 20;

export const isMapOnDarkMode = (timeFilter: TimeFilter) => {
  const date = overridableNow();
  const hours = date.getHours();

  if (timeFilter === 'weeknight') {
    return true;
  }

  if (timeFilter === 'weekday') {
    // If the user is on the weekend, the map uses
    // the light color for weekday
    if (isWeekend(date)) {
      return false;
    }

    // During weekdays we return dark mode if it's after 8 pm and
    // before the weeknight time filter hours.
    return hours >= NIGHT_HOURS_FOR_DARK_MODE && hours < NIGHT_HOURS_BOUND;
  }

  if (isWeekend(date)) {
    return hours < MORNING_HOURS_BOUND || hours >= NIGHT_HOURS_FOR_DARK_MODE;
  } else {
    return false;
  }
};
