import { SubwayRouteId, RouteStatus } from '../subway-data/subway-types';
import {
  getDateFromTimeFilter,
  getEndDateFromTimeFilter,
} from '../utils/date.utils';
import { TimeFilter } from '../subway-data';
import {
  extractStatusTimespan,
  extractStatusLastUpdated,
  extractHumanReadable,
  extractText,
} from '../utils/parse.utils';
import { FutureServiceStatusRequestBody } from './loadFutureServiceStatus';

export const NOW_SERVICE_STATUS_ENDPOINT =
  'https://collector-otp-prod.camsys-apps.com/realtime/gtfsrt/MTASBWY/alerts?type=json&apikey=qeqy84JE7hUKfaI0Lxm2Ttcm6ZA0bYrP';

const loadRouteStatus = async (
  selectedRouteId: SubwayRouteId,
  timeFilter: TimeFilter
): Promise<RouteStatus> => {
  const startDate = getDateFromTimeFilter(timeFilter);
  const formattedStartDate = `${startDate.getMonth() +
    1}/${startDate.getDate()}/${startDate.getFullYear()}`;
  const formattedStartTime = `${
    startDate.getHours() < 10
      ? '0' + startDate.getHours()
      : startDate.getHours()
  }${
    startDate.getMinutes() < 10
      ? '0' + startDate.getMinutes()
      : startDate.getMinutes()
  }`;

  const endDate = getEndDateFromTimeFilter(timeFilter, startDate);
  const formattedEndDate = `${endDate.getMonth() +
    1}/${endDate.getDate()}/${endDate.getFullYear()}`;
  const formattedEndTime = `${
    endDate.getHours() < 10 ? '0' + endDate.getHours() : endDate.getHours()
  }${
    endDate.getMinutes() < 10
      ? '0' + endDate.getMinutes()
      : endDate.getMinutes()
  }`;

  try {
    const serviceStatus = await loadFutureServiceStatus({
      line: selectedRouteId,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      startTime: formattedStartTime,
      endTime: formattedEndTime,
    });

    return serviceStatus;
  } catch (e) {
    console.error('ERROR', e);

    return {
      routeId: selectedRouteId,
      lastUpdated: new Date().getTime(),
      statusDetails: [],
    };
  }
};

const getActiveDates = status => {
  let startDate;
  let endDate;

  for (let i = 0; i < status.active_period.length; i++) {
    let period = status.active_period[i];

    if (period.start) {
      let tempDate = new Date(period.start * 1000);
      if (startDate) {
        if (tempDate < startDate) {
          startDate = tempDate;
        }
      } else {
        startDate = tempDate;
      }
    }

    if (period.end) {
      let tempDate = new Date(period.end * 1000);
      if (endDate) {
        if (tempDate > endDate) {
          endDate = tempDate;
        }
      } else {
        endDate = tempDate;
      }
    }
  }

  return {
    startDate: startDate ? startDate.toString() : false,
    endDate: endDate ? endDate.toString() : false,
  };
};

const statusByLine = (line, statii) => {
  const filteredStatii = statii.entity.filter(status => {
    let shouldReturn = false;

    status.alert.informed_entity.forEach(entity => {
      if (entity.route_id === line && entity.agency_id === 'MTASBWY') {
        shouldReturn = true;
      }

      if (entity.trip && entity.trip.route_id) {
        if (entity.trip.route_id === line) {
          shouldReturn = true;
        }
      }
    });

    return shouldReturn;
  });

  return filteredStatii;
};

const loadFutureServiceStatus = async (
  body: FutureServiceStatusRequestBody
): Promise<RouteStatus> => {
  const serviceStatusFuture = await fetch(`${NOW_SERVICE_STATUS_ENDPOINT}`, {
    headers: {
      'Content-Type': 'text/json',
    },
    method: 'GET',
    mode: 'cors',
  }).then(res => res.json());

  const response = serviceStatusFuture;

  const noStatus = {
    routeId: body.line,
    lastUpdated: new Date().getTime(),
    statusDetails: [],
  };

  if (!response) {
    return noStatus;
  }

  let statusDetails = statusByLine(body.line, response);

  // If we're looking at now, then filter by alerts that are active
  if (body.startTime === body.endTime) {
    statusDetails = statusDetails.filter((alert, index) => {
      let shouldReturn = alert.alert.active_period.some(function(period) {
        const testDate = Date.now();
        let itsGood = false;

        if (period.start * 1000 < testDate) {
          if (period.end) {
            if (period.end * 1000 > testDate) {
              itsGood = true;
            }
          } else {
            if (!alert.id.includes('planned_work')) {
              period.end = period.start * 1000 + 28800000;

              if (period.end > testDate) {
                itsGood = true;
              }
            } else {
              itsGood = true;
            }
          }
        }

        return itsGood;
      });

      return shouldReturn;
    });
  } else {
    let timePeriodStart = new Date(body.startDate);
    let timePeriodEnd = new Date(body.endDate);

    timePeriodStart.setHours(parseInt(body.startTime?.slice(0, 2) as string));
    timePeriodEnd.setHours(parseInt(body.endTime?.slice(0, 2) as string));

    statusDetails = statusDetails.filter((alert, index) => {
      let shouldReturn = alert.alert.active_period.some(function(period) {
        const periodStart = new Date(period.start * 1000);
        const periodEnd = new Date(period.end * 1000);

        return (
          (periodStart >= timePeriodStart && periodStart <= timePeriodEnd) ||
          (timePeriodStart >= periodStart && timePeriodStart <= periodEnd)
        );
      });

      return shouldReturn;
    });
  }

  if (!('length' in statusDetails)) {
    statusDetails = [statusDetails];
  }

  return {
    routeId: body.line,
    lastUpdated: new Date().getTime(),
    statusDetails: statusDetails.map(status => {
      const descriptionText =
        status.alert && status.alert.description_text
          ? status.alert.description_text
          : {};
      const meta = status.alert['transit_realtime.mercury_alert'];
      const activeDates = getActiveDates(status.alert);
      const timeSpan = extractStatusTimespan(
        descriptionText && descriptionText.translation
          ? descriptionText.translation[0]
          : '',
        activeDates.startDate,
        activeDates.endDate
      );

      const statusItem = {
        statusId: `status-${Math.random()
          .toString(36)
          .substring(7)}`,
        statusSummary: meta.alert_type,
        // statusHeader: extractText(status.alert.header_text, 'header'),
        statusHeader: extractText(status.alert.header_text, 'header'),
        statusDescription: extractText(descriptionText, 'description'),
        statusCategory: false,
        statusTime: timeSpan,
        statusHumanReadable: extractHumanReadable(meta),
        statusLastUpdated: extractStatusLastUpdated(
          new Date(meta.updated_at * 1000).toString()
        ),
        direction: '0',
      };

      return statusItem;
    }),
  };
};

export default loadRouteStatus;
