import { Feature, Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Stroke, Style } from 'ol/style';
import { RenderFunction, StyleFunction } from 'ol/style/Style';
import {
  subwayRouteColors,
  subwayRouteInactiveColors,
} from '../../subway-data';
import { add, fromPolarDegrees } from '../../geometry/point-utils';
import { Point } from '../../geometry/geometry-types';
import {
  drawRoundSingleLineForPoints,
  getRadiiAtLineIndex,
} from '../../geometry/paths';

import {
  EDITING,
  NEEDS_LINE_CAPS_HACK,
  RENDER_BUFFER_ROUTES,
  STATION_SPACING,
} from '../maps-constants';
import { getMapTheme } from '../maps-theme';
import { ZoomLevel } from '../map-types';
import { getRouteLineWidth, getOlMapZoom } from '../maps-utils';
import {
  LayerRenderingProps,
  RouteSegment,
} from '../subway-openlayers-graphics';
import { SubwayRouteId } from '../../subway-data/subway-types';
import { getRadiusBase } from './utils/getRadiusBase';

const renderRouteSegment = (
  selectedRouteId: SubwayRouteId | '',
  useDarkMap: boolean
): RenderFunction => (coordinates, state) => {
  const { context, feature, pixelRatio, resolution } = state;
  const routeSegment = feature.get('routeSegment') as RouteSegment;
  const {
    isOneDirection,
    routeId,
    normalAnglesDegrees,
    segmentsNormalAnglesDegrees,
    strategizedPoints,
    strategyIndex,
    routeIndex,
    maxIndex,
    nextRouteIndex,
    nextMaxIndex,
    unavailable,
  } = routeSegment;

  const currentZoom = getOlMapZoom(resolution, 1);
  const points = coordinates as Point[];
  const lineActiveColor = subwayRouteColors[routeId];
  const lineInactiveColor = subwayRouteInactiveColors[routeId];
  const lineWidth = getRouteLineWidth(pixelRatio, resolution);
  const colorsToUse = getMapTheme(useDarkMap).lines;

  const lineSelectedColor = unavailable ? lineInactiveColor : lineActiveColor;
  const lineNotSelectedColor = colorsToUse.notSelected;

  const lineColor =
    selectedRouteId && selectedRouteId !== routeId
      ? lineNotSelectedColor
      : lineSelectedColor;

  // The 2 stations at each end of the route segment may have different numbers of routes (dots)
  // so base the radii calculation on the station with the lowest number
  // e.g. Green 4/5/6 connects 14 St-Union Sq with 8 dots to Astor Pl with 3 dots
  // Without this adjustment, the radii will not be symmetrical in a 4-point curve (2 elbows)
  // NOTE: this code is currently duplicated between here and layer-subway-route-lines-multi.ts
  // and must be kept in sync manually
  // TODO: consolidate in RouteSegment creation?
  let effectiveMaxIndex = maxIndex;
  let lineIndex = routeIndex;
  if (nextMaxIndex < maxIndex) {
    effectiveMaxIndex = nextMaxIndex;
    lineIndex = nextRouteIndex;
  }

  const radiusBase = getRadiusBase(resolution);
  const radii = getRadiiAtLineIndex({
    points: strategizedPoints,
    radius: radiusBase,
    spacing: (STATION_SPACING * pixelRatio) / resolution,
    lineIndex,
    maxIndex: effectiveMaxIndex,
    segmentsNormalAnglesDegrees,
  });

  context.beginPath();
  context.setLineDash([]);

  // Add white around the train line segment by drawing a thicker white stroke underneath.
  context.lineCap = 'butt';
  context.strokeStyle = colorsToUse.stroke;
  context.lineWidth = lineWidth * 1.5;
  drawRoundSingleLineForPoints(points, radii, context, strategyIndex);
  context.stroke();

  // Draw the train line segment in the specific color.
  if (isOneDirection && currentZoom >= ZoomLevel.z15) {
    // Background line lighter than the current dashed line color
    context.strokeStyle =
      !selectedRouteId || selectedRouteId === routeId
        ? lineInactiveColor
        : colorsToUse.notSelectedLight;
    context.lineWidth = lineWidth;
    drawRoundSingleLineForPoints(points, radii, context, strategyIndex);
    context.stroke();

    // set dashed line configuration
    context.lineCap = 'butt';
    context.setLineDash([lineWidth * 0.25, lineWidth * 0.4]);
  } else {
    context.lineCap = 'round';
  }

  context.strokeStyle = lineColor;
  context.lineWidth = lineWidth;
  drawRoundSingleLineForPoints(points, radii, context, strategyIndex);
  context.stroke();

  // This solves an issue on Safari where lines wouldn't join correctly
  // (each segment was drawn as a separate frame buffer and not blended
  // in correctly at corners)
  if (NEEDS_LINE_CAPS_HACK) {
    context.strokeStyle = 'transparent';
    context.lineWidth = 1;
    context.stroke();
  }

  //// Draw colored "pins" to debug normal angles along line segments
  // TODO: toggle via query parameter
  const showNormalAngles = false;
  if (showNormalAngles) {
    context.save();
    context.lineWidth = 3 * pixelRatio;
    const vectorLength = 60;

    for (let i = 0; i < points.length; i++) {
      const p = points[i];
      const normalAngleDegrees = normalAnglesDegrees[i];
      const normalVector = fromPolarDegrees(vectorLength, normalAngleDegrees);
      const normalLineStart = p;
      const normalLineEnd = add(p, normalVector);

      context.beginPath();
      const isFirstSegment = i === 0;
      const isLastSegment = i === points.length - 1;
      context.strokeStyle = isFirstSegment
        ? '#00FF00'
        : isLastSegment
        ? 'red'
        : 'magenta';
      context.moveTo(normalLineStart[0], normalLineStart[1]);
      context.lineTo(normalLineEnd[0], normalLineEnd[1]);
      context.stroke();
    }
    context.restore();
  }
};

const MAX_ZINDEX = 1000;

const ROUTE_SEGMENT_ZINDEX_OVERRIDES: { [segmentId: string]: number } = {
  // Clark St (2/3) to Borough Hall (2/3/4/5)
  '231_232': MAX_ZINDEX,
  // Hoyt St (2/3) to Nevins St (2/3/4/5)
  '233_234': MAX_ZINDEX,
  // Nevins St to Atlantic Barclays (2/3/4/5/B/Q)
  '234_235': MAX_ZINDEX,
  // Eastern Pkwy Brooklyn Museum (2/3/4/5) to Franklin Av Medgar Evers College (2/3/4/5)
  // Put shuttle underneath green/red, as in Vignelli
  '238_239': MAX_ZINDEX,
  // Canal to World Trade Center (A/C/E)
  A36_A38: MAX_ZINDEX,
  // Chambers to World Trade Center (E)
  A36_E01: MAX_ZINDEX - 1,
  // Hoyt Schemerhorn (A/C/G) to Lafayette Av (A/C)
  A42_A43: 10,
  // Lexington Av-63 St (4/5/6) to 57 St-7 Av (R/W/N/Q)
  // Put the vertical line underneath the wide corner curves
  B08_R14: 0,
  // 7 Av (D/B/E) to 47-50 Sts Rockefeller Ctr (E/B/D/F/M)
  // Carefully put orange lines under the vertical yellow lines,
  // but over the blue lines when the E reroutes along the F line
  D14_D15: MAX_ZINDEX * 0.5,
  // 7 Av (2/3/4/5/Q/B) to Prospect Park (Q/B/SF)
  D25_D26: 0,
  // York St (F) to Jay St MetroTech (F/A/C)
  F18_A41: 0,
  // Queens Plaza (R/M/E) to Court Sq/23 St (M/E)
  G21_F09: MAX_ZINDEX,
  // Fulton St (G) to Hoyt Schemerhorn (A/C/G)
  G36_A42: 0,
  // Bushwick Av Aberdeen St (L) to Broadway Junction (C/A/J/Z/L)
  L21_J27: 0,
  // Court St (R/W/N) to Hoyt St (2/3)
  R28_A41: 0,
  // DeKalb Av (R/N/Q/B/D) to Atlantic Barclays (R/W/N/D)
  R30_R31: MAX_ZINDEX - 10,
  // Make the D cross under the B within the same route segment
  R30_R31_D: 100,
};

let subwayRouteLinesLayer: VectorLayer;
export const addSubwayRouteLinesLayer = ({
  map,
  routeId,
  allRoutesSegmentFeatures,
  useDarkMap,
}: Pick<LayerRenderingProps, 'routeId'> & {
  map: Map;
  allRoutesSegmentFeatures: Feature[];
  useDarkMap: boolean;
  p?: number;
}) => {
  const features: Feature[] = allRoutesSegmentFeatures;

  // In editing mode, regular strokes need to be added because
  // OpenLayers cannot detect clicks on the lines from the custom renderer.
  let editingStyle: Style | undefined;
  if (EDITING) {
    editingStyle = new Style({
      stroke: new Stroke({
        color: 'white',
        width: routeId ? 7 : 3,
      }),
    });
  }

  const styleFunction: StyleFunction = (feature, resolution) => {
    const routeSegment = feature.get('routeSegment') as RouteSegment;
    const {
      segmentId,
      routeId: segmentRouteId,
      normalAnglesDegrees,
    } = routeSegment;

    let zIndex =
      ROUTE_SEGMENT_ZINDEX_OVERRIDES[segmentId + '_' + segmentRouteId] ||
      ROUTE_SEGMENT_ZINDEX_OVERRIDES[segmentId];

    if (zIndex === undefined) {
      const verticalitySum = normalAnglesDegrees.reduce((result, angle) => {
        const fromVertical = Math.min(Math.abs(angle), Math.abs(180 - angle));
        result += (500 * (180 - fromVertical)) / 180;
        return result;
      }, 0);
      const verticalityAverage = verticalitySum / normalAnglesDegrees.length;
      zIndex = Math.round(verticalityAverage);
    }

    const styles = [
      new Style({
        renderer: renderRouteSegment(routeId, useDarkMap),
        zIndex,
      }),
    ];
    if (editingStyle) {
      styles.push(editingStyle);
    }
    return styles;
  };

  if (subwayRouteLinesLayer) {
    subwayRouteLinesLayer.setSource(
      new VectorSource({
        features,
      })
    );
    subwayRouteLinesLayer.setStyle(styleFunction);
  } else {
    subwayRouteLinesLayer = new VectorLayer({
      minZoom: 14.999,
      renderBuffer: RENDER_BUFFER_ROUTES,
      source: new VectorSource({
        features,
      }),
      style: styleFunction,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(subwayRouteLinesLayer);
  }
};
