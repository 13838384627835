import { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components/macro';
import { withFocusStyle, notTouch, desktopExtraLarge } from '../../utils/theme';

type ZoomButtonProps = {
  position: 'bottom' | 'top';
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const ZoomButtonGroup = styled.div`
  width: 50px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
  box-shadow: ${p => p.theme.boxShadow};

  ${notTouch(css`
    width: 32px;
    height: 64px;
  `)};

  ${desktopExtraLarge(css`
    width: 40px;
    height: 80px;
  `)};
`;

export const ZoomButton = styled.button<ZoomButtonProps>`
  width: 50px;
  height: 50px;
  background-color: ${p => p.theme.colors.button.bg};
  color: ${p =>
    p.disabled ? 'rgba(0, 0, 0, 0.4)' : p.theme.colors.button.color};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ${p => p.theme.transition};

  ${p => withFocusStyle(p.theme)}

  &::before {
    z-index: 3;

    ${p => {
      if (p.position === 'bottom') {
        return css`
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        `;
      } else {
        return css`
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        `;
      }
    }}
  }

  ${p => {
    if (p.position === 'bottom') {
      return css`
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
      `;
    } else {
      return css`
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      `;
    }
  }}

  ${notTouch(css`
    &:hover:not(:disabled) {
      background-color: ${p => p.theme.colors.button.bgActive};
      color: ${p => p.theme.colors.button.colorActive};
    }

    width: 32px;
    height: 32px;

    &:active {
      width: 29px;
      height: 29px;
    }
  `)}

  svg {
    pointer-events: none;
  }

  ${desktopExtraLarge(css`
    width: 40px;
    height: 40px;

    &:active {
      width: 36px;
      height: 36px;
    }
  `)};
`;
