import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import { subwayRouteColors, subwayRouteHasDarkText } from '../subway-data';
import { SubwayRouteId } from '../subway-data/subway-types';
import { renderRouteIcon } from './renderRouteIcon';
import { MENU_TRANSITION } from '../utils/animations';
import { rgba } from 'polished';

interface SubwayRouteIconProps {
  deemphasized?: boolean;
  hasSituation?: boolean;
  routeId: string;
  className?: string;
  slideOut?: boolean;
}

interface SubwayRouteIconInnerProps {
  backgroundColor: string;
  deemphasized?: boolean;
  hasSituation?: boolean;
  inverted?: boolean;
  routeId: string;
  slideOut?: boolean;
}

const blinkingLetter = keyframes`
  0% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
`;

const blinkingExclamationMark = keyframes`
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
`;

const baseSlideOutTransform = (x: number) => `translate3d(${x}%, 0, 0)`;
const startingTransform = baseSlideOutTransform(0);
const endingTransform = baseSlideOutTransform(-100);
const slideOutAnimation = keyframes`
  from {
    transform: ${startingTransform};
    opacity: 1;
  }

  to {
    transform: ${endingTransform};
    opacity: 0;
  }
`;

export const SubwayRouteIconInner = styled.div<SubwayRouteIconInnerProps>`
  width: 100%;
  height: 100%;

  ${p =>
    p.slideOut &&
    css`
      transform: ${startingTransform};
      animation: ${MENU_TRANSITION}ms linear ${MENU_TRANSITION * 4}ms 1 forwards
        ${slideOutAnimation};
    `}

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }

  path {
    transition: ${p => p.theme.transition};
    will-change: opacity;
  }

  path[data-letter='true'] {
    opacity: ${p => (p.hasSituation ? 0 : 1)};
    fill: ${p =>
      !p.inverted
        ? p.theme.colors.white
        : p.deemphasized
        ? rgba(p.theme.colors.black, 0.25)
        : p.theme.colors.black};

    ${p =>
      p.hasSituation &&
      css`
        animation: ${blinkingLetter} 5s ease infinite;
      `}
  }

  path[data-alert='true'] {
    opacity: ${p => (p.hasSituation ? 1 : 0)};

    ${p =>
      p.hasSituation &&
      css`
        animation: ${blinkingExclamationMark} 5s ease infinite;
      `}
  }
`;

const SubwayRouteIcon: React.FC<SubwayRouteIconProps> = ({
  routeId,
  deemphasized,
  className,
  hasSituation,
  slideOut,
}) => (
  <SubwayRouteIconInner
    backgroundColor={subwayRouteColors[routeId]}
    inverted={subwayRouteHasDarkText[routeId]}
    deemphasized={deemphasized}
    hasSituation={hasSituation}
    routeId={routeId}
    className={className}
    slideOut={slideOut}
  >
    {renderRouteIcon(routeId as SubwayRouteId)}
  </SubwayRouteIconInner>
);

export default SubwayRouteIcon;
