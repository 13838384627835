import { AirportRouteStation, AirportTerminal } from './subway-types';

const jfkRouteStations: AirportRouteStation[] = [
  // Jamaica
  {
    isTransfer: true,
    lat: 40.700486,
    lon: -73.807969,
    nextStopId: 'JM1',
    placeStationDotAsFirst: true,
    requiredSubwayStopIds: ['G06', 'H03'],
    stopId: 'JMT',
    stopName: 'Jamaica Station - Transfer',
    subwayStopId: 'G06',
  },
  {
    labelPositionVertical: 'bottom',
    lat: 40.700158670991385,
    lon: -73.8078451953752,
    nextStopId: 'AT2',
    pathStrategyIndex: 3,
    placeStationDotAsFirst: true,
    requiredSubwayStopIds: ['G06', 'H03'],
    stopId: 'JM1',
    stopName: 'Jamaica Station\nAirTrain paid transfer',
  },
  // Howard Beach
  {
    isTransfer: true,
    lat: 40.660476,
    lon: -73.830301,
    nextStopId: 'HB1',
    requiredSubwayStopIds: ['G06', 'H03'],
    stopId: 'HBT',
    stopName: 'Howard Beach - Transfer',
    subwayStopId: 'H03',
  },
  {
    labelPositionVertical: 'top',
    lat: 40.66056991614002,
    lon: -73.82986924196325,
    nextStopId: 'AT1',
    pathStrategyIndex: 1,
    requiredSubwayStopIds: ['G06', 'H03'],
    stopId: 'HB1',
    stopName: 'Howard Beach\nAirTrain paid transfer',
  },
  {
    lat: 40.662254,
    lon: -73.823593,
    nextStopId: 'AT2',
    pathStrategyIndex: 2,
    requiredSubwayStopIds: ['G06', 'H03'],
    stopId: 'AT1',
    stopName: 'Lefferts Boulevard',
  },
  // Jamaica and Howard connection
  {
    labelPositionVertical: 'top',
    lat: 40.6597446,
    lineLabel: 'AIRTRAIN',
    lineLabelAlign: 'middle',
    lon: -73.8035334,
    nextStopId: 'AT3',
    pathStrategyIndex: 7,
    requiredSubwayStopIds: ['G06', 'H03'],
    stopId: 'AT2',
    stopName: 'Federal Circle',
    terminal: {
      angle: 210,
      terminalId: 'jfk',
    },
  },
];

const jfkTerminal: AirportTerminal = {
  center: {
    lat: 40.645649,
    lon: -73.785307,
  },
  name: 'John F. Kennedy International Airport',
  nameShort: 'JFK',
  radius: 650,
  requiredSubwayStopIds: ['H03', 'G06'],
  stations: [
    {
      angle: 230,
      name: 'Terminal 1',
      nameShort: '1',
    },
    {
      angle: 270,
      name: 'Terminal 2',
      nameShort: '2',
    },
    {
      angle: 345,
      name: 'Terminal 4',
      nameShort: '4',
    },
    {
      angle: 30,
      name: 'Terminal 5',
      nameShort: '5',
    },
    {
      angle: 85,
      name: 'Terminal 7',
      nameShort: '7',
    },
    {
      angle: 190,
      name: 'Terminal 8',
      nameShort: '8',
    },
  ],
  terminalId: 'jfk',
};

const laGuardiaRouteStations: AirportRouteStation[] = [
  // q70
  {
    isBusStation: true,
    lat: 40.74563,
    lon: -73.902984,
    nextStopId: 'q70-2',
    pathStrategyIndex: 1,
    requiredSubwayStopIds: ['712', 'G14', '117', 'A15', '225', '621', 'R03'],
    stopId: 'q70-1',
    stopName: 'Woodside Subway and LIRR Connections',
    subwayStopId: '712',
  },
  {
    isBusStation: true,
    lat: 40.746642,
    lineLabel: 'Q70 SBS LaGuardia Link',
    lineLabelAlign: 'middle',
    lon: -73.891341,
    nextStopId: 'lg-0',
    placeStationDotAsFirst: true,
    requiredSubwayStopIds: ['712', 'G14', '117', 'A15', '225', '621', 'R03'],
    stopId: 'q70-2',
    stopName: 'Jackson Heights Subway Connections',
    subwayStopId: 'G14',
  },
  // m60
  {
    isBusStation: true,
    lat: 40.807722,
    lineLabel: 'M60 SBS',
    lineLabelAlign: 'end',
    lon: -73.96411,
    nextStopId: 'm60-2',
    pathStrategyIndex: 2,
    requiredSubwayStopIds: ['712', 'G14', '117', 'A15', '225', '621', 'R03'],
    stopId: 'm60-1',
    stopName: 'Broadway & W 116 St',
    subwayStopId: '117',
  },
  {
    isBusStation: true,
    lat: 40.811109,
    lon: -73.952343,
    nextStopId: 'm60-3',
    requiredSubwayStopIds: ['712', 'G14', '117', 'A15', '225', '621', 'R03'],
    stopId: 'm60-2',
    stopName: 'W 125 St & St Nicholas Ave',
    subwayStopId: 'A15',
  },
  {
    isBusStation: true,
    lat: 40.807754,
    lon: -73.945495,
    nextStopId: 'm60-4',
    requiredSubwayStopIds: ['712', 'G14', '117', 'A15', '225', '621', 'R03'],
    stopId: 'm60-3',
    stopName: 'W 125 St & Lenox Ave',
    subwayStopId: '225',
  },
  {
    isBusStation: true,
    lat: 40.804138,
    lineLabel: 'M60 SBS',
    lineLabelAlign: 'middle',
    lon: -73.937594,
    nextStopId: 'm60-5',
    pathStrategyIndex: 3,
    requiredSubwayStopIds: ['712', 'G14', '117', 'A15', '225', '621', 'R03'],
    stopId: 'm60-4',
    stopName: 'E 125 St & Lexington Ave',
    subwayStopId: '621',
  },
  {
    isBusStation: true,
    lat: 40.770258,
    lineLabel: 'M60 SBS',
    lineLabelAlign: 'middle',
    lon: -73.917843,
    nextStopId: 'lg-0',
    pathStrategyIndex: 7,
    requiredSubwayStopIds: ['712', 'G14', '117', 'A15', '225', '621', 'R03'],
    stopId: 'm60-5',
    stopName: 'Hoyt Ave S & 31 St',
    subwayStopId: 'R03',
  },
  // LGA Fake entrance to connect with the circle terminal
  {
    isBusStation: true,
    lat: 40.767932,
    lon: -73.875725,
    requiredSubwayStopIds: ['712', 'G14', '117', 'A15', '225', '621', 'R03'],
    stopId: 'lg-0',
    stopName: 'La Guardia - Entrance 0',
    terminal: {
      angle: 240,
      terminalId: 'lga',
    },
  },
];

const laGuardiaTerminal: AirportTerminal = {
  nameShort: 'LGA',
  center: {
    lat: 40.771081,
    lon: -73.868604,
  },
  isBusTerminal: true,
  name: 'LaGuardia Airport',
  radius: 500,
  requiredSubwayStopIds: ['712', 'G14', '117', 'A15', '225', '621', 'R03'],
  stations: [
    {
      angle: 151.95,
      name: 'Terminal B',
      nameShort: 'B',
    },
    {
      angle: 29.8,
      name: 'Terminal C',
      nameShort: 'C',
    },
    {
      angle: 8.9,
      name: 'Terminal D',
      nameShort: 'D',
    },
  ],
  terminalId: 'lga',
};

export const airportsRouteStations = [
  ...jfkRouteStations,
  ...laGuardiaRouteStations,
];
export const airportsTerminals = [jfkTerminal, laGuardiaTerminal];
