import { Style } from 'ol/style';
import { EmergencyAlert } from '../subway-data/otp/emergency-alerts-types';
import { SubwayRouteIds } from '../subway-data/subway-types';
import { overridableNow } from '../utils/date.utils';

// Vaccine feature image
import VaccineFeatureImage from '../assets/vaccineFeature.png';

/**
/* The renderBuffer is "The buffer in pixels around the viewport extent used by the renderer when getting
/* features from the vector source for the rendering or hit-detection. Recommended value: the size of
/* the largest symbol, line width or label."
/* More info: https://openlayers.org/en/latest/apidoc/module-ol_layer_Vector-VectorLayer.html
*/
export const RENDER_BUFFER_ROUTES = 300;
export const RENDER_BUFFER_LABELS = 300;
export const RENDER_BUFFER_ICONS = 40;
export const NEEDS_LINE_CAPS_HACK = / Safari\/\d+/.test(navigator.userAgent);

export const EMPTY_STYLE = new Style({});

export const STATION_SCALE = 1.5;
export const STATION_SPACING = 11 * STATION_SCALE;

// Use consistently for both feature clicks and non-feature click detection
export const HIT_TOLERANCE = 20;

// Add this class name on Vector layers to block opacity change if
// the zoom is over the maximum.
export const BLOCK_PEEKABOO_FEATURE_CLASS_NAME = 'block-peekaboo';
export const MTA_TILES_LAYER_CLASS_NAME = 'mta-tiles-layer';

// Add this class name on Vector layers that can be selected in the map
export const SELECTABLE_FEATURE_LABEL_CLASS_NAME = 'selectable-feature-label';

// Add this class name on Vector layers that can be hovered in the map
export const HOVERABLE_FEATURE_LABEL_CLASS_NAME = 'hoverable-feature-label';
export const DOT_HOVERABLE_FEATURE_LABEL_CLASS_NAME =
  'dot-hoverable-feature-label';

export const routeIdsLayerOrder: SubwayRouteIds = [
  'J',
  'Z',
  'L',
  'GS',
  '7',
  'G',
  'A',
  'C',
  'E',
  'B',
  'D',
  'F',
  'M',
  'H',
  'FS',
  'N',
  'Q',
  'R',
  'W',
  '6',
  '5',
  '4',
  '1',
  '2',
  '3',
  'SI',
];

/**
 * Enables a developer mode for interactive editing of line strategies
 * via clicking a line segment then pressing number key 0 through 8
 * to select a different Vignelli shape.
 */
export const EDITING = false;

/**
 * Enables the visualization of the Vaccine Locations on the map
 */
export const USE_VACCINE_LOCATIONS_ON_MAP = false;

/**
 * Use to display any alternative information / feature
 * It only show if there isn't any emergency alerts
 * Set to `null` to hide it
 */
export const EMERGENCY_ALERT_ALTERNATIVE_CONTENT: EmergencyAlert | null = {
  id: 'featureId',
  title: 'Covid vaccination',
  text: 'Now you can view Covid vaccination sites on the map. ',
  link: '',
  link_title: '',
  priority: 'info',
  updated: `${overridableNow().getTime() / 1000}`,
  image: VaccineFeatureImage,
  hideDate: true,
  activateFeature: 'vaccineLocations',
};
