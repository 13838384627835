import { css } from 'styled-components/macro';
import {
  desktopLarge,
  desktopExtraLarge,
  desktopLargeOnly,
} from '../utils/theme';

export const title = css`
  font-size: 2.4rem;
  line-height: 1.375;
  letter-spacing: -0.06rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ${desktopLargeOnly(css`
    font-size: 1.92rem;
    letter-spacing: -0.096rem;
    line-height: 1;
  `)};
`;

export const subTitle = css`
  font-size: 1.7rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.411;

  ${desktopLarge(css`
    font-size: 1.2rem;
    line-height: 1.5;
  `)};

  ${desktopExtraLarge(css`
    font-size: 1.5rem;
    line-height: 1.4;
  `)};
`;

// Used on TimeFrameLabel and Title on mobile
export const subTitleSmall = css`
  ${subTitle};
  font-size: 1.5rem;
  line-height: 1.333 !important;
`;

export const content = css`
  font-size: 1.7rem;
  letter-spacing: 0 !important;
  line-height: 1.411;

  ${desktopLarge(css`
    font-size: 1.2rem;
    line-height: 1.666;
  `)};

  ${desktopExtraLarge(css`
    font-size: 1.5rem;
    line-height: 1.6;
  `)};
`;

export const label = css`
  color: ${p => p.theme.colors.gray7F};
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 1.466;

  ${desktopLarge(css`
    font-size: 1.2rem;
    line-height: 1.5;
  `)};

  ${desktopExtraLarge(css`
    font-size: 1.5rem;
    line-height: 1.833;
  `)};
`;

export const labelSmall = css`
  color: ${p => p.theme.colors.gray7F};
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.666;

  ${desktopLargeOnly(css`
    font-size: 1.1rem;
  `)};
`;

export const link = css`
  ${content};
  color: ${p => p.theme.colors.blue};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const betaLabel = css`
  color: ${p => p.theme.colors.white};
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
`;
