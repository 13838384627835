import styled from 'styled-components/macro';

export const TrayInner = styled.div<{
  scrollEnabled: boolean;
  showBoxShadow: boolean;
}>`
  box-shadow: ${p => (p.showBoxShadow ? p.theme.boxShadow : 'none')};
  background-color: ${p => p.theme.colors.white};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: ${p => (p.scrollEnabled ? 'auto' : 'hidden')};
  width: 100%;
  z-index: 4;
`;

export const TrayInnerOverlay = styled.div<{
  isVisible: boolean;
}>`
  background-color: transparent;
  height: 100%;
  position: fixed;
  transition: visibility 200ms linear;
  visibility: ${p => (p.isVisible ? 'initial' : 'hidden')};
  width: 100%;
  z-index: 5;
`;
