import { TimeFilter } from '../subway-data';
import { addMinutes } from 'date-fns';
import { getDevQaUrlData } from './url.utils';

export type DayOfWeek = 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat';

export const daysOfWeek: DayOfWeek[] = [
  'sun',
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
];

type TimeLabelType = {
  label: string;
  timeframe: string;
};

// prettier-ignore
export const timeLabels: {
  [key in TimeFilter]: { [key in TimeFilter]: TimeLabelType };
} = {
  weekday: {
    weekday:   { label: 'Now',       timeframe: '6am - 9pm' },
    weeknight: { label: 'Tonight',   timeframe: '9pm - 6am' },
    weekend:   { label: 'Weekend',   timeframe: 'Fri 9pm - Mon 6am' },
  },
  weeknight: {
    weeknight: { label: 'Now',       timeframe: '9pm - 6am' },
    weekday:   { label: 'Tomorrow',  timeframe: '6am - 9pm' },
    weekend:   { label: 'Weekend',   timeframe: 'Fri 9pm - Mon 6am' },
  },
  weekend: {
    weekend:   { label: 'Now',       timeframe: 'Until Mon 6am' },
    weekday:   { label: 'Weekday',   timeframe: '6am - 9pm' },
    weeknight: { label: 'Weeknight', timeframe: '9pm - 6am' },
  },
};

export const MORNING_HOURS_BOUND = 6;
export const NIGHT_HOURS_BOUND = 23;
export const NIGHT_HOURS = 24 - NIGHT_HOURS_BOUND + MORNING_HOURS_BOUND;

/**
 * Because the evening SIR runs less frequently,
 * its weekday patternGraph at 9 PM, 10 PM, 11 PM, etc. happens to be empty.
 * But by shifting the "Tonight" time to 9:15 PM, we get a populated SIR patternGraph.
 */
export const NIGHT_MINUTES_SHIFT_FOR_PATTERN_GRAPH = 45;

/**
 * special constant: normally weekend starts Fri night for querying service alerts
 * but when the weekend is in the future, we want to see the train patterns at Sat noon
 */
export const WEEKEND_SNAPSHOT_HOURS_FOR_PATTERN_GRAPH = 12;

// from https://stackoverflow.com/a/54437356/1606061
// TODO: allow time to be customized on the new Date
export const getNextDayOfTheWeek = (
  dayName: DayOfWeek,
  excludeToday = true,
  fromDate = new Date()
): Date => {
  const dayOfWeek = daysOfWeek.indexOf(dayName);
  if (dayOfWeek < 0) return fromDate;

  const offset = excludeToday ? 1 : 0;
  const newDate = new Date(fromDate.toDateString());
  newDate.setHours(0, 0, 0, 0);
  newDate.setDate(
    newDate.getDate() +
      offset +
      ((dayOfWeek + 7 - newDate.getDay() - offset) % 7)
  );
  return newDate;
};

export const isWeekend = (date: Date): boolean => {
  const dayOfTheWeek: DayOfWeek = daysOfWeek[date.getDay()];

  if (dayOfTheWeek === 'sun' || dayOfTheWeek === 'sat') {
    return true;
  } else if (dayOfTheWeek === 'fri') {
    if (date.getHours() >= NIGHT_HOURS_BOUND) {
      return true;
    }
  } else if (dayOfTheWeek === 'mon') {
    if (date.getHours() < MORNING_HOURS_BOUND) return true;
  }

  return false;
};

export const isWeekday = (date: Date): boolean => {
  const dayOfTheWeek: DayOfWeek = daysOfWeek[date.getDay()];

  if (dayOfTheWeek === 'sun' || dayOfTheWeek === 'sat') {
    return false;
  }

  const hours = date.getHours();
  // The range for hours needs to include the starting hour but omit the ending hour
  return hours >= MORNING_HOURS_BOUND && hours < NIGHT_HOURS_BOUND;
};

export const isWeeknight = (date: Date): boolean => {
  const dayOfTheWeek: DayOfWeek = daysOfWeek[date.getDay()];

  if (dayOfTheWeek === 'sun' || dayOfTheWeek === 'sat') {
    return false;
  }

  const hours = date.getHours();

  return hours < MORNING_HOURS_BOUND || hours >= NIGHT_HOURS_BOUND;
};

export const getTimeFilterFromDate = (
  fromDate: Date = overridableNow()
): TimeFilter => {
  if (isWeekend(fromDate)) {
    return 'weekend';
  } else if (isWeekday(fromDate)) {
    return 'weekday';
  } else {
    return 'weeknight';
  }
};

export const getDateFromTimeFilter = (timeFilter: TimeFilter): Date => {
  if (timeFilter === 'weekend') {
    return thisWeekend();
  } else if (timeFilter === 'weekday') {
    return thisWeekday();
  } else {
    return thisWeeknight();
  }
};

export const getPatternGraphDateFromTimeFilter = (
  timeFilter: TimeFilter
): Date => {
  if (timeFilter === 'weekend') {
    // Querying patternGraph at noon Saturday is a better weekend snapshot than Friday night
    return thisWeekendForPatternGraph();
  } else if (timeFilter === 'weekday') {
    return thisWeekday();
  } else {
    // Querying patternGraph at 9:15 PM is a better weeknight snapshot than 9 PM because SIR is running then
    return thisWeeknightForPatternGraph();
  }
};

export const getEndDateFromTimeFilter = (
  timeFilter: TimeFilter,
  date: Date
): Date => {
  if (timeFilter === 'weeknight') {
    return thisWeeknightStop(date);
  } else if (timeFilter === 'weekend') {
    return thisWeekendStop(date);
  }

  return date;
};

const TIME_REGEX = /^(1[0-2]|0?[1-9]):?([0-5][0-9])(?:\s?)(AM|PM)$/;

/**
 * Converts a 12-hour time to an 24-hour string to make it compatible
 * with `new Date()` on all browsers.
 * Cleans up small differences like missing a space or colon.
 * @example 12:34AM -> 00:34
 * @example 1234PM -> 12:34
 * @example 1:23AM -> 01:23
 * @param timeString
 */
export const convertTo24Hour = (timeString: string | undefined): string => {
  const trimmed = timeString?.trim().toUpperCase();
  const values = trimmed?.match(TIME_REGEX);

  if (values) {
    const [, hour, minutes, amPm]: string[] = values;
    let finalHour = hour;

    if (amPm.toLowerCase() === 'pm') {
      // 12:XX PM === 12:XX
      finalHour = hour === '12' ? '12' : `${+hour + 12}`;
    } else {
      // 12:XX AM === 00:XX
      finalHour = hour === '12' ? '00' : hour;
    }
    // When hour is a single digit, add a leading zero, otherwise `new Date()` may fail to parse.
    if (finalHour.length === 1) {
      finalHour = '0' + finalHour;
    }
    return `${finalHour}:${minutes}`;
  }
  return '';
};

export interface DateTimeStrings {
  dateString: string;
  timeString: string;
}

export const getPatternGraphDateTimeStrings = (date: Date): DateTimeStrings => {
  if (isNaN(+date)) return { dateString: '', timeString: '' };
  // predictable alternative to dealing with .toLocaleDateString()
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const dateString = `${year}-${month}-${day}`;

  const hrs = date.getHours();
  const hours = ('0' + (hrs <= 12 ? hrs : hrs - 12)).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const amPm = date.getHours() >= 12 ? 'PM' : 'AM';
  // NOTE: must not have a space before AM/PM, or else patternGraph will not give correct data
  const timeString = `${hours}:${minutes}${amPm}`;
  return { dateString, timeString };
};

/**
 * We can override the time filter via date and time URL query parameters.
 * More information on getDevQaUrlData definition 'src/utils/url.utils.ts'
 */
export const getOverridableDateTime = (
  nowDate: Date = now()
): Date | undefined => {
  const { date: urlDate, time: urlTime } = getDevQaUrlData();
  const {
    dateString: todayDateString,
    timeString: todayTimeString,
  } = getPatternGraphDateTimeStrings(nowDate);

  if (!urlDate && !urlTime) return undefined;

  const dateOverrideString = urlDate || todayDateString;
  const timeOverrideString = convertTo24Hour(urlTime || todayTimeString);
  const resultDate = new Date(`${dateOverrideString}T${timeOverrideString}`);
  const isDateValid = !isNaN(resultDate.getTime());

  return isDateValid ? resultDate : undefined;
};

export const now = (): Date => new Date();
export const overridableNow = (): Date => getOverridableDateTime() ?? now();

export const thisWeekday = (fromDate: Date = overridableNow()): Date => {
  // If the given date is already in range, return it
  if (isWeekday(fromDate)) {
    return fromDate;
  }

  // If weekend, return next monday
  if (isWeekend(fromDate)) {
    const nextMonday = getNextDayOfTheWeek('mon', false, fromDate);
    nextMonday.setHours(MORNING_HOURS_BOUND, 0, 0);

    return nextMonday;
  }

  // If weeknight not in the morning, return next day morning
  if (fromDate.getHours() > MORNING_HOURS_BOUND) {
    const nextMorning = new Date(fromDate.getTime());
    nextMorning.setDate(nextMorning.getDate() + 1);
    nextMorning.setHours(MORNING_HOURS_BOUND, 0, 0);

    return nextMorning;
  }

  // If early morning, return today morning
  const todayMorning = new Date(fromDate.getTime());
  todayMorning.setHours(MORNING_HOURS_BOUND, 0, 0);

  return todayMorning;
};

export const thisWeeknight = (fromDate: Date = overridableNow()): Date => {
  // If the given date is already in range, return it
  if (isWeeknight(fromDate)) {
    return fromDate;
  }

  // If weekend, return next Monday night
  if (isWeekend(fromDate)) {
    const nextMonday = getNextDayOfTheWeek('mon', false, fromDate);
    nextMonday.setHours(NIGHT_HOURS_BOUND, 0, 0);

    return nextMonday;
  }

  // If weekday, return night time
  const todayNight = new Date(fromDate.getTime());
  todayNight.setHours(NIGHT_HOURS_BOUND, 0, 0);

  return todayNight;
};

/**
 * This shifts the snapshot for weeknight train patterns by a few minutes (currently 15)
 * as a workaround for the SIR patternGraph being empty exactly on the hour.
 */
export const thisWeeknightForPatternGraph = (
  fromDate: Date = overridableNow()
): Date => {
  // If the given date is already in range, return it
  if (isWeeknight(fromDate)) {
    return fromDate;
  }

  // If weekend, return next Monday night, shifted for patternGraph
  if (isWeekend(fromDate)) {
    const nextMonday = getNextDayOfTheWeek('mon', false, fromDate);
    nextMonday.setHours(
      NIGHT_HOURS_BOUND,
      NIGHT_MINUTES_SHIFT_FOR_PATTERN_GRAPH,
      0
    );

    return nextMonday;
  }

  // If weekday, return night time, shifted for patternGraph
  const todayNight = new Date(fromDate.getTime());
  todayNight.setHours(
    NIGHT_HOURS_BOUND,
    NIGHT_MINUTES_SHIFT_FOR_PATTERN_GRAPH,
    0
  );

  return todayNight;
};

export const thisWeeknightStop = (fromDate: Date = overridableNow()): Date => {
  if (!isWeeknight(fromDate)) {
    const weeknightStartDate = thisWeeknight(fromDate);

    const weeknightEndDate = new Date(weeknightStartDate.getTime());
    weeknightEndDate.setHours(weeknightEndDate.getHours() + NIGHT_HOURS, 0, 0);
    return weeknightEndDate;
  }

  if (fromDate.getHours() < MORNING_HOURS_BOUND) {
    const sameDayMorningEndDate = new Date(fromDate.getTime());
    sameDayMorningEndDate.setHours(MORNING_HOURS_BOUND, 0, 0);

    return sameDayMorningEndDate;
  } else {
    const nextMorningEndDate = new Date(fromDate.getTime());
    nextMorningEndDate.setDate(nextMorningEndDate.getDate() + 1);
    nextMorningEndDate.setHours(MORNING_HOURS_BOUND, 0, 0);
    return nextMorningEndDate;
  }
};

export const thisWeekendStop = (fromDate: Date = overridableNow()): Date => {
  let nextMondayMorning = getNextDayOfTheWeek('mon', true, fromDate);

  if (
    isWeekend(fromDate) ||
    (daysOfWeek[fromDate.getDay()] === 'mon' &&
      fromDate.getHours() < MORNING_HOURS_BOUND)
  ) {
    nextMondayMorning = getNextDayOfTheWeek('mon', false, fromDate);
  }

  nextMondayMorning.setHours(MORNING_HOURS_BOUND, 0, 0);

  return nextMondayMorning;
};

export const thisWeekend = (fromDate: Date = overridableNow()): Date => {
  // If the given date is already in range, return it
  if (isWeekend(fromDate)) {
    return fromDate;
  }

  const nextFriday = getNextDayOfTheWeek('fri', false, fromDate);
  nextFriday.setHours(NIGHT_HOURS_BOUND, 0, 0);

  return nextFriday;
};

export const thisWeekendForPatternGraph = (
  fromDate: Date = overridableNow()
): Date => {
  // If the given date is already in range, return it
  if (isWeekend(fromDate)) {
    return fromDate;
  }

  const nextSaturday = getNextDayOfTheWeek('sat', false, fromDate);
  nextSaturday.setHours(WEEKEND_SNAPSHOT_HOURS_FOR_PATTERN_GRAPH, 0, 0);

  return nextSaturday;
};

export const differenceInDays = (
  date1: Date | number,
  date2: Date | number
): number => {
  return Math.floor((+date2 - +date1) / (1000 * 60 * 60 * 24));
};

const maxMinutesInADay = 24 * 60;

export const getTimesAllDay = (
  dayDate: Date,
  minutesBetweenTimes: number = 60
): Date[] => {
  const startOfDay = new Date(
    dayDate.getFullYear(),
    dayDate.getMonth(),
    dayDate.getDate(),
    0,
    0,
    0
  );
  const minutesOffsets: number[] = [];
  for (
    let currentMinutes = 0;
    currentMinutes < maxMinutesInADay;
    currentMinutes += minutesBetweenTimes
  ) {
    minutesOffsets.push(currentMinutes);
  }
  const dateTimes: Date[] = minutesOffsets.map(minutesOffset =>
    addMinutes(startOfDay, minutesOffset)
  );
  return dateTimes;
};
