import React from 'react';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from '../models';
import { getSelectedMapVaccineLocations } from '../selectors/map/basic';
import { isPhone } from '../utils/deviceDetector.utils';
import Tray, { TrayPosition } from '../components/Tray';
import ViewBox from '../components/ViewBox';
import { VaccineLocation } from '../services/loadCovidVaccines';
import VaccineLocationInfo from '../components/VaccineLocationInfo/VaccineLocationInfo';

interface VaccineLocationInfoContainerProps {
  currentTrayPosition: TrayPosition;
  setCurrentTrayPosition: (value: TrayPosition) => void;
  setVaccinationViewOpened: (newValue: boolean) => void;
  shortcutsMenuOpen: boolean;
  translate: boolean;
  vaccinationViewOpened: boolean;
  vaccineLocationInfo: VaccineLocation;
}
const VaccineLocationInfoContainer: React.FC<VaccineLocationInfoContainerProps> = ({
  currentTrayPosition,
  setCurrentTrayPosition,
  setVaccinationViewOpened,
  shortcutsMenuOpen,
  translate,
  vaccinationViewOpened,
  vaccineLocationInfo,
}) => {
  if (isPhone()) {
    return (
      <Tray
        defaultPosition={TrayPosition.twoThirds}
        currentTrayPosition={currentTrayPosition}
        isOpen={vaccinationViewOpened}
        onCloseCallback={() => setVaccinationViewOpened(false)}
        setCurrentTrayPosition={setCurrentTrayPosition}
      >
        <VaccineLocationInfo
          content={vaccineLocationInfo}
          onCloseClick={() => setVaccinationViewOpened(false)}
        />
      </Tray>
    );
  }

  return (
    <ViewBox
      isOpen={vaccinationViewOpened}
      shortcutsMenuOpen={shortcutsMenuOpen}
      translate={translate}
    >
      <VaccineLocationInfo
        content={vaccineLocationInfo}
        onCloseClick={() => setVaccinationViewOpened(false)}
      />
    </ViewBox>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentTrayPosition: state.ui.currentTrayPosition,
  vaccinationViewOpened: state.ui.vaccinationViewOpened,
  vaccineLocationInfo: getSelectedMapVaccineLocations(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setCurrentTrayPosition: dispatch.ui.setCurrentTrayPosition,
  setVaccinationViewOpened: dispatch.ui.setVaccinationViewOpened,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VaccineLocationInfoContainer);
