import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components/macro';

import { isPhone } from '../../utils/deviceDetector.utils';
import { landscapePhone, tablet, desktopExtraLarge } from '../../utils/theme';

import CloseButton from '../Controls/CloseButton';
import Icon, { IconTypes } from '../Icon';
import { title, label, link, content } from '../Text';

const Wrapper = styled.div`
  background-color: ${p => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 15px calc(env(safe-area-inset-left) + 30px) 0
    calc(env(safe-area-inset-right) + 30px);

  ${landscapePhone(css`
    flex-direction: row;
    justify-content: space-between;
    max-height: calc(100vh - 28px);
    padding: 12px calc(env(safe-area-inset-left) + 74px) 0
      calc(env(safe-area-inset-right) + 74px);
  `)}

  ${tablet(css`
    flex-direction: column;
    padding: 0 24px 0;
  `)}

  ${desktopExtraLarge(css`
    padding: 0 30px 0;
  `)}
`;

const Header = styled.div`
  border-bottom: 1px solid ${p => p.theme.colors.lightGray};
  margin-bottom: 30px;
  padding: 15px 0 64px;

  ${landscapePhone(css`
    flex-basis: 316px;
  `)}

  ${tablet(css`
    background ${p => p.theme.colors.white};
    padding-bottom: 20px 0 40px;
    position: sticky;
    top: 0px;
    z-index: 10;
  `)}

  ${desktopExtraLarge(css`
    margin-bottom: 15px;
    padding: 28px 0 50px;
  `)}
`;

const HeaderClose = styled.div`
  margin: 0 -7px 13px 0;
  text-align: right;
`;

const Title = styled.h2`
  ${title};
  margin: 0 0 8px;
`;

const Subtitle = styled.p`
  ${label};
  font-weight: normal;
  vertical-align: baseline;
`;

const Text = styled.p`
  ${content};
  font-weight: bold;
  margin-bottom: 22px;

  &:last-child {
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 16px;
  }
`;

const Link = styled.a`
  ${link};
  padding-bottom: 46px;
`;

interface AirportInfoProps {
  content: {
    link: string;
    subtitle: string;
    text: string[];
    title: string;
  };
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
}
const AirportInfo: React.FC<AirportInfoProps> = ({
  onCloseClick,
  content: { link, subtitle, text, title },
}) => {
  return (
    <Wrapper>
      <Header>
        {!isPhone() && (
          <HeaderClose>
            <CloseButton onClick={onCloseClick} />
          </HeaderClose>
        )}
        <Icon type={IconTypes.Airport} />
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Header>
      <div>
        {(text || []).map((paragraph, index) => (
          <Text key={index}>{paragraph}</Text>
        ))}
      </div>
      <Link href={link} target="_blank">
        {link}
      </Link>
    </Wrapper>
  );
};

export default AirportInfo;
