import { Feature, Geolocation, Map as OlMap, View } from 'ol';
import { Circle, Point as OlPoint } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { IS_TOUCH } from '../../utils/theme';
import { ZoomLevel } from '../map-types';

let getGeoLocationLayer: VectorLayer;
export const addGeoLocationLayer = (
  map: OlMap,
  view: View,
  geolocation: Geolocation
) => {
  if (!getGeoLocationLayer) {
    const accuracyFeature = new Feature();
    const accuracyPolygonStyle = new Style({
      fill: new Fill({
        color: 'rgba(0, 79, 255, 0.1)',
      }),
    });
    accuracyFeature.setStyle(accuracyPolygonStyle);

    const backgroundRadiusSmall = 6;
    const backgroundRadiusRegular = 8;

    const positionRadiusSmall = IS_TOUCH ? 4 : 3;
    const positionRadiusRegular = IS_TOUCH ? 6 : 5;

    const backgroundPositionFeature = new Feature();
    const setBackgroundPositionFeatureStyle = () => {
      backgroundPositionFeature.setStyle(
        new Style({
          image: new CircleStyle({
            radius:
              (view.getZoom() ?? NaN) >= ZoomLevel.z12
                ? backgroundRadiusRegular
                : backgroundRadiusSmall,
            fill: new Fill({
              color: 'rgba(255, 255, 255, 1)',
            }),
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.1)',
              width: 1,
            }),
          }),
        })
      );
    };
    setBackgroundPositionFeatureStyle();

    const positionFeature = new Feature();
    const setPositionFeatureStyle = () => {
      positionFeature.setStyle(
        new Style({
          image: new CircleStyle({
            radius:
              (view.getZoom() ?? NaN) >= ZoomLevel.z12
                ? positionRadiusRegular
                : positionRadiusSmall,
            fill: new Fill({
              color: 'rgba(0, 79, 255, 1)',
            }),
          }),
        })
      );
    };
    setPositionFeatureStyle();

    const onGeolocationChange = () => {
      const coordinates = geolocation.getPosition();
      const accuracy = geolocation.getAccuracy();

      if (!coordinates) {
        return;
      }

      positionFeature.setGeometry(new OlPoint(coordinates));
      setPositionFeatureStyle();
      backgroundPositionFeature.setGeometry(new OlPoint(coordinates));
      setBackgroundPositionFeatureStyle();

      // Only use real geometry if accuracy not too large (in meters)
      if (accuracy && accuracy <= 200) {
        accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
      } else {
        accuracyFeature.setGeometry(new Circle(coordinates, 200));
      }
    };

    geolocation.on('change:position', onGeolocationChange);
    geolocation.on('change:accuracyGeometry', onGeolocationChange);
    view.on('change:resolution', onGeolocationChange);

    getGeoLocationLayer = new VectorLayer({
      source: new VectorSource({
        features: [accuracyFeature, backgroundPositionFeature, positionFeature],
      }),
    });

    map.addLayer(getGeoLocationLayer);
  }
};
