import { ImageTile, Tile, Map } from 'ol';
import { Tile as TileLayer } from 'ol/layer';
import { XYZ } from 'ol/source';
import TileState from 'ol/TileState';

import PatternLg from '../../assets/pattern-lg.png';
import {
  BLOCK_PEEKABOO_FEATURE_CLASS_NAME,
  MTA_TILES_LAYER_CLASS_NAME,
} from '../maps-constants';

const customTileLoadFunction = (imageTile: Tile, src: string) => {
  fetch(src)
    .then(response => {
      if (imageTile instanceof ImageTile) {
        const image = imageTile.getImage();
        if (!(image instanceof HTMLCanvasElement)) {
          if (response !== null) {
            image.src = src;
          } else {
            imageTile.setState(TileState.ERROR);
            image.src = PatternLg;
          }
        }
      }
    })
    .catch(err => {
      console.warn('Error loading tiles:', err);
      imageTile.setState(TileState.ERROR);
    });
};

const getMapUrl = (useHighDensity: boolean) => {
  const multiplier = useHighDensity ? '@2x/' : '';

  return `https://datastore02azenytd.blob.core.windows.net/map-tiles/${multiplier}{z}/{x}/{y}.png`;
};

let mapTilesLayer: TileLayer | null;
export const addMapTilesLayer = (map: Map): void => {
  // Low-powered by high resolution devices don't attempt to use high density images
  // Disabled by default
  const alwaysUseLowDensityOnMobile = false;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // Opinionated density adaptation: anything equal or below 1x uses normal tiles (1x),
  // anything above uses double density tiles (2x)
  const canUseHighDensity =
    (!isMobile || !alwaysUseLowDensityOnMobile) && window.devicePixelRatio > 1;

  if (mapTilesLayer) {
    const tilesSource = mapTilesLayer.getSource() as XYZ;
    const currentUrl = tilesSource.getUrls()?.[0];
    const newUrl = getMapUrl(canUseHighDensity);

    if (currentUrl !== newUrl) {
      tilesSource.setUrl(newUrl);
    }
  } else {
    mapTilesLayer = new TileLayer({
      className:
        BLOCK_PEEKABOO_FEATURE_CLASS_NAME + ' ' + MTA_TILES_LAYER_CLASS_NAME,
      source: new XYZ({
        crossOrigin: 'anonymous',
        tileLoadFunction: customTileLoadFunction,
        tilePixelRatio: canUseHighDensity ? 2 : 1,
        tileSize: 256,
        url: getMapUrl(canUseHighDensity),
      }),
    });

    map.addLayer(mapTilesLayer);
  }
};
