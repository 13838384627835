import styled from 'styled-components/macro';
import { ServiceStatusTitle } from '../ServiceStatusContent';

const TrayContent = styled.div`
  ${ServiceStatusTitle} {
    div[data-subway-icon='true'] {
      width: 26px !important;
      height: 26px !important;
    }
  }
`;

export default TrayContent;
