import { Stop, StopList, SubwayStopIdName } from '../subway-data/subway-types';
import { Immutable } from '../utils/type.utils';

/**
 * Do not include stops where route
 * doesn't stop after final stop
 */
export const getFilteredStopsForRoute = (
  stops: Immutable<(Stop | undefined)[]>
): StopList => {
  return stops.filter((stop, index) => {
    // Eventually we'll have safeguards here but for now, throw an instructive error message
    // to encourage fixing the problem at the source.
    if (!stop)
      throw new Error(`A stop was undefined; you may need to add a stop ID mapping to complexStations.
stops argument: ${stops.map(s => s?.stopId + ' - ' + s?.stopName)}`);
    // Always include if route stops at current station
    if (stop.stopType !== '2') {
      return true;
    }

    // Loop over remaining stations to search for
    // ones where route stops
    for (let i = index + 1; i <= stops.length - 1; i++) {
      if (stops[i]!.stopType !== '2') {
        return true;
      }
    }

    return false;
  }) as StopList;
};

export type HasStopId = Pick<SubwayStopIdName, 'stopId'>;

export const getSegmentId = (stationA: HasStopId, stationB: HasStopId) => {
  return `${stationA.stopId}_${stationB.stopId}`;
};

export const getSegmentIdsForStops = (stops: HasStopId[]): string[] => {
  const stopIds: string[] = [];
  for (let i = 0; i < stops.length - 1; i++) {
    const thisStop = stops[i];
    const nextStop = stops[i + 1];
    stopIds.push(getSegmentId(thisStop, nextStop));
  }
  return stopIds;
};

const SUBWAY_STOP_ID_REGEX = /^[\dA-Z]\d{2}$/;

// Route 1 has a shuttle stop `MTASBWY_3737` on weeknights at 10 pm
const BUS_STOP_ID_REGEX = /^\d{4,5}$/;

export const isASubwayStopId = (id: string): boolean =>
  SUBWAY_STOP_ID_REGEX.test(id);

export const isABusStopId = (id: string): boolean => BUS_STOP_ID_REGEX.test(id);
