import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components/macro';

import { VaccineLocation } from '../../services/loadCovidVaccines';
import { overridableNow } from '../../utils/date.utils';
import { isPhone } from '../../utils/deviceDetector.utils';
import { landscapePhone, tablet, desktopExtraLarge } from '../../utils/theme';

import CloseButton from '../Controls/CloseButton';
import Icon, { IconContainer, IconTypes } from '../Icon';
import { content, label, title } from '../Text';
import { VaccineLocationInfoItem } from './VaccineLocationInfoItem';

const WEEKDAY_LABELS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const Wrapper = styled.div`
  background-color: ${p => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 15px calc(env(safe-area-inset-left) + 30px) 0
    calc(env(safe-area-inset-right) + 30px);

  ${landscapePhone(css`
    flex-direction: row;
    justify-content: space-between;
    max-height: calc(100vh - 28px);
    padding: 12px calc(env(safe-area-inset-left) + 74px) 0
      calc(env(safe-area-inset-right) + 74px);
  `)}

  ${tablet(css`
    flex-direction: column;
    padding: 0 24px 0;
  `)}

  ${desktopExtraLarge(css`
    padding: 0 30px 0;
  `)}
`;

const Header = styled.div`
  margin-bottom: 64px;
  padding-top: 15px;

  ${landscapePhone(css`
    flex-basis: 316px;
  `)}

  ${tablet(css`
    margin-bottom: 40px;
    padding-top: 20px;
  `)}
`;

const HeaderClose = styled.div`
  margin: 0 -7px 13px 0;
  text-align: right;
`;

const Title = styled.h2`
  ${title};
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
`;

const TitleAdaIcon = styled(IconContainer)`
  flex-shrink: 0;
  margin-left: 20px;
  margin-right: -7px;

  ${tablet(css`
    height: 28px;
    margin-right: -4px;
    width: 28px;
  `)}

  ${desktopExtraLarge(css`
    height: 30px;
    margin-right: -7px;
    width: 30px;
  `)}
`;

const Subtitle = styled.p`
  ${label};
  font-weight: normal;
  vertical-align: baseline;
`;

const GroupHeader = styled.div`
  align-items: center;
  background: ${p => p.theme.colors.white};
  border-bottom: 1px solid ${p => p.theme.colors.lightGray};
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding-bottom: 16px;
  z-index: 10;

  ${tablet(css`
    padding-bottom: 8px;
  `)}
`;

const GroupHeaderTitle = styled.span`
  ${label};
  vertical-align: baseline;
`;

const TextContent = styled.p<{ scheduleMargin?: boolean }>`
  ${content}
  color: ${p => p.theme.colors.gray7F};

  ${p =>
    p.scheduleMargin
      ? css`
          margin-top: 20px;

          ${tablet(css`
            margin-top: 5px;
          `)}
        `
      : css`
          margin-bottom: 12px;

          ${tablet(css`
            margin-bottom: 8px;
          `)}

          &:last-child {
            margin-bottom: 0;
          }
        `}
`;

const DayScheduleText = styled.p<{ bold?: boolean }>`
  ${content}
  color: ${p => p.theme.colors.gray7F};
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  ${tablet(css`
    margin-bottom: 9px;
  `)}

  ${p =>
    p.bold &&
    css`
      color: ${p.theme.colors.black};
    `}
`;

interface VaccineLocationInfoProps {
  content: VaccineLocation;
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
}
const VaccineLocationInfo: React.FC<VaccineLocationInfoProps> = ({
  onCloseClick,
  content,
}) => {
  if (!content) {
    return null;
  }

  const {
    attributes: {
      ada,
      appointment,
      facilityAddress,
      facilityName,
      facilityPhone,
      facilityType,
      facilityWebsite,
      id: locationId,
      openSchedule,
      paymentOptions,
      targetOptions,
      vaccineOptions,
    },
  } = content;

  const dateNow = overridableNow();
  const todaySchedule = createTodaySchedule(dateNow, openSchedule);
  const showOpenSchedule = openSchedule.some(item => item !== null);
  const weekDaysOrder = getWeekDaysOrderFromToday(dateNow);

  return (
    <Wrapper>
      <Header>
        {!isPhone() && (
          <HeaderClose>
            <CloseButton onClick={onCloseClick} />
          </HeaderClose>
        )}
        <Title>
          <span>{facilityName}</span>
          {ada && (
            <TitleAdaIcon>
              <Icon type={IconTypes.Accessibility} />
            </TitleAdaIcon>
          )}
        </Title>
        <Subtitle>{facilityType}</Subtitle>
      </Header>

      <GroupHeader>
        <GroupHeaderTitle>Info</GroupHeaderTitle>
      </GroupHeader>
      <ul>
        {!!vaccineOptions.length && (
          <VaccineLocationInfoItem
            locationId={locationId}
            copy={
              vaccineOptions.length > 1
                ? `COVID-19 Vaccines: ${vaccineOptions.join(' and ')}`
                : `COVID-19 Vaccine: ${vaccineOptions}`
            }
            iconType={IconTypes.Vaccine}
          />
        )}
        {!!targetOptions.length && (
          <VaccineLocationInfoItem
            locationId={locationId}
            copy={
              targetOptions.length > 2
                ? `${targetOptions[0]} and ${targetOptions.length - 1} more`
                : targetOptions.join(' / ')
            }
            iconType={IconTypes.Person}
          >
            {targetOptions.length > 2 && (
              <>
                {targetOptions.map(optionText => (
                  <TextContent key={optionText}>{optionText}</TextContent>
                ))}
              </>
            )}
          </VaccineLocationInfoItem>
        )}
        <VaccineLocationInfoItem
          locationId={locationId}
          copy={appointment.options.join(' / ')}
          iconType={
            appointment.walkInOnly
              ? IconTypes.CalendarDisabled
              : IconTypes.Calendar
          }
          styleAlert={appointment.requiredOnly}
        />
        {showOpenSchedule && (
          <VaccineLocationInfoItem
            locationId={locationId}
            copy={todaySchedule.copy}
            iconType={IconTypes.Clock}
            styleAlert={todaySchedule.isClosed}
            styleWarning={todaySchedule.isClosingSoon}
          >
            <>
              {weekDaysOrder.map(dayIndex => {
                const data = openSchedule[dayIndex];
                return (
                  <DayScheduleText
                    bold={dateNow.getDay() === dayIndex}
                    key={dayIndex}
                  >
                    <span>{WEEKDAY_LABELS[dayIndex]}</span>
                    <span>
                      {data
                        ? `${getTimeAmPm(data.open)}-${getTimeAmPm(data.close)}`
                        : 'Closed'}
                    </span>
                  </DayScheduleText>
                );
              })}
              <TextContent scheduleMargin={true}>
                Availability may change during holidays.
              </TextContent>
            </>
          </VaccineLocationInfoItem>
        )}
        {!!paymentOptions.length && (
          <VaccineLocationInfoItem
            locationId={locationId}
            copy={
              paymentOptions.length < 3
                ? paymentOptions.join(' / ')
                : `${paymentOptions[0]} and ${paymentOptions.length - 1} more`
            }
            iconType={IconTypes.DollarSign}
          >
            {paymentOptions.length > 2 && (
              <>
                {paymentOptions.map(optionText => (
                  <TextContent key={optionText}>{optionText}</TextContent>
                ))}
              </>
            )}
          </VaccineLocationInfoItem>
        )}
        <VaccineLocationInfoItem
          locationId={locationId}
          copy={facilityAddress}
          iconType={IconTypes.Pin}
        />
      </ul>

      {!!(facilityWebsite || facilityPhone) && (
        <>
          <GroupHeader>
            <GroupHeaderTitle>Help</GroupHeaderTitle>
          </GroupHeader>
          <ul>
            {facilityPhone && (
              <VaccineLocationInfoItem
                locationId={locationId}
                copy={facilityPhone}
                iconType={IconTypes.Phone}
                url={`tel:${facilityPhone}`}
              />
            )}
            {facilityWebsite && (
              <VaccineLocationInfoItem
                locationId={locationId}
                copy="Schedule appointment"
                iconType={IconTypes.BlankPage}
                url={facilityWebsite}
              />
            )}
          </ul>
        </>
      )}
    </Wrapper>
  );
};

export default VaccineLocationInfo;

const createTodaySchedule = (
  dateNow: Date,
  openSchedule: ({ close: number; open: number } | null)[]
): {
  copy: string;
  isClosed: boolean;
  isClosingSoon: boolean;
} => {
  const hoursNow = dateNow.getHours();
  const minutesNow = dateNow.getMinutes();
  const todayDayIndex = dateNow.getDay();
  const todayHours = openSchedule[todayDayIndex];

  const timeNow = parseInt(
    `${hoursNow}${minutesNow < 10 ? `0${minutesNow}` : minutesNow}`
  );

  let copy = 'Closed';
  let isClosed = !todayHours;
  let isClosingSoon = false;

  if (todayHours) {
    isClosed = timeNow < todayHours.open || timeNow >= todayHours.close;
    isClosingSoon = !isClosed && timeNow >= todayHours.close - 100;

    copy = `Open now · Closes at ${getTimeAmPm(todayHours.close)}`;
  }

  if (isClosingSoon) {
    copy = 'Closing soon';
  }

  if (isClosed) {
    // If it's closed before opening today
    if (todayHours && timeNow < todayHours.open) {
      copy = `Closed · Opens at ${getTimeAmPm(todayHours.open)}`;
    } else {
      let nextOpenDay: { close: number; open: number } | null = null;
      let nextDayIndex = todayDayIndex;

      do {
        nextDayIndex = nextDayIndex < 6 ? nextDayIndex + 1 : 0;
        nextOpenDay = openSchedule[nextDayIndex];
      } while (!nextOpenDay && todayDayIndex !== nextDayIndex);

      if (nextOpenDay) {
        // if the next open day is not the following (tomorrow) we
        // show the week day name
        const weekDaysOrder = getWeekDaysOrderFromToday(dateNow);
        const isNextOpenDayTomorrow = weekDaysOrder.indexOf(nextDayIndex) === 1;

        copy = `Closed · Opens ${
          isNextOpenDayTomorrow ? '' : `on ${WEEKDAY_LABELS[nextDayIndex]}`
        } at ${getTimeAmPm(nextOpenDay.open)}`;
      }
    }
  }

  return { copy, isClosed, isClosingSoon };
};

const getTimeAmPm = (time: number): string => {
  const timeString = `${time < 1000 ? '0' : ''}${time}`;
  const hours = parseInt(timeString.slice(0, 2), 10);
  const minutes = timeString.slice(2);
  const amPm = hours < 12 ? 'am' : 'pm';

  if (minutes === '00') {
    return `${hours > 12 ? hours - 12 : hours}${amPm}`;
  }

  return `${hours > 12 ? hours - 12 : hours}:${minutes}${amPm}`;
};

const getWeekDaysOrderFromToday = (dateNow: Date) => {
  const weekDaysOrder: number[] = [];
  let currentDayIndex = dateNow.getDay();

  while (weekDaysOrder.length < 7) {
    weekDaysOrder.push(currentDayIndex);
    currentDayIndex = currentDayIndex < 6 ? currentDayIndex + 1 : 0;
  }

  return weekDaysOrder;
};
