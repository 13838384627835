import flatten from 'lodash/flatten';
import { otpStopsForRoute } from '../subway-data';
import {
  EnhancedD3DagNodeLink,
  getStopsForBaseStops,
  getStopsForSkippableStopsList,
  getStopsListsFromNodeLinkLists,
} from '../subway-data/patternGraph';
import { addMissingBaseStops } from '../subway-data/patternGraph/add-missing-base-stops';
import { unabbreviate } from '../subway-data/patternGraph/unabbreviate';
import {
  OtpRouteId,
  PatternGraphNode,
  RouteDirection,
  StopLists,
} from '../subway-data/subway-types';
import { now } from '../utils/date.utils';
import loadRouteStopsAtTimeInOneDirection from './loadRouteStopsAtTimeInOneDirection';

const loadRouteStopsAtTime = async ({
  routeId,
  date = now(),
  directionId,
}: {
  routeId: OtpRouteId;
  date?: Date;
  // Usually directionId is unspecified and both directions are loaded
  // but it can be forced for testing purposes
  directionId?: RouteDirection;
}): Promise<StopLists> => {
  // Prevent empty string from infiltrating routeId
  if (!routeId) return [];

  // We use the current directionId or
  // start the list with the Uptown direction.
  let enhancedNodeLinkLists: EnhancedD3DagNodeLink<PatternGraphNode>[][] = [
    await loadRouteStopsAtTimeInOneDirection({
      date,
      directionId: directionId ?? '0',
      routeId,
    }),
  ];

  // if we don't have a directionId, we will complete
  // the list of dags adding the Downtown direction
  if (!directionId) {
    enhancedNodeLinkLists.push(
      await loadRouteStopsAtTimeInOneDirection({
        date,
        directionId: '1',
        routeId,
      })
    );
  }

  // Remove empty node links list that could be from a DAG error
  // or having only bus stops, for example
  enhancedNodeLinkLists = enhancedNodeLinkLists.filter(
    enhancedNodeLinkList => enhancedNodeLinkList.length > 0
  );

  const customizedStopsLists = getStopsListsFromNodeLinkLists(
    enhancedNodeLinkLists,
    routeId
  );

  const staticStops = otpStopsForRoute(routeId);
  const unabbreviatedStopsLists = customizedStopsLists
    .map(stopsList =>
      unabbreviate(stopsList, staticStops, routeId, directionId)
    )
    .filter(list => !!list.length);

  const skippableStopListsOnRoute: StopLists = unabbreviatedStopsLists.map(
    stopsList => getStopsForSkippableStopsList(routeId, stopsList)
  );

  let finalStopLists: StopLists = skippableStopListsOnRoute;
  // Currently some routes like the E and R come back empty from unabbreviate(),
  // because they diverge more from the static base map than the code anticipates.
  // TODO: fix for the E and R; add unit tests
  const totalNumStops = flatten(skippableStopListsOnRoute).length;
  if (!totalNumStops) {
    finalStopLists = customizedStopsLists.map(customizedStopsList =>
      getStopsForBaseStops(routeId, customizedStopsList)
    );
  }

  return addMissingBaseStops(finalStopLists, otpStopsForRoute(routeId));
};

export default loadRouteStopsAtTime;
