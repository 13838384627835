import React from 'react';
import styled, { css } from 'styled-components/macro';
import ShortcutIcon, { ShortcutIconsEnum } from './ShortcutsIcon';
import { desktop } from '../../utils/theme';
import { content, subTitle } from '../Text';

interface SpacerProps {
  value: number;
}
const Spacer = styled.div<SpacerProps>`
  ${desktop(css`
    height: ${(p: SpacerProps) => p.value}px;
    width: 100%;
  `)}
`;

const ShortcutHeading = styled.h3`
  ${subTitle};
  margin: 32px 0;

  ${desktop(css`
    margin-top: 0;
  `)}
`;

const Shortcut = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;

  p {
    ${content};
    margin-right: 8px;
    width: 60%;
  }

  > span {
    display: flex;
    width: 35%;

    > :not(:last-of-type) {
      margin-right: 2px;
    }
  }
`;

type Direction = 'Up' | 'Right' | 'Left' | 'Down';
const ShortcutKeyIcon = styled.div<{
  direction: Direction;
}>`
  align-items: center;
  border: 2px solid ${p => p.theme.colors.black};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  transform: ${p =>
    `rotate(${
      {
        Up: '180deg',
        Down: 0,
        Right: '270deg',
        Left: '90deg',
      }[p.direction || 'Down']
    })`};

  svg {
    display: block;
    height: 21px;
    margin: 0 auto;
    width: 21px;
  }
`;

const ShortcutKeyLetter = styled.div<{ modifier?: boolean }>`
  border: 2px solid ${p => p.theme.colors.black};
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1;
  padding: ${p => (p.modifier ? '8px 5px 3px 3px' : '6px 7px')};
  text-align: center;
`;

const renderShortcutKeys = (keys: Key[] | undefined, itemKey: string) =>
  keys?.map((key, keyIndex) => {
    const keyItemIndex = `${itemKey}-${keyIndex}`;

    if (Object.values(ShortcutIconsEnum).includes(key as ShortcutIconsEnum)) {
      return (
        <ShortcutKeyIcon
          direction={key.split('arrow')[1] as Direction}
          key={keyItemIndex}
        >
          <ShortcutIcon type={key as ShortcutIconsEnum} />
        </ShortcutKeyIcon>
      );
    } else {
      const isModifierKey = ['Ctrl', 'Shift', 'ESC'].includes(key);

      return (
        <ShortcutKeyLetter modifier={isModifierKey} key={keyItemIndex}>
          <span>
            {key}
            {isModifierKey && <span>&nbsp;&nbsp;</span>}
          </span>
        </ShortcutKeyLetter>
      );
    }
  });

const renderItem = (item: Item, index: number) => {
  const itemKey = `listItem-${index}`;
  const itemMap = {
    heading: <ShortcutHeading key={itemKey}>{item.value}</ShortcutHeading>,
    spacer: <Spacer key={itemKey} value={item.spacing || 0} />,
    shortcut: (
      <Shortcut key={itemKey}>
        <p>{item.label}</p>
        <span>{renderShortcutKeys(item.keys, itemKey)}</span>
      </Shortcut>
    ),
  };

  return itemMap[item.type];
};

type Key = ShortcutIconsEnum | string;
export interface Item {
  type: 'heading' | 'spacer' | 'shortcut';
  value?: string;
  label?: string;
  keys?: Key[];
  spacing?: number;
}
const ShortcutsList: React.FC<{ items: Item[] }> = ({ items }) => (
  <div>{items.map(renderItem)}</div>
);

export default ShortcutsList;
