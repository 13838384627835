import { Feature, Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style } from 'ol/style';

import { Point } from '../../geometry/geometry-types';
import { drawRoundSingleLineForPoints } from '../../geometry/paths';

import { RENDER_BUFFER_ROUTES } from '../maps-constants';
import { getMapTheme } from '../maps-theme';
import { getRouteLineWidth } from '../maps-utils';

const getStyle = (useDarkMap: boolean) => {
  const colorsToUse = getMapTheme(useDarkMap);

  return new Style({
    renderer: (coordinates, state) => {
      const { context, pixelRatio, resolution } = state;

      context.beginPath();
      context.lineCap = 'round';
      context.strokeStyle = colorsToUse.transferLines;
      context.lineWidth = getRouteLineWidth(pixelRatio, resolution) / 5;

      drawRoundSingleLineForPoints(coordinates as Point[], [0], context);

      context.stroke();
    },
  });
};

let subwayStationTransferLinesLayer: VectorLayer;
export const addSubwayStationTransferLinesLayer = ({
  features,
  map,
  useDarkMap,
}: {
  features: Feature[];
  map: Map;
  useDarkMap: boolean;
}) => {
  if (subwayStationTransferLinesLayer) {
    subwayStationTransferLinesLayer.setSource(
      new VectorSource({
        features,
      })
    );
    subwayStationTransferLinesLayer.setStyle(getStyle(useDarkMap));
  } else {
    subwayStationTransferLinesLayer = new VectorLayer({
      minZoom: 13.999,
      renderBuffer: RENDER_BUFFER_ROUTES,
      source: new VectorSource({
        features,
      }),
      style: getStyle(useDarkMap),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(subwayStationTransferLinesLayer);
  }
};
