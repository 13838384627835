import { LineABC, Point } from './geometry-types';

export const RAD_45 = Math.PI / 4;
export const RAD_90 = Math.PI / 2;
export const RAD_180 = Math.PI;
export const RAD_270 = 1.5 * Math.PI;
export const RAD_360 = 2 * Math.PI;
export const RAD_TO_DEG = 180 / Math.PI;
export const DEG_TO_RAD = Math.PI / 180;

export const toDegrees = (angleRadians: number) => angleRadians * RAD_TO_DEG;
export const toRadians = (angleDegrees: number) => angleDegrees * DEG_TO_RAD;

/**
 * Converts an angle to its equivalent in a standard range.
 * @returns Radians in the range -π < value <= π
 */
export const normalizeAngle = (angleRadians: number) =>
  angleRadians - Math.ceil(angleRadians / RAD_360 - 0.5) * RAD_360;

/**
 * Converts an angle to its equivalent in a standard range.
 * @returns Degrees in the range -180° < value <= 180°
 */
export const normalizeAngleDegrees = (angleDegrees: number) =>
  angleDegrees - Math.ceil(angleDegrees / 360 - 0.5) * 360;

export const fromPolar = (
  radius: number,
  angleRadians: number,
  [centerX, centerY]: Point = [0, 0]
): Point => [
  centerX + radius * Math.cos(angleRadians),
  centerY + radius * Math.sin(angleRadians),
];

export const fromPolarDegrees = (
  radius: number,
  angleDegrees: number,
  [centerX, centerY]: Point = [0, 0]
): Point => fromPolar(radius, toRadians(angleDegrees), [centerX, centerY]);

export const add = (a: Point, b: Point): Point => [a[0] + b[0], a[1] + b[1]];

export const subtract = (a: Point, b: Point): Point => [
  a[0] - b[0],
  a[1] - b[1],
];

export const distance = (a: Point, b: Point): number => {
  const dx = b[0] - a[0];
  const dy = b[1] - a[1];
  return Math.sqrt(dx * dx + dy * dy);
};

export const scale = (
  [x, y]: Point,
  xScale: number,
  yScale = xScale
): Point => [x * xScale, y * yScale];

export const rotate = ([x, y]: Point, angleRadians: number): Point => {
  const ca = Math.cos(angleRadians);
  const sa = Math.sin(angleRadians);
  return [x * ca - y * sa, x * sa + y * ca];
};

export const rotateDegrees = (a: Point, angleDegrees: number): Point =>
  rotate(a, angleDegrees * DEG_TO_RAD);

export const rotatePoints = (
  points: Point[],
  angleRadians: number
): Point[] => {
  return points.map(p => rotate(p, angleRadians));
};

export const rotatePointsDegrees = <T extends Point[]>(
  points: T,
  angleDegrees: number
): T => {
  return points.map(p => rotateDegrees(p, angleDegrees)) as T;
};

export const flipX = (a: Point): Point => [-a[0], a[1]];

export const flipY = (a: Point): Point => [a[0], -a[1]];

export const angleAB = (a: Point, b: Point): number =>
  Math.atan2(b[1] - a[1], b[0] - a[0]);

export const angleABC = (a: Point, b: Point, c: Point): number =>
  normalizeAngle(
    Math.atan2(c[1] - b[1], c[0] - b[0]) - Math.atan2(b[1] - a[1], b[0] - a[0])
  );

export const angleDegreesABC = (a: Point, b: Point, c: Point): number =>
  (angleABC(a, b, c) * 180) / Math.PI;

export const normalAngleAB = (a: Point, b: Point): number => {
  if (!a || !b) {
    // TODO: figure out why undefined values are being passed (probably subway route id that's missing data)
    console.warn('normalAngleAB: something missing: a, b', a, b);
    return 0;
  }
  const dx = b[0] - a[0];
  const dy = b[1] - a[1];
  // The normals to (dx, dy) are (-dy, dx) and (dy, -dx).
  // We're using the former.
  return Math.atan2(dx, -dy);
};

export const unitNormalAB = (a: Point, b: Point): Point => {
  // The normals to (dx, dy) are (-dy, dx) and (dy, -dx)
  const dx = b[0] - a[0];
  const dy = b[1] - a[1];
  const length = Math.sqrt(dx * dx + dy * dy);
  return [-dy / length, dx / length];
};

export const normalAnglesABC = ([a, b, c]: LineABC): [
  number,
  number,
  number
] => {
  const normalAtA = normalAngleAB(a, b);
  const normalAtC = normalAngleAB(b, c);
  // The elbow normal is the average of the first and last normals
  const normalAtB = (normalAtA + normalAtC) / 2;
  return [normalAtA, normalAtB, normalAtC];
};

export const getSegmentsNormalAngles = (points: Point[]): number[] => {
  if (points.length < 2) return [];
  if (points.length === 2) return [normalAngleAB(points[0], points[1])];

  const segmentsNormals: number[] = [];
  for (let i = 0; i < points.length - 1; i++) {
    segmentsNormals[i] = normalAngleAB(points[i], points[i + 1]);
  }
  return segmentsNormals;
};

export const getSegmentsNormalAnglesDegrees = (points: Point[]): number[] =>
  getSegmentsNormalAngles(points).map(toDegrees);

export const pointsNormalAngles = (
  points: Point[],
  segmentsNormals = getSegmentsNormalAngles(points)
): number[] => {
  if (points.length < 2) return [];
  const firstNormal = segmentsNormals[0];
  if (points.length === 2) return [firstNormal, firstNormal];

  const pointsNormals: number[] = [firstNormal];
  for (let i = 1; i < points.length - 1; i++) {
    const prevSegmentNormal = segmentsNormals[i - 1];
    let nextSegmentNormal = segmentsNormals[i];
    const normalACDiff = normalizeAngle(nextSegmentNormal - prevSegmentNormal);
    pointsNormals[i] = prevSegmentNormal + normalACDiff / 2;
  }
  const lastNormal = segmentsNormals[segmentsNormals.length - 1];
  pointsNormals[segmentsNormals.length] = lastNormal;
  return pointsNormals;
};

export const pointsNormalAnglesDegrees = (points: Point[]): number[] =>
  pointsNormalAngles(points).map(toDegrees);

/**
 *
 *
 * @param spacing Amount of space between items
 * @param index Zero-based index of the item, <= maxIndex
 * @param maxIndex 0 if one dot, 1 if two dots, etc.
 * @example
 *    .    - maxIndex 0, only one dot so its position isn't shifted
 *   . .   - maxIndex 1, left & right are shifted from center by spacing/2
 *  . . .  - maxIndex 2, left & right are shifted from center by spacing
 * . . . . - maxIndex 3, similar to maxIndex 1 but with 2 more dots
 */
export const parallelSpacingAtIndex = (
  spacing: number,
  index: number,
  maxIndex: number
) => {
  let parallelSpacing = 0;
  if (0 <= index && index <= maxIndex) {
    const ratio = index - 0.5 * maxIndex;
    parallelSpacing = spacing * ratio;
  }
  return parallelSpacing;
};

/**
 * https://en.wikipedia.org/wiki/Line%E2%80%93line_intersection#Given_two_points_on_each_line
 */
export const intersect2Lines = (
  [x1, y1]: Point,
  [x2, y2]: Point,
  [x3, y3]: Point,
  [x4, y4]: Point
): Point => {
  const a = x1 * y2 - y1 * x2;
  const b = x3 * y4 - y3 * x4;
  const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
  const intersectX = (a * (x3 - x4) - b * (x1 - x2)) / denominator;
  const intersectY = (a * (y3 - y4) - b * (y1 - y2)) / denominator;
  return [intersectX, intersectY];
};
