// Downtown is direction 1
// This list is a specific modifier for a stop. If the route
// modifier is not enough, we fix some arrow stations manually.
export const stopOneDirectionAnglesForStopId = {
  142: 90, // South Ferry
  247: 90, // Flatbush Av Brooklyn College
  257: -83, // New Lots Av
  '640_6': 180, // Brooklyn Bridge - City Hall - Specific for 6 route
  726: 180, // 34 St - 11 Av
  A55_C: -85, // Euclid Av - Specific for W route
  A65: 45, // Ozone Park - Lefferts Blvd
  B10: 45, // 57 St
  D26_FS: 96, // Prospect Park
  D40_B: 180, // Brighton Beach - Specific for B route
  D43_F: 180, // Coney Island - Stillwell Av - Specific for F route
  D43_Q: 180, // Coney Island - Stillwell Av - Specific for Q route
  E01: 45, // World Trade Center
  F27_G: 90, // Church Av - Specific for G route
  H11: 43, // Far Rockaway - Mott Av
  H15: 90, // Rockaway Park - Beach 116 St
  L29: 180, // Canarsie - Rockaway Pkwy
  N10_W: 180, // 86 St - Specific for W route
  R45: 170, // Bay Ridge - 95 St
  S09: 215, // Tottenville
};
