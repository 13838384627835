import React from 'react';
import styled, { css } from 'styled-components/macro';
import FocusTrap from 'focus-trap-react';
import Icon, { IconTypes, IconContainer } from '../components/Icon';
import theme, { withFocusStyle, touch } from '../utils/theme';
import { content, label, subTitle } from './Text';

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${p => p.theme.zIndex.modals};
  width: 100vw;
  max-width: 400px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
`;

const ModalWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;

  ${touch(css`
    padding-bottom: 0;
  `)}
`;

const ModalCloseContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  ${touch(css`
    display: none;
  `)}
`;

const ModalClose = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  color: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  transition: all 0.2s ease-in-out;

  ${// Forces to use the light theme because it's inside a white area
  withFocusStyle(theme)}

  &:hover {
    background-color: black;
    border-color: black;
    color: white;
  }
`;

const ModalTouchCloseContainer = styled.div`
  display: none;

  ${touch(css`
    display: block;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 55px;
  `)}
`;

const ModalTouchClose = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 55px;
  width: 100%;
  color: #fff;
`;

const ModalContent = styled.div<{ renderFooter?: React.ComponentType }>`
  ${content};
  color: ${p => p.theme.colors.white};
  overflow-x: hidden;
  padding-bottom: ${p => (p.renderFooter ? '20px' : '5px')};
  position: relative;
  width: 100%;
`;

const ModalTitle = styled.h2`
  ${subTitle};
  margin-top: 16px;
  margin-bottom: 12px;
`;

const ModalFooter = styled.footer`
  padding-bottom: 25px;
  text-align: left;

  p {
    ${label};
  }

  svg {
    transform: scale(0.8) translateY(6px);
    margin-left: 4px;
    margin-right: 4px;
  }
`;

interface ModalProps {
  isOpen: boolean;
  title: string;
  renderFooter?: React.ComponentType;
  onRequestClose: () => void;
  shouldShowLogo?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  title,
  children,
  renderFooter: FooterContent,
  shouldShowLogo = false,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <FocusTrap>
      <ModalContainer>
        <ModalWrapper>
          <ModalContent renderFooter={FooterContent}>
            <ModalCloseContainer>
              <ModalClose onClick={() => onRequestClose()}>
                <IconContainer>
                  <Icon type={IconTypes.Cross} />
                </IconContainer>
              </ModalClose>
            </ModalCloseContainer>

            {shouldShowLogo && (
              <svg width="26" height="28" xmlns="http://www.w3.org/2000/svg">
                <g fillRule="nonzero" fill="none">
                  <g fill="#0039A6">
                    <path d="M.0224 5.9864C2.5536 2.3688 6.7536 0 11.5024 0c7.7336 0 14 6.2664 14 14s-6.2664 14-14 14C6.944 28 2.8896 25.816.336 22.4448" />
                    <path d="M19.0064 12.6336l-.28 3.1136.7224-.0448-.2688-3.0688z" />
                  </g>
                  <path
                    d="M1.904 22.1648s0-4.8944-.0448-5.4488c0-.532-.2632-3.5952-.2072-4.1104l.1456.0056 1.8368 9.2568 2.5704-.4536L7.7 12.8408h.168c.0784.5264-.1344 3.0128-.1344 3.5448-.0448.5544-.0448 4.76-.0448 4.76l2.604-.4536V7.784l-4.0656-.7056-1.1424 7.9072c-.0392 0-1.1536-8.3104-1.1536-8.3104L0 5.992l.3192 16.4528M18.4464 17.9816l1.2544-.1624.1624 1.1984 1.4672-.252-1.4056-9.2904-1.7472-.3024L16.408 19.628l1.8648-.3304zM10.9704 11.228l1.9712.1736.0056 8.8256 2.408-.42v-8.1928l1.5568.1288V8.932l-5.9416-1.0304z"
                    fill="#FFF"
                  />
                </g>
              </svg>
            )}

            <ModalTitle>{title}</ModalTitle>

            {children}
          </ModalContent>

          {FooterContent && (
            <ModalFooter>
              <FooterContent />
            </ModalFooter>
          )}
        </ModalWrapper>
        <ModalTouchCloseContainer>
          <ModalTouchClose onClick={() => onRequestClose()}>
            Close
          </ModalTouchClose>
        </ModalTouchCloseContainer>
      </ModalContainer>
    </FocusTrap>
  );
};

export default Modal;
