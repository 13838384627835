import { complexStations } from '../subway-data/complex-stations';
import {
  StationStatusEntry,
  TrainTime,
} from '../subway-data/station-status-types';
import { OTP_API_ROUTE, OTP_API_KEY } from '../subway-data';
import { calculateArrivalTimeInSecondsFromArrivalFmt } from '../utils/trainTimes.utils';

// API Documentation can be found:
// http://developer.onebusaway.org/modules/OpenTripPlanner/1.3.0.37/enunciate/apidocs/resource_NearbySchedulesResource.html
// http://developer.onebusaway.org/modules/OpenTripPlanner/1.3.0.37/enunciate/apidocs/json_TripTimeShort.html

const combineComplexIds = (complexIds: string[]): string => {
  return complexIds.map(stopId => `MTASBWY:${stopId}`).join();
};

const checkForComplexStopIds = (stopId: string) => {
  return complexStations[stopId]
    ? combineComplexIds(complexStations[stopId])
    : `MTASBWY:${stopId}`;
};

const generateURL = (stopId: string) => {
  const combinedStops = checkForComplexStopIds(stopId);

  const routesToFilterBy = '';
  // EX: 'MTASBWY__L'

  // unifies data
  const groupByParent = 'true';

  const timeRangeInSeconds = 60 * 60;

  const requestURL = `${OTP_API_ROUTE}/nearby?stops=${combinedStops}&apikey=${OTP_API_KEY}&groupByParent=${groupByParent}&routes=${routesToFilterBy}&timeRange=${timeRangeInSeconds}`;
  return requestURL;
};

export const loadStationStatus = async (
  stopId: string
): Promise<{ trainTimes: TrainTime[] } | undefined> => {
  try {
    const statusPerStation: StationStatusEntry[] = await fetch(
      generateURL(stopId)
    ).then(res => res.json());

    const trainTimes: TrainTime[] = [];

    statusPerStation.forEach(({ groups }) => {
      groups = groups.filter(({ times }) => times.length > 0);

      groups.forEach((routeEntry, index) => {
        // Nearby API 'routeId' must be extracted from format 'MTASBWY:H' for icons to render accurately
        const routeId: string = groups[index].route.id.replace('MTASBWY:', '');
        routeEntry.times.forEach(train => {
          const time = calculateArrivalTimeInSecondsFromArrivalFmt(
            // Sometimes arrivalFmt is not accurate at terminal stations - we should calculate with departureFmt instead
            // Case 1: arrivalFmt is undefined, realtimeArrival has a negative value (-1)
            // Case 2: arrivalFmt is set to the previous day at 23:43:21, realtimeArrival & scheduledArrival have a negative value (-999)
            !train.scheduledArrival && !train.realtimeArrival
              ? train.arrivalFmt ?? train.departureFmt
              : train.departureFmt
          );
          time >= 0 && // Redundancy check to ensure no negative arrivals
            trainTimes.push({
              routeId,
              directionId: train.directionId,
              longName: train.tripHeadsign,
              stopHeadsign: routeEntry.headsign,
              time: time,
              tripId: train.tripId?.replace('MTASBWY:', ''),
              isExpress: routeEntry.route.longName
                .toLowerCase()
                .includes('express'),
            });
        });
      });
    });

    return {
      trainTimes: trainTimes.sort((a, b) => a.time - b.time).slice(0, 40), // Capped at 40 arrivals
    };
  } catch (err) {
    console.error('unable to fetch nearby api', err);
  }
};

export default loadStationStatus;
