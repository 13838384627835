import React from 'react';
import styled, { css, keyframes } from 'styled-components/macro';
import {
  subwayRouteHoverColors,
  subwayRouteColors,
  subwayRouteInactiveColors,
  subwayRouteHasDarkText,
  subwayRouteLabelFromSubwayRouteId,
} from '../../subway-data';
import { SubwayRouteId } from '../../subway-data/subway-types';
import SubwayRouteIcon from '../SubwayRouteIcon';
import Icon, { IconTypes, IconContainer } from '../Icon';
import {
  withFocusStyle,
  withTouchPressStyle,
  notTouch,
  desktopExtraLarge,
  desktopLarge,
  tablet,
} from '../../utils/theme';
import { MENU_TRANSITION } from '../../utils/animations';

type FilterButtonContainerProps = {
  deemphasized?: boolean;
  inverted?: boolean;
  selectedRouteId?: SubwayRouteId | '';
};

const FilterButtonPlaceholderOuter = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  box-shadow: ${p => p.theme.boxShadow};

  ${tablet(css`
    width: 40px;
    height: 40px;
  `)};

  ${desktopLarge(css`
    width: 40px;
    height: 40px;
    svg {
      transform: scale(0.8);
    }
  `)};

  ${desktopExtraLarge(css`
    width: 50px;
    height: 50px;
    svg {
      transform: scale(0.95);
      margin-left: 2px;
    }
  `)};
`;

const DotsLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DotsLogo: React.FC = () => (
  <DotsLogoContainer>
    <svg
      viewBox="0 0 16 16"
      height="16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill="#FFCC00" />
      <circle cx="8" cy="2" r="2" fill="#FF8B00" />
      <circle cx="14" cy="2" r="2" fill="#FF553C" />
      <circle cx="2" cy="8" r="2" fill="#AED92B" />
      <circle cx="8" cy="8" r="2" fill="#3CBE3C" />
      <circle cx="14" cy="8" r="2" fill="#33BBFF" />
      <circle cx="2" cy="14" r="2" fill="#999999" />
      <circle cx="8" cy="14" r="2" fill="#B6772C" />
      <circle cx="14" cy="14" r="2" fill="#C372D4" />
    </svg>
  </DotsLogoContainer>
);

const SubwayFilterIcon: React.FC = () => (
  <FilterButtonPlaceholderOuter>
    <DotsLogo />
  </FilterButtonPlaceholderOuter>
);

const FilterButtonContainer = styled.button<FilterButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${p => p.theme.colors.button.bg};
  box-shadow: ${p => p.theme.boxShadow};
  color: ${p =>
    p.selectedRouteId
      ? p.deemphasized
        ? subwayRouteInactiveColors[p.selectedRouteId]
        : subwayRouteColors[p.selectedRouteId]
      : p.theme.colors.button.color};

  ${p => withFocusStyle(p.theme)}
  ${withTouchPressStyle()}

  ${notTouch(css`
    &:hover {
      color: ${(p: any) => subwayRouteHoverColors[p.selectedRouteId]};

      ${FilterButtonPlaceholderOuter} {
        background-color: ${p => p.theme.colors.button.bgActive};
      }

      path[data-letter='true'] {
        fill: ${(p: any) => (p.inverted ? 'black' : 'white')};
      }
    }
  `)}

  ${tablet(css`
    width: 40px;
    height: 40px;
  `)};

  ${desktopLarge(css`
    width: 40px;
    height: 40px;
  `)};

  ${desktopExtraLarge(css`
    width: 50px;
    height: 50px;
  `)};
`;

const SubwayRouteIconWrapper = styled.div`
  width: 24px;
  height: 24px;

  ${desktopExtraLarge(css`
    width: 30px;
    height: 30px;
  `)};
`;

const baseChevronTransform = (x: number) =>
  `translate3d(${x}%, -50%, 0) rotate3d(0, 0, 1, -90deg)`;
const startingChevronTransform = baseChevronTransform(50);
const endingChevronTransform = baseChevronTransform(-50);
const chevronSlideInAnimation = keyframes`
  from {
    transform: ${startingChevronTransform};
    opacity: 0;
  }

  to {
    transform: ${endingChevronTransform};
    opacity: 1;
  }
`;

const ChevronWrapper = styled(IconContainer)`
  animation: ${MENU_TRANSITION}ms linear ${MENU_TRANSITION * 4}ms 1 forwards
    ${chevronSlideInAnimation};
  color: ${p => p.theme.colors.black};
  height: 25px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: ${startingChevronTransform};
  width: 25px;
`;

const filterButtonLabel = (routeId?: SubwayRouteId | '') =>
  routeId
    ? `Unfilter ${subwayRouteLabelFromSubwayRouteId(routeId)} subway line`
    : `Open subway lines menu`;

const FilterButton: React.FC<{
  selectedRouteId?: SubwayRouteId | '';
  deemphasized?: boolean;
  isOpen: boolean;
  onClick?: (e: any) => void;
  onDoubleClick?: (e: any) => void;
}> = ({
  selectedRouteId,
  isOpen,
  deemphasized = false,
  onClick,
  onDoubleClick,
}) => (
  <FilterButtonContainer
    aria-label={filterButtonLabel(selectedRouteId)}
    data-cy="filter-button"
    onClick={onClick}
    onDoubleClick={onDoubleClick}
    selectedRouteId={selectedRouteId}
    tabIndex={isOpen ? 0 : -1}
    deemphasized={deemphasized}
    aria-haspopup="true"
    inverted={subwayRouteHasDarkText[selectedRouteId || '']}
  >
    {selectedRouteId ? (
      <SubwayRouteIconWrapper>
        <SubwayRouteIcon
          deemphasized={deemphasized}
          routeId={selectedRouteId}
          slideOut={true}
        />
        <ChevronWrapper>
          <Icon type={IconTypes.Chevron} />
        </ChevronWrapper>
      </SubwayRouteIconWrapper>
    ) : (
      <SubwayFilterIcon />
    )}
  </FilterButtonContainer>
);

export default FilterButton;
