/* eslint @typescript-eslint/no-unused-vars: 0 */
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import {
  OtpRouteId,
  PatternGraph,
  PatternGraphNode,
  RouteDirection,
  Stop,
  StopBase,
  StopList,
} from '../subway-types';
import {
  OTP_API_KEY,
  OTP_API_ROUTE,
  otpStopsForRoute,
  otpStopsOnRoutes,
} from '../index';
import { getPatternGraphDateTimeStrings, now } from '../../utils/date.utils';
import { dagStratify } from 'd3-dag';
import { findPrimaryStopIdFromAlternative } from '../complex-stations';
import { isABusStopId } from '../../maps/subway-routes.utils';
import { SkippableStopList } from './unabbreviate';
import { Immutable } from '../../utils/type.utils';
import { access } from 'fs';

export interface D3DagNodeLink<T> {
  source: D3DagNode<T>;
  target: D3DagNode<T>;
}

// This type covers a transitional moment of the data
// The links will receive extra properties based on the nodes
export interface EnhancedD3DagNodeLink<T> extends D3DagNodeLink<T> {
  isSourceTerminal?: boolean;
  isTargetTerminal?: boolean;
  sourceDirection?: RouteDirection | undefined;
  targetDirection?: RouteDirection | undefined;
}

interface D3DagNode<T> {
  children: this[];
  connected: () => boolean;
  data?: T;
  descendants: () => D3DagNode<T>[];
  id: string | undefined;
  links: () => D3DagNodeLink<T>[];
}

/**
 * A DAG is simply a collection of nodes, defined by every reachable child node from the current returned node. If a DAG contains multiple roots, then the returned node will be special in that it will have an undefined id and data and will be ignored when calling normal methods. Each child of this special returned node will be one of the roots of the DAG.
 * @see {@link https://github.com/erikbrinkman/d3-dag#dag|d3-dag docs}
 *
 * A root and a node and very much alike at runtime,
 * but this is a marker interface for purposes of documenting intent.
 */
export interface D3DagRoot<T> extends D3DagNode<T> {}

const getStopIdFromDagNode = (node: D3DagNode<PatternGraphNode>) => {
  return findPrimaryStopIdFromAlternative(
    node.data!.attribute.routes[0].stop.replace('MTASBWY_', '') ?? ''
  );
};

const removeDuplicatedStopsById = (stopsList: StopBase[]): StopBase[] => {
  // Ensure there are no duplicates in the subway stop list.
  // Sometimes a complex pattern can produce a repeated subway stop
  // after the local/express paths are flattened into one linear path.
  // For example: the express N train calls Canal St 'Q01' but the local N calls it 'R23'.
  // findPrimaryStopIdFromAlternative() replaces 'R23' with 'Q01' to unify the station for mapping purposes.
  // But before deduplicating, this made 'Q01' occur twice in the flattened path. See this issue:
  // https://github.com/workco/mta-map/issues/876
  return uniqBy(stopsList, 'stopId');
};

const createStopBase = (
  node: D3DagNode<PatternGraphNode>,
  direction: RouteDirection | undefined,
  isTerminal: boolean | undefined
): StopBase => {
  const { name: stopName } = node.data!.attribute;
  const stopId = getStopIdFromDagNode(node);

  return {
    direction,
    isTerminal,
    stopId,
    stopName,
  };
};
/**
 * Populate the node links with the source and target node directions from the
 * current loaded direction. It will allow us to compare and use this data
 * during the merge of the two directions.
 *
 * @param {D3DagNodeLink<PatternGraphNode>[]} nodeLinkList
 * @param {RouteDirection} directionId
 * @returns {EnhancedD3DagNodeLink<PatternGraphNode>[]}
 */
export const addDirectionToNodeLinkList = (
  nodeLinkList: D3DagNodeLink<PatternGraphNode>[],
  directionId: RouteDirection
): EnhancedD3DagNodeLink<PatternGraphNode>[] => {
  return nodeLinkList.map(nodeLink => {
    return {
      ...nodeLink,
      sourceDirection: directionId,
      targetDirection: directionId,
    };
  });
};

/**
 * We loop through the links list and add the terminal information of each link.
 * A terminal is a source node that doesn't have any target node linking to it
 * or a target node that has no children.
 *
 * This operation should happen before removing bus stops from the link list to
 * avoid false terminals in the middle of the route when we have part of the
 * subway stops replaced by buses.
 *
 * @param {D3DagNodeLink<PatternGraphNode>[]} nodeLinkList
 * @returns {EnhancedD3DagNodeLink<PatternGraphNode>[]}
 */
export const addTerminalToNodeLinkList = (
  nodeLinkList: D3DagNodeLink<PatternGraphNode>[]
): EnhancedD3DagNodeLink<PatternGraphNode>[] => {
  return nodeLinkList.map<EnhancedD3DagNodeLink<PatternGraphNode>>(nodeLink => {
    const sourceNodeId = nodeLink.source.id;
    const targetNodeChildrenLength = nodeLink.target.children.length;

    const hasATargetLinkingToTheSource = nodeLinkList.some(
      nodeLinkToCheck => nodeLinkToCheck.target.id === sourceNodeId
    );

    return {
      ...nodeLink,
      isSourceTerminal: !hasATargetLinkingToTheSource,
      isTargetTerminal: !targetNodeChildrenLength,
    };
  });
};

const isNodeOnNodeLinkList = (
  node: D3DagNode<PatternGraphNode>,
  nodeLinkList: EnhancedD3DagNodeLink<PatternGraphNode>[]
): boolean => {
  const nodeId = getStopIdFromDagNode(node);

  return nodeLinkList.some(
    nodeLink =>
      getStopIdFromDagNode(nodeLink.source) === nodeId ||
      getStopIdFromDagNode(nodeLink.target) === nodeId
  );
};

const inNodeTerminalOnNodeList = (
  node: D3DagNode<PatternGraphNode>,
  nodeLinkList: EnhancedD3DagNodeLink<PatternGraphNode>[]
): boolean => {
  const nodeId = getStopIdFromDagNode(node);

  return nodeLinkList.some(
    nodeLink =>
      (getStopIdFromDagNode(nodeLink.source) === nodeId &&
        nodeLink.isSourceTerminal) ||
      (getStopIdFromDagNode(nodeLink.target) === nodeId &&
        nodeLink.isTargetTerminal)
  );
};

const updateNodeLinkListWithOppositeDirection = (
  nodeLinkList: EnhancedD3DagNodeLink<PatternGraphNode>[],
  oppositeNodeLinkList: EnhancedD3DagNodeLink<PatternGraphNode>[]
): EnhancedD3DagNodeLink<PatternGraphNode>[] => {
  return nodeLinkList.map(nodeLink => {
    const isSourceBothDirections = isNodeOnNodeLinkList(
      nodeLink.source,
      oppositeNodeLinkList
    );
    const isTargetBothDirections = isNodeOnNodeLinkList(
      nodeLink.target,
      oppositeNodeLinkList
    );

    // Some routes can stop before the other in one of the directions.
    // If a terminal stop is not a terminal in both directions, we change it to false.
    // A > B > C > D and D > C > B will have only A and D as terminals.
    const isSourceTerminal =
      isSourceBothDirections && nodeLink.isSourceTerminal
        ? inNodeTerminalOnNodeList(nodeLink.source, oppositeNodeLinkList)
        : nodeLink.isSourceTerminal;
    const isTargetTerminal =
      isTargetBothDirections && nodeLink.isTargetTerminal
        ? inNodeTerminalOnNodeList(nodeLink.target, oppositeNodeLinkList)
        : nodeLink.isTargetTerminal;

    return {
      ...nodeLink,
      isSourceTerminal,
      isTargetTerminal,
      sourceDirection: isSourceBothDirections
        ? undefined
        : nodeLink.sourceDirection,
      targetDirection: isTargetBothDirections
        ? undefined
        : nodeLink.targetDirection,
    };
  });
};

/**
 * Loop through both node link lists and update the direction to undefined if
 * the stop exists in the opposite direction. After that, merge direction A
 * stops with the stops that exist only in direction B.
 *
 * @param {EnhancedD3DagNodeLink<PatternGraphNode>[][]} nodeLinkLists
 * @returns {EnhancedD3DagNodeLink<PatternGraphNode>[]}
 */
export const mergeBothDirectionsNodeLinkLists = (
  nodeLinkLists: EnhancedD3DagNodeLink<PatternGraphNode>[][]
): EnhancedD3DagNodeLink<PatternGraphNode>[] => {
  if (nodeLinkLists.length < 2) return nodeLinkLists[0] ?? [];

  const [nodeLinkListA, nodeLinkListB] = nodeLinkLists;

  // Set the direction on the node links of direction A
  const directionAUpdated = updateNodeLinkListWithOppositeDirection(
    nodeLinkListA,
    nodeLinkListB
  );

  // Set the direction on the node links of direction B
  const directionBUpdated = updateNodeLinkListWithOppositeDirection(
    nodeLinkListB,
    nodeLinkListA
  ).filter(nodeLinkB => {
    // Remove the duplicated links from direction B
    const sourceId = getStopIdFromDagNode(nodeLinkB.source);
    const targetId = getStopIdFromDagNode(nodeLinkB.target);

    return !directionAUpdated.some(
      nodeLinkA =>
        getStopIdFromDagNode(nodeLinkA.source) === sourceId &&
        getStopIdFromDagNode(nodeLinkA.target) === targetId
    );
  });

  return [...directionAUpdated, ...directionBUpdated];
};

/**
 * Takes the links from a directed acyclic graph (DAG) and creates the list of linear stops
 * See unit tests for examples of how tree structures are processed.
 *
 * @param {EnhancedD3DagNodeLink<PatternGraphNode>[]} nodeLinkList
 * @returns {StopBase[][]}
 */
export const getStopsListsFromNodeLinkList = (
  nodeLinkList: EnhancedD3DagNodeLink<PatternGraphNode>[]
): StopBase[][] => {
  let subwayStops: StopBase[] = [];
  let subwayStopsList: StopBase[][] = [];

  const nodeLinksTotal = nodeLinkList.length;

  let previousTargetId: string | undefined;

  nodeLinkList.forEach((nodeLink, index) => {
    const isLastIndex = index === nodeLinksTotal - 1;

    // If the id of the source of the current link is the same as
    // the previous target, we are still on the same path.
    if (nodeLink.source.id === previousTargetId) {
      subwayStops.push(
        createStopBase(
          nodeLink.target,
          nodeLink.targetDirection,
          nodeLink.isTargetTerminal
        )
      );
      previousTargetId = nodeLink.target.id;
    } else {
      // If we are changing to another path, we clear the current list,
      // removing the duplicated stops, and push it to the grouped lists.
      if (subwayStops.length) {
        subwayStopsList.push(removeDuplicatedStopsById(subwayStops));
      }

      // After closing the previous path, we create a new stops list
      // and add both the source and target of the current node link
      // to start the list.
      subwayStops = [];
      subwayStops.push(
        createStopBase(
          nodeLink.source,
          nodeLink.sourceDirection,
          nodeLink.isSourceTerminal
        )
      );
      subwayStops.push(
        createStopBase(
          nodeLink.target,
          nodeLink.targetDirection,
          nodeLink.isTargetTerminal
        )
      );
      previousTargetId = nodeLink.target.id;
    }

    if (isLastIndex) {
      subwayStopsList.push(removeDuplicatedStopsById(subwayStops));
    }
  });

  return subwayStopsList;
};

/**
 * Removes the bus stops from the stop list and move the terminal to the next
 * or previous valid subway stop in the same list.
 *
 * @param {StopBase[][]} subwayStopsList
 * @param {OtpRouteId} routeId
 * @returns {StopBase[][]}
 */
export const removeBusStopFromStopLists = (
  subwayStopsList: StopBase[][],
  routeId: OtpRouteId
): StopBase[][] => {
  let removedBusStopIds: string[] = [];

  const subwayStopsListWithoutBuses = subwayStopsList.map(
    subwayAndOrBusStops => {
      let busTerminalStop: StopBase | undefined;

      // When the 1 and L trains have shuttle buses on the weekend,
      // the graph is disconnected (it's multi-root).
      // So discard bus stops, which the map is unequipped to handle.
      // For now, the filtering is more flexible to remove only IDs confirmed to be bus stops,
      // rather than removing anything that doesn't match the subway stop ID pattern.
      // During development, we may discover other kinds of IDs in the data.
      // TODO: in the future we could tighten up the filter with isASubwayStopId().
      const subwayStops = subwayAndOrBusStops.reduce<StopBase[]>(
        (acc, subwayAndOrBusStop) => {
          if (isABusStopId(subwayAndOrBusStop.stopId)) {
            // If the bus stop we are removing is a terminal save it
            // for the next valid subway stop to be the terminal.
            busTerminalStop = subwayAndOrBusStop.isTerminal
              ? subwayAndOrBusStop
              : busTerminalStop;

            removedBusStopIds.push(subwayAndOrBusStop.stopId);
          } else {
            // If we have a previously removed terminal bus stop,
            // we set this valid subway stop as a terminal and clean the variable.
            if (busTerminalStop) {
              subwayAndOrBusStop.isTerminal = true;
              busTerminalStop = undefined;
            }

            // Add the subway stop to the list
            acc.push(subwayAndOrBusStop);
          }

          return acc;
        },
        []
      );

      // If we have "busTerminalStop" value after the loop, we removed a
      // bus stop at the end of the stops list, and the last valid subway
      // stop should be a terminal.
      const subwayStopsTotal = subwayStops.length;
      if (busTerminalStop && subwayStopsTotal) {
        subwayStops[subwayStopsTotal - 1].isTerminal = true;
        busTerminalStop = undefined;
      }

      return subwayStops;
    }
  );

  // List the removed bus stops on the console
  if (removedBusStopIds.length) {
    removedBusStopIds = uniq(removedBusStopIds);

    console.warn(
      `route '${routeId}': ${
        removedBusStopIds.length
      } stops were removed (shuttle bus stops): ${JSON.stringify(
        removedBusStopIds
      )}`
    );
  }

  // Remove empty stop lists that had only buses
  return subwayStopsListWithoutBuses.filter(
    subwayStopsList => !!subwayStopsList.length
  );
};

export const createPatternGraphDag = ({
  directionId = '1',
  patternGraph,
  routeId,
}: {
  directionId?: RouteDirection;
  patternGraph: PatternGraph;
  routeId: OtpRouteId;
}): D3DagRoot<PatternGraphNode> | undefined => {
  // If the line is not running, we don't receive PG nodes
  if (!patternGraph.nodes.length) return undefined;

  // Our static data is using the downtown direction.
  try {
    // The PG data brings the node and its successor.
    // To create the downtown direction, we need to reverse it.
    if (directionId === '1') {
      return dagStratify()
        .id(d => d.id)
        .parentIds(d => d.successors.map(x => x.id))(patternGraph.nodes)
        .reverse();
    }

    // The Uptown direction will be right with the
    // node and successor structure.
    return dagStratify()
      .id(d => d.id)
      .parentIds(d => d.successors.map(x => x.id))(patternGraph.nodes);
  } catch (err) {
    console.error(
      `Error in d3-dag stratify for Route ${routeId} going ${
        directionId === '1' ? 'downtown' : 'uptown'
      } (${directionId}) ----> ${err}`
    );
    return undefined;
  }
};

export const createNodeLinksListFromDag = ({ dag, directionId, routeId }) => {
  // We get here when the line is not running, empty nodes from PG data,
  // or if the data has an error when we stratify it.
  if (!dag) return [];

  // Get the list of all the links that exist in the DAG.
  // Using it, we have all the unique linear paths of the graph.
  let enhancedNodeLinks: EnhancedD3DagNodeLink<
    PatternGraphNode
  >[] = dag.links();

  // enhancedNodeLinks = removeBusStopsFromNodeLinkList(enhancedNodeLinks, directionId, routeId);
  enhancedNodeLinks = addDirectionToNodeLinkList(
    enhancedNodeLinks,
    directionId
  );
  enhancedNodeLinks = addTerminalToNodeLinkList(enhancedNodeLinks);

  return enhancedNodeLinks;
};

export const getStopsListsFromNodeLinkLists = (
  nodeLinkLists: EnhancedD3DagNodeLink<PatternGraphNode>[][],
  routeId: OtpRouteId
): StopBase[][] => {
  if (!nodeLinkLists.length) return [];

  const mergedNodeLinkList = mergeBothDirectionsNodeLinkLists(nodeLinkLists);
  const stopLists = getStopsListsFromNodeLinkList(mergedNodeLinkList);

  return removeBusStopFromStopLists(stopLists, routeId);
};

// TODO: rename to clarify this is only the static base map
export const getStopForStopId = (
  routeId: OtpRouteId,
  stopId: string
): Immutable<Stop> => {
  // Look in the static base map data for a stop with this ID assigned to this specific route
  const otpStopOnRoute = otpStopsForRoute(routeId).find(
    s => s.stopId === stopId
  );
  let stop = otpStopOnRoute;
  // When a rerouting dynamically puts a train through another route's stops,
  // it won't be in the static base map data.
  // So find it in the more raw, flatter data structure otpStopsOnRoutes and borrow it.
  if (!stop) {
    const borrowedStop = otpStopsOnRoutes.find(s => s.stopId === stopId);
    if (borrowedStop) {
      stop = { ...borrowedStop, routeId };
    }
    // TODO: get the borrowed station dot to render (may need to modify station-unified data)
    // If there's still no stop found, flag in console
    if (!stop) {
      console.error(
        'loadStopsForOneRouteAtTime() - Missing stop',
        stopId,
        'on route',
        routeId
      );
    }
  }
  // For now, assert non-undefined to assume we always find a stop
  return stop!;
};

export const getStopsForBaseStops = (
  routeId: OtpRouteId,
  baseStops: StopBase[]
): StopList => baseStops.map(stop => getStopForStopId(routeId, stop.stopId));

export const getStopsForSkippableStopsList = (
  routeId: OtpRouteId,
  skippableStopsList: SkippableStopList
): StopList =>
  skippableStopsList.map(skippableStop => {
    const stop = getStopForStopId(routeId, skippableStop.value.stopId);
    let stopType = stop.stopType;
    // Recalculate whether the train is making the local stop
    // TODO: create a new skipped property on Stop, separate from stopType: 2
    if (skippableStop.skipped) {
      stopType = '2';
    } else if (stop.stopType === '2') {
      stopType = '0';
    }

    return { ...stop, ...skippableStop.value, stopType };
  });

export const getPatternGraphUrl = ({
  routeId,
  directionId = '1',
  dateString = '',
  timeString = '',
}: {
  routeId: OtpRouteId;
  directionId?: RouteDirection;
  dateString?: string;
  timeString?: string;
}) => {
  // TODO: it is possible to load several routes at the same time with comma-separated values
  // (e.g. `MTASBWY:A,MTASBWY:C`). This would be better for performance, but when we do so the
  // graphs are intersected on the response. Wee would need to separate them somehow.
  const search = new URLSearchParams({
    routeIds: `MTASBWY:${routeId}`,
    directionId,
    date: dateString,
    time: timeString,
    apikey: OTP_API_KEY,
  }).toString();
  return `${OTP_API_ROUTE}/patternGraph?${search}`;
};

export const fetchPatternGraphForRoute = async ({
  routeId,
  date = now(),
  directionId = '1',
}: {
  routeId: OtpRouteId;
  directionId?: RouteDirection;
  date?: Date;
}): Promise<PatternGraph> => {
  const { dateString, timeString } = getPatternGraphDateTimeStrings(date);
  const url = getPatternGraphUrl({
    routeId,
    directionId,
    dateString,
    timeString,
  });

  const startTime = +new Date();
  const patternGraph = (await fetch(url).then(res =>
    res.json()
  )) as PatternGraph;

  return patternGraph;
};
