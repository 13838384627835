import {
  EmergencyAlert,
  EmergencyAlertResponse,
} from '../subway-data/otp/emergency-alerts-types';

const EMERGENCY_ALERTS_ENDPOINT =
  'https://new.mta.info/rest/emergency';

export const loadEmergencyAlerts = async (): Promise<EmergencyAlert | null> => {
  try {
    const emergencyAlertsPayload: EmergencyAlertResponse = await fetch(
      EMERGENCY_ALERTS_ENDPOINT
    ).then(res => res.json());

    return emergencyAlertsPayload[0] || null;
  } catch (err) {
    console.error('Error encountered in loading Emergency Alerts: \n', err);
    return null;
  }
};
