import { BoroughLabel } from './subway-types';

const boroughsLabels: BoroughLabel[] = [
  {
    name: 'Manhattan',
    lon: -73.969016,
    lat: 40.773662,
  },
  {
    name: 'Brooklyn',
    lon: -73.926536,
    lat: 40.654762,
  },
  {
    name: 'Queens',
    lon: -73.8931,
    lat: 40.730118,
  },
  {
    name: 'The Bronx',
    lon: -73.908273,
    lat: 40.828149,
  },
  {
    name: 'Staten Island',
    lon: -74.1392,
    lat: 40.6,
  },
];

export default boroughsLabels;
