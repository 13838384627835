import round from 'lodash/round';
import { Feature, Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { StyleFunction } from 'ol/style/Style';

import {
  BLOCK_PEEKABOO_FEATURE_CLASS_NAME,
  RENDER_BUFFER_ICONS,
} from '../maps-constants';
import { ZoomLevel } from '../map-types';
import { getOlMapZoom, getProportionalValueForZoom } from '../maps-utils';
import { subwayEntranceIconSVGDataString } from '../subway-sign-svg';
import { getMapTheme } from '../maps-theme';

const getStyleFunction = (
  useBlackIcons: boolean,
  useDarkMap: boolean
): StyleFunction => (feature, resolution) => {
  const currentZoom = getOlMapZoom(resolution, 1);
  const colorsToUse = getMapTheme(useDarkMap).entrances;
  const arrowColor = colorsToUse.foreground;
  let bgColor = colorsToUse.background;
  const bgColorActive = colorsToUse.backgroundActive;

  if (useBlackIcons) {
    bgColor = bgColorActive;
  }

  bgColor = currentZoom <= ZoomLevel.z18 ? bgColor : bgColorActive;

  const iconScale = round(
    getProportionalValueForZoom(currentZoom, {
      [ZoomLevel.z18]: 14 / 25,
    }),
    2
  );

  return new Style({
    image: new Icon({
      anchor: [0, 0],
      anchorXUnits: IconAnchorUnits.PIXELS,
      anchorYUnits: IconAnchorUnits.PIXELS,
      src: subwayEntranceIconSVGDataString(bgColor, arrowColor),
      scale: iconScale,
    }),
  });
};

let subwayStopsEntrancesLayer: VectorLayer;
export const addSubwayStopsEntrancesLayer = ({
  map,
  stopsEntrancesFeatures,
  useBlackIcons,
  useDarkMap,
}: {
  map: Map;
  stopsEntrancesFeatures: Feature[];
  useBlackIcons: boolean;
  useDarkMap: boolean;
}) => {
  if (subwayStopsEntrancesLayer) {
    subwayStopsEntrancesLayer.setSource(
      new VectorSource({
        features: stopsEntrancesFeatures,
      })
    );
    subwayStopsEntrancesLayer.setStyle(
      getStyleFunction(useBlackIcons, useDarkMap)
    );
  } else {
    subwayStopsEntrancesLayer = new VectorLayer({
      className: BLOCK_PEEKABOO_FEATURE_CLASS_NAME,
      // We need the layer to appear at 18, and the minZoom is not >= X it's > X
      minZoom: 17.999,
      renderBuffer: RENDER_BUFFER_ICONS,
      source: new VectorSource({
        features: stopsEntrancesFeatures,
      }),
      style: getStyleFunction(useBlackIcons, useDarkMap),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(subwayStopsEntrancesLayer);
  }
};
