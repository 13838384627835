import round from 'lodash/round';
import { Feature, Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import { StyleFunction } from 'ol/style/Style';

import {
  HOVERABLE_FEATURE_LABEL_CLASS_NAME,
  SELECTABLE_FEATURE_LABEL_CLASS_NAME,
  RENDER_BUFFER_ICONS,
} from '../maps-constants';
import {
  vaccineIconPinSVGDataString,
  vaccineIconSVGDataString,
} from '../subway-sign-svg';
import { getMapTheme } from '../maps-theme';
import { ZoomLevel } from '../map-types';
import { getOlMapZoom, getProportionalValueForZoom } from '../maps-utils';

const createStyle = (
  isADAFilterActive: boolean,
  useDarkMap: boolean,
  useHover?: boolean,
  useSelected?: boolean
): StyleFunction => (feature, resolution) => {
  const currentZoom = getOlMapZoom(resolution);
  const isAdaLocation = feature.get('isAdaLocation') as boolean;
  const useInactiveColor = isADAFilterActive && !isAdaLocation;
  const colorsToUse = useHover
    ? getMapTheme(useDarkMap).vaccineIcon.hover
    : getMapTheme(useDarkMap).vaccineIcon;
  const iconWidth = 18;
  const iconScale = round(
    getProportionalValueForZoom(currentZoom, {
      [ZoomLevel.z12]: 12 / iconWidth,
      [ZoomLevel.z13]: 16 / iconWidth,
      [ZoomLevel.z14]: 20 / iconWidth,
      [ZoomLevel.z15]: 24 / iconWidth,
      [ZoomLevel.z16]: 32 / iconWidth,
      [ZoomLevel.z17]: 32 / iconWidth,
      [ZoomLevel.z18]: 40 / iconWidth,
    }),
    2
  );

  return new Style({
    image: new Icon({
      scale: iconScale,
      src: useSelected
        ? vaccineIconPinSVGDataString(
            useInactiveColor && !useHover
              ? colorsToUse.backgroundInactive
              : colorsToUse.background,
            colorsToUse.foreground,
            iconWidth
          )
        : vaccineIconSVGDataString(
            useInactiveColor && !useHover
              ? colorsToUse.backgroundInactive
              : colorsToUse.background,
            colorsToUse.foreground,
            iconWidth
          ),
    }),
  });
};

let vaccineLocationsLayer: VectorLayer;
export const addVaccineLocationsLayer = ({
  isActive,
  isADAFilterActive,
  map,
  useDarkMap,
  vaccineLocationsFeatures,
}: {
  isActive: boolean;
  isADAFilterActive: boolean;
  map: Map;
  useDarkMap: boolean;
  vaccineLocationsFeatures: Feature[];
}) => {
  const vectorSource = new VectorSource({
    features: vaccineLocationsFeatures,
  });

  if (vaccineLocationsLayer) {
    if (isActive) {
      vaccineLocationsLayer.setSource(vectorSource);
    } else {
      vaccineLocationsLayer.getSource().clear();
    }
    vaccineLocationsLayer.setStyle(createStyle(isADAFilterActive, useDarkMap));

    // Update the selected element because the user can toggle the ADA
    if (currentSelectedFeature) {
      setVaccineLocationsFeatureSelectedStyle(
        isADAFilterActive,
        useDarkMap,
        currentSelectedFeature,
        true
      );
    }
  } else {
    vaccineLocationsLayer = new VectorLayer({
      className: `${HOVERABLE_FEATURE_LABEL_CLASS_NAME} ${SELECTABLE_FEATURE_LABEL_CLASS_NAME}`,
      renderBuffer: RENDER_BUFFER_ICONS,
      source: isActive ? vectorSource : new VectorSource(),
      style: createStyle(isADAFilterActive, useDarkMap),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(vaccineLocationsLayer);
  }
};

let currentHoveredFeature: Feature | undefined;
export const setVaccineLocationsFeatureHoverStyle = (
  isADAFilterActive: boolean,
  useDarkMap: boolean,
  feature?: Feature
) => {
  const isVaccineLocation = feature?.get('isVaccineLocation') as boolean;

  // If the new feature is not an vaccine icon or is different
  // from the previous label, we clean the last one.
  if (!isVaccineLocation || feature !== currentHoveredFeature) {
    const useSelected = currentHoveredFeature === currentSelectedFeature;

    // If the feature is selected we update the style
    if (useSelected) {
      currentHoveredFeature?.setStyle(
        createStyle(isADAFilterActive, useDarkMap, false, useSelected)
      );
    } else {
      currentHoveredFeature?.setStyle(undefined);
    }
    currentHoveredFeature = undefined;
  }

  if (isVaccineLocation && !currentHoveredFeature) {
    const useSelected = feature === currentSelectedFeature;

    currentHoveredFeature = feature;
    currentHoveredFeature?.setStyle(
      createStyle(isADAFilterActive, useDarkMap, true, useSelected)
    );
  }
};

let currentSelectedFeature: Feature | undefined;
export const setVaccineLocationsFeatureSelectedStyle = (
  isADAFilterActive: boolean,
  useDarkMap: boolean,
  feature?: Feature,
  force?: boolean
) => {
  if (!feature || feature !== currentSelectedFeature) {
    const useHover = currentSelectedFeature === currentHoveredFeature;

    // If the feature is hovered we update the style
    if (useHover) {
      currentSelectedFeature?.setStyle(
        createStyle(isADAFilterActive, useDarkMap, useHover, false)
      );
    } else {
      currentSelectedFeature?.setStyle(undefined);
    }
    currentSelectedFeature = undefined;
  }

  if ((feature && !currentSelectedFeature) || (feature && force)) {
    const useHover = feature === currentHoveredFeature;

    currentSelectedFeature = feature;
    currentSelectedFeature?.setStyle(
      createStyle(isADAFilterActive, useDarkMap, useHover, true)
    );
  }
};
