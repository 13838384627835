import React from 'react';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from '../models';
import { getMapSelectedAirportTerminalId } from '../selectors/map/basic';
import { isPhone } from '../utils/deviceDetector.utils';
import AirportInfo from '../components/AirportInfo/AirportInfo';
import Tray, { TrayPosition } from '../components/Tray';
import ViewBox from '../components/ViewBox';

const AIRPORTS_INFO = {
  jfk: {
    link: 'https://new.mta.info/guides/getting-to-the-airport',
    subtitle: 'Airtrain',
    text: [
      'The AirTrain connects JFK to Jamaica and Howard Beach stations, which both connect to the subway. From Jamaica, you can take the E, J, or Z trains. From Howard Beach, you can take the A train.',
      'To get to JFK via transit, take the E, J, or Z train to Sutphin Boulevard/Archer Avenue/JFK Airport. Then take the AirTrain. Or take the A train to Howard Beach and take the AirTrain from there.',
      'The AirTrain fare is $7.75 and is separate from subway fare.',
      'Read more about how to get to the airport:',
    ],
    title: 'JFK Airport',
  },
  lga: {
    link: 'https://new.mta.info/guides/getting-to-the-airport',
    subtitle: 'Buses',
    text: [
      'The Q70-SBS bus and M60-SBS bus both stop at LaGuardia. They also connect with the subway, Long Island Rail Road and Metro-North Railroad.',
      'To get to LaGuardia via transit, take the E, F, M, R, or 7 train to 74 St/Roosevelt Av and transfer to the Q70-SBS bus. Or take the 2, 3, 4, 5, 6, A, B, C, or D train to 125 St and transfer to the M60-SBS.',
      'Read more about how to get to the airport:',
    ],
    title: 'LGA Airport',
  },
};

interface StationStatusContainerProps {
  currentTrayPosition: TrayPosition;
  airportTerminalViewOpened: boolean;
  selectedAirportTerminalId: string;
  setAirportTerminalViewOpened: (newValue: boolean) => void;
  setCurrentTrayPosition: (value: TrayPosition) => void;
  shortcutsMenuOpen: boolean;
  translate: boolean;
}
const StationStatusContainer: React.FC<StationStatusContainerProps> = ({
  currentTrayPosition,
  airportTerminalViewOpened,
  selectedAirportTerminalId,
  setAirportTerminalViewOpened,
  setCurrentTrayPosition,
  shortcutsMenuOpen,
  translate,
}) => {
  if (isPhone()) {
    return (
      <Tray
        defaultPosition={TrayPosition.twoThirds}
        currentTrayPosition={currentTrayPosition}
        isOpen={airportTerminalViewOpened}
        onCloseCallback={() => setAirportTerminalViewOpened(false)}
        setCurrentTrayPosition={setCurrentTrayPosition}
      >
        <AirportInfo
          content={AIRPORTS_INFO[selectedAirportTerminalId] || {}}
          onCloseClick={() => setAirportTerminalViewOpened(false)}
        />
      </Tray>
    );
  }

  return (
    <ViewBox
      isOpen={airportTerminalViewOpened}
      shortcutsMenuOpen={shortcutsMenuOpen}
      translate={translate}
    >
      <AirportInfo
        content={AIRPORTS_INFO[selectedAirportTerminalId] || {}}
        onCloseClick={() => setAirportTerminalViewOpened(false)}
      />
    </ViewBox>
  );
};

const mapStateToProps = (state: AppState) => ({
  airportTerminalViewOpened: state.ui.airportTerminalViewOpened,
  currentTrayPosition: state.ui.currentTrayPosition,
  selectedAirportTerminalId: getMapSelectedAirportTerminalId(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setAirportTerminalViewOpened: dispatch.ui.setAirportTerminalViewOpened,
  setCurrentTrayPosition: dispatch.ui.setCurrentTrayPosition,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationStatusContainer);
