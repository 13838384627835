import React, { useCallback } from 'react';
import { RouteStatus } from '../subway-data/subway-types';
import ServiceStatus from '../components/Controls/ServiceStatus';
import { ServiceStatusButtonContainer } from './ServiceStatusButton';
import { isPhone } from '../utils/deviceDetector.utils';

interface EmergencyAlertButtonProps {
  emergencyAlertOpened: boolean;
  isActive: boolean;
  selectedRouteStatus: RouteStatus;
  setEmergencyAlertOpened: (emergencyAlertOpened: boolean) => void;
  statusViewOpened: boolean;
}

const EmergencyAlertButton: React.FC<EmergencyAlertButtonProps> = ({
  emergencyAlertOpened,
  isActive,
  selectedRouteStatus,
  setEmergencyAlertOpened,
  statusViewOpened,
}) => {
  const hasSelectedRouteStatusData =
    selectedRouteStatus.statusDetails.length > 0;
  const isActiveResponsive = isPhone()
    ? !hasSelectedRouteStatusData && !statusViewOpened
    : !hasSelectedRouteStatusData && !statusViewOpened && !emergencyAlertOpened;

  const openEmergencyAlert = useCallback(() => {
    setEmergencyAlertOpened(true);
  }, [setEmergencyAlertOpened]);

  return (
    <ServiceStatusButtonContainer
      hasSelectedRouteStatusData={hasSelectedRouteStatusData}
      isPhone={isPhone()}
      isClosed={emergencyAlertOpened}
      isActive={isActive && isActiveResponsive}
    >
      <ServiceStatus
        isClosed={emergencyAlertOpened}
        onClick={openEmergencyAlert}
        isEmergencyAlert={true}
      />
    </ServiceStatusButtonContainer>
  );
};

export default EmergencyAlertButton;
