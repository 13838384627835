import round from 'lodash/round';
import { Feature, Map } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';

import theme from '../../utils/theme';

import { RENDER_BUFFER_LABELS } from '../maps-constants';
import { getMapTheme } from '../maps-theme';
import { ZoomLevel } from '../map-types';
import { getOlMapZoom, getProportionalValueForZoom } from '../maps-utils';

const getStyle = (useDarkMap: boolean) => {
  const colorsToUse = getMapTheme(useDarkMap).labels;

  return new Style({
    renderer: (coordinates, state) => {
      const { context, feature, pixelRatio, resolution } = state;
      let currentZoom = getOlMapZoom(resolution);

      const [initialX, initialY] = coordinates as number[];
      const name = feature.get('name') as string;
      const nameShort = feature.get('nameShort') as string;
      const fontSize =
        pixelRatio *
        round(
          getProportionalValueForZoom(currentZoom, {
            [ZoomLevel.z15]: 11,
            [ZoomLevel.z16]: 13,
            [ZoomLevel.z17]: 18,
            [ZoomLevel.z18]: 30,
          }),
          2
        );
      const fontSizeSubtitle =
        pixelRatio *
        round(
          getProportionalValueForZoom(currentZoom, {
            [ZoomLevel.z13]: 7,
            [ZoomLevel.z14]: 9,
            [ZoomLevel.z17]: 15,
            [ZoomLevel.z18]: 24,
          }),
          2
        );
      const lineHeight = fontSize * 1.12;
      const textLeftPadding =
        pixelRatio *
        round(
          getProportionalValueForZoom(currentZoom, {
            [ZoomLevel.z12]: 0,
            [ZoomLevel.z16]: 10,
            [ZoomLevel.z17]: 20,
          }),
          2
        );
      const positionHorizontal = feature.get('positionHorizontal') as string;
      const positionVertical = feature.get('positionVertical') as string;

      let [title, subtitle] = name.split('\n');

      if (nameShort && currentZoom < ZoomLevel.z17) {
        [title, subtitle] = [nameShort, ''];
      }

      // Reset the content alignment to left 0 and vertical middle;
      let offsetX = textLeftPadding;
      let offsetY = 0;
      let textAlign = 'left';

      if (positionHorizontal === 'left') {
        offsetX = offsetX * -1;
        textAlign = 'right';
      }

      if (positionVertical === 'bottom' || positionVertical === 'top') {
        offsetX = 0;
        // the text height to sum/subtract from the coordinates
        offsetY = lineHeight * (positionVertical === 'bottom' ? 1 : -1);

        if (subtitle && positionVertical === 'top') {
          // We need to give space for the subtitle if the title is above
          // the line.The text height will be the title size two times.
          offsetY = lineHeight * -2;
        }
      }

      context.textAlign = textAlign as CanvasTextAlign;
      context.textBaseline = 'middle';
      context.fillStyle = colorsToUse.active;
      context.font = `bold ${fontSize}px/${lineHeight}px ${theme.fonts.default}`;
      context.fillText(title, initialX + offsetX, initialY + offsetY);

      if (subtitle) {
        // The subtitle top is the title offset zero plus the
        // height of one line of text.
        offsetY = offsetY + lineHeight;

        context.font = `normal ${fontSizeSubtitle}px ${theme.fonts.default}`;
        context.fillText(subtitle, initialX + offsetX, initialY + offsetY);
      }
    },
  });
};

let airportStationLabelsLayer: VectorLayer;
export const addAirportStationLabelsLayer = ({
  airportsStationsDotsFeatures,
  map,
  useDarkMap,
}: {
  airportsStationsDotsFeatures: Feature[];
  map: Map;
  useDarkMap: boolean;
}) => {
  if (airportStationLabelsLayer) {
    airportStationLabelsLayer.setSource(
      new VectorSource({
        features: airportsStationsDotsFeatures,
      })
    );
    airportStationLabelsLayer.setStyle(getStyle(useDarkMap));
  } else {
    airportStationLabelsLayer = new VectorLayer({
      minZoom: 13.999,
      renderBuffer: RENDER_BUFFER_LABELS,
      source: new VectorSource({
        features: airportsStationsDotsFeatures,
      }),
      style: getStyle(useDarkMap),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(airportStationLabelsLayer);
  }
};
