import mapValues from 'lodash/mapValues';
import {
  OtpRouteId,
  OtpRouteShortName,
  OtpStopOnRoute,
  Stations,
  StopList,
  StopListsByRoute,
  SubwayRouteId,
  SubwayRouteIds,
} from './subway-types';
import stationsUnified from './stations-unified';
import {
  subwayRouteColors,
  subwayRouteHasDarkText,
  subwayRouteHoverColors,
  subwayRouteInactiveColors,
} from './subway-route-colors';
import otpStops from './otp/otp-stops';
import otpRoutes from './otp/otp-routes';
import stopsOnRoutes from './otp/stopsOnRoutes';
import accessibleStops from './accessibility/accessibility-stops';

export const OTP_API_DOMAIN = 'https://otp-mta-prod.camsys-apps.com';
export const OTP_API_ROUTE = `${OTP_API_DOMAIN}/otp/routers/default`;
export const OTP_API_KEY = '2ctbNX4XX7oS5ywqVQT86DntRQQw59eB';

const stations: Stations = stationsUnified.map(station => {
  const transfers = station.Daytime_Routes_Array;

  // Get the name from our stops list
  const stopName =
    otpStops.find(stop => stop.stopId === station.stopId)?.stopName || '';

  if (!!accessibleStops[station.stopId]) {
    const { ada, level } = accessibleStops[station.stopId];

    return {
      ...station,
      stopName,
      ada,
      level,
      transfers,
      transfersAndPassthroughs: transfers,
    };
  }

  return {
    ...station,
    stopName,
    ada: false,
    transfers,
    transfersAndPassthroughs: transfers,
  };
});

export {
  stations,
  subwayRouteColors,
  subwayRouteInactiveColors,
  subwayRouteHoverColors,
  subwayRouteHasDarkText,
  otpStops,
  otpRoutes,
  otpStopsOnRoutes,
};

export const MANHATTAN_DEGREES = 29;

export const timeFilters: TimeFilter[] = ['weekday', 'weeknight', 'weekend'];

export const subwayRouteIds: SubwayRouteIds = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  'A',
  'C',
  'E',
  'G',
  'B',
  'D',
  'F',
  'M',
  'J',
  'Z',
  'L',
  'GS',
  'H',
  'FS',
  'N',
  'Q',
  'R',
  'W',
  'SI',
];

export const orderedSubwayRouteIds: SubwayRouteIds = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  'A',
  'C',
  'E',
  'SI',
  'G',
  '7',
  'B',
  'D',
  'F',
  'M',
  'J',
  'Z',
  'N',
  'Q',
  'R',
  'W',
  'L',
  'GS',
  'FS',
  'H',
];

export const sortRouteIdsForIconDisplay = (
  routeIdA: SubwayRouteId,
  routeIdB: SubwayRouteId
): number =>
  orderedSubwayRouteIds.indexOf(routeIdA) -
  orderedSubwayRouteIds.indexOf(routeIdB);

export const isSubwayRouteId = (str: string): str is SubwayRouteId =>
  subwayRouteIds.includes(str as any);

export const otpRouteIds: SubwayRouteIds = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  'A',
  'C',
  'E',
  'G',
  'B',
  'D',
  'F',
  'M',
  'J',
  'Z',
  'L',
  'H',
  'FS',
  'GS',
  'N',
  'Q',
  'R',
  'W',
  'SI',
];

export const otpRouteShortNames: OtpRouteShortName[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  'A',
  'C',
  'E',
  'G',
  'B',
  'D',
  'F',
  'M',
  'J',
  'Z',
  'L',
  'S',
  'SR',
  'SF',
  'N',
  'Q',
  'R',
  'W',
  'SIR',
  '6X',
  '7X',
  'FX',
];

export const subwayRouteIdFromOtpRouteId = (
  routeId: OtpRouteId
): SubwayRouteId => {
  if (routeId === '6X') return '6';
  if (routeId === '7X') return '7';
  if (routeId === 'FX') return 'F';

  return routeId;
};

export const subwayRouteLabelFromSubwayRouteId = (
  routeId: SubwayRouteId
): string => {
  if (routeId === 'FS') return 'SF';
  if (routeId === 'SI') return 'SIR';
  if (routeId === 'H') return 'SR';
  if (routeId === 'GS') return 'S';

  return routeId as string;
};

export const subwayRouteIdFromSubwayRouteLabel = (
  routeId: string
): SubwayRouteId => {
  if (routeId === '6X') return '6';
  if (routeId === '7X') return '7';
  if (routeId === 'SF') return 'FS';
  if (routeId === 'SIR') return 'SI';
  if (routeId === 'SR') return 'H';
  if (routeId === 'S') return 'GS';

  return routeId as SubwayRouteId;
};

/** Many-to-one */
export const otpShortNameForRouteId = (
  routeId: OtpRouteId
): OtpRouteShortName => {
  if (routeId === 'SI') return 'SIR';
  if (routeId === 'FS') return 'SF';
  if (routeId === 'H') return 'SR';
  if (routeId === 'GS') return 'S';
  if (routeId === '6X') return '6';
  if (routeId === '7X') return '7';

  return routeId;
};

export const routeIdFromShortcut = (e: KeyboardEvent): SubwayRouteId | null => {
  const key = e.key.toUpperCase();
  if (e.altKey || e.ctrlKey || e.metaKey) return null;

  if (e.shiftKey) {
    if (['F', 'R', 'S'].includes(key)) {
      return {
        F: 'FS',
        R: 'H',
        S: 'SI',
      }[key] as SubwayRouteId;
    } else return null;
  }

  switch (key) {
    case 'S':
      return 'GS' as SubwayRouteId;
    case 'H':
      return null;
    default:
      return subwayRouteIds.includes(key as SubwayRouteId)
        ? (key as SubwayRouteId)
        : null;
  }
};

// TODO: move to subway-types
// TODO: probably nothing should be using this now that each route can have multiple stop lists
export type OtpStopsByRoute = {
  [K in OtpRouteId]: StopList;
};

/** Copies lon/lat from one data set over to the other */
const copyLatLonAndSetTerminalToStops = (
  stopsOnRoutes: OtpStopOnRoute[]
): StopList => {
  return stopsOnRoutes.map(stop => {
    const subwayStation = stationsUnified.find(
      ({ stopId }) => stopId === stop.stopId
    );
    const subwayStop = otpStops.find(({ stopId }) => stopId === stop.stopId);

    let isTerminal = false;
    let lat = 0;
    let lon = 0;
    let stopName = subwayStop?.stopName || '';

    if (!subwayStation && !subwayStop) {
      console.warn('-------- missing stopId', stop.stopId);
      return { ...stop, stopName, isTerminal, lat, lon };
    }

    // Static station with lineIcon s a terminal station
    if (subwayStation?.lineIcons) {
      const subwayRouteIdForStop = subwayRouteIdFromOtpRouteId(stop.routeId);
      isTerminal = subwayStation.lineIcons.indexOf(subwayRouteIdForStop) > -1;
    }

    lat = subwayStop?.lat ?? subwayStation?.lat ?? lat;
    lon = subwayStop?.lon ?? subwayStation?.lon ?? lon;

    return {
      ...stop,
      stopName,
      isTerminal,
      lat,
      lon,
    };
  });
};

const otpStopsOnRoutes: StopList = copyLatLonAndSetTerminalToStops(
  stopsOnRoutes
);

/**
 * Transforms the flat array of stopsForRoute into a dictionary of route id to stops.
 * @param routeIds
 * @param stops This array mixes stops on various routes
 */
export const organizeOtpStopsByRoute = (
  routeIds: readonly OtpRouteId[],
  stops: StopList
): OtpStopsByRoute => {
  return routeIds.reduce((result, routeId: OtpRouteId) => {
    result[routeId] = stops.filter(stop => stop.routeId === routeId);
    return result;
  }, {} as Record<OtpRouteId, StopList>);
};

export const otpStopsByRoute: OtpStopsByRoute = organizeOtpStopsByRoute(
  otpRouteIds,
  otpStopsOnRoutes
);

export const otpStopListsByRoute: StopListsByRoute = mapValues(
  otpStopsByRoute,
  // Remove rerouting stops from the base path of an unavailable route.
  stopList => [stopList.filter(stop => !stop.isRerouting)]
);

export type TimeFilter = 'weekday' | 'weeknight' | 'weekend';

export type RoutesUnavailable = {
  [K in SubwayRouteId]?: boolean;
};

export type RoutesUnidirectional = RoutesUnavailable;

export type RoutesWithSituation = RoutesUnavailable;

export const otpStopsForRoute = (routeId: OtpRouteId): StopList =>
  otpStopsByRoute[routeId];
