import React from 'react';
import styled, { css } from 'styled-components/macro';
import { SERVICE_STATUS_BUTTON_TRANSITION } from '../utils/animations';
import { tablet, desktopExtraLarge } from '../utils/theme';

export const ANIMATION_DURATION = 500;
export const INNER_ANIMATION_DURATION = 500;

const ViewBoxWrapper = styled.aside<{
  isOpen: boolean;
  translate: boolean;
  shortcutsMenuOpen: boolean;
}>`
  position: fixed;
  top: 20px;
  left: ${p => p.theme.margin};
  z-index: ${p => p.theme.zIndex.serviceStatusDesktop};
  width: 100vw;
  height: auto;
  pointer-events: none;
  transform: ${p =>
    p.isOpen ? `translateY(${p.translate ? '60px' : 0})` : 'translateY(-5%)'};
  opacity: ${p => (p.isOpen ? (p.shortcutsMenuOpen ? 0 : 1) : 0)};
  transition: ${p => p.theme.transition};
  transition-property: transform, opacity;
  transition-duration: ${ANIMATION_DURATION}ms;
  transition-delay: ${p => (p.isOpen ? SERVICE_STATUS_BUTTON_TRANSITION : 0)}ms;

  ${tablet(css`
    width: 240px;
  `)};

  ${desktopExtraLarge(css`
    width: 300px;
  `)};
`;

const ViewBoxContent = styled.div<{ isOpen: boolean }>`
  border-radius: 6px;
  position: relative;
  height: auto;
  max-height: 100vh;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: ${p => (p.isOpen ? 'all' : 'none')};
  border-radius: 6px;
  box-shadow: ${p => p.theme.boxShadow};
  transition: height ${INNER_ANIMATION_DURATION}ms ease-in-out;
`;

interface ViewBoxProps {
  children: React.ReactElement;
  isOpen: boolean;
  translate?: boolean;
  shortcutsMenuOpen?: boolean;
}
const ViewBox: React.FC<ViewBoxProps> = ({
  children,
  isOpen,
  translate = false,
  shortcutsMenuOpen = false,
}) => {
  return (
    <ViewBoxWrapper
      isOpen={isOpen}
      translate={translate}
      shortcutsMenuOpen={shortcutsMenuOpen}
    >
      <ViewBoxContent isOpen={isOpen}>{children}</ViewBoxContent>
    </ViewBoxWrapper>
  );
};

export default ViewBox;
