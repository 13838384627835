// arrivalFmt is preferred source for accurate arrival times (as opposed to realtimeArrival)
// arrivalFmt has format ISO 8601:
// https://www.w3.org/TR/NOTE-datetime
// Complete date plus hours, minutes, seconds and a decimal fraction of a second
// YYYY-MM-DDThh:mm:ss.sTZD (eg 1997-07-16T19:20:30.45+01:00)

export const calculateArrivalTimeInSecondsFromArrivalFmt = (
  arrivalFmt: string,
  currentTimeInMS = new Date().getTime()
) => {
  const arrivalTimeInMS = new Date(arrivalFmt).getTime();
  const timeToArrivalInMS = arrivalTimeInMS - currentTimeInMS;
  const timeToArrivalInSeconds = Math.round(timeToArrivalInMS / 1000);

  return timeToArrivalInSeconds;
};
