import React from 'react';
import styled from 'styled-components/macro';
import theme from '../utils/theme';

export enum IconTypes {
  Accessibility,
  Airport,
  Alert,
  BoxlessChevron,
  Bus,
  Chevron,
  Cross,
  Location,
  Minus,
  MTA,
  Next,
  Plus,
  Previous,
  Refresh,
  Vaccine,
  DollarSign,
  Calendar,
  CalendarDisabled,
  Phone,
  BlankPage,
  Pin,
  Person,
  Clock,
}

type IconProps = {
  type: IconTypes;
};

export const IconContainer = styled.div`
  display: inline-block;
  height: 30px;
  width: 30px;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const IconContainerRounded = styled(IconContainer)`
  align-items: center;
  background-color: #000;
  border-radius: 50%;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  svg {
    transform: scale(0.8);
  }
`;

const Icon: React.FC<IconProps> = ({ type }) => {
  switch (type) {
    case IconTypes.Accessibility:
      return (
        <svg
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M12.205 10.371l.021 2.022c-1.923 1.176-2.92 3.546-2.3 5.862.753 2.809 3.603 4.496 6.375 3.754a5.102 5.102 0 002.705-1.79c.276-.35.505-.737.684-1.147l1.016 1.85a6.823 6.823 0 01-3.935 2.84c-3.7.992-7.522-1.27-8.536-5.053-.706-2.635.137-5.312 1.963-7.03a6.783 6.783 0 012.007-1.308zm2.093-2.37c.675 0 1.222.547 1.222 1.222V10h3.218a1 1 0 110 2h-3.219v2.501h4.377a1 1 0 01.964.732l.046.07.017.03 1.953 3.537c.26.471.102 1.064-.36 1.342a.929.929 0 01-1.292-.346l-1.859-3.365h-5.312v-.024a1.222 1.222 0 01-.977-1.197V9.223c0-.675.547-1.222 1.222-1.222zm0-4.001c.945 0 1.71.784 1.71 1.75 0 .967-.765 1.75-1.71 1.75s-1.71-.783-1.71-1.75c0-.966.765-1.75 1.71-1.75z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      );

    case IconTypes.Airport:
      return (
        <svg
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.274.308c.948-.939 2.373.479 1.4 1.444l-3.968 3.946 2.216 8.844L12.457 16l-3.71-7.308-2.965 2.95.28 2.266-1.16 1.153-1.358-2.571-2.587-1.352 1.16-1.153 2.265.265 2.967-2.949L0 3.613l1.466-1.457L10.28 4.28 14.274.308z"
            fillRule="evenodd"
            fill="#000"
          />
        </svg>
      );

    case IconTypes.Alert:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <circle fill={theme.colors.yellow} cx="15" cy="15" r="12" />
            <path
              d="M15 19c.828427 0 1.5.671573 1.5 1.5S15.828427 22 15 22s-1.5-.671573-1.5-1.5.671573-1.5 1.5-1.5zm0-11.5c.828427 0 1.5.671573 1.5 1.5v6c0 .828427-.671573 1.5-1.5 1.5s-1.5-.671573-1.5-1.5V9c0-.828427.671573-1.5 1.5-1.5z"
              fill={theme.colors.black}
            />
          </g>
        </svg>
      );

    case IconTypes.MTA:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22">
          <g fill="none" fillRule="nonzero">
            <path
              fill="#000"
              d="M.01706667 4.56106539C1.94559988 1.80479889 5.14559969-.000001 8.7637328-.000001c5.89226629 0 10.66666599 4.77439972 10.66666599 10.666666 0 5.8922663-4.7743997 10.6666661-10.66666599 10.6666661-3.47306646 0-6.56213294-1.6639999-8.50773282-4.2325331"
            />
            <path
              fill="#FFF"
              d="M1.45066658 16.8874647s0-3.7290665-.03413333-4.1514665c0-.4053333-.20053332-2.7391998-.15786666-3.13173311l.11093333.00426667L2.7690665 16.6613313l1.95839988-.3455999 1.13919994-6.53226632h.12799999c.05973333.40106662-.10239999 2.29546652-.10239999 2.70079982-.03413334.4224-.03413334 3.6266665-.03413334 3.6266665l1.98399989-.3456V5.93066531l-3.09759982-.53759996-.87039995 6.02453295c-.02986666 0-.87893328-6.33173294-.87893328-6.33173294L0 4.56533206V17.100798M14.0543992 13.7002649l.9557332-.1237334.1237334.9130666 1.1178666-.192-1.0709333-7.07839953-1.3311999-.23039999-1.3482666 7.96586622 1.4207999-.2517333zM8.3583995 8.55466516l1.50186658.13226666.00426667 6.72426628 1.83466655-.32V8.84906514l1.1861333.09813333V6.80533193l-4.5269331-.78506662z"
            />
            <path
              fill="#000"
              d="M14.4810658 9.62559843l-.2133333 2.37226657.5504-.0341334-.2048-2.33813317z"
            />
          </g>
        </svg>
      );

    case IconTypes.Bus:
      return (
        <svg
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M15 5a14.626 14.626 0 016.682 1.605l.03.016a2.594 2.594 0 011.303 1.938h.005l.607 4.818c.113.899.188 1.802.225 2.707l.02.679.128 6.644h-1.929v1.473a1.12 1.12 0 01-1.12 1.12h-.01a1.12 1.12 0 01-1.12-1.12v-1.473h-9.643v1.473A1.12 1.12 0 019.059 26H9.05a1.12 1.12 0 01-1.12-1.12l-.001-1.473H6l.128-6.637c.017-.91.074-1.818.168-2.722l.079-.678.608-4.81a2.601 2.601 0 011.308-1.94h-.003l.002-.001.022-.01c.057-.032.115-.06.174-.087l-.168.083.351-.173A14.628 14.628 0 0115 5zM9.058 18.222H9.05a1.12 1.12 0 00-1.12 1.12v.028c0 .619.501 1.12 1.12 1.12h.01a1.12 1.12 0 001.12-1.12v-.028a1.12 1.12 0 00-1.12-1.12zm11.893 0h-.01a1.12 1.12 0 00-1.12 1.12v.028c0 .619.502 1.12 1.12 1.12h.01a1.12 1.12 0 001.12-1.12v-.028a1.12 1.12 0 00-1.12-1.12zm.27-9.397l-6.044.033h-.354l-6.017-.033-.07.007a.32.32 0 00-.25.284L7.96 15.04l.001.074a.32.32 0 00.29.273h.013l13.46.001.073-.008a.32.32 0 00.245-.339l-.5-5.922-.013-.069a.32.32 0 00-.308-.224zm-2.844-2.27h-6.754a.48.48 0 00-.48.48v.011c0 .266.215.48.48.48h6.754a.48.48 0 00.48-.48v-.011a.48.48 0 00-.48-.48z"
            fill="currentColor"
          />
        </svg>
      );

    case IconTypes.Cross:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <g
            stroke="currentColor"
            strokeLinecap="square"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
          >
            <path d="M10 10l9.899495 9.899495M19.899494 10l-9.899495 9.899495" />
          </g>
        </svg>
      );

    case IconTypes.Location:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.833 5v2.546a7.503 7.503 0 016.621 6.62H25v1.667h-2.546a7.503 7.503 0 01-6.62 6.621V25h-1.667v-2.546a7.503 7.503 0 01-6.621-6.62H5v-1.667h2.546a7.503 7.503 0 016.62-6.621V5h1.667zM15 9.167a5.833 5.833 0 100 11.666 5.833 5.833 0 000-11.666zm0 2.5a3.333 3.333 0 110 6.666 3.333 3.333 0 010-6.666z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      );

    case IconTypes.Minus:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 15h12"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="square"
            fill="none"
            fillRule="evenodd"
          />
        </svg>
      );

    case IconTypes.Plus:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <g
            stroke="currentColor"
            strokeLinecap="square"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
          >
            <path d="M15 8.928932v12M21 14.928932H9" />
          </g>
        </svg>
      );

    case IconTypes.Previous:
      return (
        <svg
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M7 3.835534L1.714214 9.12132.3 7.707107 8.007107 0l7.707107 7.707107L14.3 9.12132l-5.3-5.3V16H7V3.835534z"
              id="previous-1"
            />
          </defs>
          <use
            fill="currentColor"
            fillRule="nonzero"
            xlinkHref="#previous-1"
            transform="rotate(-90 15 8)"
          />
        </svg>
      );

    case IconTypes.Next:
      return (
        <svg
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <path
              d="M7 3.835534L1.714214 9.12132.3 7.707107 8.007107 0l7.707107 7.707107L14.3 9.12132l-5.3-5.3V16H7V3.835534z"
              id="next-1"
            />
          </defs>
          <use
            fill="currentColor"
            fillRule="nonzero"
            xlinkHref="#next-1"
            transform="rotate(90 8 15)"
          />
        </svg>
      );

    case IconTypes.Chevron:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fillRule="evenodd">
            <path stroke="currentColor" strokeWidth="2" d="M8 19l7-7 7 7" />
          </g>
        </svg>
      );

    case IconTypes.BoxlessChevron:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 9">
          <defs>
            <path
              id="a"
              d="M20.2382057 18.679763l1.4142136-1.4142135-6.6797631-6.6797631-6.67976298 6.6797631 1.41421356 1.4142135 5.26554942-5.2655494z"
            />
          </defs>
          <use
            fill="#000"
            fillRule="nonzero"
            transform="translate(-8 -10)"
            xlinkHref="#a"
          />
        </svg>
      );

    case IconTypes.Refresh:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15 7c3.728 0 6.86 2.55 7.748 6h-2.09a6 6 0 10.258 3l2.022.001A8.001 8.001 0 017 15a8 8 0 018-8z"
            fill="currentColor"
          />
          <path fill="currentColor" d="M23 8v5h-5z" />
        </svg>
      );

    case IconTypes.Vaccine:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M26.01 7.096l-4.42-4.42-1.413 1.415 1.502 1.503-2.828 2.828-.972-.972-1.415-1.414L15.05 7.45l-3.889 3.889-1.414 1.414-3.89 3.89 1.024 3.75-2.614 2.613A1 1 0 105.68 24.42l2.614-2.614 3.75 1.023 9.192-9.192 1.415-1.414-1.415-1.415-.972-.972 2.828-2.828 1.503 1.502 1.414-1.414zm-6.187 5.127l-3.359-3.359-3.889 3.889 3.36 3.359 3.888-3.89zm-11.01 7.652l2.638.72 3.069-3.069-3.359-3.359-3.068 3.069.72 2.639z"
            fill="currentColor"
            fillRule="evenodd"
          />
          <path
            d="M11.161 11.339l6.187 6.187-5.303 5.303-4.861-1.326-1.326-4.86 5.303-5.304z"
            fill="currentColor"
          />
        </svg>
      );

    case IconTypes.DollarSign:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.785 18.148H8.75c.128 1.676.662 2.993 1.604 3.95.942.943 2.21 1.493 3.807 1.653v2.499h1.317v-2.499a7.272 7.272 0 002.107-.479 5.264 5.264 0 001.652-1.03 4.713 4.713 0 001.077-1.58c.272-.606.407-1.3.407-2.083 0-.734-.143-1.364-.43-1.891a4.432 4.432 0 00-1.006-1.317 4.906 4.906 0 00-1.245-.814 6.067 6.067 0 00-1.126-.43l-1.436-.384v-5.89c1.468.271 2.362 1.197 2.681 2.777h2.036c-.32-1.404-.862-2.506-1.629-3.304-.766-.798-1.795-1.277-3.088-1.436V3.75H14.16v2.116c-.686.048-1.333.2-1.94.455-.59.255-1.109.606-1.556 1.053-.43.431-.774.95-1.03 1.557a4.896 4.896 0 00-.382 1.963c0 .702.096 1.3.287 1.796.192.494.487.917.886 1.269.415.335.926.63 1.532.885.623.24 1.357.463 2.203.67v6.346c-.958-.16-1.756-.551-2.394-1.174-.623-.622-.95-1.468-.982-2.538zm4.693 3.712v-6.01c.43.128.838.271 1.22.43.384.145.711.328.983.552.27.223.486.494.646.814.16.303.24.686.24 1.149 0 .51-.08.958-.24 1.34a2.4 2.4 0 01-.646.934c-.272.24-.599.423-.982.551a4.733 4.733 0 01-1.221.24zM14.16 7.78v5.603A8.871 8.871 0 0113.06 13a4.283 4.283 0 01-.886-.503 2.38 2.38 0 01-.575-.742c-.127-.303-.191-.663-.191-1.078 0-.91.263-1.596.79-2.059.542-.479 1.197-.758 1.963-.838z"
            fill="currentColor"
          />
        </svg>
      );

    case IconTypes.Calendar:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M11.25 7.5V5H9.062v2.5H5V25h20V7.5h-3.75V5h-2.188v2.5H11.25zm11.563 5.313V9.687H7.188v3.126h15.625zM7.188 15v7.813h15.625V15H7.188z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      );

    case IconTypes.CalendarDisabled:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M5 25l-1.25 1.25 1.547 1.547 21.25-21.25L25 5l-2.5 2.5h-1.25V5h-2.188v2.5H11.25V5H9.062v2.5H5V25zm2.188-2.188L15 15H7.187v7.813zm10-10l3.125-3.124H7.188v3.124h10z"
            fill="currentColor"
            fillRule="evenodd"
          />
          <path
            d="M12.803 25l2.187-2.188h7.822V15h-.01L25 12.803V25H12.803z"
            fill="currentColor"
          />
        </svg>
      );

    case IconTypes.Phone:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.635 13.107c2 3.242 3.334 4.539 6.667 6.485l2.667-2.595c1.362.34 3.082.804 4.462.798.46-.003.918.444.93.871l-.03 4.047c-.002.447-.464.897-.933.924-11.492.02-18.135-6.442-18.51-17.64-.037-.433.425-.883.885-.885h4.557c.503-.018.961.427 1.07.699-.116 1.518.545 3.369.902 4.702l-2.667 2.594z"
            fill="currentColor"
          />
        </svg>
      );

    case IconTypes.BlankPage:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M20.181 7.125H13.75v-1.75h9.625V15h-1.75V8.156l-7.629 7.629-1.237-1.238 7.422-7.422zm3.194 17.5h-18V5.375h4v1.75h-2.25v15.75h14.5V20h1.75v4.625z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      );

    case IconTypes.Pin:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M8.457 4.987a9.144 9.144 0 015.928-2.175c2.176 0 4.28.771 5.927 2.175M9.673 6.414a7.27 7.27 0 014.712-1.726c1.734 0 3.406.614 4.71 1.726a6.99 6.99 0 012.397 4.317 6.92 6.92 0 01-1.07 4.787A619.268 619.268 0 0116.4 21.21c-.674.946-1.349 1.893-2.022 2.845-.67-.945-1.34-1.883-2.009-2.82-1.357-1.9-2.71-3.79-4.028-5.712-.367-.577-.706-1.164-.922-1.899-.215-.732-.32-1.655-.14-2.892m13.033-5.744a8.864 8.864 0 013.035 5.476 8.795 8.795 0 01-1.365 6.096l-.006.01-.007.01c-1.335 1.919-2.695 3.827-4.05 5.73a1227.1 1227.1 0 00-2.023 2.845c-.35.497-.92.784-1.518.784a1.86 1.86 0 01-1.518-.783 747.613 747.613 0 00-2.002-2.81 372.835 372.835 0 01-4.072-5.775l-.01-.014-.008-.013c-.396-.622-.854-1.392-1.148-2.391-.295-1.008-.412-2.204-.197-3.69a8.869 8.869 0 013.034-5.475M7.278 10.73a6.994 6.994 0 012.395-4.317zm7.097 3.644a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm0 1.875a4.375 4.375 0 100-8.75 4.375 4.375 0 000 8.75z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      );

    case IconTypes.Person:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="15"
            cy="10.078"
            fill="none"
            fillRule="evenodd"
            r="5.141"
            stroke="currentColor"
            strokeWidth="1.75"
          />
          <path
            d="M23.75 25.938c0-5.437-3.918-9.844-8.75-9.844S6.25 20.5 6.25 25.937"
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeWidth="1.75"
          />
        </svg>
      );

    case IconTypes.Clock:
      return (
        <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M22.813 15a7.813 7.813 0 11-15.626 0 7.813 7.813 0 0115.625 0zM25 15c0 5.523-4.477 10-10 10S5 20.523 5 15 9.477 5 15 5s10 4.477 10 10zm-9.063-5v4.688H20v1.874h-5.938V10h1.876z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      );

    default:
      return null;
  }
};

export default Icon;
