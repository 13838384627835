import { SubwayStopAccessibility } from '../subway-types';

// Do not change this file content.
// You should update the ./src/ADAControlFile_LiveMap.csv file content and
// run "yarn update-ada-stops" to re-generate this file.

/*
 * There are no MTA APIs available to list ADA-compliant
 * stations that we know of, only one for outages on equipments.
 * This array lists all accessible stations provided by the MTA
 * team in ADAControlFile_LiveMap.csv
 */
const accessibilityStops: {
  [key in string]: SubwayStopAccessibility;
} = {
  '104': {
    ada: true,
    level: '1',
  },
  '109': {
    ada: true,
    level: '2',
  },
  '120': {
    ada: true,
    level: '1',
  },
  '123': {
    ada: true,
    level: '1',
  },
  '124': {
    ada: true,
    level: '1',
  },
  '125': {
    ada: true,
    level: '1',
  },
  '127': {
    ada: true,
    level: '1',
  },
  '128': {
    ada: true,
    level: '1',
  },
  '137': {
    ada: true,
    level: '1',
  },
  '138': {
    ada: true,
    level: '1',
  },
  '142': {
    ada: true,
    level: '1',
  },
  '205': {
    ada: true,
    level: '1',
  },
  '208': {
    ada: true,
    level: '1',
  },
  '211': {
    ada: true,
    level: '1',
  },
  '213': {
    ada: true,
    level: '1',
  },
  '217': {
    ada: true,
    level: '1',
  },
  '221': {
    ada: true,
    level: '1',
  },
  '224': {
    ada: true,
    level: '1',
  },
  '229': {
    ada: true,
    level: '1',
  },
  '235': {
    ada: true,
    level: '1',
  },
  '238': {
    ada: true,
    level: '1',
  },
  '244': {
    ada: true,
    level: '1',
  },
  '247': {
    ada: true,
    level: '1',
  },
  '250': {
    ada: true,
    level: '1',
  },
  '407': {
    ada: true,
    level: '1',
  },
  '412': {
    ada: true,
    level: '1',
  },
  '414': {
    ada: true,
    level: '1',
  },
  '418': {
    ada: true,
    level: '1',
  },
  '420': {
    ada: true,
    level: '1',
  },
  '423': {
    ada: true,
    level: '2',
  },
  '503': {
    ada: true,
    level: '1',
  },
  '601': {
    ada: true,
    level: '1',
  },
  '613': {
    ada: true,
    level: '1',
  },
  '621': {
    ada: true,
    level: '1',
  },
  '626': {
    ada: true,
    level: '2',
  },
  '630': {
    ada: true,
    level: '1',
  },
  '631': {
    ada: true,
    level: '1',
  },
  '633': {
    ada: true,
    level: '2',
  },
  '634': {
    ada: true,
    level: '1',
  },
  '637': {
    ada: true,
    level: '1',
  },
  '639': {
    ada: true,
    level: '1',
  },
  '640': {
    ada: true,
    level: '1',
  },
  '701': {
    ada: true,
    level: '1',
  },
  '707': {
    ada: true,
    level: '1',
  },
  '710': {
    ada: true,
    level: '1',
  },
  '712': {
    ada: true,
    level: '1',
  },
  '723': {
    ada: true,
    level: '1',
  },
  '725': {
    ada: true,
    level: '1',
  },
  '726': {
    ada: true,
    level: '1',
  },
  R03: {
    ada: true,
    level: '1',
  },
  R15: {
    ada: true,
    level: '2',
  },
  R30: {
    ada: true,
    level: '1',
  },
  R41: {
    ada: true,
    level: '1',
  },
  R44: {
    ada: true,
    level: '1',
  },
  D26: {
    ada: true,
    level: '1',
  },
  D32: {
    ada: true,
    level: '2',
  },
  D35: {
    ada: true,
    level: '1',
  },
  D43: {
    ada: true,
    level: '1',
  },
  B21: {
    ada: true,
    level: '1',
  },
  N02: {
    ada: true,
    level: '2',
  },
  M12: {
    ada: true,
    level: '1',
  },
  M16: {
    ada: true,
    level: '1',
  },
  M01: {
    ada: true,
    level: '1',
  },
  L06: {
    ada: true,
    level: '1',
  },
  L08: {
    ada: true,
    level: '1',
  },
  L20: {
    ada: true,
    level: '2',
  },
  L29: {
    ada: true,
    level: '1',
  },
  S03: {
    ada: true,
    level: '1',
  },
  A02: {
    ada: true,
    level: '1',
  },
  A07: {
    ada: true,
    level: '1',
  },
  A15: {
    ada: true,
    level: '1',
  },
  A25: {
    ada: true,
    level: '2',
  },
  A28: {
    ada: true,
    level: '1',
  },
  A32: {
    ada: true,
    level: '1',
  },
  A48: {
    ada: true,
    level: '1',
  },
  A55: {
    ada: true,
    level: '1',
  },
  A65: {
    ada: true,
    level: '1',
  },
  H01: {
    ada: true,
    level: '1',
  },
  H03: {
    ada: true,
    level: '1',
  },
  H15: {
    ada: true,
    level: '1',
  },
  H11: {
    ada: true,
    level: '1',
  },
  D03: {
    ada: true,
    level: '1',
  },
  D04: {
    ada: true,
    level: '1',
  },
  B04: {
    ada: true,
    level: '1',
  },
  B06: {
    ada: true,
    level: '1',
  },
  B08: {
    ada: true,
    level: '1',
  },
  D15: {
    ada: true,
    level: '1',
  },
  F27: {
    ada: true,
    level: '1',
  },
  F01: {
    ada: true,
    level: '1',
  },
  F06: {
    ada: true,
    level: '1',
  },
  G08: {
    ada: true,
    level: '1',
  },
  G21: {
    ada: true,
    level: '1',
  },
  G05: {
    ada: true,
    level: '1',
  },
  G06: {
    ada: true,
    level: '1',
  },
  G07: {
    ada: true,
    level: '1',
  },
  G26: {
    ada: true,
    level: '1',
  },
  Q05: {
    ada: true,
    level: '1',
  },
  Q04: {
    ada: true,
    level: '1',
  },
  Q03: {
    ada: true,
    level: '1',
  },
  S31: {
    ada: true,
    level: '1',
  },
  S25: {
    ada: true,
    level: '1',
  },
  S19: {
    ada: true,
    level: '1',
  },
  S09: {
    ada: true,
    level: '1',
  },
  S11: {
    ada: true,
    level: '1',
  },
  R20: {
    ada: true,
    level: '1',
  },
  L03: {
    ada: true,
    level: '1',
  },
  D11: {
    ada: true,
    level: '1',
  },
  A09: {
    ada: true,
    level: '1',
  },
  F09: {
    ada: true,
    level: '1',
  },
  R17: {
    ada: true,
    level: '1',
  },
  D17: {
    ada: true,
    level: '1',
  },
  R16: {
    ada: true,
    level: '1',
  },
  A27: {
    ada: true,
    level: '1',
  },
  F11: {
    ada: true,
    level: '1',
  },
  A24: {
    ada: true,
    level: '1',
  },
  B16: {
    ada: true,
    level: '1',
  },
  N04: {
    ada: true,
    level: '1',
  },
  G14: {
    ada: true,
    level: '1',
  },
  R31: {
    ada: true,
    level: '1',
  },
  D24: {
    ada: true,
    level: '1',
  },
  L01: {
    ada: true,
    level: '1',
  },
  A31: {
    ada: true,
    level: '1',
  },
  D21: {
    ada: true,
    level: '1',
  },
  M21: {
    ada: true,
    level: '1',
  },
  R25: {
    ada: true,
    level: '1',
  },
  E01: {
    ada: true,
    level: '1',
  },
  M22: {
    ada: true,
    level: '1',
  },
  A38: {
    ada: true,
    level: '1',
  },
  M08: {
    ada: true,
    level: '1',
  },
  L17: {
    ada: true,
    level: '1',
  },
  L26: {
    ada: true,
    level: '1',
  },
  R29: {
    ada: true,
    level: '1',
  },
  A41: {
    ada: true,
    level: '1',
  },
};

export default accessibilityStops;
