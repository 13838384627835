import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AppDispatch, AppState } from '../models';
import Tray, { TrayPosition } from '../components/Tray';
import { RouteStatus, SubwayRouteId } from '../subway-data/subway-types';
import ServiceStatusContent from '../components/ServiceStatusContent';
import { isPhone } from '../utils/deviceDetector.utils';
import { getMapSelectedRouteId } from '../selectors/map/basic';
import { getMapRoutesUnavailable } from '../selectors/map/getMapRoutesUnavailable';
import { RoutesUnavailable } from '../subway-data';

interface ServiceStatusContainerProps {
  currentTrayPosition: TrayPosition;
  mapRoutesUnavailable: RoutesUnavailable;
  selectedRouteId: SubwayRouteId | '';
  selectedRouteStatus: RouteStatus;
  serviceStatusOpen: boolean;
  stationViewOpened: boolean;
  setStatusViewOpened: (value: boolean) => void;
  setCurrentTrayPosition: (value: TrayPosition) => void;
}

const ServiceStatusContainer: React.FC<ServiceStatusContainerProps> = ({
  currentTrayPosition,
  mapRoutesUnavailable,
  selectedRouteId,
  serviceStatusOpen,
  setCurrentTrayPosition,
  setStatusViewOpened,
  selectedRouteStatus,
  stationViewOpened,
}) => {
  const selectedRouteRef = useRef(selectedRouteId);

  useEffect(() => {
    if (selectedRouteRef.current !== selectedRouteId) {
      selectedRouteRef.current = selectedRouteId;
    }
  }, [selectedRouteId]);

  useEffect(() => {
    if (selectedRouteId && !selectedRouteStatus?.statusDetails.length) {
      !stationViewOpened && setCurrentTrayPosition(TrayPosition.hidden);
      setStatusViewOpened(false);
    }
  }, [
    selectedRouteId,
    selectedRouteStatus,
    serviceStatusOpen,
    setCurrentTrayPosition,
    setStatusViewOpened,
    stationViewOpened,
  ]);

  return isPhone() ? (
    <Tray
      currentTrayPosition={currentTrayPosition}
      defaultPosition={TrayPosition.small}
      isOpen={serviceStatusOpen}
      onCloseCallback={() => setStatusViewOpened(false)}
      setCurrentTrayPosition={setCurrentTrayPosition}
    >
      <ServiceStatusContent
        isRouteUnavailable={mapRoutesUnavailable[selectedRouteId]}
        routeId={selectedRouteId}
        status={selectedRouteStatus}
        isOpen={serviceStatusOpen}
        isSmallPosition={
          serviceStatusOpen && currentTrayPosition === TrayPosition.small
        }
      />
    </Tray>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  currentTrayPosition: state.ui.currentTrayPosition,
  mapRoutesUnavailable: getMapRoutesUnavailable(state),
  selectedRouteId: getMapSelectedRouteId(state),
  serviceStatusOpen: state.ui.statusViewOpened,
  stationViewOpened: state.ui.stationViewOpened,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setCurrentTrayPosition: dispatch.ui.setCurrentTrayPosition,
  setStatusViewOpened: dispatch.ui.setStatusViewOpened,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceStatusContainer);
