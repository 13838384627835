/**
 * Checks whether one item precedes another in an array.
 */
import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';

import {
  OtpRouteId,
  RouteDirection,
  StopBase,
  StopList,
} from '../subway-types';

export const precedesIn = <
  ITEM,
  ITEMS extends Array<ITEM> | ReadonlyArray<ITEM>
>(
  items: ITEMS,
  a: ITEM,
  b: ITEM
): boolean => {
  const indexOfA = items.indexOf(a);
  const indexOfB = items.indexOf(b);
  return indexOfA >= 0 && indexOfB >= 0 && indexOfA < indexOfB;
};

export interface Skippable<T> {
  value: T;
  skipped?: boolean;
}

export interface SkippableStopList extends ReadonlyArray<Skippable<StopBase>> {}

/**
 * Takes a list of stops for only a train's stopping points,
 * and "unabbreviates" it to include passthrough stops, marking those as skipped.
 * This is essential for rendering patternGraph stops on the map in such a way
 * that the train lines pass through the correct stops along the way,
 * rather than cutting across the map directly.
 *
 * @param inputs An array of stops for one route, generally from patternGraph, where a train stops. It does not contain the passthrough stop IDs, thus it is "abbreviated."
 * @param references An array of stop IDs for a route that contains the stops where the train stops or passes through.
 * @param routeId Not needed for the algorithm, but useful for debugging.
 * @param directionId Not needed for the algorithm, but useful for debugging.
 * @returns An array of objects, each containing a stop ID and a flag whether the stop is being skipped, because the train is passing through without stopping.
 */
export const unabbreviate = (
  inputs: StopBase[] | undefined,
  references: StopList,
  routeId?: OtpRouteId,
  directionId?: RouteDirection
): SkippableStopList => {
  if (!inputs) return [];
  // This array's contents will be mutated in this function but cast as immutable before returning.
  const results: Skippable<StopBase>[] = [];

  for (let inputIndex = 0; inputIndex < inputs.length; inputIndex++) {
    const currentInput = inputs[inputIndex];
    // Each of the inputs will be added to the results.
    results.push({ value: currentInput });

    const nextInput: StopBase | undefined = inputs[inputIndex + 1];

    // Check if the references contain both the current input and the next input.
    let currentReferenceIndex = findLastIndex(references, {
      stopId: currentInput.stopId,
    });
    const nextReferenceIndex = findIndex(references, {
      stopId: nextInput?.stopId,
    });

    // If PG's next stop has the reference as a rerouting stop, we try to
    // find the previous stop as a rerouting stop.It will cover the creation
    // of unexpected branches with skipping stops.
    if (
      references[nextReferenceIndex] &&
      references[nextReferenceIndex].isRerouting
    ) {
      currentReferenceIndex =
        findLastIndex(references, {
          isRerouting: true,
          stopId: currentInput.stopId,
        }) ?? currentReferenceIndex;
    }

    // Some routes double back on themselves in stopsOnRoutes.ts to handle forks,
    // so we need to use the first index of the first station rather than lastIndexOf
    // E.g. to prevent issue #1146: route 5 drawing an extra line directly between Franklin Av and Crown Hts Utica Av
    if (nextReferenceIndex < currentReferenceIndex) {
      currentReferenceIndex = findIndex(references, {
        stopId: currentInput.stopId,
      });
    }
    const foundBothInputs =
      currentReferenceIndex >= 0 && nextReferenceIndex >= 0;
    // The two found items must be at least 2 indices apart in order for there to be skipped item(s) in-between.
    const foundSkipped = nextReferenceIndex - currentReferenceIndex >= 2;
    if (foundBothInputs && foundSkipped) {
      // Collect the stop IDs that were skipped between currentInput and nextInput
      const skippedReferences = references.slice(
        currentReferenceIndex + 1,
        nextReferenceIndex
      );
      // If one of the stops in one direction, we use it. At this point,
      // we will never have stops in opposite directions.
      const direction = currentInput.direction ?? nextInput.direction;

      const skippedReferenceValues: Skippable<
        StopBase
      >[] = skippedReferences.map(stop => ({
        value: {
          direction,
          isTerminal: false,
          stopId: stop.stopId,
          stopName: stop.stopName,
        },
        skipped: true,
      }));

      if (skippedReferenceValues.length) {
        results.push(...skippedReferenceValues);
      }
    }
  }
  return results as SkippableStopList;
};
