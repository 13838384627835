import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  orderedSubwayRouteIds,
  RoutesUnavailable,
  RoutesWithSituation,
  TimeFilter,
} from '../../subway-data';
import { SubwayRouteId } from '../../subway-data/subway-types';
import { landscapePhone } from '../../utils/theme';
import SubwayRouteButton from './SubwayRouteButton';
import MinimizeButton from './MinimizeButton';
import { isPhone } from '../../utils/deviceDetector.utils';
import {
  MENU_TRANSITION_DELAY_IN,
  MENU_TRANSITION_DELAY_OUT,
  TIME_TOGGLE_TRANSITION,
} from '../../utils/animations';
import Footer from '../Footer/Footer';
import ModalOverlay from '../ModalOverlay';
import { subTitleSmall } from '../Text';
import { selectedTimeDifferentFromCurrentMessage } from '../ui-constants';
import { timeLabels } from '../../utils/date.utils';

interface RouteMenuProps {
  currentTime: TimeFilter;
  extraPaddingBottom?: boolean;
  isOpen: boolean;
  routesUnavailable: RoutesUnavailable;
  routesWithSituation: RoutesWithSituation;
  selectedRouteId: SubwayRouteId | '';
  timeFilter: TimeFilter;
  onOpen: () => void;
  onSelectRoute: (routeId: SubwayRouteId) => void;
  onRequestClose: () => void;
}

const ITEMS_PER_COLUMN = 4;
const ITEMS_PER_LINE = 6;
const ITEMS_PER_LINE_LANDSCAPE = 13;
const ITEM_PADDING_FACTOR = 0.14;

const MenuBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.colors.white};
  z-index: -1;
  transform-origin: 20px 95%;
`;

const MenuContent = styled.div`
  min-width: 300px;
  width: 100%;

  ${landscapePhone(css`
    max-width: 80%;
    padding-bottom: 26px;
  `)}
`;

const HeadingContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
`;

const TimeFilterLabel = styled.p<{ isOpen: boolean }>`
  ${subTitleSmall};
  margin-right: auto;
`;

const FooterWrapper = styled.div<{ isOpen: boolean }>`
  bottom: 0;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: ${p => p.theme.transition};
  transition-delay: ${(p: any) =>
    p.isOpen ? TIME_TOGGLE_TRANSITION + MENU_TRANSITION_DELAY_IN : 0}ms;
  transform: ${p => (p.isOpen ? 'translateY(0%)' : 'translateY(100%)')};

  ${landscapePhone(css`
    background-color: ${p => p.theme.colors.grayF7};
    position: initial;
  `)};
`;

const SubwayRoutesContainer = styled.ul`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin: 32px ${(100 / ITEMS_PER_LINE) * -ITEM_PADDING_FACTOR}% 2px;

  ${landscapePhone(css`
    margin: 16px ${(100 / ITEMS_PER_LINE_LANDSCAPE) * -ITEM_PADDING_FACTOR}% 0;
  `)};
`;

const SubwayRouteButtonContainer = styled.li<{ withTransitions: boolean }>`
  position: relative;
  box-sizing: border-box;
  opacity: ${p => (p.withTransitions ? 1 : 0)};

  flex: 0 0 ${100 / ITEMS_PER_LINE}%;

  ${landscapePhone(css`
    flex: 0 0 ${100 / ITEMS_PER_LINE_LANDSCAPE}%;
  `)}
`;

const Menu = styled.nav<{
  extraPaddingBottom?: boolean;
  isOpen: boolean;
  withTransitions: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh - 60px);
  padding: 24px;
  padding-bottom: calc(
    env(safe-area-inset-bottom) +
      ${p => (p.extraPaddingBottom ? '60px' : '20px')}
  );
  overflow-y: ${p => (p.isOpen ? 'auto' : 'hidden')};
  user-select: none;
  -ms-overflow-style: none;
  will-change: transform;
  transition: all 0.4s ease-in-out;
  transition-duration: ${p => (p.withTransitions ? '0.3s' : '0s')};
  transition-delay: ${p =>
    p.isOpen
      ? MENU_TRANSITION_DELAY_IN + 100
      : MENU_TRANSITION_DELAY_OUT + 100}ms;
  z-index: ${p => (p.isOpen ? p.theme.zIndex.routeMenu : -1)};

  ${landscapePhone(css`
    max-width: inherit;
    padding: 16px 0 calc(env(safe-area-inset-bottom));
  `)}

  ${MenuBackground} {
    transform: ${p => (p.isOpen ? 'scale(1)' : 'scale(0)')};
    border-radius: ${p => (p.isOpen ? '0%' : '50%')};
    will-change: transform;
    transition: all 0.4s ease-in-out;
    transition-duration: ${p => (p.withTransitions ? '0.3s' : '0s')};
    transition-delay: ${p =>
      p.isOpen ? MENU_TRANSITION_DELAY_IN : MENU_TRANSITION_DELAY_OUT + 300}ms;
  }

  ${HeadingContent} {
    opacity: ${p => (p.isOpen ? '1' : '0')};
    transform: translate3d(0, 0, 0);
    transition: opacity 0.5s ease-in-out;
    transition-duration: ${p => (p.withTransitions ? '0.25s' : '0s')};
    transition-delay: ${p =>
      p.isOpen ? MENU_TRANSITION_DELAY_IN + 400 : MENU_TRANSITION_DELAY_OUT}ms;
  }

  ${SubwayRouteButtonContainer} {
    transition: all 0.5s ease-in-out;
    transition-duration: ${p => (p.withTransitions ? '0.4s' : '0s')};
    transition-delay: ${p =>
      p.isOpen ? MENU_TRANSITION_DELAY_IN : MENU_TRANSITION_DELAY_OUT}ms;
  }

  ${SubwayRoutesContainer} {
    [data-animation-number='0'] {
      transition-delay: ${p =>
        p.isOpen
          ? MENU_TRANSITION_DELAY_IN + 190
          : MENU_TRANSITION_DELAY_OUT + 190}ms;
    }

    [data-animation-number='1'] {
      transition-delay: ${p =>
        p.isOpen
          ? MENU_TRANSITION_DELAY_IN + 180
          : MENU_TRANSITION_DELAY_OUT + 180}ms;
    }

    [data-animation-number='2'] {
      transition-delay: ${p =>
        p.isOpen
          ? MENU_TRANSITION_DELAY_IN + 170
          : MENU_TRANSITION_DELAY_OUT + 170}ms;
    }

    [data-animation-number='3'] {
      transition-delay: ${p =>
        p.isOpen
          ? MENU_TRANSITION_DELAY_IN + 160
          : MENU_TRANSITION_DELAY_OUT + 160}ms;
    }

    [data-animation-number='4'] {
      transition-delay: ${p =>
        p.isOpen
          ? MENU_TRANSITION_DELAY_IN + 150
          : MENU_TRANSITION_DELAY_OUT + 150}ms;
    }

    [data-animation-number='5'] {
      transition-delay: ${p =>
        p.isOpen
          ? MENU_TRANSITION_DELAY_IN + 140
          : MENU_TRANSITION_DELAY_OUT + 140}ms;
    }

    [data-animation-number='6'] {
      transition-delay: ${p =>
        p.isOpen
          ? MENU_TRANSITION_DELAY_IN + 130
          : MENU_TRANSITION_DELAY_OUT + 130}ms;
    }

    [data-animation-number='7'] {
      transition-delay: ${p =>
        p.isOpen
          ? MENU_TRANSITION_DELAY_IN + 120
          : MENU_TRANSITION_DELAY_OUT + 120}ms;
    }

    [data-animation-number='8'] {
      transition-delay: ${p =>
        p.isOpen
          ? MENU_TRANSITION_DELAY_IN + 110
          : MENU_TRANSITION_DELAY_OUT + 110}ms;
    }

    [data-animation-number='9'] {
      transition-delay: ${p =>
        p.isOpen
          ? MENU_TRANSITION_DELAY_IN + 100
          : MENU_TRANSITION_DELAY_OUT + 100}ms;
    }
  }
`;

const getItemAnimationNumber = (index: number): number => {
  const diagonalsCount = 9;
  const x = index % ITEMS_PER_LINE;
  const y = Math.floor(index / ITEMS_PER_LINE);
  return isPhone() ? ITEMS_PER_COLUMN - (y - x) : diagonalsCount - (x + y);
};

const SubwayRouteIconWrapper = styled.div`
  width: 100%;
  padding: ${ITEM_PADDING_FACTOR * 100}%;
`;

const SubwayRoutesMenu: React.FC<RouteMenuProps> = ({
  currentTime,
  routesUnavailable,
  routesWithSituation,
  selectedRouteId = '',
  isOpen,
  onOpen,
  onSelectRoute,
  onRequestClose,
  extraPaddingBottom,
  timeFilter,
}) => {
  const menuRef = useRef<HTMLElement>(null);
  const onOpenTimeoutRef = useRef<number | undefined>();

  // If menu is already opened at first mount, we can initialize transitions right away
  const [withTransitions, setWithTransitions] = useState<boolean>(isOpen);

  useEffect(() => {
    if (isOpen) {
      onOpenTimeoutRef.current = setTimeout(() => {
        onOpen();
      }, MENU_TRANSITION_DELAY_IN);
    }

    return () => {
      clearTimeout(onOpenTimeoutRef.current);
    };
  }, [isOpen, onOpen]);

  useEffect(() => {
    if (!menuRef.current) {
      return;
    }

    if (isOpen) {
      const items: HTMLLIElement[] = Array.from(
        menuRef.current.querySelectorAll('[data-menu-item="true"]')
      );
      items.forEach(item => {
        item.style.transform = '';
      });

      menuRef.current.focus();
    } else {
      const menu = menuRef.current.getBoundingClientRect();
      const items: HTMLLIElement[] = Array.from(
        menuRef.current.querySelectorAll('[data-menu-item="true"]')
      );

      const itemRects = items.map(item => item.getBoundingClientRect());
      itemRects.forEach((data, index) => {
        const item = items[index];
        const diffY = isPhone()
          ? window.innerHeight - data.top + data.height / 3
          : -(data.top - menu.top - data.height / 3);
        const diffX = isPhone()
          ? -data.left - data.width / 3
          : -(data.left - menu.left - data.width / 3);
        item.style.transform = `translate(${diffX}px, ${diffY}px) scale(0)`;
      });

      if (!withTransitions) {
        /*
         * Initialize transitions after moving
         * all menu items to their right position
         */
        setTimeout(() => {
          setWithTransitions(true);
        });
      }
    }
  }, [isOpen, withTransitions]);

  return (
    <>
      {isOpen && (
        <ModalOverlay
          color="transparent"
          zIndex={2}
          onClick={onRequestClose}
          hiddenOnDesktop
        />
      )}
      <Menu
        data-cy="menu"
        isOpen={isOpen}
        ref={menuRef}
        withTransitions={withTransitions}
        extraPaddingBottom={extraPaddingBottom}
      >
        <MenuBackground />
        <MenuContent>
          <HeadingContent>
            {currentTime !== timeFilter && (
              <TimeFilterLabel isOpen={isOpen}>
                {selectedTimeDifferentFromCurrentMessage(
                  timeLabels[currentTime][timeFilter].label
                )}
              </TimeFilterLabel>
            )}
            <MinimizeButton
              data-cy="minimize-route-menu"
              aria-label="Minimize route menu"
              onClick={onRequestClose}
              type="close"
            />
          </HeadingContent>
          <SubwayRoutesContainer>
            {orderedSubwayRouteIds.map((routeId, index) => {
              const deemphasized = !!routesUnavailable[routeId];
              const hasSituation = !!routesWithSituation[routeId];
              const selected = routeId === selectedRouteId;

              return (
                <SubwayRouteButtonContainer
                  key={routeId}
                  data-menu-item="true"
                  data-animation-number={getItemAnimationNumber(index)}
                  withTransitions={withTransitions}
                >
                  <SubwayRouteIconWrapper>
                    <SubwayRouteButton
                      {...{
                        routeId,
                        selected,
                        deemphasized,
                        hasSituation,
                        isOpen,
                      }}
                      onClick={e => {
                        e.stopPropagation();
                        onSelectRoute(routeId);
                      }}
                    />
                  </SubwayRouteIconWrapper>
                </SubwayRouteButtonContainer>
              );
            })}
          </SubwayRoutesContainer>
        </MenuContent>
        <FooterWrapper isOpen={isOpen}>
          <Footer />
        </FooterWrapper>
      </Menu>
    </>
  );
};

export default SubwayRoutesMenu;
