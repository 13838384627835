import { createSelector } from 'reselect';
import { getMapStopListsByRoute } from './getMapStopListsByRoute';
import {
  StopList,
  StopLists,
  StopListsByRoute,
} from '../../subway-data/subway-types';

export const getMapFilteredStopListsByRoute = createSelector(
  getMapStopListsByRoute,
  (stopListsByRoute: StopListsByRoute): StopListsByRoute => {
    // Do not include stops where route
    // doesn't stop after final stop
    const results = {} as StopListsByRoute;
    // TODO: refactor to Object.entries
    Object.keys(stopListsByRoute).forEach(routeId => {
      const stopLists: StopLists = stopListsByRoute[routeId];
      results[routeId] = stopLists.map(
        stops =>
          stops.filter((stop, index) => {
            // Eventually we'll have safeguards here but for now, throw an instructive error message
            // to encourage fixing the problem at the source.
            if (!stop) {
              const errorMessage = `A stop was undefined on route ${routeId} at index ${index}. You may need to add a stop ID mapping to complexStations.\nStops argument: ${stops.map(
                s => s?.stopId + ' - ' + s?.stopName
              )}`;
              console.error(errorMessage);
              // throw new Error(errorMessage);
              return false;
            }

            // Always include if route stops at current station
            if (stop.stopType !== '2') {
              return true;
            }

            // Loop over remaining stations to search for
            // ones where route stops
            for (let i = index + 1; i <= stops.length - 1; i++) {
              if (stops[i]!.stopType !== '2') {
                return true;
              }
            }

            return false;
          }) as StopList
      );
    });
    return results;
  }
);
