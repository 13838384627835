import React from 'react';
import { TrainTime } from '../../subway-data/station-status-types';
import { StationStatusRoutesIcons } from './StationStatusRoutesIcons';
import { SubwayRouteId } from '../../subway-data/subway-types';

import styled, { css } from 'styled-components/macro';
import { tablet, desktopExtraLarge } from '../../utils/theme';
import { RoutesUnavailable } from '../../subway-data';

const cellIconWidth = { phone: '60px', tablet: '35px', desktopXL: '45px' };
const cellTimeWidth = { phone: '45px', tablet: '38px', desktopXL: '50px' };

const Row = styled.tr`
  border-bottom: 1px solid ${p => p.theme.colors.lightGray};

  &:last-child {
    border: none;
  }
`;

const RowBtn = styled(Row)`
  cursor: pointer;
`;

const Icon = styled.td`
  padding-top: 16px;
  vertical-align: top;
  width: ${cellIconWidth.phone};

  ${tablet(css`
    padding-top: 9px;
    width: ${cellIconWidth.tablet};
  `)}

  ${desktopExtraLarge(css`
    padding-top: 16px;
    width: ${cellIconWidth.desktopXL};
  `)}

    svg {
    transform-origin: center left;

    ${tablet(css`
      transform: scale(0.6666);
    `)}

    ${desktopExtraLarge(css`
      transform: scale(0.8333);
    `)}
  }
`;

const Name = styled.td`
  color: ${p => p.theme.colors.gray7F};
  font-size: 1.4rem;
  line-height: 1.285;
  padding: 16px 0;
  text-align: left;
  vertical-align: top;
  text-transform: capitalize;
  width: calc(100% - ${cellIconWidth.phone} - ${cellTimeWidth.phone});

  ${tablet(css`
    font-size: 1.1rem;
    line-height: 1.454;
    padding: 12px 0;
    width: calc(100% - ${cellIconWidth.tablet} - ${cellTimeWidth.tablet});
  `)}

  ${desktopExtraLarge(css`
    font-size: 1.4rem;
    line-height: 1.142;
    padding: 17px 0;
    width: calc(100% - ${cellIconWidth.desktopXL} - ${cellTimeWidth.desktopXL});
  `)}
`;

const ServiceName = styled(Name)`
  text-transform: none;
`;

const NameTitle = styled.span`
  color: ${p => p.theme.colors.black};
  font-weight: bold;
  display: block;
  margin-bottom: 2px;

  ${tablet(css`
    margin-bottom: 1px;
  `)}

  ${desktopExtraLarge(css`
    margin-bottom: 3px;
  `)}
`;

const Time = styled.td`
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.857;
  padding: 17px 0;
  text-align: right;
  vertical-align: top;
  width: ${cellTimeWidth.phone};

  ${tablet(css`
    font-size: 1.2rem;
    line-height: 1.333;
    padding: 13px 0;
    width: ${cellTimeWidth.tablet};
  `)}

  ${desktopExtraLarge(css`
    font-size: 1.5rem;
    line-height: 1.006;
    padding: 15px 0;
    width: ${cellTimeWidth.desktopXL};
  `)}
`;

const TimeLabel = styled.small`
  display: inline-block;
  font-size: 1.2rem;
  line-height: 2.166;
  margin-left: 1px;

  ${tablet(css`
    font-size: 1rem;
    line-height: 1.6;
  `)}

  ${desktopExtraLarge(css`
    font-size: 1.25rem;
    line-height: 1.28;
  `)}
`;

const ExpressLabel = styled.div`
  background-color: ${p => p.theme.colors.gray7F};
  color: ${p => p.theme.colors.white};
  font-size: 0.9rem;
  border-radius: 99px;
  width: max-content;
  line-height: 1;
  padding: 3px 7px;
  margin: 5px 0 4px;
`;

export const StationStatusTrainsTableRow: React.FC<Omit<
  TrainTime,
  'tripId' | 'directionId'
>> = ({ routeId, longName, time, stopHeadsign, isExpress }) => {
  const timeInMinutes = Math.round(time / 60);
  return (
    <Row>
      <Icon>
        <StationStatusRoutesIcons
          routeIds={[routeId as SubwayRouteId]}
          width={36}
        />
      </Icon>
      <Name>
        <NameTitle>{longName.toLocaleLowerCase()}</NameTitle>
        {stopHeadsign}
        {isExpress && <ExpressLabel>EXPRESS</ExpressLabel>}
      </Name>
      <Time>
        {timeInMinutes === 0 ? (
          <TimeLabel>Now</TimeLabel>
        ) : (
          <>
            {timeInMinutes}
            <TimeLabel>min</TimeLabel>
          </>
        )}
      </Time>
    </Row>
  );
};

interface StationStatusServiceTableRowProps {
  hasServiceAlerts: boolean;
  routeId: SubwayRouteId;
  setSelectedRouteId: (routeId: SubwayRouteId | '') => void;
  unavailableRoutes: RoutesUnavailable;
}
export const StationStatusServiceTableRow: React.FC<StationStatusServiceTableRowProps> = ({
  hasServiceAlerts,
  routeId,
  setSelectedRouteId,
  unavailableRoutes,
}) => {
  return (
    <RowBtn onClick={() => setSelectedRouteId(routeId)} role="button">
      <Icon>
        <StationStatusRoutesIcons
          routeIds={[routeId as SubwayRouteId]}
          routesUnavailableForStation={unavailableRoutes}
          width={36}
        />
      </Icon>
      <ServiceName>
        <NameTitle>
          {unavailableRoutes[routeId]
            ? 'No service'
            : hasServiceAlerts
            ? 'Running with changes'
            : 'Good service expected'}
        </NameTitle>
        {hasServiceAlerts && !unavailableRoutes[routeId]
          ? 'Stops and timing will vary'
          : 'Both directions'}
      </ServiceName>
    </RowBtn>
  );
};
