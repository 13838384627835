import flatten from 'lodash/flatten';
import round from 'lodash/round';
import { Map as OlMap } from 'ol';
import { clamp } from 'ol/math';

import { Point, LineAB } from '../geometry/geometry-types';
import {
  rotatePointsDegrees,
  getSegmentsNormalAnglesDegrees,
} from '../geometry/point-utils';

import { STATION_SCALE } from './maps-constants';
import { ZoomLevel } from './map-types';
import { getStrategyByIndex } from '../geometry/path-strategies';
import { MANHATTAN_DEGREES, RoutesUnavailable } from '../subway-data';
import { stopOneDirectionAnglesForStopId } from '../subway-data/stop-one-direction-angles';
import {
  OtpRouteId,
  RouteDirection,
  StopListsByRoute,
  SubwayRouteIds,
} from '../subway-data/subway-types';

const olMapInstance = new OlMap({});

// Export specific map functions
export const getOlMapZoom = (
  resolution: number,
  digits: number = 2
): number => {
  if (digits >= 0) {
    return round(
      olMapInstance.getView().getZoomForResolution(resolution) ?? NaN,
      digits
    );
  }
  return olMapInstance.getView().getZoomForResolution(resolution) ?? NaN;
};

// https://openlayers.org/en/latest/examples/vector-labels.html
// http://stackoverflow.com/questions/14484787/wrap-text-in-javascript
export const wrapText = (
  str: string,
  width: number,
  spaceReplacer = '\n'
): string => {
  if (str.length > width) {
    let p: number = width;
    let exceedingChars = str.slice(-(str.length - width));
    // This checks if there is more than one exceeding word
    if (!/(\w.+\s).+/g.test(exceedingChars)) {
      exceedingChars = exceedingChars.replace(' ', '');
      const abbreviations = ['Blvd', 'Pkwy', 'St', 'Av'];
      const index = abbreviations.findIndex(a => a.includes(exceedingChars));
      p = width - (index === -1 ? 0 : abbreviations[index].length + 1);
    }

    while (p > 0 && str[p] !== ' ' && str[p] !== '-') {
      p--;
    }
    if (p > 0) {
      let left = str.substring(p, p + str.substring(p, p + 1) === '-' ? 1 : 0);
      let right = str.substring(p + 1);
      return left + spaceReplacer + wrapText(right, width, spaceReplacer);
    }
  }

  return str;
};

export const cleanSubwayName = (title: string): string =>
  title.replace(' - ', ' ').replace('/', ' ');

// The object should contain keys between zoom levels where the values would
// change. Following the design tables for the elements.

// A text that is z12: 11, z13: 11, z14: 11, z15: 13, z17: 13, z18: 15, should
// have an object as:
// {
//   [ZoomLevel.14]: 11,
//   [ZoomLevel.15]: 13,
//   [ZoomLevel.17]: 13,
//   [ZoomLevel.18]: 15
// }
export const getProportionalValueForZoom = (
  currentZoom: number,
  values: { [key in ZoomLevel]?: number }
): number => {
  const sortedZooms = Object.keys(values).sort((a, b) => +a - +b);

  if (!sortedZooms.length) {
    return NaN;
  }

  const { minZoom, maxZoom } = sortedZooms.reduce<{
    maxZoom?: ZoomLevel;
    minZoom: ZoomLevel;
  }>(
    (acc, zoom) => {
      if (currentZoom >= +zoom) {
        acc.minZoom = +zoom;
      } else if (currentZoom <= +zoom && !acc.maxZoom) {
        acc.maxZoom = +zoom;
      }

      return acc;
    },
    { minZoom: +sortedZooms[0] }
  );

  if (!maxZoom || minZoom === maxZoom) {
    return values[minZoom]!;
  }

  // The math behind the proportion formula

  // We find the value using the proportion between the ratio of the min and
  // max zoom/values, and the min and current zoom/values.

  // A - minZoom
  // B - minValue
  // C - currentZoom
  // D - maxZoom
  // E - maxValue
  // x - proportional value
  // https://www.symbolab.com/solver/solve-for-equation-calculator/%5Cleft(D-A%5Cright)%2F%5Cleft(E-B%5Cright)%3D%5Cleft(C-A%5Cright)%2F%5Cleft(x-B%5Cright)

  return (
    ((currentZoom - minZoom) * (values[maxZoom]! - values[minZoom]!)) /
      (maxZoom - minZoom) +
    values[minZoom]!
  );
};

export const getNormalAngleAndPointsForLinePoints = (
  linePoints: LineAB,
  pathStrategyIndex: number = 0
): {
  segmentsNormalAnglesDegrees: number[];
  points: Point[];
} => {
  const rotatedPoints = rotatePointsDegrees(linePoints, MANHATTAN_DEGREES);
  const strategyRotatedPoints = getStrategyByIndex(pathStrategyIndex)(
    rotatedPoints
  );

  return {
    segmentsNormalAnglesDegrees: getSegmentsNormalAnglesDegrees(rotatedPoints),
    points: rotatePointsDegrees(strategyRotatedPoints, -MANHATTAN_DEGREES),
  };
};

export const getRouteLineWidth = (pixelRatio: number, resolution: number) => {
  return clamp(
    (9 * pixelRatio * STATION_SCALE) / resolution,
    1.5 * pixelRatio,
    Infinity
  );
};

export const getStopDirectionAngle = ({
  direction,
  routeId,
  segmentNormalAngles = [],
  stationAngle,
  stopId,
}: {
  direction: RouteDirection | undefined;
  routeId: OtpRouteId;
  segmentNormalAngles: number[];
  stationAngle: number;
  stopId: string;
}) => {
  // Some grouped stations need arrows pointing in different directions
  // because of the way the lines connect.
  // Coney Island - Stillwell Av for N, D, F, and Q is an example where
  // F and Q need an arrow pointing to the left and N and D pointing
  // right to represent the downtown direction.
  const angleModifierForStopAndRoute =
    stopOneDirectionAnglesForStopId[`${stopId}_${routeId}`];
  const angleModifierForStop = stopOneDirectionAnglesForStopId[stopId];

  let result = stationAngle;

  if (segmentNormalAngles[0] !== undefined) {
    result = segmentNormalAngles[0];
  }

  // We draw the lines going downtown (direction 1).
  // If the arrow direction is uptown, we rotate the current angle.
  if (direction === '0') {
    result += 180;
  }

  return result + (angleModifierForStopAndRoute ?? angleModifierForStop ?? 0);
};

export const getRoutesUnavailableForStop = (
  stopId: string,
  routeIdsOnStop: SubwayRouteIds,
  stopListsByRouteRaw: Partial<StopListsByRoute>
): RoutesUnavailable => {
  const routesUnavailableForStop: RoutesUnavailable = {};

  routeIdsOnStop.forEach(routeId => {
    const stops = flatten(stopListsByRouteRaw[routeId])?.filter(
      stop => stop.stopId === stopId
    );

    // If we don't have the stops, the route is unavailable, and the stop too.
    // Because of how we deal with the static stops and the segments, we have
    // duplicated stops for some branches and not running segments. The stop
    // will be unavailable if all the duplicates are unavailable.
    routesUnavailableForStop[routeId] =
      stops.length === 0 || stops.every(stop => stop.unavailable);
  });

  return routesUnavailableForStop;
};
