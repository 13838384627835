import { createSelector } from 'reselect';
import { MapState } from '../../models/map';
import {
  OtpRouteId,
  Station,
  Stations,
  Stop,
  StopList,
  StopLists,
  StopListsByRoute,
  SubwayRouteId,
  SubwayRouteIds,
} from '../../subway-data/subway-types';
import {
  sortRouteIdsForIconDisplay,
  stations,
  subwayRouteIdFromOtpRouteId,
  TimeFilter,
} from '../../subway-data';
import { getMapStopListsByTimeFilterAndRoute, getMapTimeFilter } from './basic';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

const processAndSortTransfers = (routeIds: SubwayRouteIds): SubwayRouteIds => {
  // Remove duplicate routeIds created by doubling back on lines at route forks
  return uniq(
    // Catch aliases like 6X for 6
    routeIds.map(routeId => subwayRouteIdFromOtpRouteId(routeId as OtpRouteId))
    // The transfer icons need to be displayed in the same order as the main menu icons,
    // whereas station.transfers is ordered by the necessary line positioning in the map layout.
    // E.g. at 57 St/7 Av, station.transfers is RWNQ for track layout, but the icons should be NQRW.
  ).sort(sortRouteIdsForIconDisplay);
};

/**
 * Helper function for calculating which trains stop at or pass through which stop IDs.
 * It goes through all the stops on a route
 * and marks the intersection of routeId and stopId in `stationTransfersAndPassthroughs`,
 * and possibly in `stationTransfers`.
 * @param routeId
 * @param stopsForRoute
 * @param stationTransfers Will be mutated by this function.
 * @param stationTransfersAndPassthroughs Will be mutated by this function.
 */
const collectTransfersForRoute = (
  routeId: SubwayRouteId,
  stopsForRoute: StopList,
  stationTransfers: { [stopId: string]: SubwayRouteIds },
  stationTransfersAndPassthroughs: {
    [stopId: string]: SubwayRouteIds;
  }
): void => {
  for (const stop of stopsForRoute) {
    const { stopId, stopType } = stop;

    // A station's `transfers` data includes only the trains that actually stop at the station.
    // We use stopType: 2 to indicate that the train passes through a station without stopping,
    // and filter that out of `transfers`.
    // This data is used for transfer icons by station names.
    // E.g. if Lafayette Av has A and C going through it but only the C is stopping,
    // the station will have two blue lines going through but just the C circle transfer icon.
    if (stopType !== '2') {
      const transfersSoFar = stationTransfers[stopId] || [];
      const transfersExpanded: SubwayRouteIds = [...transfersSoFar, routeId];
      stationTransfers[stopId] = uniq(transfersExpanded);
    }

    // The separate `transfersAndPassthroughs` data is important for
    // calculating positioning of parallel train lines.
    // E.g. a station may have 3 trains going through it, but only 2 stopping at the station.
    // So we would draw three lines and two dots,
    // and use the total number of `transfersAndPassthroughs`
    // to calculate how far to shift them from the station's center point.
    const transfersAndPassthroughsSoFar =
      stationTransfersAndPassthroughs[stopId] || [];
    const transfersAndPassthroughsExpanded: SubwayRouteIds = [
      ...transfersAndPassthroughsSoFar,
      routeId,
    ];
    stationTransfersAndPassthroughs[stopId] = uniq(
      transfersAndPassthroughsExpanded
    );
  }
};

/**
 * Finds the first and last stops for a route and adds them to stationTerminals.
 * @param routeId
 * @param stopListsForRoute
 * @param stationTerminals Will be mutated by this function.
 */
const collectTerminalsForRoute = (
  routeId: SubwayRouteId,
  stopListsForRoute: StopLists,
  stationTerminals: { [stopId: string]: SubwayRouteIds }
): void => {
  for (const stopsForRoute of stopListsForRoute) {
    if (!stopsForRoute.length) continue;

    stopsForRoute.forEach(stop => {
      if (stop.isTerminal) {
        const stopTerminalsSoFar = stationTerminals[stop.stopId] || [];
        const terminalsExpanded: SubwayRouteIds = [
          ...stopTerminalsSoFar,
          routeId,
        ];
        stationTerminals[stop.stopId] = uniq(terminalsExpanded);
      }
    });
  }
};

export const getMapStations = createSelector(
  getMapTimeFilter,
  getMapStopListsByTimeFilterAndRoute,
  (
    mapTimeFilter: TimeFilter,
    mapStopListsByTimeFilterAndRoute: MapState['stopListsByTimeFilterAndRoute']
  ): Stations => {
    const stopListsByRouteAtTime: Partial<StopListsByRoute> =
      mapStopListsByTimeFilterAndRoute[mapTimeFilter] || {};

    // This will be a index of transfers per station.
    const stationTransfers: { [stopId: string]: SubwayRouteIds } = {};
    const stationTransfersAndPassthroughs: {
      [stopId: string]: SubwayRouteIds;
    } = {};
    const stationTerminals: { [stopId: string]: SubwayRouteIds } = {};

    // Iterate through stops for the selected time filter
    // and build up the index of transfers per station.
    for (const [routeId, stopLists] of Object.entries(stopListsByRouteAtTime)) {
      // patternGraph might be empty for this route at this time
      if (!stopLists?.length) continue;
      const stops = flatten<Stop>(stopLists);
      // KEEP for debugging as sometimes this assert fails
      // console.assert(
      //   stops.length === uniq(stops).length,
      //   'should be no duplicates in flattened stops - stopListsByRouteAtTime - route ' +
      //     routeId
      // );
      collectTransfersForRoute(
        routeId as SubwayRouteId,
        stops,
        stationTransfers,
        stationTransfersAndPassthroughs
      );
      // Calculate the terminals for each route from patternGraph data
      collectTerminalsForRoute(
        routeId as SubwayRouteId,
        stopLists,
        stationTerminals
      );
    }

    // As a fallback for routes without patternGraph stops,
    // iterate through the default stops and collect the transfers.
    const stopListsByRouteDefault = mapStopListsByTimeFilterAndRoute.default;
    for (const [routeId, stopLists] of Object.entries(
      stopListsByRouteDefault
    )) {
      // If the route has stops from patternGraph, skip and don't overwrite their transfers.
      if (!stopListsByRouteAtTime[routeId]?.length) {
        const stops = flatten<Stop>(stopLists as StopLists);
        // KEEP for debugging as sometimes this assert fails
        // console.assert(
        //   stops.length === uniq(stops).length,
        //   'should be no duplicates in flattened stops - stopListsByRouteDefault - route ' +
        //     routeId
        // );
        collectTransfersForRoute(
          routeId as SubwayRouteId,
          stops,
          stationTransfers,
          stationTransfersAndPassthroughs
        );
        // Calculate the terminals for each route from default stops data
        collectTerminalsForRoute(
          routeId as SubwayRouteId,
          stopLists as StopLists,
          stationTerminals
        );
      }
    }

    return stations.map(
      (station): Station => {
        const { stopId } = station;
        const transfers = processAndSortTransfers(
          stationTransfers[stopId] || []
        );

        // Transfers generated in this function tend to be in alphabetical order,
        // but we want to sort them by the order in the stations data,
        // which are carefully chosen for optimal layout of the lines.
        const transfersAndPassthroughs = [
          ...(stationTransfersAndPassthroughs[stopId] || []),
        ];
        transfersAndPassthroughs.sort(
          (routeIdA, routeIdB) =>
            station.Daytime_Routes_Array.indexOf(routeIdA) -
            station.Daytime_Routes_Array.indexOf(routeIdB)
        );

        return {
          ...station,
          transfers,
          transfersAndPassthroughs,
          lineIcons: stationTerminals[stopId],
        };
      }
    );
  }
);
