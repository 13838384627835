import { FeatureLike } from 'ol/Feature';
import IconOrigin from 'ol/style/IconOrigin';
import round from 'lodash/round';

import {
  isAlignedBelow,
  isHorizontalOffsetExclusion,
  isLastSkipped45Angle,
  isLeftAligned,
  isSharpRightDescending,
  isSharpRightDescendingExclusion,
  isStandardRightDescendingExclusion,
  isStandardRightDescending_45Excluded,
  isLeftAlignedExclusion,
  isTopLeftAligned,
  isBottomLeftAligned,
  isLeftAlignedWithNegativeXAxisModifier,
} from '../../../subway-data/station-alignment';
import { SkippedEdgeStation, Station } from '../../../subway-data/subway-types';

export const getLabelAlignmentProps = ({
  feature,
  fontLineHeight,
  iconScale,
  iconWidth,
  useIcons,
}: {
  feature: FeatureLike;
  fontLineHeight: number;
  iconScale: number;
  iconWidth: number;
  useIcons: boolean;
}) => {
  const station = feature.get('station') as Station;
  const stopId = station.stopId;
  const titleLinesCount = feature.get('titleLinesCount') as number;
  const skippedEdgeStation = feature.get('skippedEdgeStation') as
    | SkippedEdgeStation
    | undefined;
  const isHorizontalStation = feature.get('isHorizontalStation') as boolean;
  const isVerticalStation = feature.get('isVerticalStation') as boolean;
  const isNegativeHorizontalStation = feature.get(
    'isNegativeHorizontalStation'
  ) as boolean;
  const normalAngle = feature.get('normalAngle') as number;

  let textOffsetY = fontLineHeight / -2;

  let iconY = round(
    (textOffsetY - fontLineHeight * (titleLinesCount - 1) - 2) / iconScale,
    2
  );

  if (
    isHorizontalStation ||
    isHorizontalOffsetExclusion[stopId] ||
    isTopLeftAligned[stopId]
  ) {
    const textPlusIconHeight =
      fontLineHeight * titleLinesCount +
      (useIcons ? iconWidth * iconScale : 0) -
      (isNegativeHorizontalStation && useIcons ? 10 : 5);
    textOffsetY = textPlusIconHeight * -1.5;

    if (isAlignedBelow[stopId]) {
      textOffsetY = fontLineHeight / 3;
    }
    iconY = round(
      (textOffsetY + fontLineHeight * titleLinesCount + 2) / -iconScale,
      2
    );
  }

  let anchorOrigin = IconOrigin.TOP_LEFT; //default
  let textAlign = 'left'; //default

  if (
    !isLeftAlignedExclusion[stopId] &&
    ((isVerticalStation && skippedEdgeStation === SkippedEdgeStation.last) ||
      isLastSkipped45Angle(normalAngle, skippedEdgeStation) ||
      (isSharpRightDescending(normalAngle) &&
        !isSharpRightDescendingExclusion[stopId]) ||
      (isStandardRightDescending_45Excluded(normalAngle) &&
        skippedEdgeStation === SkippedEdgeStation.none &&
        !isStandardRightDescendingExclusion[stopId]) ||
      isTopLeftAligned[stopId] ||
      isBottomLeftAligned[stopId] ||
      isLeftAligned[stopId] ||
      isLeftAlignedWithNegativeXAxisModifier[stopId])
  ) {
    textAlign = 'right';
    anchorOrigin = IconOrigin.TOP_RIGHT;
  }

  return { anchorOrigin, iconY, textAlign, textOffsetY };
};
