import React, { useEffect, useCallback, useState, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { connect } from 'react-redux';
import MenuTimeToggle, { MenuTimeToggleContainer } from './MenuTimeToggle';
import { AppDispatch, AppState } from '../models';
import {
  RoutesUnavailable,
  RoutesWithSituation,
  orderedSubwayRouteIds,
  TimeFilter,
} from '../subway-data';
import { SubwayRouteId, RouteStatus } from '../subway-data/subway-types';
import SubwayRoutesMenu from '../components/SubwayRoutesMenu';
import {
  landscapePhone,
  tablet,
  desktopExtraLarge,
  desktopLarge,
} from '../utils/theme';
import { isPhone } from '../utils/deviceDetector.utils';
import { MENU_TRANSITION_DELAY_IN } from '../utils/animations';
import {
  getUIAirportViewOpened,
  getUIRouteMenuOpened,
  getUIStationViewOpened,
} from '../selectors/ui';
import {
  getMapRoutesWithSituation,
  getMapSelectedRouteId,
  getMapCurrentTime,
  getMapTimeFilter,
} from '../selectors/map/basic';
import { getMapRoutesUnavailable } from '../selectors/map/getMapRoutesUnavailable';
import ServiceStatus from '../components/ServiceStatusContent';
import Icon, { IconTypes } from '../components/Icon';
import SubwayRouteButton from '../components/SubwayRoutesMenu/SubwayRouteButton';
import { title, betaLabel } from '../components/Text';
import { getMapRouteStatus } from '../selectors/map/getMapRouteStatus';
import Aside from '../components/Aside';
import EmergencyAlertContent from '../components/EmergencyAlertContent';
import { EmergencyAlert } from '../subway-data/otp/emergency-alerts-types';
import { mapTitle } from '../components/ui-constants';

interface SubwayRoutesMenuWrapperProps {
  isOpen: boolean;
  shortcutsMenuOpen: boolean;
  translate: boolean;
  selectedRouteId: SubwayRouteId | '';
}
const SubwayRoutesMenuWrapper = styled.div<SubwayRoutesMenuWrapperProps>`
  overflow-y: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: ${p => p.theme.zIndex.routeMenu};
  pointer-events: ${p => (p.isOpen ? 'all' : 'none')};
  display: flex;
  flex-direction: column;
  border-radius: 6px 6px 0 0;
  overflow-x: hidden;
  box-shadow: ${(p: any) => (p.isOpen ? p.theme.boxShadow : 'none')};
  transition: box-shadow 0.4s ease-in-out, transform 0.4s ease-out,
    opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-duration: ${(p: any) => (p.withTransitions ? '0.2s' : '0.4s')};
  transition-delay: ${(p: any) =>
    p.isOpen ? MENU_TRANSITION_DELAY_IN + 100 : 0}ms;

  ${landscapePhone(css`
    background: ${p => p.theme.colors.white};
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0ms;
    transition-delay: ${MENU_TRANSITION_DELAY_IN}ms;
    width: 100%;

    ${(p: any) =>
      p.isOpen &&
      css`
        opacity: 1;
        transition-delay: 0;
      `};
  `)};

  ${tablet(css`
    background-color: ${p => p.theme.colors.white};
    border-radius: 6px;
    bottom: initial;
    box-shadow: ${p => p.theme.boxShadow};
    top: 0;
    margin: ${p => p.theme.margin} 0;
    margin: ${p => `calc(env(safe-area-inset-top) + ${p.theme.margin})`} 0;
    left: ${p => p.theme.margin};
    left: ${p => `calc(env(safe-area-inset-left) + ${p.theme.margin})`};
    opacity: ${(p: any) => (p.shortcutsMenuOpen || !p.isOpen ? 0 : 1)};
    padding: ${p => (!!p.selectedRouteId ? '0 25px 25px' : '25px')};
    position: relative;
    right: inherit;
    transform: ${(p: SubwayRoutesMenuWrapperProps) =>
      `translateY(${p.translate ? '60px' : 0})`};
    width: ${p => p.theme.sizes.panelDesktopLarge};
    z-index: ${p => (p.isOpen ? p.theme.zIndex.routeMenu + 1 : 0)};
  `)};

  ${desktopExtraLarge(css`
    padding: ${(p: any) => (!!p.selectedRouteId ? '0 30px 30px' : '30px')};
    width: ${p => p.theme.sizes.panelDesktopExtraLarge};
  `)};
`;

const BackButton = styled.button<{ active: boolean }>`
  align-items: flex-start;
  border: 0;
  background: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  height: 25px;
  position: absolute;
  pointer-events: ${(p: { active: boolean }) => (p.active ? 'all' : 'none')};
  transform: ${(p: { active: boolean }) =>
    p.active ? `translateX(0)` : `translateX(-55px)`};
  transition: transform 0.4s ease;
  width: 26px;
  z-index: 2;

  svg {
    display: block;
    height: 50%;
    margin-left: -2px;
    transform: rotate(-90deg);
    width: 50%;
  }
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  transform: translateX(0);
  transition: transform 0.4s ease;
  width: 28px;

  svg {
    height: inherit;
    display: block;
    width: inherit;
  }
`;

const Title = styled.div`
  align-items: flex-end;
  display: flex;
  margin-top: 16px;
  margin-bottom: 24px;
  transform: translateX(0);
  transition: transform 0.4s ease;

  h1 {
    ${title};
    line-height: 1;
    letter-spacing: -0.1rem;
  }

  span {
    ${betaLabel};
    background: ${p => p.theme.colors.blue};
    border-radius: 6px;
    margin-left: 4px;
    margin-bottom: 2px;
    padding: 1px 4px;
  }

  ${desktopExtraLarge(css`
    h1 {
      letter-spacing: -0.05rem;
    }

    span {
      margin-bottom: 4px;
    }
  `)};
`;

// This container's width calculations are needed so it
// doesn't shrink when a scrollbar is visible, they're
// always the width minus left and right padding values
const Container = styled.div`
  height: 100%;

  ${desktopLarge(css`
    width: calc(240px - 25px * 2);
  `)};

  ${desktopExtraLarge(css`
    width: calc(300px - 30px * 2);
  `)};
`;

type HeadingContainerProps = Pick<
  SubwayRoutesMenuWrapperProps,
  'selectedRouteId'
>;
const HeadingContainer = styled.div<HeadingContainerProps>`
  ${tablet(css`
    background: ${p => p.theme.colors.white};
    position: sticky;
    top: 0px;
    z-index: 10;
    padding: ${(p: HeadingContainerProps) =>
      !!p.selectedRouteId ? '25px 0 20px' : '0'};

    .spacer {
      display: block;
      height: 22px;
      width: 100%;
    }
  `)};

  ${desktopExtraLarge(css`
    padding: ${(p: HeadingContainerProps) =>
      !!p.selectedRouteId ? '30px 0 20px' : '0'};
  `)};
`;

const Heading = styled.div<{ isOpen: boolean }>`
  display: none;

  ${tablet(css`
    display: block;
    max-height: ${(p: any) => (p.isOpen ? 175 : 0)}px;
    opacity: ${(p: any) => (p.isOpen ? 1 : 0)};
    pointer-events: ${(p: any) => (p.isOpen ? 'all' : 'none')};
    transition: max-height 0.4s ease, opacity 0.4s ease;
    overflow: hidden;

    .spacer {
      display: block;
      height: 20px;
      width: 100%;
    }

    ${(p: any) =>
      !p.isOpen &&
      css`
        ${LogoWrapper},
        ${Title} {
          transform: translateX(55px);
        }
      `};
  `)};
`;

const COLUMNS = 6;
const GRID_SPACING = {
  default: 9,
  extraLarge: 12,
};
const BTN_SIZE = {
  default: 24,
  extraLarge: 30,
};
const BOX_SIZE = {
  default: GRID_SPACING.default + BTN_SIZE.default,
  extraLarge: GRID_SPACING.extraLarge + BTN_SIZE.extraLarge,
};

const Routes = styled.div<{ isOpen: boolean }>`
  display: none;

  ${tablet(css`
    column-gap: ${GRID_SPACING.default}px;
    display: grid;
    grid-template-columns: repeat(${COLUMNS}, auto);
    justify-content: space-around;
    max-height: ${(p: any) => BOX_SIZE.default * (p.isOpen ? 5 : 2)}px;
    position: relative;
    pointer-events: ${(p: any) => (p.isOpen ? 'all' : 'none')};
    transition: max-height 0.4s ease;
    row-gap: ${GRID_SPACING.default}px;
    width: 100%;
  `)};

  ${desktopExtraLarge(css`
    column-gap: ${GRID_SPACING.extraLarge}px;
    max-height: ${(p: any) => BOX_SIZE.extraLarge * (p.isOpen ? 5 : 2)}px;
    row-gap: ${GRID_SPACING.extraLarge}px;
  `)};
`;

const getRouteButtonTransform = (
  active: boolean,
  index: number,
  isOpen: boolean,
  size: 'large' | 'extraLarge'
) => {
  if (isOpen) {
    return `scale(1) translateX(0) translateY(0)`;
  }

  const row = Math.floor(index / 6);
  const column = 3 - (index - row * 6);
  const boxSize = size === 'large' ? BOX_SIZE.default : BOX_SIZE.extraLarge;
  const btnSize = size === 'large' ? BTN_SIZE.default : BTN_SIZE.extraLarge;
  const scale = (boxSize * 2) / btnSize;
  const x = column * boxSize - boxSize / 2;
  const y = row * boxSize;

  return `scale(${active ? scale : 1}) translateX(${
    active ? x / scale : x
  }px) translateY(-${active ? y / scale : y}px)`;
};

interface RouteButtonWrapperProps {
  active: boolean;
  index: number;
  isOpen: boolean;
}
const RouteButtonWrapper = styled.div<RouteButtonWrapperProps>`
  transform: ${p =>
    getRouteButtonTransform(p.active, p.index, p.isOpen, 'large')};
  transition: transform 0.4s ease, z-index 0.1s ease;
  transform-origin: top;
  z-index: ${p => (p.active ? 1 : 0)};

  ${desktopExtraLarge(css`
    transform: ${(p: RouteButtonWrapperProps) =>
      getRouteButtonTransform(p.active, p.index, p.isOpen, 'extraLarge')};
  `)};
`;

const Content = styled.div<{ isOpen: boolean }>`
  display: none;

  ${tablet(css`
    display: block;
    max-height: ${(p: any) => (p.isOpen ? 2000 : 0)}px;
    opacity: ${(p: any) => (p.isOpen ? 1 : 0)};
    pointer-events: ${(p: any) => (p.isOpen ? 'all' : 'none')};
    transition: max-height 0.2s ease, opacity 0.5s ease;
    transition-delay: 0s, ${(p: any) => (p.isOpen ? 0.4 : 0)}s;
    width: 100%;

    ${MenuTimeToggleContainer} {
      padding-bottom: 16px;
    }
  `)};
`;

interface SubwayRoutesMenuContainerProps {
  airportTerminalViewOpened: boolean;
  currentTime: TimeFilter;
  emergencyAlert: EmergencyAlert;
  routesUnavailable: RoutesUnavailable;
  routesWithSituation: RoutesWithSituation;
  routeMenuOpened: boolean;
  selectedRouteId: SubwayRouteId | '';
  shortcutsMenuOpen: boolean;
  stationViewOpened: boolean;
  selectedRouteStatus: RouteStatus;
  setRouteMenuOpened: (value: boolean) => void;
  setSelectedRouteId: (routeId: SubwayRouteId | '') => void;
  setVaccineLocationsVisible: (value: boolean) => void;
  timeFilter: TimeFilter;
  translate: boolean;
  vaccinationViewOpened: boolean;
}
const SubwayRoutesMenuContainer: React.FC<SubwayRoutesMenuContainerProps> = ({
  airportTerminalViewOpened,
  currentTime,
  emergencyAlert,
  routeMenuOpened,
  routesUnavailable,
  routesWithSituation,
  selectedRouteId,
  setRouteMenuOpened,
  shortcutsMenuOpen,
  setSelectedRouteId,
  setVaccineLocationsVisible,
  selectedRouteStatus,
  stationViewOpened,
  timeFilter,
  translate,
  vaccinationViewOpened,
}) => {
  const routeIdTimeout = useRef<number | undefined>();
  const [internalRouteId, setInternalRouteId] = useState<SubwayRouteId | ''>(
    selectedRouteId
  );

  const setSelectedRouteWithDelay = useCallback(
    (selectedRouteId: SubwayRouteId | '') => {
      setInternalRouteId(selectedRouteId);

      clearTimeout(routeIdTimeout.current);

      routeIdTimeout.current = setTimeout(() => {
        setSelectedRouteId(selectedRouteId);
        setRouteMenuOpened(!selectedRouteId);
      }, 500);
    },

    [setRouteMenuOpened, setSelectedRouteId]
  );

  useEffect(() => {
    setInternalRouteId(selectedRouteId);
  }, [selectedRouteId]);

  const onMenuOpen = useCallback(() => {
    if (isPhone()) {
      internalRouteId && setInternalRouteId('');
    }
  }, [internalRouteId, setInternalRouteId]);

  const openOnDesktop =
    !airportTerminalViewOpened && !stationViewOpened && !vaccinationViewOpened;
  return (
    <>
      <SubwayRoutesMenuWrapper
        isOpen={isPhone() ? routeMenuOpened : openOnDesktop}
        translate={translate}
        selectedRouteId={internalRouteId}
        shortcutsMenuOpen={shortcutsMenuOpen}
      >
        {isPhone() ? (
          <SubwayRoutesMenu
            routesUnavailable={routesUnavailable}
            routesWithSituation={routesWithSituation}
            selectedRouteId={internalRouteId}
            onOpen={onMenuOpen}
            onSelectRoute={setSelectedRouteWithDelay}
            onRequestClose={() => {
              setRouteMenuOpened(false);
            }}
            isOpen={routeMenuOpened}
            currentTime={currentTime}
            timeFilter={timeFilter}
            extraPaddingBottom
          />
        ) : (
          <Container>
            <HeadingContainer selectedRouteId={internalRouteId}>
              <BackButton
                active={!!internalRouteId}
                aria-hidden={!!internalRouteId}
                tabIndex={internalRouteId ? 0 : -1}
                onClick={() => setSelectedRouteWithDelay('')}
              >
                <Icon type={IconTypes.BoxlessChevron} />
              </BackButton>
              <Heading isOpen={!internalRouteId}>
                <LogoWrapper aria-label="MTA">
                  <Icon type={IconTypes.MTA} />
                </LogoWrapper>
                <Title>
                  <h1>{mapTitle}</h1>
                  <span>beta</span>
                </Title>
                <MenuTimeToggle />
                <span className="spacer" />
              </Heading>
              <Routes isOpen={!internalRouteId}>
                {orderedSubwayRouteIds.map((routeId, index) => {
                  const deemphasized = !!routesUnavailable[routeId];
                  const hasSituation = !!routesWithSituation[routeId];
                  const selected = routeId === selectedRouteId;

                  return (
                    <RouteButtonWrapper
                      active={internalRouteId === routeId}
                      key={`route-item-${index}`}
                      isOpen={!internalRouteId}
                      index={index}
                    >
                      <SubwayRouteButton
                        isOpen
                        onClick={e => {
                          e.stopPropagation();
                          setSelectedRouteWithDelay(routeId);
                        }}
                        {...{
                          routeId,
                          selected,
                          deemphasized,
                          hasSituation,
                        }}
                      />
                    </RouteButtonWrapper>
                  );
                })}
              </Routes>
              {!!internalRouteId && (
                <>
                  <span className="spacer" />
                  <MenuTimeToggle />
                </>
              )}
            </HeadingContainer>
            <Content isOpen={!!internalRouteId}>
              <ServiceStatus
                isOpen={true}
                isRouteUnavailable={!!routesUnavailable[selectedRouteId]}
                routeId={internalRouteId}
                status={selectedRouteStatus}
              />
            </Content>
          </Container>
        )}
      </SubwayRoutesMenuWrapper>
      {!isPhone() && (
        <Aside
          isOpen={!selectedRouteId && openOnDesktop}
          isEmergencyAlert
          translate={translate}
          shortcutsMenuOpen={shortcutsMenuOpen}
        >
          <EmergencyAlertContent
            emergencyAlertContent={emergencyAlert}
            containerType="aside"
            setVaccineLocationsVisible={setVaccineLocationsVisible}
          />
        </Aside>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  airportTerminalViewOpened: getUIAirportViewOpened(state),
  currentTime: getMapCurrentTime(state),
  emergencyAlert: state.map.emergencyAlert,
  routeMenuOpened: getUIRouteMenuOpened(state),
  routesUnavailable: getMapRoutesUnavailable(state),
  routesWithSituation: getMapRoutesWithSituation(state),
  selectedRouteId: getMapSelectedRouteId(state),
  selectedRouteStatus: getMapRouteStatus(state),
  stationViewOpened: getUIStationViewOpened(state),
  timeFilter: getMapTimeFilter(state),
  vaccinationViewOpened: state.ui.vaccinationViewOpened,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setRouteMenuOpened: dispatch.ui.setRouteMenuOpened,
  setSelectedRouteId: dispatch.map.setSelectedRouteId,
  setVaccineLocationsVisible: dispatch.map.setVaccineLocationsVisible,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubwayRoutesMenuContainer);
