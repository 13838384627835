import React, { RefObject, useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { formatReadableOutage } from '../../utils/elevatorEscalatorStatus';
import { desktopExtraLarge, desktopLarge, tablet } from '../../utils/theme';
import {
  FilteredOutage,
  OutageListing,
} from '../../subway-data/elevator-escalator-status-types';
import AnimateHeight from 'react-animate-height';
import { isPhone } from '../../utils/deviceDetector.utils';
import { subTitle } from '../Text';

const OutagesContainer = styled.div`
  margin-bottom: 44px;

  ${tablet(css`
    max-width: 310px;
  `)};

  ${desktopLarge(css`
    margin-bottom: 27px;
    max-width: 185px;
  `)};

  ${desktopExtraLarge(css`
    margin-bottom: 40px;
    max-width: 223px;
  `)};
`;

const OutageCopy = styled.p<{
  isVisible: boolean;
}>`
  ${subTitle};
  transition: opacity 500ms ease-out;
  margin-bottom: 20px;
`;

const OutageHeader = styled(OutageCopy)`
  font-weight: bold;
`;

const OutageLink = styled.button<{
  negativeTop?: boolean;
}>`
  ${subTitle};
  color: ${p => p.theme.colors.blue};
  cursor: pointer;
  display: flex;
  margin-top: ${p => (p.negativeTop ? '-18px' : '0')};

  &:hover {
    text-decoration: underline;
  }
`;

const MoreDetailsLink = styled(OutageLink)<{
  onClick: () => void;
}>`
  font-weight: normal;
  letter-spacing: -0.4px;
  text-align: left;
`;

const getOutageHeaderCopy = (
  equipmentOutageListings: OutageListing[],
  equipmentTotal: number
): string => {
  const outageEquipmentBreakdown = {
    EL: 0,
    ES: 0,
  };

  equipmentOutageListings.forEach(listing => {
    const { equipmenttype } = listing;
    outageEquipmentBreakdown[equipmenttype]++;
  });
  const hasBothOutageTypes =
    outageEquipmentBreakdown.EL > 0 && outageEquipmentBreakdown.ES > 0;
  const hasOnlyElevatorOutages =
    outageEquipmentBreakdown.ES === 0 && outageEquipmentBreakdown.EL > 0;
  const hasOnlyEscalatorOutages =
    outageEquipmentBreakdown.EL === 0 && outageEquipmentBreakdown.ES > 0;

  const FALLBACK_MESSAGE =
    'Elevator and escalator access may be interrupted at this station.';

  if (!equipmentTotal) {
    return FALLBACK_MESSAGE;
  }
  if (hasBothOutageTypes) {
    const equipmentInServiceTotal =
      equipmentTotal -
      (outageEquipmentBreakdown.EL + outageEquipmentBreakdown.ES);
    // TODO: fallback state for when equipmentInServiceTotal = 0 - copy pending
    return `Elevator and escalator access interrupted at this station: ${equipmentInServiceTotal} out of ${equipmentTotal} pieces of equipment in good service.`;
  } else if (hasOnlyElevatorOutages) {
    return `Elevator access may be interrupted at this station: ${equipmentTotal -
      outageEquipmentBreakdown.EL} out of ${equipmentTotal} elevators in good service.`;
  } else if (hasOnlyEscalatorOutages) {
    return `Escalator access may be interrupted at this station: ${equipmentTotal -
      outageEquipmentBreakdown.ES} out of ${equipmentTotal} escalators in good service.`;
  }
  return FALLBACK_MESSAGE;
};

const getFilteredOutageListings = (
  equipmentOutageListings: OutageListing[]
): FilteredOutage[] => {
  const outageListingTypes = {};
  equipmentOutageListings.forEach(outageListing => {
    const { serving, equipmenttype } = outageListing;
    if (!outageListingTypes[`${equipmenttype}-${serving}`]) {
      outageListingTypes[`${equipmenttype}-${serving}`] = {
        listing: outageListing,
        count: 1,
      };
    } else {
      outageListingTypes[`${equipmenttype}-${serving}`].count++;
    }
  });
  return Object.values(outageListingTypes);
};

const ELEVATOR_ESCALATOR_DETAILS_LINK =
  'https://new.mta.info/elevator-escalator-status';
const onClickMoreDetails = () => {
  window.open(ELEVATOR_ESCALATOR_DETAILS_LINK);
};

export const ElevatorEscalatorStatus: React.FC<{
  equipmentOutageListings: OutageListing[];
  equipmentTotal: number;
  scrollRef: RefObject<HTMLDivElement>;
  mobileScrollRef?: RefObject<HTMLDivElement>;
}> = ({
  equipmentOutageListings,
  equipmentTotal,
  scrollRef,
  mobileScrollRef,
}) => {
  const DEFAULT_ANIMATION_DURATION = 500;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const outageListingsRef = useRef(equipmentOutageListings);
  const [animationDuration, setAnimationDuration] = useState<number>(
    DEFAULT_ANIMATION_DURATION
  );

  useEffect(() => {
    if (outageListingsRef.current !== equipmentOutageListings) {
      outageListingsRef.current = equipmentOutageListings;
      setAnimationDuration(0);
      setIsExpanded(false);
      setTimeout(() => {
        setAnimationDuration(DEFAULT_ANIMATION_DURATION);
      }, 200);
    }
  }, [equipmentOutageListings, isExpanded, outageListingsRef, setIsExpanded]);

  if (!isExpanded) {
    if (isPhone()) {
      mobileScrollRef?.current?.scrollTo({
        top: 20, // ensures scroll tab isn't cropped
      });
    } else {
      scrollRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  const filteredOutageListings = getFilteredOutageListings(
    equipmentOutageListings
  );
  return (
    <OutagesContainer>
      <OutageHeader isVisible={equipmentOutageListings.length > 0}>
        {getOutageHeaderCopy(equipmentOutageListings, equipmentTotal)}
      </OutageHeader>
      <AnimateHeight
        duration={animationDuration}
        height={isExpanded ? 'auto' : 0}
        easing="ease-in-out"
        animateOpacity
      >
        {filteredOutageListings.map(outage => {
          const { listing } = outage;
          return (
            <OutageCopy isVisible={isExpanded} key={listing.equipment}>
              {formatReadableOutage(outage)}
            </OutageCopy>
          );
        })}
        <OutageCopy isVisible={isExpanded} key="more-details">
          For more details:
          <MoreDetailsLink onClick={() => onClickMoreDetails()}>
            {ELEVATOR_ESCALATOR_DETAILS_LINK}
          </MoreDetailsLink>
        </OutageCopy>
      </AnimateHeight>
      {equipmentOutageListings.length > 0 && (
        <OutageLink
          negativeTop={!isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Show less' : 'Read more'}
        </OutageLink>
      )}
    </OutagesContainer>
  );
};
