import React from 'react';
import styled, { css } from 'styled-components/macro';
import { TRANSITION_200 } from '../../utils/animations';

interface TrayBarProps {
  isFullPosition: boolean;
}

const TrayBarContainer = styled.div`
  background: ${p => p.theme.colors.white};
  display: flex;
  justify-content: center;
  padding-left: ${p =>
    `calc(env(safe-area-inset-left, 20px) + ${p.theme.margin})`};
  padding-right: ${p =>
    `calc(env(safe-area-inset-right, 20px) + ${p.theme.margin})`};
  width: 100%;
`;

const TrayBarInner = styled.div<TrayBarProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 30px;
  left: 0;
  padding: 4px;
  position: relative;
  transition: left ${TRANSITION_200}ms linear,
    transform ${TRANSITION_200}ms linear;
  transform: translateX(0%);
  width: 30px;

  &:after,
  &:before {
    background: black;
    border-radius: 3px;
    content: '';
    display: block;
    height: 2px;
    opacity: 0.2;
    position: absolute;
    transform: rotate(0deg) scale(1) translate3d(0, 0, 0);
    transition: opacity ${TRANSITION_200}ms linear,
      transform ${TRANSITION_200}ms linear;
    width: 100%;
  }

  ${p =>
    p.isFullPosition &&
    css`
      left: 50%;
      margin-top: 16px;
      transform: translateX(-50%);

      &:after {
        opacity: 1;
        transform: scale(0.75) rotate(45deg) translate3d(0, 0, 0);
      }

      &:before {
        opacity: 1;
        transform: scale(0.75) rotate(-45deg) translate3d(0, 0, 0);
      }
    `};
`;

const TrayBar = ({ isFullPosition }: TrayBarProps) => (
  <TrayBarContainer className="trayBar__container">
    <TrayBarInner isFullPosition={isFullPosition} className="trayBar__close" />
  </TrayBarContainer>
);

export default TrayBar;
