import round from 'lodash/round';
import { Feature, Map } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import { StyleFunction } from 'ol/style/Style';

import { RoutesUnavailable } from '../../subway-data';
import {
  Station,
  SubwayRouteId,
  SubwayRouteIds,
  Stop,
} from '../../subway-data/subway-types';
import theme from '../../utils/theme';

import {
  EMPTY_STYLE,
  HOVERABLE_FEATURE_LABEL_CLASS_NAME,
  RENDER_BUFFER_LABELS,
  SELECTABLE_FEATURE_LABEL_CLASS_NAME,
} from '../maps-constants';
import { getMapTheme } from '../maps-theme';
import { ZoomLevel } from '../map-types';
import { getOlMapZoom, getProportionalValueForZoom } from '../maps-utils';
import { routeIconsSvgData } from '../subway-sign-svg';
import { LayerRenderingProps } from '../subway-openlayers-graphics';
import { getLabelAlignmentProps } from './utils/label-alignment';

// TODO : refactor cache logic & semantically stronger variants
let stationLabelsCacheMultiLine = {}; // Multiline view

const stationLabelSVGStyle = ({
  currentZoom,
  feature,
  isADAFilterActive,
  selectedRouteId,
  useDarkMap,
  useHover,
}: {
  currentZoom: ZoomLevel;
  feature: FeatureLike;
  isADAFilterActive?: boolean;
  selectedRouteId: SubwayRouteId | '';
  useDarkMap: boolean;
  useHover: boolean;
}): Style => {
  const station = feature.get('station') as Station;
  const title = feature.get('title');
  const stopId = station.stopId;
  const useInactiveColor = isADAFilterActive && !station.ada;
  const colorsToUse = getMapTheme(useDarkMap).labels;
  const lineIcons: SubwayRouteIds =
    currentZoom < ZoomLevel.z17
      ? selectedRouteId
        ? station.transfers
        : station.lineIcons || []
      : [];
  const useIcons = !!station.ada || !!lineIcons.length;
  const routesUnavailableForStop = feature.get(
    'routesUnavailableForStop'
  ) as RoutesUnavailable;

  const fontSize = selectedRouteId
    ? round(
        getProportionalValueForZoom(currentZoom, {
          [ZoomLevel.z13]: 18,
          [ZoomLevel.z17]: 18,
          [ZoomLevel.z18]: 30,
        }),
        2
      )
    : round(
        getProportionalValueForZoom(currentZoom, {
          [ZoomLevel.z15]: 11,
          [ZoomLevel.z16]: 13,
          [ZoomLevel.z17]: 18,
          [ZoomLevel.z18]: 30,
        }),
        2
      );
  const fontWeight = station.transfers.length > 1 ? 'bold' : '';
  const fontLineHeight = round(fontSize * 1.2, 2);

  const iconWidth = 16;
  const iconScale = round(
    getProportionalValueForZoom(currentZoom, {
      [ZoomLevel.z16]: 16 / iconWidth,
      [ZoomLevel.z17]: 20 / iconWidth,
      [ZoomLevel.z18]: 30 / iconWidth,
    }),
    2
  );
  const iconX = round(
    getProportionalValueForZoom(currentZoom, {
      [ZoomLevel.z17]: 1,
      [ZoomLevel.z18]: 0.8,
    }),
    2
  );

  const {
    anchorOrigin,
    iconY,
    textAlign,
    textOffsetY,
  } = getLabelAlignmentProps({
    feature,
    fontLineHeight,
    iconScale,
    iconWidth,
    useIcons,
  });

  const cacheId = `${selectedRouteId}-${stopId}-${useIcons}-${
    station.ada
  }-${lineIcons?.map(
    routeId => routesUnavailableForStop[routeId]
  )}-${fontSize}-${iconScale}-${iconX}-${isADAFilterActive}-${useDarkMap}-${useHover}`;

  if (!(cacheId in stationLabelsCacheMultiLine)) {
    // After zoom 17, where the route ids size inside the line is readable,
    // we hide the edge station icons, keeping only ADA icons.
    const { imgSize, src } = routeIconsSvgData({
      iconWidth,
      isADAFilterActive,
      isADAStation: station.ada,
      routeIds: lineIcons,
      routesUnavailable: routesUnavailableForStop,
      useDarkMap,
    });

    stationLabelsCacheMultiLine[cacheId] = new Style({
      image: src
        ? new Icon({
            anchor: [iconX, iconY],
            anchorOrigin: anchorOrigin,
            anchorXUnits: IconAnchorUnits.PIXELS,
            anchorYUnits: IconAnchorUnits.PIXELS,
            // We need imgSize otherwise this always disappears when decluttering is on
            imgSize,
            scale: iconScale,
            src,
          })
        : undefined,
      text: new Text({
        font: `${fontWeight} ${fontSize}px/${fontLineHeight}px ${theme.fonts.default}`,
        offsetY: textOffsetY,
        text: title,
        textBaseline: 'top',
        textAlign,

        // TODO: memoize Fill objects
        fill: new Fill({
          color: useHover
            ? colorsToUse.hover
            : useInactiveColor
            ? colorsToUse.inactive
            : colorsToUse.active,
        }),
        stroke: new Stroke({
          color: colorsToUse.outline,
          width: 2,
        }),
      }),
      zIndex: station.transfers.length,
    });
  }

  return stationLabelsCacheMultiLine[cacheId];
};

const getStationLabelStyle = ({
  routeId,
  isADAFilterActive = false,
  useDarkMap,
  useHover = false,
}: LayerRenderingProps & {
  useDarkMap: boolean;
  useHover?: boolean;
}): StyleFunction => (feature, resolution) => {
  let currentZoom = getOlMapZoom(resolution);

  if (
    (routeId && currentZoom >= ZoomLevel.z13) ||
    currentZoom >= ZoomLevel.z14
  ) {
    return stationLabelSVGStyle({
      currentZoom,
      feature,
      isADAFilterActive,
      selectedRouteId: routeId,
      useDarkMap,
      useHover,
    });
  }

  return EMPTY_STYLE;
};

let subwayStationLabelsLayer: VectorLayer;
interface SubwayStationLabelsLayerFnArgs extends LayerRenderingProps {
  allFeaturesForAllRoutesUnified: Feature[];
  map: Map;
  soloStationsFeatures: Feature[];
  useDarkMap: boolean;
}
export const addSubwayStationLabelsLayer = ({
  allFeaturesForAllRoutesUnified,
  isADAFilterActive,
  map,
  routeId,
  soloStationsFeatures,
  useDarkMap,
}: SubwayStationLabelsLayerFnArgs) => {
  const features = !routeId
    ? allFeaturesForAllRoutesUnified.filter(feature => {
        const station = feature.get('station') as Station;
        return !!station.transfers.length;
      })
    : soloStationsFeatures;
  const style = getStationLabelStyle({
    isADAFilterActive,
    routeId,
    useDarkMap,
  });

  if (subwayStationLabelsLayer) {
    subwayStationLabelsLayer.setSource(
      new VectorSource({
        features,
      })
    );
    subwayStationLabelsLayer.setStyle(style);
  } else {
    subwayStationLabelsLayer = new VectorLayer({
      className: `${SELECTABLE_FEATURE_LABEL_CLASS_NAME} ${HOVERABLE_FEATURE_LABEL_CLASS_NAME}`,
      declutter: true,
      source: new VectorSource({
        features,
      }),
      style,
      renderBuffer: RENDER_BUFFER_LABELS,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(subwayStationLabelsLayer);
  }
};

let currentLabelOrDotHoveredFeature: Feature | undefined;
let currentLabelHoveredFeatureWithStyle: Feature | undefined;
export const setSubwayStationLabelFeatureHoverStyle = ({
  routeId,
  isADAFilterActive = false,
  stationLabelOrDotFeature,
  useDarkMap,
}: LayerRenderingProps & {
  stationLabelOrDotFeature?: Feature;
  useDarkMap: boolean;
}) => {
  // if it has station we have the hover on the label
  const station = stationLabelOrDotFeature?.get('station') as Station;
  // if it has stop we have the hover on the dot
  const stop = stationLabelOrDotFeature?.get('stop') as Stop;

  // If the new feature is not an station label/dot or is different
  // from the previous label, we clean the last one.
  if (
    (!station && !stop) ||
    stationLabelOrDotFeature !== currentLabelOrDotHoveredFeature
  ) {
    currentLabelHoveredFeatureWithStyle?.setStyle(undefined);
    currentLabelHoveredFeatureWithStyle = undefined;
    currentLabelOrDotHoveredFeature = undefined;
  }

  if ((station || stop) && !currentLabelOrDotHoveredFeature) {
    currentLabelOrDotHoveredFeature = stationLabelOrDotFeature;
    currentLabelHoveredFeatureWithStyle = stationLabelOrDotFeature;

    if (stop) {
      currentLabelHoveredFeatureWithStyle = subwayStationLabelsLayer
        .getSource()
        .getFeatures()
        .find(f => f.get('stopId') === stop.stopId);
    }

    currentLabelHoveredFeatureWithStyle?.setStyle(
      getStationLabelStyle({
        isADAFilterActive,
        routeId,
        useDarkMap,
        useHover: true,
      })
    );
  }
};
