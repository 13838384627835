import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components/macro';
import Icon, { IconTypes, IconContainer } from '../Icon';
import {
  notTouch,
  withFocusStyle,
  withTouchPressStyle,
  touch,
  tablet,
  desktopLarge,
  desktopExtraLarge,
} from '../../utils/theme';
import { isPhone } from '../../utils/deviceDetector.utils';

interface ServiceStatusProps {
  isEmergencyAlert?: boolean;
  isClosed: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const ServiceStatusContainer = styled.button<{
  isClosed: boolean;
  isEmergencyAlert: boolean;
}>`
  width: 50px;
  height: 50px;
  color: ${p => p.theme.colors.button.color};
  background-color: ${p => p.theme.colors.button.bg};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${p => p.theme.boxShadow};
  cursor: pointer;
  outline: none;

  ${p => withFocusStyle(p.theme)}
  ${withTouchPressStyle()}

  transition: ${p => p.theme.transition};

  path, circle {
    transition: ${p => p.theme.transition};
  }

  ${touch(css`
    ${tablet(css`
      svg {
        transform: scale(0.8);
      }
    `)}
  `)}

  ${notTouch(css`
    transition-property: opacity;
    opacity: ${(p: any) => (p.isEmergencyAlert && p.isClosed ? 0 : 1)};

    &:hover {
      path {
        fill: ${(p: any) => (p.isClosed ? 'auto' : p.theme.colors.white)};
      }

      circle {
        fill: ${(p: any) => (p.isClosed ? 'auto' : p.theme.colors.black)};
      }
    }

    svg {
      transform: scale(0.8);
    }
  `)}

  ${tablet(css`
    width: 40px;
    height: 40px;
  `)};

  ${desktopLarge(css`
    width: 40px;
    height: 40px;
  `)};

  ${desktopExtraLarge(css`
    width: 50px;
    height: 50px;

    svg {
      transform: scale(1);
    }
  `)};
`;

const ServiceStatus: React.FC<ServiceStatusProps> = ({
  onClick,
  isClosed,
  isEmergencyAlert,
  ...props
}) => {
  let dataCy = 'service-status-button';
  let ariaLabelOpened = 'Open the service status';
  let ariaLabelClosed = 'Close the service status';

  if (isEmergencyAlert) {
    dataCy = 'emergency-alert-button';
    ariaLabelOpened = 'Open the emergency alert';
    ariaLabelClosed = 'Close the emergency alerts';
  }

  return (
    <ServiceStatusContainer
      onClick={onClick}
      isClosed={isClosed}
      data-cy={dataCy}
      aria-label={isClosed ? ariaLabelClosed : ariaLabelOpened}
      isEmergencyAlert={!!isEmergencyAlert}
      {...props}
    >
      <IconContainer>
        {isClosed && !isPhone() ? (
          <Icon type={IconTypes.Chevron} />
        ) : (
          <Icon type={IconTypes.Alert} />
        )}
      </IconContainer>
    </ServiceStatusContainer>
  );
};

export default ServiceStatus;
