import React from 'react';
import { RouteStatus, RouteStatusMeta } from '../subway-data/subway-types';
import { renderToString } from 'react-dom/server';
import { renderRouteIcon } from '../components/renderRouteIcon';
import { subwayRouteIdFromSubwayRouteLabel } from '../subway-data';
import { formatDistanceToNow } from 'date-fns';
import Icon, { IconTypes, IconContainerRounded } from '../components/Icon';
import { overridableNow } from './date.utils';

export const EN_DASH = '–';

export const TIMESPAN_REGEX = /<b>([A-Z&,\-() ]+)<br clear=left>(.+?)<br clear=left>/;
export const CATEGORY_REGEX = /<BODY>(<[a-z#=0-9 ]+>)?<b>([&A-Za-z0-9- ]+)(<br clear=left>)*/;

/**
 * Some service statuses lack a definite end date ("until further notice").
 * However, the current SOAP API forces the end date field to be filled.
 * So this "distant future" value is used to represent that indefinite end date.
 *
 * @todo Revisit this in 2021.
 */
export const STATUS_UNTIL_FURTHER_NOTICE_DATE = '12/31/2021 11:59 PM';

export const containsTables = (routeStatus: RouteStatus) => {
  return (routeStatus.statusDetails || []).some(({ statusDescription }) =>
    statusDescription.toLowerCase().includes('</table>')
  );
};

export const extractStatusCategory = (statusDescription: string): string => {
  const [, , category] = CATEGORY_REGEX.exec(statusDescription) || ['', '', ''];

  return category.trim();
};

export const extractStatusTimespan = (
  description: string,
  startingDate: string,
  endingDate?: string,
  /** Used for simulating different dates in unit tests */
  nowDate = overridableNow()
): string => {
  const startDate = new Date(startingDate);
  const startDateText = startDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
  const startDayOfWeek = startDate.toLocaleDateString('en-US', {
    weekday: 'short',
  });

  const endDate = endingDate ? new Date(endingDate) : undefined;
  const endDateText = !endDate
    ? ''
    : endDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      });
  const endDayOfWeek = !endDate
    ? ''
    : endDate.toLocaleDateString('en-US', {
        weekday: 'short',
      });

  let dateRangeText = startDateText;
  if (endDateText && endDateText !== startDateText) {
    dateRangeText += EN_DASH + endDateText;
  }
  dateRangeText += ', ' + startDayOfWeek;
  if (endDayOfWeek && endDayOfWeek !== startDayOfWeek) {
    dateRangeText += ' to ' + endDayOfWeek;
  }

  // When the status is already in effect, omit the start date and use "Until"
  if (+startDate <= +nowDate && !!endDate) {
    dateRangeText = `Until ${endDateText}, ${endDayOfWeek}`;
  }

  // When the status is open-ended (no end date), hide the date range
  if (endingDate === STATUS_UNTIL_FURTHER_NOTICE_DATE) {
    dateRangeText = '';
  }

  let [, , timeSpan] = TIMESPAN_REGEX.exec(description) || ['', '', ''];

  timeSpan =
    timeSpan
      .replace(' - ', EN_DASH)
      .replace(/<.*?>/g, '')
      .replace('<b>', '')
      .replace('</b>', '') || dateRangeText;

  return timeSpan;
};

export const cleanStatusDescription = (description: string): string => {
  return (
    description
      .replace(TIMESPAN_REGEX, '')
      .replace('<br><b><br></b></div><br>', '</div>')
      // Remove extra underscores
      .replace(/_{3,}<br>/g, '')
      .replace(/<b>([A-Z ]+)([A-Za-z0-9 ]+)?<br clear=left>/, '')
  );
};

/**
 * @returns Regular expression matching <text> or [text]
 */
const inBrackets = (text: string): RegExp =>
  new RegExp(`<${text}>|\\[${text}\\]`, 'gi');

const renderRoundedIcon = (iconType: IconTypes) => {
  return (
    <IconContainerRounded>
      <Icon type={iconType} />
    </IconContainerRounded>
  );
};

const localIconSVG = (element: JSX.Element | null): string => {
  const icon = renderToString(element!);

  return `<div data-subway-icon="true" style="display: inline-block; width: 18px; height: 18px; transform: translateY(4px);">${icon}</div>`;
};

export const withSubwayRouteIcons = (text: string): string => {
  const renderedText = text
    // Accessibility icon
    .replace(
      inBrackets('ad'),
      localIconSVG(renderRoundedIcon(IconTypes.Accessibility))
    )
    .replace(
      inBrackets('accessibility icon'),
      localIconSVG(renderRoundedIcon(IconTypes.Accessibility))
    )
    .replace(
      inBrackets('shuttle bus icon'),
      localIconSVG(renderRoundedIcon(IconTypes.Bus))
    )
    // Bus icon
    .replace(inBrackets('SB'), localIconSVG(renderRoundedIcon(IconTypes.Bus)))
    // Diamond icons for express
    .replace(inBrackets('6D'), localIconSVG(renderRouteIcon('6', true)))
    .replace(inBrackets('7D'), localIconSVG(renderRouteIcon('7', true)))
    .replace(inBrackets('SIR'), localIconSVG(renderRouteIcon('SI', true)))
    // Regular subway routes in <>
    .replace(/<([\dA-Z])>/g, (group, routeLabel) => {
      const routeId = subwayRouteIdFromSubwayRouteLabel(routeLabel);
      return localIconSVG(renderRouteIcon(routeId, true));
    })
    // Regular subway routes in []
    // Don't make case insensitive, otherwise it will strip <a> and <b> HTML tags
    .replace(/\[([\dA-Z])\]/g, (group, routeLabel) => {
      const routeId = subwayRouteIdFromSubwayRouteLabel(routeLabel);
      return localIconSVG(renderRouteIcon(routeId, true));
    });

  return renderedText;
};

export const extractStatusLastUpdated = (stringDate: string): string => {
  const date = new Date(stringDate);
  if (date.toString() === 'Invalid Date') {
    return '';
  }

  return `Last updated ${formatDistanceToNow(new Date(date))} ago`;
};

export const extractText = (content: any, type: string): string => {
  if (content.translation) {
    let text = content.translation.filter((text: any) => {
      return text.language.toLowerCase() === 'en-html';
    });

    if (text.length === 0) {
      text = content.translation.filter((text: any) => {
        return text.language.toLowerCase() === 'en';
      });

      if (text.length === 0) {
        text = content.translation.filter((text: any) => {
          return text.language.toLowerCase() === '';
        });

        if (text.length === 0) {
          text = [
            {
              text: '',
            },
          ];
        }
      }
    }

    return text[0].text;
  }

  return '';
};

export const extractHumanReadable = (status: RouteStatusMeta): object => {
  let result: any = false;

  if (status) {
    if (status.hasOwnProperty('human_readable_active_period')) {
      const humanReadable: any = status.human_readable_active_period || {};
      const translations: Array<any> = humanReadable.translation;

      result = translations[0].text;
    }
  }

  return result;
};
