import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import FilterButton from '../components/Controls/FilterButton';
import { SubwayRouteId } from '../subway-data/subway-types';
import {
  MENU_TRANSITION_DELAY_IN,
  MENU_TRANSITION_DELAY_OUT,
} from '../utils/animations';

interface FilterButtonContainerProps {
  onClick?: () => void;
  onDoubleClick?: () => void;
  selectedRouteId: SubwayRouteId | '';
  deemphasized?: boolean;
  isOpen: boolean;
}

const FilterButtonWrapper = styled.div<{ isOpen: boolean }>`
  pointer-events: ${p => (p.isOpen ? 'auto' : 'none')};
  transform: ${p => (p.isOpen ? 'scale(1)' : 'scale(0)')};
  transition: all 0.3s ease-in-out;
  transition-delay: ${p =>
    p.isOpen ? MENU_TRANSITION_DELAY_OUT + 450 : MENU_TRANSITION_DELAY_IN}ms;
`;

const FilterButtonContainer: React.FC<FilterButtonContainerProps> = ({
  onClick,
  onDoubleClick,
  selectedRouteId,
  deemphasized,
  isOpen,
}) => {
  const [lastClickTimestamp, setLastClickTimestamp] = useState(0);
  const lastClickTimestampRef = useRef(lastClickTimestamp);

  useEffect(() => {
    lastClickTimestampRef.current = lastClickTimestamp;
  }, [lastClickTimestamp]);

  const onOneClick = () => {
    const timestamp = new Date().getTime();
    setLastClickTimestamp(timestamp);

    setTimeout(() => {
      if (lastClickTimestampRef.current !== 0) {
        setLastClickTimestamp(0);
        onClick && onClick();
      }
    }, 200);
  };

  const onTwoClick = () => {
    setLastClickTimestamp(0);
    onDoubleClick && onDoubleClick();
  };

  return (
    <FilterButtonWrapper isOpen={isOpen} className="filter-button-wrapper">
      <FilterButton
        selectedRouteId={selectedRouteId}
        onClick={onOneClick}
        onDoubleClick={onTwoClick}
        deemphasized={deemphasized}
        isOpen={isOpen}
      />
    </FilterButtonWrapper>
  );
};

export default FilterButtonContainer;
