import { Feature, Map } from 'ol';
import { Point as OlPoint } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Style, Text, Fill, Stroke } from 'ol/style';
import { StyleFunction } from 'ol/style/Style';

import { BoroughLabel } from '../../subway-data/subway-types';
import boroughsLabels from '../../subway-data/boroughs';
import theme from '../../utils/theme';

import { getOlMapZoom, getProportionalValueForZoom } from '../maps-utils';
import { getMapTheme } from '../maps-theme';
import { ZoomLevel } from '../map-types';

const getStyleFunction = (useDarkMap: boolean): StyleFunction => (
  feature,
  resolution
) => {
  const currentZoom = getOlMapZoom(resolution, 1);
  const colorsToUse = getMapTheme(useDarkMap).labels;

  const borough: BoroughLabel = feature.get('borough');
  const fontSize = getProportionalValueForZoom(currentZoom, {
    [ZoomLevel.z11]: 14,
    [ZoomLevel.z12]: 18,
    [ZoomLevel.z13]: 24,
  });

  return new Style({
    text: new Text({
      fill: new Fill({
        color: colorsToUse.active,
      }),
      font: `bold ${fontSize}px ${theme.fonts.default}`,
      stroke: new Stroke({
        color: colorsToUse.outline,
        width: 3,
      }),
      text: borough.name,
      textAlign: 'center',
      textBaseline: 'middle',
    }),
  });
};

const boroughFeatures: Feature[] = boroughsLabels.map(borough => {
  return new Feature({
    borough,
    type: 'icon',
    geometry: new OlPoint(fromLonLat([borough.lon, borough.lat])),
  });
});

let boroughsLabelsLayer: VectorLayer;
export const addBoroughsLabelsLayer = ({
  map,
  useDarkMap,
}: {
  map: Map;
  useDarkMap: boolean;
}) => {
  if (boroughsLabelsLayer) {
    boroughsLabelsLayer.setStyle(getStyleFunction(useDarkMap));
  } else {
    boroughsLabelsLayer = new VectorLayer({
      declutter: true,
      maxZoom: 13.999,
      source: new VectorSource({
        features: boroughFeatures,
      }),
      style: getStyleFunction(useDarkMap),
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(boroughsLabelsLayer);
  }
};
