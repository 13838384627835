import { createSelector } from 'reselect';
import { MapState } from '../../models/map';
import {
  Station,
  Stations,
  Stop,
  StopId,
  StopList,
  StopLists,
  StopListsByRoute,
  SubwayRouteId,
} from '../../subway-data/subway-types';
import {
  MANHATTAN_DEGREES,
  RoutesUnavailable,
  TimeFilter,
} from '../../subway-data';
import {
  getMapSelectedRouteId,
  getMapSelectedStation,
  getMapStrategiesForSegments,
  getMapTimeFilter,
  getMapTrainStatusByStopId,
} from './basic';
import mapValues from 'lodash/mapValues';
import flatMap from 'lodash/flatMap';
import pull from 'lodash/pull';
import { routeIdsLayerOrder, STATION_SPACING } from '../../maps/maps-constants';
import { getMapStopListsByRoute } from './getMapStopListsByRoute';
import { getMapRoutesUnavailable } from './getMapRoutesUnavailable';
import { getMapStations } from './getMapStations';
import { getMapNormalAngleDegreesForAllStations } from './geometry';
import {
  TrainStatusByStopId,
  TrainTimeStatus,
} from '../../subway-data/station-status-types';
import { Feature } from 'ol';
import flatten from 'lodash/flatten';
import { LineString, MultiLineString } from 'ol/geom';
import {
  getFilteredStopsForRoute,
  getSegmentId,
} from '../../maps/subway-routes.utils';
import { LineAB, Point } from '../../geometry/geometry-types';
import {
  add,
  fromPolar,
  normalizeAngle,
  parallelSpacingAtIndex,
  pointsNormalAnglesDegrees,
  rotatePointsDegrees,
  getSegmentsNormalAngles,
} from '../../geometry/point-utils';
import {
  angled,
  angledS,
  getStrategyByIndex,
} from '../../geometry/path-strategies';
import { getLineIndicesAtStation } from '../../maps/subway-stops.utils';
import round from 'lodash/round';
import {
  RouteSegment,
  stationPointsForSubwayRouteId,
} from '../../maps/subway-openlayers-graphics';
import { MOVING_TRAIN_MAX_SECONDS } from '../../config';
import { getTimeFilterFromDate } from '../../utils/date.utils';
import { SegmentStrategyIndex } from '../../maps/strategies-for-segments';
import { toDegrees } from 'ol/math';

export const getSegmentsForRoute = ({
  routeId,
  routesUnavailable,
  stops,
  stations,
  stationAngles,
  strategiesForSegments,
}: {
  routeId: SubwayRouteId;
  routesUnavailable: RoutesUnavailable;
  stops: StopList;
  stations: Stations;
  stationAngles: { [stopId: string]: number };
  strategiesForSegments: MapState['strategiesForSegments'];
}): RouteSegment[] => {
  const rotation = MANHATTAN_DEGREES;
  const stopsForRoute = getFilteredStopsForRoute(stops);
  const pointsForRoute: Point[] = stationPointsForSubwayRouteId(
    stops,
    stationAngles,
    stations
  );

  const segments: RouteSegment[] = [];
  for (let i = 0; i < stopsForRoute.length - 1; i++) {
    // TODO: remove if unused
    // const prevStop: Stop | undefined = stopsForRoute[i - 1];
    const stop = stopsForRoute[i];
    const nextStop: Stop = stopsForRoute[i + 1];

    const station = stations.find(
      someStation => someStation.stopId === stop.stopId
    )!;
    const nextStation = stations.find(
      station => station.stopId === nextStop.stopId
    )!;
    const segmentId = getSegmentId(stop, nextStop);
    const thisPosition = pointsForRoute[i];
    const nextPosition = pointsForRoute[i + 1];
    const endpoints: LineAB = [thisPosition, nextPosition];
    const rotatedPoints = rotatePointsDegrees(endpoints, rotation);

    const strategyIndexStringOrNumber: SegmentStrategyIndex =
      strategiesForSegments[segmentId] || 0;
    let strategyIndex = strategyIndexStringOrNumber;
    let param1: number = 0.2;
    let param2: number = 0.2;
    if (typeof strategyIndexStringOrNumber === 'string') {
      [strategyIndex, param1, param2] = strategyIndexStringOrNumber
        .split(' ')
        .map(Number);
    }

    let stationAngle = station.angle || 0;
    let nextStationAngle = nextStation.angle || 0;
    if (station.perpendicularStack?.includes(routeId)) {
      stationAngle += 90;
    }
    if (nextStation.perpendicularStack?.includes(routeId)) {
      nextStationAngle += 90;
    }

    const strategy = getStrategyByIndex(strategyIndex);
    // The line strategy has to be executed in a rotated space
    let strategizedRotated = strategy(rotatedPoints);
    // TODO: clean up order
    if (strategyIndex === 9) {
      strategizedRotated = angled(rotatedPoints, {
        angleADegrees: 90 + stationAngle,
        angleBDegrees: 90 + nextStationAngle,
      });
    } else if (strategyIndex === 10) {
      strategizedRotated = angledS(rotatedPoints, {
        angleADegrees: -90 + stationAngle,
        angleBDegrees: 90 + nextStationAngle,
        distanceRatioA: param1,
        distanceRatioB: param2,
      });
    }

    // When there are 4 points in the strategy (S-curves),
    // and there are multiple lines in parallel,
    // the middle elbow points need to be shifted along their normal angles.
    // NOTE: it's important to shift the middle points before rotating back, not after.

    const points = strategizedRotated;
    const segmentsNormalAngles = getSegmentsNormalAngles(points);
    ///////////////// 4 POINTS /////////////////
    if (points.length === 4) {
      const [, b, c] = points;

      // Shift B, the first middle point
      const segmentNormalAngleDiffB =
        normalizeAngle(segmentsNormalAngles[1] - segmentsNormalAngles[0]) / 2;

      const { index: indexB, maxIndex: maxIndexB } = getLineIndicesAtStation({
        routeId,
        station,
      });
      const spacingAtIndexB = parallelSpacingAtIndex(
        STATION_SPACING,
        indexB,
        maxIndexB
      );

      // Find the "original B": cancel out the shifting by route index
      // For now, assume normal angle of 0
      // and same # of indices at both stations.
      const bVector: Point = [
        -spacingAtIndexB * Math.cos(segmentsNormalAngles[0]),
        -spacingAtIndexB * Math.sin(segmentsNormalAngles[0]),
      ];
      const bCenter: Point = add(b, bVector);
      // const bCenter: Point = [b[0] - spacingAtIndexB, b[1]];
      // const bCenter: Point = b;
      const radiusElbowB = spacingAtIndexB / Math.cos(segmentNormalAngleDiffB);
      const bShifted = add(
        bCenter,
        // fromPolar(radiusElbowB, -segmentNormalAngleDiffB)
        fromPolar(
          radiusElbowB,
          segmentNormalAngleDiffB + segmentsNormalAngles[0]
        )
        // fromPolar(radiusElbowB, Math.PI / 2 - segmentNormalAngleDiffB)
        // fromPolar(radiusElbowB, toRadians(45))
      );
      // TODO: clean up this mutation
      points[1] = bShifted;
      // points[1] = bCenter;

      // Shift C, the second middle point
      const segmentNormalAngleDiffC =
        normalizeAngle(segmentsNormalAngles[2] - segmentsNormalAngles[1]) / 2;

      const { index: indexC, maxIndex: maxIndexC } = getLineIndicesAtStation({
        routeId,
        // TODO: next station?
        station,
      });
      const spacingAtIndexC = parallelSpacingAtIndex(
        STATION_SPACING,
        indexC,
        maxIndexC
      );
      const cVector: Point = [
        -spacingAtIndexC * Math.cos(segmentsNormalAngles[2]),
        -spacingAtIndexC * Math.sin(segmentsNormalAngles[2]),
      ];
      const cCenter: Point = add(c, cVector);
      // const cCenter: Point = [c[0] - spacingAtIndexC, c[1]];
      // const cCenter: Point = c;
      const radiusElbowC = spacingAtIndexC / Math.cos(segmentNormalAngleDiffC);
      const cShifted = add(
        cCenter,
        // fromPolar(radiusElbowC, segmentNormalAngleDiffC)
        fromPolar(
          radiusElbowC,
          -segmentNormalAngleDiffC + segmentsNormalAngles[2]
        )
        // fromPolar(radiusElbowB, toRadians(45))
      );
      // TODO: clean up this mutation
      points[2] = cShifted;
    }

    // Now rotate the generated points back into the original space
    const strategizedPoints = rotatePointsDegrees(
      strategizedRotated,
      -rotation
    );

    const normalAnglesDegrees = pointsNormalAnglesDegrees(strategizedRotated)
      // Have to subtract the angle from 180 for some reason for it to be correct
      .map(angleDegrees => 180 - angleDegrees)
      .map(angleDegrees => round(angleDegrees, 1));

    // TODO: J/Z near Canal needs to be not reversed to be correct
    const routeIds = [...station.transfersAndPassthroughs].reverse();
    const routeIndex = routeIds.indexOf(routeId);
    const maxIndex = station.transfersAndPassthroughs.length - 1;

    const nextRouteIds = [...nextStation.transfersAndPassthroughs].reverse();
    const nextRouteIndex = nextRouteIds.indexOf(routeId);
    // TODO: handle different numbers of routes at each end, e.g. Orange W 4 to Broadway-Lafayette
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const nextMaxIndex = nextStation.transfersAndPassthroughs.length - 1;

    const isOneDirection =
      stop.direction !== undefined || nextStop.direction !== undefined;

    const segment: RouteSegment = {
      isOneDirection,
      routeId,
      segmentId,
      stopIds: [stop.stopId, nextStop.stopId],
      stopNames: [stop.stopName, nextStop.stopName],
      strategyIndex,
      originalPoints: [thisPosition, nextPosition],
      strategizedPoints,
      normalAnglesDegrees,
      segmentsNormalAnglesDegrees: segmentsNormalAngles.map(toDegrees),
      routeIndex,
      maxIndex,
      nextRouteIndex,
      nextMaxIndex,
      unavailable:
        routesUnavailable[routeId] || stop.unavailable || nextStop.unavailable,
    };
    segments.push(segment);
  }
  return segments;
};

const getSegmentLists = ({
  routeId,
  routesUnavailable,
  stopLists,
  stations,
  normalAngleDegreesForAllStations,
  strategiesForSegments,
}: {
  routeId: SubwayRouteId;
  routesUnavailable: RoutesUnavailable;
  stopLists: StopLists;
  stations: Stations;
  normalAngleDegreesForAllStations: { [stopId: string]: number };
  strategiesForSegments: MapState['strategiesForSegments'];
}): RouteSegment[][] => {
  return stopLists.map(stopList =>
    getSegmentsForRoute({
      routeId,
      routesUnavailable,
      stops: stopList,
      stations,
      stationAngles: normalAngleDegreesForAllStations,
      strategiesForSegments,
    })
  );
};

const getSortedRouteIdsLayerOrder = (selectedRouteId: SubwayRouteId | '') => {
  let linesOrder: SubwayRouteId[] = [...routeIdsLayerOrder];

  // Renders the selected line over all the others to make it visible
  // on lower zooms and more apparent on higher zoom levels.
  if (selectedRouteId) {
    pull(linesOrder, selectedRouteId);
    linesOrder.push(selectedRouteId);
  }

  return linesOrder;
};

export const getMapAllRoutesSegmentFeatures = createSelector(
  getMapRoutesUnavailable,
  getMapSelectedRouteId,
  getMapStopListsByRoute,
  getMapStations,
  getMapNormalAngleDegreesForAllStations,
  getMapStrategiesForSegments,
  (
    routesUnavailable: RoutesUnavailable,
    selectedRouteId: SubwayRouteId | '',
    stopListsByRoute: StopListsByRoute,
    stations: Stations,
    normalAngleDegreesForAllStations: { [stopId: string]: number },
    strategiesForSegments: MapState['strategiesForSegments']
  ): Feature[] => {
    const routeIdsOrder = getSortedRouteIdsLayerOrder(selectedRouteId);

    return flatMap(routeIdsOrder, routeId => {
      const segmentLists = getSegmentLists({
        routeId,
        routesUnavailable,
        stopLists: stopListsByRoute[routeId],
        stations,
        normalAngleDegreesForAllStations,
        strategiesForSegments,
      });

      return (
        flatten(segmentLists)
          // We can have local and express segments in a different direction.
          // We draw the ones running both directions on top.
          .sort(routeSegment => (routeSegment.isOneDirection ? -1 : 1))
          .map(routeSegment => {
            const { strategizedPoints } = routeSegment;

            return new Feature({
              geometry: new LineString(strategizedPoints),
              routeSegment,
            });
          })
      );
    });
  }
);

export const getMapAllRoutesSegmentMultiLineFeatures = createSelector(
  getMapRoutesUnavailable,
  getMapSelectedRouteId,
  getMapStopListsByRoute,
  getMapStations,
  getMapNormalAngleDegreesForAllStations,
  getMapStrategiesForSegments,
  (
    routesUnavailable: RoutesUnavailable,
    selectedRouteId: SubwayRouteId | '',
    stopListsByRoute: StopListsByRoute,
    stations: Stations,
    normalAngleDegreesForAllStations: { [stopId: string]: number },
    strategiesForSegments: MapState['strategiesForSegments']
  ): Feature[] => {
    const routeIdsOrder = getSortedRouteIdsLayerOrder(selectedRouteId);
    return flatMap(routeIdsOrder, routeId => {
      const segmentList = flatten(
        getSegmentLists({
          routeId,
          routesUnavailable,
          stopLists: stopListsByRoute[routeId],
          stations,
          normalAngleDegreesForAllStations,
          strategiesForSegments,
        })
      );

      return new Feature({
        geometry: new MultiLineString(
          segmentList.map(routeSegment => routeSegment.strategizedPoints)
        ),
        routeId,
        routeSegments: segmentList,
      });
    });
  }
);

const EMPTY_TRAIN_TIME_STATUS = { lastUpdate: NaN, trains: [] };

export const getMapSoonTrainTimesForSelectedStation = createSelector(
  getMapTimeFilter,
  getMapSelectedStation,
  getMapTrainStatusByStopId,
  (
    timeFilter: TimeFilter,
    selectedStation: Station | null,
    trainStatusByStopId: TrainStatusByStopId
  ): TrainTimeStatus => {
    const isNow = getTimeFilterFromDate() === timeFilter;
    if (!isNow || !selectedStation) return EMPTY_TRAIN_TIME_STATUS;
    const trainTimeStatusForSelectedStop =
      trainStatusByStopId[selectedStation.stopId];
    if (!trainTimeStatusForSelectedStop) return EMPTY_TRAIN_TIME_STATUS;

    const { lastUpdate } = trainTimeStatusForSelectedStop;
    const trainsArrivingSoon = trainTimeStatusForSelectedStop.trains.filter(
      train => train.time <= MOVING_TRAIN_MAX_SECONDS
    );
    return { lastUpdate, trains: trainsArrivingSoon };
  }
);

export const getMapSoonTrainTimes = createSelector(
  getMapTimeFilter,
  getMapTrainStatusByStopId,
  (
    timeFilter: TimeFilter,
    trainStatusByStopId: TrainStatusByStopId
  ): TrainStatusByStopId => {
    const isNow = getTimeFilterFromDate() === timeFilter;
    if (!isNow) return {};
    return mapValues(trainStatusByStopId, trainTimeStatusForOneStop => {
      const { lastUpdate } = trainTimeStatusForOneStop;
      const trainsArrivingSoon = trainTimeStatusForOneStop.trains.filter(
        train => train.time <= MOVING_TRAIN_MAX_SECONDS
      );
      return { lastUpdate, trains: trainsArrivingSoon };
    });
  }
);

export const getMovingTrainsFeaturesForStopId = (
  stopId: StopId,
  allRoutesSegmentFeatures: Feature[],
  trainTimeStatus: TrainTimeStatus
): Feature[] => {
  if (!stopId) return [];
  const trainTimes0 = trainTimeStatus.trains.filter(
    train => train.directionId === '0'
  );
  const trainTimes1 = trainTimeStatus.trains.filter(
    train => train.directionId === '1'
  );
  // TODO: de-duplicate logic for the two directions
  const routeSegmentFeatures0: Feature[] = trainTimes0
    .map(train => {
      const feature = allRoutesSegmentFeatures.find(someFeature => {
        const routeSegment = someFeature.get('routeSegment') as RouteSegment;
        // A vertical route segment usually runs north-to-south
        // but direction 0 is the opposite direction,
        // so the selected stop will be the segment's second stop.
        return (
          routeSegment.stopIds[0] === stopId &&
          routeSegment.routeId === train.routeId
        );
      });
      if (!feature) return undefined;
      return new Feature({
        geometry: feature.getGeometry(),
        routeSegment: feature.get('routeSegment') as RouteSegment,
        directionId: '0',
        lastUpdate: trainTimeStatus.lastUpdate,
        trainTime: train,
        trainTimeStatus: trainTimeStatus,
      });
    })
    // Remove undefined values and tell TypeScript they're gone
    .filter(Boolean) as Feature[];

  const routeSegmentFeatures1: Feature[] = trainTimes1
    .map(train => {
      const feature = allRoutesSegmentFeatures.find(someFeature => {
        const routeSegment = someFeature.get('routeSegment') as RouteSegment;
        return (
          routeSegment.stopIds[1] === stopId &&
          routeSegment.routeId === train.routeId
        );
      });
      if (!feature) return undefined;
      return new Feature({
        geometry: feature.getGeometry(),
        routeSegment: feature.get('routeSegment') as RouteSegment,
        directionId: '1',
        lastUpdate: trainTimeStatus.lastUpdate,
        trainTime: train,
        trainTimeStatus: trainTimeStatus,
      });
    })
    // Remove undefined values and tell TypeScript they're gone
    .filter(Boolean) as Feature[];

  return [...routeSegmentFeatures0, ...routeSegmentFeatures1];
};

export const getMovingTrainsFeaturesForSelectedStation = createSelector(
  getMapSelectedStation,
  getMapAllRoutesSegmentFeatures,
  getMapSoonTrainTimesForSelectedStation,
  (
    selectedStation: Station | null,
    allRoutesSegmentFeatures: Feature[],
    soonTrainTimesForSelectedStation: TrainTimeStatus
  ): Feature[] => {
    const selectedStopId = selectedStation?.stopId;
    if (!selectedStopId) return [];

    return getMovingTrainsFeaturesForStopId(
      selectedStopId,
      allRoutesSegmentFeatures,
      soonTrainTimesForSelectedStation
    );
  }
);

export const getMovingTrainsFeatures = createSelector(
  getMapAllRoutesSegmentFeatures,
  getMapSoonTrainTimes,
  (
    allRoutesSegmentFeatures: Feature[],
    soonTrainTimes: TrainStatusByStopId
  ): Feature[] => {
    const featureLists = Object.entries(
      soonTrainTimes
    ).map(([stopId, trainTime]) =>
      getMovingTrainsFeaturesForStopId(
        stopId,
        allRoutesSegmentFeatures,
        trainTime
      )
    );
    return flatMap(featureLists);
  }
);
