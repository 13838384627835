import intersection from 'lodash/intersection';

/**
 * If a feature has on or more subway stop ids related, we create the elements
 * related to the stop ids that are available for the current map state.
 */
export const intersectsWithAvailableStopIds = (
  allAvailableStopIds: string[],
  requiredSubwayStopIds: string[] = []
): boolean => {
  if (!requiredSubwayStopIds.length) return true;

  return intersection(allAvailableStopIds, requiredSubwayStopIds).length > 0;
};
