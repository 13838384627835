import { Feature, Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import IconOrigin from 'ol/style/IconOrigin';
import { StyleFunction } from 'ol/style/Style';

import { SubwayRouteIds } from '../../subway-data/subway-types';
import { RoutesUnavailable } from '../../subway-data';

import { EMPTY_STYLE, RENDER_BUFFER_ICONS } from '../maps-constants';
import { ZoomLevel } from '../map-types';
import { getOlMapZoom } from '../maps-utils';
import { LayerRenderingProps } from '../subway-openlayers-graphics';
import { routeIconsSvgData } from '../subway-sign-svg';

let lineIconCache = {};
const getLineIconStyle = (
  routesUnavailable: RoutesUnavailable = {},
  useDarkMap: boolean
): StyleFunction => (feature, resolution) => {
  const currentZoom = getOlMapZoom(resolution);
  const lineIcons = feature.get('lineIcons') as SubwayRouteIds;
  const iconWidth = currentZoom >= ZoomLevel.z12 ? 10 : 9; // mobile zoom can go below 12, for which slightly smaller icon is appropriate (9)

  if (!lineIcons) return EMPTY_STYLE;

  const cacheId = `${lineIcons}-${lineIcons.map(
    routeId => routesUnavailable[routeId]
  )}-${useDarkMap}-${iconWidth}`;

  if (!(cacheId in lineIconCache)) {
    lineIconCache[cacheId] = new Style({
      image: new Icon({
        anchor: [-3, -10],
        anchorOrigin: IconOrigin.TOP_LEFT,
        anchorXUnits: IconAnchorUnits.PIXELS,
        anchorYUnits: IconAnchorUnits.PIXELS,
        // we don't use routesUnavailableForStop because for the line icon
        // what matter is if the line is running
        ...routeIconsSvgData({
          iconWidth,
          routeIds: lineIcons,
          routesUnavailable,
          useDarkMap,
        }),
      }),
    });
  }

  return lineIconCache[cacheId];
};

let subwayRouteIdsLayer: VectorLayer;
export const addSubwayRouteIdsLayer = ({
  allFeaturesForAllRoutesUnified,
  map,
  routeId,
  routesUnavailable,
  useDarkMap,
}: LayerRenderingProps & {
  allFeaturesForAllRoutesUnified: Feature[];
  map: Map;
  useDarkMap: boolean;
}) => {
  const features = routeId ? [] : allFeaturesForAllRoutesUnified;
  const style = getLineIconStyle(routesUnavailable, useDarkMap);

  if (subwayRouteIdsLayer) {
    subwayRouteIdsLayer.setSource(
      new VectorSource({
        features,
      })
    );
    subwayRouteIdsLayer.setStyle(style);
  } else {
    subwayRouteIdsLayer = new VectorLayer({
      declutter: true,
      maxZoom: 13.999,
      renderBuffer: RENDER_BUFFER_ICONS,
      source: new VectorSource({
        features,
      }),
      style,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
    });

    map.addLayer(subwayRouteIdsLayer);
  }
};
