import React from 'react';
import styled from 'styled-components/macro';
import { NotRunningServiceMessage } from './ui-constants';
import { subTitle, content } from './Text';

const Title = styled.h2`
  ${subTitle};
  margin: 0 0 10px;
`;

const SubTitle = styled.p`
  ${content};
`;

const NotRunning: React.FC<{
  notRunningServiceMessage: NotRunningServiceMessage;
}> = ({ notRunningServiceMessage }) => (
  <>
    <Title>{notRunningServiceMessage?.title}</Title>
    <SubTitle>{notRunningServiceMessage?.subtitle}</SubTitle>
  </>
);

export default NotRunning;
