import { createSelector } from 'reselect';

import { AppState } from '../models';
import { UIState } from '../models/ui';

export const getUIState = (state: AppState): UIState => state.ui;

export const getUIInitialized = createSelector(
  getUIState,
  (uiState: UIState) => uiState.initialized
);

export const getUIRouteMenuOpened = createSelector(
  getUIState,
  (uiState: UIState) => uiState.routeMenuOpened
);

export const getUIStationViewOpened = createSelector(
  getUIState,
  (uiState: UIState) => uiState.stationViewOpened
);

export const getUIAirportViewOpened = createSelector(
  getUIState,
  (uiState: UIState) => uiState.airportTerminalViewOpened
);

export const getUIStatusViewOpened = createSelector(
  getUIState,
  (uiState: UIState) => uiState.statusViewOpened
);
