/* eslint-disable no-unexpected-multiline */
import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  subwayRouteColors,
  subwayRouteInactiveColors,
  subwayRouteHoverColors,
  subwayRouteLabelFromSubwayRouteId,
  subwayRouteHasDarkText,
} from '../../subway-data';
import { SubwayRouteId } from '../../subway-data/subway-types';
import theme, {
  withFocusStyle,
  withTouchPressStyle,
  notTouch,
  desktop,
} from '../../utils/theme';
import SubwayRouteIcon from '../SubwayRouteIcon';

interface SubwayRouteButtonProps {
  inverted?: boolean;
  isOpen: boolean;
  routeId: SubwayRouteId;
  selected: boolean;
  deemphasized: boolean;
  hasSituation: boolean;
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SubwayRouteButtonInner = styled.button<SubwayRouteButtonProps>`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  outline: none;
  color: ${p =>
    p.deemphasized
      ? subwayRouteInactiveColors[p.routeId]
      : subwayRouteColors[p.routeId]};
  background-color: transparent;
  margin: 0;
  transition: ${p => p.theme.transition};
  cursor: pointer;

  /* Forces to use the light theme
  because it's inside a white area */
  ${withFocusStyle(theme)}
  ${withTouchPressStyle()}

  ${notTouch(css`
    &:hover {
      color: ${(p: any) => subwayRouteHoverColors[p.routeId]};

      path[data-letter='true'] {
        fill: ${(p: any) => (p.inverted ? 'black' : 'white')};
      }
    }
  `)}

  ${desktop(css`
    height: 100%;
    width: 100%;

    &::before {
      display: none;
    }
  `)};
`;

const subwayRouteLabel = (
  routeId: SubwayRouteId,
  deemphasized: boolean,
  hasSituation: boolean
): string => {
  const baseString = `${subwayRouteLabelFromSubwayRouteId(
    routeId
  )} subway line`;
  if (hasSituation) return `${baseString} with service updates`;
  if (deemphasized) return `${baseString} with interrupted service`;
  return `${baseString}`;
};

const SubwayRouteButton: React.FC<SubwayRouteButtonProps> = ({
  routeId,
  onClick,
  selected,
  deemphasized,
  hasSituation,
  isOpen,
}) => (
  <SubwayRouteButtonInner
    {...{ routeId, onClick, selected, deemphasized, hasSituation }}
    aria-label={subwayRouteLabel(routeId, deemphasized, hasSituation)}
    data-cy={`menu-button-${routeId}`}
    tabIndex={isOpen ? 0 : -1}
    isOpen={isOpen}
    inverted={subwayRouteHasDarkText[routeId || '']}
  >
    <SubwayRouteIcon
      deemphasized={deemphasized}
      routeId={routeId}
      hasSituation={isOpen && hasSituation}
    />
  </SubwayRouteButtonInner>
);

export default SubwayRouteButton;
