import { ConnectedStationsCustomization } from './subway-types';

/**
 * The stations have a complex id value that means all the stations associated
 * with a station complex. The stations that are part of a complex have
 * internal transfers for each other.
 *
 * This object will customize the data from the `getMapConnectedStations`
 * selector on some functions.
 *
 * The order of the items in each Station Complex group sets the order to draw
 * the lines between them on the map.
 *
 * The pathStrategyIndex applies the curve pattern between the current
 * and the next station.
 *
 * The parallelSpacingAtFirstIndex sets the start of the connection line at
 * the initial edge of a station. If it's false, it connects with the end edge.
 */
export const connectedStationsCustomization: {
  [complexId: number]: ConnectedStationsCustomization[];
} = {
  601: [
    // 14 St (B/D/F/M)
    { stopId: 'D19', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 3 },
    // 14 St (1/2/3)
    { stopId: '132', parallelSpacingAtFirstIndex: false, pathStrategyIndex: 1 },
    // 6 Av (L)
    { stopId: 'L02', parallelSpacingAtFirstIndex: true },
  ],
  605: [
    // 168 St (A/C)
    { stopId: 'A09', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 2 },
    // 168 St Washington Hts (1)
    { stopId: '112', parallelSpacingAtFirstIndex: false },
  ],
  606: [
    // Court Sq - 23 St (M/E)
    { stopId: 'F09', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 2 },
    // Court Sq (G)
    {
      stopId: '719',
      parallelSpacingAtFirstIndex: true,
    },
  ],
  608: [
    // 4 Av-9 St (R/W/N/Q/D)
    { stopId: 'R33', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 1 },
    // 4 Av-9 St (F/G)
    { stopId: 'F23', parallelSpacingAtFirstIndex: false },
  ],
  609: [
    // 42 St - Bryant Pk (B/D/F/M)
    { stopId: 'D16', parallelSpacingAtFirstIndex: false, pathStrategyIndex: 1 },
    // 5 Av (7)
    { stopId: '724', parallelSpacingAtFirstIndex: true },
  ],
  611: [
    // 42 St - Port Authority
    { stopId: 'A27', parallelSpacingAtFirstIndex: false },
    // Times Sq-42 St
    { stopId: '127', parallelSpacingAtFirstIndex: true },
  ],
  612: [
    // Lexington Av/53 St (M/E)
    { stopId: 'F11', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 2 },
    // 51 St (6)
    { stopId: '630', parallelSpacingAtFirstIndex: false },
  ],
  613: [
    // Lexington Av/59 St (R/W/N)
    { stopId: 'R11', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 2 },
    // 59 St (4/5/6)
    { stopId: '629', parallelSpacingAtFirstIndex: false },
  ],
  615: [
    // 62 St (D)
    { stopId: 'B16', parallelSpacingAtFirstIndex: false, pathStrategyIndex: 1 },
    // New Utrecht Av (W/N/Q)
    { stopId: 'N04', parallelSpacingAtFirstIndex: false },
  ],
  616: [
    // Jackson Hts - Roosevelt Av (R/M/F/E)
    { stopId: 'G14', parallelSpacingAtFirstIndex: false, pathStrategyIndex: 2 },
    // 74 St - Broadway (7)
    { stopId: '710', parallelSpacingAtFirstIndex: false },
  ],
  617: [
    // Atlantic Av Barclays Ctr (R/N/D)
    { stopId: 'R31', parallelSpacingAtFirstIndex: false, pathStrategyIndex: 0 },
    // Atlantic Av Barclays Ctr (2/3/4/5/Q/B)
    { stopId: '235', parallelSpacingAtFirstIndex: true },
  ],
  618: [
    // 8 Av (L)
    { stopId: 'L01', parallelSpacingAtFirstIndex: false, pathStrategyIndex: 2 },
    // 14 St (A/C/E)
    { stopId: 'A31', parallelSpacingAtFirstIndex: false },
  ],
  619: [
    // Broadway-Lafayette St (B/D/F/M)
    { stopId: 'D21', parallelSpacingAtFirstIndex: false, pathStrategyIndex: 2 },
    // Bleecker St (4/5/6)
    { stopId: '637', parallelSpacingAtFirstIndex: true },
  ],
  620: [
    // Court St (R/W/N/Q)
    {
      stopId: 'R28',
      parallelSpacingAtFirstIndex: true,
      pathStrategyIndex: 9,
    },
    // Borough Hall (2/3/4/5)
    { stopId: '232', parallelSpacingAtFirstIndex: true },
  ],
  622: [
    // Chambers St (4/5/6/J/Z)
    { stopId: 'M21', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 1 },
    // Brooklyn Bridge - City Hall
    { stopId: '640', parallelSpacingAtFirstIndex: false },
  ],
  623: [
    // Canal St (4/5/6/J/Z)
    { stopId: 'Q01', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 0 },
    // Canal St (R/W/N/Q)
    { stopId: 'R23', parallelSpacingAtFirstIndex: false },
  ],
  624: [
    // Cortlandt St (R/W/N/Q)
    { stopId: 'R25', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 1 },
    // World Trade Center
    { stopId: 'E01', parallelSpacingAtFirstIndex: false, pathStrategyIndex: 4 },
    // Park Place
    { stopId: '228', parallelSpacingAtFirstIndex: false, pathStrategyIndex: 2 },
    // Chambers (A/C)
    { stopId: 'A36', parallelSpacingAtFirstIndex: false },
  ],
  626: [
    // Botanic Garden
    { stopId: 'S04', parallelSpacingAtFirstIndex: false, pathStrategyIndex: 1 },
    // Franklin Av-Medgar Evers College
    { stopId: '239', parallelSpacingAtFirstIndex: true },
  ],
  627: [
    // Franklin Av (FS)
    { stopId: 'S01', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 1 },
    // Franklin Av (C)
    { stopId: 'A45', parallelSpacingAtFirstIndex: true },
  ],
  628: [
    // Fulton St (4/5)
    { stopId: '418', parallelSpacingAtFirstIndex: false },
    // Fulton St (A/C/J/Z)
    { stopId: 'A38', parallelSpacingAtFirstIndex: false },
    // Fulton St (2/3)
    { stopId: '229', parallelSpacingAtFirstIndex: true },
  ],
  629: [
    // Lorimer St (L)
    { stopId: 'L10', parallelSpacingAtFirstIndex: true, pathStrategyIndex: 6 },
    // Metropolitan Av (G)
    { stopId: 'G29', parallelSpacingAtFirstIndex: false },
  ],
  635: [
    // Whitehall St
    { stopId: 'R27', parallelSpacingAtFirstIndex: true },
    // South Ferry
    { stopId: '142', parallelSpacingAtFirstIndex: false },
  ],
};
