export const getWindowHeight = (): number => {
  if (typeof window === 'undefined' || typeof navigator === 'undefined') {
    return 0;
  }

  if (!navigator.userAgent.match(/iphone|ipod|ipad/i)) {
    return window.innerHeight;
  }

  if (navigator.userAgent.match(/safari/i)) {
    return window.innerHeight;
  }

  const isPortrait = window.matchMedia('(orientation: portrait)').matches;
  const dims = { w: 0, h: 0 };
  const ruler = document.createElement('div');

  ruler.style.position = 'fixed';
  ruler.style.height = '100vh';
  ruler.style.width = '0px';
  ruler.style.top = '0px';

  document.documentElement.appendChild(ruler);

  dims.w = isPortrait ? ruler.offsetHeight : window.innerWidth;
  dims.h = isPortrait ? window.innerWidth : ruler.offsetHeight;

  document.documentElement.removeChild(ruler);

  if (!isPortrait) {
    return dims.h; //ruler.offsetHeight
  }

  return dims.w; //ruler.offsetHeight
};
