import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components/macro';
import { SERVICE_STATUS_BUTTON_TRANSITION } from '../utils/animations';
import { desktopExtraLarge, tablet } from '../utils/theme';

export const ANIMATION_DURATION = 500;
export const INNER_ANIMATION_DURATION = 500;

interface AsideContainerProps {
  isEmergencyAlert?: boolean;
  isOpen: boolean;
  translate?: boolean;
  shortcutsMenuOpen: boolean;
}
const AsideContainer = styled.aside<AsideContainerProps>`
  bottom: 0;
  left: ${p => p.theme.margin};
  position: fixed;
  z-index: ${p =>
    p.isEmergencyAlert
      ? p.theme.zIndex.routeMenu
      : p.theme.zIndex.serviceStatusDesktop};
  width: 100vw;
  height: auto;
  pointer-events: none;
  transform: ${p =>
    p.isOpen ? `translateY(${p.translate ? '60px' : '0'})` : 'translateY(-5%)'};
  opacity: ${p => (p.isOpen ? (p.shortcutsMenuOpen ? 0 : 1) : 0)};
  transition: ${p => p.theme.transition};
  transition-property: transform, opacity;
  transition-duration: ${ANIMATION_DURATION}ms;
  transition-delay: ${p =>
    p.isOpen && !p.isEmergencyAlert ? SERVICE_STATUS_BUTTON_TRANSITION : 0}ms;

  ${tablet(css`
    width: 240px;
    top: ${(p: AsideContainerProps) => (p.isEmergencyAlert ? '380px' : '80px')};
  `)};

  ${desktopExtraLarge(css`
    width: 300px;
    top: ${(p: AsideContainerProps) => (p.isEmergencyAlert ? '436px' : '80px')};
  `)};
`;

const AsideInner = styled.div<{ isOpen: boolean }>`
  background-color: ${p => p.theme.colors.white};
  border-radius: 6px;
  box-shadow: ${p => p.theme.boxShadow};
  height: auto;
  overflow-y: auto;
  pointer-events: ${p => (p.isOpen ? 'all' : 'none')};
  position: relative;
  width: 100%;
`;

interface AsideProps {
  isEmergencyAlert?: boolean;
  isOpen: boolean;
  children: React.ReactElement;
  translate?: boolean;
  shortcutsMenuOpen?: boolean;
}
const Aside: React.FC<AsideProps> = ({
  isEmergencyAlert,
  isOpen,
  children,
  translate = false,
  shortcutsMenuOpen = false,
}) => {
  const innerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    innerRef.current &&
      innerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [children]);

  return (
    <AsideContainer
      isOpen={isOpen}
      isEmergencyAlert={isEmergencyAlert}
      shortcutsMenuOpen={shortcutsMenuOpen}
      translate={translate}
    >
      <AsideInner isOpen={isOpen} ref={innerRef}>
        {children}
      </AsideInner>
    </AsideContainer>
  );
};

export default Aside;
