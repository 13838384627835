import React from 'react';
import styled, { css } from 'styled-components/macro';
import { TrainTime } from '../../subway-data/station-status-types';
import { SubwayRouteIds, SubwayRouteId } from '../../subway-data/subway-types';
import { tablet, desktopExtraLarge } from '../../utils/theme';
import {
  StationStatusTrainsTableRow,
  StationStatusServiceTableRow,
} from './StationStatusTrainsTableRow';
import {
  RoutesUnavailable,
  RoutesUnidirectional,
  RoutesWithSituation,
} from '../../subway-data';

const Table = styled.table`
  width: 100%;

  ${tablet(css`
    margin-bottom: 30px;
  `)}

  ${desktopExtraLarge(css`
    margin-bottom: 40px;
  `)}
`;

const ServiceTable = styled(Table)`
  margin-bottom: 0 !important;
`;

const TableHeader = styled.thead`
  display: none;
`;

export const StationStatusTrainsTable: React.FC<{
  upcomingTrainTimes: TrainTime[];
}> = ({ upcomingTrainTimes }) => {
  return (
    <Table>
      <TableHeader>
        <tr>
          <th>Subway Route</th>
          <th>Terminal Destination</th>
          <th>Time to arrival</th>
        </tr>
      </TableHeader>

      <tbody>
        {upcomingTrainTimes!.map(upcomingTrain => {
          const {
            routeId,
            time,
            longName,
            stopHeadsign,
            tripId,
            isExpress,
          } = upcomingTrain;
          return (
            <StationStatusTrainsTableRow
              key={tripId}
              {...{ tripId, routeId, longName, time, stopHeadsign, isExpress }}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

interface StationStatusServiceTableProps {
  routesWithSituation: RoutesWithSituation;
  setSelectedRouteId: (routeId: SubwayRouteId | '') => void;
  transfers: SubwayRouteIds;
  unavailableRoutes: RoutesUnavailable;
  unidirectionalRoutes: RoutesUnidirectional;
}
export const StationStatusServiceTable: React.FC<StationStatusServiceTableProps> = ({
  routesWithSituation,
  setSelectedRouteId,
  transfers,
  unavailableRoutes,
  unidirectionalRoutes,
}) => {
  return (
    <ServiceTable>
      <TableHeader>
        <tr>
          <th>Subway Route</th>
          <th>Expected service</th>
        </tr>
      </TableHeader>
      <tbody>
        {transfers.map(routeId => {
          return (
            <StationStatusServiceTableRow
              key={routeId}
              routeId={routeId}
              setSelectedRouteId={setSelectedRouteId}
              unavailableRoutes={unavailableRoutes}
              hasServiceAlerts={
                !!routesWithSituation[routeId] ||
                !!unidirectionalRoutes?.[routeId]
              }
            />
          );
        })}
      </tbody>
    </ServiceTable>
  );
};
