import { AppState } from '.';
import {
  otpStopListsByRoute,
  RoutesWithSituation,
  stations,
  TimeFilter,
} from '../subway-data';
import {
  OtpRouteId,
  RouteDirection,
  RouteStatus,
  ServiceStatus,
  Station,
  Stations,
  StopId,
  StopLists,
  StopListsByRoute,
  SubwayRouteId,
} from '../subway-data/subway-types';
import {
  TrainStatusByStopId,
  TrainTimesByStopId,
} from '../subway-data/station-status-types';
import loadRouteStatus from '../services/loadRouteStatus';
import UrlState, { getDevQaUrlData } from '../utils/url.utils';
import {
  getOverridableDateTime,
  getPatternGraphDateFromTimeFilter,
  getTimeFilterFromDate,
  overridableNow,
} from '../utils/date.utils';
import { clamp } from 'ol/math';
import {
  CACHE_EXPIRACY_DURATION,
  CENTER_ON_GEOLOCATION_TIMEOUT,
  DEFAULT_CENTER_ZOOM,
  DEFAULT_LAT,
  DEFAULT_LON,
  DEFAULT_MAX_ZOOM,
  DEFAULT_MIN_ZOOM,
  DEFAULT_ZOOM,
  VISIBILITY_TIMEOUT_REFRESH,
} from '../config';
import getRoutesWithSituation from '../services/getRoutesWithSituation';
import {
  isAndroidDevice,
  isInternetExplorer,
  isStandaloneMode,
} from '../utils/deviceDetector.utils';
import { isValueDifferent, isWithinExtent } from '../utils/math.utils';
import strategiesForSegments, {
  StrategiesForSegments,
} from '../maps/strategies-for-segments';
import loadStationStatus from '../services/loadStationStatus';
import fetchRouteStopsTimeTable from '../subway-data/patternGraph/fetchRouteStopsTimeTable';
import loadRouteStopsAtTime from '../services/loadRouteStopsAtTime';
import { loadElevatorEscalatorStatusWithStopId } from '../services/loadElevatorEscalatorStatus';
import { ElevatorEscalatorStatus } from '../subway-data/elevator-escalator-status-types';
import { getMapRoutesUnavailable } from '../selectors/map/getMapRoutesUnavailable';
import { EmergencyAlert } from '../subway-data/otp/emergency-alerts-types';
import { loadEmergencyAlerts } from '../services/loadEmergencyAlerts';
import {
  EDITING,
  EMERGENCY_ALERT_ALTERNATIVE_CONTENT,
  USE_VACCINE_LOCATIONS_ON_MAP,
} from '../maps/maps-constants';
import { isMapOnDarkMode } from '../utils/map.utils';
import { getMapRouteStatus } from '../selectors/map/getMapRouteStatus';
import loadCovidVaccines, {
  VaccineLocation,
} from '../services/loadCovidVaccines';

// TODO: consider a clearer distinction between subway data (that needs to be loaded)
// and the visual state of the map
export interface MapState {
  stopListsByTimeFilterAndRoute: { default: StopListsByRoute } & Partial<
    { [key in TimeFilter]: StopListsByRoute }
  >;
  lastTimePatternGraphDataLoaded: Partial<
    { [key in TimeFilter]: Partial<Record<SubwayRouteId, Date>> }
  >;
  stations: Stations;
  routesWithSituation: RoutesWithSituation;
  timeFilter: TimeFilter;
  // TODO: rename to currentTimeFilter and consider adding currentDate
  currentTime: TimeFilter;
  ada: boolean;
  serviceStatus: ServiceStatus;
  geolocation: number[];
  // NOTE: newer versions of TypeScript change this to GeolocationPositionError
  geolocationError: PositionError | null;
  strategiesForSegments: StrategiesForSegments;
  lat: number;
  lon: number;
  zoom: number;
  /** Used for map editing mode. */
  currentSegment: string;
  currentSegmentStrategy: number;
  selectedAirportTerminalId: string | null;
  selectedRouteId: SubwayRouteId | '';
  selectedStation: Station | null;
  trainStatusByStopId: TrainStatusByStopId;
  elevatorEscalatorStatus: ElevatorEscalatorStatus;
  emergencyAlert: EmergencyAlert | null;
  useDarkMap: boolean;
  vaccineLocations: VaccineLocation[] | null;
  vaccineLocationsVisible: boolean;
  selectedVaccineLocationId: string | null;
}

const {
  timeFilter: timeFilterFromUrl,
  selectedRouteId,
  zoom,
  lat,
  lon,
  ada,
  vaccineLocations: vaccineLocationsVisibleFromUrl,
} = UrlState.get();
const areCoordsWithinExtent = isWithinExtent([lon, lat]);

const routeIdsForPatternGraph: OtpRouteId[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'J',
  'L',
  'M',
  'N',
  'Q',
  'R',
  'W',
  'Z',
  // shuttles
  'H',
  'FS',
  'GS',
  // Staten Island
  'SI',
];

const timeFilterFromDate = getTimeFilterFromDate();
const initialTimeFilter = timeFilterFromUrl || timeFilterFromDate;

export const initialMapState: Readonly<MapState> = {
  stopListsByTimeFilterAndRoute: { default: otpStopListsByRoute },
  lastTimePatternGraphDataLoaded: {},
  stations,
  routesWithSituation: {},
  zoom: clamp(zoom, DEFAULT_MIN_ZOOM, DEFAULT_MAX_ZOOM),
  lat: areCoordsWithinExtent ? lat : DEFAULT_LAT,
  lon: areCoordsWithinExtent ? lon : DEFAULT_LON,
  selectedRouteId,
  timeFilter: initialTimeFilter,
  ada,
  currentTime: timeFilterFromDate,
  serviceStatus: {
    weekday: {},
    weeknight: {},
    weekend: {},
  },
  geolocation: [],
  geolocationError: null,
  strategiesForSegments,
  currentSegment: '',
  currentSegmentStrategy: 0,
  selectedStation: null,
  selectedAirportTerminalId: null,
  // We assume empty status for all stations so it means "never loaded"
  // (i.e. offline, faulty, or otherwise not updated yet)
  trainStatusByStopId: {},
  elevatorEscalatorStatus: {
    outages: {},
    equipmentTotalsByStopId: {},
  },
  emergencyAlert: null,
  useDarkMap: isMapOnDarkMode(initialTimeFilter),
  vaccineLocations: [],
  vaccineLocationsVisible:
    USE_VACCINE_LOCATIONS_ON_MAP && vaccineLocationsVisibleFromUrl,
  selectedVaccineLocationId: null,
};

const updateServiceStatus = async (
  state: AppState,
  selectedRouteId: SubwayRouteId,
  timeFilter: TimeFilter,
  dispatch
) => {
  const timestamp = new Date().getTime();
  let routeStatus: RouteStatus | undefined =
    state.map.serviceStatus[timeFilter][selectedRouteId];
  const isRouteStatusExpired =
    timestamp - (routeStatus?.lastUpdated || 0) > CACHE_EXPIRACY_DURATION;

  if (!routeStatus || isRouteStatusExpired) {
    dispatch.ui.setAwaitingServiceStatus(true);

    routeStatus = await loadRouteStatus(selectedRouteId, timeFilter);

    if (routeStatus) {
      dispatch.map.setRoutesStatus({
        timeFilter,
        routeStatusList: [routeStatus],
      });
      dispatch.ui.setLastUpdate();
    }
  }

  const isRouteUnavailable = getMapRoutesUnavailable(state)[selectedRouteId];
  const routeStatusWithOneDirection = getMapRouteStatus(state);

  // On the first load, the routeStatus doesn't have the current new status
  // data in the state object.The solution will test both statuses to correctly
  // show the status view if the line has only the one direction alert.
  // Later we could review it to set the complete route status in a better way.
  if (
    (isRouteUnavailable ||
      routeStatus.statusDetails.length ||
      routeStatusWithOneDirection?.statusDetails.length) &&
    !state.ui.routeMenuOpened
  ) {
    dispatch.ui.setStatusViewOpened(true);
  } else {
    dispatch.ui.setStatusViewOpened(false);
  }

  dispatch.ui.setAwaitingServiceStatus(false);
};

const map = {
  state: initialMapState,

  reducers: {
    patchStopListsByRoute(
      state: MapState,
      timeFilter: TimeFilter,
      stopListsByRoute: Partial<StopListsByRoute>
    ) {
      // Create basic data for that timeFilter if not found yet
      if (!(timeFilter in state.stopListsByTimeFilterAndRoute)) {
        state.stopListsByTimeFilterAndRoute[
          timeFilter
        ] = {} as StopListsByRoute;
      }

      const currentStopListsByRoute =
        state.stopListsByTimeFilterAndRoute[timeFilter];

      // Apply new data
      if (currentStopListsByRoute) {
        for (const [routeId, stopLists] of Object.entries(stopListsByRoute)) {
          currentStopListsByRoute[routeId] = stopLists || [];
        }
      }
    },

    setRoutesWithSituation(
      state: MapState,
      routesWithSituation: RoutesWithSituation
    ) {
      state.routesWithSituation = routesWithSituation;
    },

    setCurrentSegment(state: MapState, segmentId: string) {
      state.currentSegment = segmentId;
    },

    setStrategyForCurrentSegment(state: MapState, strategy: number) {
      state.strategiesForSegments[state.currentSegment] = strategy;
    },

    setSelectedRouteId(state: MapState, selectedRouteId: SubwayRouteId | '') {
      state.selectedRouteId = selectedRouteId;
    },

    setTimeFilter(state: MapState, timeFilter: TimeFilter) {
      state.timeFilter = timeFilter;
    },

    setCurrentTime(state: MapState, currentTime: TimeFilter) {
      state.currentTime = currentTime;
    },

    setAda(state: MapState, ada: boolean) {
      state.ada = ada;
    },

    toggleADA(state: MapState) {
      state.ada = !state.ada;
    },

    setVaccineLocationsVisible(state: MapState, isVisible: boolean) {
      state.vaccineLocationsVisible = isVisible;
    },

    toggleVaccineLocationsVisible(state: MapState) {
      state.vaccineLocationsVisible = !state.vaccineLocationsVisible;
    },

    zoomIn(state: MapState) {
      state.zoom = clamp(state.zoom + 1, DEFAULT_MIN_ZOOM, DEFAULT_MAX_ZOOM);
    },

    zoomOut(state: MapState) {
      state.zoom = clamp(state.zoom - 1, DEFAULT_MIN_ZOOM, DEFAULT_MAX_ZOOM);
    },

    setServiceStatus(state: MapState, serviceStatus: ServiceStatus) {
      state.serviceStatus = serviceStatus;
    },

    setElevatorEscalatorStatus(
      state: MapState,
      elevatorEscalatorStatus: ElevatorEscalatorStatus
    ) {
      state.elevatorEscalatorStatus = elevatorEscalatorStatus;
    },

    setEmergencyAlert(state: MapState, emergencyAlert: EmergencyAlert) {
      state.emergencyAlert = emergencyAlert;
    },

    setMapView(
      state: MapState,
      payload: Pick<MapState, 'lat' | 'lon' | 'zoom'>
    ) {
      // Often floating-point quirks add a tiny amount like .00000000001 to lat
      const hasLatChanged = isValueDifferent(state.lat, payload.lat);
      const hasLonChanged = isValueDifferent(state.lon, payload.lon);
      const areCoordsWithinExtent = isWithinExtent([
        payload.lon || 0,
        payload.lat || 0,
      ]);

      state.lat =
        areCoordsWithinExtent && hasLatChanged ? payload.lat : state.lat;
      state.lon =
        areCoordsWithinExtent && hasLonChanged ? payload.lon : state.lon;
      state.zoom = payload.zoom || state.zoom;
    },

    setRoutesStatus(
      state: MapState,
      payload: {
        timeFilter: TimeFilter;
        routeStatusList: RouteStatus[];
      }
    ) {
      state.serviceStatus[payload.timeFilter] = {
        ...state.serviceStatus[payload.timeFilter],
        ...payload.routeStatusList.reduce((acc, routeStatus) => {
          acc[routeStatus.routeId] = routeStatus;
          return acc;
        }, {}),
      };
    },

    setGeolocation(state: MapState, geolocation: number[]) {
      state.geolocation = geolocation;
    },

    // NOTE: newer versions of TypeScript change this to GeolocationPositionError
    setGeolocationError(state: MapState, error: PositionError) {
      state.geolocationError = error;
    },

    setSelectedStation(state: MapState, selectedStation: Station | null) {
      state.selectedStation = selectedStation;
    },

    setSelectedAirportTerminalId(
      state: MapState,
      selectedTerminalId: string | null
    ) {
      state.selectedAirportTerminalId = selectedTerminalId;
    },

    setSelectedVaccineLocationId(state: MapState, locationId: string | null) {
      state.selectedVaccineLocationId = locationId;
    },

    setMapZoom(state: MapState, zoom: number) {
      state.zoom = zoom;
    },

    setUseDarkMap(state: MapState, useDarkMap: boolean) {
      state.useDarkMap = useDarkMap;
    },

    patchTrainStatusByStopId(
      state: MapState,
      trainTimesByStopId: TrainTimesByStopId
    ) {
      const now = Date.now();
      Object.keys(trainTimesByStopId).forEach(stopId => {
        state.trainStatusByStopId[stopId] = {
          lastUpdate: now,
          trains: trainTimesByStopId[stopId],
        };
      });
    },

    setVaccineLocations(
      state: MapState,
      vaccineLocations: VaccineLocation[] | null
    ) {
      state.vaccineLocations = vaccineLocations;
    },
  },

  effects: dispatch => ({
    async bootstrapMap(payload, state: AppState) {
      /*
       * Force Android devices to scroll in order
       * to hide bottom bar
       * See: https://stackoverflow.com/questions/4068559/removing-address-bar-from-browser-to-view-on-android
       */
      if (isAndroidDevice()) {
        window.scrollTo(0, 1);
      }

      // Listen for visibility changes
      document.addEventListener('visibilitychange', () => {
        const currentTime = new Date().getTime();
        const shouldResetState =
          currentTime - state.ui.lastInteraction > VISIBILITY_TIMEOUT_REFRESH;

        if (document.visibilityState === 'visible' && shouldResetState) {
          if (isStandaloneMode()) {
            dispatch.map.resetState({ includingView: true });
          } else {
            dispatch.map.resetState({ includingView: false });
          }
        }

        dispatch.ui.setLastInteraction(currentTime);
      });

      // ! TODO: potentially re-enable when design has been revisited
      // if (!isStandaloneMode() && isPhone()) {
      //   dispatch.ui.requestOpenErrorModal('pwa');
      // }

      if (isInternetExplorer()) {
        dispatch.ui.requestOpenErrorModal('ie11');
      }

      // Listen for URL changes
      window.addEventListener('hashchange', () => {
        const urlState = UrlState.get();

        dispatch.map.setAda(urlState.ada);
        dispatch.map.setVaccineLocationsVisible(urlState.vaccineLocations);
        dispatch.map.setMapView({
          lat: urlState.lat,
          lon: urlState.lon,
          zoom: clamp(urlState.zoom, DEFAULT_MIN_ZOOM, DEFAULT_MAX_ZOOM),
        });
        dispatch.map.setSelectedRouteId(urlState.selectedRouteId);
        dispatch.map.setTimeFilter(timeFilter);
      });

      // Listen for keyboard events
      document.addEventListener('keyup', e => {
        if (EDITING) {
          const key = e.key.toUpperCase();
          dispatch.map.setStrategyForCurrentSegment(+key);
        }
      });

      // Listen for Geolocation changes
      if ('geolocation' in navigator) {
        navigator.geolocation.watchPosition(
          position => {
            const {
              coords: { latitude, longitude },
            } = position;

            dispatch.map.geolocationListener([latitude, longitude]);
          },
          error => {
            dispatch.map.setGeolocationError(error);
          },
          {
            enableHighAccuracy: true,
          }
        );
      }

      // Load patternGraph data for routes
      const { selectedRouteId, timeFilter } = state.map;
      await dispatch.map.loadPatternGraph(routeIdsForPatternGraph);

      if (selectedRouteId) {
        dispatch.map.setSelectedRouteId(selectedRouteId);

        // This is a dev tool to scan a full day of patternGraph data for one route,
        // and print to console a table of how many stops are on the route at each time interval.
        // The time table requires dozens of network calls,
        // so it's opt-in via the date/time query params.
        // This only executes on map bootstrap, not on changing time filter or route,
        // so a page refresh is necessary to load a different time table.
        if (getOverridableDateTime()) {
          const date = overridableNow();
          const timeTable = await fetchRouteStopsTimeTable({
            routeId: selectedRouteId,
            date,
            minutesBetweenTimes: 60,
            enableParallel: true,
          });
          console.table(timeTable);
        }
      } else {
        dispatch.ui.setLastUpdate();
      }

      const routesWithSituation: RoutesWithSituation = await getRoutesWithSituation(
        timeFilter,
        state.map.serviceStatus,
        dispatch
      );
      dispatch.map.setRoutesWithSituation(routesWithSituation);
      // TODO: also setRoutesUnavailable() ?

      dispatch.map.updateElevatorEscalatorStatus();

      let vaccineLocations: VaccineLocation[] | null = null;
      if (USE_VACCINE_LOCATIONS_ON_MAP) {
        vaccineLocations = await loadCovidVaccines();
        dispatch.map.setVaccineLocations(vaccineLocations);
      }

      // Load any potential emergency alerts
      let emergencyAlert: EmergencyAlert | null = await loadEmergencyAlerts();

      if (
        !emergencyAlert &&
        EMERGENCY_ALERT_ALTERNATIVE_CONTENT &&
        vaccineLocations
      ) {
        emergencyAlert = EMERGENCY_ALERT_ALTERNATIVE_CONTENT;
      }

      dispatch.map.setEmergencyAlert(emergencyAlert);
      dispatch.ui.toggleEmergencyAlertView();
    },

    centerMapOnGeolocation(payload, state: AppState) {
      if (state.map.geolocation.length === 2) {
        const lon = state.map.geolocation[0];
        const lat = state.map.geolocation[1];
        const zoom = DEFAULT_CENTER_ZOOM;
        dispatch.map.setMapView({ lat, lon, zoom });
      }

      if (state.map.geolocationError || !state.ui.geolocationEnabled) {
        dispatch.ui.requestOpenErrorModal('geolocation');
      }
    },

    async loadPatternGraph(routeIds: OtpRouteId[], state: AppState) {
      const date = getPatternGraphDateFromTimeFilter(state.map.timeFilter);
      const enableParallel = true;

      // The `direction` query parameter forces loading of patternGraph for one direction only.
      // More information on getDevQaUrlData definition 'src/utils/url.utils.ts'
      const directionId = getDevQaUrlData().direction;

      type Input = {
        routeId: OtpRouteId;
        date: Date;
        directionId?: RouteDirection;
      };

      // Each route can have multiple stop lists (if forked)
      type Output = StopLists;

      // Ignore route ids that have just been loaded
      let dataForTime =
        state.map.lastTimePatternGraphDataLoaded[state.map.timeFilter];
      const now = new Date();
      const throttleTime = 30 * 1000; // Only load pattern graph data once every 30 sec max
      const routeIdsToLoad = routeIds.filter(
        routeId =>
          !dataForTime ||
          !dataForTime[routeId] ||
          now.getTime() - dataForTime[routeId].getTime() > throttleTime
      );

      if (routeIdsToLoad.length === 0) return;

      // TODO: instead of loadOneRouteStopsAtTime(), call a function that fetches
      // all stops at the same time if supported by the API.
      // See fetchPatternGraphForRoute() inside /src/subway-data/patternGraph/index.ts
      // for more information.
      const inputs: Input[] = routeIdsToLoad.map(routeId => ({
        routeId,
        date,
        directionId,
      }));

      let outputs: Output[] = [];
      if (enableParallel) {
        outputs = await Promise.all(inputs.map(loadRouteStopsAtTime));
      } else {
        for (const input of inputs) {
          outputs.push(await loadRouteStopsAtTime(input));
        }
      }

      const newStopsByRoute: Partial<StopListsByRoute> = {};
      routeIdsToLoad.forEach((routeId, i) => {
        newStopsByRoute[routeId] = outputs[i];

        if (!dataForTime) {
          dataForTime = state.map.lastTimePatternGraphDataLoaded[
            state.map.timeFilter
          ] = {};
        }
        dataForTime[routeId] = now;
      });
      dispatch.map.patchStopListsByRoute(state.map.timeFilter, newStopsByRoute);
    },

    async loadPatternGraphForSelectedRoutes(
      selectedRouteId: SubwayRouteId | ''
    ) {
      // For now, only load patternGraph for specific routes
      if (selectedRouteId) {
        // Single route
        if (routeIdsForPatternGraph.includes(selectedRouteId)) {
          await dispatch.map.loadPatternGraph([selectedRouteId]);
        }
      } else {
        // All routes
        await dispatch.map.loadPatternGraph(routeIdsForPatternGraph);
      }
    },

    async setSelectedRouteId(routeId: SubwayRouteId | '', state: AppState) {
      dispatch.map.loadPatternGraphForSelectedRoutes(routeId);
      dispatch.ui.toggleEmergencyAlertView();

      if (routeId) {
        await updateServiceStatus(
          state,
          routeId,
          state.map.timeFilter,
          dispatch
        );
      }
    },

    async updateElevatorEscalatorStatus() {
      const elevatorEscalatorStatus:
        | ElevatorEscalatorStatus
        | undefined = await loadElevatorEscalatorStatusWithStopId();
      dispatch.map.setElevatorEscalatorStatus(elevatorEscalatorStatus);
    },

    async updateStopTrainTimes(stopId: StopId) {
      const stationStatus = await loadStationStatus(stopId);
      if (stationStatus?.trainTimes) {
        dispatch.map.patchTrainStatusByStopId({
          [stopId]: stationStatus.trainTimes,
        });
      }
    },

    async setTimeFilter(timeFilter: TimeFilter, state: AppState) {
      const { selectedRouteId } = state.map;

      dispatch.map.setUseDarkMap(isMapOnDarkMode(timeFilter));

      if (selectedRouteId) {
        await updateServiceStatus(state, selectedRouteId, timeFilter, dispatch);
      }

      // Request the status for all the routes to update the menu,
      // the map, and any other global layout elements
      dispatch.map.loadPatternGraphForSelectedRoutes();

      // Reset availability to sync animations when getting new ones
      dispatch.map.setRoutesWithSituation({});

      const routesWithSituation = await getRoutesWithSituation(
        timeFilter,
        state.map.serviceStatus,
        dispatch
      );
      dispatch.map.setRoutesWithSituation(routesWithSituation);
    },

    async resetState({ includingView }: { includingView: boolean }) {
      const newTimeFilter = getTimeFilterFromDate();

      dispatch.map.setCurrentTime(newTimeFilter);
      dispatch.map.setTimeFilter(newTimeFilter);
      dispatch.map.setServiceStatus({
        weekend: {},
        weekday: {},
        weeknight: {},
      });

      if (includingView) {
        dispatch.map.setMapView({
          lat: DEFAULT_LAT,
          lon: DEFAULT_LON,
          zoom: DEFAULT_ZOOM,
        });
        dispatch.map.setSelectedRouteId('');
      }
    },

    geolocationListener([latitude, longitude], state: AppState) {
      const isFirstGeolocationChange = state.map.geolocation.length === 0;
      const isDefaultLocation =
        state.map.lat === DEFAULT_LAT && state.map.lon === DEFAULT_LON;
      const areCoordsWithinExtent = isWithinExtent([longitude, latitude]);

      // Only center the map on current location if using a fresh version of the map within NYC
      if (
        isFirstGeolocationChange &&
        isDefaultLocation &&
        areCoordsWithinExtent
      ) {
        const timestamp = new Date().getTime();

        /*
         * Only auto center the map on
         * geolocation if it takes less than
         * a certain amount of time to get it
         */
        if (
          state.ui.lastInteraction - timestamp <
          CENTER_ON_GEOLOCATION_TIMEOUT
        ) {
          dispatch.map.setMapView({
            lat: latitude,
            lon: longitude,
            zoom: DEFAULT_CENTER_ZOOM,
          });
        }
      }

      dispatch.map.setGeolocation([longitude, latitude]);
    },

    setGeolocation(geolocation: number[]) {
      const [lon, lat] = geolocation;
      const areCoordsWithinExtent = isWithinExtent([lon, lat]);

      dispatch.ui.setGeolocationEnabled(areCoordsWithinExtent);
    },

    toggleVaccineLocationsVisible(_, state: AppState) {
      // We close any open vaccination modal if we turn of the toggle
      if (!state.map.vaccineLocationsVisible) {
        dispatch.ui.setVaccinationViewOpened(false);
      }
    },
  }),
};

export default map;
