import { MANHATTAN_DEGREES } from './subway-data';
import { easeQuadOut } from 'd3-ease';
import { isPhone } from './utils/deviceDetector.utils';
import { Extent } from 'ol/extent';
import { ZoomLevel } from './maps/map-types';

/*
 * Map related config variables
 */
export const DEFAULT_MIN_ZOOM = isPhone() ? ZoomLevel.z11 : ZoomLevel.z12;
export const DEFAULT_MAX_ZOOM = ZoomLevel.z18;
export const DEFAULT_CENTER_ZOOM = ZoomLevel.z15;
export const DEFAULT_ZOOM = isPhone() ? ZoomLevel.z13 : ZoomLevel.z14;

// Limit view to NYC
export const DEFAULT_EXTENT: Extent = isPhone()
  ? [-8278869.8963, 4928600.7599, -8190340.9601, 5008083.1539]
  : [-8299328.8635, 4900077.8439, -8190340.9601, 5018083.1539];

// Manhattan vertically aligned
export const DEFAULT_ROTATION = (-MANHATTAN_DEGREES * Math.PI) / 180;

// Centered on downtown Manhattan/Dumbo
// Separate coordinates for Phone to ensure Manhattan is centered in viewframe (due to different zoom & aspect ratio)
export const DEFAULT_LAT = isPhone() ? 40.7436 : 40.70949;
export const DEFAULT_LON = isPhone() ? -73.9909 : -73.97853;

export const DEFAULT_TRANSITION_EASING = easeQuadOut;
export const DEFAULT_TRANSITION_DURATION = 1600;

// Time before refreshing map state (30 minutes)
export const VISIBILITY_TIMEOUT_REFRESH = 30 * 60 * 1000;

// Time during which we autocenter the map on geolocation (5 seconds)
export const CENTER_ON_GEOLOCATION_TIMEOUT = 5 * 1000;

export const LOCAL_STORAGE_ERROR_PROMPTS_KEY = 'mta-map-error-prompts';

// Time before invalidating the cache & making new requests to the MTA API (5 minutes)
export const CACHE_EXPIRACY_DURATION = 5 * 60 * 1000;

/** Used to show moving trains only for "soon" arrivals, i.e. in the next number of seconds */
export const MOVING_TRAIN_MAX_SECONDS = 5 * 60;

/** This quantity is later scaled by pixel ratio and zoom resolution. */
export const MOVING_TRAIN_LENGTH = 90;

/** The zoom at which moving trains will become visible. */
export const MOVING_TRAIN_MIN_ZOOM = 14.999;

/** Controls how far away a moving train appears on the track, given its ETA. */
export const MOVING_TRAIN_SPEED = 10;

/** Sets the frame rate for animating moving trains. */
export const MOVING_TRAIN_FPS = 10;
